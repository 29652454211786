import React, { FC, ReactElement } from 'react';

import { StyledContent, StyledInfoBox, StyledMessage } from './styles';

import { ReactComponent as InfoIcon } from 'images/infoIcon.svg';
import { ReactComponent as ErrorIcon } from 'images/errorIcon.svg';

export type variants = 'info' | 'error' | 'primary';

interface InfoBoxPropTypes {
  big?: boolean;
  className?: string;
  content?: ReactElement;
  message?: string | ReactElement;
  variant?: variants;
}

const VariantIcon = {
  info: InfoIcon,
  error: ErrorIcon,
  primary: null,
};

export const InfoBox: FC<InfoBoxPropTypes> = ({
  message,
  className = '',
  variant = 'info',
  content,
  big = false,
}) => {
  const Icon = VariantIcon[variant];

  return (
    <StyledInfoBox big={big} className={className} custom={Boolean(content)} variant={variant}>
      {Icon && <Icon />}
      {message && <StyledMessage>{message}</StyledMessage>}
      {content && <StyledContent>{content}</StyledContent>}
    </StyledInfoBox>
  );
};
