import styled from 'styled-components/macro';

export const StyledContainer = styled.div`
  text-align: center;
  width: 100%;

  & > button {
    font-weight: 600;
    margin: 0 auto;
  }
`;

export const StyledInputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.sizes.sm};
`;

export const StyledErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
