import { CompanyEntity } from 'ducks/companies/types';

export const formatFileSize = (size: number) => {
  const kb = Math.round(size / 1000);
  if (kb < 1000) return `${kb} kB`;
  const mb = Math.round(kb / 100) / 10;
  return `${mb} MB`;
};

export const hasPreview = (fileType: string): boolean =>
  fileType.includes('image') || fileType.includes('video');

export const prepareColumnsOrderProps = <T extends string | number | symbol>(
  defaultColumns: T[],
  userGridPrefrence?: T[],
): { columnsOrder: T[]; columnsVisibility: T[] } => {
  if (!userGridPrefrence) {
    return { columnsOrder: defaultColumns, columnsVisibility: defaultColumns };
  }
  const resultColumnsOrder = [
    ...userGridPrefrence,
    ...defaultColumns.filter((x) => !userGridPrefrence.includes(x)),
  ].filter((column) => defaultColumns.includes(column)) as T[]; //filter out columns that got removed, but remains in the settings
  return { columnsOrder: resultColumnsOrder, columnsVisibility: userGridPrefrence };
};

export const sortCompanyAddresses = (res: CompanyEntity) => {
  const order = {
    business: 2,
    billing: 4,
    mailing: 3,
    visit: 1,
  };

  const newAddresses = [...res.addresses].sort((a, b) => order[a.typeSymbol] - order[b.typeSymbol]);
  return { ...res, addresses: newAddresses };
};

export const contactOriginToModel = {
  company: 'CompanyContact',
  workplace: 'WorkplaceContact',
  visit: 'CompanyVisitContact',
} as const;

export const getHoursAndMinutes = (date: string) => {
  return `${new Date(date.toLocaleString()).getHours().toString().padStart(2, '0')}:${new Date(
    date.toLocaleString(),
  )
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
};

export const mergeTimeAndDate = (date: string, time: string) => {
  const dateWithTime = new Date(date);
  const splitTime = time.split(':');
  const hours = splitTime ? splitTime[0] : '00';
  const minutes = splitTime ? splitTime[1] : '00';

  dateWithTime.setHours(parseInt(hours));
  dateWithTime.setMinutes(parseInt(minutes));

  return dateWithTime;
};

export const add30MinutesToTime = (timeStr: string): string => {
  const [hours, minutes] = timeStr.split(':').map(Number);
  const totalMinutes = hours * 60 + minutes + 30;
  const newHours = Math.floor(totalMinutes / 60);
  const newMinutes = totalMinutes % 60;
  return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
};
