import React from 'react';

import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { currentUserSelector } from 'ducks/users/selectors';
import { useSelector } from 'react-redux';
import { FallbackLink } from 'components/FallbackLink/FallbackLink';

const development = /localhost/.test(window.location.href) && 'development';

const bugsnagClient = bugsnag({
  apiKey: window.REACT_APP_ENV.BUGSNAG_API_KEY,
  releaseStage:
    development || window.REACT_APP_ENV.BUGSNAG_RELEASE_STAGE || window.REACT_APP_ENV.NODE_ENV,
});

bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary = bugsnagClient.getPlugin('react');

export const Bugsnag = ({ children }) => {
  const user = useSelector(currentUserSelector);
  if (user?.email) {
    bugsnagClient.user = {
      id: user.id,
      name: user.name,
      email: user.email,
    };
  }

  return <ErrorBoundary FallbackComponent={FallbackLink}>{children}</ErrorBoundary>;
};
