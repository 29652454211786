import React, { useEffect, useMemo } from 'react';
import { globalHistory, navigate, RouteComponentProps } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';

import { checkIsIpad } from 'utils/deviceChecker';
import { CompanyResults } from 'containers/powersearch/Results/CompanyResults';
import { CompanyVisitsResults } from 'containers/powersearch/Results/CompanyVisitsResults';
import { ContactsResults } from 'containers/powersearch/Results/ContactsResults';
import { formatDate, formatQueryDate } from 'utils/date';
import { PriorNotificationsResults } from 'containers/powersearch/Results/PriorNotificationsResults';
import { RevisedActivitiesResults } from 'containers/powersearch/Results/RevisedActivitiesResults';
import { StoreType } from 'store';
import { StyledLayoutWithFullWidth } from 'containers/styles';
import { WorkplaceResults } from 'containers/powersearch/Results/WorkplaceResults';

import { StyledTabContainerVertical } from './styles';

interface PageProps extends RouteComponentProps {
  tag?: string;
}

export const Page = (props: PageProps) => {
  const { tag, location } = props;
  const { t } = useTranslation();
  const selectedDistrict = useSelector((state: StoreType) => state.powerSearch.district);
  const districtValue = selectedDistrict?.length > 0 ? selectedDistrict : null;
  const savedState = location?.state;
  // savedState always has key prop
  const hasSavedState = savedState && Object.keys(savedState).length > 1;

  useEffect(() => {
    if (tag === 'null') {
      navigate(
        globalHistory.location.pathname.includes('redesigned/search')
          ? `${globalHistory.location.pathname.replace('null', 'company')}${
              globalHistory.location.search
            }`
          : globalHistory.location.pathname,
        {
          replace: globalHistory.location.pathname.includes('redesigned/search'),
        },
      );
    }
  }, []);

  const companyInitialState = useMemo(
    () => ({
      status: {
        displayValue: ['active'],
        isActive: true,
        value: ['active'],
      },
    }),
    [districtValue],
  );

  const workplaceInitialState = useMemo(() => {
    const queryDate = formatQueryDate(new Date());

    return {
      termination: {
        displayValue: [`${capitalize(t('filters.from'))}: ${formatDate(queryDate)}`],
        isActive: true,
        value: { min: queryDate },
      },
    };
  }, [districtValue]);

  const isIpad = checkIsIpad();

  return (
    <StyledTabContainerVertical isIpad={isIpad}>
      <StyledLayoutWithFullWidth>
        {tag === 'company' && (
          <CompanyResults
            initialState={(hasSavedState ? savedState : companyInitialState) as object}
          />
        )}
        {tag === 'company_visits' && (
          <CompanyVisitsResults initialState={(hasSavedState ? savedState : {}) as object} />
        )}
        {tag === 'workplace' && <WorkplaceResults initialState={workplaceInitialState as object} />}
        {tag === 'contact' && (
          <ContactsResults initialState={(hasSavedState ? savedState : {}) as object} />
        )}
        {tag === 'prior_notifications' && (
          <PriorNotificationsResults initialState={(hasSavedState ? savedState : {}) as object} />
        )}
        {tag === 'revised_activities' && (
          <RevisedActivitiesResults initialState={(hasSavedState ? savedState : {}) as object} />
        )}
      </StyledLayoutWithFullWidth>
    </StyledTabContainerVertical>
  );
};
