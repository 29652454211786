import i18n from 'i18n';

const t = (x: string) => i18n.t(x);

export const companyVisitStatusesMap = {
  draft: { variant: 'success', content: t('common.draft') },
  edit: { variant: 'success', content: t('common.edit') },
  follow_up: { variant: 'high-alert', content: t('common.follow_up') },
  completed: { variant: 'neutral', content: t('common.complete') },
  scheduled: { variant: 'primary', content: t('filters.planned') },
  planned: { variant: 'primary', content: t('filters.planned') },
  overdue: { variant: 'high-alert', content: t('filters.overdue') },
  active: { variant: 'success', content: t('company_details.company_is_active') },
  inactive: { variant: 'inactive', content: t('company_details.company_is_inactive') },
  Active: { variant: 'success', content: t('company_details.company_is_active') },
  Inactive: { variant: 'inactive', content: t('company_details.company_is_inactive') },
} as const;
