import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { getInitials } from 'utils';
import { StoreType } from 'store/index';

const currentUserNameSelector = (state: StoreType): string => get(state, 'users.current.name', '');
export const currentUserSelector = (state: StoreType) => state.users.current;

const usersSelector = (state: StoreType) => state.users.all;

export const currentUserInitialsSelector: Function = createSelector(
  currentUserNameSelector,
  (name) => getInitials(name),
);

export const rvoUsersSelector = createSelector(usersSelector, (users) =>
  users
    .filter((user) => user.rvoUser && user.active)
    .map((user) => ({ additionalLabel: user.email, value: user.email, label: user.name || '-' })),
);

export const userGridPrefferencesSelector = (state: StoreType) => state.users.current?.gridSettings;

export const userIndustryNameSelector = createSelector(
  currentUserSelector,
  (user) => user?.industryName,
);
