import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';

import { StyledLayout, StyledTopSpace } from 'containers/styles';
import { Margin } from 'styles/utils';
import { Card } from 'components/Card/Card';
import { HeadPanel } from 'components/HeadPanel/HeadPanel';
import { CreateCompanyForm } from 'containers/CompanyCreate/CreateCompanyForm';

export const CreateCompany: FC<RouteComponentProps> = () => {
  const { t } = useTranslation();

  return (
    <>
      <StyledTopSpace />
      <HeadPanel>
        <h1>{t('company_details.add_new_company')}</h1>
      </HeadPanel>
      <StyledLayout>
        <Margin top='1.6rem'>
          <Card noPadding>
            <CreateCompanyForm />
          </Card>
        </Margin>
      </StyledLayout>
    </>
  );
};
