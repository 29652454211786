import { MutableRefObject } from 'react';
import axios, { CancelTokenSource } from 'axios';

import { searchForCompaniesNewVisit } from 'ducks/newVisit';
import { SearchCompanyEntityResponse } from 'ducks/powerSearch/company/types';

export const searchCompanies = async (
  query: string,
  company: object,
  page_size = 500,
  token: MutableRefObject<CancelTokenSource | null> = { current: null },
) => {
  token?.current?.cancel();
  const CancelToken = axios.CancelToken;
  token.current = CancelToken.source();
  const response = await searchForCompaniesNewVisit({ query, company, page_size }, token.current);
  return response.data.tableBody.map((company: SearchCompanyEntityResponse) => ({
    ...company,
    label: company.name,
    value: company.companyId,
  }));
};
