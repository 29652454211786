import { theme } from 'consts/theme';
import React, { FC } from 'react';

import {
  StyledHeader1Text,
  StyledHeader2Text,
  StyledHeader3Text,
  StyledParagraphText,
} from './styles';

export interface TextPropTypes {
  children: React.ReactNode;
  size?: keyof typeof theme.fontSizes;
  bold?: boolean;
  color?: keyof typeof theme.colors;
}

const variants = {
  xxs: StyledParagraphText,
  xs: StyledParagraphText,
  sm: StyledParagraphText,
  base: StyledParagraphText,
  lg: StyledHeader3Text,
  xl: StyledHeader2Text,
  xxl: StyledHeader1Text,
};

export const Text: FC<TextPropTypes> = ({
  children,
  size = 'base',
  bold = false,
  color = 'black900',
}) => {
  const Component = variants[size];

  return (
    <Component bold={bold} color={color} size={size}>
      {children}
    </Component>
  );
};
