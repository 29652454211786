import React, { MutableRefObject } from 'react';
import { useSelector } from 'react-redux';
import { CancelTokenSource } from 'axios';

import { newVisitObservationSelector } from 'ducks/newVisit';
import { Observation } from 'containers/newVisit/shared/Observation/Observation';

interface ObligatoryChecklistPropTypes {
  cancelToken: MutableRefObject<CancelTokenSource | null>;
  companyVisitId: string;
  visitId: string;
}

export const ObligatoryChecklist = ({
  cancelToken,
  companyVisitId,
  visitId,
}: ObligatoryChecklistPropTypes) => {
  const observations = useSelector(newVisitObservationSelector(companyVisitId))?.filter(
    ({ obligatory }) => obligatory,
  );

  return (
    <>
      <div data-testid='obligatory-checklist'>
        {observations?.map((observation) => (
          <Observation
            key={observation.id}
            {...observation}
            cancelToken={cancelToken}
            visitId={visitId}
          />
        ))}
      </div>
    </>
  );
};
