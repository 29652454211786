import styled, { css } from 'styled-components';

export const StyledTabContainer = styled.div`
  margin: ${({ theme }) => `calc(${theme.sizes.md} * 2.5 + ${theme.sizes.xs}) ${theme.sizes.md} 0`};
`;

export const StyledTabContainerVertical = styled.div<{ isIpad: boolean }>`
  ${({ isIpad, theme }) =>
    isIpad
      ? css`
          padding-top: calc(${theme.sizes.md} * 2.5 + ${theme.sizes.xs});
        `
      : css`
          margin: calc(${theme.sizes.md} * 2.5 + ${theme.sizes.xs}) 0 0;
        `}
`;

export const StyledLayout = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 144rem;
  width: 100%;
`;
