import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useModal } from 'components/Modal/Modal';
import {
  StyledModalButtonsWrapper,
  StyledModalHeader,
  StyledModalMessage,
} from 'components/Modal/styles';
import { useAppDispatch } from 'store';
import { deleteVisit, DeleteVisitResponse } from 'ducks/newVisit';
import toast from 'react-hot-toast';

interface DeleteVisitProps {
  visitId: string;
}

export const DeleteVisit = (props: DeleteVisitProps): React.ReactElement => {
  const { visitId } = props;
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const { hideModal } = useModal();
  const dispatch = useAppDispatch();

  const onSubmit = React.useCallback(async () => {
    setIsProcessing(true);
    try {
      const response = (await dispatch(deleteVisit(visitId))) as { payload: DeleteVisitResponse };

      if (response?.payload?.error) {
        throw new Error(response.payload.error);
      }
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      } else {
        throw err;
      }
    }
    hideModal();
  }, [visitId]);

  return (
    <>
      <StyledModalHeader>{t('new_visits.remove_visit')}</StyledModalHeader>

      <StyledModalMessage>{t('new_visits.remove_visit_desc')}</StyledModalMessage>
      <StyledModalButtonsWrapper>
        <DefaultButton
          capitalize
          isDisabled={isProcessing}
          label={t('common.cancel')}
          onClick={hideModal}
          type='button'
          variant='tertiary'
        />
        <DefaultButton
          isDisabled={isProcessing}
          label={t('new_visits.remove_visit')}
          onClick={onSubmit}
          type='button'
          variant='danger'
        />
      </StyledModalButtonsWrapper>
    </>
  );
};
