import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInput } from 'components/TextInput/TextInput';
import { CentredDefaultButton } from 'contexts/FilterContext/styles';
import { StyledContainer, StyledErrorWrapper, StyledInputsWrapper } from './styles';
import { StyledError } from 'components/Forms/styles';

interface NumberRangePropTypes {
  maxValue: number;
  minValue: number;
  onChange: Function;
  value: { min: number; max: number } | null;
  isActive: boolean;
}

export const NumberRange = (props: NumberRangePropTypes) => {
  const { maxValue, minValue, onChange, value, isActive } = props;
  const [min, setMin] = useState(value?.min || minValue);
  const [max, setMax] = useState(value?.max || maxValue);
  const { t } = useTranslation();

  const maxValueForMinInput = value ? value.max : maxValue;
  const minValueForMaxInput = value ? value.min : minValue;

  useEffect(() => {
    if (!value) {
      setMin(minValue);
      setMax(maxValue);
    }
  }, [value]);

  // ---- min input section ----
  const minOnBlur = useCallback(() => {
    if (min <= maxValueForMinInput && min >= minValue) {
      onChange({ min, max: maxValueForMinInput });
    } else setMin(value?.min || minValue);
  }, [min, value]);

  const minOnChange = useCallback((e) => setMin(Number(e.currentTarget.value)), [setMin]);

  // ---- max input section ----
  const maxOnBlur = useCallback(() => {
    if (max >= minValueForMaxInput && max <= maxValue) {
      onChange({ max, min: minValueForMaxInput });
    } else setMax(value?.max || maxValue);
  }, [max, value]);

  const maxOnChange = useCallback((e) => setMax(Number(e.currentTarget.value)), [setMax]);

  // ---- extra handlers and helpers ----
  const onKeyPress = useCallback((e) => e.key === 'Enter' && e.currentTarget.blur(), []);

  let error = null;

  if (min < minValue) error = { min: `min ${minValue}` };
  else if (min > maxValueForMinInput) error = { min: `max ${maxValueForMinInput}` };
  else if (max > maxValue) error = { max: `max ${maxValue}` };
  else if (max < minValueForMaxInput) error = { max: `min ${minValueForMaxInput}` };
  else error = null;

  return (
    <StyledContainer>
      <StyledInputsWrapper>
        <StyledErrorWrapper>
          <TextInput
            inputProps={{
              max: maxValueForMinInput,
              min: minValue,
              onBlur: minOnBlur,
              onChange: minOnChange,
              onKeyPress,
              type: 'number',
              value: min.toString(), // .toString() used to remove leading 0 from input value, eg. 03
            }}
            isError={!!error?.min}
            textAlign='center'
          />
          {error?.min && <StyledError>{error.min}</StyledError>}
        </StyledErrorWrapper>

        <StyledErrorWrapper>
          <TextInput
            inputProps={{
              max: maxValue,
              min: minValueForMaxInput,
              onBlur: maxOnBlur,
              onChange: maxOnChange,
              onKeyPress,
              type: 'number',
              value: max.toString(), // .toString() used to remove leading 0 from input value, eg. 03
            }}
            isError={!!error?.max}
            textAlign='center'
          />
          {error?.max && <StyledError>{error.max}</StyledError>}
        </StyledErrorWrapper>
      </StyledInputsWrapper>

      <CentredDefaultButton
        isActive={isActive}
        label={t('filters.clear_selected')}
        onClick={() => isActive && onChange(null)}
        variant='contrast-flat'
      />
    </StyledContainer>
  );
};
