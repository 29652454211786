import styled from 'styled-components/macro';

import { StyledShading } from 'components/Shading/styles';

interface StyledGridProps {
  disabled?: boolean;
  templateColumns?: string;
  withFullWidth?: boolean;
}

export const DialogCloseButton = styled.button`
  position: absolute;
  right: -10%;
  top: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  border: none;
  font-size: 20px;
  font-weight: bold;
`;

export const Dialog = styled.dialog`
  height: 100%;
  width: 80%;
  z-index: 100;
  overflow: visible;
  border-radius: 10px;
  border: none;

  &::backdrop {
    background-color: rgba(10, 15, 32, 0.8);
  }
`;

export const Map = styled.div`
  height: 100%;
  width: 100%;
`;

export const StyledGridScrollContainer = styled.div`
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: ${({ theme }) => theme.sizes.xs};
    width: ${({ theme }) => theme.sizes.xs};
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const StyledGrid = styled.div<StyledGridProps>`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: ${({ templateColumns }) => templateColumns};
  max-width: ${({ withFullWidth }) => (withFullWidth ? '100%' : '144rem')};
  width: 100%;
`;

export const StyledTopPanel = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;

  & > span {
    margin: ${({ theme }) => theme.sizes.xs} 0;
    margin-right: ${({ theme }) => theme.sizes.md};
  }
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

export const TableGridShading = styled.div`
  display: flex;
  overflow: hidden;

  & ${StyledShading} {
    display: flex;
    overflow: hidden;
    width: 100%;
  }
`;

export const TableGridAddButton = styled.div`
  margin-left: auto;
`;

export const TableGridLoading = styled.div`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 100;

  svg {
    ${({ theme }) => theme.icon.xxl};
  }
`;
