import { createSelector } from '@reduxjs/toolkit';

import { Checkpoint } from '../newVisit';
import { RevisedActivityTypes } from 'ducks/events/types';
import { RevisedActivityTypesOption } from 'ducks/constants/types';
import { StoreType } from 'store/index';

const changeChecklistToObject = (checkpoints: Checkpoint[] = []) =>
  checkpoints?.reduce((acc, cur) => {
    const key = cur.id.toString();
    return Object.assign(acc, { [key]: cur });
  }, {});

export const companyRolesSelector = createSelector(
  (state: StoreType) => state.constants.companyRoles,
  (roles) => roles?.map(({ name, id }) => ({ label: name, value: id.toString() })),
);

export const constantsSelector = (state: StoreType) => state.constants;

export const checklistsSelector = (state: StoreType) => state.constants.checklists;

export const activeChecklistsSelector = (state: StoreType) => state.constants.activeChecklists;

export const measuresSelector = (state: StoreType) => state.constants.measures;

export const measureTypesSelector = (state: StoreType) => state.constants.measureTypes;

export const industryBranchesSelector = (state: StoreType) => state.constants.industryBranches;

export const checkpointsSelector = (state: StoreType) => state.constants.checkpoints;

export const checkpointsInActiveChecklistSelector = (state: StoreType) =>
  state.constants.checkpointsInActiveChecklist;

const checkpointsObjectSelector = createSelector(
  checkpointsSelector,
  (checkpoints: Checkpoint[]) => changeChecklistToObject(checkpoints) as Record<string, Checkpoint>,
);

export const checkpointSelector = (id: string) =>
  createSelector(
    checkpointsObjectSelector,
    (checkpoints: Record<string, Checkpoint>) => checkpoints[id],
  );
export const activeCheckpointsSelector = createSelector(
  checkpointsInActiveChecklistSelector,
  (checkpoints: Checkpoint[]) =>
    changeChecklistToObject(checkpoints?.filter(({ active }) => active)) as Record<
      string,
      Checkpoint
    >,
);

export const obligatoryChecklistSelector = createSelector(activeChecklistsSelector, (checklist) =>
  checklist?.filter(({ obligatory }) => obligatory),
);

export const additionalChecklistSelector = createSelector(activeChecklistsSelector, (checklist) =>
  checklist?.filter(({ obligatory }) => !obligatory),
);

export const checklistSelector = (checklistId: number) => (state: StoreType) =>
  state.constants.checklists.find(({ id }) => id === checklistId);

export const additionalCheckpointsListSelector = (state: StoreType) =>
  state.constants.checklists
    .filter(
      ({ obligatory, industryId }) => !obligatory && state.users.current?.industryId === industryId,
    )
    .flatMap((checklist) =>
      checklist.checkpoints.map((checkpoint) => ({
        ...checkpoint,
        checklistId: checklist.id,
        checkpointObligatory: checklist.obligatory,
      })),
    );

export const standardCommentCategoriesSelector = (state: StoreType) =>
  state.constants.standardCommentCategories.filter(
    (category) => category.standardComments.length > 0,
  );

export const standardCommentsSelector = (state: StoreType) => state.constants.standardComments;

export const revisedActivityTypesSelector = (state: StoreType): RevisedActivityTypes[] =>
  state.constants.revisedActivityTypes;

export const revisedActivityTypesOptionsSelector = createSelector<
  StoreType,
  RevisedActivityTypes[],
  RevisedActivityTypesOption[]
>(revisedActivityTypesSelector, (types) =>
  types.map(({ name, subTypes }) => ({
    label: name,
    value: name,
    subTypes: subTypes.map(({ name }) => ({ label: name, value: name })),
  })),
);

export const tipsThemesSelector = (state: StoreType) => state.constants.tipsThemes;
