/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentType, useEffect, useMemo, useRef, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { globalHistory } from '@reach/router';
import { useSelector } from 'react-redux';

import { API } from 'api';
import {
  BatchActionsProvider,
  useBatchActionsContext,
} from 'contexts/BatchActionsContext/BatchActionsContext';
import { FiltersContext, useFiltersContext } from 'contexts/FilterContext';
import { MetaType } from 'ducks/types';
import { navigateWithState } from 'utils/navigateWithState';
import { PaginationContextProvider, usePaginationContext } from 'contexts/PaginationContext';
import { Refetcher } from 'hocs/composedTableProviders/Refetcher';
import { SortContextProvider, useSortContext } from 'contexts/SortContext';
import { SpinnerWrapper } from 'hocs/composedTableProviders/styles';
import { StoreType } from 'store';
import { currentUserSelector } from 'ducks/users/selectors';

import { ReactComponent as SpinnerIcon } from 'images/spinner.svg';

export interface TableProvidersProps {
  fetchParams: FetchParams;
}

interface TableProvidersPropTypes {
  fetchFnExtraParams?: (componentProps: any) => object;
  fetchFunction: (arg?: any) => AsyncThunkAction<any, any, object>;
  filtersNamesMapper?: (filterName: string) => string;
  loadResource: string;
  metaUrl: string;
  spyDistrict?: boolean;
  spyQuery?: boolean;
  spyOnlyMine?: boolean;
}

interface Response {
  data: { tableBody: any[] };
  meta: MetaType;
}

export type FetchParams = { [key: string]: string };

const SEARCH_PAGE = 'powersearch';
const DASHBOARD_PAGE = 'dashboardVisits';

export function withTableProviders<ComponentProps>(
  WrappedComponent: ComponentType<ComponentProps & { initialState?: object }>,
  options: TableProvidersPropTypes,
) {
  const {
    fetchFunction,
    filtersNamesMapper,
    loadResource,
    metaUrl,
    fetchFnExtraParams,
    spyDistrict,
    spyQuery,
    spyOnlyMine,
  } = options;

  const HocComponent: ComponentType<any> = (props) => {
    // TODO fix any
    const { initialState, ...rest } = props;
    const user = useSelector(currentUserSelector);
    const [metaObject, setMetaObject] = useState<MetaType | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { query, district, showOnlyMine } = useSelector((state: StoreType) => state.powerSearch);
    const cancelToken = useRef<CancelTokenSource | null>(null);
    const params = useMemo(() => {
      const params: FetchParams = {
        ...(fetchFnExtraParams && fetchFnExtraParams(rest)),
      };
      if (spyOnlyMine && showOnlyMine && user)
        params['filter[rvo_id][values]'] = user.id.toString();
      if (spyDistrict && district.length > 0)
        params['filter[district_names][values]'] = district.join(',');
      if (spyQuery) params.query = query;
      return params;
    }, [spyDistrict && district, spyQuery && query, spyOnlyMine && showOnlyMine]);

    useEffect(() => {
      return () => {
        if (cancelToken.current) {
          cancelToken.current.cancel('Component unmounted');
        }
      };
    }, []);

    useEffect(() => {
      if (cancelToken.current) cancelToken.current.cancel();
      const CancelToken = axios.CancelToken;
      cancelToken.current = CancelToken.source();

      const fetch = async () => {
        try {
          setIsLoading(true);
          const response = await API.get<Response>(metaUrl, {
            params,
            cancelToken: cancelToken.current!.token,
          });
          const newMetaObject = response.data.meta;
          if (response.data.meta.filters.districtNames?.values) {
            newMetaObject.filters.districtNames.values = district;
          }
          setMetaObject(newMetaObject);
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      };

      fetch();
    }, [params]);

    if (isLoading) {
      return (
        <SpinnerWrapper>
          <SpinnerIcon data-testid='composed-table-providers-loading-icon' />
        </SpinnerWrapper>
      );
    }

    if (!metaObject) return null;

    const setNavigation = (newState: object) => {
      if (cancelToken.current) cancelToken.current.cancel();

      if (loadResource === SEARCH_PAGE) {
        navigateWithState('search', newState);
      } else if (loadResource === DASHBOARD_PAGE) {
        navigateWithState('dashboard', newState);
      }
    };

    const setFilters = (filter: string, value: object) => {
      if (cancelToken.current) cancelToken.current.cancel();

      if ([SEARCH_PAGE, DASHBOARD_PAGE].includes(loadResource)) {
        let newState = { ...(cloneDeep(globalHistory.location.state) || {}) };

        if (loadResource === SEARCH_PAGE) {
          newState = {
            ...newState,
            search: {
              ...(globalHistory.location?.state?.search || {}),
              currentPage: 1,
              filters: {
                ...(globalHistory.location?.state?.search?.filters || {}),
                [filter]: value,
              },
            },
          };
        } else if (loadResource === DASHBOARD_PAGE) {
          newState = {
            ...newState,
            dashboard: {
              ...(globalHistory.location?.state?.dashboard || {}),
              currentPage: 1,
              filters: {
                ...(globalHistory.location?.state?.dashboard?.filters || {}),
                [filter]: value,
              },
            },
          };
        }
        setMetaObject({ ...metaObject, page: 1 });

        setNavigation(newState);
      }
    };

    const setPagination = (pageParams: { currentPage?: number; rowsPerPage?: number }) => {
      if ([SEARCH_PAGE, DASHBOARD_PAGE].includes(loadResource)) {
        let newState = { ...(cloneDeep(globalHistory.location.state) || {}) };

        if (loadResource === SEARCH_PAGE) {
          newState = {
            ...newState,
            search: {
              ...(globalHistory.location?.state?.search || {}),
              ...pageParams,
            },
          };
        } else if (loadResource === DASHBOARD_PAGE) {
          newState = {
            ...newState,
            dashboard: {
              ...(globalHistory.location?.state?.dashboard || {}),
              ...pageParams,
            },
          };
        }

        setNavigation(newState);
      }
    };

    return (
      <BatchActionsProvider>
        <SortContextProvider>
          <PaginationContextProvider
            isDashboard={loadResource === DASHBOARD_PAGE}
            isPowerSearch={loadResource === SEARCH_PAGE}
            metaObject={metaObject!}
            setPaginationInLocation={setPagination}
          >
            <FiltersContext
              filtersNamesMapper={filtersNamesMapper}
              initialState={initialState}
              isDashboard={loadResource === DASHBOARD_PAGE}
              isPowerSearch={loadResource === 'powersearch'}
              metaObject={metaObject!}
              // ts-ignore
              setFiltersInLocation={setFilters}
            >
              <Refetcher
                fetchFunction={fetchFunction}
                isLoading={isLoading}
                loadResource={loadResource}
                params={params}
              >
                <WrappedComponent {...rest} fetchParams={params} />
              </Refetcher>
            </FiltersContext>
          </PaginationContextProvider>
        </SortContextProvider>
      </BatchActionsProvider>
    );
  };

  return HocComponent;
}

// ========= HOOK =========

export const useTableContexts = () => {
  const paginationCtx = usePaginationContext();
  const filterCtx = useFiltersContext();
  const batchActionsCtx = useBatchActionsContext();
  const sortCtx = useSortContext();

  const allParams = {
    ...filterCtx.filterParams,
    ...sortCtx.sortParams,
    page_size: paginationCtx.rowsPerPage,
    page: paginationCtx.currentPage,
  };

  return {
    ...paginationCtx,
    ...filterCtx,
    ...batchActionsCtx,
    ...sortCtx,
    allParams,
  };
};
