import styled, { css } from 'styled-components/macro';
import { variants } from './InfoBox';

export const StyledInfoBox = styled.div<{ variant: variants; big: boolean; custom: boolean }>`
  align-items: flex-start;
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  display: flex;
  margin-bottom: ${({ theme }) => theme.sizes.hmd};
  padding: ${({ theme }) => theme.sizes.xs};

  ${({ big }) =>
    big &&
    css`
      padding: ${({ theme }) => theme.borderRadius.md};
    `}

  ${({ custom, variant }) =>
    custom &&
    !['info', 'error'].includes(variant) &&
    css`
      display: block;
    `}

  ${({ variant, theme }) =>
    variant === 'info' &&
    css`
      background-color: ${theme.colors.blue50};
      border-color: ${theme.colors.blue100};

      svg {
        color: ${({ theme }) => theme.colors.blue500};
      }
    `}

  ${({ variant, theme }) =>
    variant === 'error' &&
    css`
      background-color: ${theme.colors.red50};
      border-color: ${theme.colors.red100};

      svg {
        color: ${({ theme }) => theme.colors.red500};
      }
    `}

  ${({ variant, theme }) =>
    variant === 'primary' &&
    css`
      background-color: ${theme.colors.black50};
      border-color: transparent;
      color: ${theme.colors.black300};

      svg {
        color: ${theme.colors.black500};
      }
    `}

  svg {
    ${({ theme }) => theme.icon.md};
    flex: 0 0 2rem;
  }
`;

export const StyledMessage = styled.div`
  flex: 1 1 auto;
  line-height: ${({ theme }) => theme.lineHeight.lg};
  margin-left: ${({ theme }) => theme.sizes.sm};
`;

export const StyledContent = styled.div`
  line-height: ${({ theme }) => theme.lineHeight.lg};
  margin-left: ${({ theme }) => theme.sizes.sm};
`;
