import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { DefaultButton } from 'components/DefaultButton/DefaultButton';

interface ToggleBarPropTypes {
  hideText?: string;
  showText?: string;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  & > button {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export const ToggleBar: FC<ToggleBarPropTypes> = (props) => {
  const { children, hideText, showText } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const hideMsg = hideText || t('common.hide');
  const showMsg = showText || t('common.show_more');

  return (
    <StyledWrapper>
      {isOpen && <div>{children}</div>}
      <DefaultButton
        label={isOpen ? hideMsg : showMsg}
        onClick={() => setIsOpen((x) => !x)}
        size='small'
        type='button'
        variant='tertiary'
      />
    </StyledWrapper>
  );
};
