import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';

import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { PopperDropdown } from 'components/PopperDropdown/PopperDropdown';
import { RadioButtonWithLabel } from 'components/RadioButtonWithLabel/RadioButtonWithLabel';
import { StyledClearButton } from 'components/MultiSelectList/styles';
import { SingleListContainer } from './styles';

import { ReactComponent as ArrowDownIcon } from 'images/arrowDownIcon.svg';

interface FilterState {
  isActive: boolean;
  displayValue: string[];
  value: string | null;
  removeFn?: () => void;
}

interface SingleSelectPropTypes {
  isActive: boolean;
  label: string;
  onChange: (x: FilterState | null) => void;
  options: Array<string>;
  removeFn: () => void;
  translate?: boolean;
  value?: string | null;
}

const SingleSelectFilter = (props: SingleSelectPropTypes) => {
  const { isActive, label, onChange, options, value, translate, removeFn } = props;
  const { t } = useTranslation();

  const ToggleComponent = ({ onClick }: { onClick?: () => void }) => (
    <DefaultButton
      icon={<ArrowDownIcon />}
      iconPosition='right'
      isActive={isActive}
      isDisabled={options.length < 2}
      label={label}
      onClick={onClick}
      variant='filter'
    />
  );

  return (
    <PopperDropdown noPadding ToggleComponent={ToggleComponent}>
      <SingleListContainer>
        {options.map((item) => (
          <RadioButtonWithLabel
            isSelected={item === value}
            key={item}
            onClick={() =>
              item === value
                ? onChange({ isActive: false, displayValue: [], value: null })
                : onChange({ isActive: true, displayValue: [item], value: item, removeFn })
            }
          >
            {translate ? t(`common.${item}`) : item}
          </RadioButtonWithLabel>
        ))}
        <StyledClearButton
          isActive={isActive}
          label={t('common.clear')}
          onClick={() => isActive && removeFn()}
          variant='contrast-flat'
        />
      </SingleListContainer>
    </PopperDropdown>
  );
};

SingleSelectFilter.defaultProps = {
  translate: false,
  value: null,
};

const areEqual = (prevProps: SingleSelectPropTypes, nextProps: SingleSelectPropTypes) =>
  isEqual(prevProps.value, nextProps.value) && isEqual(prevProps.options, nextProps.options);

const PureSingleSelectFilter = memo(SingleSelectFilter, areEqual);

export { PureSingleSelectFilter as SingleSelectFilter };
