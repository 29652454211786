import React from 'react';
import { useTranslation } from 'react-i18next';

export const CompanyNoResultsContent = ({ query }: { query: string }) => {
  const { t } = useTranslation();
  const isOrgNumber = query !== '' && query.length === 9 && !Number.isNaN(Number(query));
  const href = isOrgNumber
    ? `https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${query.trim()}`
    : `https://w2.brreg.no/enhet/sok/treffliste.jsp?navn=${query.trim()}&orgform=0&fylke=0&kommune=0`;

  return (
    <>
      {t('power_search.companies.no_results')} {t(`power_search.companies.try_searching`)}{' '}
      <a href={href} rel='noopener noreferrer' target='_blank'>
        Enhetsregisteret
      </a>
    </>
  );
};
