import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { TableGrid } from 'components/Table/TableGrid/TableGrid';
import { ContactsSearchSelect } from './../shared/ContactsSearchSelect';
import {
  CanConnectToType,
  NewVisitContactModal,
} from 'dialogs/newVisits/Contacts/NewVisitContactModal';
import { useModal } from 'components/Modal/Modal';
import {
  createStep2Contact,
  NewVisitContact,
  newVisitContactsSelector,
  newVisitWorkplaceSelector,
  removeStep2Contact,
  setStep2Contact,
  visitIdSelector,
} from 'ducks/newVisit';
import { contactsComponentsMapStep2 } from 'containers/newVisit/shared/tableMappers/contacts';
import { useAppDispatch } from 'store';
import { SearchContactsEntity } from 'ducks/powerSearch/contacts/types';
import { ContentContainer } from 'styles/utils';

interface ContactsProps {
  isDisabled?: boolean;
}

export const Contacts = (props: ContactsProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { setModalComponent, showModal, setShowHeader } = useModal();
  const workplace = useSelector(newVisitWorkplaceSelector)!;
  const contacts = useSelector(newVisitContactsSelector);
  const visitId = useSelector(visitIdSelector);
  const hasOwnerCompany = !!workplace?.proprietorshipId;

  const actionHandlers = {
    delete_from_visit: (item: NewVisitContact) =>
      !item.loading &&
      dispatch(
        removeStep2Contact({ ...item, visit_id: item.visitId, workplace_id: item.workplaceId! }),
      ),
    edit: (item: NewVisitContact) => {
      const { origin, id, companyId, workplaceId } = item;
      setModalComponent(
        <NewVisitContactModal
          canConnectTo={[item.origin]}
          contact={item}
          defaultConectTo={item.origin}
          submitFn={({ role, ...restValues }) => {
            dispatch(
              setStep2Contact({
                ...restValues,
                company_id: companyId,
                id,
                origin,
                role_id: role?.value,
                visit_id: visitId,
                workplace_id: workplaceId!,
              }),
            );
          }}
        />,
      );
      setShowHeader(false);
      showModal();
    },
  };

  const createContact = useCallback(() => {
    const canConnectTo = ['workplace'];
    if (hasOwnerCompany) canConnectTo.push('company');
    setModalComponent(
      <NewVisitContactModal
        canConnectTo={canConnectTo as CanConnectToType}
        defaultConectTo={hasOwnerCompany ? 'company' : 'workplace'}
        submitFn={(values, connectTo) => {
          dispatch(
            createStep2Contact({
              ...values,
              role_id: values.role?.value,
              company_id: connectTo === 'company' ? workplace!.proprietorshipId! : undefined,
              origin: connectTo,
              visit_id: visitId,
              workplace_id: workplace!.workplaceId,
            }),
          );
        }}
      />,
    );
    setShowHeader(false);
    showModal();
  }, [setModalComponent, showModal, workplace, visitId]);

  const addContactHandle = useCallback(
    ({ id, origin }: SearchContactsEntity) =>
      dispatch(
        setStep2Contact({
          id,
          include_in_report: false,
          origin,
          receive_visit_report: false,
          visit_id: visitId,
          workplace_id: workplace!.workplaceId!,
        }),
      ),
    [dispatch, workplace, visitId],
  );

  return (
    <ContentContainer margin='2.4rem auto 0' maxWidth='72rem'>
      {contacts && contacts?.length > 0 && (
        <TableGrid
          actionHandlers={actionHandlers}
          bodyData={contacts}
          columnsOrder={[
            'name',
            'phone',
            'role',
            'email',
            'obligatory_training',
            'show_in_report',
            'receive_visit_report',
          ]}
          componentsMap={contactsComponentsMapStep2}
          noResultsMessage=''
        />
      )}
      <ContactsSearchSelect
        companyId={workplace?.proprietorshipId}
        isDisabled={props.isDisabled}
        newContactHandler={createContact}
        onSelect={addContactHandle}
      />
    </ContentContainer>
  );
};
