import { StyledLabel } from 'components/Forms/styles';
import styled from 'styled-components';

export const StyledInputlikeContent = styled.div`
  background-color: ${({ theme }) => theme.colors.black50};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: 1px solid ${({ theme }) => theme.colors.black100};
  display: inline-block;
  font-weight: 500;
  line-height: ${({ theme }) => theme.lineHeight.lg};
  padding: ${({ theme }) => theme.sizes.xxs};
  white-space: nowrap;
`;

export const StyledInputlikeLabel = styled(StyledLabel)`
  cursor: default;
`;
