import React, { useMemo } from 'react';

import { Card, StyledCardDivider, StyledCardHeader } from 'components/Card/Card';
import { CompanyEntity } from 'ducks/companies/types';
import { TableGrid } from 'components/Table/TableGrid/TableGrid';
import { CapsLabel } from 'components/CapsLabel/CapsLabel';
import { Margin } from 'styles/utils';
import i18n from 'i18n';

interface BasicDataRegisteredPaymentsPropTypes {
  company: CompanyEntity;
}

const t = (x: string) => i18n.t(x);

const componentsMap = {
  year: {
    component: ({ year }: { year: string }) => <>{year || '-'}</>,
    size: 'min-content',
  },
  payment: {
    component: ({ payment }: { payment: string }) => (
      <Margin left='auto'>{`${payment} NOK` || '-'}</Margin>
    ),
    size: 'auto',
    headerComponent: () => (
      <Margin left='auto'>
        <CapsLabel>{t('table.columns.payment')}</CapsLabel>
      </Margin>
    ),
  },
};

export const BasicDataRegisteredPayments = ({ company }: BasicDataRegisteredPaymentsPropTypes) => {
  const { payments } = company;
  const tableBody = useMemo(
    () =>
      payments.map(({ year, amount }, id) => ({
        year,
        payment: amount,
        id: String(id),
      })),
    [payments],
  );
  const columnsOrder: ('year' | 'payment')[] = ['year', 'payment'];

  return (
    <Card>
      <StyledCardHeader>{t('company_details.basic-data.registered_payments')}</StyledCardHeader>
      <StyledCardDivider fullWidth />
      <TableGrid
        bodyData={tableBody}
        columnsOrder={columnsOrder}
        componentsMap={componentsMap}
        noResultsMessage={t('company_details.basic-data.no_registered_payments')}
        withCheckbox={false}
      />
    </Card>
  );
};
