import { createSlice } from '@reduxjs/toolkit';

import { fetchEmbededUrl } from './actions';
import { Report } from './types';

const reportsSlice = createSlice({
  name: 'reports',
  initialState: null as
    | Report[]
    | Error
    | {
        error: string;
      }
    | null,
  reducers: {
    resetReport: () => null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEmbededUrl.fulfilled, (state, { payload }) => {
      return payload;
    });
  },
});

export const { reducer: reportsReducer, actions } = reportsSlice;
export const { resetReport } = actions;
