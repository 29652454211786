import { createSlice } from '@reduxjs/toolkit';

import { searchPriorNotifications } from './actions';
import { SearchPriorNotificationsState } from './types';

const initialState: SearchPriorNotificationsState = {
  data: {
    tableBody: [],
  },
  error: false,
  meta: {
    columnsOrder: [],
    filters: {},
    page: 1,
    pageSize: 10,
    totalCount: null,
    totalPages: 1,
  },
};

const priorNotificationsSlice = createSlice({
  name: 'search/priorNotificationss',
  initialState,
  reducers: {
    resetPriorNotificationsSearchResults: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(searchPriorNotifications.rejected, (state) => ({
      ...state,
      error: true,
    }));
    builder.addCase(searchPriorNotifications.fulfilled, (state, { payload }) => ({
      ...payload,
      error: false,
    }));
  },
});

const {
  reducer: priorNotificationsSearchReducer,
  actions: { resetPriorNotificationsSearchResults },
} = priorNotificationsSlice;

export { priorNotificationsSearchReducer, resetPriorNotificationsSearchResults };
