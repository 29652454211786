import React, { ComponentType } from 'react';
import { FieldRenderProps } from 'react-final-form';

import {
  Attachments,
  AttachmentsProps,
  ExtendFile,
} from 'components/Forms/Attachments/Attachments';

export const AttachmentField: ComponentType<AttachmentsProps & FieldRenderProps<ExtendFile[]>> = ({
  input,
  ...rest
}) => {
  return <Attachments {...rest} onChange={input.onChange} value={input.value || []} />;
};
