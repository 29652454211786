import React, { useCallback } from 'react';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  StyledModalButtonsWrapper,
  StyledModalHeader,
  StyledModalItemToRemove,
  StyledModalMessage,
  useModal,
} from 'components/Modal/Modal';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useLoadingContext } from 'contexts/LoadingContext';
import { formatDate } from 'utils/date';
import { deleteCompanyVisit } from 'ducks/visits/actions';
import { DashboardVisitEntity } from 'ducks/dashboard/types';

interface DeleteVisitsModalPropTypes<T> {
  entity: T;
  actionCallback?: () => void;
}

export const DeleteVisitsModal = <T extends DashboardVisitEntity>({
  entity,
  actionCallback,
}: DeleteVisitsModalPropTypes<T>) => {
  const { t } = useTranslation();
  const { typeOfVisit, date, companyName, address, workplace, siblingsNames } = entity;
  const { setLoading, isLoading, setLoaded } = useLoadingContext();
  const { hideModal } = useModal();
  const dispatch = useDispatch();
  const header = t(
    `company_details.visits.${
      typeOfVisit === 'light' || !workplace ? 'delete_visit_report' : 'remove_company_from_visit'
    }`,
  );
  const message = t(
    `company_details.visits.${
      typeOfVisit === 'light' || !workplace
        ? 'data_lose'
        : siblingsNames && siblingsNames.length > 1
        ? 'data_company_lose'
        : 'delete_whole_visit'
    }`,
  );

  const deleteVisit = useCallback(
    async ({ companyVisitId }: DashboardVisitEntity) => {
      setLoading('delete-visit');
      await dispatch(deleteCompanyVisit(companyVisitId));
      setLoaded('delete-visit');
      actionCallback && actionCallback();
      hideModal();
    },
    [dispatch, hideModal, deleteCompanyVisit],
  );

  return (
    <>
      <StyledModalHeader>{header}</StyledModalHeader>
      {date && (
        <>
          <StyledModalItemToRemove>
            {`${formatDate(date.value)} - ${companyName ? companyName : ''}`}
          </StyledModalItemToRemove>
          <StyledModalItemToRemove>
            {workplace ? `${workplace.value} - ${address}` : address}
          </StyledModalItemToRemove>
        </>
      )}
      <StyledModalMessage>{message}</StyledModalMessage>
      <StyledModalButtonsWrapper>
        <DefaultButton
          label={capitalize(t('common.cancel'))}
          onClick={hideModal}
          variant='secondary'
        />
        <DefaultButton
          isDisabled={isLoading('delete-visit')}
          label={
            siblingsNames && siblingsNames.length > 1
              ? capitalize(t('company_details.visits.delete_company'))
              : capitalize(t('company_details.visits.delete'))
          }
          onClick={() => deleteVisit(entity)}
          variant='danger'
        />
      </StyledModalButtonsWrapper>
    </>
  );
};
