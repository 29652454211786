import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize, debounce } from 'lodash';
import axios, { CancelTokenSource } from 'axios';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';

import { SearchCompanyEntityResponse } from 'ducks/powerSearch/company/types';
import { StyledModalHeader, useModal } from 'components/Modal/Modal';
import { CreateCompanyForm } from 'containers/CompanyCreate/CreateCompanyForm';
import { ContentContainer } from 'styles/utils';
import { SearchSelectListItemStandard } from 'components/Forms/SearchSelect/styles';
import {
  StyledCompanySearchWrapper,
  WorkplaceListItem,
} from 'containers/newVisit/OrdinaryVisit/styles';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useScreenSizeContext } from 'contexts/ScreenSizeContext';
import { fetchCompany } from 'ducks/companies/actions';
import { StatusBadge } from 'components/StatusBadge/StatusBadge';
import {
  newVisitAddressSelector,
  newVisitCompanyVisitsSelector,
  searchForCompaniesNewVisit,
  visitIdSelector,
} from 'ducks/newVisit';
import { Text } from 'components/Text/Text';
import { CompanyPreviewModal } from 'containers/newVisit/shared/CompanyPreviewModal';
import { WorkplacePickCompanyAddress } from 'containers/newVisit/OrdinaryVisit/Step1/WorkplacePickCompanyAddress';
import { ButtonsPanel } from 'containers/newVisit/shared/ButtonsPanel/ButtonsPanel';
import { CompanyEntity } from 'ducks/companies/types';
import { ArrowRightBigIcon, EyeIcon, PlusIcon, SpinnerIcon } from 'utils/iconsMap';
import { chosenDistrictSelector } from 'ducks/powerSearch/selectors';
import { SearchSelectWithCheckbox } from 'components/Forms/SearchSelectWithCheckbox/SearchSelectWithCheckbox';
import { newVisitFullAddressSelector } from '../../../../ducks/newVisit/selectors';
import { SpinnerWrapper } from 'hocs/composedTableProviders/styles';

export const SelectCompanyAddress = () => {
  const companyVisit = useSelector(newVisitCompanyVisitsSelector);
  const visitId = useSelector(visitIdSelector);
  const hasAddress = !!useSelector(newVisitAddressSelector);
  const addressObject = useSelector(newVisitFullAddressSelector);
  const isOneTimeAddress = addressObject?.kind === 60 ? true : false;
  const chosenDistrict = useSelector(chosenDistrictSelector);
  const [listItems, setListItems] = useState<ReactElement[]>([]);
  const [searchInactive, setSearchInactive] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<CompanyEntity | null>(null);
  const { sm } = useScreenSizeContext();
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);

  const cancelToken = useRef<CancelTokenSource | null>(null);
  const { setModalComponent, showModal, setCustomStyles, setShowHeader } = useModal();

  const onSearchHandle = async (text: string) => {
    if (text.length === 0) setListItems([]);
    else
      debouncedSearchCompany.current({
        query: text,
        company: {
          'filter[status][values]': searchInactive ? ['active', 'inactive'] : 'active',
        },
        page_size: 500,
      });
  };

  const fetchAndSetCompany = async (companyId: string) => {
    if (companyId) {
      setIsFetching(true);
      const company = await fetchCompany(companyId);
      setIsFetching(false);
      setSelectedCompany(company);
    }
  };

  useEffect(() => {
    const companyId = window.history.state?.companyId;
    fetchAndSetCompany(companyId);
  }, []);

  useEffect(() => {
    const cv = companyVisit[0];
    const companyId = cv?.companyId;
    fetchAndSetCompany(companyId);
  }, [companyVisit]);

  const companyPreviewModalHandle = async (companyId: string) => {
    setIsFetching(true);
    const company = await fetchCompany(companyId);
    setIsFetching(false);
    setModalComponent(<CompanyPreviewModal company={company} />);
    setShowHeader(true);
    showModal();
  };

  const companyModalHandle = () => {
    setCustomStyles({ maxWidth: '85rem' });
    setModalComponent(
      <>
        <StyledModalHeader>{t('company_details.add_new_company')}</StyledModalHeader>
        <CreateCompanyForm callback={setSelectedCompany} numberKnown />
      </>,
    );
    setShowHeader(true);
    showModal();
  };

  const debouncedSearchCompany = useRef(
    debounce(async (params: { query: string; company: object; page_size: number }) => {
      cancelToken.current?.cancel();
      const CancelToken = axios.CancelToken;
      cancelToken.current = CancelToken.source();

      try {
        const response = await searchForCompaniesNewVisit(params, cancelToken.current);
        const data = response?.data;

        if (data && data?.tableBody?.length > 0) {
          setListItems(
            data.tableBody.map((company: SearchCompanyEntityResponse) => (
              <SearchSelectListItemStandard
                key={company.companyId}
                onClick={() => fetchAndSetCompany(company.companyId)}
              >
                <WorkplaceListItem>
                  <div>
                    <Text>
                      <StatusBadge variant={company.status === 'active' ? 'success' : 'inactive'}>
                        {capitalize(
                          t(
                            `company_details.company_is_${
                              company.status === 'active' ? 'active' : 'inactive'
                            }`,
                          ),
                        )}
                      </StatusBadge>
                      {company.name}
                    </Text>
                    {company?.municipalityName && <Text size='xs'>{company.municipalityName}</Text>}
                    <Text color='black500' size='xxs'>
                      {t('forms.org_number')}: {company.orgNo}
                    </Text>
                  </div>
                  <ContentContainer display='flex' margin='auto 1.2rem auto auto'>
                    <DefaultButton
                      icon={<EyeIcon />}
                      onClick={(e) => {
                        e.stopPropagation();
                        companyPreviewModalHandle(company.companyId);
                      }}
                      size='small'
                      variant='tertiary'
                    />
                  </ContentContainer>
                  <ArrowRightBigIcon />
                </WorkplaceListItem>
              </SearchSelectListItemStandard>
            )),
          );
        } else {
          setListItems([
            <SearchSelectListItemStandard key='no-results'>
              {t('new_visits.no_results_message')}
            </SearchSelectListItemStandard>,
          ]);
        }
      } catch (err) {}
    }, 350),
  );

  return (
    <>
      <ContentContainer margin='1.2rem auto' maxWidth='72rem'>
        {!hasAddress && (
          <StyledCompanySearchWrapper>
            <SearchSelectWithCheckbox
              autoFocus
              closeOnSelect
              isChecked={searchInactive}
              label={t('new_visits.include_inactive')}
              listItems={listItems}
              onSearch={onSearchHandle}
              placeholder={t('new_visits.find_company')}
              setSearchInactive={() => setSearchInactive((x) => !x)}
              size='small'
              vsize='small'
            />
            <DefaultButton
              icon={<PlusIcon />}
              label={sm ? t('company_details.add_new_company') : undefined}
              onClick={companyModalHandle}
              size='small'
            />
          </StyledCompanySearchWrapper>
        )}

        {isFetching && (
          <div style={{ marginTop: 50 }}>
            <SpinnerWrapper>
              <SpinnerIcon />
            </SpinnerWrapper>
          </div>
        )}

        {selectedCompany && (
          <WorkplacePickCompanyAddress
            hasAddress={hasAddress}
            isOneTimeAddress={isOneTimeAddress}
            key={selectedCompany?.id}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
          />
        )}
      </ContentContainer>
      {hasAddress && (
        <ButtonsPanel
          isButtonsActive
          mainButtonHandler={() => navigate('./companies', { state: { ...window.history.state } })}
          mainButtonLabel={t('common.next_step')}
          visitId={String(visitId)}
        />
      )}
    </>
  );
};
