import { API } from 'api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Report } from './types';

export const fetchEmbededUrl = createAsyncThunk('reports/fetchEmbededUrl', async () => {
  try {
    const response = await API.get(`analytics`);
    return response.data as Report[];
  } catch (e) {
    console.log(e);
    return [];
  }
});
