import styled from 'styled-components/macro';
import Calendar from 'react-calendar';

export const StyledCalendarWrapper = styled.div`
  background-color: white;
  max-width: 100%;
  width: 33rem;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.sizes.md};

  button {
    margin-left: ${({ theme }) => theme.sizes.hmd};
  }
`;

export const StyledCalendar = styled(Calendar)`
  .react-calendar {
    &__navigation {
      ${({ theme }) => theme.fontSizes.xl};
      margin-bottom: ${({ theme }) => theme.sizes.md};

      button {
        ${({ theme }) => theme.icon.lg};

        &:disabled svg path {
          fill: ${({ theme }) => theme.colors.black50};
        }
      }
    }

    &__month-view {
      &__weekdays {
        ${({ theme }) => theme.fontSizes.xs};
        margin: ${({ theme }) => theme.sizes.xs} 0;
        text-align: center;
        border-bottom: 1px solid ${({ theme }) => theme.colors.black100};

        &__weekday {
          color: ${({ theme }) => theme.colors.black500};
        }

        abbr[title] {
          border-bottom: none !important;
          cursor: default !important;
          text-decoration: none !important;
        }
      }

      &__days {
        &__day {
          &--neighboringMonth {
            color: ${({ theme }) => theme.colors.black300};
          }
        }
      }
    }

    &__tile {
      background-color: transparent;
      max-width: 100%;
      padding: ${({ theme }) => theme.sizes.hmd};
      position: relative;
      text-align: center;
      z-index: 1;

      &:disabled {
        color: ${({ theme }) => theme.colors.black300};
        cursor: not-allowed;

        &:hover:after {
          background-color: white;
        }
      }

      &:hover:after,
      &:focus:after {
        ${({ theme }) => theme.icon.xl};
        background-color: ${({ theme }) => theme.colors.blue100};
        border-radius: 50%;
        content: '';
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }

      &--active {
        color: white;

        &:after {
          ${({ theme }) => theme.icon.xl};
          background-color: ${({ theme }) => theme.colors.blue500};
          border-radius: 50%;
          content: '';
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
        }

        &:hover:after,
        &:focus:after {
          background-color: ${({ theme }) => theme.colors.blue400};
        }
      }
    }

    &__year-view {
      .react-calendar__tile {
        &:hover:after {
          border-radius: 0;
          width: 100%;
        }
      }
    }
  }
`;
