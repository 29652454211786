import React from 'react';
import { navigate } from '@reach/router';

import i18n from 'i18n';
import { contactsComponentsMap } from 'containers/companyDetails/Contacts/tableComponentsMap';
import { SearchContactsEntity } from 'ducks/powerSearch/contacts/types';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { StyledNoWrapCellContent, StyledTruncatedContent } from 'components/Table/TableRow/styles';
import { StyledLink } from 'containers/companyDetails/BasicData/styles';

const t = (x: string) => i18n.t(x);

export const contactsTableMapper = {
  ...contactsComponentsMap,
  company_aka: {
    component: ({ companyAka }: SearchContactsEntity) => (
      <StyledNoWrapCellContent>{companyAka || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  company_name: {
    component: ({ companyName, companyId }: SearchContactsEntity) =>
      companyName ? (
        <Tooltip label={companyName?.length > 24 ? companyName : ''}>
          <StyledTruncatedContent>
            <StyledLink
              as='button'
              onClick={() =>
                navigate(`/redesigned/companies/${companyId}/basic-data`, {
                  state: { ...window.history.state, previousPathname: window.location.pathname },
                })
              }
            >
              {companyName}
            </StyledLink>
          </StyledTruncatedContent>
        </Tooltip>
      ) : (
        <StyledNoWrapCellContent>-</StyledNoWrapCellContent>
      ),
    size: 'minmax(24rem, max-content)',
  },
  origin: {
    component: ({ origin }: SearchContactsEntity) => (
      <StyledNoWrapCellContent>{t(`filters.${origin}`)}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
};

export type ContactTableMapper = typeof contactsTableMapper;
