import React, { useEffect, useMemo, useState } from 'react';

export const SortContext = React.createContext({
  field: '',
  order: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  changeSort: (x) => {},
  hasProvider: false,
  sortParams: {},
});

export const SortContextProvider = ({ children }) => {
  const storageState = localStorage.getItem(`sort-${window.location.pathname}`);
  const initialState = storageState ? JSON.parse(storageState) : { field: '', order: '' };
  const [columnOrder, setColumnOrder] = useState(initialState);

  const nextSortType = (sortType) => {
    if (sortType === '') return 'asc';
    if (sortType === 'asc') return 'desc';
    return '';
  };

  const handleSort = (nextField) => {
    if (nextField === columnOrder.field) {
      setColumnOrder({ ...columnOrder, order: nextSortType(columnOrder.order) });
    } else {
      setColumnOrder({ field: nextField, order: 'asc' });
    }
  };

  const sortParams = useMemo(
    () => (columnOrder.order ? { [`sort[${columnOrder.field}]`]: columnOrder.order } : {}),
    [columnOrder],
  );

  useEffect(() => {
    localStorage.setItem(`sort-${window.location.pathname}`, JSON.stringify(columnOrder));
  }, [columnOrder]);

  return (
    <SortContext.Provider
      value={{ ...columnOrder, changeSort: handleSort, hasProvider: true, sortParams }}
    >
      {children}
    </SortContext.Provider>
  );
};

export const useSortContext = () => React.useContext(SortContext);
