import * as yup from 'yup';

export const companyVisitDetailsSchema = yup.object({
  industryBranchIds: yup.mixed().when('$isHrrBranch', {
    is: true,
    then: yup.array().of(yup.string().required()).required(),
    otherwise: undefined,
  }),
  numberOfEmployees: yup.number().min(1).required(),
  companyRoleIds: yup.array().of(yup.string()).required(),
});

export const observationSchema = yup.object({
  checkpointId: yup.string().required(),
  comment: yup.string().nullable(),
  companyVisitId: yup.string().required(),
  deadline: yup.string(),
  fulfilled: yup.string().nullable(),
  measureId: yup.string(),
  measureTypes: yup.object(),
  newDeadline: yup.string(),
  quantity: yup.string(),
});

export const companyVisitSchema = yup
  .object({
    observations: yup.array().of(observationSchema),
  })
  .concat(companyVisitDetailsSchema.required());
