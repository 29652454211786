import React, { ComponentType, createContext, FC, useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import { useOnClickOutside } from 'utils/hooks';
import { StyledDropdownContainer, StyledDropdownWrapper } from 'components/PopperDropdown/styles';
import { popperOffsetModifier } from 'utils';

const DropdownContext = createContext<{
  isOpen: boolean;
  setIsOpen: ((state: boolean) => void) | Function;
}>({
  isOpen: false,
  setIsOpen: () => false,
});

interface PopperDropdownPropTypes {
  closeOnClickOutside?: boolean;
  noPadding?: boolean;
  ToggleComponent: ComponentType<{ onClick?: () => void }>;
  toggleProps?: object;
  className?: string;
}

const POPPER_ROOT_ELEMENT = document.querySelector('body');

export const PopperDropdown: FC<PopperDropdownPropTypes> = ({
  children,
  closeOnClickOutside = true,
  noPadding = false,
  ToggleComponent,
  className,
  toggleProps,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      popperOffsetModifier,
      {
        name: 'flip',
        options: { fallbackPlacements: ['top-start'] },
      },
    ],
  });

  useOnClickOutside({ current: popperElement }, () =>
    closeOnClickOutside ? setIsOpen(false) : undefined,
  );

  return (
    <StyledDropdownWrapper className={className}>
      <div ref={setReferenceElement}>
        <ToggleComponent onClick={() => setIsOpen((val) => !val)} {...toggleProps} />
      </div>

      {isOpen &&
        createPortal(
          <StyledDropdownContainer
            data-testid='dropdown-inner'
            noPadding={noPadding}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <DropdownContext.Provider value={{ isOpen, setIsOpen }}>
              {children}
            </DropdownContext.Provider>
          </StyledDropdownContainer>,
          POPPER_ROOT_ELEMENT!,
        )}
    </StyledDropdownWrapper>
  );
};

export const useDropdownContext = () => useContext(DropdownContext);
