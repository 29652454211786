import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled from 'styled-components';
import { StyledError } from '../styles';

const Wrapper = styled.div`
  position: relative;
`;

const AbsoluteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: -22px;
`;

const StyledTimeInput = styled.input<{ isError: boolean }>`
  &[type='time'] {
    position: relative;
    height: 41px;
    width: 90px;
    padding-left: 5px;
    overflow: hidden;
    border: 1px solid #9fa1a8;
    border-radius: 0.4rem;
    border-color: ${(p) => p.isError && '#CC2506'};

    &:disabled {
      background-color: #f4f4f5;
      border-color: #dfe0e2;
      color: #9fa1a8;
    }

    &::-webkit-calendar-picker-indicator {
      background-size: 20px;
      margin-right: 10px;
      margin-bottom: 5px;
      opacity: 0.5;
      cursor: pointer;
    }
  }
`;

export const TimePickerField = (props: FieldRenderProps<string>) => {
  const { input, meta } = props;

  const isError = meta.touched && meta.error;

  return (
    <Wrapper>
      <StyledTimeInput {...props} {...input} isError={isError} type='time' />
      <AbsoluteWrapper>{isError && <StyledError>{meta.error}</StyledError>}</AbsoluteWrapper>
    </Wrapper>
  );
};
