import React, { FC } from 'react';
import { Redirect, RouteComponentProps, WindowLocation } from '@reach/router';
import { setPassword, validatePassword } from './helpers';
import {
  StyledAuthForm,
  StyledAuthFormWrapper,
  StyledAuthInput,
  StyledPasswordPolicyList,
  StyledPasswordPolicyListItem,
  StyledSubmitButton,
} from './styles';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { ChallengeResponse } from './types';
interface NewPasswordProps
  extends RouteComponentProps<{ location: { state: { challengeResponse: ChallengeResponse } } }> {
  location: WindowLocation<{ challengeResponse: ChallengeResponse }>;
}

export const NewPassword: FC<NewPasswordProps> = (props) => {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  if (props.location.state === null) {
    return <Redirect from='' to='../sign_in' noThrow />;
  }

  const { challengeName, session, cognitoUserId } = props.location.state.challengeResponse;

  if (!challengeName || !session || !cognitoUserId)
    return <Redirect from='' to='../sign_in' noThrow />;

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error(t('auth.error.mismatched_passwords'));
      return;
    } else if (validatePassword(newPassword).error) {
      toast.error(t(`auth.error.${validatePassword(newPassword).error}`));
      return;
    }

    try {
      const response = await setPassword(challengeName, newPassword, session, cognitoUserId);
      if (response.error) {
        toast.error(response.error);
        return;
      }
      props.navigate && props.navigate('../sign_in');
    } catch (err) {
      console.log(err);
      toast.error(err as string);
    }
  };

  const handleNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <StyledAuthFormWrapper>
      <StyledAuthForm onSubmit={onSubmit}>
        <StyledPasswordPolicyList>
          <b>{t('auth.instructions.password_must')}</b>
          <StyledPasswordPolicyListItem>
            {t('auth.instructions.password_length')}
          </StyledPasswordPolicyListItem>
          <StyledPasswordPolicyListItem>
            {t('auth.instructions.password_uppercase')}
          </StyledPasswordPolicyListItem>
          <StyledPasswordPolicyListItem>
            {t('auth.instructions.password_symbol')}
          </StyledPasswordPolicyListItem>
        </StyledPasswordPolicyList>
        <StyledAuthInput
          autoFocus
          placeholder={t('auth.new_password')}
          type='password'
          onChange={handleNewPassword}
        />
        <StyledAuthInput
          placeholder={t('auth.confirm_password')}
          type='password'
          onChange={handleConfirmPassword}
        />
        <StyledSubmitButton type='submit'>{t('auth.set_new_password')}</StyledSubmitButton>
      </StyledAuthForm>
    </StyledAuthFormWrapper>
  );
};
