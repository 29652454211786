import { useEffect, useRef } from 'react';

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    window.addEventListener('mousedown', listener);
    window.addEventListener('touchstart', listener);

    return () => {
      window.removeEventListener('mousedown', listener);
      window.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const useUnmount = (effect: (deps) => void, deps): void => {
  const tracked = useRef();

  useEffect(() => {
    tracked.current = deps;
  }, deps);

  useEffect(() => {
    return () => {
      effect(tracked.current);
    };
  }, []);

  return;
};
