import React, { ReactElement } from 'react';

import { BulletVariants, StyledBulletLabel } from './styles';

interface BulletLabelPropTypes {
  variant?: BulletVariants;
  children: ReactElement | string;
}
const BulletLabel = ({ variant, children }: BulletLabelPropTypes) => (
  <StyledBulletLabel variant={variant}>{children}</StyledBulletLabel>
);

export { BulletLabel };
