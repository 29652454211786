import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CompanyVisit, newVisitWorkplaceSelector } from 'ducks/newVisit';
import { CompanyVisitTab } from './CompanyVisitTab';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { PlusIcon } from 'utils/iconsMap';
import { Shading } from 'components/Shading/Shading';
import { StyledCompanyVisitsTabsBorder, StyledCompanyVisitsTabsWrap } from './styles';
import { TabContainer } from 'components/Tabs/styles';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { currentUserSelector } from 'ducks/users/selectors';

interface CompanyVisitsTabsPropTypes {
  companyVisits: CompanyVisit[];
  handleClick: (cv: CompanyVisit | 'new') => void;
  selectedCompanyVisit: CompanyVisit | 'new';
}

interface isActiveBorderProps {
  companyVisits: CompanyVisit[];
  index: number;
  selectedCompanyVisit: CompanyVisitsTabsPropTypes['selectedCompanyVisit'];
}

export const isActiveWithBorder = ({
  companyVisits,
  index,
  selectedCompanyVisit,
}: isActiveBorderProps) => {
  const isNotNew = selectedCompanyVisit !== 'new';
  const tabIsActive =
    (isNotNew && companyVisits[index]?.id === (selectedCompanyVisit as CompanyVisit)?.id) || false;
  const previousIsActive =
    companyVisits.length > 0 &&
    isNotNew &&
    companyVisits[index - 1]?.id === (selectedCompanyVisit as CompanyVisit)?.id;
  const nextIsActive =
    companyVisits.length > 0 &&
    isNotNew &&
    companyVisits[index + 1]?.id === (selectedCompanyVisit as CompanyVisit)?.id;
  const borderLeft = tabIsActive || (!tabIsActive && previousIsActive) || index === 0 || false;
  const borderRight =
    tabIsActive || (!tabIsActive && nextIsActive) || index === companyVisits.length - 1 || false;

  return { borderLeft, borderRight, tabIsActive };
};

export const CompanyVisitsTabs = ({
  companyVisits,
  handleClick,
  selectedCompanyVisit,
}: CompanyVisitsTabsPropTypes) => {
  const [shading, setShading] = useState({ showLeft: false, showRight: false });
  const workplace = useSelector(newVisitWorkplaceSelector);
  const user = useSelector(currentUserSelector);
  const gridRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const calculateShading = () => {
    if (!gridRef.current) return;
    const {
      current: { scrollLeft, scrollWidth, offsetWidth },
    } = gridRef;
    const showLeft = scrollLeft > 0;
    const showRight = scrollLeft + offsetWidth < scrollWidth;
    if (showLeft !== shading.showLeft || showRight !== shading.showRight) {
      setShading({ showLeft, showRight });
    }
  };
  const userCanCreateNewVisit = useMemo(
    () =>
      workplace &&
      selectedCompanyVisit !== 'new' &&
      selectedCompanyVisit.industryId === user?.industryId,
    [workplace, selectedCompanyVisit, user],
  );

  useEffect(calculateShading, [gridRef, companyVisits]);

  if (!companyVisits.length) {
    return null;
  }

  return (
    <StyledCompanyVisitsTabsWrap>
      <StyledCompanyVisitsTabsBorder>
        <Shading {...shading}>
          <TabContainer onScroll={calculateShading} ref={gridRef} withScroll>
            {companyVisits.map((companyVisit, index) => {
              const { borderLeft, borderRight, tabIsActive } = isActiveWithBorder({
                companyVisits,
                index,
                selectedCompanyVisit,
              });

              return (
                <CompanyVisitTab
                  borderLeft={borderLeft}
                  borderRight={borderRight}
                  companyVisit={companyVisit}
                  handleClick={handleClick}
                  key={companyVisit.id}
                  selectedCompanyVisit={selectedCompanyVisit}
                  tabIsActive={tabIsActive}
                />
              );
            })}
          </TabContainer>
        </Shading>
      </StyledCompanyVisitsTabsBorder>

      {userCanCreateNewVisit && (
        <Tooltip className='company-visit-tabs-add-new' label={t(`new_visits.add_company`)}>
          <DefaultButton
            icon={<PlusIcon />}
            onClick={() => handleClick('new')}
            size='small'
            testid='company-visit-tabs-add-new'
            variant='gray'
          />
        </Tooltip>
      )}
    </StyledCompanyVisitsTabsWrap>
  );
};
