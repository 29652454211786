import React from 'react';
import i18n from 'i18n';
import { StyledNoWrapCellContent } from 'components/Table/TableRow/styles';
import { StatusBadge } from 'components/StatusBadge/StatusBadge';
import { CompanyVisitReport, ReportRecipient } from 'ducks/newVisit';

const t = (x: string) => i18n.t(x);

export const reportColumnsOrder: ('version' | 'date' | 'status')[] = ['version', 'date', 'status'];
export const recipientsColumnsOrder: ('role' | 'name' | 'email' | 'date' | 'status')[] = [
  'role',
  'name',
  'email',
  'date',
  'status',
];

const statusesMap = {
  opened: {
    content: 'common.opened',
    variant: 'success',
  },
  success: {
    content: 'common.delivered',
    variant: 'success',
  },
  failed: {
    content: 'common.failed',
    variant: 'fail',
  },
  accepted: {
    content: 'common.sent',
    variant: 'success',
  },
  'Not scheduled': {
    content: 'common.not_sent',
    variant: 'fail',
  },
} as const;

const statusComponent = (status: CompanyVisitReport['status'] | ReportRecipient['status']) => {
  const reportStatus = status || 'failed';

  return (
    <StatusBadge variant={statusesMap[reportStatus].variant}>
      {t(statusesMap[reportStatus].content)}
    </StatusBadge>
  );
};

export const reportComponentsMap = Object.freeze({
  date: {
    component: ({ date }: CompanyVisitReport) => (
      <StyledNoWrapCellContent>{date}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  version: {
    component: ({ version }: CompanyVisitReport) => (
      <StyledNoWrapCellContent>{version}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  status: {
    component: ({ status }: CompanyVisitReport) => statusComponent(status),
    size: 'min-content',
  },
});

export const recipientsComponentsMap = Object.freeze({
  date: {
    component: ({ date }: ReportRecipient) => (
      <StyledNoWrapCellContent>{date}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  role: {
    component: ({ role }: ReportRecipient) => (
      <StyledNoWrapCellContent>{role || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  status: {
    component: ({ status }: ReportRecipient) => statusComponent(status),
    size: 'min-content',
  },
  name: {
    component: ({ name }: ReportRecipient) => (
      <StyledNoWrapCellContent>{name}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  email: {
    component: ({ email }: ReportRecipient) => (
      <StyledNoWrapCellContent>{email}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
});
