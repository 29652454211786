import { ButtonWrapperAlign } from './ButtonWrapper';
import styled, { css } from 'styled-components/macro';

interface StyledButtonWrapperPropTypes {
  align: ButtonWrapperAlign;
  isWrap: boolean;
}

export const StyledButtonWrapper = styled.div<StyledButtonWrapperPropTypes>`
  align-items: center;
  display: flex;

  ${({ isWrap }) =>
    isWrap &&
    css`
      flex-wrap: wrap;
    `}

  ${({ align }) => {
    switch (align) {
      case 'center':
        return css`
          margin: 0 auto;
        `;
      case 'right':
        return css`
          justify-content: flex-end;
          margin-left: auto;
        `;
    }
  }}

  & > * {
    margin-bottom: ${({ isWrap, theme }) => (isWrap ? theme.sizes.xs : '0')};
    margin-left: ${({ theme }) => theme.sizes.xs};
  }
`;
