/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
// @ts-nocheck

import React, {
  ComponentType,
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { debounce } from 'lodash';
import { usePopper } from 'react-popper';

import { TextInput } from 'components/TextInput/TextInput';
import { useOnClickOutside } from 'utils/hooks';
import { SearchSelectList } from './styles';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { popperSameMinWidthModifier } from 'utils';

import { ReactComponent as SearchIcon } from 'images/searchIcon.svg';

interface SearchSelectPropTypes {
  autoFocus?: boolean;
  clearOnSelect?: boolean;
  closeOnSelect?: boolean;
  disabled?: boolean;
  listItems: ReactElement<{ selected?: boolean }>[];
  onChange?: (val: string) => void;
  onSearch: (text: string) => void;
  placeholder?: string;
  size?: 'small' | 'regular';
  testid?: string;
  value?: string;
  vsize?: 'small' | 'regular';
}

const ROOT_ELEMENT = document.querySelector('body');

const SearchSelect: ComponentType<SearchSelectPropTypes> = ({
  autoFocus = false,
  clearOnSelect = false,
  closeOnSelect = false,
  disabled,
  listItems,
  onChange: changeHandle,
  onSearch,
  placeholder,
  size = 'regular',
  testid = 'search-select-component',
  value: controlledValue,
  vsize = 'regular',
}: SearchSelectPropTypes) => {
  const iconSize = size === 'small' ? 20 : 24;
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'flip',
        options: { allowedAutoPlacements: ['top-start', 'bottom-start'] },
      },
      popperSameMinWidthModifier,
    ],
  });
  const [listVisible, setVisibility] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const listItemsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  useOnClickOutside(listItemsRef, (event) => {
    if (!inputRef.current.contains(event.target)) setVisibility(false);
  });

  const onListClick = React.useCallback(() => {
    closeOnSelect && setVisibility(false);
    if (clearOnSelect) {
      setVisibility(false);
      inputRef!.current!.value = '';
      changeHandle && changeHandle('');
    }
  }, [clearOnSelect, closeOnSelect, changeHandle, setVisibility]);

  const debouncedSearch = useCallback(
    debounce(async (text: string) => {
      setLoading(true);
      onSearch(text);
    }, 300),
    [onSearch],
  );

  useEffect(() => {
    setLoading(false);
  }, [listItems]);

  return (
    <div data-testid={testid} ref={setReferenceElement}>
      <TextInput
        autoFocus={autoFocus}
        inputProps={{
          'data-testid': 'search-select-input',
          onFocus: () => setVisibility(true),
          onChange: ({ currentTarget: { value } }) => {
            changeHandle && changeHandle(value);
            debouncedSearch(value);
          },
          value: controlledValue || controlledValue === '' ? controlledValue : undefined,
        }}
        isDisabled={disabled}
        placeholder={placeholder}
        postfixElement={isLoading ? <LoadingSpinner small /> : undefined}
        prefixElement={<SearchIcon height={iconSize} width={iconSize} />}
        ref={inputRef}
        size='fullWidth'
        vsize={vsize}
      />

      {listVisible &&
        listItems?.length > 0 &&
        createPortal(
          <div ref={listItemsRef}>
            <SearchSelectList
              onClick={onListClick}
              ref={setPopperElement}
              style={{ ...styles.popper }}
              {...attributes.popper}
            >
              {listItems.map((listItem, index) => (
                <Fragment key={index}>{listItem}</Fragment>
              ))}
            </SearchSelectList>
          </div>,
          ROOT_ELEMENT,
        )}
    </div>
  );
};

export { SearchSelect };
