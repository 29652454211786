import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useModal } from 'components/Modal/Modal';
import {
  StyledModalButtonsWrapper,
  StyledModalHeader,
  StyledModalItemToRemove,
  StyledModalMessage,
} from 'components/Modal/styles';

interface Props {
  name: string | null;
  kind: string | null;
  onSubmit: () => Promise<unknown>;
}

export const DeleteWorkplace = (props: Props) => {
  const [isProcessing, setIsProcessing] = React.useState(false);
  const { onSubmit, name, kind } = props;
  const { t } = useTranslation();
  const { hideModal } = useModal();
  const fullName = ''
    .concat(kind ? kind : '')
    .concat(kind && name ? ' - ' : '')
    .concat(name ? name : '');

  return (
    <>
      <StyledModalHeader>{t('workplace.delete_workplace')}</StyledModalHeader>

      <StyledModalItemToRemove>{fullName}</StyledModalItemToRemove>

      <StyledModalMessage>{t('workplace.data_lose')}</StyledModalMessage>
      <StyledModalButtonsWrapper>
        <DefaultButton
          capitalize
          isDisabled={isProcessing}
          label={t('common.cancel')}
          onClick={hideModal}
          type='button'
          variant='tertiary'
        />
        <DefaultButton
          capitalize
          isDisabled={isProcessing}
          label={t('workplace.delete_workplace')}
          onClick={async () => {
            setIsProcessing(true);
            await onSubmit();
            hideModal();
          }}
          type='button'
          variant='danger'
        />
      </StyledModalButtonsWrapper>
    </>
  );
};
