import * as React from 'react';
import { createPortal } from 'react-dom';
import { useCombobox, useMultipleSelection } from 'downshift';
import * as PopperJS from '@popperjs/core';
import { usePopper } from 'react-popper';

import { StyledMenu, StyledSelectItem } from 'components/FieldSingleSelect/styles';
import { StyledError, StyledFieldWrapper, StyledLabel } from 'components/Forms/styles';
import {
  StyledContainer,
  StyledIconsContainer,
  StyledInput,
  StyledSelectedItem,
  StyledWrapper,
} from 'components/AsyncMultiselect/style';
import { CrossIcon, SpinnerIcon } from 'utils/iconsMap';
import { popperOffsetModifier, promiseDebounce } from 'utils';

export interface Option {
  label: string;
  value: string;
  description?: string;
}

export interface AsyncMultiSelectProps {
  extraInputProps?: {
    onBlur?: () => unknown;
    onFocus?: () => unknown;
    name?: string;
  };
  fetchFunction: (query: string) => Promise<Option[]>;
  hasError?: false | string;
  isDisabled?: boolean;
  label: string;
  onChange: (element: Option[]) => void;
  placeholder?: string;
  popperPlacement?: PopperJS.Placement;
  required?: boolean;
  value: Option[];
}

const POPPER_ROOT_ELEMENT = document.querySelector('body');

export const AsyncMultiSelect = (props: AsyncMultiSelectProps): React.ReactElement => {
  const {
    extraInputProps,
    fetchFunction,
    hasError,
    isDisabled,
    label,
    onChange,
    placeholder,
    popperPlacement = 'bottom-start',
    required,
    value,
  } = props;
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<Option[]>([]);
  const [loading, setLoading] = React.useState(false);

  const debouncedFetchFunction = React.useRef<(query: string) => Promise<Option[]>>(
    promiseDebounce((query: string) => fetchFunction(query), 350),
  );

  React.useEffect(() => {
    debouncedFetchFunction.current = promiseDebounce((query: string) => fetchFunction(query), 350);
  }, [fetchFunction]);

  // ------------------- popper

  const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: popperPlacement,
    modifiers: [
      popperOffsetModifier,
      {
        name: 'flip',
        options: { allowedAutoPlacements: ['top-start'], padding: { top: 60, bottom: 60 } },
      },
    ],
  });

  // ------------------- handlers and downshift
  const {
    addSelectedItem,
    getDropdownProps,
    getSelectedItemProps,
    removeSelectedItem,
    selectedItems,
    setSelectedItems,
    reset,
  } = useMultipleSelection({
    initialSelectedItems: value,
    onStateChange: ({ type, selectedItems }) => {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
          onChange(selectedItems || []);
          break;
        case useMultipleSelection.stateChangeTypes.SelectedItemClick:
          onChange(selectedItems || []);
          break;
        case useMultipleSelection.stateChangeTypes.FunctionReset:
          setSelectedItems([]);
          onChange([]);
          break;
        default:
          break;
      }
    },
  });

  const typeHandler = React.useCallback(
    async (inputValue: string | undefined) => {
      setInputValue(inputValue || '');
      if (inputValue) {
        setLoading(true);
        setOptions([]);
        const opts = await debouncedFetchFunction.current(inputValue);
        const filtredOptions = opts.filter((option) =>
          selectedItems.every(({ value }) => value !== option.value),
        );
        setOptions(filtredOptions);
      }
      setLoading(false);
    },
    [fetchFunction, selectedItems],
  );

  const {
    getComboboxProps,
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    isOpen,
  } = useCombobox({
    inputValue,
    itemToString: (item) => item?.label || '',
    items: options,
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          typeHandler(inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem && !value.some(({ value }) => selectedItem.value === value)) {
            setInputValue('');
            addSelectedItem(selectedItem);
            onChange([...value, selectedItem]);
            setOptions([]);
          }
          break;
        case useCombobox.stateChangeTypes.FunctionSelectItem:
          onChange([...value, selectedItem!]);
          break;
        default:
          break;
      }
    },
  });

  return (
    <StyledFieldWrapper>
      <StyledLabel {...getLabelProps()}>
        {label}
        {required && '*'}
      </StyledLabel>
      <StyledContainer {...getComboboxProps()} hasError={hasError} isDisabled={isDisabled}>
        <StyledWrapper ref={setReferenceElement}>
          {selectedItems.map((selectedItem, index) => (
            <StyledSelectedItem
              key={`selected-item-${index}`}
              {...getSelectedItemProps({ selectedItem, index })}
              // replace with {...getSelectedItemProps({ selectedItem, index, disabled: isDisabled })}
              // and remove `{...(isDisabled && { .. ` part
              // when (or if) PR: https://github.com/downshift-js/downshift/pull/1297 will be merged and new downshift version deployed
              {...(isDisabled && {
                onClick: undefined,
                onKeyDown: undefined,
              })}
            >
              {selectedItem.label}
              <CrossIcon
                onClick={() => (isDisabled ? undefined : removeSelectedItem(selectedItem))}
              />
            </StyledSelectedItem>
          ))}
          <div>
            <StyledInput
              placeholder={placeholder}
              {...extraInputProps}
              {...getInputProps(
                getDropdownProps({ preventKeyAction: isOpen, disabled: isDisabled }),
              )}
            />
          </div>
        </StyledWrapper>
        <StyledIconsContainer>
          {loading && <SpinnerIcon />}
          <CrossIcon onClick={() => (isDisabled ? undefined : reset())} />
        </StyledIconsContainer>
      </StyledContainer>
      {createPortal(
        <div
          ref={setPopperElement}
          style={{ ...styles.popper, zIndex: 1000, top: 40 }}
          {...attributes.popper}
        >
          <StyledMenu isOpen={isOpen} {...getMenuProps()}>
            {options.map((item, index) => (
              <StyledSelectItem
                isHighlighted={highlightedIndex === index}
                key={index}
                {...getItemProps({ item, index })}
              >
                {item.label}
                {item.description && <span>{item.description}</span>}
              </StyledSelectItem>
            ))}
          </StyledMenu>
        </div>,
        POPPER_ROOT_ELEMENT!,
      )}
      {hasError && <StyledError>{hasError}</StyledError>}
    </StyledFieldWrapper>
  );
};
