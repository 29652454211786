import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useModal } from 'components/Modal/Modal';
import { StyledNewVisitCompanyPreview } from 'containers/newVisit/OrdinaryVisit/styles';
import { Margin } from 'styles/utils';
import {
  StyledDetails,
  StyledSecondaryText,
} from 'containers/companyDetails/CompanyNavigationPanel/styles';
import { StatusBadge } from 'components/StatusBadge/StatusBadge';
import { BasicData } from 'containers/companyDetails/BasicData/BasicData';
import { CompanyEntity } from 'ducks/companies/types';
import { capitalize } from 'lodash';

interface CompanyPreviewModalPropsType {
  company: CompanyEntity;
}

export const CompanyPreviewModal = (props: CompanyPreviewModalPropsType) => {
  const { company } = props;

  const { t } = useTranslation();
  const { setCustomStyles } = useModal();
  useEffect(() => {
    setCustomStyles({ maxWidth: '74rem' });
  }, []);

  return (
    <StyledNewVisitCompanyPreview>
      <Margin margin='2.4rem 0'>
        <StyledDetails>
          <h1>{company.info.name}</h1>
          {company.info.orgTypeCode && (
            <StatusBadge variant='active'>{company.info.orgTypeCode}</StatusBadge>
          )}
          <StatusBadge variant={company.info.vatDuty ? 'active' : 'inactive'}>
            {t('company_details.vat')}
          </StatusBadge>
          <StatusBadge variant={company.info.active ? 'success' : 'inactive'}>
            {capitalize(
              t(`company_details.company_is_${company.info.active ? 'active' : 'inactive'}`),
            )}
          </StatusBadge>
          <StyledSecondaryText>
            {t('company_details.organisational_number')}: {company.info.number}
          </StyledSecondaryText>
        </StyledDetails>
      </Margin>
      <BasicData clickableContactEmail={false} company={company} />
    </StyledNewVisitCompanyPreview>
  );
};
