import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { workplaceTableMapper } from './workplaceTableMapper';
import {
  TableProvidersProps,
  useTableContexts,
  withTableProviders,
} from 'hocs/composedTableProviders/composedTableProviders';
import { StoreType, useAppDispatch } from 'store';
import { TableGrid } from 'components/Table/TableGrid/TableGrid';
import {
  getWorkplaceCSV,
  removeWorkplace,
  searchWorkplaces,
} from 'ducks/powerSearch/workplace/actions';
import {
  StyledColumn,
  StyledContainer,
  StyledTableBottomPanel,
} from 'containers/companyDetails/Visits/styles';
import { TableFilters } from 'components/TableFilters/TableFilters';
import { PaginationComposed } from 'components/Pagination/Pagination';
import { CommonActionsPanel } from 'components/CommonActionsPanel/CommonActionsPanel';
import { ButtonWrapper } from 'components/ButtonWrapper/ButtonWrapper';
import { useLoadingContext } from 'contexts/LoadingContext';
import { StyledCardMax } from 'components/Card/styles';
import { GetWorkplaceResponse } from 'ducks/powerSearch/workplace/types';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useModal } from 'components/Modal/Modal';
import { SaveSearch } from './resultsModals';
import { getActiveFiltersState } from 'utils';
import { resetSearchResults } from 'ducks/powerSearch/actions';
import { WorkplaceModal } from 'components/WorkplaceModal/WorkplaceModal';
import { API } from 'api';
import { DeleteWorkplace } from 'dialogs/Workplace/DeleteWorkplace';
import { BookmarkIcon } from 'utils/iconsMap';
import { ChooseVisitModal } from 'containers/newVisit/ChooseVisitModal';
import { checkIsIpad } from 'utils/deviceChecker';
import { toast } from 'react-hot-toast';
import { clearWorkplacesSearchResultsError } from 'ducks/powerSearch/workplace/reducer';

const WorkplaceResults: FC<TableProvidersProps> = (props) => {
  const results = useSelector((state: StoreType) => state.powerSearch.workplace);
  const { query } = useSelector((state: StoreType) => state.powerSearch);
  const {
    filtersState,
    setContextName,
    itemsSelected,
    addMultipleSelection,
    filterParams,
  } = useTableContexts();
  const { setLoaded, setLoading } = useLoadingContext();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { showModal, setModalComponent, setCustomStyles, setShowHeader } = useModal();

  useEffect(() => {
    return () => {
      dispatch(resetSearchResults());
    };
  }, [dispatch, resetSearchResults]);

  useEffect(() => {
    setContextName('powerWorkplaces');
  }, []);

  const exportCsvHandler = async () => {
    setLoading('powersearch');
    await getWorkplaceCSV(itemsSelected.map((item: GetWorkplaceResponse) => item.workplaceId));
    setLoaded('powersearch');
  };

  const selectAllHandler = async () => {
    setLoading('powersearch');
    const params = { ...filterParams, ...props.fetchParams, page_size: 10000, page: 1 };
    const response = await API.get(`power/workplaces`, { params });
    addMultipleSelection(
      response.data.data.tableBody.map((item: GetWorkplaceResponse) => ({
        entity: { ...item, id: item.workplaceId },
        id: item.workplaceId,
      })),
    );
    setLoaded('powersearch');
  };

  const actionHandlers = {
    delete: (workplace: GetWorkplaceResponse) => {
      setModalComponent(
        <DeleteWorkplace
          kind={workplace.kind}
          name={workplace.name}
          onSubmit={() => dispatch(removeWorkplace(workplace.workplaceId))}
        />,
      );
      setShowHeader(false);
      showModal();
    },
    edit: (workplace: GetWorkplaceResponse) => {
      setModalComponent(<WorkplaceModal workplace={workplace} />);
      setCustomStyles({ maxWidth: '78rem' });
      setShowHeader(false);
      showModal();
    },
    exportCSV: exportCsvHandler,
    selectAll: selectAllHandler,
    show: (workplace: GetWorkplaceResponse) => {
      setModalComponent(<WorkplaceModal isDisabled workplace={workplace} />);
      setCustomStyles({ maxWidth: '78rem' });
      setShowHeader(false);
      showModal();
    },
    create_visit: (workplace: GetWorkplaceResponse) => {
      setCustomStyles({ maxWidth: '64rem' });
      setModalComponent(<ChooseVisitModal noType={true} />);
      setShowHeader(false);
      showModal();
    },
  };

  if (results.error) {
    toast.error(results.error);
    dispatch(clearWorkplacesSearchResultsError());
  }

  const isIpad = checkIsIpad();

  return (
    // @ts-ignore
    <StyledContainer isIpad={isIpad}>
      <StyledCardMax>
        <StyledColumn>
          <TableFilters isSearchPagination />
          <TableGrid
            actionHandlers={actionHandlers}
            addButton={
              <DefaultButton
                icon={<BookmarkIcon />}
                label={t(`power_search.save_search`)}
                onClick={() => {
                  setModalComponent(
                    <SaveSearch
                      params={{
                        state: getActiveFiltersState(filtersState),
                        query,
                        tag: 'workplace',
                      }}
                    />,
                  );
                  setShowHeader(false);
                  showModal();
                }}
              />
            }
            allFilteredOutMessage={t(`power_search.workplaces.all_filtered_out`)}
            bodyData={results.data.tableBody}
            columnsOrder={results.meta.columnsOrder}
            componentsMap={workplaceTableMapper}
            isSearchPagination
            loadResource='powersearch'
            noResultsMessage={t(`power_search.workplaces.no_results`)}
            totalCount={results.meta.totalCount}
            withCheckbox
            withFullWidth
          />
          <StyledTableBottomPanel>
            <PaginationComposed loadResource='powersearch' />
            <ButtonWrapper align='right'>
              <CommonActionsPanel actionHandlers={actionHandlers} />
            </ButtonWrapper>
          </StyledTableBottomPanel>
        </StyledColumn>
      </StyledCardMax>
    </StyledContainer>
  );
};

const ResultsComposed = withTableProviders(WorkplaceResults, {
  fetchFunction: searchWorkplaces,
  loadResource: 'powersearch',
  metaUrl: 'power/workplaces',
  spyDistrict: true,
  spyQuery: true,
});

export { ResultsComposed as WorkplaceResults };
