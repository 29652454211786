import { globalHistory, navigate } from '@reach/router';

export const navigateWithState = (type: string, state = {}) => {
  const isSearch = type === 'search';
  let searchPath = globalHistory.location.pathname;
  if (isSearch) {
    searchPath = globalHistory.location.pathname.includes('redesigned/search')
      ? `${globalHistory.location.pathname}${globalHistory.location.search}`
      : globalHistory.location.pathname;
  }

  navigate(searchPath, {
    replace: globalHistory.location.pathname.includes(`redesigned/${type}`),
    state: {
      ...(globalHistory.location?.state || {}),
      ...(state || {}),
    },
  });
};
