import React from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useModal } from 'components/Modal/Modal';
import {
  StyledModalButtonsWrapper,
  StyledModalHeader,
  StyledModalMessage,
} from 'components/Modal/styles';

interface DeleteAttachmentModalProps {
  deleteFile: () => void;
}

export const DeleteAttachmentModal = ({
  deleteFile,
}: DeleteAttachmentModalProps): React.ReactElement => {
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const { hideModal } = useModal();

  const onSubmit = React.useCallback(async () => {
    setIsProcessing(true);
    deleteFile();
    hideModal();
  }, []);

  return (
    <>
      <StyledModalHeader>{t('common.delete_attachment')}</StyledModalHeader>

      <StyledModalMessage>{t('common.attachment_will_be_deleted')}</StyledModalMessage>
      <StyledModalButtonsWrapper>
        <DefaultButton
          capitalize
          isDisabled={isProcessing}
          label={t('common.cancel')}
          onClick={hideModal}
          type='button'
          variant='tertiary'
        />
        <DefaultButton
          capitalize
          isDisabled={isProcessing}
          label={t('common.delete')}
          onClick={onSubmit}
          type='button'
          variant='danger'
        />
      </StyledModalButtonsWrapper>
    </>
  );
};
