import i18n from 'i18n';
import { setLocale } from 'yup';

const t = (x: string) => i18n.t(x);

setLocale({
  string: {
    email: t('forms.incorrect_email'),
    matches: t('forms.invalid_format'),
  },
  mixed: {
    required: t('forms.required'),
  },
});
