import { createSlice } from '@reduxjs/toolkit';
import { Address } from 'ducks/companies/types';
import i18n from 'i18n';
import { camelCase } from 'lodash';

import { searchCompanies } from './actions';
import {
  CompanySearchState,
  PowerSearchMeta,
  SearchCompanyEntity,
  SearchCompanyEntityResponse,
} from './types';

const t = (x: string) => i18n.t(x);

const initialState: CompanySearchState = {
  data: {
    tableBody: [],
  },
  error: undefined,
  meta: {
    columnsOrder: [],
    filters: {},
    page: 1,
    pageSize: 10,
    totalCount: null,
    totalPages: 1,
  },
};

const columnsHiddenForBA = [
  'cleaningLicenseText',
  'branch',
  'providesAccommodationServices',
  'providesCleaningServices',
  'hasAlcoholLicense',
  'riskLevel',
];

const amendEntity = (entity: SearchCompanyEntityResponse): SearchCompanyEntity => ({
  ...entity,
  id: entity.companyId,
});

const amendMeta = (meta: PowerSearchMeta) => {
  if (window.location.host.toUpperCase().includes('BA')) {
    const columnsOrder = meta.columnsOrder.filter(
      (x: string) => !columnsHiddenForBA.includes(camelCase(x)),
    );
    return { ...meta, columnsOrder };
  }
  return meta;
};

export const amendAddresses = (addresses: Array<Address>) =>
  addresses.map((el) => {
    const { typeSymbol, country } = el;
    const label = t(`company_details.basic-data.address_types.${typeSymbol}`);
    const tagLetter = label.charAt(0);
    const address = el.address.trim();
    const postal = [el.postalCode, el.city].filter(Boolean).join(' ');
    const format = `${address || ''}${address.length > 0 && postal.length > 0 ? ', ' : ''}${
      postal.trim() || ''
    }`;
    const addressWithCountry = country ? `${format}, ${country} ` : format;
    const gAddress = `${format}, ${country ? country : 'Norway'}`.replace(' ', '+');
    const gMapLink = `https://www.google.com/maps/search/${gAddress}`;

    return {
      ...el,
      label,
      tagLetter,
      address: format,
      addressWithCountry,
      gMapLink,
      gAddress,
    };
  });

const companytSearchSlice = createSlice({
  name: 'companySearch/company',
  initialState,
  reducers: {
    resetCompanySearchResults: () => initialState,
    clearCompanySearchError: (state) => ({ ...state, error: undefined }),
  },
  extraReducers: (builder) => {
    builder.addCase(searchCompanies.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(searchCompanies.fulfilled, (state, { payload }) => {
      state.data.tableBody = payload.data.tableBody.map(amendEntity);
      state.meta = amendMeta({ ...state.meta, ...payload.meta });
    });
  },
});

const {
  reducer: companySearchReducer,
  actions: { resetCompanySearchResults, clearCompanySearchError },
} = companytSearchSlice;

export { companySearchReducer, resetCompanySearchResults, clearCompanySearchError };
