import styled from 'styled-components/macro';

export type BulletVariants = 'red' | 'green' | 'yellow';

export const StyledBulletLabel = styled.div<{ variant?: BulletVariants }>`
  padding-left: ${({ theme }) => theme.sizes.md};
  position: relative;

  &::before {
    background-color: ${({ variant, theme }) => {
      switch (variant) {
        case 'green':
          return theme.colors.green500;
        case 'red':
          return theme.colors.red500;
        case 'yellow':
          return theme.colors.yellow500;
        default:
          return theme.colors.black300;
      }
    }};
    border-radius: 50%;
    content: '';
    height: ${({ theme }) => theme.sizes.hmd};
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: ${({ theme }) => theme.sizes.hmd};
  }
`;
