import { createSlice } from '@reduxjs/toolkit';

import { createWorkplace, editWorkplace, removeWorkplace, searchWorkplaces } from './actions';
import { GetWorkplaceResponse, SearchWorkplacesEntity, SearchWorkplacesState } from './types';

const initialState: SearchWorkplacesState = {
  data: {
    tableBody: [],
  },
  error: undefined,
  meta: {
    columnsOrder: [],
    filters: {},
    page: 1,
    pageSize: 10,
    totalCount: null,
    totalPages: 1,
  },
};

const amendEntity = (entity: GetWorkplaceResponse): SearchWorkplacesEntity => ({
  ...entity,
  id: entity.workplaceId,
});

const workplacesSlice = createSlice({
  name: 'search/workplacess',
  initialState,
  reducers: {
    resetWorkplacesSearchResults: () => initialState,
    clearWorkplacesSearchResultsError: (state) => ({ ...state, error: undefined }),
  },
  extraReducers: (builder) => {
    builder.addCase(searchWorkplaces.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(searchWorkplaces.fulfilled, (state, { payload }) => {
      state.data.tableBody = payload.data.tableBody.map(amendEntity);
      state.meta = payload.meta;
    });
    builder.addCase(createWorkplace.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(createWorkplace.fulfilled, (state, { payload }) => {
      state.data.tableBody.push(amendEntity(payload));
      state.meta!.totalCount!++;
    });
    builder.addCase(editWorkplace.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(editWorkplace.fulfilled, (state, { payload, meta }) => {
      const id = meta.arg.id;
      const workplace = state.data.tableBody.find(({ workplaceId }) => workplaceId === id);
      Object.assign(workplace, amendEntity(payload));
    });
    builder.addCase(removeWorkplace.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(removeWorkplace.fulfilled, (state, { meta }) => {
      const id = meta.arg;
      state.data.tableBody = state.data.tableBody.filter(({ workplaceId }) => workplaceId !== id);
    });
  },
});

const {
  reducer: workplacesSearchReducer,
  actions: { resetWorkplacesSearchResults, clearWorkplacesSearchResultsError },
} = workplacesSlice;

export { workplacesSearchReducer, resetWorkplacesSearchResults, clearWorkplacesSearchResultsError };
