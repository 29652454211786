import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';

import { companyTableMapper } from './companyTableMapper';
import {
  TableProvidersProps,
  useTableContexts,
  withTableProviders,
} from 'hocs/composedTableProviders/composedTableProviders';
import { StoreType } from 'store';
import { TableGrid } from 'components/Table/TableGrid/TableGrid';
import { getCompaniesCSV, searchCompanies } from 'ducks/powerSearch/company/actions';
import {
  StyledColumn,
  StyledContainer,
  StyledTableBottomPanel,
} from 'containers/companyDetails/Visits/styles';
import { TableFilters } from 'components/TableFilters/TableFilters';
import { PaginationComposed } from 'components/Pagination/Pagination';
import { CommonActionsPanel } from 'components/CommonActionsPanel/CommonActionsPanel';
import { ButtonWrapper } from 'components/ButtonWrapper/ButtonWrapper';
import { useLoadingContext } from 'contexts/LoadingContext';
import { StyledCardMax } from 'components/Card/styles';
import { SearchCompanyEntity, SearchCompanyEntityResponse } from 'ducks/powerSearch/company/types';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useModal } from 'components/Modal/Modal';
import { SaveSearch } from './resultsModals';
import { getActiveFiltersState } from 'utils';
import { resetSearchResults } from 'ducks/powerSearch/actions';
import { CompanyNoResultsContent } from 'containers/powersearch/Results/CompanyNoResultsContent';
import { API } from 'api';
import { checkIsIpad } from 'utils/deviceChecker';

import { ReactComponent as BookmarkIcon } from 'images/bookmarkIcon.svg';
import { toast } from 'react-hot-toast';
import { clearCompanySearchError } from 'ducks/powerSearch/company/reducer';

const filtersNamesMapper = (filter: string) => {
  if (filter === 'email') return 'email_present';
  if (filter === 'phone') return 'phone_present';
  if (filter === 'has_alcohol_license') return 'provides_alcohol_service';
  return filter;
};

const CompanyResults: FC<TableProvidersProps> = (props) => {
  const results = useSelector((state: StoreType) => state.powerSearch.company);
  const { query } = useSelector((state: StoreType) => state.powerSearch);
  const { filtersState, setContextName, itemsSelected, filterParams, addMultipleSelection } =
    useTableContexts();
  const { setLoaded, setLoading } = useLoadingContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showModal, setModalComponent, setShowHeader } = useModal();

  useEffect(() => {
    return () => {
      dispatch(resetSearchResults());
    };
  }, [dispatch]);

  useEffect(() => {
    setContextName('powercompanies');
  }, []);

  const showHandler = ({ companyId }: SearchCompanyEntity) =>
    navigate(`/redesigned/companies/${companyId}/basic-data`);

  const selectAllHandler = async () => {
    setLoading('powersearch');
    const params = { ...filterParams, ...props.fetchParams, page_size: 10000, page: 1 };
    const response = await API.get(`power/companies`, { params });

    addMultipleSelection(
      response.data.data.tableBody.map((x: SearchCompanyEntityResponse) => ({
        entity: { ...x, id: x.companyId },
        id: x.companyId,
      })),
    );
    setLoaded('powersearch');
  };

  const exportCsvHandler = async () => {
    setLoading('powersearch');
    await getCompaniesCSV(itemsSelected.map((item: SearchCompanyEntity) => item.id));
    setLoaded('powersearch');
  };

  const actionHandlers = {
    exportCSV: exportCsvHandler,
    selectAll: selectAllHandler,
    show: showHandler,
  };

  if (results.error) {
    toast.error(results.error);
    dispatch(clearCompanySearchError());
  }

  const isIpad = checkIsIpad();

  return (
    <StyledContainer isIpad={isIpad}>
      <StyledCardMax>
        <StyledColumn>
          <TableFilters isSearchPagination />
          <TableGrid
            actionHandlers={actionHandlers}
            addButton={
              <DefaultButton
                icon={<BookmarkIcon />}
                label={t(`power_search.save_search`)}
                onClick={() => {
                  setModalComponent(
                    <SaveSearch
                      params={{ state: getActiveFiltersState(filtersState), query, tag: 'company' }}
                    />,
                  );
                  setShowHeader(false);
                  showModal();
                }}
              />
            }
            allFilteredOutMessage={t(`power_search.companies.all_filtered_out`)}
            bodyData={results.data.tableBody}
            columnsOrder={results.meta.columnsOrder}
            componentsMap={companyTableMapper}
            isSearchPagination
            loadResource='powersearch'
            noResultsContent={<CompanyNoResultsContent query={query} />}
            totalCount={results.meta.totalCount}
            withCheckbox
            withFullWidth
          />
          <StyledTableBottomPanel>
            <PaginationComposed loadResource='powersearch' />
            <ButtonWrapper align='right'>
              <CommonActionsPanel actionHandlers={actionHandlers} />
            </ButtonWrapper>
          </StyledTableBottomPanel>
        </StyledColumn>
      </StyledCardMax>
    </StyledContainer>
  );
};

const ResultsComposed = withTableProviders(CompanyResults, {
  fetchFunction: searchCompanies,
  filtersNamesMapper,
  loadResource: 'powersearch',
  metaUrl: 'power/companies',
  spyDistrict: true,
  spyQuery: true,
});

export { ResultsComposed as CompanyResults };
