import { TabContainer } from 'components/Tabs/styles';
import styled, { css } from 'styled-components/macro';

import { CardProps } from './Card';

export const StyledCard = styled.div<CardProps>`
  background-color: white;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: ${({ theme }) => theme.boxShadows.blue500};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: ${({ theme }) => theme.sizes.xs};
  padding: ${({ noPadding, theme }) => (noPadding ? '0' : `${theme.sizes.hmd} ${theme.sizes.md}`)};
  position: relative;
  width: 100%;
`;

export const StyledCardBlock = styled.div<CardProps>`
  background-color: white;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: ${({ theme }) => theme.boxShadows.blue500};
  margin-bottom: ${({ theme }) => theme.sizes.xs};
  padding: ${({ noPadding, theme }) => (noPadding ? '0' : `${theme.sizes.hmd} ${theme.sizes.md}`)};
  width: 100%;
`;

export const StyledCardHeader = styled.header<{ size?: 'small' | 'default' }>`
  ${({ theme, size }) => (size === 'small' ? theme.fontSizes.base : theme.fontSizes.lg)};
  align-items: center;
  color: ${({ theme }) => theme.colors.black900};
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  margin-bottom: ${({ theme, size }) => (size === 'small' ? theme.sizes.xs : theme.sizes.hmd)};
`;

export const StyledCardDivider = styled.div<{ fullWidth?: boolean; bold?: boolean }>`
  background-color: ${({ theme, bold }) => (bold ? theme.colors.black200 : theme.colors.black100)};
  height: ${({ bold }) => (bold ? '3px' : '1px')};
  margin-bottom: ${({ theme }) => theme.sizes.hmd};
  width: 100%;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      transform: translateX(-${({ theme }) => theme.sizes.md});
      width: calc(100% + ${({ theme }) => theme.sizes.lg});
    `}
`;

export const StyledCardSmall = styled(StyledCard)`
  flex-direction: initial;
  overflow: hidden;
`;

export const StyledCardWithTabsContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 100%;

  ${TabContainer} {
    flex: 0 0 auto;
  }
`;

export const StyledCardMax = styled(StyledCardSmall)`
  max-height: ${({ theme }) => `calc(100vh - (${theme.sizes.md} * 2.5 + ${theme.sizes.xs}))`};
`;
