import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { companyVisitsTableMapper } from './companyVisitsTableMapper';
import {
  TableProvidersProps,
  useTableContexts,
  withTableProviders,
} from 'hocs/composedTableProviders/composedTableProviders';
import { StoreType } from 'store';
import { TableGrid } from 'components/Table/TableGrid/TableGrid';
import { getCompanyVisitsCSV, searchCompanyVisits } from 'ducks/powerSearch/companyVisits/actions';
import {
  StyledColumn,
  StyledContainer,
  StyledTableBottomPanel,
} from 'containers/companyDetails/Visits/styles';
import { TableFilters } from 'components/TableFilters/TableFilters';
import { PaginationComposed } from 'components/Pagination/Pagination';
import { CommonActionsPanel } from 'components/CommonActionsPanel/CommonActionsPanel';
import { ButtonWrapper } from 'components/ButtonWrapper/ButtonWrapper';
import { useLoadingContext } from 'contexts/LoadingContext';
import { StyledCardMax } from 'components/Card/styles';
import {
  SearchCompanyVisitsEntity,
  SearchCompanyVisitsEntityResponse,
} from 'ducks/powerSearch/companyVisits/types';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useModal } from 'components/Modal/Modal';
import { SaveSearch } from './resultsModals';
import { getActiveFiltersState } from 'utils';
import { DeleteVisitsModal } from 'containers/companyDetails/Visits/VisitsModals';
import { BASE_URL } from 'api/apiUrl';
import { resetSearchResults } from 'ducks/powerSearch/actions';
import { checkIsIpad } from 'utils/deviceChecker';
import { ChooseDateDashboardVisit } from 'dialogs/Dashboard/ChooseDateDashboardVisit';
import { ReactComponent as BookmarkIcon } from 'images/bookmarkIcon.svg';
import { API } from 'api';
import { toast } from 'react-hot-toast';
import { clearCompanyVisitsSearchError } from 'ducks/powerSearch/companyVisits/reducer';

const CompanyVisitsResults: FC<TableProvidersProps> = (props) => {
  const results = useSelector((state: StoreType) => state.powerSearch.companyVisits);
  const { query } = useSelector((state: StoreType) => state.powerSearch);
  const {
    deleteSelection,
    filtersState,
    itemsSelected,
    setContextName,
    addMultipleSelection,
    filterParams,
  } = useTableContexts();
  const { setLoaded, setLoading } = useLoadingContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showModal, setModalComponent, setShowHeader } = useModal();

  useEffect(() => {
    setContextName('powerCompanyVisits');
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetSearchResults());
    };
  }, [dispatch, resetSearchResults]);

  const deleteHandler = (entity: SearchCompanyVisitsEntity) => {
    setModalComponent(
      <DeleteVisitsModal actionCallback={() => deleteSelection(entity.id)} entity={entity} />,
    );
    setShowHeader(false);
    showModal();
  };

  const selectAllHandler = async () => {
    setLoading('powersearch');
    const params = { ...filterParams, ...props.fetchParams, page_size: 10000, page: 1 };
    const response = await API.get(`power/company_visits`, { params });

    addMultipleSelection(
      response.data.data.tableBody.map((x: SearchCompanyVisitsEntityResponse) => ({
        entity: { ...x, id: x.companyVisitId },
        id: x.companyVisitId,
      })),
    );
    setLoaded('powersearch');
  };

  const duplicateHandler = (entity: SearchCompanyVisitsEntity) => {
    if (entity.visitId) {
      setModalComponent(<ChooseDateDashboardVisit visitId={entity.visitId} />);
      setShowHeader(false);
      showModal();
    }
  };
  const editHandler = ({ visitId, companyVisitId }: SearchCompanyVisitsEntity) =>
    (window.location.href = `${BASE_URL}/visits/${visitId}?company_visit_id=${companyVisitId}`);

  const showHandler = ({ visitId, companyVisitId }: SearchCompanyVisitsEntity) =>
    (window.location.href = `${BASE_URL}/visits/${visitId}?company_visit_id=${companyVisitId}`);

  const exportCsvHandler = async () => {
    setLoading('powersearch');
    await getCompanyVisitsCSV(itemsSelected.map((item: SearchCompanyVisitsEntity) => item.id));
    setLoaded('powersearch');
  };

  const actionHandlers = {
    delete: deleteHandler,
    show: showHandler,
    edit: editHandler,
    duplicate: duplicateHandler,
    selectAll: selectAllHandler,
    exportCSV: exportCsvHandler,
  };

  if (results.error) {
    toast.error(results.error);
    dispatch(clearCompanyVisitsSearchError());
  }

  const isIpad = checkIsIpad();

  return (
    // @ts-ignore
    <StyledContainer isIpad={isIpad}>
      <StyledCardMax>
        <StyledColumn>
          <TableFilters isSearchPagination />
          <TableGrid
            actionHandlers={actionHandlers}
            addButton={
              <DefaultButton
                icon={<BookmarkIcon />}
                label={t(`power_search.save_search`)}
                onClick={() => {
                  setModalComponent(
                    <SaveSearch
                      params={{
                        state: getActiveFiltersState(filtersState),
                        query,
                        tag: 'company_visits',
                      }}
                    />,
                  );
                  setShowHeader(false);
                  showModal();
                }}
              />
            }
            allFilteredOutMessage={t(`company_details.visits.all_filtered_out`)}
            bodyData={results.data.tableBody}
            columnsOrder={results.meta.columnsOrder}
            componentsMap={companyVisitsTableMapper}
            isSearchPagination
            loadResource='powersearch'
            noResultsMessage={t(`company_details.visits.no_results`)}
            totalCount={results.meta.totalCount}
            withCheckbox
            withFullWidth
          />
          <StyledTableBottomPanel>
            <PaginationComposed loadResource='powersearch' />
            <ButtonWrapper align='right'>
              <CommonActionsPanel actionHandlers={actionHandlers} />
            </ButtonWrapper>
          </StyledTableBottomPanel>
        </StyledColumn>
      </StyledCardMax>
    </StyledContainer>
  );
};

const ResultsComposed = withTableProviders(CompanyVisitsResults, {
  fetchFunction: searchCompanyVisits,
  loadResource: 'powersearch',
  metaUrl: 'power/company_visits',
  spyDistrict: true,
  spyQuery: true,
});

export { ResultsComposed as CompanyVisitsResults };
