import React, { ElementType } from 'react';
import { globalHistory } from '@reach/router';

import { CapsLabel } from 'components/CapsLabel/CapsLabel';
import { navigateWithState } from 'utils/navigateWithState';
import { StickyOptionsype, StyledHeaderColumn, StyledSortIcon } from './styles';
import { useSortContext } from 'contexts/SortContext';

import { ReactComponent as SortUpIcon } from 'images/sortUpIcon.svg';
import { ReactComponent as SortDownIcon } from 'images/sortDownIcon.svg';

interface ColumnPropTypes {
  columnName: string;
  CustomComponent?: ElementType;
  disabled?: boolean;
  isDashboard?: boolean;
  isSearchPagination?: boolean;
  label?: string;
  sticky?: StickyOptionsype;
}

const Column = ({
  columnName,
  CustomComponent,
  disabled,
  isDashboard,
  isSearchPagination,
  label,
  sticky,
}: ColumnPropTypes) => {
  const sortContext = useSortContext();
  const { changeSort, field, order, hasProvider } = sortContext;

  const onChangingSort = () => {
    changeSort(columnName);

    if (document.getElementById('table-grid-scroll-container')) {
      (document.getElementById('table-grid-scroll-container') as HTMLDivElement).scrollTop = 0;
    }

    if (isSearchPagination) {
      navigateWithState('search', {
        search: {
          ...(globalHistory.location?.state?.search || {}),
          scrollTop: 0,
        },
      });
    } else if (isDashboard) {
      navigateWithState('dashboard', {
        dashboard: {
          ...(globalHistory.location?.state?.dashboard || {}),
          scrollTop: 0,
        },
      });
    }
  };

  return (
    <StyledHeaderColumn
      onClick={() => !disabled && onChangingSort()}
      sticky={sticky}
      withHover={hasProvider}
    >
      {CustomComponent ? (
        <CustomComponent />
      ) : (
        <CapsLabel data-testid={`th-${columnName}`} isDisabled={disabled}>
          {label as string}
        </CapsLabel>
      )}
      {order && field === columnName && (
        <StyledSortIcon data-testid='sort-icon'>
          {order === 'asc' && <SortUpIcon />}
          {order === 'desc' && <SortDownIcon />}
        </StyledSortIcon>
      )}
    </StyledHeaderColumn>
  );
};

Column.defaultProps = {
  disabled: false,
};

export { Column };
