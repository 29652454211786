import { Report } from 'ducks/reports/types';
import React, { FC } from 'react';
import { EmbededReport } from './EmbededReport';

interface ReportsTabsPropsTypes {
  fetchedReports: Report[];
  title: string;
}

export const ReportsTabs: FC<ReportsTabsPropsTypes> = ({ fetchedReports, title }) => {
  const [chosenReport, setChosenReport] = React.useState('');

  React.useEffect(() => {
    if (fetchedReports && fetchedReports.length > 0) {
      setChosenReport(fetchedReports.filter((report) => report.title === title)[0].embedUrl);
    }
  }, []);

  if (!fetchedReports || fetchedReports.length < 1 || !chosenReport) {
    return null;
  } else {
    return (
      <>
        <EmbededReport url={chosenReport} />
      </>
    );
  }
};