import {
  CompanyVisit,
  CompanyVisitResponse,
  NewVisitState,
  NewVisitType,
  NewVisitWithActiveCompany,
  NewVisitWorkplaceStepType,
} from 'ducks/newVisit/types';

export const amendCompanyVisitResponse = (companyVisit: CompanyVisitResponse): CompanyVisit => ({
  ...companyVisit,
  commentAttachmentsToRemove: [],
  personalNotesAttachmentsToRemove: [],
});

export const amendVisitResponse = (
  visit: NewVisitWithActiveCompany,
  withoutResetActiveCompanyVisit?: boolean,
): NewVisitState => {
  const { companyVisits, id, userId, ...rest } = visit;

  const amendedCompanyVisits = companyVisits.map(amendCompanyVisitResponse);
  let workplaceOption: NewVisitWorkplaceStepType = 'New';
  if (!visit.workplace && companyVisits.length === 1) workplaceOption = 'SameAsCompany';
  if (visit.workplaceId) workplaceOption = 'Find';
  const type = 'Ordinary' as NewVisitType;

  const visitWithResponse = {
    ...rest,
    companyVisits: amendedCompanyVisits,
    id: String(id),
    type,
    userId: userId,
    workplaceOption,
  };

  if (withoutResetActiveCompanyVisit) {
    return visitWithResponse;
  }

  return {
    ...visitWithResponse,
    activeCompanyVisit: null,
  };
};
