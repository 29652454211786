import * as yup from 'yup';
import { setIn } from 'final-form';

export const yupValidate =
  (schema: yup.Schema<unknown>, options?: yup.ValidateOptions) => async (values: object) => {
    try {
      await schema.validate(values, { abortEarly: false, ...options });
    } catch (e) {
      return (e as yup.ValidationError).inner?.reduce((errors, error) => {
        return setIn(errors, error.path, error.message);
      }, {});
    }
  };

export const validateSchema = async (
  schema: yup.Schema<unknown>,
  values: {},
  options?: yup.ValidateOptions,
) => {
  try {
    await schema.validate(values, options);
    return true;
  } catch (e) {
    return false;
  }
};
