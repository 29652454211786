import React from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { StyledModalButtonsWrapper, StyledModalHeader, useModal } from 'components/Modal/Modal';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { WorkplaceForm } from 'containers/newVisit/shared/WorkplaceForm';
import { createWorkplace, editWorkplace } from 'ducks/powerSearch/workplace/actions';
import { GetWorkplaceResponse } from 'ducks/powerSearch/workplace/types';
import { useAppDispatch } from 'store';
import { currentUserSelector } from 'ducks/users/selectors';

interface WorkplaceModalPropsType {
  workplace?: GetWorkplaceResponse;
  isDisabled?: boolean;
}

export const WorkplaceModal = ({ workplace, isDisabled }: WorkplaceModalPropsType) => {
  const isEdit = !!workplace;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useSelector(currentUserSelector)!;
  const { hideModal } = useModal();
  const initialValues = workplace
    ? {
        kind: workplace.kind ? { label: workplace.kind, value: workplace.kind } : undefined,
        name: workplace.name || '',
        postal_area_name: workplace.postalAreaName || undefined,
        postal_area_id: workplace.postalAreaId || undefined,
        post_code: workplace.postalAreaCode || '',
        municipality: {
          label: workplace.municipalityName,
          value: Number(workplace.municipalityId),
        },
        street: workplace.street || workplace.address || '',
        street_number: workplace.streetNumber || '',
        proprietorship: workplace.proprietorshipId
          ? { label: workplace.proprietorshipName!, value: workplace.proprietorshipId }
          : undefined,
        termination: workplace.termination?.split('.').reverse().join('.'),
        comment: workplace.comment || '',
      }
    : undefined;

  const heading = () => {
    if (isDisabled) return `${t('new_visits.show_workplace')} - ${workplace?.industryName}`;
    return isEdit
      ? `${t('new_visits.edit_workplace')} - ${workplace?.industryName}`
      : `${t('new_visits.new_workplace')} - ${user.industryName}`;
  };

  return (
    <>
      <StyledModalHeader>{heading()}</StyledModalHeader>
      <WorkplaceForm
        Buttons={({ onClick, isPrimaryDisabled }) =>
          isDisabled ? (
            <>
              <StyledModalButtonsWrapper>
                <DefaultButton
                  capitalize
                  label={t('common.close')}
                  onClick={hideModal}
                  type='button'
                  variant='secondary'
                />
              </StyledModalButtonsWrapper>
            </>
          ) : (
            <>
              <StyledModalButtonsWrapper>
                <DefaultButton
                  capitalize
                  label={t('common.cancel')}
                  onClick={hideModal}
                  type='button'
                  variant='secondary'
                />
                <DefaultButton
                  capitalize
                  isDisabled={isPrimaryDisabled}
                  label={t('common.save')}
                  onClick={onClick}
                  type='button'
                  variant='primary'
                />
              </StyledModalButtonsWrapper>
            </>
          )
        }
        buttonsPosition='outside'
        init={initialValues}
        isCommentEditable={workplace?.isCommentEditable}
        isDisabled={isDisabled}
        onSubmit={(values) =>
          dispatch(
            isEdit
              ? editWorkplace({ ...values, id: workplace!.workplaceId })
              : createWorkplace(values),
          )
            .then(unwrapResult)
            .then(hideModal)
        }
      />
    </>
  );
};
