import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { globalHistory, navigate } from '@reach/router';
import { Cancelable, debounce, isEqual, omit } from 'lodash';
import axios, { CancelTokenSource } from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { Tag } from 'components/Tag/Tag';
import { TextInput } from 'components/TextInput/TextInput';
import { StandardLabel } from 'components/StandardLabel/StandardLabel';
import { useOnClickOutside } from 'utils/hooks';
import { ClearStyles, Margin } from 'styles/utils';
import { searchMatches } from 'ducks/powerSearch/actions';
import { TagColorVariant } from 'components/Tag/colorVariants';
import { resetSearchResults, setQuery } from 'ducks/powerSearch/actions';
import {
  SearchBarWrapper,
  StyledSearchBarDropdown,
  StyledSearchbarRow,
  StyledSearchIcon,
} from './styles';
import { formatQueryDate } from 'utils/date';
import { setDistrict } from 'ducks/powerSearch/reducer';
import { allFilteredDistrictsSelector, chosenDistrictSelector } from 'ducks/powerSearch/selectors';
import { Option } from 'components/MultiSelectList/MultiSelectList';
import { MultiSelectField } from 'components/Forms/MultiSelectField/MultiSelectField';

import { ReactComponent as ArrowRightBigIcon } from 'images/arrowRightBigIcon.svg';
import { ReactComponent as LoadingIcon } from 'images/spinner.svg';

const categories = [
  'company',
  'company_visits',
  'workplace',
  'contact',
  'prior_notifications',
  'revised_activities',
] as const;

type PowerSearchCategoryTuple = typeof categories;
type PowerSearchCategory = PowerSearchCategoryTuple[number];

export interface CategoryMatches {
  category: PowerSearchCategory;
  matches: number | null;
}

const MatchesInit = categories.map((category) => ({ category, matches: null }));

const categoryColorVariants: { [key: string]: TagColorVariant } = {
  prior_notifications: 'lightgreen',
  company: 'blue',
  contact: 'lightblue',
  company_visits: 'green',
  workplace: 'purple',
  revised_activities: 'pink',
};

const SearchBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { location } = globalHistory;
  const [districts, saveDistricts] = useState<Option[]>([]);
  const chosenDistrict = useSelector(chosenDistrictSelector);
  const allDistricts = useSelector(allFilteredDistrictsSelector);

  useEffect(() => {
    saveDistricts(allDistricts);
    const areAllDistrictsSelected = chosenDistrict.length === allDistricts.length;
    sessionStorage.setItem('areAllDistrictsSelected', String(areAllDistrictsSelected));
  }, []);

  // const urlParams = useParams();
  // WAIT UNTIL FIXED: useParams sometimes return null with embedded router https://github.com/reach/router/pull/392
  // Maybe we will finally make it in 2021 :)
  const pathArray = window.location.pathname.split('/');
  const urlCategory = pathArray.includes('search')
    ? (pathArray[pathArray.length - 1] as PowerSearchCategory)
    : null;
  const urlParams = urlCategory && categories.includes(urlCategory) ? urlCategory : null;
  const userQuery = queryString.parse(location.search).q?.toString() || '';

  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedCategory, pickCategory] = useState<PowerSearchCategory | null>(urlParams);
  const [searchedText, setText] = useState<string>(userQuery);
  const [isOpen, setIsOpen] = useState(false);
  const [matches, setMatches] = useState<Array<CategoryMatches>>(MatchesInit);
  const [fetching, setFetching] = useState(false);

  const promise = useRef<CancelTokenSource | null>(null);
  const debouncedSearch = useRef<(((text: string) => Promise<void>) & Cancelable) | null>(null);

  useEffect(() => {
    // it is super weird, but this is what client expect.
    // see more details https://skalar.atlassian.net/browse/RVO-828
    // related file: packages/client/src/api/index.js:18
    const areAllDistrictsSelected = chosenDistrict.length === districts.length;
    sessionStorage.setItem('areAllDistrictsSelected', String(areAllDistrictsSelected));
    // end of super weird

    debouncedSearch.current = debounce(async (text: string) => {
      if (promise.current) promise.current.cancel();
      const CancelToken = axios.CancelToken;
      promise.current = CancelToken.source();

      if (text.length > 0) {
        setFetching(true);
        const response = await searchMatches(
          {
            company: {
              'filter[district_names][values]': chosenDistrict,
            },
            workplace: {
              'filter[district_names][values]': chosenDistrict,
              'filter[termination][min]': formatQueryDate(new Date()),
            },
            priorNotifications: {
              'filter[district_names][values]': chosenDistrict,
            },
            companyVisit: {
              'filter[district_names][values]': chosenDistrict,
            },
            contacts: {
              'filter[district_names][values]': chosenDistrict,
            },
            revisedActivities: {
              'filter[district_names][values]': chosenDistrict,
            },
            query: text,
          },
          promise.current,
        );
        response && setMatches(response);
        response && setFetching(false);
      } else {
        setMatches(MatchesInit);
        setFetching(false);
      }
    }, 300);
  }, [chosenDistrict]);

  useEffect(() => {
    setText((prev) => (isEqual(prev, userQuery) ? prev : userQuery!));
  }, [userQuery]);

  useEffect(() => {
    pickCategory((prev) => (isEqual(prev, urlParams) ? prev : urlParams));
  }, [urlParams]);

  useEffect(() => {
    if (location.state?.districtNames) {
      dispatch(setDistrict(location.state?.districtNames?.values));
    }
  }, [location.pathname]);

  const districtLabelFunction = useCallback(
    (labels: string[], placeholder?: string) => {
      if (labels?.length) {
        if (labels.length === districts?.length) return t('common.whole_country');
        return labels.length > 2
          ? `${[...labels].splice(0, 2).join(', ')} + ${labels.length - 2}`
          : labels.join(', ');
      } else {
        return placeholder;
      }
    },
    [districts],
  );

  const setDistrictToState = (newDistrict: string[]) => {
    const newState = {
      ...location.state,
      districtNames: {
        type: 'string_array',
        values: newDistrict,
      },
    };

    navigate(
      location.pathname.includes('redesigned/search')
        ? `/redesigned/search/${selectedCategory}${searchedText && `?q=${searchedText}`}`
        : location.pathname + location.search,
      {
        replace: location.pathname.includes('redesigned/search'),
        state: newState,
      },
    );
  };

  useEffect(() => {
    setDistrictToState(chosenDistrict);
  }, [chosenDistrict, pickCategory]);

  const commitSearch = (category: PowerSearchCategory) => {
    if (selectedCategory === category && searchedText === userQuery) return setIsOpen(false);
    const filterKeys = Object.keys(localStorage).filter((key) => /\/search\//.test(key));
    filterKeys.forEach((key) => localStorage.removeItem(key));

    let newState = { ...(location?.state || {}) };
    if (selectedCategory !== category) {
      newState = { ...newState, search: omit(location?.state?.search, 'filters') || {} };
    }

    pickCategory(category);
    dispatch(resetSearchResults());
    dispatch(setQuery(searchedText));

    if (newState?.search?.currentPage) {
      newState.search.currentPage = 1;
    }
    if (newState?.search?.rowsPerPage) {
      newState.search.rowsPerPage = 10;
    }
    navigate(`/redesigned/search/${category}${searchedText && `?q=${searchedText}`}`, {
      replace: location.pathname.includes('redesigned/search'),
      state: newState,
    });
    setIsOpen(false);
  };

  const searchbarRef = useRef(null);
  useOnClickOutside(searchbarRef, () => setIsOpen(false));

  return (
    <SearchBarWrapper ref={searchbarRef}>
      <StyledSearchIcon />
      {selectedCategory && (
        <Tag
          handleClick={() => {
            if (inputRef && inputRef.current) inputRef.current.focus();
            pickCategory(null);
          }}
          value={t(`common.${selectedCategory}`)}
          variant={categoryColorVariants[selectedCategory]}
        />
      )}
      <TextInput
        inputProps={{
          value: searchedText,
          'data-testid': 'searchbar-input',
          onFocus: () => setIsOpen(true),
          onChange: (e) => {
            const { value } = e.currentTarget;
            debouncedSearch.current!(value);
            setText(value);
          },
        }}
        placeholder={t('top_bar.search.placeholder')}
        ref={inputRef}
      />
      <ClearStyles margin>
        <MultiSelectField
          buttonTestId='searchbar-district-select'
          customLabel={districtLabelFunction}
          input={{
            name: 'district',
            onBlur: () => {},
            onChange: (value) => dispatch(setDistrict(value)),
            onFocus: () => {},
            value: chosenDistrict,
          }}
          meta='amfetamina'
          options={districts}
          placeholder={t('common.whole_country')}
        />
      </ClearStyles>
      {isOpen && (
        <StyledSearchBarDropdown data-testid='dropdown-inner' noPadding>
          {matches.map((item: CategoryMatches) => (
            <StyledSearchbarRow key={item.category} onClick={() => commitSearch(item.category)}>
              <Tag
                value={t(`common.${item.category}`)}
                variant={categoryColorVariants[item.category]}
              />

              {searchedText && item.matches !== null && !fetching && (
                <Margin left='2.4rem'>
                  <StandardLabel variant='thin'>{item.matches}</StandardLabel>
                </Margin>
              )}

              {fetching && (
                <Margin left='2.4rem'>
                  <StandardLabel variant='thin'>
                    <LoadingIcon data-testid='searchbar-loading-icon' />
                  </StandardLabel>
                </Margin>
              )}

              <Margin left='auto'>
                <ArrowRightBigIcon />
              </Margin>
            </StyledSearchbarRow>
          ))}
        </StyledSearchBarDropdown>
      )}
    </SearchBarWrapper>
  );
};

export { SearchBar };
