import { createAsyncThunk } from '@reduxjs/toolkit';
import * as FileSaver from 'file-saver';

import { API } from 'api';
import { SearchCompanyParams, SearchCompanyResponse } from './types';
import { AxiosError } from 'axios';

export const searchCompanies = createAsyncThunk(
  'powerSearch/companies',
  async (params: SearchCompanyParams, { rejectWithValue, signal }) => {
    try {
      const response = await API.get(`power/companies`, { params, signal });
      return response.data as SearchCompanyResponse;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const getCompaniesCSV = async (ids: string[]) => {
  const response = await API.post(
    `power/companies`,
    {
      id: ids.join(','),
    },
    {
      params: { page: 1, page_size: 10000 },
      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
      },
    },
  );

  const blob = new Blob([response.request.response], {
    type: 'application/vnd.ms-excel',
  });

  FileSaver.saveAs(blob, 'companies-export.xls');
  return response.data as SearchCompanyResponse;
};
