import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { revisedActivitiesTableMapper } from './revisedActivitiesTableMapper';
import {
  TableProvidersProps,
  useTableContexts,
  withTableProviders,
} from 'hocs/composedTableProviders/composedTableProviders';
import { StoreType } from 'store';
import { TableGrid } from 'components/Table/TableGrid/TableGrid';
import {
  getRevisedActivitiesCSV,
  searchAllRevisedActivities,
  searchRevisedActivities,
} from 'ducks/powerSearch/revisedActivities/actions';
import {
  StyledColumn,
  StyledContainer,
  StyledTableBottomPanel,
} from 'containers/companyDetails/Visits/styles';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { TableFilters } from 'components/TableFilters/TableFilters';
import { PaginationComposed } from 'components/Pagination/Pagination';
import { CommonActionsPanel } from 'components/CommonActionsPanel/CommonActionsPanel';
import { ButtonWrapper } from 'components/ButtonWrapper/ButtonWrapper';
import { useLoadingContext } from 'contexts/LoadingContext';
import { StyledCardMax } from 'components/Card/styles';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useModal } from 'components/Modal/Modal';
import { SaveSearch } from './resultsModals';
import { getActiveFiltersState } from 'utils';
import { resetSearchResults } from 'ducks/powerSearch/actions';
import { RvoEventsEntity, RvoEventsEntityResponse } from 'ducks/events/types';
import { Tooltip } from 'components/Tooltip/Tooltip';
import {
  AddOrEditRevisedActivity,
  DeleteRevisedActivity,
  ShowRevisedActivity,
} from 'dialogs/RevisedActivities';
import { checkIsIpad } from 'utils/deviceChecker';

import { ReactComponent as BookmarkIcon } from 'images/bookmarkIcon.svg';
import { ReactComponent as DeleteIcon } from 'images/deleteIcon.svg';
import { SearchRevisedActivitiesParams } from 'ducks/powerSearch/revisedActivities/types';
import { toast } from 'react-hot-toast';
import { clearRevisedActivitiesSearchResultsError } from 'ducks/powerSearch/revisedActivities/reducer';
import { setOnlyMine } from 'ducks/powerSearch/reducer';

const RevisedActivitiesResults: FC<TableProvidersProps> = (props) => {
  const {
    query,
    revisedActivities: results,
    showOnlyMine,
  } = useSelector((state: StoreType) => state.powerSearch);
  const {
    commonAction,
    deleteSelection,
    filtersState,
    itemsSelected,
    setContextName,
    addMultipleSelection,
    filterParams,
  } = useTableContexts();
  const { setLoaded, setLoading } = useLoadingContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showModal, setModalComponent, setShowHeader } = useModal();

  useEffect(() => {
    setContextName('powerRevisedActivities');
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetSearchResults());
    };
  }, [dispatch, resetSearchResults]);

  // --- action handlers ----
  const editHandler = (entity: RvoEventsEntity) => {
    setModalComponent(<AddOrEditRevisedActivity companyId={entity.companyId} event={entity} />);
    showModal();
  };

  const deleteHandler = (entity: RvoEventsEntity) => {
    setModalComponent(
      <DeleteRevisedActivity
        actionCallback={() => deleteSelection(entity.id)}
        itemsSelected={[entity]}
      />,
    );
    setShowHeader(false);
    showModal();
  };

  const showHandler = (entity: RvoEventsEntity) => {
    setModalComponent(<ShowRevisedActivity event={entity} />);
    setShowHeader(true);
    showModal();
  };

  const selectAllHandler = async () => {
    setLoading('powerRevisedActivities');
    const params = {
      ...filterParams,
      ...props.fetchParams,
    } as unknown as SearchRevisedActivitiesParams;
    const response = await searchAllRevisedActivities(params);

    addMultipleSelection(
      response.data.tableBody.map((item: RvoEventsEntityResponse) => ({
        entity: { ...item, id: item.revisedActivityId },
        id: item.revisedActivityId,
      })),
    );
    setLoaded('powerRevisedActivities');
  };

  const exportCsvHandler = () => {
    setLoading('powerRevisedActivities');
    getRevisedActivitiesCSV(itemsSelected.map((item: RvoEventsEntity) => item.id));
    setLoaded('powerRevisedActivities');
  };

  const actionHandlers = {
    exportCSV: exportCsvHandler,
    show: showHandler,
    delete: deleteHandler,
    edit: editHandler,
    selectAll: selectAllHandler,
  };

  if (results.error) {
    toast.error(results.error);
    dispatch(clearRevisedActivitiesSearchResultsError());
  }

  const isIpad = checkIsIpad();

  const onChange = () => {
    dispatch(setOnlyMine(!showOnlyMine));
  };

  return (
    // @ts-ignore
    <StyledContainer isIpad={isIpad}>
      <StyledCardMax>
        <StyledColumn>
          <TableFilters isSearchPagination />
          <Checkbox
            isChecked={showOnlyMine}
            onClick={onChange}
            label={t(`power_search.show_only_mine`)}
          />
          <TableGrid
            actionHandlers={actionHandlers}
            addButton={
              <DefaultButton
                icon={<BookmarkIcon />}
                label={t(`power_search.save_search`)}
                onClick={() => {
                  setModalComponent(
                    <SaveSearch
                      params={{
                        state: getActiveFiltersState(filtersState),
                        query,
                        tag: 'revised_activities',
                      }}
                    />,
                  );
                  setShowHeader(false);
                  showModal();
                }}
              />
            }
            allFilteredOutMessage={t('company_details.events.all_filtered_out')}
            bodyData={results.data.tableBody}
            columnsOrder={results.meta.columnsOrder}
            componentsMap={revisedActivitiesTableMapper}
            isSearchPagination
            loadResource='powerRevisedActivities'
            noResultsMessage={t('company_details.events.no_results')}
            resultsSelector='company_details.events'
            totalCount={results.meta.totalCount}
            withCheckbox
            withFullWidth
          />
          <StyledTableBottomPanel>
            <PaginationComposed loadResource='powerRevisedActivities' />
            <ButtonWrapper align='right'>
              <CommonActionsPanel actionHandlers={actionHandlers}>
                <Tooltip label={t('common.delete_selected')}>
                  <DefaultButton
                    icon={<DeleteIcon />}
                    isDisabled={!itemsSelected.length || itemsSelected?.length > 1}
                    onClick={commonAction ? commonAction.action : () => {}}
                    testid='revised-activities-delete-btn'
                    variant='secondary'
                  />
                </Tooltip>
              </CommonActionsPanel>
            </ButtonWrapper>
          </StyledTableBottomPanel>
        </StyledColumn>
      </StyledCardMax>
    </StyledContainer>
  );
};

const ResultsComposed = withTableProviders(RevisedActivitiesResults, {
  fetchFunction: searchRevisedActivities,
  loadResource: 'powersearch',
  metaUrl: 'power/revised_activities',
  spyDistrict: true,
  spyQuery: true,
  spyOnlyMine: true,
});

export { ResultsComposed as RevisedActivitiesResults };
