import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enLocales from 'locales/en.json';
import noLocales from 'locales/no.json';

const { NODE_ENV } = window.REACT_APP_ENV || 'test';

const resources = {
  en: {
    translation: enLocales,
  },
  no: {
    translation: noLocales,
  },
};

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend)
  // detect user language:
  // via cookie (set cookie i18next=LANGUAGE)
  // via localStorage (set key i18nextLng=LANGUAGE)
  // via navigator (set browser language)
  // via querystring (append ?lng=LANGUAGE to URL)
  // via htmlTag (add html language tag <html lang="LANGUAGE" ...)
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: 'no',
    fallbackLng: 'en',
    debug: !NODE_ENV === 'production',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
document.documentElement.lang = i18n.language;

// eslint-disable-next-line import/no-default-export
export default i18n;
