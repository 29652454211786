import styled from 'styled-components';

export const StyledCompanyVisitsTabsWrap = styled.div`
  align-items: center;
  background-color: white;
  display: flex;
  margin-bottom: ${({ theme }) => theme.sizes.hmd};
  position: sticky;
  top: 0;
  z-index: 98;

  &:after,
  &:before {
    background-color: white;
    content: '';
    height: ${({ theme }) => theme.sizes.hmd};
    position: absolute;
    width: 100%;
  }

  &:after {
    bottom: -${({ theme }) => theme.sizes.hmd};
  }

  &:before {
    top: -${({ theme }) => theme.sizes.hmd};
  }

  & > div {
    overflow-x: auto;
  }

  & > .company-visit-tabs-add-new {
    flex: 0 0 auto;
    margin-left: ${({ theme }) => theme.sizes.hmd};
  }
`;

export const StyledRow = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.sizes.md};

  & > * {
    margin-right: ${({ theme }) => theme.sizes.lg};
    margin-top: ${({ theme }) => theme.sizes.hmd};
    margin-bottom: 0;
  }
`;

export const StyledCompanyTab = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  & > span {
    margin-top: ${({ theme }) => theme.sizes.xxs};
  }
`;

export const StyledCompanyTabText = styled.div<{ active: boolean }>`
  color: ${({ active, theme }) => (active ? theme.colors.black900 : theme.colors.black300)};
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0.8rem;
`;

export const StyledCompanyVisitsTabsBorder = styled.div`
  &:after {
    background-color: ${({ theme }) => theme.colors.black300};
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
  }
`;
