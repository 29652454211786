import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useTranslation } from 'react-i18next';

import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { DatepickerField } from 'components/Forms/DatepickerField/DatepickerField';
import { TextareaField } from 'components/Forms/TextareaField/TextareaField';
import { useModal } from 'components/Modal/Modal';
import { StyledModalButtonsWrapper, StyledModalHeader } from 'components/Modal/styles';
import { StyledForm } from 'components/Forms/styles';
import { TextField } from 'components/Forms/TextField/TextField';
import { CheckboxField } from 'components/Forms/CheckboxField/CheckboxField';
import { formatQueryDate } from 'utils/date';
import { yupValidate } from 'utils/yupValidate';
import { planAVisitSchema } from 'dialogs/PriorNotices/validation';
import { SpinnerIcon } from 'utils/iconsMap';
import { isEmpty } from 'lodash';
import { IPlanAVistFormValues } from 'dialogs/PriorNotices/types';
import { StyledError } from 'containers/newVisit/OrdinaryVisit/styles';
import { SerializedError } from '@reduxjs/toolkit';
import { Margin } from 'styles/utils';
import { scheduleCompanyVisit } from 'dialogs/PriorNotices/utils';

interface PlanAVisitProps {
  priorNotificationId: string;
  companyName: string;
}

export const PlanAVisit = (props: PlanAVisitProps) => {
  const { priorNotificationId, companyName } = props;
  const { hideModal } = useModal();

  const { t } = useTranslation();
  const onSubmit = React.useCallback(
    async (values: IPlanAVistFormValues) => {
      const { date, ...rest } = values;
      const normalizedData = { date: formatQueryDate(date)!, ...rest, priorNotificationId };
      return scheduleCompanyVisit(normalizedData)
        .then(() => hideModal())
        .catch((err: SerializedError) => ({ [FORM_ERROR]: err.message }));
    },
    [priorNotificationId],
  );

  const initialValues = React.useMemo(() => {
    return {
      addToCalendar: false,
      comment: '',
      date: '',
      title: companyName,
    };
  }, []);

  return (
    <>
      <StyledModalHeader>{t('power_search.prior_notifications.plan_a_visit')}</StyledModalHeader>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, submitError }) => (
          <StyledForm onSubmit={handleSubmit}>
            <Field
              component={TextField}
              label={t('table.columns.title')}
              name='title'
              required
              subscription={{ value: true, touched: true, error: true }}
            />
            <Field
              component={DatepickerField}
              label={t('table.columns.date')}
              name='date'
              required
              subscription={{ value: true, touched: true, error: true }}
              width='fullWidth'
            />
            <Field
              component={TextareaField}
              label={t('table.columns.comment')}
              maxRows={10}
              name='comment'
              placeholder={t('forms.write_a_message')}
              subscription={{ value: true }}
            />

            <Field
              component={CheckboxField}
              label={t('table.columns.add_to_calendar')}
              name='addToCalendar'
              subscription={{ value: true }}
            />
            {submitError && (
              <Margin margin='1.2rem auto'>
                <StyledError>{submitError}</StyledError>
              </Margin>
            )}

            <StyledModalButtonsWrapper>
              <DefaultButton
                capitalize
                isDisabled={submitting}
                label={t('common.cancel')}
                onClick={hideModal}
                type='button'
                variant='tertiary'
              />
              <FormSpy subscription={{ errors: true }}>
                {({ errors }) => (
                  <DefaultButton
                    capitalize
                    icon={submitting ? <SpinnerIcon /> : null}
                    isDisabled={!isEmpty(errors) || submitting}
                    label={t('forms.save_changes')}
                    testid='submit-add-edit-rvo-form'
                    type='submit'
                  />
                )}
              </FormSpy>
            </StyledModalButtonsWrapper>
          </StyledForm>
        )}
        subscription={{ values: true, submitting: true, submitError: true }}
        validate={yupValidate(planAVisitSchema)}
      />
    </>
  );
};
