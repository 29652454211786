import React, { useEffect } from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserSelector } from 'ducks/users/selectors';
import { getCurrentUser } from 'ducks/users/actions';

export const ProtectedRoute: React.FC<RouteComponentProps> = ({ uri, children }) => {
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  const user = useSelector(currentUserSelector);

  const fetchUser = async () => {
    await dispatch(getCurrentUser());
    setLoading(false);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  if (loading) return null;

  if (user && uri?.includes('users'))
    return <Redirect from='' to='/redesigned/dashboard' noThrow />;
  if (!user && !uri?.includes('users'))
    return <Redirect from='' to='/redesigned/users/sign_in' noThrow />;

  return <>{children}</>;
};
