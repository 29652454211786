import styled from 'styled-components/macro';

export const StyledAvatar = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black700};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  border: 1px ${({ theme }) => theme.colors.black700} solid;
  color: white;
  display: flex;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.025rem;
  padding: ${({ theme }) => theme.sizes.xs};
  text-transform: uppercase;

  & > span {
    ${({ theme }) => theme.fontSizes.base};
    ${({ theme }) => theme.icon.md};
    line-height: ${({ theme }) => theme.lineHeight.lg};
  }
`;
