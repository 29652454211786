import React, { ComponentType } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Checkbox } from 'components/Checkbox/Checkbox';
import { StyledFieldWrapper } from 'components/Forms/styles';
import { StyledCheckboxWrapper } from './styles';

interface CheckboxFieldPropTypes extends FieldRenderProps<boolean> {
  label?: string;
  isDisabled?: boolean;
  testid?: string;
}

const CheckboxField: ComponentType<CheckboxFieldPropTypes> = ({
  input: { onChange, value },
  label,
  isDisabled = false,
  testid,
}: CheckboxFieldPropTypes) => {
  return (
    <StyledFieldWrapper>
      <StyledCheckboxWrapper>
        <Checkbox
          isChecked={value}
          isDisabled={isDisabled}
          label={label}
          onClick={() => {
            onChange(!value);
          }}
          testid={testid}
        />
      </StyledCheckboxWrapper>
    </StyledFieldWrapper>
  );
};

export { CheckboxField };
