import styled from 'styled-components/macro';

export const StyledTopBarWrap = styled.div`
  background-color: ${window.REACT_APP_ENV.BUGSNAG_RELEASE_STAGE === 'staging'
    ? '#64B6AC'
    : 'white'};
  box-shadow: ${({ theme }) => theme.boxShadows.coolGrey300};
  height: calc(${({ theme }) => theme.sizes.md} * 2.5);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
`;
