import * as yup from 'yup';
import { CancelTokenSource } from 'axios';
import { capitalize } from 'lodash';

import i18n from 'i18n';
import { API } from 'api';
import { SearchCompanyResponse } from 'ducks/powerSearch/company/types';
import { Option } from 'components/AsyncMultiselect/AsyncMultiSelect';
import { SearchContactsResponse } from 'ducks/powerSearch/contacts/types';
import { SearchCompanyVisitsResponse } from 'ducks/powerSearch/companyVisits/types';
import { formatDate } from 'utils/date';

const t = (x: string) => i18n.t(x);

const isTimeGreaterThan = (from: string, to: string) => {
  const [fromHour, fromMinutes] = from.split(':');
  const [toHour, toMinutes] = to.split(':');

  if (fromHour > toHour) return false;
  if (fromHour === toHour && fromMinutes >= toMinutes) return false;

  return true;
};

export const addOrEditRevisedActivitySchema = yup.object().shape({
  type: yup.string().required(),
  subtype: yup.string().nullable(),
  description: yup.string().max(1000).required(),
  date: yup.mixed().test('date test', t('forms.required'), (val) => !!val),
  to_date: yup.mixed().test('to_date test', t('forms.required'), (val) => !!val),
  to_time: yup.string().test('to_time test', t('forms.invalid_time'), function (val) {
    if (this.parent.time && this.parent.date === this.parent.to_date) {
      return isTimeGreaterThan(this.parent.time, val);
    } else {
      return true;
    }
  }),
});

export const tipsAtForRevisedActivities = yup.object().shape({
  tipsThemeId: yup.string().required(),
  comment: yup.string().max(250).required(),
});

export const fetchCompanies = async (query: string, token?: CancelTokenSource) => {
  const response = await API.get<SearchCompanyResponse>(`power/companies`, {
    params: { query },
    cancelToken: token?.token,
  });

  const serializeData: Option[] = response.data.data.tableBody.map(
    ({ companyId, name, address }) => ({
      description: address,
      label: name,
      value: companyId,
    }),
  );

  return serializeData;
};

export const fetchContacts = async (
  query: string,
  companyNames?: string[],
  token?: CancelTokenSource,
) => {
  const response = await API.get<SearchContactsResponse>('power/contacts', {
    params: { query, 'filter[company_name][values]': companyNames },
    cancelToken: token?.token,
  });

  const serializeData: Option[] = response.data.data.tableBody.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  return serializeData;
};

export const fetchCompanyVisits = async (companyNames?: string[], token?: CancelTokenSource) => {
  const response = await API.get<SearchCompanyVisitsResponse>('power/company_visits', {
    params: { 'filter[company_name][values]': companyNames, page_size: 1000, 'sort[date]': 'desc' },
    cancelToken: token?.token,
  });

  const serializeData: Option[] = response.data.data.tableBody.map(
    ({ companyVisitId, date, workplace, status, rvo }) => ({
      additionalLabel: [capitalize(t(`filters.${status}`)), rvo].filter(Boolean).join(', '),
      label: `${formatDate(date.value)} - ${workplace?.value || t('workplace.no_workplace')}`,
      value: companyVisitId,
    }),
  );

  return serializeData;
};
