import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { StyledContainer } from '../Visits/styles';
import { WorkplaceEntity } from 'ducks/powerSearch/workplace/types';
import { getWorkplace } from 'ducks/workplaces/actions';
import { WorkplaceChosen } from 'containers/newVisit/OrdinaryVisit/Step1/WorkplaceChosen';
import { connect, useDispatch } from 'react-redux';
import { StoreType } from 'store';

interface BasicDataProps extends RouteComponentProps {
  workplace: WorkplaceEntity | null;
}

const BasicData = ({ workplace }: BasicDataProps) => {
  const [edited, setEdited] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    workplace?.workplaceId && dispatch(getWorkplace(workplace.workplaceId));
    setEdited(false);
  }, [edited]);

  if (!workplace) return null;
  return (
    <StyledContainer>
      <WorkplaceChosen setEdited={setEdited} workplace={workplace} />
    </StyledContainer>
  );
};

export const BasicDataWorkplace = connect((state: StoreType) => ({
  workplace: state.workplaces.workplace,
}))(BasicData);
