import styled from 'styled-components/macro';

import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { PopperDropdown } from 'components/PopperDropdown/PopperDropdown';
import { StyledDropdownContainer } from 'components/PopperDropdown/styles';

export const StyledForm = styled.form`
  margin: 0 auto;
  width: 100%;
`;

export const StyledLabel = styled.label`
  color: ${({ theme }) => theme.colors.black700};
  display: block;
  font-weight: 600;
  margin-bottom: ${({ theme }) => theme.sizes.xs};
`;

export const StyledDescription = styled.div`
  ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.black300};
  margin-top: ${({ theme }) => theme.sizes.xs};
`;

export const StyledError = styled.div`
  color: ${({ theme }) => theme.colors.red500};
  ${({ theme }) => theme.fontSizes.xs};
  margin-top: ${({ theme }) => theme.sizes.xs};
`;

export const StyledFieldWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.sizes.sm};
`;

export const StyledVerticalFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => `0 ${theme.sizes.xs} ${theme.sizes.sm} ${theme.sizes.xs}`};
`;

export const StyledCheckboxWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  & ${StyledFieldWrapper} {
    margin-bottom: ${({ theme }) => theme.sizes.xs};
    margin-right: ${({ theme }) => theme.sizes.md};
  }
`;

export const StyledToggleButton = styled(DefaultButton)`
  justify-content: space-between;
  text-align: left;
  width: 100%;
`;

export const StyledFieldDropdown = styled(PopperDropdown)`
  margin: 0;

  & ${StyledDropdownContainer} {
    width: 100%;
  }
`;
