import React, { useEffect, useState } from 'react';
import { FieldMetaState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { isBefore, isDate } from 'date-fns';

import {
  StyledDescription,
  StyledError,
  StyledFieldWrapper,
  StyledLabel,
} from 'components/Forms/styles';
import { PopperDropdown, useDropdownContext } from 'components/PopperDropdown/PopperDropdown';
import { StyledCalendar, StyledCalendarWrapper } from 'components/DatePicker/styles';
import {
  formatDate,
  formatMonth,
  formatMonthYear,
  formatQueryDate,
  formatWeekdayShort,
} from 'utils/date';
import { StyledButton, StyledCalendarIcon, StyledClearIcon, StyledPlaceholder } from './styles';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { dateExceedDeadline } from 'utils/cells';

import { ReactComponent as ArrowLeftBigIcon } from 'images/arrowLeftBigIcon.svg';
import { ReactComponent as ArrowRightBigIcon } from 'images/arrowRightBigIcon.svg';

// TODO fix typigns, this is a mess

interface ToggleComponentPropTypes {
  buttonTestId?: string;
  isDisabled?: boolean;
  onChange?: (value: string | null) => void;
  onClick?: () => void;
  placeholder?: string;
  setDate?: (value: Date | null) => void;
  size?: 'small' | 'medium';
  value?: Date | null;
  width?: 'fullWidth';
  withClearIcon?: boolean;
  withDeadline?: boolean;
}

interface InnerComponentPropTypes {
  date?: Date | Date[] | null;
  minDate?: Date;
  maxDate?: Date;
  onChange: (value: string | null) => void;
  setDate: (value: Date | null) => void;
}

export interface DatepickerInputPropTypes {
  buttonTestId?: string;
  className?: string;
  description?: string;
  isDisabled?: boolean;
  label?: string;
  meta?: FieldMetaState<string>;
  minDate?: Date;
  maxDate?: Date;
  name?: string;
  onBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (value: string | null) => void;
  onFocus?: (event?: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required?: boolean;
  size?: 'small' | 'medium';
  type?: string;
  value: string | null;
  width?: 'fullWidth';
  withDeadline?: boolean;
}

const ToggleComponent = ({
  buttonTestId,
  isDisabled,
  onChange,
  onClick,
  placeholder,
  setDate,
  size = 'medium',
  value,
  width,
  withClearIcon,
  withDeadline,
}: ToggleComponentPropTypes) => {
  const { t } = useTranslation();
  const reset = () => {
    onChange!(null);
    setDate!(null);
  };
  const isExceed =
    (withDeadline && value && dateExceedDeadline(formatQueryDate(value) || '')) || false;

  return (
    <StyledButton
      className={isDisabled ? 'datepicker-button-is-disabled' : 'datepicker-button-is-enabled'}
      data-testid={buttonTestId}
      isDisabled={isDisabled}
      isExceed={isExceed}
      onClick={isDisabled ? undefined : onClick}
      size={size}
      type='button'
      width={size === 'small' ? undefined : width}
    >
      {value ? (
        formatDate(value)
      ) : (
        <StyledPlaceholder>
          {placeholder ? placeholder : `${t('forms.select_a')} ${t('forms.date')}`}
        </StyledPlaceholder>
      )}
      <div>
        <StyledCalendarIcon />
        {withClearIcon && (
          <StyledClearIcon
            onClick={(e) => {
              e.stopPropagation();
              reset();
            }}
          />
        )}
      </div>
    </StyledButton>
  );
};

const InnerComponent = ({ setDate, date, onChange, minDate, maxDate }: InnerComponentPropTypes) => {
  const { t } = useTranslation();
  const { setIsOpen } = useDropdownContext();
  const onSelect = (value: Date | Date[]) => {
    const hackedValue = Array.isArray(value) ? value[0] : value;
    onChange(formatQueryDate(hackedValue));
    setDate(hackedValue);
    setIsOpen(false);
  };

  return (
    <StyledCalendarWrapper>
      <StyledCalendar
        formatMonth={(locale: string, day: Date) => formatMonth(day)}
        formatMonthYear={(locale: string, day: Date) => formatMonthYear(day)}
        formatShortWeekday={(locale: string, day: Date) => formatWeekdayShort(day)}
        maxDate={maxDate}
        minDate={minDate}
        minDetail='decade'
        next2Label={null}
        nextLabel={<ArrowRightBigIcon />}
        onChange={onSelect}
        prev2Label={null}
        prevLabel={<ArrowLeftBigIcon />}
        value={date}
      />
      <DefaultButton
        capitalize
        isDisabled={minDate && isDate(minDate) && isBefore(new Date(), minDate)}
        label={t('common.today')}
        onClick={() => onSelect(new Date())}
        testid='datepicker-today-button'
        type='button'
        variant='tertiary'
        width='fullWidth'
      />
    </StyledCalendarWrapper>
  );
};

export const DatepickerInput = (props: DatepickerInputPropTypes) => {
  const {
    buttonTestId,
    className,
    description,
    isDisabled,
    label,
    meta,
    minDate,
    maxDate,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    required,
    size = 'medium',
    value,
    width,
    withDeadline,
  } = props;

  const [date, setDate] = useState(value ? new Date(value) : null);

  useEffect(() => {
    setDate(value ? new Date(value) : null);
  }, [value]);

  return (
    <StyledFieldWrapper className={className} onClick={(e) => e.stopPropagation()}>
      {label && (
        <StyledLabel>
          {label}
          {required ? '*' : ''}
        </StyledLabel>
      )}

      <PopperDropdown
        ToggleComponent={ToggleComponent}
        toggleProps={{
          buttonTestId,
          isDisabled,
          onChange,
          placeholder,
          setDate,
          size,
          value: date,
          width,
          withClearIcon: !required,
          withDeadline,
        }}
      >
        <InnerComponent
          date={date}
          maxDate={maxDate}
          minDate={minDate}
          onChange={onChange}
          setDate={setDate}
        />
      </PopperDropdown>
      {description && <StyledDescription>{description}</StyledDescription>}
      {meta?.touched && meta?.error && <StyledError>{meta.error}</StyledError>}
      <input name={name} onBlur={onBlur} onFocus={onFocus} type='hidden' value={value || ''} />
    </StyledFieldWrapper>
  );
};
