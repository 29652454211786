import React, { ComponentType, useMemo } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { MultiSelectList } from 'components/MultiSelectList/MultiSelectList';
import { Option } from 'components/MultiSelectList/MultiSelectList';

import { ReactComponent as ArrowDownIcon } from 'images/arrowDownIcon.svg';
import {
  StyledFieldDropdown,
  StyledFieldWrapper,
  StyledLabel,
  StyledToggleButton,
} from '../styles';

interface MultiSelectFieldPropTypes extends FieldRenderProps<string[]> {
  buttonSize?: 'small' | 'medium' | 'large';
  customLabel?: (selected: string[], placeholder?: string) => string;
  isDisabled?: boolean;
  label?: string;
  options: Option[];
  placeholder?: string;
  required?: boolean;
}

export const MultiSelectField: ComponentType<MultiSelectFieldPropTypes> = ({
  buttonSize = 'medium',
  buttonTestId,
  customLabel,
  input: { onChange, value },
  isDisabled,
  label,
  options,
  placeholder,
  required,
  translate,
}) => {
  const selectedLabels = useMemo(() => {
    if (!Array.isArray(value)) {
      return [];
    }
    return options.filter((item) => value.find((x) => x === item.value)).map((item) => item.label);
  }, [JSON.stringify(options), JSON.stringify(value)]);

  const buttonLabel = useMemo(() => {
    if (customLabel) return customLabel(selectedLabels, placeholder);
    if (selectedLabels?.length) {
      return selectedLabels.length > 2
        ? `${[...selectedLabels].splice(0, 2).join(', ')} + ${selectedLabels.length - 2}`
        : selectedLabels.join(', ');
    } else {
      return placeholder;
    }
  }, [JSON.stringify(selectedLabels), placeholder]);

  const ToggleComponent = (compProps: object) => (
    <StyledToggleButton
      icon={<ArrowDownIcon />}
      iconPosition='right'
      isDisabled={!options || options.length === 0 || isDisabled}
      label={buttonLabel}
      size={buttonSize}
      testid={buttonTestId}
      type='button'
      variant={selectedLabels?.length ? 'filter' : 'placeholder'}
      {...compProps}
    />
  );

  return (
    <StyledFieldWrapper>
      {label && (
        <StyledLabel>
          {label}
          {required && '*'}
        </StyledLabel>
      )}
      <StyledFieldDropdown noPadding ToggleComponent={ToggleComponent}>
        <MultiSelectList
          onChange={(value: (string | number)[]) => onChange(value)}
          options={[...options] || []}
          translate={translate}
          value={value}
        />
      </StyledFieldDropdown>
    </StyledFieldWrapper>
  );
};
