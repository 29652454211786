import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { CompanyVisit } from 'ducks/newVisit';
import { companyVisitStatusesMap } from 'consts/companyVisitStatusesMap';
import { StatusBadge } from 'components/StatusBadge/StatusBadge';
import { StyledCompanyTab, StyledCompanyTabText } from './styles';
import { StyledFlatContrastButton } from 'components/DefaultButton/styles';
import { Tab } from 'components/Tabs/styles';
import { Tooltip } from 'components/Tooltip/Tooltip';

interface CompanyVisitsTabPropTypes {
  borderLeft: boolean;
  borderRight: boolean;
  companyVisit: CompanyVisit;
  handleClick: (cv: CompanyVisit | 'new') => void;
  selectedCompanyVisit: CompanyVisit | 'new';
  tabIsActive: boolean;
}

const TabInfo = styled('div')`
  display: flex;
`;

const StyledUnderlineButton = styled(StyledFlatContrastButton).attrs({
  as: 'div',
})`
  margin: 0;
  font-size: 1.1rem;
  height: 18px;
  line-height: 1;
  margin-left: 0.6rem;
  padding: 0;
  text-decoration: underline;
  width: 18px;

  &:hover {
    opacity: 0.9;
  }
`;

export const CompanyVisitTab = ({
  borderLeft,
  borderRight,
  companyVisit,
  handleClick,
  tabIsActive,
}: CompanyVisitsTabPropTypes) => {
  const { t } = useTranslation();
  const { content = 'error', variant = 'fail' } =
    companyVisitStatusesMap[companyVisit.status] || {};

  return (
    <Tab
      active={tabIsActive}
      bigCard
      borderLeft={borderLeft}
      borderRight={borderRight}
      className={tabIsActive ? 'company-visit-tab-active' : 'company-visit-tab-inactive'}
      data-testid='company-visit-tab'
      key={companyVisit.id}
      length={companyVisit.companyName.length}
      onClick={() => handleClick(companyVisit)}
    >
      <StyledCompanyTab>
        <StyledCompanyTabText active={tabIsActive}>{companyVisit.companyName}</StyledCompanyTabText>
        <TabInfo>
          <StatusBadge variant={variant}>{content}</StatusBadge>

          <Tooltip label={t('new_visits.view_company_details')}>
            <StyledUnderlineButton
              isActive={false}
              onClick={() => {
                window.open(`/redesigned/companies/${companyVisit.companyId}/basic-data`, '_blank');
              }}
              size='small'
              width='auto'
            >
              {t('new_visits.vis')}
            </StyledUnderlineButton>
          </Tooltip>
        </TabInfo>
      </StyledCompanyTab>
    </Tab>
  );
};
