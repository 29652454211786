import React, { MutableRefObject } from 'react';
import { useSelector } from 'react-redux';

import {
  isNewVisitFinished,
  isNewVisitFollowUp,
  isNewVisitWithEditingAccess,
} from 'ducks/newVisit';
import { SummaryNotes } from 'containers/newVisit/OrdinaryVisit/SummaryNotes';
import { CancelTokenSource } from 'axios';

interface NotesSectionProps {
  cancelToken: MutableRefObject<CancelTokenSource | null>;
  companyVisitId: string;
}

export const NotesSection = ({ cancelToken, companyVisitId }: NotesSectionProps) => {
  const isVisitFinished = useSelector(isNewVisitFinished(companyVisitId));
  const isVisitFollowUp = useSelector(isNewVisitFollowUp(companyVisitId));
  const isVisitCanEdit = useSelector(isNewVisitWithEditingAccess(companyVisitId));
  const isDisabled = !isVisitCanEdit || (isVisitCanEdit && (isVisitFinished || isVisitFollowUp));

  return (
    <>
      <SummaryNotes
        cancelToken={cancelToken}
        companyVisitId={companyVisitId}
        isDisabled={isDisabled}
      />
    </>
  );
};
