import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useMatch, useNavigate } from '@reach/router';

import {
  CompanyNavigationPanel,
  TabsName,
} from 'containers/companyDetails/CompanyNavigationPanel/CompanyNavigationPanel';
import { StoreType } from 'store';
import { getCompany } from 'ducks/companies/actions';
import { getUsers } from 'ducks/users/actions';
import { StyledLayoutWithFullWidth, StyledTabContainer } from 'containers/styles';
import { resetCompanies } from 'ducks/companies/reducer';

interface PageProps extends RouteComponentProps {
  companyId?: string;
}

export const Page: FC<PageProps> = (props) => {
  const { children, companyId, uri } = props;
  const companyDetails = useSelector((state: StoreType) => state.companies);
  const dispatch = useDispatch();
  const match = useMatch('/redesigned/companies/:companyId/:tab');
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetCompanies());
  }, []);

  useEffect(() => {
    companyId && dispatch(getCompany(companyId));
    dispatch(getUsers());
  }, [companyId, dispatch]);

  if (!companyDetails) return null;
  if (!match) navigate(`${uri}/basic-data`);

  return (
    <>
      <CompanyNavigationPanel activeTab={match?.tab as TabsName} company={companyDetails} />

      <StyledTabContainer>
        <StyledLayoutWithFullWidth>{children}</StyledLayoutWithFullWidth>
      </StyledTabContainer>
    </>
  );
};
