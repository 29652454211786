import styled, { css } from 'styled-components/macro';

export const StyledLabel = styled.span<{ isDisabled?: boolean }>`
  ${({ theme }) => theme.fontSizes.base};
  color: ${({ isDisabled, theme }) => (isDisabled ? theme.colors.black300 : theme.colors.black700)};
  font-weight: 600;
  white-space: nowrap;
`;

export const StyledThinLabel = styled(StyledLabel)`
  ${({ theme }) =>
    css`
      color: ${theme.colors.black500};
      font-weight: 500;
    `}
`;

export const StyledPrimaryLabel = styled(StyledLabel)`
  ${({ theme }) =>
    css`
      ${theme.fontSizes.base};
      color: ${theme.colors.black400};
      font-weight: 500;
      text-transform: uppercase;
    `}
`;
