import styled, { css } from 'styled-components/macro';

export const StyledMenuDrawer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 100%;
  height: 100vh;
  width: calc(${({ theme }) => theme.sizes.md} * 15);
  background: white;
  box-shadow: -1.4rem 0 2.4rem 0 rgba(13, 41, 124, 0.1), 0 0 0.2rem 0 rgba(13, 41, 124, 0.06);
  padding: ${({ theme }) => theme.sizes.md};
  transition: transform 0.3s ease, opacity 0.15s ease;
  will-change: transform;
  ${({ isMenuOpen }) =>
    isMenuOpen
      ? css`
          transform: translateX(-100%);
        `
      : css`
          transform: translateX(0%);
          pointer-events: none;
          opacity: 0;
        `};
`;

export const StyledMemuDrawerList = styled.ul`
  margin: ${({ theme }) => `${theme.sizes.md} ${theme.sizes.xs}`};

  & a {
    color: ${({ theme }) => theme.colors.black700};
    text-decoration: none;
  }
`;

export const StyledMenuDrawerItem = styled.li`
  padding: ${({ theme }) => theme.sizes.hmd} 0;
  text-decoration: none;

  & > button {
    color: ${({ theme }) => theme.colors.black700};
    text-align: left;
  }
`;

export const StyledMenuDrawerOverlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  visibility: hidden;
  ${({ isVisible }) =>
    isVisible &&
    css`
      visibility: visible;
    `}
`;
