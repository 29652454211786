import styled from 'styled-components/macro';

import { TabContainer } from 'components/Tabs/styles';
import { StyledMultiLineCell } from 'components/Table/TableRow/styles';

export const StyledEventLogCardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 100%;

  ${TabContainer} {
    flex: 0 0 auto;
  }
`;

export const StyledEmptyEvents = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.sizes.sm} 0;
  width: 100%;

  & > span {
    margin-bottom: ${({ theme }) => theme.sizes.hmd};
  }
`;

export const StyledMultiLineContactsCell = styled(StyledMultiLineCell)`
  cursor: pointer;
`;

export const StyledAttachments = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
