import React, { FC } from 'react';
import queryString from 'query-string';

import { StyledThumbnail, StyledVideoContainer } from 'components/Forms/Attachments/styles';
import { Attachment } from 'ducks/events/types';
import { BASE_URL } from 'api/apiUrl';
import { AttachmentBadge } from 'components/AttachmentBadge/AttachmentBadge';
import { StyledVideoPlayer } from 'components/Forms/Attachments/styles';

interface ThumbnailPropTypes {
  file: Attachment;
}

export const FileThumbnail: FC<ThumbnailPropTypes> = ({
  file,
  file: { contentType, className, id },
}) => {
  const urlParams = queryString.stringify({
    id,
    class_name: className,
    content_disposition: 'attachment',
  });

  const serveUrl = `${BASE_URL}/api/serve_attachments?${urlParams}`;

  const isImage = contentType.includes('image');
  const isVideo = contentType.includes('video');

  return (
    <div>
      {isImage && (
        <a href={serveUrl}>
          <StyledThumbnail
            style={{
              backgroundImage: `url(${serveUrl})`,
            }}
          />
        </a>
      )}
      {isVideo && (
        <a href={serveUrl}>
          <StyledVideoContainer>
            <StyledVideoPlayer src={serveUrl} />
          </StyledVideoContainer>
        </a>
      )}
      {!isImage && !isVideo && <AttachmentBadge {...file} />}
    </div>
  );
};
