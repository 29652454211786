import React, { forwardRef, memo, useCallback } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

import { NumberRange } from 'components/NumberRange/NumberRange';
import { PopperDropdown } from 'components/PopperDropdown/PopperDropdown';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';

import { ReactComponent as ArrowDownIcon } from 'images/arrowDownIcon.svg';

const RangeFilter = (props) => {
  const { isActive, label, maxValue, minValue, onChange, value, removeFn } = props;
  const isDisabled = maxValue.value === minValue.value;
  const { t } = useTranslation();

  const changeHandler = useCallback(
    (value) => {
      if (isEmpty(value))
        onChange({
          isActive: false,
          value: null,
          displayValue: [],
        });
      else {
        const isActive = maxValue.value !== value.max || minValue.value !== value.min;
        const displayValue = isActive
          ? [
              maxValue.value === value.max
                ? `${value.min} ${t('filters.or_more')}`
                : `${value.min} ${t('filters.to')} ${value.max}`,
            ]
          : [];

        onChange({
          isActive,
          value,
          displayValue,
          removeFn,
        });
      }
    },
    [onChange, maxValue.value, minValue.value, removeFn, t],
  );

  const ToggleComponent = forwardRef((compProps, ref) => (
    <DefaultButton
      icon={<ArrowDownIcon />}
      iconPosition='right'
      isActive={isActive}
      isDisabled={isDisabled}
      label={label}
      ref={ref}
      variant='filter'
      {...compProps}
    />
  ));

  return (
    <PopperDropdown ToggleComponent={ToggleComponent}>
      {!isDisabled && (
        <NumberRange
          isActive={isActive}
          maxValue={maxValue.value}
          minValue={minValue.value}
          onChange={changeHandler}
          value={value}
        />
      )}
    </PopperDropdown>
  );
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.value, nextProps.value) &&
  isEqual(prevProps.maxValue, nextProps.maxValue) &&
  isEqual(prevProps.minValue, nextProps.minValue);

const PureRangeFilter = memo(RangeFilter, areEqual);

export { PureRangeFilter as RangeFilter };
