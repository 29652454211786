import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { navigate, RouteComponentProps } from '@reach/router';

import { Margin } from 'styles/utils';
import {
  newVisitAddressSelector,
  newVisitWorkplaceOptionSelector,
  setNewVisitWorkplaceOption,
  visitSelector,
} from 'ducks/newVisit';
import { LevelField } from 'components/Forms/LevelField/LevelField';
import { StyledHorizontalRule } from 'containers/CompanyCreate/styles';
import { useAppDispatch } from 'store';
import { SelectCompanyAddress } from 'containers/newVisit/OrdinaryVisit/Step1/SelectCompanyAddress';
import { StyledWorkplaceOptions } from 'containers/newVisit/OrdinaryVisit/styles';
import { NewWorkplace } from 'containers/newVisit/OrdinaryVisit/Step1/NewWorkplace';
import { FindWorkplace } from 'containers/newVisit/OrdinaryVisit/Step1/FindWorkplace';

export const Workplace: FC<RouteComponentProps> = () => {
  const chosenAddress = useSelector(newVisitAddressSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selectedOption = useSelector(newVisitWorkplaceOptionSelector);
  const visit = useSelector(visitSelector);

  if (!visit.visitDate) {
    // Navigate to dashboard if 'redesigned/new-ordinary-visit/0/workplace' is directly accessed without picking a date.
    navigate('/redesigned/dashboard');
  }

  const switchOptions = useMemo(
    () => [
      { label: t('new_visits.new_workplace'), value: 'New' },
      { label: t('new_visits.find_workplace'), value: 'Find' },
      { label: t('new_visits.same_as_company'), value: 'SameAsCompany' },
    ],
    [],
  );
  const lightOption = useMemo(
    () => [{ label: t('new_visits.light_visit'), value: 'SameAsCompany' }],
    [],
  );

  useEffect(() => {
    return () => sessionStorage.removeItem('automaticallyAddCompanyToVisit');
  }, [visit.id]);

  return (
    <>
      <StyledWorkplaceOptions>
        <LevelField
          input={{
            onChange: (val) => dispatch(setNewVisitWorkplaceOption(val)),
            value: visit.light ? 'SameAsCompany' : selectedOption,
            name: 'type',
            onBlur: () => {},
            onFocus: () => {},
          }}
          isDisabled={!!chosenAddress}
          label=''
          meta={{}}
          options={visit.light ? lightOption : switchOptions}
          size='small'
        />
      </StyledWorkplaceOptions>
      <Margin top='1.2rem'>
        <StyledHorizontalRule />
      </Margin>
      {(selectedOption === 'SameAsCompany' || visit.light) && <SelectCompanyAddress />}
      {selectedOption === 'New' && !visit?.light && <NewWorkplace />}
      {selectedOption === 'Find' && !visit?.light && <FindWorkplace />}
    </>
  );
};
