import { theme } from 'consts/theme';
import styled from 'styled-components/macro';

interface StyledTextPropTypes {
  bold: boolean;
  color: keyof typeof theme.colors;
  size: keyof typeof theme.fontSizes;
}

const commonProperties = ({ bold, size, color }: StyledTextPropTypes): string => `
  ${bold ? 'font-weight: bold;' : ''};
  ${theme.fontSizes[size]};
  color: ${theme.colors[color]};
  margin: 0;
  white-space: nowrap;
`;

export const StyledParagraphText = styled.p<StyledTextPropTypes>`
  ${(props) => commonProperties(props)};
`;

export const StyledHeader3Text = styled.h3<StyledTextPropTypes>`
  ${(props) => commonProperties(props)};
`;

export const StyledHeader2Text = styled.h2<StyledTextPropTypes>`
  ${(props) => commonProperties(props)};
`;

export const StyledHeader1Text = styled.h1<StyledTextPropTypes>`
  ${(props) => commonProperties(props)};
`;
