import React from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { StyledModalButtonsWrapper, StyledModalHeader, useModal } from 'components/Modal/Modal';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useAppDispatch } from 'store';
import { currentUserSelector } from 'ducks/users/selectors';
import { editUser, getUser } from 'ducks/users/actions';
import { ProfileForm } from 'containers/Users/ProfileForm';

export const ProfileModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useSelector(currentUserSelector);
  const { hideModal } = useModal();

  React.useEffect(() => {
    if (user) {
      dispatch(getUser(user.id));
    }
  }, [dispatch, user, useSelector, currentUserSelector]);
  const initialValues = user
    ? {
        name: user.name || '',
        email: user.email || '',
        phone: user.phone || '',
        postal_area_id: user.postalAreaId || undefined,
        postal_area: user.postalArea || undefined,
        postal_area_name: user.postalAreaName || undefined,
        address: user.address || '',
        industryName: user.industryName || '',
        districtName: user.districtName || '',
        receiveCalendarEvents: user.receiveCalendarEvents || false,
      }
    : undefined;

  const heading = () => {
    return t('common.profile');
  };

  return (
    <>
      <StyledModalHeader>{heading()}</StyledModalHeader>
      <ProfileForm
        Buttons={({ onClick, isPrimaryDisabled }) => (
          <>
            <StyledModalButtonsWrapper>
              <DefaultButton
                capitalize
                label={t('common.cancel')}
                onClick={hideModal}
                type='button'
                variant='secondary'
              />
              <DefaultButton
                capitalize
                isDisabled={isPrimaryDisabled}
                label={t('common.save')}
                onClick={onClick}
                type='button'
                variant='primary'
              />
            </StyledModalButtonsWrapper>
          </>
        )}
        buttonsPosition='outside'
        init={initialValues}
        onSubmit={async (values) =>
          await dispatch(editUser({ ...values, id: user!.id }))
            .then(unwrapResult)
            .then(hideModal)
        }
      />
    </>
  );
};
