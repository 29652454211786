import React, { FC } from 'react';
import { Redirect, RouteComponentProps, WindowLocation } from '@reach/router';
import { resetPassword, validatePassword } from './helpers';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import {
  StyledAuthForm,
  StyledAuthFormWrapper,
  StyledAuthInput,
  StyledPasswordPolicyList,
  StyledPasswordPolicyListItem,
  StyledSubmitButton,
} from './styles';

interface ConfirmForgotPasswordProps
  extends RouteComponentProps<{ location: { state: { email: string; code: string } } }> {
  location: WindowLocation<{ email: string; code: string }>;
}

export const ConfirmForgotPassword: FC<ConfirmForgotPasswordProps> = (props) => {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  if (props.location.state === null) {
    return <Redirect from='' to='../sign_in' noThrow />;
  }

  const { email, code } = props.location.state;

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error(t('auth.error.mismatched_passwords'));
      return;
    } else if (validatePassword(newPassword).error) {
      toast.error(t(`auth.error.${validatePassword(newPassword).error}`));
      return;
    }

    try {
      const response = await resetPassword(email, newPassword, code);
      if (response.error) {
        toast.error(response.error);
        return;
      }
      props.navigate && props.navigate('../sign_in');
    } catch (err) {
      console.log(err);
      toast.error(err as string);
    }
  };

  const handleNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <StyledAuthFormWrapper>
      <StyledAuthForm onSubmit={onSubmit}>
        <StyledPasswordPolicyList>
          <b>{t('auth.instructions.password_must')}</b>
          <StyledPasswordPolicyListItem>
            {t('auth.instructions.password_length')}
          </StyledPasswordPolicyListItem>
          <StyledPasswordPolicyListItem>
            {t('auth.instructions.password_uppercase')}
          </StyledPasswordPolicyListItem>
          <StyledPasswordPolicyListItem>
            {t('auth.instructions.password_symbol')}
          </StyledPasswordPolicyListItem>
        </StyledPasswordPolicyList>
        <StyledAuthInput
          autoFocus
          aria-label='new password'
          placeholder={t('auth.new_password')}
          type='password'
          onChange={handleNewPassword}
        />
        <StyledAuthInput
          aria-label='confirm password'
          placeholder={t('auth.confirm_password')}
          type='password'
          onChange={handleConfirmPassword}
        />
        <StyledSubmitButton type='submit'>{t('auth.set_new_password')}</StyledSubmitButton>
      </StyledAuthForm>
    </StyledAuthFormWrapper>
  );
};
