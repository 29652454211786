import { createSlice } from '@reduxjs/toolkit';

import { searchContacts } from './actions';
import { SearchContactsEntity, SearchContactsEntityResponse, SearchContactsState } from './types';
import { deleteCompanyContact, editCompanyContact } from 'ducks/contacts/actions';

const initialState: SearchContactsState = {
  data: {
    tableBody: [],
  },
  error: undefined,
  meta: {
    columnsOrder: [],
    filters: {},
    page: 1,
    pageSize: 10,
    totalCount: null,
    totalPages: 1,
  },
};

const amendEntity = (entity: SearchContactsEntityResponse): SearchContactsEntity => ({
  ...entity,
});

const contactsSlice = createSlice({
  name: 'search/Contactss',
  initialState,
  reducers: {
    resetContactsSearchResults: () => initialState,
    clearContactsSearchError: (state) => ({ ...state, error: undefined }),
  },
  extraReducers: (builder) => {
    builder.addCase(searchContacts.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(searchContacts.fulfilled, (state, { payload }) => {
      state.data.tableBody = payload.data.tableBody.map(amendEntity);
      state.meta = payload.meta;
    });
    builder.addCase(editCompanyContact.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(editCompanyContact.fulfilled, (state, { payload }) => {
      state.data.tableBody = state.data.tableBody.reduce(
        (acc: SearchContactsEntity[], cur) =>
          cur.id === payload.id ? [...acc, amendEntity(payload)] : [...acc, cur],
        [],
      );
    });
    builder.addCase(deleteCompanyContact.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(deleteCompanyContact.fulfilled, (state, { meta }) => {
      state.data.tableBody = state.data.tableBody.filter((contact) => contact.id !== meta.arg);
      if (typeof state.meta.totalCount === 'number') --state.meta.totalCount;
    });
  },
});

const {
  reducer: contactsSearchReducer,
  actions: { resetContactsSearchResults, clearContactsSearchError },
} = contactsSlice;

export { contactsSearchReducer, resetContactsSearchResults, clearContactsSearchError };
