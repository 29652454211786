import { format } from 'date-fns';
import { enGB, nb } from 'date-fns/locale';
import { capitalize } from 'lodash';

const selectedLng = localStorage.getItem('i18nextLng');

const locales = {
  no: nb,
  en: enGB,
};

export const formatDate = (date) =>
  date && Date.parse(date)
    ? format(new Date(date), 'P', { locale: selectedLng ? locales[selectedLng] : nb })
    : date;

export const formatQueryDate = (date) => {
  if (!date) return null;
  const dateObject = typeof date === 'string' ? new Date(date) : date;
  return format(dateObject, 'yyyy-MM-dd');
};

export const formatWeekdayShort = (date) =>
  format(new Date(date), 'EEEEEE', { locale: selectedLng ? locales[selectedLng] : nb });

export const formatMonthYear = (date) =>
  capitalize(
    format(new Date(date), 'MMMM yyyy', { locale: selectedLng ? locales[selectedLng] : nb }),
  );

export const formatMonth = (date) =>
  capitalize(format(new Date(date), 'MMMM', { locale: selectedLng ? locales[selectedLng] : nb }));
