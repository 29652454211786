import React, { FC } from 'react';

import { StyledLayout } from 'containers/styles';
import { TopBar } from 'components/TopBar/TopBar';
import { StyledTopBarWrap } from './styles';

export const Navbar: FC = () => (
  <StyledTopBarWrap>
    <StyledLayout>
      <TopBar />
    </StyledLayout>
  </StyledTopBarWrap>
);
