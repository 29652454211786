import { Placement } from '@popperjs/core';

import { KeyframesPopup } from 'styles/utils';
import styled, { css } from 'styled-components/macro';

export const StyledPopupSurface = styled.div`
  background: white;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  box-shadow: ${({ theme }) => theme.boxShadows.coolGrey400};
  transform-origin: top;
  will-change: transform, opacity;
  animation: ${KeyframesPopup} 0.12s ease-out forwards;
  overflow: hidden;
  min-width: 7rem;
  color: ${({ theme }) => theme.colors.black700};
`;

export const StyledMenuItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  list-style-type: none;
  line-height: 0.6;
  padding: 1.2rem;
  &:hover {
    background-color: ${({ theme }) => theme.colors.blue50};
  }
  & > svg {
    margin-right: 1rem;
  }
`;

export const StyledMenu = styled.ul`
  padding: 0;
  margin: 0;
  max-height: 80vh;
  overflow: auto;
`;

export const StyledInlineBlock = styled.div`
  display: inline-block;
  position: relative;
`;

interface StyledPopupPropTypes {
  selectButtonHeight?: number;
  'data-placement': Placement;
  width?: number;
}
export const StyledPopup = styled.div<StyledPopupPropTypes>`
  /* !important is used to override react-Popper placement styles, which are applied calculated dynamically. By default react-popper positions the popup with placement 'bottom' which is 0px under the triggering Reference. Negative 'top' will make popper's top appear right over its Reference, not right below it.   */
  ${(props) =>
    props.selectButtonHeight &&
    props['data-placement'] === 'bottom' &&
    css`
      top: -${props.selectButtonHeight / 10 || 4.4}rem !important;
    `}
 /* The opposite happens when there is not enough room below the component:  */
  ${(props) =>
    props.selectButtonHeight &&
    props['data-placement'] === 'top' &&
    css`
      top: ${props.selectButtonHeight / 10 || 4.4}rem !important;
    `}

  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
  z-index: 10000;
`;
