import { updateGridSettings } from 'ducks/users/actions';
import { createSlice } from '@reduxjs/toolkit';
import { camelizeKeys } from 'humps';

import {
  addSavedSearches,
  getCurrentUser,
  getUsers,
  removeSavedSearches,
  updateSavedSearch,
} from './actions';
import {
  CurrentUserEntityResponse,
  SavedSearchesType,
  UsersEntity,
  UsersEntityResponse,
  UsersState,
} from './types';

const amendUsersEntity = (user: UsersEntityResponse): UsersEntity => ({ ...user, id: user.userId });

const amendSavedSearches = (search: SavedSearchesType[] | null): SavedSearchesType[] =>
  (search || []).map((x) => camelizeKeys(x)) as SavedSearchesType[];

const amendCurentUser = (user: CurrentUserEntityResponse) => {
  const savedSearches = amendSavedSearches(user.savedSearches);

  return {
    ...user,
    savedSearches,
  };
};

const initialState: UsersState = {
  current: null,
  all: [],
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetCurrentUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.fulfilled, (state, { payload }) => {
      if (payload === null) return;
      state.current = amendCurentUser(payload);
    });
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.all = payload.data.tableBody.map(amendUsersEntity);
    });
    builder.addCase(addSavedSearches.fulfilled, (state, { payload }) => {
      state.current!.savedSearches = payload;
    });
    builder.addCase(removeSavedSearches.fulfilled, (state, { payload }) => {
      state.current!.savedSearches = payload;
    });
    builder.addCase(updateSavedSearch.fulfilled, (state, { payload }) => {
      state.current!.savedSearches = payload;
    });
    builder.addCase(updateGridSettings.fulfilled, (state, { payload }) => {
      state.current!.gridSettings = payload;
    });
  },
});

const {
  reducer: usersReducer,
  actions: { resetCurrentUser },
} = usersSlice;

export { usersReducer, resetCurrentUser };
