import React, { ComponentType } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { FieldTextPropTypes, TextInput } from 'components/TextInput/TextInput';
import { StyledDescription, StyledError, StyledFieldWrapper } from 'components/Forms/styles';

interface TextFieldPropTypes extends FieldRenderProps<string> {
  additionalInputProps?: FieldTextPropTypes;
  description?: string;
  isDisabled?: boolean;
  label?: string;
  placeholder?: string;
  required?: boolean;
  size?: 'small' | 'medium' | 'big' | 'fullWidth' | 'auto';
  testId?: string;
}

const TextField: ComponentType<TextFieldPropTypes> = ({
  input,
  inputExtra,
  label,
  required,
  placeholder,
  additionalInputProps,
  description,
  meta,
  isDisabled,
  testId,
  size = 'fullWidth',
}) => {
  const finalInput: Record<string, unknown> = {};
  Object.assign(finalInput, input);
  if (inputExtra) {
    for (const key in inputExtra) {
      finalInput[key] = inputExtra[key];
    }
  }
  return (
    <StyledFieldWrapper>
      <TextInput
        // eslint-disable-next-line no-sequences
        inputProps={finalInput}
        isDisabled={isDisabled}
        isError={meta.touched && meta.error}
        label={label && `${label}${required ? '*' : ''}`}
        placeholder={placeholder}
        size={size}
        testId={testId}
        {...additionalInputProps}
      />
      {description && <StyledDescription>{description}</StyledDescription>}
      {meta.touched && meta.error && <StyledError>{meta.error}</StyledError>}
    </StyledFieldWrapper>
  );
};

TextField.defaultProps = {
  required: false,
};

export { TextField };
