import styled, { css } from 'styled-components/macro';
import { StyledDropdownWrapper } from 'components/PopperDropdown/styles';

type StickyOptions = 'horizontal' | 'vertical' | 'both';

const stickyModes = (type: StickyOptions) => {
  switch (type) {
    case 'horizontal':
      return css`
        position: sticky;
        right: 0;
      `;
    case 'vertical':
      return css`
        position: sticky;
        top: 0;
      `;
    case 'both':
      return css`
        position: sticky;
        right: 0;
        top: 0;
        z-index: 20;
      `;
    default:
      return '';
  }
};

export const CenterCenter = styled.div<{ sticky: StickyOptions }>`
  align-items: center;
  background-color: white;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black100};
  display: flex;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.sizes.hmd};

  ${({ sticky }) => sticky && stickyModes(sticky)};
`;

export const CenterEnd = styled(CenterCenter)`
  background-color: white;
  justify-content: flex-end;
  padding-bottom: ${({ theme }) => theme.sizes.hmd};

  ${({ sticky }) => sticky && stickyModes(sticky)};
`;

export const StyledConfigWrap = styled.div`
  padding-right: ${({ theme }) => theme.tableVerticalPadding};

  ${StyledDropdownWrapper} {
    margin: 0;
  }
`;
