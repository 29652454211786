import React, { forwardRef, InputHTMLAttributes, ReactElement } from 'react';

import {
  ClearButton,
  FixElementBox,
  PrefixElementBox,
  StyledInput,
  StyledInputWrapper,
  StyledWrapper,
} from './styles';
import { StyledLabel } from 'components/Forms/styles';

import { ReactComponent as CloseIcon } from 'images/closeIcon.svg';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  'data-testid'?: string;
}
export interface FieldTextPropTypes {
  autoFocus?: boolean;
  defaultValue?: string;
  inputProps?: InputProps;
  isDisabled?: boolean;
  isError?: boolean;
  label?: string;
  placeholder?: string;
  postfixElement?: ReactElement | boolean;
  prefixElement?: ReactElement | boolean;
  size?: 'small' | 'medium' | 'big' | 'fullWidth' | 'auto';
  vsize?: 'small' | 'regular';
  textAlign?: string;
  withClearButton?: boolean;
  testId?: string;
}

export const TextInput = forwardRef<HTMLInputElement, FieldTextPropTypes>(
  (
    {
      autoFocus = false,
      defaultValue,
      inputProps = {},
      isDisabled = false,
      isError = false,
      label,
      placeholder,
      postfixElement,
      prefixElement,
      size = 'medium',
      textAlign,
      vsize = 'regular',
      withClearButton = false,
      testId,
    },
    ref,
  ) => {
    const hasAnyValue = inputProps && inputProps.value;

    return (
      <>
        {label && <StyledLabel htmlFor={inputProps.name}>{label}</StyledLabel>}
        <StyledWrapper
          data-testid='generic-field'
          isDisabled={isDisabled}
          isError={isError}
          size={size}
          vsize={vsize}
        >
          {prefixElement && <PrefixElementBox>{prefixElement}</PrefixElementBox>}
          <StyledInputWrapper>
            <StyledInput
              autoFocus={autoFocus}
              data-testid={testId ? testId : 'input-element'}
              defaultValue={defaultValue}
              disabled={isDisabled}
              id={inputProps.name}
              placeholder={placeholder}
              ref={ref}
              textAlign={textAlign}
              {...inputProps}
            />
          </StyledInputWrapper>
          {postfixElement && <FixElementBox>{postfixElement}</FixElementBox>}
          {withClearButton && hasAnyValue && (
            <FixElementBox>
              <ClearButton
                onClick={() => {
                  if (inputProps?.onChange)
                    inputProps.onChange({
                      target: { value: '' },
                      currentTarget: { value: '' },
                    } as React.ChangeEvent<HTMLInputElement>);
                }}
              >
                <CloseIcon height='10' strokeWidth='2' width='10' />
              </ClearButton>
            </FixElementBox>
          )}
        </StyledWrapper>
      </>
    );
  },
);
