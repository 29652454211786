import { createSlice } from '@reduxjs/toolkit';
import { deleteRvoEvent, updateRvoEvent } from 'ducks/events/actions';

import { RvoEventsEntity, RvoEventsEntityResponse } from 'ducks/events/types';
import { searchRevisedActivities } from './actions';
import { SearchRevisedActivitiesState } from './types';

const initialState: SearchRevisedActivitiesState = {
  data: {
    tableBody: [],
  },
  error: undefined,
  meta: {
    columnsOrder: [],
    filters: {},
    page: 1,
    pageSize: 10,
    totalCount: null,
    totalPages: 1,
  },
};

const amendEntity = (entity: RvoEventsEntityResponse): RvoEventsEntity => ({
  ...entity,
  id: entity.revisedActivityId,
});

const revisedActivitiesSlice = createSlice({
  name: 'search/RevisedActivitiess',
  initialState,
  reducers: {
    resetRevisedActivitiesSearchResults: () => initialState,
    clearRevisedActivitiesSearchResultsError: (state) => ({ ...state, error: undefined }),
  },
  extraReducers: (builder) => {
    builder.addCase(searchRevisedActivities.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(searchRevisedActivities.fulfilled, (state, { payload }) => {
      state.data.tableBody = payload.data.tableBody.map(amendEntity);
      state.meta = payload.meta;
    });
    builder.addCase(updateRvoEvent.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(updateRvoEvent.fulfilled, (state, { payload }) => {
      state.data.tableBody = state.data.tableBody.reduce(
        (acc: RvoEventsEntity[], cur) =>
          cur.revisedActivityId === payload.revisedActivityId
            ? [...acc, amendEntity(payload)]
            : [...acc, cur],
        [],
      );
    });
    builder.addCase(deleteRvoEvent.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(deleteRvoEvent.fulfilled, (state, { meta }) => {
      state.data.tableBody = state.data.tableBody.filter((event) => event.id !== meta.arg);
      if (typeof state.meta.totalCount === 'number') --state.meta.totalCount;
    });
  },
});

const {
  reducer: revisedActivitiesSearchReducer,
  actions: { resetRevisedActivitiesSearchResults, clearRevisedActivitiesSearchResultsError },
} = revisedActivitiesSlice;

export {
  revisedActivitiesSearchReducer,
  resetRevisedActivitiesSearchResults,
  clearRevisedActivitiesSearchResultsError,
};
