export type ChallengeResponse = {
  cognitoUserId?: string;
  session?: string;
  challengeName?: string;
  accessToken?: string;
};

export enum ChallengeNames {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  MFA_SETUP = 'MFA_SETUP',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
}
