import React, { FC } from 'react';

import {
  ActivatedBadge,
  FailBadge,
  HighAlertBadge,
  LowAlertBadge,
  NeutralBadge,
  NotActiveBadge,
  PrimaryBadge,
  SuccessBadge,
} from './styles';

const variants = {
  'high-alert': HighAlertBadge,
  'low-alert': LowAlertBadge,
  active: ActivatedBadge,
  fail: FailBadge,
  inactive: NotActiveBadge,
  neutral: NeutralBadge,
  primary: PrimaryBadge,
  success: SuccessBadge,
};

export type StatusBadgeVariants = keyof typeof variants;

export type StatusBadgeSize = 'small' | 'default' | 'verySmall';

type StatusBadgeProps = {
  fill?: boolean;
  size?: StatusBadgeSize;
  variant: StatusBadgeVariants;
};

const StatusBadge: FC<StatusBadgeProps> = ({ size = 'verySmall', children, variant, fill }) => {
  const Component = variants[variant];

  return (
    <Component fillIn={fill} size={size}>
      {children}
    </Component>
  );
};

StatusBadge.defaultProps = {
  fill: false,
};

export { StatusBadge };
