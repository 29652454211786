import React, { FC, useState } from 'react';

interface ContextValue {
  isLoading: (name: string) => boolean;
  setLoaded: (name: string) => void;
  setLoading: (name: string) => void;
}

export const LoadingContext = React.createContext<ContextValue>({
  isLoading: () => false,
  setLoaded: () => undefined,
  setLoading: () => undefined,
});

export const LoadingContextProvider: FC = ({ children }) => {
  const [loadingResources, setLoadingResources] = useState<string[]>([]);

  const setLoaded = (name: string) => {
    setLoadingResources(loadingResources.filter((resource) => resource !== name));
  };

  const isLoading = (name: string) => loadingResources.includes(name);

  const setLoading = (name: string) => {
    if (!isLoading(name)) {
      setLoadingResources([...loadingResources, name]);
    }
  };

  return (
    <LoadingContext.Provider value={{ setLoaded, isLoading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoadingContext = () => React.useContext(LoadingContext);
