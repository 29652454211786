import React, { FC } from 'react';
//@ts-ignore
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';

interface EmbededReportPropsTypes {
  url: string;
}

export const EmbededReport: FC<EmbededReportPropsTypes> = ({ url }) => {
  function embeding() {
    const containerDiv = document.getElementById('embeddingContainer');
    const options = {
      url: url,
      container: containerDiv,
      footerPaddingEnabled: true,
      locale: 'nb-NO',
      printEnabled: true,
    };
    embedDashboard(options);
  }

  React.useEffect(() => {
    embeding();
  }, [url]);

  if (!url) {
    return null;
  }

  return (
    <div id='embeddingContainer' style={{ width: '100%', height: '90vh', paddingBottom: '2rem' }} />
  );
};
