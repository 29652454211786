import React, { FC } from 'react';

import { Address } from 'ducks/companies/types';
import { StyledMap } from './styles';

interface ExtendedAddress extends Address {
  gAddress: string;
  tagLetter: string;
}

const StaticMapImage: FC<{ addresses: ExtendedAddress[] }> = ({ addresses }) => {
  if (addresses.length < 1) return null;

  const base = 'https://maps.googleapis.com/maps/api/staticmap?';
  const size = 'size=600x250';
  const markers = addresses
    .map((el) => `&markers=color:0xFFFFFF|label:${el.tagLetter}|${el.gAddress}`)
    .join('');
  const key = `&key=${window.REACT_APP_ENV.GOOGLE_PLACES_API_KEY}`;
  const url = base + size + markers + key;

  return <StyledMap alt='map' src={url} />;
};

export { StaticMapImage };
