import FileSaver from 'file-saver';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { API } from 'api';
import {
  AddCompanyContactParams,
  AddWorkplaceContactParams,
  ContactEntityResponse,
  ContactsResponse,
  EditCompanyContactParams,
  EditWorkplaceContactParams,
  GetCompanyContactsParams,
} from './types';
import { AxiosError } from 'axios';

export const getCompanyContacts = createAsyncThunk(
  'contact/getCompanyContacts',
  async (params: GetCompanyContactsParams, { rejectWithValue }) => {
    try {
      const response = await API.get(`company_contacts`, { params });
      return response.data as ContactsResponse;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const getAllCompanyContacts = async (params: GetCompanyContactsParams) => {
  const response = await API.get(`company_contacts`, {
    params: { ...params, page: 1, page_size: 1000 },
  });
  return response.data as ContactsResponse;
};

export const getCompanyContactRoles = createAsyncThunk(
  'contact/getCompanyContactRoles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`company_contact_roles`);
      return response.data as Array<{ id: number; name: string }>;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const getWorkplaceContactRoles = createAsyncThunk(
  'contact/getWorkplaceContactRoles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`workplace_roles`);
      return response.data as Array<{ id: number; name: string }>;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const addCompanyContact = createAsyncThunk(
  'contact/addCompanyContact',
  async (params: AddCompanyContactParams, { rejectWithValue }) => {
    try {
      const response = await API.post(`company_contacts`, { data: { attributes: params } });
      return response.data as ContactEntityResponse;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const editCompanyContact = createAsyncThunk(
  'contact/editCompanyContact',
  async (params: EditCompanyContactParams, { rejectWithValue }) => {
    const { contactId, ...attributes } = params;
    try {
      const response = await API.put(`company_contacts/${contactId}`, { data: { attributes } });
      return response.data as ContactEntityResponse;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);
export const addWorkplaceContact = createAsyncThunk(
  'contact/addWorkplaceContact',
  async (params: AddWorkplaceContactParams) => {
    const response = await API.post(`workplace_contacts`, { data: { attributes: params } });
    return response.data as ContactEntityResponse;
  },
);

export const editWorkplaceContact = createAsyncThunk(
  'contact/editWorkplaceContact',
  async (params: EditWorkplaceContactParams) => {
    const { contactId, ...attributes } = params;
    const response = await API.put(`workplace_contacts/${contactId}`, { data: { attributes } });
    return response.data as ContactEntityResponse;
  },
);

export const deleteCompanyContact = createAsyncThunk(
  'contact/deleteCompanyContact',
  async (companyVisitContactId: string, { rejectWithValue }) => {
    try {
      await API.delete(`company_contacts/${companyVisitContactId}`);
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const sendEmail = createAsyncThunk('contact/sendEmail', async (params: FormData) => {
  const response = await API.post(`send_emails`, params, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response.data;
});

export const setCompanyContactColumnsConfig = createAsyncThunk(
  'contact/setCompanyContactColumnsConfig',
  async ({ order, visibility }: { order?: string[]; visibility?: string[] }) => {
    return { order, visibility };
  },
);

export const getContactsCSV = async (ids: string[]) => {
  const response = await API.post(
    `company_contacts`,
    {
      id: ids.join(','),
    },
    {
      params: { page: 1, page_size: 10000 },
      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
      },
    },
  );

  const blob = new Blob([response.request.response], {
    type: 'application/vnd.ms-excel',
  });

  FileSaver.saveAs(blob, 'contacts-export.xls');
  return response.data as ContactEntityResponse;
};
