import React from 'react';
import { FieldRenderProps } from 'react-final-form';

import { StyledError, StyledFieldWrapper, StyledLabel } from 'components/Forms/styles';
import { RadioButtonWithLabel } from 'components/RadioButtonWithLabel/RadioButtonWithLabel';

type Option = {
  label: string;
  value: string | number;
  isDisabled?: boolean;
};

interface ListSelectFieldPropTypes extends FieldRenderProps<string | number> {
  label?: string;
  required?: boolean;
  options: Option[];
}

export const ListSelectField = ({
  input,
  label,
  meta,
  options,
  required,
}: ListSelectFieldPropTypes) => {
  return (
    <StyledFieldWrapper>
      {label && (
        <StyledLabel>
          {label}
          {required ? '*' : ''}
        </StyledLabel>
      )}

      <div>
        {options.map((item) => (
          <RadioButtonWithLabel
            isDisabled={item.isDisabled}
            isSelected={input.value === item.value}
            key={item.value}
            onClick={() => input.onChange(item.value)}
          >
            {item.label}
          </RadioButtonWithLabel>
        ))}
      </div>
      {meta.touched && meta.error && <StyledError>{meta.error}</StyledError>}
      <input name={input.name} onChange={input.onChange} type='hidden' value={input.value} />
    </StyledFieldWrapper>
  );
};
