import styled, { css } from 'styled-components/macro';

import { ReactComponent as CloseIcon } from 'images/closeIcon.svg';
import { ReactComponent as UndoIcon } from 'images/undoIcon.svg';

export const StyledFilesListItem = styled.li`
  align-items: center;
  color: ${({ theme }) => theme.colors.black500};
  display: flex;
  padding: ${({ theme }) => `0 0 ${theme.sizes.hmd} ${theme.sizes.md}`};
`;

export const StyledFileName = styled.span<{ isRejected?: boolean }>`
  align-items: center;
  color: ${({ theme }) => theme.colors.black500};
  display: inline;
  overflow: hidden;
  padding-right: ${({ theme }) => theme.sizes.hmd};
  text-overflow: ellipsis;
  white-space: nowrap;

  & svg {
    ${({ theme }) => theme.icon.sm};
    margin-right: ${({ theme }) => theme.sizes.hmd};
  }

  ${({ isRejected }) =>
    isRejected &&
    css`
      text-decoration: line-through;
    `}
`;

export const StyledDeleteIcon = styled(CloseIcon)`
  ${({ theme }) => theme.icon.sm};
  color: ${({ theme }) => theme.colors.red100};
  cursor: pointer;
`;

export const StyledUndoIcon = styled(UndoIcon)`
  ${({ theme }) => theme.icon.sm};
  color: ${({ theme }) => theme.colors.green300};
  cursor: pointer;
`;
