import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

import { GroupListPickerItem } from './GroupListPickerItem';
import { TextInput } from 'components/TextInput/TextInput';
import { Margin } from 'styles/utils';

import { ReactComponent as SearchIcon } from 'images/searchIcon.svg';
import { StyledGroupListPickerSearch, StyledGroupListPickerWrapper } from './styles';

export interface GroupListItem {
  description?: string;
  id: string;
  name?: string;
}

export interface GroupListPickerGroup {
  elements: GroupListItem[];
  id: string;
  name: string;
}

interface GroupListPickerPropTypes {
  items: GroupListPickerGroup[];
  onChange: (values: string[]) => void;
  values: string[];
  withSearch?: boolean;
}

export const GroupListPicker: FC<GroupListPickerPropTypes> = ({
  items,
  onChange,
  values,
  withSearch,
}) => {
  const [filtredOptions, setFiltredOptions] = useState(items);
  const { t } = useTranslation();

  const debounceSearch = useCallback(
    debounce((searchText: string) => {
      const newOptions = filtredOptions
        .map(({ elements, ...groupProps }) => {
          const filtredElements = elements.filter(
            ({ description, name }) =>
              name?.includes(searchText) || description?.includes(searchText),
          );

          return { ...groupProps, elements: filtredElements };
        })
        .filter(({ elements }) => elements.length > 0);

      setFiltredOptions(newOptions);
    }, 350),
    [setFiltredOptions],
  );

  return (
    <StyledGroupListPickerWrapper>
      <StyledGroupListPickerSearch>
        {withSearch && (
          <Margin bottom='2.4rem'>
            <TextInput
              inputProps={{ onChange: (e) => debounceSearch(e.target.value) }}
              placeholder={`${t('filters.search')}...`}
              postfixElement={<SearchIcon />}
              size='fullWidth'
              withClearButton
            />
          </Margin>
        )}
      </StyledGroupListPickerSearch>

      {filtredOptions.map((group) => (
        <GroupListPickerItem
          groupName={group.name}
          items={group.elements}
          key={group.name}
          selected={values}
          setSelection={onChange}
        />
      ))}
    </StyledGroupListPickerWrapper>
  );
};
