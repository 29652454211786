import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, StyledCardDivider, StyledCardHeader } from 'components/Card/Card';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { ButtonWrapper } from 'components/ButtonWrapper/ButtonWrapper';
import { ValueWithLabel } from 'components/ValueWithLabel/ValueWithLabel';
import {
  StyledAddressContainer,
  StyledAddressForm,
  StyledAddressTag,
  StyledFieldWrapper,
  StyledLink,
} from './styles';
import { TextInput } from 'components/TextInput/TextInput';
import { CompanyEntity } from 'ducks/companies/types';
import { API } from 'api';
import { StaticMapImage } from 'components/StaticMapImage/StaticMapImage';
import { updateCompany } from 'ducks/companies/actions';
import { amendAddresses } from 'ducks/powerSearch/company/reducer';
import { useAppDispatch } from 'store';

import { ReactComponent as EditIcon } from 'images/editIcon.svg';

type ResponseType = {
  data: {
    name: string;
    id: number;
  };
  status: number;
};

export const BasicDataAddresses: FC<{ company: CompanyEntity }> = ({ company }) => {
  const visitAddress = company.addresses.find((el) => el.typeSymbol === 'visit') || {
    street: '',
    streetNumber: '',
    postalCode: '',
    city: '',
  };
  const [isEdit, setIsEdit] = useState(false);
  const [lock, setLock] = useState(false);
  const [street, setStreet] = useState(visitAddress.street);
  const [streetNumber, setStreetNumber] = useState(visitAddress.streetNumber);
  const [postalCode, setPostalCode] = useState(visitAddress.postalCode);
  const [postalCodeId, setPostalCodeId] = useState<number | undefined>();
  const [postcodeError, setPostcodeError] = useState(false);
  const [city, setCity] = useState(visitAddress.city);
  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setStreet(visitAddress.street);
    setStreetNumber(visitAddress.streetNumber);
    setPostalCode(visitAddress.postalCode);
    setCity(visitAddress.city);
  }, [company?.id]);

  const onSubmit = async () => {
    setSubmitting(true);
    try {
      await dispatch(
        updateCompany({
          companyId: company.id,
          street,
          street_number: streetNumber,
          postal_area_id: postalCodeId,
        }),
      );
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
    setIsEdit(false);
  };

  const amendedAddresses = useMemo(() => amendAddresses(company?.addresses), [company, t]) || [];

  const postCodeCallback = async (event: React.FormEvent<HTMLInputElement>) => {
    const { value }: { value: string } = event.currentTarget;
    setPostalCode(value);
    setLock(true);
    setCity('');
    setPostcodeError(false);
    if (value.length === 4) {
      try {
        const response: ResponseType = await API.get(`/post_codes/${value}`);
        setCity(response.data.name);
        setPostalCodeId(response.data.id);
        setLock(false);
      } catch (error) {
        setPostcodeError(true);
      }
    }
  };

  const validateAddress = () => {
    return street === '' || streetNumber === '' || postalCode === '' || city === '';
  };

  return (
    <Card>
      <StyledCardHeader>
        {t('company_details.basic-data.addresses')}
        {isEdit ? (
          <ButtonWrapper>
            <DefaultButton
              capitalize
              isDisabled={submitting}
              label={t('common.cancel')}
              onClick={() => setIsEdit(false)}
              size='small'
              type='button'
              variant='secondary'
            />
            <DefaultButton
              capitalize
              isDisabled={lock || submitting || validateAddress()}
              label={t('common.save')}
              onClick={onSubmit}
              size='small'
              type='button'
              variant='primary'
            />
          </ButtonWrapper>
        ) : (
          <ButtonWrapper>
            <DefaultButton
              capitalize
              icon={<EditIcon />}
              label={t('common.edit')}
              onClick={() => setIsEdit(true)}
              size='small'
              type='button'
              variant='tertiary'
            />
          </ButtonWrapper>
        )}
      </StyledCardHeader>

      <StyledCardDivider fullWidth />

      {isEdit && (
        <ValueWithLabel
          flexDirection='vertical'
          label={t('company_details.basic-data.address_types.visit')}
        >
          <StyledAddressForm>
            <StyledFieldWrapper>
              <TextInput
                inputProps={{
                  value: street,
                  onChange: (val) => setStreet(val.currentTarget.value),
                }}
                label={t('forms.street')}
                size='fullWidth'
              />
            </StyledFieldWrapper>
            <StyledFieldWrapper>
              <TextInput
                inputProps={{
                  value: streetNumber,
                  onChange: (val) => setStreetNumber(val.currentTarget.value),
                }}
                label={t('forms.street_number')}
              />
            </StyledFieldWrapper>
            <StyledFieldWrapper>
              <TextInput
                inputProps={{
                  value: postalCode,
                  onChange: postCodeCallback,
                  maxLength: 4,
                }}
                isError={postcodeError}
                label={t('forms.post_code')}
              />
            </StyledFieldWrapper>
            <StyledFieldWrapper>
              <TextInput
                inputProps={{ value: city || '' }}
                isDisabled
                label={t('forms.post_area')}
                size='big'
              />
            </StyledFieldWrapper>
          </StyledAddressForm>
        </ValueWithLabel>
      )}

      {amendedAddresses.map((address, i) => {
        if (isEdit && address.typeSymbol === 'visit') return null;
        return (
          <StyledAddressContainer key={i}>
            {!isEdit && <StyledAddressTag>{address.tagLetter}</StyledAddressTag>}
            <ValueWithLabel label={address.label}>
              <StyledLink
                href={address.gMapLink}
                isDisabled={isEdit}
                rel='noopener noreferrer'
                target='_blank'
              >
                {address.addressWithCountry}
              </StyledLink>
            </ValueWithLabel>
          </StyledAddressContainer>
        );
      })}

      <StaticMapImage addresses={amendedAddresses} />
    </Card>
  );
};
