import styled from 'styled-components';

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  z-index: 100;

  svg {
    ${({ theme }) => theme.icon.xxl};
  }
`;
