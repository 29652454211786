import React, { MutableRefObject } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FileWithPath } from 'file-selector';
import axios, { CancelTokenSource } from 'axios';

import { Notes } from 'containers/newVisit/shared/Notes';
import {
  deleteCompanyVisitAttachment,
  newVisitSummaryNotesSelector,
  reduxDeleteCompanyVisitAttachment,
  updateCompanyVisit,
  uploadCompanyVisitAttachment,
} from 'ducks/newVisit';
import { useAppDispatch } from 'store';
import { AttachmentWithPreview } from 'components/Forms/Attachments/AttachmentsWithRichText';

interface SummaryNotesPropTypes {
  cancelToken: MutableRefObject<CancelTokenSource | null>;
  companyVisitId: string;
  isDisabled: boolean;
}

export const SummaryNotes = ({
  cancelToken,
  companyVisitId,
  isDisabled,
}: SummaryNotesPropTypes) => {
  const summaryNotes = useSelector(newVisitSummaryNotesSelector(companyVisitId));
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const summaryNotesTextarea = {
    label: 'Add note',
    onBlur: (comment: string) => {
      cancelToken.current?.cancel();
      const UpdateCancelToken = axios.CancelToken;
      cancelToken.current = UpdateCancelToken.source();
      dispatch(
        updateCompanyVisit({
          comment,
          companyVisitId,
          token: cancelToken.current,
          without: ['comment'],
        }),
      );
    },
    placeholder: 'Write a note',
    defaultValue: summaryNotes.notes,
  };

  const attachmentsToRemoveProps = {
    attachmentList: summaryNotes.attachments ?? [],
    deleteFile: (id: number) => dispatch(reduxDeleteCompanyVisitAttachment({ id, companyVisitId })),
  };

  const uploadAttachment = React.useCallback(
    async (file: FileWithPath) => {
      const data = new FormData();
      data.append('data[attributes][comment_attachment]', file);
      data.append('data[attributes][company_visit_id]', companyVisitId);
      try {
        return await uploadCompanyVisitAttachment(data);
      } catch (error) {
        throw new Error((error as Error)?.message ?? t('common.error_message'));
      }
    },
    [companyVisitId],
  );

  const deleteFile = React.useCallback(
    ({ id }: AttachmentWithPreview) => deleteCompanyVisitAttachment({ companyVisitId, id }),
    [companyVisitId],
  );

  return (
    <Notes
      attachmentsToRemoveProps={attachmentsToRemoveProps}
      deleteFile={deleteFile}
      editorProps={summaryNotesTextarea}
      isDisabled={isDisabled}
      key={companyVisitId}
      label={t('new_visits.summary_notes')}
      testid={'new-visit-summary-notes'}
      uploadAttachment={uploadAttachment}
      withStandardComments
    />
  );
};
