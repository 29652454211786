import React, { ComponentType, useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useCombobox } from 'downshift';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';
import { WorkplaceListItem } from 'containers/newVisit/OrdinaryVisit/styles';
import { Text } from 'components/Text/Text';
import { ArrowRightBigIcon, EyeIcon } from 'utils/iconsMap';
import { StyledError, StyledFieldWrapper, StyledLabel } from 'components/Forms/styles';
import { StyledMenu } from 'components/FieldSingleSelect/styles';
import { FieldTextPropTypes, TextInput } from 'components/TextInput/TextInput';
import { StyledClearIcon } from 'components/SelectButton/SelectButton';
import { promiseDebounce } from 'utils';
import { StyledSearchIcon } from 'components/Forms/AsyncSelectField/styles';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { useOnClickOutside } from 'utils/hooks';
import { capitalize } from 'lodash';
import { StatusBadge } from 'components/StatusBadge/StatusBadge';
import { useTranslation } from 'react-i18next';
import { ContentContainer } from 'styles/utils';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { CompanyPreviewModal } from 'containers/newVisit/shared/CompanyPreviewModal';
import { fetchCompany } from 'ducks/companies/actions';
import { useModal } from 'components/Modal/Modal';
import { SearchSelectListItemStandard } from '../SearchSelectWithCheckbox/styles';

type Option = {
  status: string;
  value: string;
  label: string;
  municipalityName: string;
  orgNo: string;
  companyId: string;
};

interface SingleSelectFieldPropTypes extends FieldRenderProps<Option | null> {
  additionalInputProps?: FieldTextPropTypes;
  fetchFunction: (query: string) => Promise<Option[]>;
  id?: string;
  isChecked: boolean;
  isDisabled?: boolean;
  label?: string;
  labelCheckbox: string;
  onSelect?: (selectedItem?: Option | null) => void;
  placeholder?: string;
  required?: boolean;
  setSearchInactive: (val: boolean) => void;
  size?: 'small' | 'medium' | 'big' | 'fullWidth';
  withClearButton?: boolean;
}

const TOOLTIP_ROOT_ELEMENT = document.querySelector('body');

const AsyncSelectFieldWithCheckbox: ComponentType<SingleSelectFieldPropTypes> = ({
  additionalInputProps,
  input,
  placeholder,
  id,
  isChecked = false,
  label,
  setSearchInactive,
  labelCheckbox,
  size = 'fullWidth',
  required,
  fetchFunction,
  withClearButton,
  meta,
  isDisabled,
  onSelect,
}: SingleSelectFieldPropTypes) => {
  const [inputItems, setInputItems] = useState<Option[]>([]);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLUListElement | null>(null);
  const [listVisible, setVisibility] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        options: { fallbackPlacements: ['top'] },
      },
    ],
  });

  const { t } = useTranslation();

  const debouncedFetch = promiseDebounce(async (query: string) => fetchFunction(query), 300);

  const {
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    inputValue,
    setInputValue,
    reset,
  } = useCombobox({
    items: inputItems,
    onInputValueChange: async ({ inputValue, selectedItem }) => {
      if (selectedItem?.label !== inputValue) {
        try {
          const options = await debouncedFetch(inputValue);
          setInputItems(options);
        } catch (err) {}
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      input.onChange(selectedItem);
      onSelect && onSelect(selectedItem);
      setVisibility(false);
    },
    itemToString: (item) => item?.label || '',
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.FunctionReset:
          input.onChange(selectedItem);
          break;
        default:
          break;
      }
    },
  });
  useEffect(() => {
    setInputValue(input?.value?.label || '');
  }, []);

  useEffect(() => {
    const updateResults = async () => {
      const options = await debouncedFetch(inputValue, isChecked);
      setInputItems(options);
    };
    if (inputValue) {
      updateResults();
    }
  }, [isChecked]);
  const { setModalComponent, showModal, setShowHeader } = useModal();
  const listItemsRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  useOnClickOutside(listItemsRef, (event: { target: Node | null }) => {
    if (!inputRef?.current?.contains(event.target)) setVisibility(false);
  });

  const companyPreviewModalHandle = async (companyId: string) => {
    const company = await fetchCompany(companyId);
    setModalComponent(<CompanyPreviewModal company={company} />);
    setShowHeader(true);
    showModal();
  };

  return (
    <StyledFieldWrapper id={id}>
      {label && (
        <StyledLabel {...getLabelProps()}>
          {label}
          {required ? '*' : ''}
        </StyledLabel>
      )}

      <div
        ref={inputRef}
        style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', width: '100%' }}
      >
        <div ref={setReferenceElement} style={{ marginRight: '1.6rem' }}>
          <div {...getComboboxProps()}>
            <TextInput
              inputProps={{
                ...getInputProps(),
                onFocus: () => {
                  setVisibility(true);
                },
                name: input.name,
              }}
              isDisabled={isDisabled || false}
              isError={meta.touched && meta.error}
              placeholder={placeholder || ''}
              postfixElement={withClearButton && !isDisabled && <StyledClearIcon onClick={reset} />}
              prefixElement={<StyledSearchIcon />}
              size={size}
              {...additionalInputProps}
            />
            {meta.touched && meta.error && <StyledError>{meta.error}</StyledError>}
          </div>
        </div>
        <Checkbox
          isChecked={isChecked}
          isDisabled={isDisabled || false}
          label={labelCheckbox}
          onClick={(x) => {
            setSearchInactive(!x);
          }}
        />
      </div>
      {createPortal(
        <div {...getMenuProps()}>
          {listVisible && (
            <div ref={listItemsRef}>
              <StyledMenu
                {...getMenuProps()}
                isOpen={listVisible}
                ref={setPopperElement}
                style={{ ...styles.popper, maxHeight: '40vh' }}
                {...attributes.popper}
              >
                {inputItems.map((item, index) => (
                  <SearchSelectListItemStandard
                    key={item.companyId}
                    {...getItemProps({ item, index })}
                  >
                    <WorkplaceListItem key={item.label + index}>
                      <div>
                        <Text>
                          <StatusBadge variant={item.status === 'active' ? 'success' : 'inactive'}>
                            {capitalize(
                              t(
                                `company_details.company_is_${
                                  item.status === 'active' ? 'active' : 'inactive'
                                }`,
                              ),
                            )}
                          </StatusBadge>
                          {item.label}
                        </Text>
                        {item?.municipalityName && <Text size='xs'>{item.municipalityName}</Text>}
                        <Text color='black500' size='xxs'>
                          {t('forms.org_number')}: {item.orgNo}
                        </Text>
                      </div>
                      <ContentContainer display='flex' margin='auto 1.2rem auto auto'>
                        <DefaultButton
                          icon={<EyeIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            companyPreviewModalHandle(item.companyId);
                          }}
                          size='small'
                          variant='tertiary'
                        />
                      </ContentContainer>
                      <ArrowRightBigIcon />
                    </WorkplaceListItem>
                  </SearchSelectListItemStandard>
                ))}
              </StyledMenu>
            </div>
          )}
        </div>,
        TOOLTIP_ROOT_ELEMENT,
      )}
    </StyledFieldWrapper>
  );
};

AsyncSelectFieldWithCheckbox.defaultProps = {
  options: [],
  required: false,
};

export { AsyncSelectFieldWithCheckbox };
