import React, { FC, ReactElement, useCallback, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import axios, { CancelTokenSource } from 'axios';
import * as yup from 'yup';

import { SearchSelect } from 'components/Forms/SearchSelect/SearchSelect';
import { SearchSelectListItemStandard } from 'components/Forms/SearchSelect/styles';
import { StyledCompanySearchWrapper, WorkplaceListItem } from './../OrdinaryVisit/styles';
import { getContacts } from 'ducks/powerSearch/contacts/actions';
import { ContentContainer, Margin } from 'styles/utils';
import { Text } from 'components/Text/Text';

import { ReactComponent as RightArrowIcon } from 'images/arrowRightBigIcon.svg';

export interface Recipient {
  name: string;
  email: string;
}

interface EmailSearchSelectPropTypes {
  onSelect: (recipient: Recipient) => void;
}

const emailSchema = yup.string().email().required();

export const EmailSearchSelect: FC<EmailSearchSelectPropTypes> = ({ onSelect }) => {
  const [listItems, setListItems] = useState<ReactElement[]>([]);
  const [error, setError] = useState('');
  const cancelToken = useRef<CancelTokenSource | null>(null);
  const { t } = useTranslation();

  const onSearchHandle = async (text: string) => {
    setError('');
    if (text.length === 0) setListItems([]);
    else debouncedSearchContacts({ name: text });
  };

  const debouncedSearchContacts = useCallback(
    debounce(async (params: { name: string }) => {
      cancelToken.current?.cancel();
      const CancelToken = axios.CancelToken;
      cancelToken.current = CancelToken.source();
      const response = await getContacts({ ...params, email_present: true }, cancelToken.current);

      if (response.data?.tableBody?.length > 0) {
        const contacts = response.data.tableBody;
        setListItems(
          contacts.map((item) => (
            <SearchSelectListItemStandard
              key={item.id}
              onClick={async () => {
                try {
                  await emailSchema.validate(item.email);
                  setError('');
                  onSelect({ name: item.name, email: item.email });
                } catch (err) {
                  setError(err.message);
                }
              }}
            >
              <WorkplaceListItem>
                <div>
                  <Text bold>{item.name}</Text>
                  <Text size='sm'>{item.email}</Text>
                </div>
                <RightArrowIcon />
              </WorkplaceListItem>
            </SearchSelectListItemStandard>
          )),
        );
      } else {
        setListItems([
          <SearchSelectListItemStandard key='no-results'>
            {t('new_visits.no_results_message')}
          </SearchSelectListItemStandard>,
        ]);
      }
    }, 350),
    [onSelect],
  );

  return (
    <Margin margin='0.8rem 0'>
      <ContentContainer maxWidth='64rem'>
        <StyledCompanySearchWrapper>
          <SearchSelect
            clearOnSelect
            closeOnSelect
            listItems={listItems}
            onSearch={onSearchHandle}
            placeholder={t('new_visits.find_contacts')}
          />
        </StyledCompanySearchWrapper>
        {error && (
          <Text bold color='red500'>
            {error}
          </Text>
        )}
      </ContentContainer>
    </Margin>
  );
};
