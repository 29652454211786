import React, { useCallback } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { isEmpty } from 'lodash';
import * as yup from 'yup';
import i18n from 'i18n';

import { StyledCardDivider, StyledCardHeader } from 'components/Card/Card';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { EmbededDatepickerField } from 'components/Forms/EmbededDatepickerField/EmbededDatepickerField';
import { StyledModalButtonsWrapper, useModal } from 'components/Modal/Modal';
import { StyledChooseVisitForm, StyledNewVisitModalHeader } from 'containers/newVisit/styles';
import { yupValidate } from 'utils/yupValidate';
import { formatQueryDate } from 'utils/date';
import { setNewVisitDateAndId } from 'ducks/newVisit';
import { useAppDispatch } from 'store';
import { ChooseCompaniesVisit } from 'dialogs/Dashboard/ChooseCompaniesDashboardVisit';
import { StyledDateAndTypeContainer } from './DashboardStyles';

const t = (x: string) => i18n.t(x);

const schema = yup.object().shape({
  date: yup.date().required(),
});

type ValuesType = {
  date: Date;
};

interface ChooseDateDashboardVisitProps {
  callback?: (id: string | number) => void | Promise<unknown>;
  initialDate?: Date;
  initialType?: string;
  visitId: string;
}

export const ChooseDateDashboardVisit = (props: ChooseDateDashboardVisitProps): JSX.Element => {
  const { visitId } = props;
  const { hideModal } = useModal();
  const [step2, setStep2] = React.useState(false);
  const [newVisitId, setNewVisitId] = React.useState<string | number>('');
  const dispatch = useAppDispatch();

  const onSubmit = useCallback(async (values: ValuesType) => {
    const date = formatQueryDate(values.date);
    const callback = async (id: string | number) => {
      await props.callback?.(id);
      setNewVisitId(id);
      setStep2(true);
    };
    if (date) {
      dispatch(setNewVisitDateAndId({ visitDate: date, type: 'ordinary', callback }));
    }
  }, []);

  const initialValues = {
    date: props.initialDate || '',
  };

  return step2 ? (
    <ChooseCompaniesVisit originalVisitId={visitId} visitId={newVisitId} />
  ) : (
    <>
      <StyledNewVisitModalHeader>{t('new_visits.choose_date')}</StyledNewVisitModalHeader>

      <Form
        initialValues={initialValues as unknown as ValuesType}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <StyledChooseVisitForm onSubmit={handleSubmit}>
            <StyledDateAndTypeContainer>
              <div>
                <StyledCardHeader>{t('new_visits.visit_date')}</StyledCardHeader>
                <StyledCardDivider />
                <Field
                  component={EmbededDatepickerField}
                  extraOptions={{
                    tileDisabled: ({ date, view }: { date: Date; view: string }) =>
                      view === 'month' && /0|6/.test(date.getDay().toString()),
                  }}
                  name='date'
                  required
                  subscription={{ value: true, touched: true, error: true }}
                />
              </div>
            </StyledDateAndTypeContainer>

            <StyledModalButtonsWrapper>
              <DefaultButton
                capitalize
                label={t('common.cancel')}
                onClick={hideModal}
                type='button'
                variant='tertiary'
              />
              <FormSpy subscription={{ errors: true }}>
                {({ errors }) => (
                  <DefaultButton
                    capitalize
                    isDisabled={!isEmpty(errors)}
                    label={t('common.next_step')}
                    type='submit'
                  />
                )}
              </FormSpy>
            </StyledModalButtonsWrapper>
          </StyledChooseVisitForm>
        )}
        subscription={{}}
        validate={yupValidate(schema)}
      />
    </>
  );
};
