import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useSelector } from 'react-redux';

import { checkIsIpad } from '../../utils/deviceChecker';
import { currentUserSelector } from 'ducks/users/selectors';
import { DashboardVisits } from './DashboardVisits';
import { StyledLayoutWithFullWidth } from 'containers/styles';
import { StyledTabContainerVertical } from 'containers/powersearch/Page/styles';

export const Dashboard: FC<RouteComponentProps> = () => {
  const user = useSelector(currentUserSelector);
  if (!user) return null;

  const isIpad = checkIsIpad();

  return (
    <StyledTabContainerVertical isIpad={isIpad}>
      <StyledLayoutWithFullWidth>
        <DashboardVisits userId={user.id} />
      </StyledLayoutWithFullWidth>
    </StyledTabContainerVertical>
  );
};
