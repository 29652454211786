import styled, { css } from 'styled-components/macro';

export const BreadcrumbContainer = styled.div`
  background-color: white;
  display: flex;
`;

export const BreadcrumbNumber = styled.div`
  ${({ theme }) => theme.icon.md};
  ${({ theme }) => theme.fontSizes.xs};
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blue400};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  color: white;
  display: inline-flex;
  justify-content: center;
  margin: 0 ${({ theme }) => theme.sizes.hmd};
`;

export const BreadcrumbText = styled.div`
  ${({ theme }) => theme.fontSizes.base};
  color: ${({ theme }) => theme.colors.black500};
  white-space: nowrap;
`;

export const BreadcrumbStep = styled.button<{ active: boolean }>`
  align-items: center;
  background-color: white;
  display: flex;
  margin: 0 ${({ theme }) => theme.sizes.xs};

  ${({ active }) =>
    !active &&
    css`
      pointer-events: none;

      & ${BreadcrumbText} {
        color: ${({ theme }) => theme.colors.black200};
      }
      & ${BreadcrumbNumber} {
        background-color: white;
        border: 1px solid ${({ theme }) => theme.colors.black200};
        color: ${({ theme }) => theme.colors.black300};
      }
    `}

  @media (max-width: 800px) {
    flex-wrap: wrap;

    & ${BreadcrumbText} {
      margin-top: ${({ theme }) => theme.sizes.xxs};
    }
  }
`;
