import React, { useEffect } from 'react';
import * as yup from 'yup';
import {
  StyledChooseVisitForm,
  StyledDateAndTypeContainer,
  StyledNewVisitModalHeader,
} from './styles';
import i18n from 'i18n';
import { add, format, isBefore, isFuture } from 'date-fns';
import { StyledModalButtonsWrapper } from 'components/Modal/styles';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useModal } from 'components/Modal/Modal';
import { Field, Form } from 'react-final-form';
import { StyledCardDivider, StyledCardHeader } from 'components/Card/styles';
import { EmbededDatepickerField } from 'components/Forms/EmbededDatepickerField/EmbededDatepickerField';
import { SingleSelectField } from 'components/Forms/SingleSelectField/SingleSelectField';
import { ListSelectField } from 'components/Forms/ListSelectField/ListSelectField';
import { capitalize } from 'lodash';
import { useScreenSizeContext } from 'contexts/ScreenSizeContext';
import { getVisitSubTypes } from 'ducks/constants/actions';
import { unwrapResult } from '@reduxjs/toolkit';
import store, { useAppDispatch } from 'store';
import { VisitSubType } from 'ducks/constants/types';
import toast from 'react-hot-toast';
import {
  NewVisitType,
  resetNewVisit,
  setNewVisitWorkplaceOption,
  setVisitWithoutRequest,
} from 'ducks/newVisit';
import { currentUserSelector } from 'ducks/users/selectors';
import { formatQueryDate } from 'utils/date';
import { navigate } from '@reach/router';
import { allFilteredDistrictsSelector } from 'ducks/powerSearch/selectors';
import { yupValidate } from 'utils/yupValidate';

interface ChooseVisitModalProps {
  noType?: boolean;
  initialDate?: Date;
  initialType?: string;
  initialSubType?: string;
  companyId?: string;
  workplaceId?: string;
}

interface ValuesType {
  date: Date;
  type: string;
  subType?: VisitSubType;
}

type SubType = {
  label: string;
  value: string;
};

export const previousDistrictNames = () => {
  if (
    window.history.state?.previousDistrictNames &&
    window.location.pathname.includes('new-ordinary-visit')
  ) {
    return window.history.state.previousDistrictNames;
  } else if (window.history.state && window.history.state.districtNames) {
    return window.history.state.districtNames;
  } else {
    return null;
  }
};

const onSubmitHandler = async (
  { date, type, subType }: ValuesType,
  companyId?: string,
  workplaceId?: string,
) => {
  const user = currentUserSelector(store.getState());
  const allDistricts = allFilteredDistrictsSelector(store.getState()).map(
    (district) => district.value,
  );

  store.dispatch(resetNewVisit({ id: '0' }));
  if (companyId) store.dispatch(setNewVisitWorkplaceOption('SameAsCompany'));
  if (workplaceId) store.dispatch(setNewVisitWorkplaceOption('Find'));

  store.dispatch(
    setVisitWithoutRequest({
      visitDate: formatQueryDate(date)!,
      userId: user?.id ?? null,
      type: (type[0].toUpperCase() + type.substring(1)) as NewVisitType,
      light: type === 'light',
    }),
  );

  navigate(`/redesigned/new-ordinary-visit/0/workplace`, {
    state: {
      ...window.history.state,
      previousPathname: window.location.pathname,
      districtNames: {
        type: 'string_array',
        values: allDistricts,
      },
      previousDistrictNames: previousDistrictNames(),
      subType: subType,
      companyId,
      workplaceId,
    },
  });
};

const ApprovalModal = ({
  values,
  companyId,
  workplaceId,
}: {
  values: ValuesType;
  companyId?: string;
  workplaceId?: string;
}) => {
  const t = (x: string) => i18n.t(x);
  const { hideModal } = useModal();

  const onSubmit = async () => {
    await onSubmitHandler(values, companyId, workplaceId);
    hideModal();
  };

  return (
    <>
      <StyledNewVisitModalHeader>{t('new_visits.confirm_date')}</StyledNewVisitModalHeader>
      <div style={{ marginBottom: '2.4rem', textAlign: 'center', width: 'fit-content' }}>
        <p>
          {t('new_visits.selected_date') +
            (values.date && format(values.date, 'dd-MM-yy')) +
            t('new_visits.selected_date_2')}
        </p>
      </div>
      <StyledModalButtonsWrapper>
        <DefaultButton
          capitalize
          label={t('common.cancel')}
          onClick={hideModal}
          type='button'
          variant='tertiary'
        />
        <DefaultButton capitalize label={t('common.confirm')} type='button' onClick={onSubmit} />
      </StyledModalButtonsWrapper>
    </>
  );
};

export const ChooseVisitModal = ({
  noType,
  initialDate,
  initialSubType,
  companyId,
  initialType,
  workplaceId,
}: ChooseVisitModalProps) => {
  const [subTypes, setSubTypes] = React.useState<SubType[]>([]);
  const { md } = useScreenSizeContext();
  const { hideModal } = useModal();
  const { setModalComponent } = useModal();
  const dispatch = useAppDispatch();
  const t = (x: string) => i18n.t(x);
  const { BRAND_ID } = window.REACT_APP_ENV || 'hrr';

  const VISIT_TYPES = ['ordinary'];
  if (BRAND_ID === 'hrr') {
    VISIT_TYPES.push('light');
  }

  const visitsOptions = VISIT_TYPES.map((type) => ({
    label: t(`new_visits.${type}_visit`),
    value: type,
  }));

  const schema = yup.object().shape({
    type: yup.string().oneOf(VISIT_TYPES).required(),
    date: yup.date().required(),
  });

  const initialValues = {
    date: initialDate || new Date(),
    type: initialType || 'ordinary',
    subType: initialSubType || undefined,
  };

  useEffect(() => {
    if (BRAND_ID !== 'hrr') return;

    const fetchData = async () => {
      try {
        const data = await dispatch(getVisitSubTypes());
        const results = unwrapResult(data);

        const subTypes = results.map((subType: VisitSubType) => ({
          label: subType.name,
          value: subType.id,
        }));

        setSubTypes(subTypes);
      } catch (err) {
        toast.error(err as string);
      }
    };

    fetchData();
  }, []);

  const needsApproval = (date: Date) =>
    isFuture(date) || isBefore(date, add(new Date(), { weeks: -2 }));

  const onSubmit = async (values: ValuesType) => {
    await onSubmitHandler(values, companyId, workplaceId);
    hideModal();
  };

  return (
    <>
      <StyledNewVisitModalHeader>{t('new_visits.choose_type_and_date')}</StyledNewVisitModalHeader>
      <Form
        validate={yupValidate(schema)}
        initialValues={initialValues as ValuesType}
        onSubmit={onSubmit}
        render={({ handleSubmit, values, form }) => (
          <StyledChooseVisitForm onSubmit={handleSubmit}>
            <StyledDateAndTypeContainer
              direction={noType ? 'column' : md ? 'row' : 'column'}
              mWidth={noType ? 'none' : '25vh'}
            >
              {!noType && (
                <div style={{ display: 'flex', alignItems: 'flex-start', width: '250px' }}>
                  <StyledCardHeader>{t('new_visits.visit_type')}</StyledCardHeader>
                  <StyledCardDivider />
                  <Field
                    component={ListSelectField}
                    name='type'
                    options={visitsOptions}
                    required
                    subscription={{ value: true }}
                  />
                  {values.type === 'light' && (
                    <Field
                      component={SingleSelectField}
                      label={capitalize(t('forms.type'))}
                      name='subType'
                      options={subTypes}
                      placeholder={`${t('forms.select_a')} ${t('forms.type')}`}
                      required={values.type === 'light'}
                      size='small'
                      style={{ maxWidth: '20vh', overflowX: 'hidden' }}
                      subscription={{ value: true, error: true }}
                    />
                  )}
                </div>
              )}
              <div>
                <StyledCardHeader>{t('new_visits.visit_date')}</StyledCardHeader>
                <StyledCardDivider />
                <Field
                  component={EmbededDatepickerField}
                  extraOptions={{
                    tileDisabled: ({ date, view }: { date: Date; view: string }) =>
                      view === 'month' && /0|6/.test(date.getDay().toString()),
                  }}
                  name='date'
                  required
                  subscription={{ value: true, touched: true, error: true }}
                />
              </div>
            </StyledDateAndTypeContainer>
            <StyledModalButtonsWrapper>
              <DefaultButton
                capitalize
                label={t('common.cancel')}
                onClick={hideModal}
                type='button'
                variant='tertiary'
              />
              <DefaultButton
                capitalize
                label={t('common.choose')}
                type='button'
                isDisabled={values.type === 'light' && !values.subType}
                onClick={
                  needsApproval(values.date)
                    ? () =>
                        setModalComponent(
                          <ApprovalModal
                            values={values}
                            companyId={companyId}
                            workplaceId={workplaceId}
                          />,
                        )
                    : () => form.submit()
                }
              />
            </StyledModalButtonsWrapper>
          </StyledChooseVisitForm>
        )}
      />
    </>
  );
};
