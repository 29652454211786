import { createPortal } from 'react-dom';
import React, { FC, useState } from 'react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';

import { StyledArrow, StyledContent, StyledSurface, StyledTooltip } from './styles';

const TOOLTIP_ROOT_ELEMENT = document.querySelector('body');

interface TooltipProps {
  centerContent?: boolean;
  className?: string;
  label: string;
  placement?: Placement;
}

export const Tooltip: FC<TooltipProps> = ({
  centerContent,
  children,
  className,
  label,
  placement = 'top',
}) => {
  const [isHovered, setHovered] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLSpanElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
  });

  return (
    <>
      <StyledContent
        className={className}
        isCentred={centerContent}
        onMouseEnter={() => label && setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        ref={setReferenceElement}
      >
        {children}
      </StyledContent>

      {TOOLTIP_ROOT_ELEMENT &&
        isHovered &&
        createPortal(
          <StyledSurface ref={setPopperElement} style={styles.popper} {...attributes.popper}>
            <StyledTooltip>{label}</StyledTooltip>
            <StyledArrow
              ref={setArrowElement}
              style={{ ...styles.arrow, position: 'relative' }}
              {...attributes.popper}
            />
          </StyledSurface>,
          TOOLTIP_ROOT_ELEMENT,
        )}
    </>
  );
};
