import React from 'react';
import {
  CreateActivityModalLink,
  CreateCompanyModalLink,
  CreateResetUserModalLink,
  CreateWorkplaceModalLink,
} from 'components/MenuDrawer/MenuDrawer';
import { ADMIN, MAIL_LOG, REPORTS, SAVED_SEARCH } from 'utils/urls';

export const MENU_DRAWER_ITEMS = [
  {
    label: 'top_bar.menu_drawer.create_new_business',
    component: (props) => <CreateWorkplaceModalLink {...props} />,
  },
  {
    label: 'top_bar.menu_drawer.create_new_business',
    component: (props) => <CreateCompanyModalLink {...props} />,
  },
  {
    label: 'top_bar.menu_drawer.new_activity',
    component: (props) => <CreateActivityModalLink {...props} />,
  },
  { label: 'top_bar.menu_drawer.bookmarks', url: SAVED_SEARCH },
  { label: 'top_bar.menu_drawer.reports', url: REPORTS },
  { label: 'top_bar.menu_drawer.mail_log', url: MAIL_LOG },
  { label: 'top_bar.menu_drawer.admin', url: ADMIN, withAdminRole: true },
  {
    label: 'top_bar.menu_drawer.reset_user',
    component: (props) => <CreateResetUserModalLink {...props} />,
    withAdminRole: true,
  },
];
