import { camelizeKeys } from 'humps';
import cuid from 'cuid';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  AddSavedSearches,
  CurrentUserEntityResponse,
  GridSettingsType,
  RemoveSavedSearches,
  SavedSearchesType,
  UpdateSavedSearch,
  UsersResponse,
  ValuesPostType,
} from './types';
import { ThunkApiInterface } from 'store';

import { API } from 'api';

export const getCurrentUser = createAsyncThunk('users/getCurrentUser', async () => {
  const response = await API.get(`current_user`);
  if (!response.data.email) return null;
  return response.data as CurrentUserEntityResponse;
});

export const getUsers = createAsyncThunk('users/getUsers', async () => {
  const response = await API.get(`users`);
  return response.data as UsersResponse;
});
export const getUser = createAsyncThunk('users/getUser', async (id: string | number) => {
  const response = await API.get(`user_profiles/${id}`);
  return response.data as UsersResponse;
});

export const editUser = createAsyncThunk(
  'users/editUser',
  async ({ id, ...attributes }: ValuesPostType & { id: number }) => {
    const response = await API.put(`user_profiles/${id}`, {
      data: { attributes },
    });
    return response.data;
  },
);

const amendSavedSearches = (
  savedSearches: Omit<SavedSearchesType, 'actions'>[],
): SavedSearchesType[] =>
  savedSearches.map((s) => ({
    ...s,
    id: cuid(),
    actions: [{ type: 'delete' }, { type: 'edit' }],
  }));

export const addSavedSearches = createAsyncThunk<
  SavedSearchesType[],
  AddSavedSearches,
  ThunkApiInterface
>('users/addSavedSearches', async (params, thunkApi) => {
  const { userId, attributes } = params;
  const prevState = thunkApi.getState().users.current?.savedSearches || [];
  const { gridSettings } = thunkApi.getState().users.current!;
  const savedSearches = amendSavedSearches([...prevState, attributes]);
  const response = await API.put(`users/${userId}`, {
    data: { attributes: { savedSearches, gridSettings } },
  });
  const newSavedSearches = response.data.savedSearches.map((x: object) =>
    camelizeKeys(x),
  ) as SavedSearchesType[];

  return newSavedSearches;
});

export const removeSavedSearches = createAsyncThunk<
  SavedSearchesType[],
  RemoveSavedSearches,
  ThunkApiInterface
>('users/removeSavedSearches', async (params, thunkApi) => {
  const { userId, searchIds } = params;
  const { savedSearches: prevState, gridSettings } = thunkApi.getState().users.current!;
  const savedSearches = prevState.filter((s) => !searchIds.includes(s.id));

  const response = await API.put(`users/${userId}`, {
    data: { attributes: { savedSearches, gridSettings } },
  });
  const newSavedSearches = response.data.savedSearches.map((x: object) =>
    camelizeKeys(x),
  ) as SavedSearchesType[];

  return newSavedSearches;
});

export const updateSavedSearch = createAsyncThunk<
  SavedSearchesType[],
  UpdateSavedSearch,
  ThunkApiInterface
>('users/updateSavedSearch', async (params, thunkApi) => {
  const { userId, attributes } = params;
  const { savedSearches: prevState, gridSettings } = thunkApi.getState().users.current!;
  const index = prevState.findIndex((search) => search.id === attributes.id);
  const savedSearches = [
    ...prevState.slice(0, index),
    ...amendSavedSearches([attributes]),
    ...prevState.slice(index + 1),
  ];

  const response = await API.put(`users/${userId}`, {
    data: { attributes: { savedSearches, gridSettings } },
  });

  return response.data.savedSearches;
});

export const updateGridSettings = createAsyncThunk<
  GridSettingsType,
  GridSettingsType,
  ThunkApiInterface
>('users/updateGridSettings', async (params, thunkApi) => {
  const { savedSearches, gridSettings, id: userId } = thunkApi.getState().users.current!;

  const response = await API.put(`users/${userId}`, {
    data: {
      attributes: {
        savedSearches,
        gridSettings:
          typeof gridSettings === 'object' ? { ...gridSettings, ...params } : { ...params },
      },
    },
  });

  return response.data.gridSettings as GridSettingsType;
});
