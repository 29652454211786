import React from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { deleteRvoEvent } from 'ducks/events/actions';
import { RevisedActivitiesFields } from './RevisedActivitiesFields';
import { RvoEventsEntity } from 'ducks/events/types';
import { StyledModalButtonsWrapper, StyledModalHeader, useModal } from 'components/Modal/Modal';
import { useAppDispatch } from 'store';
import { useLoadingContext } from 'contexts/LoadingContext';

interface DeleteRevisedActivityProps {
  actionCallback?: () => void;
  itemsSelected: RvoEventsEntity[];
}

export const DeleteRevisedActivity = ({
  actionCallback,
  itemsSelected,
}: DeleteRevisedActivityProps): JSX.Element => {
  const { setLoading, isLoading, setLoaded } = useLoadingContext();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { hideModal } = useModal();

  const item = itemsSelected[0] || [];

  return (
    <>
      <StyledModalHeader>{`${t('common.delete_activity')}?`}</StyledModalHeader>
      <RevisedActivitiesFields item={item} />

      <StyledModalButtonsWrapper>
        <DefaultButton
          capitalize
          isDisabled={isLoading('delete-multiple-events')}
          label={t('common.cancel')}
          onClick={hideModal}
          variant='secondary'
        />

        <DefaultButton
          capitalize
          isDisabled={isLoading('delete-multiple-events')}
          label={t('common.delete')}
          onClick={async () => {
            setLoading('delete-multiple-events');
            await Promise.all(itemsSelected.map((item) => dispatch(deleteRvoEvent(item.id))));
            actionCallback && actionCallback();
            setLoaded('delete-multiple-events');
            hideModal();
          }}
          variant='danger'
        />
      </StyledModalButtonsWrapper>
    </>
  );
};
