import React from 'react';
import { cells } from 'utils/cells';
import { rvoEventsComponentsMap } from 'containers/companyDetails/EventsLog/tableComponentsMap';
import { RvoEventsEntity } from 'ducks/events/types';
import { StyledMultiLineCell, StyledNoWrapCellContent } from 'components/Table/TableRow/styles';
import { navigate } from '@reach/router';
import { TextOnclickLink } from 'components/TextOnclickLink/TextOnclickLink';

export const revisedActivitiesTableMapper = {
  ...rvoEventsComponentsMap,

  company_names: {
    component: ({ companies }: RvoEventsEntity) => (
      <StyledMultiLineCell>
        {companies.map(({ companyName, companyId }) => (
          <TextOnclickLink
            key={companyId}
            onClick={() =>
              navigate(`/redesigned/companies/${companyId}/basic-data`, {
                state: { ...window.history.state, previousPathname: window.location.pathname },
              })
            }
          >
            <StyledNoWrapCellContent>{companyName}</StyledNoWrapCellContent>
          </TextOnclickLink>
        ))}
      </StyledMultiLineCell>
    ),
    size: 'min-content',
  },
  district_names: cells.district_names,
};

export type RevisedActivitiesTableMapper = typeof revisedActivitiesTableMapper;
