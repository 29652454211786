import React from 'react';
import styled, { css } from 'styled-components/macro';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';

import { ReactComponent as ArrowDownIcon } from 'images/arrowDownIcon.svg';
import { ReactComponent as ClearIcon } from 'images/closeBigIcon.svg';

interface SelectButtonPropTypes {
  isError?: boolean;
  clearSelection: () => void;
  withClearButton?: boolean;
}

export const StyledClearIcon = styled(ClearIcon)`
  ${({ theme }) => theme.icon.md};
  padding: ${({ theme }) => theme.sizes.xxs};
`;

const IconsWrapper = styled.div`
  line-height: 0;
  margin-left: auto;
`;

const StyledArrowDown = styled(ArrowDownIcon)`
  ${({ theme }) => theme.icon.md};
`;

export const SelectButton = styled(DefaultButton).attrs(
  ({ clearSelection, withClearButton, isError }: SelectButtonPropTypes) => ({
    icon: (
      <IconsWrapper>
        {withClearButton && (
          <StyledClearIcon
            onClick={(e) => {
              e.stopPropagation();
              clearSelection();
            }}
          />
        )}
        <StyledArrowDown />
      </IconsWrapper>
    ),
    iconPosition: 'right',
    isError,
    type: 'button',
    variant: 'tertiary',
    width: 'fullWidth',
  }),
)<{ selected: boolean; isError?: boolean }>`
  border: 0.1rem solid
    ${({ theme, isError }) => (isError ? theme.colors.red500 : theme.colors.black300)};

  ${({ selected, theme }) =>
    !selected &&
    css`
      & > span {
        color: ${theme.colors.black200};
        font-weight: normal;
      }
    `}
`;
