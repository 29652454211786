import React from 'react';
import { Router } from '@reach/router';

import { CreateCompany } from 'containers/CompanyCreate/CreateCompany';
import { Page as CompanyDetailsPage } from 'containers/companyDetails/Page/Page';
import { Page as WorkplaceDetailsPage } from 'containers/workplaceDetails/Page/Page';
import { Visits } from 'containers/companyDetails/Visits/Visits';
import { Contacts } from 'containers/companyDetails/Contacts/Contacts';
import { BasicDataComposed } from 'containers/companyDetails/BasicData/BasicData';
import { EventsLog } from 'containers/companyDetails/EventsLog/EventsLog';
import { PageWithNavbar } from 'containers/PageWithNavbar';

import { Page as PowersearchPage } from 'containers/powersearch/Page/Page';
import { Bookmarks } from 'containers/powersearch/Bookmarks/Bookmarks';
import { NotFound } from 'containers/NotFound/NotFound';

import { OrdinaryVisit } from 'containers/newVisit/OrdinaryVisit/OrdinaryVisit';
import { Workplace as OrdinaryVisitWorkplace } from 'containers/newVisit/OrdinaryVisit/Step1/Workplace';
import { RichTextPresentation } from 'components/RichText/RichTextPresentation';
import { Step3 } from 'containers/newVisit/OrdinaryVisit/Step3/Step3';
import { Dashboard } from 'containers/Dashboard/Dashboard';
import { MailLog } from 'containers/MailLog/MailLog';
import { VisitsWorkplace } from 'containers/workplaceDetails/Visits/Visits';
import { BasicDataWorkplace } from 'containers/workplaceDetails/BasicData/BasicData';
import { Reports } from 'containers/Reports/Reports';
import { NewPassword } from 'containers/Auth/NewPassword';
import { Login } from 'containers/Auth/Login';
import { OTP } from 'containers/Auth/OTP';
import { SetupMFA } from 'containers/Auth/SetupMFA';
import { ProtectedRoute } from 'containers/Auth/ProtectedRoute';
import { ForgotPassword } from 'containers/Auth/ForgotPassword';
import { ConfirmForgotPassword } from 'containers/Auth/ConfirmForgotPassword';

export const Routes = () => {
  return (
    <Router basepath='/redesigned'>
      <ProtectedRoute default path='*'>
        <Login path='users/sign_in' />
        <NewPassword path='users/new_password' />
        <ForgotPassword path='users/forgot_password' />
        <ConfirmForgotPassword path='users/reset_password' />
        <SetupMFA path='users/setup_mfa' />
        <OTP path='users/otp' />
        <PageWithNavbar default path='*'>
          <NotFound default />
          <Dashboard path='dashboard' />
          <MailLog path='mail-log' />
          <CompanyDetailsPage path='companies/:companyId'>
            <BasicDataComposed default path='basic-data' />
            <Contacts path='contacts' />
            <Visits path='visits' />
            <EventsLog path='event-logs' />
          </CompanyDetailsPage>
          <CreateCompany path='/create-company' />
          <PowersearchPage path='/search/:tag' />
          <Bookmarks path='/saved-search' />
          <OrdinaryVisit path='/new-ordinary-visit/:id'>
            <OrdinaryVisitWorkplace path='/workplace' />
            <Step3 path='/companies' />
          </OrdinaryVisit>
          <RichTextPresentation path='/presentation' />
          <WorkplaceDetailsPage path='/workplaces/:workplaceId'>
            <BasicDataWorkplace default path='/basic-data' />
            <VisitsWorkplace path='visits' />
          </WorkplaceDetailsPage>
          <Reports path='reports/:id' />
        </PageWithNavbar>
      </ProtectedRoute>
    </Router>
  );
};
