import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { connect } from 'react-redux';

import { StyledContainer } from '../Visits/styles';
import { StyledColumn } from './styles';
import { BasicDataStatus } from './BasicDataStatus';
import { BasicDataAddresses } from './BasicDataAddresses';
import { BasicDataContactInfo } from './BasicDataContactInfo';
import { BasicDataNace } from './BasicDataNace';
import { BasicDataRegisteredPayments } from './BasicDataRegisteredPayments';
import { CompanyEntity } from 'ducks/companies/types';
import { StoreType } from 'store';
import { useScreenSizeContext } from 'contexts/ScreenSizeContext';

interface BasicDataProps extends RouteComponentProps {
  clickableContactEmail?: boolean;
  company: CompanyEntity | null;
}

export const BasicData = ({ company, clickableContactEmail }: BasicDataProps) => {
  const { lg } = useScreenSizeContext();
  if (!company) return null;

  return (
    <StyledContainer>
      {lg ? (
        <>
          <StyledColumn>
            <BasicDataStatus company={company} />
            <BasicDataNace company={company} />
            <BasicDataRegisteredPayments company={company} />
          </StyledColumn>
          <StyledColumn>
            <BasicDataContactInfo clickableEmail={clickableContactEmail} company={company} />
            <BasicDataAddresses company={company} />
          </StyledColumn>
        </>
      ) : (
        <StyledColumn>
          <BasicDataStatus company={company} />
          <BasicDataContactInfo clickableEmail={clickableContactEmail} company={company} />
          <BasicDataAddresses company={company} />
          <BasicDataNace company={company} />
          <BasicDataRegisteredPayments company={company} />
        </StyledColumn>
      )}
    </StyledContainer>
  );
};

export const BasicDataComposed = connect((state: StoreType) => ({ company: state.companies }))(
  BasicData,
);
