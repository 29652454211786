import React, { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { CancelTokenSource } from 'axios';

import { activeCheckpointsSelector } from 'ducks/constants/selectors';
import { ContentContainer, Margin } from 'styles/utils';
import {
  Checkpoint,
  createObservation,
  isNewVisitFinished,
  isNewVisitFollowUp,
  isNewVisitWithEditingAccess,
  newVisitObservationSelector,
} from 'ducks/newVisit';
import { Observation } from './Observation/Observation';
import {
  StyledCompanySearchWrapper,
  WorkplaceListItem,
} from 'containers/newVisit/OrdinaryVisit/styles';
import { SearchSelect } from 'components/Forms/SearchSelect/SearchSelect';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useScreenSizeContext } from 'contexts/ScreenSizeContext';
import { useModal } from 'components/Modal/Modal';
import { SearchSelectListItemStandard } from 'components/Forms/SearchSelect/styles';
import { useAppDispatch } from 'store';
import { NewVisitCheckListModal } from 'containers/newVisit/OrdinaryVisit/NewVisitCheckListModals';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { ArrowRightBigIcon } from 'utils/iconsMap';

interface ObligatoryChecklistPropTypes {
  cancelToken: MutableRefObject<CancelTokenSource | null>;
  companyVisitId: string;
  visitId: string;
}

export const AdditionalChecklist = ({
  cancelToken,
  companyVisitId,
  visitId,
}: ObligatoryChecklistPropTypes) => {
  const [searchOptions, setSearchOptions] = useState<JSX.Element[]>([]);
  const { setModalComponent, showModal, setCustomStyles, setShowHeader } = useModal();
  const { sm } = useScreenSizeContext();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const checkpoints = useSelector(activeCheckpointsSelector);
  const isVisitFinished = useSelector(isNewVisitFinished(companyVisitId));
  const isVisitFollowUp = useSelector(isNewVisitFollowUp(companyVisitId));
  const isVisitCanEdit = useSelector(isNewVisitWithEditingAccess(companyVisitId));
  const isDisabled = !isVisitCanEdit || (isVisitCanEdit && (isVisitFinished || isVisitFollowUp));

  const observations = useSelector(newVisitObservationSelector(companyVisitId)) || [];
  const additionalObservations = observations.filter(({ obligatory }) => !obligatory);

  const availableCheckpointsIds: Checkpoint[] = useMemo(() => {
    return Object.values(checkpoints);
  }, [checkpoints, observations]);

  const onSearch = useCallback(
    (text: string) => {
      if (!text) setSearchOptions([]);

      const options = availableCheckpointsIds
        .filter(
          ({ name, description }) =>
            name?.toLowerCase()?.includes(text.toLowerCase()) ||
            description?.toLowerCase()?.includes(text.toLowerCase()),
        )
        .map(({ name, id }) => (
          <SearchSelectListItemStandard
            key={id}
            onClick={() => {
              dispatch(createObservation({ companyVisitId, checkpointId: id.toString() }));
              setSearchOptions([]);
            }}
          >
            <WorkplaceListItem>
              <div>{name}</div>
              <ArrowRightBigIcon />
            </WorkplaceListItem>
          </SearchSelectListItemStandard>
        ));
      setSearchOptions(options);
    },
    [checkpoints, setSearchOptions, availableCheckpointsIds, dispatch],
  );

  const debounceSearch = useCallback(
    debounce((text: string) => onSearch(text), 350),
    [onSearch],
  );

  return (
    <>
      <div data-testid='additional-checklist'>
        {additionalObservations?.map((observation) => (
          <Observation
            key={observation.id}
            {...observation}
            cancelToken={cancelToken}
            visitId={visitId}
          />
        ))}
        <ContentContainer margin='1.2rem 0 0' maxWidth='64rem'>
          <StyledCompanySearchWrapper>
            <SearchSelect
              clearOnSelect
              disabled={isDisabled}
              listItems={searchOptions}
              onSearch={debounceSearch}
              placeholder={t('filters.search')}
              size='small'
              vsize='small'
            />
            <Margin left={sm ? '2.4rem' : '0.8rem'}>
              <Tooltip label={t('new_visits.add_checkpoint_from_list')}>
                <DefaultButton
                  isDisabled={isDisabled}
                  label={t('new_visits.choose_from_the_list')}
                  onClick={() => {
                    setCustomStyles({ maxWidth: '60rem' });
                    setModalComponent(<NewVisitCheckListModal companyVisitId={companyVisitId} />);
                    setShowHeader(false);
                    showModal();
                  }}
                  size='small'
                  testid='additional-checklist-list-button'
                />
              </Tooltip>
            </Margin>
          </StyledCompanySearchWrapper>
        </ContentContainer>
      </div>
    </>
  );
};
