export const theme = {
  colors: {
    orange900: '#342513',
    orange800: '#7e4d10',
    orange700: '#b1680b',
    orange600: '#cd7300',
    orange500: '#e98300',
    orange400: '#ed9c33',
    orange300: '#f2b566',
    orange200: '#f6cd99',
    orange100: '#fbe6cc',
    orange50: '#fdf3e6',
    blue900: '#0a1b53',
    blue800: '#0d297c',
    blue700: '#103298',
    blue600: '#153eba',
    blue500: '#1745cf',
    blue400: '#2e58d4',
    blue300: '#5d7ddd',
    blue200: '#a2b5ec',
    blue100: '#d9e1f7',
    blue75: '#EEEEFE',
    blue50: '#f7f9ff',
    black900: '#0a0f20',
    black800: '#232736',
    black700: '#3b3f4d',
    black600: '#545763',
    black500: '#6c6f79',
    black400: '#858790',
    black300: '#9fa1a8',
    black200: '#bfc0c4',
    black100: '#dfe0e2',
    black50: '#f4f4f5',
    green600: '#17BB48',
    green500: '#18d050',
    green300: '#5DDE85',
    green100: '#BFF2CF',
    green50: '#eefbf3',
    red500: '#CC2506',
    red300: '#DB6650',
    red100: '#F0BEB4',
    red50: '#FCEEEC',
    yellow500: '#F0D018',
    yellow400: '#F2D52F',
    yellow300: '#F5DE5D',
    yellow200: '#F8E88C',
    yellow100: '#FBF1BA',
  },
  boxShadows: {
    coolGrey200: '0 0.1rem 0.2rem 0 rgba(13, 41, 124, 0.07), 0 0 0.1rem 0 rgba(13, 41, 124, 0.06)',
    coolGrey300: '0 0 0.2rem 0 rgba(13,41,124,0.06), 0 0.4rem 0.8rem 0 rgba(13,41,124,0.08)',
    coolGrey400: '0 1.4rem 2.4rem 0 rgba(13, 41, 124, 0.1), 0 0 0.2rem 0 rgba(13, 41, 124, 0.06)',
    grey500: '0 1.2rem 2.4rem 0.4rem rgba(35, 39, 54, 0.1)',
    orange200: '0 0.2rem 0.4rem 0 rgba(205, 115, 0, 0.4)',
    orange300: '0 0.4rem 0.8rem -0.1rem rgba(205, 115, 0, 0.4)',
    blue200: '0 0.2rem 0.4rem 0 rgba(21, 62, 186, 0.35)',
    blue300: '0 0.4rem 0.8rem -0.1rem rgba(21, 62, 186, 0.35)',
    blue500: '0 1.2rem 2.4rem 0rem rgba(21, 62, 186, 0.15)',
    green200: '0 0.2rem 0.4rem 0 rgba(23, 187, 72, 0.35)',
    green300: '0 0.4rem 0.8rem -0.1rem rgba(23, 187, 72, 0.35)',
    lightBlue200: '0 0.2rem 0.4rem 0 rgba(23, 69, 207, 0.08), 0 0 0.1rem 0 rgba(23, 69, 207, 0.07)',
    lightBlue300: '0 0.4rem 0.8rem 0 rgba(23, 69, 207, 0.09), 0 0 0.2rem 0 rgba(23, 69, 207, 0.07)',
    lightBlue500: '0 1.2rem 2.4rem 0 rgba(23, 69, 207, 0.1), 0 0 0.2rem 0 rgba(23, 69, 207, 0.07)',
    red200: '0 0.4rem 0.8rem 0 rgba(204,37,6,0.35)',
    red300: '0 0.4rem 0.8rem -.1rem rgba(204,37,6,0.35)',
  },
  fontSizes: {
    xxs: 'font-size: 1rem; line-height: 1.2rem',
    xs: 'font-size: 1.2rem; line-height: 1.6rem',
    sm: 'font-size: 1.3rem; line-height: 1.6rem',
    base: 'font-size: 1.4rem; line-height: 1.6rem',
    lg: 'font-size: 1.6rem; line-height: 2rem',
    xl: 'font-size: 1.8rem; line-height: 2rem',
    xxl: 'font-size: 2.8rem; line-height: 3.2rem',
  },
  sizes: {
    xxs: '0.4rem',
    xs: '0.8rem',
    hmd: '1.2rem', // half md - sometimes required, and it's looks better than calc(md / 2)
    sm: '1.6rem',
    md: '2.4rem',
    lg: '4.8rem',
    xl: '7.2rem',
  },
  lineHeight: {
    md: '1.6rem',
    lg: '2rem',
    xl: '2.4rem',
  },
  borderRadius: {
    sm: '0.4rem',
    md: '0.8rem',
    full: '100%',
  },
  icon: {
    sm: 'height: 1.6rem; width: 1.6rem',
    md: 'height: 2.0rem; width: 2.0rem',
    lg: 'height: 2.4rem; width: 2.4rem',
    xl: 'height: 3.6rem; width: 3.6rem',
    xxl: 'height: 7.2rem; width: 7.2rem',
  },
  logo: {
    default: 'width: 100%;',
  },
  tableVerticalPadding: '0.8rem',
} as const;

export type ThemeInterface = typeof theme;
