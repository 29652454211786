import styled, { css } from 'styled-components/macro';

import { Badge } from 'components/StatusBadge/styles';
import { StyledBulletLabel } from 'components/BulletLabel/styles';
import { StyledContainer } from 'components/ValueWithLabel/ValueWithLabel';

export const StyledColumn = styled.div`
  flex: 1 1;
  padding: 0 ${({ theme }) => theme.sizes.xs};

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

export const StyledAddressContainer = styled.div<{ justify?: boolean }>`
  align-items: center;
  display: flex;
  margin-bottom: ${({ theme }) => theme.sizes.xs};
  ${({ justify }) =>
    justify &&
    css`
      justify-content: space-between;
    `}

  & > div {
    margin-bottom: 0;
  }
`;

export const StyledAddressTag = styled.div`
  ${({ theme }) => theme.icon.lg};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.orange500};
  color: ${({ theme }) => theme.colors.black900};
  flex-shrink: 0;
  line-height: ${({ theme }) => theme.lineHeight.lg};
  margin-right: ${({ theme }) => theme.sizes.sm};
  text-align: center;
`;

export const StyledLink = styled.a<{ isDisabled?: boolean }>`
  &,
  &:hover,
  &:visited {
    color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.colors.black200 : theme.colors.blue500};
    cursor: pointer;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 600px) {
      white-space: wrap;
    }
    ${({ isDisabled }) =>
      isDisabled &&
      css`
        pointer-events: none;
        @media (max-width: 600px) {
          white-space: wrap;
        }
      `}
  }
  @media (max-width: 600px) {
    white-space: normal;
  }
`;

export const StyledAddressForm = styled.div`
  display: flex;
  margin: ${({ theme }) => `${theme.sizes.sm} 0`};
  width: 100%;
`;

export const StyledFieldWrapper = styled.div`
  margin-left: ${({ theme }) => theme.sizes.sm};

  &:first-child {
    flex-grow: 1;
    margin-left: 0;
  }
`;

export const StyledForm = styled.form`
  margin: 0;
  max-width: 44rem;
  width: 100%;
`;

export const StyledRiskLevel = styled.div`
  margin: ${({ theme }) => `${theme.sizes.xs} 0 ${theme.sizes.sm}`};

  & ${Badge} {
    ${({ theme }) => theme.icon.lg};
    margin-right: ${({ theme }) => theme.sizes.xs};
    border-radius: ${({ theme }) => theme.borderRadius.full};
  }
`;

export const StyledBranches = styled.div`
  margin-bottom: ${({ theme }) => theme.sizes.xs};
`;

export const StyledAddressWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  min-width: 0;

  & > :first-child {
    margin-right: ${({ theme }) => theme.sizes.hmd};
  }

  & ${StyledContainer} {
    color: ${({ theme }) => theme.colors.black200};
    margin: 0;
    min-width: 0;
  }
`;

export const StyledButtonsWrapper = styled.div`
  align-items: center;
  display: flex;

  & > :first-child {
    margin-right: ${({ theme }) => theme.sizes.hmd};
  }
`;

export const StyledBranchStatus = styled.div`
  align-items: center;
  display: flex;
  margin: ${({ theme }) => theme.sizes.xs} 0;
  position: relative;
  width: 100%;

  & ${StyledBulletLabel} {
    left: 16rem;
    position: absolute;
  }
`;

export const StyledValuesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > * {
    flex: 1 0 33%;
  }
`;

export const StyledAdditionalStatuses = styled.div`
  margin-bottom: ${({ theme }) => theme.sizes.xs};

  & > ${Badge} {
    margin: ${({ theme }) => `0 ${theme.sizes.xs} ${theme.sizes.xs} 0`};
  }
`;

export const StyledWarningMessage = styled.div<{ variant: 'danger' | 'warning' }>`
  align-items: center;
  display: flex;
  margin-bottom: ${({ theme }) => theme.sizes.hmd};

  & > span {
    margin-left: ${({ theme }) => theme.sizes.sm};
  }

  & > svg {
    ${({ theme }) => theme.icon.md};
    color: ${({ variant, theme }) =>
      variant === 'danger' ? theme.colors.red500 : theme.colors.orange500};
    line-height: ${({ theme }) => theme.lineHeight.lg};
  }
`;

export const StyledRiskWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
`;

export const StyledInfraction = styled.div<{
  onClick?: () => void;
  status?: 'OK' | 'warning' | 'violation';
  total?: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: ${({ total }) => {
    if (total && total > 0) {
      return 'pointer';
    }
    return 'default';
  }};
  color: ${({ status, theme }) => {
    switch (status) {
      case 'OK':
        return theme.colors.black400;
      case 'warning':
        return theme.colors.orange500;
      case 'violation':
        return theme.colors.red500;
      default:
        return theme.colors.black400;
    }
  }};
  &:hover {
    color: ${({ theme }) => theme.colors.black900};
  }
`;

export const StyledInfractionWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.sizes.xxs};
`;
