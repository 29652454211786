import React, { createContext, useContext, useState } from 'react';
import ReactModal from 'react-modal';

import { checkIsIpad } from 'utils/deviceChecker';
import { modalStylesOverrides, StyledCloseButton, StyledCloseButtonWrapper } from './styles';
import { LoadingContextProvider } from 'contexts/LoadingContext';

import { ReactComponent as CloseBigIcon } from 'images/closeBigIcon.svg';

const ModalContext = createContext();

export const ModalProvider = (props) => {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [modalComponent, setModalComponent] = useState(null);
  const [showHeader, setShowHeader] = useState(true);
  const [customStyles, setCustomStyles] = useState(null);

  const showModal = () => setIsOpen(true);

  const hideModal = () => {
    setCustomStyles(null);
    setIsOpen(false);
    setModalComponent(null);
  };

  const isIpad = checkIsIpad();

  return (
    <ModalContext.Provider
      value={{ showModal, hideModal, setModalComponent, setCustomStyles, setShowHeader, isOpen }}
      {...props}
    >
      {children}
      {modalComponent && (
        <ReactModal
          ariaHideApp={false}
          isOpen={isOpen}
          onRequestClose={hideModal}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          style={{
            ...modalStylesOverrides,
            content: {
              ...modalStylesOverrides.content,
              maxHeight: isIpad ? '-webkit-fill-available' : modalStylesOverrides.content.maxHeight,
              ...customStyles,
            },
          }}
        >
          {showHeader && (
            <StyledCloseButtonWrapper>
              <StyledCloseButton data-testid='close-big-icon' onClick={hideModal} type='button'>
                <CloseBigIcon />
              </StyledCloseButton>
            </StyledCloseButtonWrapper>
          )}
          <LoadingContextProvider>{modalComponent}</LoadingContextProvider>
        </ReactModal>
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used with ModalProvider');
  }
  return context;
};

export * from './styles';
