import { StyledDropdownWrapper } from '../../../../components/PopperDropdown/styles';
import styled from 'styled-components/macro';

import { ReactComponent as ArrowDownIcon } from 'images/arrowDownIcon.svg';

export const StyledChecklistItem = styled.div<{ isOpen: boolean }>`
  background-color: ${({ isOpen }) => (isOpen ? '#FAFAFA' : 'white')};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.black100}`};
  padding: ${({ theme }) => theme.sizes.xs} 0;
  max-width: 110rem;
`;

const omitFlipProp = (prop: number | string) => prop !== 'flip';
export const StyledChecklistItemExpandIcon = styled(ArrowDownIcon).withConfig({
  shouldForwardProp: omitFlipProp,
})<{
  isOpen: boolean;
}>`
  cursor: pointer;
  height: 3rem;
  left: ${({ theme }) => theme.sizes.sm};
  position: absolute;
  top: 1.4rem;
  transform: ${({ isOpen }) => (isOpen ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)')};
  width: 3rem;

  path {
    fill: ${({ isOpen }) => (isOpen ? '#1E4AC7' : '#5E6871')};
    transition: fill 0.3s;
  }
`;

export const StyledChecklistItemTopSection = styled.div<{ isOpen: boolean }>`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${({ theme }) => `0 ${theme.sizes.sm} 0 ${theme.sizes.lg}`};
  position: relative;
  width: 100%;
`;

export const StyledChecklistItemContent = styled.div`
  padding: ${({ theme }) => `${theme.sizes.md} ${theme.sizes.sm}`};
`;

export const StyledChecklistItemCategory = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  margin: ${({ theme }) => theme.sizes.xxs} 0;
  overflow: hidden;
`;

export const StyledObservationCheckpointLabel = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.blue500};
  display: flex;
  fill: ${({ theme }) => theme.colors.blue500};
  ${({ theme }) => theme.fontSizes.lg};
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.sizes.xxs};
  margin-right: ${({ theme }) => theme.sizes.xs};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const StyledObservationFields = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;

  ${StyledDropdownWrapper} {
    margin: 0;
  }

  & > *:not(:last-child) {
    margin-left: ${({ theme }) => theme.sizes.xs};
    margin-bottom: 0;
  }
`;

export const StyledNewDeadlineRow = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 100%;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.sizes.hmd};

  ${StyledDropdownWrapper} {
    margin: 0;
  }

  & > * {
    margin-left: ${({ theme }) => theme.sizes.xs};
    margin-bottom: ${({ theme }) => theme.sizes.xs};
  }

  & > label {
    color: ${({ theme }) => theme.colors.black700};
    font-weight: 600;
  }
`;

export const StyledObservationActions = styled.div`
  display: flex;
  margin-bottom: 0;
  margin-left: auto;

  & > * {
    margin-left: ${({ theme }) => theme.sizes.xs};
  }
`;

export const StyledTextAndButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${({ theme }) => theme.sizes.hmd};
  width: 100%;

  & > :first-child {
    flex-grow: 1;
    margin-right: ${({ theme }) => theme.sizes.sm};
  }
`;

export const StyledAttachmentAndCommentButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :first-child {
    margin-bottom: ${({ theme }) => theme.sizes.xs};
  }
`;
