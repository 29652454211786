import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  StyledFilesHeader,
  StyledThumbnail,
  StyledThumbnailCross,
  StyledThumbnailsContainer,
  StyledThumbnailTooltipWithCross,
} from 'components/Forms/Attachments/styles';
import { VideoThumbnail } from 'components/Forms/Attachments/VideoThumbnail';
import { ReactComponent as CloseIcon } from 'images/closeIcon.svg';

export interface ThumbFile {
  id?: number | string;
  name: string;
  preview: string;
  size: number;
  type: string;
}

interface ThumbsProps {
  deleteFile(file: ThumbFile): void;
  deleteTooltip?: string;
  files: ThumbFile[];
  isDisabled?: boolean;
}

export const Thumbs = ({
  files,
  deleteFile,
  isDisabled,
  deleteTooltip,
}: ThumbsProps): JSX.Element | null => {
  const { t } = useTranslation();
  const isDeleteTooltip = deleteTooltip && deleteTooltip?.length > 0;

  const thumbs = files
    .map((file) => {
      if (file.type.includes('image') && !isDisabled) {
        const crossButton = isDeleteTooltip ? (
          <StyledThumbnailTooltipWithCross label={deleteTooltip || ''}>
            <CloseIcon onClick={() => deleteFile(file)} />{' '}
          </StyledThumbnailTooltipWithCross>
        ) : (
          <StyledThumbnailCross onClick={() => deleteFile(file)} />
        );
        return (
          <StyledThumbnail key={file.name} style={{ backgroundImage: `url(${file.preview})` }}>
            {crossButton}
          </StyledThumbnail>
        );
      } else if (file.type.includes('video') && !isDisabled) {
        return <VideoThumbnail deleteHandler={deleteFile} file={file} key={file.name} />;
      } else return null;
    })
    .filter(Boolean);

  if (thumbs.length === 0) return null;
  return (
    <>
      <StyledFilesHeader>{t('forms.images')}</StyledFilesHeader>
      <StyledThumbnailsContainer>{thumbs}</StyledThumbnailsContainer>
    </>
  );
};
