import FileSaver from 'file-saver';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { API } from 'api';
import { DashboardVisitsResponse, GetDashboardVisits } from 'ducks/dashboard/types';
import { AxiosError } from 'axios';

export const getDashboardVisits = createAsyncThunk(
  'dashboard/visits',
  async (params: GetDashboardVisits, { rejectWithValue }) => {
    try {
      const response = await API.get(`dashboard/visits`, { params });
      return response.data as DashboardVisitsResponse;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const setDashboardVisitColumnsConfig = createAsyncThunk(
  'dashboard/setDashboardVisitColumnsConfig',
  async ({ order, visibility }: { order?: string[]; visibility?: string[] }) => {
    return { order, visibility };
  },
);

export const deleteDashboardVisit = createAsyncThunk(
  'dashboard/deleteDashboardVisit',
  async (id: string, { rejectWithValue }) => {
    try {
      await API.delete(`company_visits/${id}`);
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const getDashboardVisitsCSV = async (company_id: string, ids: string[]) => {
  const response = await API.post(
    `dashboard/visits`,
    {
      id: ids.join(','),
    },
    {
      params: {
        page: 1,
        page_size: ids.length,
      },

      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
      },
    },
  );

  const blob = new Blob([response.request.response], {
    type: 'application/vnd.ms-excel',
  });

  FileSaver.saveAs(blob, 'visits-export.xls');
  return response.data as DashboardVisitsResponse;
};
