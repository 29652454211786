import React, { ComponentType } from 'react';
import { FieldRenderProps } from 'react-final-form';

import {
  AttachmentsToRemove,
  AttachmentsToRemovePropTypes,
} from 'components/Forms/AttachmentsToRemove/AttachmentsToRemove';

export const AttachmentsToRemoveField: ComponentType<
  AttachmentsToRemovePropTypes & FieldRenderProps<number[]>
> = ({ input, attachmentList }) => {
  return <AttachmentsToRemove attachmentList={attachmentList} {...input} />;
};
