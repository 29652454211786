import * as React from 'react';
import queryString from 'query-string';
import { BASE_URL } from 'api/apiUrl';

import { StyledAttachmentBadge } from './styles';
import { FileIcon, LandscapeIcon, PlayIcon, SpeakerIcon } from 'utils/iconsMap';

const iconVariants = Object.freeze({
  application: FileIcon,
  audio: SpeakerIcon,
  image: LandscapeIcon,
  text: FileIcon,
  video: PlayIcon,
});

export interface AttachmentBadgePropTypes {
  action?: 'download' | 'show';
  className: string;
  contentType: string;
  fileName: string;
  id: number;
}

export const AttachmentBadge: React.FC<AttachmentBadgePropTypes> = ({
  action = 'download',
  className,
  contentType,
  fileName,
  id,
}) => {
  const [attachmentType] = contentType.split('/') as (keyof typeof iconVariants)[];
  const Icon = iconVariants[attachmentType] || FileIcon;

  const urlParams = queryString.stringify({
    id,
    class_name: className,
    content_disposition: action === 'download' ? 'attachment' : 'inline',
  });

  const href = `${BASE_URL}/api/serve_attachments?${urlParams}`;

  return (
    <StyledAttachmentBadge href={href} target='_blank'>
      <Icon />
      <span>{fileName}</span>
    </StyledAttachmentBadge>
  );
};
