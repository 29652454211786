import styled, { css } from 'styled-components';

export const StyledInput = styled.input`
  border: none;
  line-height: ${({ theme }) => theme.lineHeight.lg};
  padding: ${({ theme }) => theme.sizes.xxs};
  width: 100%;
`;

export const StyledContainer = styled.div<{ isDisabled?: boolean; hasError?: boolean }>`
  align-items: center;
  background-color: white;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: 1px solid ${({ theme }) => theme.colors.black100};
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;

  &:focus-within,
  &:active {
    border-color: ${({ theme, isDisabled }) => !isDisabled && theme.colors.black300};
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: ${({ theme }) => theme.colors.black50};

      & svg {
        cursor: default !important;
      }
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${({ theme }) => theme.colors.red500};
    `}
`;

export const StyledIconsContainer = styled.div`
  display: flex;
  flex: 0 0 6.4rem;
  justify-content: flex-end;

  & > svg {
    color: ${({ theme }) => theme.colors.black500};
    cursor: pointer;
    flex: 0 0 3.2rem;
    height: 16px;
    padding: ${({ theme }) => `0 ${theme.sizes.xs}`};
    width: 16px;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;

  & > div {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    margin: ${({ theme }) => theme.sizes.xxs};
  }
`;

export const StyledSelectedItem = styled.span`
  ${({ theme }) => theme.fontSizes.base};
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: 1px solid ${({ theme }) => theme.colors.black300};
  display: flex;
  margin: ${({ theme }) => theme.sizes.xxs};
  padding: ${({ theme }) => `${theme.sizes.xxs}`};

  & > svg {
    color: ${({ theme }) => theme.colors.black500};
    cursor: pointer;
    height: 16px;
    margin-left: ${({ theme }) => `${theme.sizes.xs}`};
    width: 16px;
  }
`;
