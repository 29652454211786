import { API } from 'api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CompanyEntity, CompanyStatusResponse, UpdateCompanyParams } from './types';
import { sortCompanyAddresses } from 'utils/helpers';
import { AxiosError } from 'axios';

export const getCompany = createAsyncThunk('companies/getCompany', async (companyId: string) => {
  const response = await API.get(`companies/${companyId}`);
  return response.data as CompanyEntity;
});

export const fetchCompany = async (companyId: string) => {
  const response = await API.get(`companies/${companyId}`);
  return sortCompanyAddresses(response.data as CompanyEntity);
};

export const getCompanyStatus = async (orgNumber: string) => {
  try {
    const { data } = await API.get(`company_statuses/${orgNumber}`);
    const result = { ...data };
    return result as CompanyStatusResponse;
  } catch (e) {
    throw (e as AxiosError).message;
  }
};

export const createCompany = async (values: object) => {
  const result = await API.post(`companies`, values);
  return result.data as CompanyEntity;
};

export const updateCompany = createAsyncThunk(
  'companies/updateCompany',
  async (params: UpdateCompanyParams) => {
    const { companyId, ...attributes } = params;
    const response = await API.patch(`companies/${companyId}`, { ...attributes });
    return response.data as CompanyEntity;
  },
);
