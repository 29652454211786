import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { CancelTokenSource } from 'axios';
import { isEmpty, isEqual } from 'lodash';

import { ChecklistsSection } from 'containers/newVisit/OrdinaryVisit/Step3/ChecklistsSection';
import { CompanyContactsSection } from 'containers/newVisit/OrdinaryVisit/Step3/CompanyContactsSection';
import { CompanyDetailsSection } from 'containers/newVisit/OrdinaryVisit/Step3/CompanyDetailsSection';
import { NotesSection } from 'containers/newVisit/OrdinaryVisit/Step3/NotesSection';
import {
  CompanyVisit,
  isNewVisitWithDeletingAccess,
  isNewVisitWithEditingAccess,
  newVisitCompanyVisitSelector,
  newVisitCompanyVisitsSelector,
  setActiveCompanyId,
  visitIdSelector,
  visitSelector,
} from 'ducks/newVisit';
import { CompanySearchSelect } from 'containers/newVisit/shared/CompanySearchSelect';
import { CompanyVisitsTabs } from 'containers/newVisit/OrdinaryVisit/Step3/CompanyVisitsTabs';
import { ButtonsPanel } from 'containers/newVisit/shared/ButtonsPanel/ButtonsPanel';
import { RecipientsModal } from 'containers/newVisit/shared/RecipientsModal';
import { ReportsSection } from 'containers/newVisit/OrdinaryVisit/Step3/ReportsSection';
import { useModal } from 'components/Modal/Modal';
import { useDidUpdate } from 'hooks/useDidUpdate';
import { useAppDispatch } from 'store';
import { StatusSection } from 'containers/newVisit/OrdinaryVisit/Step3/StatusSection';
import { StyledWrappingContainer } from '../styles';

export const Step3: FC<RouteComponentProps> = () => {
  const visit = useSelector(visitSelector);
  const companyVisits = useSelector(newVisitCompanyVisitsSelector);
  const visitId = useSelector(visitIdSelector);
  const cancelToken = useRef<CancelTokenSource | null>(null);
  const updateCompanyCancelToken = useRef<CancelTokenSource | null>(null);

  const preselectedCompanyVisitId = document.location.search?.replace('?company_visit_id=', '');
  const preselectedCompanyVisit = useMemo(
    () =>
      preselectedCompanyVisitId
        ? companyVisits.find(({ id }) => id === preselectedCompanyVisitId)
        : companyVisits[0],
    [preselectedCompanyVisitId],
  );
  const [selectedCompanyVisit, setSelectedCompanyVisit] = useState<CompanyVisit | 'new'>(
    preselectedCompanyVisit || 'new',
  );

  const selectedCompany = useSelector(
    newVisitCompanyVisitSelector((selectedCompanyVisit as CompanyVisit)?.id),
  );

  useEffect(() => {
    if (
      !isEmpty(selectedCompany) &&
      selectedCompanyVisit !== 'new' &&
      !isEqual(selectedCompanyVisit, selectedCompany)
    ) {
      setSelectedCompanyVisit(selectedCompany as CompanyVisit);
    }
  }, [JSON.stringify(selectedCompany)]);

  const isVisitCanEdit = useSelector(
    isNewVisitWithEditingAccess(
      (selectedCompanyVisit as CompanyVisit)?.id || preselectedCompanyVisit?.id,
    ),
  );
  const isVisitCanDelete = useSelector(
    isNewVisitWithDeletingAccess(
      (selectedCompanyVisit as CompanyVisit)?.id || preselectedCompanyVisit?.id,
    ),
  );

  const { t } = useTranslation();
  const { setModalComponent, showModal, setCustomStyles, setShowHeader } = useModal();
  const dispatch = useAppDispatch();

  useDidUpdate(() => {
    const lastCompanyVisit = companyVisits[companyVisits.length - 1];
    setSelectedCompanyVisit(lastCompanyVisit ? lastCompanyVisit : 'new');
  }, [companyVisits.length]);

  useEffect(() => {
    dispatch(setActiveCompanyId(selectedCompanyVisit === 'new' ? null : selectedCompanyVisit.id));
  }, [selectedCompanyVisit]);

  useEffect(() => {
    if (preselectedCompanyVisit) {
      setSelectedCompanyVisit(preselectedCompanyVisit);
    }
    window.scrollTo(0, 0);
  }, [JSON.stringify(preselectedCompanyVisit)]);

  useEffect(() => {
    if (document?.getElementById('new-visit-card')) {
      (document.getElementById('new-visit-card') as HTMLDivElement).scrollTop = 0;
    }
  }, []);

  if (
    selectedCompanyVisit !== 'new' &&
    companyVisits.every(({ id }) => id !== selectedCompanyVisit.id)
  )
    return null;

  return (
    <StyledWrappingContainer limitHeight={true}>
      <CompanyVisitsTabs
        companyVisits={companyVisits}
        handleClick={setSelectedCompanyVisit}
        selectedCompanyVisit={selectedCompanyVisit}
      />
      {selectedCompanyVisit === 'new' ? (
        <CompanySearchSelect />
      ) : (
        <>
          <StatusSection companyVisitId={selectedCompanyVisit.id} />
          <CompanyDetailsSection
            companyVisitId={selectedCompanyVisit.id}
            selectedAddress={visit.visitationAddress}
          />
          <ReportsSection
            companyVisitId={selectedCompanyVisit.id}
            companyVisitReports={selectedCompanyVisit.companyVisitReports}
          />
          <CompanyContactsSection companyVisitId={selectedCompanyVisit.id} />
          <ChecklistsSection
            cancelToken={cancelToken}
            companyVisitId={selectedCompanyVisit.id}
            visitId={String(visitId)}
          />
          <NotesSection
            cancelToken={updateCompanyCancelToken}
            companyVisitId={selectedCompanyVisit.id}
          />
        </>
      )}
      <ButtonsPanel
        companyVisitId={selectedCompanyVisit === 'new' ? undefined : selectedCompanyVisit.id}
        isButtonsActive={isVisitCanEdit || isVisitCanDelete}
        mainButtonHandler={() => {
          setCustomStyles({ maxWidth: '64rem' });
          setModalComponent(
            <RecipientsModal
              companyVisitId={(selectedCompanyVisit as CompanyVisit).id}
              workplaceContacts={visit.contacts}
            />,
          );
          setShowHeader(false);
          showModal();
        }}
        mainButtonLabel={t('common.send')}
        visitId={visit.id}
      />
    </StyledWrappingContainer>
  );
};
