import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { TableGrid } from 'components/Table/TableGrid/TableGrid';
import { CardMax } from 'components/Card/Card';
import { PaginationComposed } from 'components/Pagination/Pagination';
import { TableFilters } from 'components/TableFilters/TableFilters';
import { CommonActionsPanel } from 'components/CommonActionsPanel/CommonActionsPanel';
import {
  StyledColumn,
  StyledContainer,
  StyledEmptyVisits,
  StyledEmptyVisitsWrap,
  StyledTableBottomPanel,
} from 'containers/companyDetails/Visits/styles';
import { StoreType } from 'store';
import { ButtonWrapper } from 'components/ButtonWrapper/ButtonWrapper';
import { useLoadingContext } from 'contexts/LoadingContext';
import { visitsComponentsMap } from './tableComponentsMap';
import {
  TableProvidersProps,
  useTableContexts,
  withTableProviders,
} from 'hocs/composedTableProviders/composedTableProviders';
import { getDashboardVisits, getDashboardVisitsCSV } from 'ducks/dashboard/actions';
import { DashboardVisitEntity } from 'ducks/dashboard/types';
import { API } from 'api';
import { useModal } from 'components/Modal/Modal';
import { ChooseDateDashboardVisit } from 'dialogs/Dashboard/ChooseDateDashboardVisit';
import { DeleteDashboardVisit } from 'dialogs/Dashboard/DeleteDashboardVisit';
import { checkIsIpad } from '../../utils/deviceChecker';
import { toast } from 'react-hot-toast';
import { clearCompanyVisitError, resetCompanyVisitReducer } from 'ducks/dashboard/reducer';

interface DashboardVisitsProps extends TableProvidersProps {
  userId: string;
}

const DashboardVisits: FC<DashboardVisitsProps> = ({ fetchParams, userId }) => {
  const visits = useSelector((state: StoreType) => state.dashboardVisits);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setLoaded, setLoading } = useLoadingContext();
  const { setModalComponent, setShowHeader, showModal } = useModal();
  const { setContextName, itemsSelected, filterParams, addMultipleSelection } = useTableContexts();

  useEffect(() => {
    setContextName('visits');
    return () => {
      setContextName(null);
      dispatch(resetCompanyVisitReducer());
    };
  }, []);

  // --- table row actions handlers ---

  const selectAllHandler = async () => {
    setLoading('dashboardVisits');
    const params = { ...filterParams, ...fetchParams, page_size: 10000, page: 1 };
    const response = await API.get(`dashboard/visits`, { params });

    addMultipleSelection(
      response.data.data.tableBody.map((entity: DashboardVisitEntity) => ({
        entity,
        id: entity.id,
      })),
    );
    setLoaded('dashboardVisits');
  };

  const exportCsvHandler = async () => {
    setLoading('dashboardVisits');
    await getDashboardVisitsCSV(
      userId,
      itemsSelected
        .map((item: DashboardVisitEntity) => item.id || item.companyVisitId)
        .filter(Boolean),
    );
    setLoaded('dashboardVisits');
  };

  const showAndEditHandler = ({ date }: DashboardVisitEntity) =>
    date.url ? navigate(date.url) : undefined;

  const deleteHandler = (entity: DashboardVisitEntity) => {
    setModalComponent(<DeleteDashboardVisit entity={entity} />);
    setShowHeader(false);
    showModal();
  };

  const actionHandlers = {
    show: showAndEditHandler,
    edit: showAndEditHandler,
    duplicate: async (entity: DashboardVisitEntity) => {
      if (entity.visitId) {
        setModalComponent(<ChooseDateDashboardVisit visitId={entity.visitId} />);
        setShowHeader(false);
        showModal();
      }
    },
    delete: deleteHandler,
    exportCSV: exportCsvHandler,
    selectAll: selectAllHandler,
  };

  if (visits.error) {
    toast.error(visits.error);
    dispatch(clearCompanyVisitError());
  }

  if (isEmpty(visits.meta.columnsOrder)) return null;

  if (isEmpty(visits.data.tableBody) && isEmpty(filterParams)) {
    return (
      <CardMax>
        <StyledEmptyVisitsWrap>
          <StyledEmptyVisits>
            <div>{t('company_details.visits.no_visits')}</div>
          </StyledEmptyVisits>
        </StyledEmptyVisitsWrap>
      </CardMax>
    );
  }

  const isIpad = checkIsIpad();

  return (
    // @ts-ignore
    <StyledContainer isIpad={isIpad}>
      <CardMax>
        <StyledColumn>
          <TableFilters isDashboard />
          <TableGrid
            actionHandlers={actionHandlers}
            allFilteredOutMessage={t('company_details.visits.all_filtered_out')}
            bodyData={visits.data.tableBody}
            columnsOrder={visits.meta.columnsOrder}
            componentsMap={visitsComponentsMap}
            isDashboard
            loadResource='dashboardVisits'
            resultsSelector='company_details.visits'
            totalCount={visits.meta.totalCount}
            withCheckbox
            withFullWidth
          />
          <StyledTableBottomPanel>
            <PaginationComposed loadResource='dashboardVisits' />
            <ButtonWrapper align='right'>
              <CommonActionsPanel actionHandlers={actionHandlers} />
            </ButtonWrapper>
          </StyledTableBottomPanel>
        </StyledColumn>
      </CardMax>
    </StyledContainer>
  );
};

const DashboardVisitsComposed = withTableProviders(DashboardVisits, {
  fetchFnExtraParams: (props) => ({ rvo_id: props.userId }),
  fetchFunction: getDashboardVisits,
  loadResource: 'dashboardVisits',
  metaUrl: 'dashboard/visits',
});

export { DashboardVisitsComposed as DashboardVisits };
