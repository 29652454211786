import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { useOnClickOutside } from 'utils/hooks';
import { StyledActionDrawer, StyledDrawerButton, StyledDrawerOptions } from './styles';
import { iconsMap, MeatballIcon } from 'utils/iconsMap';

export const ActionDrawer = ({ actions, disabled }) => {
  const { t } = useTranslation();
  const ref = useRef();
  const [isActive, setActive] = useState(false);
  useOnClickOutside(ref, () => setActive(false));

  if (!actions || actions?.length === 0) return null;

  const handleClick = (handler) => {
    if (isActive) setActive(false);
    handler();
  };

  if (actions.length === 1) {
    const [{ type, handler }] = actions;

    return (
      <Tooltip key={type} label={t(`common.${type}`)}>
        <StyledDrawerButton
          icon={iconsMap[type]}
          isDisabled={disabled}
          onClick={() => handleClick(handler)}
          size='small'
          testid={type}
          variant='tertiary'
        />
      </Tooltip>
    );
  }

  return (
    <StyledActionDrawer onMouseLeave={() => setActive(false)} ref={ref}>
      <StyledDrawerButton
        icon={<MeatballIcon />}
        isActive={isActive}
        isDisabled={disabled}
        onClick={() => setActive(!isActive)}
        onMouseEnter={() => setActive(true)}
        size='small'
        testid='table-row-action-drawer'
        variant='tertiary'
      />

      <StyledDrawerOptions
        data-testid={
          isActive
            ? 'table-row-action-drawer-options-expanded'
            : 'table-row-action-drawer-options-collapsed'
        }
        isVisible={isActive}
      >
        {isActive &&
          actions.map(
            ({ handler, type, label }) =>
              handler && (
                <Tooltip key={type} label={label || t(`common.${type}`)}>
                  <DefaultButton
                    icon={iconsMap[type]}
                    isDisabled={disabled}
                    onClick={() => handleClick(handler)}
                    size='small'
                    testid={type}
                    variant='secondary'
                  />
                </Tooltip>
              ),
          )}
      </StyledDrawerOptions>
    </StyledActionDrawer>
  );
};
