import React, { FC } from 'react';

import { RouteComponentProps } from '@reach/router';
import { CardWithTabs } from 'components/Card/Card';
import { CrmEvents } from './CrmEvents';
import { RvoEvents } from './RvoEvents';

interface EventsLogProps extends RouteComponentProps {
  companyId?: string;
}

export const EventsLog: FC<EventsLogProps> = ({ companyId }) => {
  return (
    <CardWithTabs
      tabs={[
        { name: 'RVO', content: <RvoEvents companyId={companyId} /> },
        { name: 'ITX CRM', content: <CrmEvents companyId={companyId} /> },
      ]}
    />
  );
};
