import { combineReducers, createReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';

import { companySearchReducer } from './company/reducer';
import { companyVisitsSearchReducer } from './companyVisits/reducer';
import { contactsSearchReducer } from './contacts/reducer';
import { District } from './types';
import { FetchingStatus } from '../types';
import { getCurrentUser } from 'ducks/users/actions';
import { getDistricts, setCategory, setQuery } from './actions';
import { priorNotificationsSearchReducer } from './priorNotifications/reducer';
import { revisedActivitiesSearchReducer } from './revisedActivities/reducer';
import { workplacesSearchReducer } from './workplace/reducer';

const queryFromUrl = queryString.parse(window.location.search).q?.toString() || '';

const query = createReducer(queryFromUrl, {
  [setQuery.type]: (state, { payload }) => payload,
});

const districtSlice = createSlice({
  name: 'search',
  initialState: [] as string[],
  reducers: {
    setDistrict: (state, action: PayloadAction<string[]>) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.fulfilled, (state, { payload }) => {
      if (!payload) return [];
      return [payload.districtName].flat();
    });
  },
});

const allDistrictsSlice = createSlice({
  name: 'allDistrict',
  initialState: {
    data: [] as District[],
    status: 'idle' as FetchingStatus,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDistricts.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getDistricts.rejected, (state) => {
      state.status = 'rejected';
    });
    builder.addCase(getDistricts.fulfilled, (state, { payload }) => {
      state.data = payload || [];
      state.status = 'resolved';
    });
  },
});

const category = createReducer(null, {
  [setCategory.type]: (state, { payload }) => payload,
});

const showOnlyMineSlice = createSlice({
  name: 'showOnlyMine',
  initialState: true,
  reducers: {
    setOnlyMine: (state, action: PayloadAction<boolean>) => action.payload,
  },
});

export const { setDistrict } = districtSlice.actions;
export const { setOnlyMine } = showOnlyMineSlice.actions;

export const powerSearchReducer = combineReducers({
  category,
  company: companySearchReducer,
  contacts: contactsSearchReducer,
  companyVisits: companyVisitsSearchReducer,
  priorNotifications: priorNotificationsSearchReducer,
  revisedActivities: revisedActivitiesSearchReducer,
  query,
  district: districtSlice.reducer,
  allDistricts: allDistrictsSlice.reducer,
  showOnlyMine: showOnlyMineSlice.reducer,
  workplace: workplacesSearchReducer,
});
