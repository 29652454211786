import * as yup from 'yup';

import i18n from 'i18n';

const t = (x: string) => i18n.t(x);

export const planAVisitSchema = yup.object().shape({
  addToCalendra: yup.boolean(),
  comment: yup.string(),
  date: yup.mixed().test('date test', t('forms.required'), (val) => !!val),
  title: yup.string().required(),
});
