import styled, { css } from 'styled-components/macro';

import { ReactComponent as ArrowDownIcon } from 'images/arrowDownIcon.svg';

export const StyledGroupListPickerWrapper = styled.div`
  width: Calc(100% + 8rem);
  margin-left: -4rem;
  max-height: 50vh;
  overflow: scroll;
`;
export const StyledGroupListPickerSearch = styled.div`
  padding: 0 4rem;
`;

export const StyledGroupListItemWrapper = styled.div`
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme.colors.blue50};
`;
export const StyledGroupListItem = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: ${({ theme }) => theme.sizes.xs} 1rem;
  margin: 0 4rem;
  user-select: none;
  border-radius: 0.4rem;
  :hover {
    background-color: ${({ theme }) => theme.colors.blue75};
  }
`;

export const StyledGroupListNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ theme }) => theme.sizes.sm};
`;

export const StyledGroupListCounter = styled.div`
  ${({ theme }) => theme.fontSizes.xxs};
  color: ${({ theme }) => theme.colors.blue500};
`;

export const StyledGroupListName = styled.div<{ hasSelections?: boolean }>`
  font-weight: bold;
  white-space: nowrap;
  ${({ hasSelections, theme }) =>
    hasSelections &&
    css`
      color: ${theme.colors.blue500};
    `};
`;

export const StyledExpandIcon = styled(ArrowDownIcon)<{ flip: boolean }>`
  height: 3rem;
  transform: ${({ flip }) => flip && 'rotate(180deg)'};
  width: 3rem;

  path {
    fill: ${({ theme }) => theme.colors.black100};
    transition: fill 0.3s;
  }
`;
