import React, { FC } from 'react';
import ReactQuill from 'react-quill';

import { StyledLabel } from 'components/Forms/styles';

import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link'],
  ],
  clipboard: { matchVisual: false },
};

export interface RichTextPropTypes {
  defaultValue?: string;
  isDisabled?: boolean;
  label?: string;
  onBlur?: (val?: string) => void;
  onChange: (value: string) => void;
  placeholder?: string;
  value: string;
}

export const RichText: FC<RichTextPropTypes> = ({
  defaultValue,
  isDisabled,
  label,
  onBlur,
  onChange,
  placeholder,
  value = '',
}) => (
  <>
    {label && <StyledLabel as='label'>{label}</StyledLabel>}{' '}
    <ReactQuill
      defaultValue={defaultValue}
      modules={modules}
      onBlur={() => onBlur && onBlur()}
      onChange={onChange}
      placeholder={placeholder}
      readOnly={isDisabled}
      value={value}
    />
  </>
);
