import styled from 'styled-components/macro';

export const StyledProfileFormRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
    & > :not(:first-child) {
      margin-left: 1.6rem;
    }
  }
`;
export const StyledProfileFormSingleRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
    & > :first-child {
      width: 72.5%;
    }
  }
`;

export const StyledProfileKindRow = styled(StyledProfileFormRow)`
  @media (min-width: 768px) {
    & > * {
      flex: 1 1 30rem;
    }
  }
`;

export const StyledProfileStreetRow = styled(StyledProfileFormRow)`
  @media (min-width: 768px) {
    & > :first-child {
      flex: 4 1 auto;
    }
    & > :nth-child(2) {
      flex: 0 1 6rem;
    }
    & > :nth-child(3) {
      flex: 2 0 7rem;
    }
  }
`;
