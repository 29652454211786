import React from 'react';
import { useSelector } from 'react-redux';

import { Avatar } from 'components/Avatar/Avatar';

import { ReactComponent as ArrowDownIcon } from 'images/arrowDownIcon.svg';

import { currentUserInitialsSelector } from 'ducks/users/selectors';
import { StyledAvatarMenu } from './styles';

export const AvatarMenu = (props) => {
  const initials = useSelector(currentUserInitialsSelector);
  return (
    <StyledAvatarMenu {...props}>
      <Avatar initials={initials} />
      <ArrowDownIcon />
    </StyledAvatarMenu>
  );
};
