import styled, { css } from 'styled-components/macro';

interface CapsLabelPropTypes {
  isDisabled?: boolean;
}

export const CapsLabel = styled.span<CapsLabelPropTypes>`
  ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.black400};
  text-transform: uppercase;
  letter-spacing: 0.025rem;
  font-weight: bold;
  white-space: nowrap;

  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      color: ${theme.colors.black200};
      pointer-events: none;
    `}
`;
