export const colorVariants = {
  blue: {
    background: '#7e4d10',
    border: '#transparent',
    containerShadow: 'none',
    value: 'white',
    label: 'white',
  },
  lightblue: {
    background: '#e98300',
    border: '#transparent',
    containerShadow: 'none',
    value: 'white',
    label: 'white',
  },
  green: {
    background: '#767676',
    border: '#transparent',
    containerShadow: 'none',
    value: 'white',
    label: 'white',
  },
  lightgreen: {
    background: '#286e31',
    border: '#transparent',
    containerShadow: 'none',
    value: 'white',
    label: 'white',
  },
  purple: {
    background: '#6B4BCC',
    border: '#transparent',
    containerShadow: 'none',
    value: 'white',
    label: 'white',
  },
  pink: {
    background: '#bc33a3',
    border: '#transparent',
    containerShadow: 'none',
    value: 'white',
    label: 'white',
  },
};

export type TagColorVariant = keyof typeof colorVariants;
