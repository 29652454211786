import React, { FC } from 'react';
import { Redirect, RouteComponentProps, WindowLocation } from '@reach/router';
import QRCode from 'react-qr-code';
import toast from 'react-hot-toast';
import { finishMfaSetup } from './helpers';
import {
  QRCodeWrapper,
  StyledAuthForm,
  StyledAuthFormWrapper,
  StyledAuthHeader,
  StyledAuthInput,
  StyledInstructions,
  StyledSubmitButton,
} from './styles';
import { useTranslation } from 'react-i18next';

interface MfaDetails {
  challengeName: string;
  qrCodeUrl: string;
  session: string;
  privateKey?: string; // Alternative to QR code scanning. The key can be entered manually to generate the OTP account in the app.
}
interface SetupMFAProps
  extends RouteComponentProps<{ location: { state: { mfaDetails: MfaDetails } } }> {
  location: WindowLocation<{ mfaDetails: MfaDetails }>;
}

export const SetupMFA: FC<SetupMFAProps> = (props) => {
  const { t } = useTranslation();
  const [code, setCode] = React.useState('');

  if (props.location.state === null) {
    return <Redirect from='' to='../sign_in' noThrow />;
  }

  const { qrCodeUrl, session } = props.location.state.mfaDetails;

  if (!qrCodeUrl) {
    toast.error(t('auth.error.generic_error'));
    props.navigate && props.navigate('../sign_in');
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!code) {
      toast.error(t('auth.error.missing_code'));
      return;
    }
    try {
      const response = await finishMfaSetup(code, session);
      if (response.status === 'SUCCESS') {
        toast.success(t('auth.success.mfa_setup_success'));
      } else {
        toast.error(t('auth.error.invalid_code'));
      }
      props.navigate && props.navigate('../sign_in');
    } catch (err) {
      console.log(err);
      toast.error(err as string);
    }
  };

  const handleCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  return (
    <StyledAuthFormWrapper marginTop='10em'>
      <StyledAuthForm onSubmit={onSubmit}>
        <StyledAuthHeader>{t('auth.instructions.mfa_required')}</StyledAuthHeader>
        <StyledInstructions>{t('auth.instructions.enable_mfa_step_1')}</StyledInstructions>
        {qrCodeUrl && (
          <QRCodeWrapper>
            <QRCode value={qrCodeUrl} />
          </QRCodeWrapper>
        )}
        <StyledInstructions>{t('auth.instructions.enable_mfa_step_2')}</StyledInstructions>
        <StyledAuthInput
          autoFocus
          aria-label='code'
          placeholder={t('auth.otp')}
          type='text'
          onChange={handleCode}
        />
        <StyledSubmitButton type='submit'>{t('auth.finish_mfa_setup')}</StyledSubmitButton>
      </StyledAuthForm>
    </StyledAuthFormWrapper>
  );
};
