import React, { ReactNode } from 'react';

import { StyledLeftShading, StyledRightShading, StyledShading } from './styles';

interface PropsType {
  children: ReactNode;
  showLeft?: boolean;
  showRight?: boolean;
  leftLarge?: boolean;
  rightLarge?: boolean;
}

const Shading = ({ children, showLeft, showRight, leftLarge, rightLarge }: PropsType) => {
  const isCompanyVisit = window.location?.pathname?.match(/redesigned\/new-ordinary-visit/); // tabs on company visits

  return (
    <StyledShading>
      {showLeft && <StyledLeftShading large={leftLarge} />}
      {children}
      {showRight && (
        <StyledRightShading isCompanyVisit={isCompanyVisit !== null} large={rightLarge} />
      )}
    </StyledShading>
  );
};

Shading.defaultProps = {
  leftLarge: false,
  rightLarge: false,
  showLeft: false,
  showRight: false,
  variant: 'default',
};

export { Shading };
