import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useModal } from 'components/Modal/Modal';
import {
  StyledModalButtonsWrapper,
  StyledModalHeader,
  StyledModalMessage,
} from 'components/Modal/styles';
import { useAppDispatch } from 'store';
import { updateCompanyVisitStatus } from 'ducks/newVisit';

interface FinishCompanyVisitProps {
  companyVisitId: string;
  hasNotMetObservations: boolean;
}

export const FinishCompanyVisit = ({
  companyVisitId,
  hasNotMetObservations,
}: FinishCompanyVisitProps): React.ReactElement => {
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const { hideModal } = useModal();
  const dispatch = useAppDispatch();

  const onSubmit = React.useCallback(async () => {
    setIsProcessing(true);
    await dispatch(updateCompanyVisitStatus({ id: companyVisitId, newStatus: 'completed' }));
    hideModal();
  }, [companyVisitId]);

  return (
    <>
      <StyledModalHeader>{t('new_visits.finish_visit')}</StyledModalHeader>

      <StyledModalMessage>
        {hasNotMetObservations
          ? t('new_visits.finish_visit_desc_with_warning')
          : t('new_visits.finish_visit_desc')}
      </StyledModalMessage>
      <StyledModalButtonsWrapper>
        <DefaultButton
          capitalize
          isDisabled={isProcessing}
          label={t('common.cancel')}
          onClick={hideModal}
          type='button'
          variant='tertiary'
        />
        <DefaultButton
          isDisabled={isProcessing}
          label={t('common.finish')}
          onClick={onSubmit}
          type='button'
          variant='primary'
        />
      </StyledModalButtonsWrapper>
    </>
  );
};
