import styled from 'styled-components/macro';

export const StyledNav = styled.nav`
  display: inline-flex;
  align-items: stretch;
  & > * {
    margin-right: ${({ theme }) => theme.sizes.xs};
  }
`;

export const StyledWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.sizes.md};
`;

export const StyledText = styled.div`
  padding: ${({ theme }) => theme.sizes.xs} 0;
  font-weight: bold;
  white-space: nowrap;
`;

export const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.black300};
`;
