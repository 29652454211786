import React, { ReactNode } from 'react';
import { StyledButtonWrapper } from './styles';

export type ButtonWrapperAlign = 'left' | 'right' | 'center';

interface ButtonWrapperPropTypes {
  align: ButtonWrapperAlign;
  children: ReactNode | Array<ReactNode>;
  wrap: boolean;
}

const ButtonWrapper = ({ children, align, wrap }: ButtonWrapperPropTypes) => (
  <StyledButtonWrapper align={align} isWrap={wrap}>
    {children}
  </StyledButtonWrapper>
);

ButtonWrapper.defaultProps = {
  align: 'left',
  wrap: false,
};

export { ButtonWrapper };
