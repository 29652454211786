import * as React from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { SimpleTableGrid } from 'components/Table/TableGrid/SimpleTableGrid';
import { PeopleIcon } from 'utils/iconsMap';
import {
  recipientsColumnsOrder,
  recipientsComponentsMap,
  reportColumnsOrder,
  reportComponentsMap,
} from 'containers/newVisit/shared/tableMappers/reports';
import { CompanyVisitReport } from 'ducks/newVisit';
import { CollapsibleSection } from 'components/CollapsibleSection/CollapsibleSection';

export interface ReportsSectionProps {
  readonly companyVisitReports: CompanyVisitReport[];
  readonly companyVisitId: string;
}

const InnerTable = (props: CompanyVisitReport): JSX.Element => {
  const { t } = useTranslation();
  const { contacts } = props;

  return (
    <SimpleTableGrid
      bodyData={contacts}
      columnsOrder={recipientsColumnsOrder}
      componentsMap={recipientsComponentsMap}
      noResultsMessage={t('new_visits.no_results_message')}
    />
  );
};

export const ReportsSection = ({
  companyVisitReports,
  companyVisitId,
}: Readonly<ReportsSectionProps>): JSX.Element | null => {
  const { t } = useTranslation();

  const actionHandlers = {
    open_report: ({ url }: CompanyVisitReport) => window.open(url, '_blank'),
  };

  if (isEmpty(companyVisitReports)) return null;

  return (
    <CollapsibleSection
      header={t('new_visits.reports')}
      name={`isReportSectionOpen-${companyVisitId}`}
      testid='report-section'
    >
      <SimpleTableGrid
        actionHandlers={actionHandlers}
        bodyData={companyVisitReports}
        columnsOrder={reportColumnsOrder}
        componentsMap={reportComponentsMap}
        CustomToggleDropdownIcon={PeopleIcon}
        DropdownRowComponent={InnerTable}
        expandableButtonTooltips={{
          opened: t('new_visits.hide_recipients'),
          closed: t('new_visits.view_recipients'),
        }}
        isExpandable
      />
    </CollapsibleSection>
  );
};
