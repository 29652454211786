import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import styled, { css } from 'styled-components/macro';

export const StyledActionDrawer = styled.div`
  background-color: inherit;
  display: inline-flex;
`;

export const StyledDrawerOptions = styled.div<{ isVisible: boolean }>`
  align-items: center;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.25) 0%, white 10%);
  display: inline-flex;
  height: 100%;
  opacity: 0;
  padding-left: ${({ theme }) => theme.sizes.md};
  position: absolute;
  top: 0;
  transform: translateX(-100%);
  transition: opacity 0.15s;

  & > * {
    margin-right: ${({ theme }) => theme.sizes.xs};
    background-color: white;
  }

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
    `}
`;

export const StyledDrawerButton = styled(DefaultButton)`
  margin: ${({ theme }) => theme.sizes.xxs} 0;
`;
