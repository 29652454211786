import React, { ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize, debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import axios, { CancelTokenSource } from 'axios';
import { chosenDistrictSelector } from 'ducks/powerSearch/selectors';
import { SearchCompanyEntityResponse } from 'ducks/powerSearch/company/types';
import { StyledModalHeader, useModal } from 'components/Modal/Modal';
import { CreateCompanyForm } from 'containers/CompanyCreate/CreateCompanyForm';
import { ContentContainer } from 'styles/utils';
import { SearchSelectListItemStandard } from 'components/Forms/SearchSelect/styles';
import { StyledCompanySearchWrapper, WorkplaceListItem } from '../OrdinaryVisit/styles';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useScreenSizeContext } from 'contexts/ScreenSizeContext';
import { fetchCompany } from 'ducks/companies/actions';
import { StatusBadge } from 'components/StatusBadge/StatusBadge';
import { createNewVisitCompanyVisit, searchForCompaniesNewVisit } from 'ducks/newVisit';
import { Text } from 'components/Text/Text';
import { CompanyPreviewModal } from 'containers/newVisit/shared/CompanyPreviewModal';

import { ReactComponent as RightArrowIcon } from 'images/arrowRightBigIcon.svg';
import { ReactComponent as PlusIcon } from 'images/plusIcon.svg';
import { ReactComponent as EyeIcon } from 'images/eyeIcon.svg';
import { SearchSelectWithCheckbox } from 'components/Forms/SearchSelectWithCheckbox/SearchSelectWithCheckbox';
import { useParams } from '@reach/router';

interface CompanySearchSelectPropTypes {
  disabled?: boolean;
}

export const CompanySearchSelect = ({ disabled = false }: CompanySearchSelectPropTypes) => {
  const [listItems, setListItems] = useState<ReactElement[]>([]);
  const [searchInactive, setSearchInactive] = useState(false);
  const { sm } = useScreenSizeContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const chosenDistrict = useSelector(chosenDistrictSelector);
  const cancelToken = useRef<CancelTokenSource | null>(null);
  const { setModalComponent, showModal, setCustomStyles, setShowHeader } = useModal();
  const { id: visitId } = useParams();

  const onSearchHandle = async (text: string) => {
    if (text.length === 0) setListItems([]);
    else {
      debouncedSearchCompany.current({
        query: text,
        company: {
          'filter[status][values]': searchInactive ? ['active', 'inactive'] : 'active',
        },
        page_size: 500,
        searchInactive,
      });
    }
  };

  const companyPreviewModalHandle = async (companyId: string) => {
    const company = await fetchCompany(companyId);
    setModalComponent(<CompanyPreviewModal company={company} />);
    setShowHeader(true);
    showModal();
  };

  const companyModalHandle = () => {
    setCustomStyles({ maxWidth: '85rem' });
    setModalComponent(
      <>
        <StyledModalHeader>{t('company_details.add_new_company')}</StyledModalHeader>
        <CreateCompanyForm
          callback={(company) => {
            dispatch(createNewVisitCompanyVisit({ company_id: company.id, visit_id: visitId }));
          }}
          numberKnown
        />
      </>,
    );
    showModal();
  };

  const debouncedSearchCompany = useRef(
    debounce(
      async (params: {
        query: string;
        company: object;
        page_size: number;
        searchInactive: boolean;
      }) => {
        cancelToken.current?.cancel();
        const CancelToken = axios.CancelToken;
        cancelToken.current = CancelToken.source();

        try {
          const response = await searchForCompaniesNewVisit(params, cancelToken.current);
          const data = response?.data;

          if (data && data?.tableBody?.length > 0) {
            setListItems(
              data.tableBody.map((company: SearchCompanyEntityResponse) => (
                <SearchSelectListItemStandard
                  key={company.companyId}
                  onClick={() => {
                    dispatch(
                      createNewVisitCompanyVisit({
                        company_id: company.companyId,
                        visit_id: visitId,
                      }),
                    );
                  }}
                >
                  <WorkplaceListItem>
                    <div>
                      <Text>
                        <StatusBadge variant={company.status === 'active' ? 'success' : 'inactive'}>
                          {capitalize(
                            t(
                              `company_details.company_is_${
                                company.status === 'active' ? 'active' : 'inactive'
                              }`,
                            ),
                          )}
                        </StatusBadge>
                        {company.name}
                      </Text>
                      {company?.municipalityName && (
                        <Text size='xs'>{company.municipalityName}</Text>
                      )}
                      <Text color='black500' size='xxs'>
                        {t('forms.org_number')}: {company.orgNo}
                      </Text>
                    </div>
                    <ContentContainer display='flex' margin='auto 1.2rem auto auto'>
                      <DefaultButton
                        icon={<EyeIcon />}
                        onClick={(e) => {
                          e.stopPropagation();
                          companyPreviewModalHandle(company.companyId);
                        }}
                        size='small'
                        variant='tertiary'
                      />
                    </ContentContainer>
                    <RightArrowIcon />
                  </WorkplaceListItem>
                </SearchSelectListItemStandard>
              )),
            );
          } else {
            setListItems([
              <SearchSelectListItemStandard key='no-results'>
                {t('new_visits.no_results_message')}
              </SearchSelectListItemStandard>,
            ]);
          }
        } catch (err) {}
      },
      350,
    ),
  );

  return (
    <ContentContainer margin='2.4rem auto' maxWidth='60rem'>
      <StyledCompanySearchWrapper>
        <SearchSelectWithCheckbox
          autoFocus
          closeOnSelect
          isChecked={searchInactive}
          label={t('new_visits.include_inactive')}
          listItems={listItems}
          onSearch={onSearchHandle}
          placeholder={t('new_visits.find_company')}
          setSearchInactive={() => setSearchInactive((x) => !x)}
        />
        <DefaultButton
          icon={<PlusIcon />}
          isDisabled={disabled}
          label={sm ? t('company_details.add_new_company') : undefined}
          onClick={companyModalHandle}
        />
      </StyledCompanySearchWrapper>
    </ContentContainer>
  );
};
