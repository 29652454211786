import React, { ComponentType } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Textarea, TextareaPropTypes } from 'components/Forms/Textarea/Textarea';

export const TextareaField: ComponentType<TextareaPropTypes & FieldRenderProps<string>> = ({
  input,
  ...rest
}) => {
  return <Textarea {...input} {...rest} />;
};
