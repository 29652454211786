import React, { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { CancelTokenSource } from 'axios';

import { AdditionalChecklist } from 'containers/newVisit/shared/AdditionalChecklist';
import { constantsSelector } from 'ducks/constants/selectors';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { ObligatoryChecklist } from 'containers/newVisit/shared/ObligatoryChecklist';
import { StyledCardHeader } from 'components/Card/styles';

interface ChecklistsSectionProps {
  cancelToken: MutableRefObject<CancelTokenSource | null>;
  companyVisitId: string;
  visitId: string;
}

const StyledChecklists = styled('div')`
  border-top: ${({ theme }) => `1px solid ${theme.colors.black100}`};
  margin-bottom: 2.4rem;
`;

export const ChecklistsSection = ({
  cancelToken,
  companyVisitId,
  visitId,
}: ChecklistsSectionProps) => {
  const { checklistsStatus, measuresStatus, measureTypesStatus, standardCommentsStatus } =
    useSelector(constantsSelector);

  const { t } = useTranslation();

  const isFetching = [
    checklistsStatus,
    measuresStatus,
    measureTypesStatus,
    standardCommentsStatus,
  ].some((status) => status === 'pending' || status === 'idle');

  return (
    <div data-testid='new-visit-checklists-section'>
      <StyledCardHeader>{t('new_visits.checkpoints')}</StyledCardHeader>

      {isFetching ? (
        <LoadingSpinner small />
      ) : (
        <StyledChecklists>
          <ObligatoryChecklist
            cancelToken={cancelToken}
            companyVisitId={companyVisitId}
            visitId={visitId}
          />
          <AdditionalChecklist
            cancelToken={cancelToken}
            companyVisitId={companyVisitId}
            key={companyVisitId}
            visitId={visitId}
          />
        </StyledChecklists>
      )}
    </div>
  );
};
