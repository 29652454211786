import React, { useCallback, useMemo, useState } from 'react';
import { capitalize } from 'lodash';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';

import { Card, StyledCardDivider, StyledCardHeader } from 'components/Card/Card';
import { StatusBadge } from 'components/StatusBadge/StatusBadge';
import {
  StyledAdditionalStatuses,
  StyledBranches,
  StyledBranchStatus,
  StyledInfraction,
  StyledInfractionWrapper,
  StyledRiskLevel,
  StyledRiskWrapper,
  StyledValuesContainer,
  StyledWarningMessage,
} from './styles';
import { StandardLabel } from 'components/StandardLabel/StandardLabel';
import { BulletLabel } from 'components/BulletLabel/BulletLabel';
import { ValueWithLabel } from 'components/ValueWithLabel/ValueWithLabel';
import { ButtonWrapper } from 'components/ButtonWrapper/ButtonWrapper';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { CheckboxField } from 'components/Forms/CheckboxField/CheckboxField';
import { StyledCheckboxWrapper } from 'components/Forms/styles';
import { TextareaField } from 'components/Forms/TextareaField/TextareaField';
import { LevelField } from 'components/Forms/LevelField/LevelField';
import { Margin } from 'styles/utils';
import { CompanyEntity } from 'ducks/companies/types';
import { formatDate } from 'utils/date';
import { yupValidate } from 'utils/yupValidate';
import { updateCompany } from 'ducks/companies/actions';

import { ReactComponent as EditIcon } from 'images/editIcon.svg';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { StopIcon, WarningIcon } from 'utils/iconsMap';
import { navigate } from '@reach/router';

const riskOptions = [1, 2, 3, 4].map((x) => ({ label: x, value: x }));

const riskVariants = ['success', 'low-alert', 'high-alert', 'fail'] as Array<
  'success' | 'low-alert' | 'high-alert' | 'fail'
>;

const validationSchema = yup.object().shape({
  comment: yup.string().max(1000),
});

interface BasicDataStatusPropTypes {
  company: CompanyEntity;
}

export const BasicDataStatus = ({ company }: BasicDataStatusPropTypes) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isHrrBranch = window.REACT_APP_ENV?.BRAND_ID === 'hrr';
  const { id, status, info } = company;
  const {
    cleaningLicenseText,
    hasAlcoholLicense,
    hasAlcoholLicenseIndicator,
    hasAlcoholLicenseSetByItx,
    providesAccommodationServices,
    providesCleaningServices,
    providesCleaningServicesIndicator,
    providesCleaningServicesSetByItx,
    alcoholServingMunicipality,
    alcoholServingEstablishment,
  } = status.branches;
  const [isEdit, setIsEdit] = useState(false);

  const riskValue = status.riskLevel;
  const issueObservations = status.issueObservations;
  const stopObservations = status.stopObservations;

  const getObservationTooltipLabel = (ongoing: number, total: number, type: 'stops' | 'issues') => {
    const outOf = t(`company_details.basic-data.open_${type}`);
    const noOngoingInfractions = t(`company_details.basic-data.no_open_${type}`);
    const label = ongoing > 0 ? `${ongoing} ${outOf} ${total}` : noOngoingInfractions;
    return label;
  };

  const stopObservationsLabel = getObservationTooltipLabel(
    stopObservations.ongoingInfractions,
    stopObservations.totalInfractions,
    'stops',
  );

  const issueObservationsLabel = getObservationTooltipLabel(
    issueObservations.ongoingInfractions,
    issueObservations.totalInfractions,
    'issues',
  );

  const amu = useMemo(() => status.regulations.amu, [status]);
  const ik = useMemo(() => status.regulations.ik, [status]);
  const bht = useMemo(() => status.regulations.bht, [status]);
  const {
    bankrupt,
    establishedAtDate,
    registeredAtDate,
    lastRegisteredAnnualAccountsYear,
    ongoingBankruptcyProceedings,
    ongoingForcedLiquidation,
    invoiceBounced,
  } = status;

  const safetyRepresentative = useMemo(() => status.regulations.safetyRepresentative, [status]);

  const onSubmit = useCallback(
    async (values) => {
      await dispatch(updateCompany({ companyId: id, comment: '', ...values }));
      setIsEdit(false);
    },
    [dispatch, id],
  );

  return (
    <Card>
      <Form
        initialValues={{
          risk_level: riskValue < 1 ? null : riskValue,
          provides_accommodation_services: providesAccommodationServices,
          provides_cleaning_services: providesCleaningServices,
          has_alcohol_license: hasAlcoholLicense,
          amu,
          bht,
          ik,
          safety_representative: safetyRepresentative,
          comment: status.comment || '',
        }}
        onSubmit={onSubmit}
        render={({ handleSubmit, form: { reset }, submitting }) => (
          <form onSubmit={handleSubmit}>
            <StyledCardHeader>
              {t('table.columns.status')}
              {isEdit ? (
                <ButtonWrapper>
                  <DefaultButton
                    capitalize
                    isDisabled={submitting}
                    label={t('common.cancel')}
                    onClick={() => {
                      reset();
                      setIsEdit(false);
                    }}
                    size='small'
                    type='button'
                    variant='secondary'
                  />
                  <DefaultButton
                    capitalize
                    isDisabled={submitting}
                    label={t('common.save')}
                    size='small'
                    type='submit'
                    variant='primary'
                  />
                </ButtonWrapper>
              ) : (
                <DefaultButton
                  capitalize
                  icon={<EditIcon />}
                  label={t('common.edit')}
                  onClick={() => setIsEdit(true)}
                  size='small'
                  type='button'
                  variant='tertiary'
                />
              )}
            </StyledCardHeader>

            <StyledCardDivider fullWidth />
            {isEdit ? (
              <>
                {isHrrBranch && (
                  <>
                    <StandardLabel>{t('company_details.basic-data.branch')}</StandardLabel>
                    <Margin bottom='2.4rem' top='1.6rem'>
                      <StyledCheckboxWrapper>
                        <Field
                          component={CheckboxField}
                          label={t('company_details.basic-data.accommodation')}
                          name='provides_accommodation_services'
                        />

                        <Field
                          component={CheckboxField}
                          isDisabled={hasAlcoholLicenseSetByItx}
                          label={t('company_details.basic-data.food_service')}
                          name='has_alcohol_license'
                        />

                        <Field
                          component={CheckboxField}
                          isDisabled={providesCleaningServicesSetByItx}
                          label={t('company_details.basic-data.cleaning')}
                          name='provides_cleaning_services'
                        />
                      </StyledCheckboxWrapper>
                    </Margin>

                    <Margin bottom='3.2rem'>
                      <Field
                        clearButton
                        component={LevelField}
                        label={t('company_details.basic-data.risk')}
                        name='risk_level'
                        options={riskOptions}
                      />
                    </Margin>
                  </>
                )}

                <Margin bottom='2.4rem'>
                  <StyledCheckboxWrapper>
                    <Field
                      component={CheckboxField}
                      label={t('company_details.basic-data.amu')}
                      name='amu'
                    />
                    <Field
                      component={CheckboxField}
                      label={t('company_details.basic-data.bht')}
                      name='bht'
                    />
                    <Field
                      component={CheckboxField}
                      label={t('company_details.basic-data.ik_system')}
                      name='ik'
                    />
                    <Field
                      component={CheckboxField}
                      label={t('company_details.basic-data.safety_representative')}
                      name='safety_representative'
                    />
                  </StyledCheckboxWrapper>
                </Margin>
                <Field
                  characterLimit={1000}
                  component={TextareaField}
                  label={t('company_details.basic-data.comment')}
                  maxRows={3}
                  name='comment'
                  placeholder={t('company_details.basic-data.comment')}
                />
              </>
            ) : (
              <>
                {isHrrBranch && !info.branch.includes('no_branch') && (
                  <>
                    <StandardLabel variant='primary'>
                      {t('company_details.basic-data.branch')}
                    </StandardLabel>
                    <StyledBranches>
                      {info.branch.includes('accommodation') && (
                        <StyledBranchStatus>
                          <StatusBadge variant='active'>
                            {t('company_details.basic-data.accommodation')}
                          </StatusBadge>
                        </StyledBranchStatus>
                      )}
                      {info.branch.includes('food_service') && (
                        <StyledBranchStatus>
                          <StatusBadge variant='active'>
                            {t('company_details.basic-data.food_service')}
                          </StatusBadge>
                          {hasAlcoholLicenseSetByItx && (
                            <BulletLabel variant={hasAlcoholLicenseIndicator || undefined}>
                              {t('company_details.basic-data.license_to_serve_alcohol')}
                            </BulletLabel>
                          )}
                        </StyledBranchStatus>
                      )}
                      {info.branch.includes('cleaning') && (
                        <StyledBranchStatus>
                          <StatusBadge variant='active'>
                            {t('company_details.basic-data.cleaning')}
                          </StatusBadge>
                          {providesCleaningServicesSetByItx && (
                            <BulletLabel variant={providesCleaningServicesIndicator || undefined}>
                              {cleaningLicenseText || ''}
                            </BulletLabel>
                          )}
                        </StyledBranchStatus>
                      )}
                    </StyledBranches>
                    <StyledCardDivider fullWidth />
                  </>
                )}
                {isHrrBranch ? (
                  <>
                    <StyledRiskWrapper>
                      <div>
                        <StandardLabel variant='primary'>
                          {t('company_details.basic-data.risk')}
                        </StandardLabel>
                        <StyledRiskLevel>
                          {riskVariants.map((variant, index) => (
                            <StatusBadge
                              fill
                              key={index}
                              variant={riskValue === index + 1 ? variant : 'inactive'}
                            >
                              {index + 1}
                            </StatusBadge>
                          ))}
                        </StyledRiskLevel>
                      </div>
                      <StyledInfractionWrapper>
                        <Margin right='1.6rem' left='2.4rem'>
                          <Tooltip label={stopObservationsLabel}>
                            <StyledInfraction
                              status={stopObservations.status}
                              total={stopObservations.totalInfractions}
                              onClick={
                                stopObservations.totalInfractions > 0
                                  ? () => {
                                      navigate(`/redesigned/companies/${id}/visits`, {
                                        state: {
                                          ...window.history.state,
                                          filters: [
                                            {
                                              filterName: 'stops',
                                              value: { min: 1 },
                                              displayValue: ['1 eller flere'],
                                            },
                                          ],
                                        },
                                        replace: true,
                                      });
                                    }
                                  : undefined
                              }
                            >
                              <StopIcon data-testid='th-stops' />
                              <span>
                                {stopObservations.ongoingInfractions}/
                                {stopObservations.totalInfractions}
                              </span>
                            </StyledInfraction>
                          </Tooltip>
                        </Margin>
                        <Margin right='1.6rem'>
                          <Tooltip label={issueObservationsLabel}>
                            {' '}
                            <StyledInfraction
                              status={issueObservations.status}
                              total={issueObservations.totalInfractions}
                              onClick={
                                issueObservations.totalInfractions > 0
                                  ? () => {
                                      navigate(`/redesigned/companies/${id}/visits`, {
                                        state: {
                                          ...window.history.state,
                                          filters: [
                                            {
                                              filterName: 'issues',
                                              value: { min: '1' },
                                              displayValue: ['1 eller flere'],
                                            },
                                          ],
                                        },
                                        replace: true,
                                      });
                                    }
                                  : undefined
                              }
                            >
                              <WarningIcon data-testid='th-issues' />
                              <span>
                                {issueObservations.ongoingInfractions}/
                                {issueObservations.totalInfractions}
                              </span>
                            </StyledInfraction>
                          </Tooltip>
                        </Margin>
                      </StyledInfractionWrapper>
                    </StyledRiskWrapper>

                    <StyledCardDivider fullWidth />
                  </>
                ) : (
                  <>
                    <StandardLabel variant='primary'>
                      {t('company_details.basic-data.risk')}
                    </StandardLabel>
                    <StyledRiskWrapper>
                      <StyledInfractionWrapper>
                        <Margin top='1rem'>
                          <Tooltip label={stopObservationsLabel}>
                            <StyledInfraction status={stopObservations.status}>
                              <StopIcon data-testid='th-stops' />
                              <span>
                                {stopObservations.ongoingInfractions}/
                                {stopObservations.totalInfractions}
                              </span>
                            </StyledInfraction>
                          </Tooltip>
                        </Margin>
                        <Margin top='1rem' left='1.6rem'>
                          <Tooltip label={issueObservationsLabel}>
                            <StyledInfraction status={issueObservations.status}>
                              <WarningIcon data-testid='th-issues' />
                              <span>
                                {issueObservations.ongoingInfractions}/
                                {issueObservations.totalInfractions}
                              </span>
                            </StyledInfraction>
                          </Tooltip>
                        </Margin>
                      </StyledInfractionWrapper>
                    </StyledRiskWrapper>
                    <StyledCardDivider fullWidth />
                  </>
                )}

                {(bankrupt || ongoingBankruptcyProceedings || ongoingForcedLiquidation) && (
                  <>
                    {bankrupt && (
                      <StyledWarningMessage variant='danger'>
                        <WarningIcon />
                        <span>{t('company_details.basic-data.bankruptcy')}</span>
                      </StyledWarningMessage>
                    )}
                    {ongoingBankruptcyProceedings && (
                      <StyledWarningMessage variant='warning'>
                        <WarningIcon />
                        <span>{t('company_details.basic-data.bankruptcy_proceeding')}</span>
                      </StyledWarningMessage>
                    )}
                    {ongoingForcedLiquidation && (
                      <StyledWarningMessage variant='warning'>
                        <WarningIcon />
                        <span>{t('company_details.basic-data.forced_liquidation')}</span>
                      </StyledWarningMessage>
                    )}
                    <StyledCardDivider fullWidth />
                  </>
                )}

                <StyledValuesContainer>
                  <ValueWithLabel
                    flexDirection='vertical'
                    label={t('company_details.basic-data.joined')}
                  >
                    {status.joined ? formatDate(status.joined) : '-'}
                  </ValueWithLabel>
                  {registeredAtDate && (
                    <ValueWithLabel
                      flexDirection='vertical'
                      label={t('company_details.basic-data.registered')}
                    >
                      {formatDate(registeredAtDate)}
                    </ValueWithLabel>
                  )}
                  <ValueWithLabel
                    flexDirection='vertical'
                    label={t('company_details.basic-data.invoice_bounced')}
                  >
                    {capitalize(t(invoiceBounced ? 'common.yes' : 'common.no'))}
                  </ValueWithLabel>
                  {establishedAtDate && (
                    <ValueWithLabel
                      flexDirection='vertical'
                      label={t('company_details.basic-data.established')}
                    >
                      {formatDate(establishedAtDate)}
                    </ValueWithLabel>
                  )}
                  {alcoholServingEstablishment && (
                    <ValueWithLabel
                      flexDirection='vertical'
                      label={t('company_details.basic-data.alcohol_serving_establishment')}
                    >
                      {alcoholServingEstablishment}
                    </ValueWithLabel>
                  )}
                  {alcoholServingMunicipality && (
                    <ValueWithLabel
                      flexDirection='vertical'
                      label={t('company_details.basic-data.alcohol_serving_municipality')}
                    >
                      {alcoholServingMunicipality}
                    </ValueWithLabel>
                  )}
                  {lastRegisteredAnnualAccountsYear && (
                    <ValueWithLabel
                      flexDirection='vertical'
                      label={t('company_details.basic-data.last_annual_accounts')}
                    >
                      {lastRegisteredAnnualAccountsYear}
                    </ValueWithLabel>
                  )}
                  {!info.active && (
                    <ValueWithLabel
                      flexDirection='vertical'
                      label={t('company_details.basic-data.deactivated')}
                    >
                      {status.endDate ? formatDate(status.endDate) : '-'}
                    </ValueWithLabel>
                  )}
                  <ValueWithLabel
                    flexDirection='vertical'
                    label={t('company_details.basic-data.number_of_employees')}
                  >
                    {status?.numberOfEmployees || '0'}
                  </ValueWithLabel>
                </StyledValuesContainer>

                <StyledCardDivider fullWidth />

                <StyledAdditionalStatuses>
                  <StatusBadge variant={amu ? 'active' : 'inactive'}>
                    {t('company_details.basic-data.amu')}
                  </StatusBadge>
                  <StatusBadge variant={bht ? 'active' : 'inactive'}>
                    {t('company_details.basic-data.bht')}
                  </StatusBadge>
                  <StatusBadge variant={ik ? 'active' : 'inactive'}>
                    {t('company_details.basic-data.ik_system')}
                  </StatusBadge>
                  <StatusBadge variant={safetyRepresentative ? 'active' : 'inactive'}>
                    {t('company_details.basic-data.safety_representative')}
                  </StatusBadge>
                </StyledAdditionalStatuses>

                {status.comment && (
                  <>
                    <StyledCardDivider fullWidth />

                    <ValueWithLabel
                      flexDirection='vertical'
                      label={t('company_details.basic-data.comment')}
                    >
                      {status.comment}
                    </ValueWithLabel>
                  </>
                )}
              </>
            )}
          </form>
        )}
        validate={yupValidate(validationSchema)}
      />
    </Card>
  );
};
