import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { add } from 'date-fns';
import { capitalize, debounce, isEmpty, omit } from 'lodash';
import styled from 'styled-components';
import axios, { CancelTokenSource } from 'axios';

import {
  deleteObservation,
  deleteObservationAttachment,
  getLinkedStandardComments,
  getVisitById,
  isNewVisitFinished,
  isNewVisitFollowUp,
  isNewVisitWithEditingAccess,
  newVisitCompanyVisitSelector,
  ObservationType,
  reduxDeleteObservationAttachment,
  updateObservation,
  uploadObservationAttachment,
} from 'ducks/newVisit';
import {
  checkpointSelector,
  measuresSelector,
  measureTypesSelector,
} from 'ducks/constants/selectors';
import {
  StyledChecklistItem,
  StyledChecklistItemCategory,
  StyledChecklistItemContent,
  StyledChecklistItemExpandIcon,
  StyledChecklistItemTopSection,
  StyledNewDeadlineRow,
  StyledObservationCheckpointLabel,
  StyledObservationFields,
} from 'containers/newVisit/shared/Observation/style';
import { DatepickerInput } from 'components/Forms/DatepickerInput/DatepickerInput';
import {
  FieldSingleSelect,
  triggerCustomEvent,
} from 'components/FieldSingleSelect/FieldSingleSelect';
import { formatQueryDate } from 'utils/date';
import { useAppDispatch } from 'store';
import { NumberField } from 'components/Forms/NumberField/NumberField';
import { useModal } from 'components/Modal/Modal';
import { NewVisitCommentModal } from 'containers/newVisit/OrdinaryVisit/NewVisitCommentModal';
import { AttachmentsToInstantRemove } from 'components/Forms/AttachmentsToRemove/AttachmentsToInstantRemove';
import { ObservationButtons } from 'containers/newVisit/shared/Observation/ObservationButtons';
import { DeleteObservation } from 'dialogs/Observation/DeleteObservation';
import { AttachmentWithPreview } from 'components/Forms/Attachments/InstantUploadAttachments';
import { AttachmentsWithRichText } from 'components/Forms/Attachments/AttachmentsWithRichText';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { amendStandardCommentOptions } from 'containers/newVisit/shared/Observation/helpers';
import { UPDATE_COMMENT_TIME, UPDATE_TIME } from '../../constants';
import { PaperclipIcon } from 'utils/iconsMap';
import { DeleteAttachmentModal } from './DeleteAttachmentModal';

type submitPropsType = {
  checkpointId?: string;
  deadline?: string | null;
  comment?: string | null;
  companyVisitId?: string;
  quantity?: string;
  fulfilled?: boolean | null;
  newDeadline?: string | null;
  measureId?: string | null;
  measureTypeId?: string;
};

const StyledAttachmentsWithRichText = styled(AttachmentsWithRichText)`
  margin-bottom: 0;
`;

const StyledPaperclipIcon = styled(PaperclipIcon)`
  height: 16px;
  margin-left: 0.4rem;
`;

export const Observation = (props: ObservationType) => {
  const {
    attachments,
    cancelToken,
    checkpointId,
    comment = '',
    companyVisitId,
    deadline = '',
    fulfilled: propsFulfilled,
    id,
    measureId,
    measureTypeId,
    measureTypes: possibleMeasureTypes,
    name,
    newDeadline: propsNewDeadline,
    quantity,
    visitId,
  } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [temporaryAttachments, setTemporaryAttachments] = useState<[] | { id: string }[]>([]);
  const [attachmentError, setAttachmentError] = useState('');
  const [isOpenMeasureSelect, setIsOpenMeasureSelect] = useState<boolean | undefined>();
  const { setModalComponent, showModal, setCustomStyles, setShowHeader } = useModal();
  const updateCancelToken = useRef<CancelTokenSource | null>(null);

  const [measureCount, setMeasureCount] = useState(quantity || '1');
  const [text, setText] = useState(comment || '');
  const [selectedDeadline, setSelectedDeadline] = useState(deadline || null);
  const [fulfilled, setFulfilled] = useState(!!propsFulfilled);
  const [newDeadline, setNewDeadline] = useState<string | null>(propsNewDeadline);

  const checkpoint = useSelector(checkpointSelector(checkpointId));
  const companyVisit = useSelector(newVisitCompanyVisitSelector(companyVisitId));
  const measureTypes = useSelector(measureTypesSelector)!;
  const measures = useSelector(measuresSelector)!;
  const isVisitFinished = useSelector(isNewVisitFinished(companyVisitId));
  const isVisitFollowUp = useSelector(isNewVisitFollowUp(companyVisitId));
  const isVisitCanEdit = useSelector(isNewVisitWithEditingAccess(companyVisitId));
  const isDisabled = !isVisitCanEdit || (isVisitCanEdit && (isVisitFinished || isVisitFollowUp));
  const isCompleted = 'completed' === companyVisit?.status;

  const measureTypeOptions = useMemo(() => {
    const filteredByCheckpoint = measureTypes.filter((measureType) =>
      measureType.checkpointIds.includes(checkpoint?.id),
    );
    return filteredByCheckpoint.map(({ id, name }) => ({ label: name, value: id }));
  }, [measureTypes]);

  const [measureType, setMeasureType] = useState(
    measureTypeOptions.find(({ value }) => value === measureTypeId) || null,
  );

  const measureOptions = useMemo(() => {
    return measureType
      ? measures
          .filter(({ id }) => possibleMeasureTypes[measureType.value].includes(id))
          .map(({ name, id }) => ({ label: name, value: id }))
      : [];
  }, [measures, measureType, possibleMeasureTypes]);
  const [measure, setMeasure] = useState(
    measureOptions.find(({ value }) => value === measureId) || null,
  );

  const minDeadlineDate = useMemo(() => {
    const followDateExists = !isEmpty(companyVisit?.followUpDate);
    const deadlineDateExists = !isEmpty(selectedDeadline);
    const datesExists = followDateExists && deadlineDateExists;
    const splitFollowUpDate = followDateExists && companyVisit?.followUpDate?.split('.');
    const formattedFollowUpDate =
      (splitFollowUpDate &&
        `${splitFollowUpDate[2]}-${splitFollowUpDate[1]}-${splitFollowUpDate[0]}`) ||
      '';
    let latestDate = '';

    if (datesExists) {
      latestDate =
        // @ts-ignore
        (formattedFollowUpDate < selectedDeadline ? selectedDeadline : formattedFollowUpDate) || '';
    } else if (followDateExists && !deadlineDateExists) {
      latestDate = formattedFollowUpDate || '';
    } else if (!followDateExists && deadlineDateExists) {
      latestDate = selectedDeadline || '';
    }

    return (
      (!isEmpty(latestDate) &&
        add(new Date(latestDate), {
          days: 1,
        })) ||
      undefined
    );
  }, [companyVisit?.followUpDate, selectedDeadline]);

  const sendAndUpdate = ({
    getProps,
    updateProps,
    withVisitRefresh,
  }: {
    getProps: { id: string };
    updateProps: { id: string };
    withVisitRefresh: boolean;
  }) => {
    updateCancelToken.current?.cancel();
    const UpdateCancelToken = axios.CancelToken;
    updateCancelToken.current = UpdateCancelToken.source();
    dispatch(updateObservation({ ...updateProps, token: updateCancelToken.current }));

    const CancelToken = axios.CancelToken;
    if (cancelToken?.current !== undefined) {
      cancelToken.current?.cancel();
    }
    cancelToken.current = CancelToken.source();

    if (withVisitRefresh) {
      dispatch(
        getVisitById({
          ...getProps,
          withoutResetActiveCompanyVisit: true,
          token: cancelToken.current,
        }),
      );
    }
  };

  const onSubmit = async ({ withStatus = false, withVisitRefresh = true }) => {
    if (withStatus) {
      setIsLoading(true);
    }
    const observation = {
      checkpointId,
      deadline: selectedDeadline ? formatQueryDate(selectedDeadline) : '',
      comment: text || '',
      companyVisitId,
      quantity: measureCount,
      fulfilled: selectedDeadline ? fulfilled : undefined,
      newDeadline,
      id,
      measureId: measure?.value,
      measureTypeId: measureType?.value,
    };

    sendAndUpdate({
      updateProps: observation,
      getProps: { id: String(visitId) },
      withVisitRefresh,
    });

    if (withStatus) {
      setIsLoading(false);
    }
  };

  const onSubmitWithParams = async (submitProps: submitPropsType) => {
    const newObservations = isDisabled
      ? omit(submitProps, [
          'checkpointId',
          'comment',
          'companyVisitId',
          'deadline',
          'measureId',
          'measureTypeId',
          'quantity',
        ])
      : submitProps;

    updateCancelToken.current?.cancel();
    const UpdateCancelToken = axios.CancelToken;
    updateCancelToken.current = UpdateCancelToken.source();

    sendAndUpdate({
      updateProps: { ...newObservations, id },
      getProps: { id: String(visitId) },
      withVisitRefresh: true,
    });
  };

  const debouncedUpdateCompany = useCallback(
    debounce((submitProps: submitPropsType) => onSubmitWithParams(submitProps), UPDATE_TIME),
    [isDisabled],
  );

  const debouncedUpdateCompanyChangingComment = useCallback(
    debounce(
      (submitProps: submitPropsType) => onSubmitWithParams(submitProps),
      UPDATE_COMMENT_TIME,
      { maxWait: UPDATE_COMMENT_TIME },
    ),
    [isDisabled],
  );

  const openStandardCommentsModal = useCallback(
    async ({
      measureId,
      measureTypeId,
      autoOpen,
    }: {
      measureId?: string;
      measureTypeId?: string;
      autoOpen?: boolean;
    }) => {
      const res = await getLinkedStandardComments({ checkpointId, measureId, measureTypeId });
      if (autoOpen && !res.length) return;
      const linkedStandardComments = res.map(amendStandardCommentOptions);

      setCustomStyles({ maxWidth: '60rem' });
      setModalComponent(
        <NewVisitCommentModal
          handleSelect={(message) => {
            setText((prev) => {
              const newComment = `${prev}${message}`.trim();
              onSubmitWithParams({ comment: newComment || '' });
              return newComment;
            });
          }}
          linkedStandardComments={linkedStandardComments}
        />,
      );
      setShowHeader(false);
      showModal();
    },
    [setText],
  );

  const confirmObservationDelete = useCallback(() => {
    setModalComponent(
      <DeleteObservation
        name={name || checkpoint!.name}
        onSubmit={() => {
          setIsLoading(true);
          return dispatch(deleteObservation({ id, companyVisitId }));
        }}
      />,
    );
    setShowHeader(false);
    showModal();
  }, [id, companyVisitId]);

  const attachmentsToRemoveProps = {
    attachmentList: attachments,
    deleteFile: (fileId: number) => {
      setModalComponent(
        <DeleteAttachmentModal
          deleteFile={() =>
            dispatch(
              reduxDeleteObservationAttachment({ id: fileId, companyVisitId, observationId: id }),
            )
          }
        />,
      );
      setShowHeader(false);
      showModal();
    },
  };

  const uploadAttachment = React.useCallback(
    async (file: FileWithPath) => {
      const data = new FormData();
      data.append('data[attributes][observation_attachment]', file);
      data.append('data[attributes][observation_id]', id);

      try {
        return await uploadObservationAttachment(data, (attachmentId) =>
          setTemporaryAttachments([...temporaryAttachments, { id: attachmentId }]),
        );
      } catch (error) {
        setAttachmentError(error.message);
      }
    },
    [companyVisitId],
  );

  const deleteFile = ({ id }: AttachmentWithPreview) =>
    deleteObservationAttachment(id, () =>
      setTemporaryAttachments(temporaryAttachments.filter(({ id: imageId }) => imageId !== id)),
    );

  const capitalizeName = (): string => {
    const observationName = name || checkpoint?.name;
    if (observationName?.length) {
      if (observationName[0] === observationName[0].toUpperCase()) {
        return observationName;
      }
      return capitalize(observationName);
    }
    return '';
  };

  return (
    <StyledChecklistItem isOpen={isOpen}>
      <StyledChecklistItemTopSection isOpen={isOpen}>
        <StyledChecklistItemExpandIcon
          className={
            isOpen ? 'observation-shrink-toggle--is-open' : 'observation-shrink-toggle--is-closed'
          }
          data-testid='observation-shrink-toggle'
          isOpen={isOpen}
          onClick={() => {
            setIsOpen((x) => !x);
            isOpen && !isDisabled && onSubmit({ withStatus: true });
          }}
        />
        <StyledChecklistItemCategory>
          <StyledObservationCheckpointLabel data-testid='observation-name'>
            {capitalizeName()}
            {(attachments.length > 0 || temporaryAttachments.length > 0) && <StyledPaperclipIcon />}
          </StyledObservationCheckpointLabel>
        </StyledChecklistItemCategory>
        <StyledObservationFields>
          <FieldSingleSelect
            handleSelectedItemChange={(x) => {
              setMeasureType(x);
              if (!x || isDisabled) return;
              triggerCustomEvent(`openMeasure:${id}`);

              const possibleMeasures = possibleMeasureTypes[x.value];
              if (possibleMeasures.length === 0) {
                setMeasure(null);
                setIsOpen(true);
                setIsOpenMeasureSelect(undefined);
                openStandardCommentsModal({ measureTypeId: x.value, autoOpen: true });
                debouncedUpdateCompany({ measureId: null, measureTypeId: x.value });
              } else if (possibleMeasures.length === 1) {
                const one = measures.find(({ id }) => id === possibleMeasures[0])!;
                setMeasure({ label: one.name, value: one.id });
                setIsOpen(true);
                setIsOpenMeasureSelect(false);
                openStandardCommentsModal({
                  measureId: one.id,
                  measureTypeId: x.value,
                  autoOpen: true,
                });
                debouncedUpdateCompany({ measureId: one.id, measureTypeId: x.value });
              } else {
                setMeasure(null);
                setIsOpenMeasureSelect(undefined);
                triggerCustomEvent(`openMeasure:${id}`);
                debouncedUpdateCompany({
                  measureId: null,
                  measureTypeId: x.value,
                });
              }
            }}
            isDisabled={isDisabled}
            name='status'
            options={measureTypeOptions}
            placeholder={t('forms.type')}
            selectedItem={measureType}
            size='small'
          />

          <NumberField
            digitsLimit={2}
            input={{
              name: 'input',
              value: measureCount,
              onChange: (e) => {
                setMeasureCount(e.target.value);
                debouncedUpdateCompany({ quantity: e.target.value });
              },
              onBlur() {},
              onFocus() {},
            }}
            isDisabled={!measureType || isDisabled}
            meta=''
            placeholder='0'
            size='small'
          />

          <FieldSingleSelect
            customEventName={`openMeasure:${id}`}
            handleSelectedItemChange={(e) => {
              setIsOpen(true);
              setMeasure(e);
              openStandardCommentsModal({
                measureId: e?.value,
                measureTypeId: measureType?.value,
                autoOpen: true,
              });
              debouncedUpdateCompany({ measureId: e?.value });
            }}
            isDisabled={!measureType || measureOptions.length === 0 || isDisabled}
            isOpenControlled={isOpenMeasureSelect}
            name='measure'
            options={measureOptions}
            placeholder={t('new_visits.measure')}
            selectedItem={measure}
            size='small'
          />

          <DatepickerInput
            buttonTestId='observation-datepicker-button'
            isDisabled={!measureType || companyVisit?.status === 'follow_up' || isDisabled}
            onChange={async (value) => {
              setSelectedDeadline(value);
              await debouncedUpdateCompany({
                deadline: value ? formatQueryDate(value) : '',
                fulfilled: null,
              });
            }}
            placeholder={t('new_visits.deadline')}
            size='small'
            value={selectedDeadline}
            withDeadline
          />
          <ObservationButtons
            isDisabled={isDisabled}
            isLoading={isLoading}
            onRemove={confirmObservationDelete}
          />
        </StyledObservationFields>

        {deadline &&
          companyVisit?.status &&
          ['follow_up', 'completed'].includes(companyVisit?.status) && (
            <StyledNewDeadlineRow data-testid='observation-new-deadline'>
              <label htmlFor='observation-fulfilled'>{t('common.fulfilled')}</label>
              <Checkbox
                inputProps={{ id: 'observation-fulfilled' }}
                isChecked={fulfilled}
                isDisabled={!isVisitCanEdit || isCompleted}
                onClick={() => {
                  setFulfilled((x) => !x);
                  debouncedUpdateCompany({ fulfilled: selectedDeadline ? !fulfilled : undefined });
                }}
              />
              <DatepickerInput
                buttonTestId='observation-new-deadline-datepicker'
                isDisabled={!isVisitCanEdit || fulfilled || isCompleted}
                minDate={minDeadlineDate}
                onChange={(value) => {
                  setNewDeadline(value);
                  debouncedUpdateCompany({ newDeadline: value });
                }}
                placeholder={t('new_visits.new_deadline')}
                size='small'
                value={newDeadline}
              />
            </StyledNewDeadlineRow>
          )}
      </StyledChecklistItemTopSection>

      {isOpen && (
        <StyledChecklistItemContent>
          <StyledAttachmentsWithRichText
            attachmentError={attachmentError}
            deleteFile={deleteFile}
            deleteTooltip={t('common.delete_attachment')}
            isDisabled={isDisabled}
            isDisabledAddingComment={!measureType}
            openStandardCommentsModal={() =>
              openStandardCommentsModal({
                measureId: measure?.value,
                measureTypeId: measureType?.value,
              })
            }
            richTextProps={{
              isDisabled: isDisabled,
              onBlur: async () => {
                if (text !== props.comment) {
                  await onSubmitWithParams({ comment: text || '' });
                }
              },
              onChange: async (value = '') => {
                setText(value);
                await debouncedUpdateCompanyChangingComment({ comment: value });
              },
              value: text,
            }}
            uploadAttachment={uploadAttachment}
            withStandardComments
          />
          <AttachmentsToInstantRemove {...attachmentsToRemoveProps} isDisabled={isDisabled} />
        </StyledChecklistItemContent>
      )}
    </StyledChecklistItem>
  );
};
