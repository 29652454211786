import React, { Component, FC, ReactElement } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';

const reorder = (list: string[], startIndex: number, endIndex: number): string[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface Item {
  content: ReactElement | FC | Function;
  id: string;
}

interface DragDropPropTypes {
  items: Item[];
  onChange?: (items: string[]) => void;
}

export class DragDrop extends Component<DragDropPropTypes, { items: string[] }> {
  state = {
    items: this.props.items.map((x) => x.id),
  };

  onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(this.state.items, result.source.index, result.destination.index);
    const { onChange } = this.props;
    if (onChange) {
      onChange(items);
    }

    this.setState({
      items,
    });
  };

  _renderItem = (item: string, index: number): ReactElement => (
    <Draggable draggableId={item} index={index} key={item}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
        const ItemComponent = this.props.items.find((x) => x.id === item)?.content;

        const compProps = { isDragging: snapshot.isDragging } as React.Props<{
          isDragging: boolean;
        }>;
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={provided.draggableProps.style}
          >
            {typeof ItemComponent === 'function' ? <ItemComponent {...compProps} /> : ItemComponent}
          </div>
        );
      }}
    </Draggable>
  );

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided: DroppableProvided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={{ padding: '0.8rem' }}>
              {this.state.items.map(this._renderItem)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}
