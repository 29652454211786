import React from 'react';
import { useSelector } from 'react-redux';

import { GetWorkplaceResponse } from 'ducks/powerSearch/workplace/types';
import { useTranslation } from 'react-i18next';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useAppDispatch } from 'store';
import {
  createNewVisitCompanyVisit,
  newVisitAddressSelector,
  setWorkplaceIdToVisit,
  visitDateSelector,
  visitParamsSelector,
} from 'ducks/newVisit';

import { ReactComponent as EditIcon } from 'images/editIcon.svg';
import { Margin } from 'styles/utils';
import { StyledWorkplaceSaveCancelButtons } from 'containers/newVisit/OrdinaryVisit/styles';
import { allFilteredDistrictsSelector } from 'ducks/powerSearch/selectors';
import { generateVisit } from './generateVisit';

interface WorkplaceChosenButtonsProps {
  isEdit: boolean;
  isPrimaryDisabled: boolean;
  noChoosing?: boolean;
  setHaveOwner: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  workplace: GetWorkplaceResponse;
}

export const WorkplaceChosenButtons = (props: WorkplaceChosenButtonsProps) => {
  const { isEdit, isPrimaryDisabled, setIsEdit, workplace, setHaveOwner } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const chosenAddress = useSelector(newVisitAddressSelector);
  const { light, type } = useSelector(visitParamsSelector);
  const visitDate = useSelector(visitDateSelector);
  const allDistricts = useSelector(allFilteredDistrictsSelector).map((district) => district.value);

  const canEdit = workplace.actions.some(({ type }) => type === 'edit');

  if (props.noChoosing ? false : !chosenAddress)
    return (
      <Margin left='auto'>
        <DefaultButton
          capitalize
          label={t('common.save')}
          onClick={async () => {
            const visitId = await generateVisit(light, type, visitDate, allDistricts);
            await dispatch(
              setWorkplaceIdToVisit({
                workplaceId: workplace.workplaceId,
                visitId,
              }),
            );
            const company_id = sessionStorage.getItem('automaticallyAddCompanyToVisit');
            company_id && dispatch(createNewVisitCompanyVisit({ company_id, visit_id: visitId }));
          }}
          size='small'
          type='button'
          variant='success'
        />
      </Margin>
    );

  return (
    <Margin top='1.2rem'>
      {isEdit ? (
        <StyledWorkplaceSaveCancelButtons>
          <DefaultButton
            capitalize
            label={t('common.cancel')}
            onClick={() => {
              setIsEdit(false);
              setHaveOwner(!!workplace.proprietorshipId);
            }}
            size='small'
            type='button'
            variant='secondary'
          />
          <DefaultButton
            capitalize
            form='workplace-form'
            isDisabled={isPrimaryDisabled}
            label={t('common.save')}
            size='small'
            type='submit'
            variant='primary'
          />
        </StyledWorkplaceSaveCancelButtons>
      ) : (
        <Margin left='auto'>
          <DefaultButton
            capitalize
            icon={<EditIcon />}
            isDisabled={!canEdit}
            label={t('common.edit')}
            onClick={() => setIsEdit(true)}
            size='small'
            type='button'
            variant='tertiary'
          />
        </Margin>
      )}
    </Margin>
  );
};
