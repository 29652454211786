import React from 'react';

import { SearchPriorNotificationsEntity } from 'ducks/powerSearch/priorNotifications/types';
import {
  StyledNoWrapCellContent,
  StyledTruncatedContent,
  TrueFalse,
} from 'components/Table/TableRow/styles';
import { formatDate } from 'utils/date';
import { Tooltip } from 'components/Tooltip/Tooltip';

export const priorNotificationsTableMapper = {
  start_date: {
    component: ({ startDate }: SearchPriorNotificationsEntity) => (
      <StyledNoWrapCellContent>{startDate ? formatDate(startDate) : '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  reporting_company_name: {
    component: ({ reportingCompanyName }: SearchPriorNotificationsEntity) => (
      <Tooltip label={reportingCompanyName?.length > 24 ? reportingCompanyName : ''}>
        <StyledTruncatedContent>{reportingCompanyName}</StyledTruncatedContent>
      </Tooltip>
    ),
    size: 'min-content',
  },
  project_type: {
    component: ({ projectType }: SearchPriorNotificationsEntity) => (
      <Tooltip label={projectType?.length > 24 ? projectType : ''}>
        <StyledTruncatedContent>{projectType}</StyledTruncatedContent>
      </Tooltip>
    ),
    size: 'minmax(24rem, max-content)',
  },
  workplace_address: {
    component: ({ workplaceAddress }: SearchPriorNotificationsEntity) => (
      <StyledNoWrapCellContent>{workplaceAddress || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  postal_code: {
    component: ({ postalCode }: SearchPriorNotificationsEntity) => (
      <StyledNoWrapCellContent>{postalCode || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  postal_area: {
    component: ({ postalArea }: SearchPriorNotificationsEntity) => (
      <StyledNoWrapCellContent>{postalArea || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  municipality_name: {
    component: ({ municipalityName }: SearchPriorNotificationsEntity) => (
      <StyledNoWrapCellContent>{municipalityName || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  constructor_name: {
    component: ({ constructorName }: SearchPriorNotificationsEntity) => (
      <StyledNoWrapCellContent>{constructorName || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  constructor_org_no: {
    component: ({ constructorOrgNo }: SearchPriorNotificationsEntity) => (
      <StyledNoWrapCellContent>{constructorOrgNo || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  organisation_contact: {
    component: ({ organisationContact }: SearchPriorNotificationsEntity) => (
      <StyledNoWrapCellContent>{organisationContact || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  organisation_contact_phone: {
    component: ({ organisationContactPhone }: SearchPriorNotificationsEntity) => (
      <StyledNoWrapCellContent>{organisationContactPhone || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  organisation_representative: {
    component: ({ organisationRepresentative }: SearchPriorNotificationsEntity) => (
      <StyledNoWrapCellContent>{organisationRepresentative || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  constructor_representative_org_no: {
    component: ({ constructorRepresentativeOrgNo }: SearchPriorNotificationsEntity) => (
      <StyledNoWrapCellContent>{constructorRepresentativeOrgNo || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  representative_contact_person: {
    component: ({ representativeContactPerson }: SearchPriorNotificationsEntity) => (
      <StyledNoWrapCellContent>{representativeContactPerson || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  representative_contact_phone: {
    component: ({ representativeContactPhone }: SearchPriorNotificationsEntity) => (
      <StyledNoWrapCellContent>{representativeContactPhone || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  hms_plan: {
    component: ({ hmsPlan }: SearchPriorNotificationsEntity) => <TrueFalse val={hmsPlan} />,
    size: 'min-content',
  },
  end_date: {
    component: ({ endDate }: SearchPriorNotificationsEntity) => (
      <StyledNoWrapCellContent>{endDate ? formatDate(endDate) : '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  expected_number_of_companies: {
    component: ({ expectedNumberOfCompanies }: SearchPriorNotificationsEntity) => (
      <StyledNoWrapCellContent>{expectedNumberOfCompanies || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  expected_number_of_employees: {
    component: ({ expectedNumberOfEmployees }: SearchPriorNotificationsEntity) => (
      <StyledNoWrapCellContent>{expectedNumberOfEmployees || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  project_manager_name: {
    component: ({ projectManagerName }: SearchPriorNotificationsEntity) => (
      <StyledNoWrapCellContent>{projectManagerName || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  comment: {
    component: ({ comment }: SearchPriorNotificationsEntity) => (
      <Tooltip label={comment && comment.length > 24 ? comment : ''}>
        <StyledTruncatedContent>{comment}</StyledTruncatedContent>
      </Tooltip>
    ),
    size: 'min-content',
  },
  district_names: {
    component: ({ districtNames }: SearchPriorNotificationsEntity) =>
      districtNames?.length > 1 ? (
        <Tooltip label={districtNames.join(', ')}>
          <StyledNoWrapCellContent>
            {districtNames[0]} + {districtNames.length - 1}
          </StyledNoWrapCellContent>
        </Tooltip>
      ) : (
        <StyledNoWrapCellContent>
          {!districtNames || districtNames.length === 0 ? '-' : districtNames[0]}
        </StyledNoWrapCellContent>
      ),
    size: 'max-content',
  },
  created_at: {
    component: ({ createdAt }: SearchPriorNotificationsEntity) => (
      <StyledNoWrapCellContent>{createdAt ? formatDate(createdAt) : '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  external_reference_id: {
    component: ({ externalReferenceId }: SearchPriorNotificationsEntity) => (
      <StyledNoWrapCellContent>{externalReferenceId || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
};

export type WorkplaceTableMapper = typeof priorNotificationsTableMapper;
