import React, {
  ComponentType,
  ReactElement,
  SVGProps,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { TableRow } from 'components/Table/TableRow/TableRow';
import { TableHeader } from 'components/Table/TableHeader/TableHeader';
import { StyledGrid, StyledGridScrollContainer, TableGridShading } from './styles';
import { Shading } from 'components/Shading/Shading';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { Margin } from 'styles/utils';
import { ActionsHandlers, TableComponentsMap, TableEntity } from 'components/Table/types';

const ACTIONS_WIDTH = `auto`;
const FIREFOX_HACK = '0.3px';

interface SimpleTableGridProps<T, K> {
  actionHandlers?: ActionsHandlers;
  bodyData: Array<K>;
  columnsOrder: (keyof T)[];
  componentsMap: T;
  CustomToggleDropdownIcon?: ComponentType<SVGProps<SVGSVGElement> & { isOpen?: boolean }>;
  DropdownRowComponent?: ComponentType<K>;
  expandableButtonTooltips?: { opened?: string; closed?: string };
  isExpandable?: boolean;
  noResultsContent?: ReactElement;
  noResultsMessage?: string;
}

export const SimpleTableGrid = <T extends TableComponentsMap, K extends TableEntity>({
  actionHandlers,
  bodyData = [],
  columnsOrder,
  componentsMap,
  CustomToggleDropdownIcon,
  DropdownRowComponent,
  expandableButtonTooltips,
  isExpandable = false,
  noResultsContent,
  noResultsMessage,
}: SimpleTableGridProps<T, K>) => {
  const [shading, setShading] = useState({ showLeft: false, showRight: false });
  const gridRef = useRef<HTMLDivElement | null>(null);
  const withActions = bodyData.some((el) => !!el.actions);

  const calculateShading = () => {
    if (!gridRef.current) return;
    const {
      current: { scrollLeft, scrollWidth, offsetWidth },
    } = gridRef;
    const showLeft = scrollLeft > 0;
    const showRight = scrollLeft + offsetWidth < scrollWidth;
    if (showLeft !== shading.showLeft || showRight !== shading.showRight) {
      setShading({ showLeft, showRight });
    }
  };

  useEffect(calculateShading, [gridRef]);

  const templateColumns = useMemo((): string => {
    let cols = columnsOrder.reduce<string>(
      (acc, currentValue) => `${acc} ${componentsMap[currentValue].size}`,
      '',
    );
    if (withActions || isExpandable) cols += ` ${ACTIONS_WIDTH} ${FIREFOX_HACK}`;

    return cols;
  }, [componentsMap, withActions, isExpandable]);

  if (bodyData.length === 0)
    return (
      <Margin margin='0.8rem 0' right='auto'>
        <InfoBox content={noResultsContent} message={noResultsMessage} />
      </Margin>
    );

  return (
    <TableGridShading>
      <Shading rightLarge {...shading}>
        <StyledGridScrollContainer onScroll={calculateShading} ref={gridRef}>
          <StyledGrid templateColumns={templateColumns}>
            <TableHeader
              actionHandlers={{}}
              allColumns={columnsOrder}
              columnsOrder={columnsOrder}
              columnsVisibility={columnsOrder}
              componentsMap={componentsMap}
              isExpandable={isExpandable}
              noConfig
              withActions={withActions || isExpandable}
            />
            {bodyData.map((entity, i) => (
              <TableRow
                actionHandlers={actionHandlers}
                columnsOrder={columnsOrder as string[]}
                componentsMap={componentsMap}
                CustomToggleDropdownIcon={CustomToggleDropdownIcon}
                DropdownRowComponent={DropdownRowComponent}
                entity={entity}
                expandableButtonTooltips={expandableButtonTooltips}
                isExpandable={isExpandable}
                key={i}
                withActions={withActions}
                withCheckbox={false}
              />
            ))}
          </StyledGrid>
        </StyledGridScrollContainer>
      </Shading>
    </TableGridShading>
  );
};
