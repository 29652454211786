import { createSlice } from '@reduxjs/toolkit';

import { sortCompanyAddresses } from 'utils/helpers';
import { getCompany, updateCompany } from './actions';
import { CompanyEntity } from './types';

const companiesSlice = createSlice({
  name: 'companies',
  initialState: null as CompanyEntity | null,
  reducers: {
    resetCompanies: () => null,
  },
  extraReducers: (builder) => {
    builder.addCase(getCompany.fulfilled, (state, { payload }) => {
      return sortCompanyAddresses(payload);
    });
    builder.addCase(updateCompany.fulfilled, (state, { payload }) => {
      return sortCompanyAddresses(payload);
    });
  },
});

export const { reducer: companiesReducer, actions } = companiesSlice;
export const { resetCompanies } = actions;
