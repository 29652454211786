import { API } from 'api';
import { AxiosError, CancelTokenSource } from 'axios';
import { navigate } from '@reach/router';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { GetWorkplaceResponse } from 'ducks/powerSearch/workplace/types';
import {
  AddNewVisitContactParams,
  CompanyVisitResponse,
  CreateNewVisitCompanyVisit,
  CreateObservationParams,
  CreateSameAsCompanyVisitParams,
  CreateVisitWithOneTimeAddress,
  DeleteCompanyVisitAttachmentParams,
  DeleteObservationAttachmentParams,
  DeleteObservationParams,
  NewVisitContact,
  NewVisitParams,
  NewVisitResponse,
  ObservationType,
  RemoveContactParamsBase,
  SetContactParams,
  SubmitNewVisitCopyParams,
  SubmitVisitDialogParams,
  SubmitVisitDialogResponse,
  SubmitVisitReportParams,
  TipsATPropsResponse,
  UpdateCompanyVisitStatusParams,
  UpdateNewVisitCompanyVisit,
  UpdateObservationParams,
  UpdateVisitParams,
} from 'ducks/newVisit/types';
import { CompanyEntity, UpdateCompanyParams } from 'ducks/companies/types';
import { WorkplaceFormSubmitValues } from 'containers/newVisit/shared/WorkplaceForm';
import { SearchCompanyResponse } from 'ducks/powerSearch/company/types';
import { SearchCompanyParams } from 'ducks/powerSearch/types';
import { contactOriginToModel } from 'utils/helpers';
import { StandardComment } from 'ducks/constants/types';
import { decamelizeKeys } from 'humps';
import { Attachment } from 'ducks/events/types';

// ---------- step 0 ----------

export const setNewVisitDateAndId = createAsyncThunk(
  'newVisit/setNewVisitDateAndId',
  async (params: NewVisitParams) => {
    const { callback, ...attributes } = params;
    const response = await API.post<NewVisitResponse>(`new_visits/visits`, {
      data: { attributes },
    });
    callback && callback(response.data.id);

    return response.data;
  },
);

export const getVisitById = createAsyncThunk(
  'newVisit/getVisitById',
  async (params: {
    id: string;
    withoutResetActiveCompanyVisit?: boolean | null;
    token?: CancelTokenSource;
  }) => {
    const response = await API.get<NewVisitResponse>(`new_visits/visits/${params.id}`, {
      cancelToken: params?.token?.token,
    });
    return response.data;
  },
);

export const setNewVisitDate = createAsyncThunk(
  'newVisit/setNewVisitDate',
  async (params: UpdateVisitParams) => {
    const { visitId, ...attributes } = params;
    const response = await API.put<NewVisitResponse>(`new_visits/visits/${visitId}`, {
      data: { attributes },
    });

    return response.data;
  },
);

// ---------- step 1 ----------

export const setWorkplaceIdToVisit = createAsyncThunk(
  'newVisit/setWorkplaceIdToVisit',
  async (params: UpdateVisitParams) => {
    const { visitId, ...attributes } = params;
    const response = await API.put<NewVisitResponse>(`new_visits/visits/${visitId}`, {
      data: { attributes },
    });
    return response.data;
  },
);

export const createNewVisitWorkplace = createAsyncThunk(
  'newVisit/createNewVisitWorkplace',
  async (attributes: WorkplaceFormSubmitValues) => {
    const response = await API.post<GetWorkplaceResponse>(`workplaces`, {
      data: { attributes },
    });
    return response.data;
  },
);

export const editNewVisitWorkplace = createAsyncThunk(
  'newVisit/editNewVisitWorkplace',
  async ({ id, ...attributes }: WorkplaceFormSubmitValues & { id: string | number }) => {
    const response = await API.put<GetWorkplaceResponse>(`workplaces/${id}`, {
      data: { attributes },
    });
    return response.data;
  },
);

export const updateCompany = async (params: UpdateCompanyParams) => {
  const { companyId, ...attributes } = params;
  const response = await API.patch<CompanyEntity>(`companies/${companyId}`, { ...attributes });
  return response.data;
};

// ---------- workplace contacts ----------

export const createStep2Contact = createAsyncThunk(
  'newVisit/createStep2Contact',
  async (attributes: AddNewVisitContactParams & { workplace_id: string }) => {
    const { origin } = attributes;

    const params = {
      ...attributes,
      duck_as: contactOriginToModel['workplace'],
      contact_class: contactOriginToModel[origin],
    };

    const response = await API.post<NewVisitContact>(`new_visits/contacts`, {
      data: { attributes: params },
    });

    return response.data;
  },
);

export const setStep2Contact = createAsyncThunk(
  'newVisit/setStep2Contact',
  async (attributes: SetContactParams & { workplace_id: string }) => {
    const { origin, id } = attributes;

    const params = {
      ...attributes,
      duck_as: contactOriginToModel['workplace'],
      contact_class: contactOriginToModel[origin],
    };

    const response = await API.put<NewVisitContact>(`new_visits/contacts/${id}`, {
      data: { attributes: params },
    });

    return response.data;
  },
);

export const removeStep2Contact = createAsyncThunk(
  'newVisit/removeStep2Contact',
  async (attributes: RemoveContactParamsBase & { workplace_id: string }) => {
    const { origin, id } = attributes;

    const params = {
      ...attributes,
      duck_as: contactOriginToModel['workplace'],
      contact_class: contactOriginToModel[origin],
    };

    const response = await API.delete(`new_visits/contacts/${id}`, {
      data: { data: { attributes: params } },
    });

    return response.data;
  },
);

// ---------- companyVisit contacts ----------

export const createStep3Contact = createAsyncThunk(
  'newVisit/createStep3Contact',
  async (attributes: AddNewVisitContactParams & { company_visit_id: string }) => {
    const { origin } = attributes;

    const params = {
      ...attributes,
      duck_as: contactOriginToModel['visit'],
      contact_class: contactOriginToModel[origin],
      workplace_id: undefined,
    };

    const response = await API.post<NewVisitContact>(`new_visits/contacts`, {
      data: { attributes: params },
    });

    return response.data;
  },
);

export const setStep3Contact = createAsyncThunk(
  'newVisit/setStep3Contact',
  async (attributes: SetContactParams & { company_visit_id: string }) => {
    const { origin, id } = attributes;

    const params = {
      ...attributes,
      duck_as: contactOriginToModel['visit'],
      contact_class: contactOriginToModel[origin],
      workplace_id: undefined,
    };

    const response = await API.put<NewVisitContact>(`new_visits/contacts/${id}`, {
      data: { attributes: params },
    });
    return response.data;
  },
);

export const removeStep3Contact = createAsyncThunk(
  'newVisit/removeStep3Contact',
  async (attributes: RemoveContactParamsBase & { company_visit_id: string }) => {
    const { origin, id } = attributes;

    const params = {
      ...attributes,
      duck_as: contactOriginToModel['visit'],
      contact_class: contactOriginToModel[origin],
      workplace_id: undefined,
    };

    const response = await API.delete(`new_visits/contacts/${id}`, {
      data: { data: { attributes: params } },
    });

    return response.data;
  },
);

// ---------- company visit ----------

export const searchForCompaniesNewVisit = async (
  { ...userParams }: SearchCompanyParams,
  token: CancelTokenSource,
) => {
  const params = {
    ...userParams,
    ...userParams.company,
  };

  const response = await API.get<SearchCompanyResponse>(`power/companies`, {
    params: { query: params.query, ...params.company, page_size: params.page_size },
    cancelToken: token.token,
  });
  return response.data;
};

export const createNewVisitCompanyVisit = createAsyncThunk(
  'newVisit/createNewVisitCompanyVisit',
  async (attributes: CreateNewVisitCompanyVisit) => {
    const response = await API.post<CompanyVisitResponse>(`new_visits/company_visits`, {
      data: { attributes },
    });

    return response.data;
  },
);

export const createSameAsCompanyVisit = createAsyncThunk(
  'newVisit/createSameAsCompanyVisit',
  async (params: CreateSameAsCompanyVisitParams) => {
    const { address, visit_id, company_id } = params;

    await API.post<CompanyVisitResponse>(`new_visits/company_visits`, {
      data: { attributes: { visit_id, company_id } },
    });
    const response = await API.put<NewVisitResponse>(`new_visits/visits/${visit_id}`, {
      data: { attributes: { same_as_company_address_id: address.id } },
    });

    return response.data;
  },
);

export const updateSameAsCompanyVisit = createAsyncThunk(
  'newVisit/updateSameAsCompanyVisit',
  async (params: Omit<CreateSameAsCompanyVisitParams, 'company_id'>) => {
    const { address, visit_id } = params;
    const response = await API.put<NewVisitResponse>(`new_visits/visits/${visit_id}`, {
      data: { attributes: { same_as_company_address_id: address.id } },
    });
    return response.data;
  },
);

export const createVisitWithOneTimeAddress = createAsyncThunk(
  'newVisit/createVisitWithOneTimeAddress',
  async (params: CreateVisitWithOneTimeAddress) => {
    const { address, visit_id, company_id } = params;

    await API.post<CompanyVisitResponse>(`new_visits/company_visits`, {
      data: { attributes: { visit_id, company_id } },
    });
    const response = await API.put<NewVisitResponse>(`new_visits/visits/${visit_id}`, {
      data: {
        attributes: {
          postal_area_id: address.postalAreaId,
          street: address.street,
          street_number: address.streetNumber,
        },
      },
    });

    return response.data;
  },
);

export const updateVisitWithOneTimeAddress = createAsyncThunk(
  'newVisit/updateVisitWithOneTimeAddress',
  async (params: Omit<CreateVisitWithOneTimeAddress, 'company_id'>) => {
    const { address, visit_id } = params;
    const response = await API.put<NewVisitResponse>(`new_visits/visits/${visit_id}`, {
      data: {
        attributes: {
          postal_area_id: address.postalAreaId,
          street: address.street,
          street_number: address.streetNumber,
        },
      },
    });
    return response.data;
  },
);

export const updateCompanyVisit = createAsyncThunk(
  'newVisit/updateCompanyVisit',
  async (params: UpdateNewVisitCompanyVisit) => {
    const { companyVisitId, token, without, ...attributes } = params;

    const response = await API.put<CompanyVisitResponse>(
      `new_visits/company_visits/${companyVisitId}`,
      { data: { attributes } },
      { cancelToken: token?.token },
    );

    return response.data;
  },
);

export const updateCompanyVisitTips = createAsyncThunk(
  'newVisit/updateCompanyVisitTips',
  async (attributes: TipsATPropsResponse) => {
    const response = await API.post<CompanyVisitResponse>(`new_visits/tips`, {
      data: { attributes },
    });

    return response.data;
  },
);

export const uploadCompanyVisitAttachment = async (params: FormData) => {
  const response = await API.post<Attachment>(`new_visits/company_visit_attachments`, params, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
};

export const deleteCompanyVisitAttachment = async ({ id }: DeleteCompanyVisitAttachmentParams) => {
  const response = await API.delete<void>(`new_visits/company_visit_attachments/${id}`);

  return response.data;
};

export const reduxDeleteCompanyVisitAttachment = createAsyncThunk(
  'newVisit/reduxDeleteCompanyVisitAttachment',
  async ({ id }: DeleteCompanyVisitAttachmentParams) => {
    const response = await API.delete<void>(`new_visits/company_visit_attachments/${id}`);

    return response.data;
  },
);

export const deleteCompanyVisit = createAsyncThunk(
  'newVisit/deleteCompanyVisit',
  async (id: string) => {
    return await API.delete<void>(`new_visits/company_visits/${id}`);
  },
);

export const updateCompanyVisitStatus = createAsyncThunk(
  'newVisit/updateCompanyVisitStatus',
  async (params: UpdateCompanyVisitStatusParams) => {
    const { id, newStatus } = params;
    const response = await API.put<CompanyVisitResponse>(
      `new_visits/company_visit_statuses/${id}`,
      {
        data: { attributes: { newStatus } },
      },
    );
    return response.data;
  },
);

// ---------- observations  ----------

export const createObservation = createAsyncThunk(
  'newVisit/createObservation',
  async (attributes: CreateObservationParams) => {
    const response = await API.post<ObservationType>(`new_visits/observations`, {
      data: { attributes },
    });

    return response.data;
  },
);

export const updateObservation = createAsyncThunk(
  'newVisit/updateObservation',
  async (params: UpdateObservationParams) => {
    const { id, token, ...attributes } = params;
    const response = await API.put<ObservationType>(
      `new_visits/observations/${params.id}`,
      {
        data: { attributes },
      },
      { cancelToken: token?.token },
    );

    return response.data;
  },
);

export const deleteObservation = createAsyncThunk(
  'newVisit/deleteObservation',
  async ({ id }: DeleteObservationParams) => {
    const response = await API.delete<ObservationType>(`new_visits/observations/${id}`);

    return response.data;
  },
);

export const getLinkedStandardComments = async (params: {
  checkpointId?: string;
  measureTypeId?: string;
  measureId?: string;
}) => {
  const response = await API.get<StandardComment[]>(`new_visits/standard_comments`, {
    params: decamelizeKeys(params),
  });
  return response.data;
};

export const uploadObservationAttachment = async (
  params: FormData,
  callback?: (id: Attachment['id']) => void,
) => {
  const response = await API.post<Attachment>(`new_visits/observation_attachments`, params, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  if (callback) {
    callback(response?.data?.id);
  }

  return response.data;
};

export const deleteObservationAttachment = async (id: string | number, callback?: () => void) => {
  const response = await API.delete<void>(`new_visits/observation_attachments/${id}`);

  if (callback) {
    callback();
  }

  return response.data;
};

export const reduxDeleteObservationAttachment = createAsyncThunk(
  'newVisit/reduxDeleteObservationAttachment',
  async ({ id }: DeleteObservationAttachmentParams) => {
    const response = await API.delete<void>(`new_visits/observation_attachments/${id}`);

    return response.data;
  },
);

export const submitVisitReport = async (attributes: SubmitVisitReportParams) => {
  try {
    const response = await API.post<void>(`new_visits/visit_reports`, { data: { attributes } });
    return response.data;
  } catch (e) {
    return (e as AxiosError).response?.data;
  }
};

export const createVisitDialogPath = createAsyncThunk(
  'newVisit/createDialogPath',
  async (attributes: SubmitVisitDialogParams, { rejectWithValue }) => {
    try {
      const response = await API.post(`new_visits/dialog`, {
        data: { attributes },
      });
      return response.data as SubmitVisitDialogResponse;
    } catch (e) {
      return rejectWithValue((e as AxiosError).response?.data);
    }
  },
);

export const createNewVisitCopy = createAsyncThunk(
  'newVisit/createNewVisitCopy',
  async (attributes: SubmitNewVisitCopyParams) => {
    const response = await API.post(`new_visits/copy_visits`, {
      data: { attributes },
    });

    return response.data;
  },
);

// ---------- delete visit  ----------

export const deleteVisit = createAsyncThunk(
  'newVisit/deleteVisit',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await API.delete(`new_visits/visits/${id}`);
      navigate('/redesigned/dashboard');
      return response.data;
    } catch (e) {
      return rejectWithValue((e as AxiosError).response?.data);
    }
  },
);
