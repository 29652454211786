import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  StyledDeleteIcon,
  StyledFileName,
  StyledFilesHeader,
  StyledFileSize,
  StyledFilesListItem,
} from 'components/Forms/Attachments/styles';
import { formatFileSize } from 'utils/helpers';

interface DocumentFile {
  id?: number | string;
  name: string;
  size: number;
  type: string;
}

interface DocumentsProps {
  files: DocumentFile[];
  deleteFile(file: DocumentFile): void;
  isDisabled?: boolean;
}

export const Documents = ({
  deleteFile,
  files,
  isDisabled,
}: DocumentsProps): JSX.Element | null => {
  const [isProcessing, setIsProcessing] = React.useState(false);
  const { t } = useTranslation();

  const documents = files
    .filter((file) => file.type.includes('application') || file.type.includes('text'))
    .map((file) => (
      <StyledFilesListItem key={file.name}>
        <StyledFileName isRejected={isProcessing}>{file.name}</StyledFileName>
        <StyledFileSize>{formatFileSize(file.size)} </StyledFileSize>
        {!isDisabled && (
          <StyledDeleteIcon
            onClick={() => {
              setIsProcessing(true);
              deleteFile(file);
            }}
          />
        )}
      </StyledFilesListItem>
    ));

  if (documents.length === 0) return null;
  return (
    <>
      <StyledFilesHeader>{t('forms.documents')}</StyledFilesHeader>
      <ul>{documents}</ul>
    </>
  );
};
