import styled from 'styled-components/macro';

import { StyledDropdownContainer } from 'components/PopperDropdown/styles';
import { StyledWrapper } from 'components/TextInput/styles';

import { ReactComponent as SearchIcon } from 'images/searchIcon.svg';

export const SearchBarWrapper = styled.div`
  align-items: center;
  display: flex;
  position: relative;

  ${StyledWrapper} {
    border: none;
    flex: 1 1;
  }
`;

export const StyledSearchBarDropdown = styled(StyledDropdownContainer)`
  top: 100%;
  width: 100%;
`;

export const StyledSearchBarMobile = styled(StyledDropdownContainer)`
  box-shadow: ${({ theme }) => theme.boxShadows.coolGrey400};
  left: 50%;
  padding: ${({ theme }) => theme.sizes.xxs};
  top: 8rem;
  transform: translateX(-50%);
  width: calc(100% - 4rem);
`;

export const StyledSearchbarRow = styled.button`
  align-items: center;
  background-color: white;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black100};
  display: flex;
  padding: ${({ theme }) => `${theme.sizes.xs} ${theme.sizes.sm}`};
  user-select: none;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.black50};
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  ${({ theme }) => theme.icon.md};
  margin: ${({ theme }) => theme.sizes.xs};
`;
