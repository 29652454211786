import { createSlice } from '@reduxjs/toolkit';
import { WorkplaceEntity } from 'ducks/powerSearch/workplace/types';
import { getWorkplace, getWorkplaceKinds } from 'ducks/workplaces/actions';

import { WorkplacesState } from './types';

const initialState: WorkplacesState | null = {
  workplaceKinds: [],
  workplace: null as WorkplaceEntity | null,
};

const workplaceSlice = createSlice({
  name: 'workplaces',
  initialState,
  reducers: {
    resetWorkplaces: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getWorkplace.fulfilled, (state, { payload }) => {
      state.workplace = payload;
    });
    builder.addCase(getWorkplaceKinds.fulfilled, (state, { payload }) => {
      const amendedWorkplaceKinds = payload.map(({ name }) => ({ label: name, value: name }));
      state.workplaceKinds = amendedWorkplaceKinds;
    });
  },
});

export const { reducer: workplacesReducer, actions } = workplaceSlice;
export const { resetWorkplaces } = actions;
