import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TableGrid } from 'components/Table/TableGrid/TableGrid';
import { ContactsSearchSelect } from 'containers/newVisit/shared/ContactsSearchSelect';
import { useAppDispatch } from 'store';
import {
  createStep3Contact,
  isNewVisitFollowUp,
  isNewVisitWithEditingAccess,
  newVisitCompanyVisitSelector,
  NewVisitContact,
  removeStep3Contact,
  setStep3Contact,
  visitIdSelector,
} from 'ducks/newVisit';
import { contactsComponentsMapStep3 } from 'containers/newVisit/shared/tableMappers/contacts';
import { useModal } from 'components/Modal/Modal';
import { NewVisitContactModal } from 'dialogs/newVisits/Contacts/NewVisitContactModal';
import { CollapsibleSection } from 'components/CollapsibleSection/CollapsibleSection';
import { ContentContainer } from 'styles/utils';
import { isNewVisitFinished } from 'ducks/newVisit';

interface CompanyContactsSectionProps {
  companyVisitId: string;
}

export const CompanyContactsSection = ({ companyVisitId }: CompanyContactsSectionProps) => {
  const companyVisit = useSelector(newVisitCompanyVisitSelector(companyVisitId))!;
  const isVisitFinished = useSelector(isNewVisitFinished(companyVisitId));
  const isVisitFollowUp = useSelector(isNewVisitFollowUp(companyVisitId));
  const isVisitCanEdit = useSelector(isNewVisitWithEditingAccess(companyVisitId));
  const isDisabled = !isVisitCanEdit || (isVisitCanEdit && (isVisitFinished || isVisitFollowUp));

  const visitId = useSelector(visitIdSelector);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showModal, setModalComponent, setShowHeader } = useModal();

  const { companyId, contacts } = companyVisit;

  const createContact = useCallback(() => {
    setModalComponent(
      <NewVisitContactModal
        autosetReciveVisitButtonToTrue
        canConnectTo={['company', 'visit']}
        defaultConectTo='company'
        showInReportDefaultTrue
        submitFn={(values, connectTo) => {
          dispatch(
            createStep3Contact({
              company_id: connectTo === 'company' ? companyId : undefined,
              company_visit_id: companyVisitId,
              email: values.email,
              include_in_report: values.include_in_report,
              name: values.name,
              obligatory_training: values.obligatory_training,
              origin: connectTo,
              phone: values.phone,
              receive_visit_report: values.receive_visit_report,
              role_id: values.role?.value,
              visit_id: visitId,
              comment: values.comment,
            }),
          );
        }}
      />,
    );
    setShowHeader(false);
    showModal();
  }, [setModalComponent, showModal, companyVisitId, companyId, visitId]);

  const contactsActionHandlers = {
    delete_from_visit: ({ id, origin }: NewVisitContact) =>
      dispatch(
        removeStep3Contact({
          company_visit_id: companyVisitId,
          id,
          visit_id: visitId.toString(),
          origin,
        }),
      ),
    edit: (item: NewVisitContact) => {
      setModalComponent(
        <NewVisitContactModal
          canConnectTo={[item.origin]}
          contact={item}
          defaultConectTo={item.origin}
          submitFn={(values) =>
            dispatch(
              setStep3Contact({
                ...values,
                company_visit_id: companyVisitId,
                id: item.id,
                origin: item.origin,
                role_id: values.role?.value,
                visit_id: visitId.toString(),
              }),
            )
          }
        />,
      );
      setShowHeader(false);
      showModal();
    },
  };

  return (
    <CollapsibleSection
      header={t('breadcrumb.contacts')}
      name={`isContactsSectionOpen-${companyVisitId}`}
      testid='company-contacts-section'
    >
      {contacts?.length > 0 && (
        <TableGrid
          actionHandlers={contactsActionHandlers}
          bodyData={contacts}
          columnsOrder={[
            'name',
            'phone',
            'role',
            'email',
            'obligatory_training',
            'show_in_report',
            'receive_visit_report',
            'comment',
          ]}
          componentsMap={contactsComponentsMapStep3}
          isDisabled={isDisabled}
          noConfig
        />
      )}
      <ContentContainer margin='0' maxWidth='64rem'>
        <ContactsSearchSelect
          companyId={companyId}
          isDisabled={isDisabled}
          key={companyVisitId}
          newContactHandler={createContact}
          onSelect={({ id, origin, email }) =>
            dispatch(
              setStep3Contact({
                company_visit_id: companyVisitId.toString(),
                id,
                include_in_report: true,
                origin,
                receive_visit_report: !!email,
                visit_id: visitId.toString(),
              }),
            )
          }
          searchWithin={['company', 'visit']}
          vsize='small'
        />
      </ContentContainer>
    </CollapsibleSection>
  );
};
