import { BreadcrumbContainer } from 'components/Breadcrumb/styles';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import styled from 'styled-components/macro';

export const StyledNewVisitModalHeader = styled.div`
  ${({ theme }) => theme.fontSizes.xxl};
  font-weight: 600;
  margin-bottom: ${({ theme }) => theme.sizes.md};
  margin-top: ${({ theme }) => theme.sizes.md};
`;

// ---- choose visit type modal ----

interface StyledDateAndTypeContainerPropTypes {
  direction: 'column' | 'row';
  mWidth: 'none' | '25vh';
}

export const StyledDateAndTypeContainer = styled.div<StyledDateAndTypeContainerPropTypes>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: space-between;
  & > div :first-child {
    max-width: 60vh;
    overflow-x: auto;
  }
  @media (min-width: 768px) {
    & > div :first-child {
      max-width: ${({ mWidth }) => mWidth};
      overflow-x: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const StyledChooseVisitForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 72rem;
`;

// ---- bottom buttons panel ----

export const ButtonsPanelWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black50};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: ${({ theme }) => theme.sizes.xxs};
  position: fixed;
  right: 0;
  z-index: 101;

  & > button:not(:last-child) {
    margin-right: ${({ theme }) => theme.sizes.md};
  }
`;

// ---- navbar styles ----

export const StyledNewVisitNavbar = styled.div`
  background-color: white;
  box-shadow: ${({ theme }) => theme.boxShadows.coolGrey200};
  margin-top: calc(${({ theme }) => theme.sizes.md} * 2.5);
  padding: ${({ theme }) => theme.sizes.xs};

  position: sticky;
  top: calc(${({ theme }) => theme.sizes.md} * 2.5);
  width: 100%;
  z-index: 99;
`;

export const StyledNewVisitNavbarLayout = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 144rem;
  width: 100%;

  @media (max-width: 768px) {
    ${BreadcrumbContainer} {
      flex-grow: 1;
      justify-content: space-around;

      & div {
        margin: 0;
      }
    }
  }
`;

export const StyledTextAndIcon = styled.div`
  align-items: center;
  display: flex;
  ${({ theme }) => theme.fontSizes.xl};

  & > *:first-child {
    margin-right: ${({ theme }) => theme.sizes.md};
  }
`;

export const StyledNewVisitNavigationButton = styled(DefaultButton)`
  display: inline-block;
  margin-right: ${({ theme }) => theme.sizes.md};
`;

export const StyledCompaniesList = styled.ul`
  padding: 1rem;
  li {
    list-style-type: circle;
    margin: 1rem;
  }
`;
