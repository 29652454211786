import React, { useCallback } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { isEmpty } from 'lodash';
import * as yup from 'yup';
import i18n from 'i18n';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { EmbededDatepickerField } from 'components/Forms/EmbededDatepickerField/EmbededDatepickerField';
import { StyledModalButtonsWrapper, StyledModalHeader, useModal } from 'components/Modal/Modal';
import { StyledChooseVisitForm, StyledCompaniesList } from 'containers/newVisit/styles';
import { yupValidate } from 'utils/yupValidate';
import { formatQueryDate } from 'utils/date';
import {
  CompanyVisit,
  setNewVisitDate,
  updateVisitDateWithoutRequest,
  visitIdSelector,
} from 'ducks/newVisit';
import { useAppDispatch } from 'store';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { useSelector } from 'react-redux';

const t = (x: string) => i18n.t(x);

const schema = yup.object().shape({
  date: yup.date().required(),
});

type ValuesType = {
  date: Date;
};

interface EditDateModalProps {
  initialDate?: Date;
  companyVisits?: CompanyVisit[];
}

export const EditDateModal = (props: EditDateModalProps): JSX.Element => {
  const { initialDate, companyVisits } = props;
  const { hideModal, setShowHeader } = useModal();
  const dispatch = useAppDispatch();
  const visitId = useSelector(visitIdSelector);
  const [warningState, setWarningState] = React.useState<boolean>(false);
  const companyVisitsWithReportsSent = companyVisits?.filter(
    (companyVisit) => companyVisit.companyVisitReports.length > 0,
  );

  const onSubmit = useCallback(
    async (values: ValuesType) => {
      const date = formatQueryDate(values.date);
      if (date) {
        // If visitId is not equal to 0, it means that we are editing an existing visit
        if (visitId === 0) {
          dispatch(updateVisitDateWithoutRequest(date));
        } else {
          await dispatch(setNewVisitDate({ visitId: visitId, visitDate: date }));
        }

        if (companyVisitsWithReportsSent && companyVisitsWithReportsSent.length > 0) {
          setWarningState(true);
          setShowHeader(true);
        } else {
          hideModal();
        }
      }
    },
    [visitId],
  );

  return (
    <>
      <StyledModalHeader>
        {warningState ? t('new_visits.date_updated') : t('new_visits.choose_date')}
      </StyledModalHeader>
      {warningState ? (
        <div>
          <InfoBox
            content={
              companyVisitsWithReportsSent && companyVisitsWithReportsSent.length > 1 ? (
                <>
                  {t('new_visits.several_reports_resend')}
                  <StyledCompaniesList>
                    {companyVisitsWithReportsSent?.map((companyVisit, i) => (
                      <li key={i + companyVisit.companyName}>{companyVisit.companyName}</li>
                    ))}
                  </StyledCompaniesList>
                </>
              ) : (
                <>
                  {companyVisitsWithReportsSent &&
                    t('new_visits.one_report_resend') +
                      companyVisitsWithReportsSent[0].companyName +
                      '.'}
                </>
              )
            }
          />
        </div>
      ) : (
        <Form
          initialValues={{ date: initialDate || new Date() }}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <StyledChooseVisitForm onSubmit={handleSubmit}>
              <Field
                component={EmbededDatepickerField}
                extraOptions={{
                  tileDisabled: ({ date, view }: { date: Date; view: string }) =>
                    view === 'month' && /0|6/.test(date.getDay().toString()),
                }}
                name='date'
                required
                subscription={{ value: true, touched: true, error: true }}
              />
              <StyledModalButtonsWrapper>
                <DefaultButton
                  capitalize
                  label={t('common.cancel')}
                  onClick={hideModal}
                  type='button'
                  variant='tertiary'
                />
                <FormSpy subscription={{ errors: true }}>
                  {({ errors }) => (
                    <DefaultButton
                      capitalize
                      isDisabled={!isEmpty(errors)}
                      label={t('common.choose')}
                      type='submit'
                    />
                  )}
                </FormSpy>
              </StyledModalButtonsWrapper>
            </StyledChooseVisitForm>
          )}
          subscription={{ values: true }}
          validate={yupValidate(schema)}
        />
      )}
    </>
  );
};
