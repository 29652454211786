const ORIGIN = window.location.origin;

// Static
export const NEW_WORKPLACE = `${ORIGIN}/workplaces/new`;
export const NEW_BUSINESS_WITH_ORGANIZATION_NUMBER = `${ORIGIN}/redesigned/create-company`;
export const NEW_BUSINESS_WITHOUT_ORGANIZATION_NUMBER = `${ORIGIN}/redesigned/create-company`;
export const ADMIN = `${ORIGIN}/admin`;
export const SAVED_SEARCH = `${ORIGIN}/redesigned/saved-search`;
export const MAIL_LOG = `${ORIGIN}/redesigned/mail-log`;
export const REPORTS = `${ORIGIN}/redesigned/reports`;
