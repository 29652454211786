import { theme } from 'consts/theme';
import styled from 'styled-components/macro';

export const StyledDivider = styled.div<{ customProps?: {} }>`
  background-color: ${theme.colors.black100};
  height: 1px;
  margin-bottom: ${({ theme }) => theme.sizes.sm};
  margin-top: ${({ theme }) => theme.sizes.md};
  transform: translateX(-4rem);
  width: calc(100% + 8rem);
  ${({ customProps }) => customProps}
`;

export const StyledModalButtonsWrapper = styled.div`
  background-color: white;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  margin-top: 2.4rem;
  min-height: 38px;
  position: sticky;
  z-index: 2;

  &:before {
    background-color: white;
    border-top: 1px solid ${({ theme }) => theme.colors.black100};
    content: '';
    height: 1.6rem;
    left: -4rem;
    position: absolute;
    top: -1.6rem;
    width: calc(100% + 8rem);
  }

  &:after {
    background-color: white;
    bottom: -1.6rem;
    content: '';
    height: 1.6rem;
    position: absolute;
    width: 100%;
  }

  & > button {
    margin-left: ${({ theme }) => theme.sizes.hmd};
    z-index: 1;
  }
`;

export const StyledModalHeader = styled.div`
  ${({ theme }) => theme.fontSizes.xl};
  font-weight: 600;
  margin: -7px 0 ${({ theme }) => theme.sizes.md} 0;
`;

export const StyledModalItemToRemove = styled.div`
  ${({ theme }) => theme.fontSizes.base};
  color: ${theme.colors.black400};
  line-height: ${({ theme }) => theme.lineHeight.lg};
`;

export const StyledModalMessage = styled.div`
  ${({ theme }) => theme.fontSizes.base};
  line-height: ${({ theme }) => theme.lineHeight.lg};
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const StyledCloseButton = styled.button`
  width: 30px;
  display: block;
  margin-left: auto;
  margin-top: -10px;
  margin-bottom: -30px;
`;

export const StyledCloseButtonWrapper = styled.div`
  position: sticky;
  width: 100%;
  z-index: 1;
  margin-bottom: 0px;
`;

export const modalStylesOverrides = {
  overlay: {
    alignItems: 'center',
    backgroundColor: `${theme.colors.black900}CC`,
    bottom: 'none',
    display: 'flex',
    justifyContent: 'center',
    left: 'none',
    height: '100vh',
    overflow: 'hidden',
    padding: '4rem 0',
    position: 'static',
    right: 'none',
    top: 'none',
    width: '100vw',
  },
  content: {
    background: '#fff',
    border: 'none',
    borderRadius: '.8rem',
    bottom: 'none',
    display: 'flex',
    flexDirection: 'column',
    left: 'none',
    maxHeight: 'calc(100vh - 8rem)',
    maxWidth: '55rem',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '30px 4rem 1.6rem',
    position: 'relative',
    right: 'none',
    top: 'none',
    width: '90%',
  },
};
