import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { navigate, useLocation, useMatch } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb';
import {
  StyledNewVisitNavbar,
  StyledNewVisitNavbarLayout,
  StyledNewVisitNavigationButton,
  StyledTextAndIcon,
} from 'containers/newVisit/styles';
import {
  activeCompanyVisitSelector,
  newVisitCompanyVisitsSelector,
  newVisitCreatorIdSelector,
  newVisitIndustryIdSelector,
  visitDateSelector,
} from 'ducks/newVisit';
import { NotFound } from 'containers/NotFound/NotFound';
import { formatDate } from 'utils/date';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { ArrowLeftBigIcon, EyeIcon } from 'utils/iconsMap';
import { API_BASE_URL } from 'api';
import { useModal } from 'components/Modal/Modal';
import { useScreenSizeContext } from 'contexts/ScreenSizeContext';
import { EditDateModal } from 'containers/newVisit/EditDateModal';
import { EditIcon } from 'utils/iconsMap';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { ButtonWrapper } from 'components/ButtonWrapper/ButtonWrapper';
import { CurrentUserEntityResponse } from 'ducks/users/types';

const steps = ['workplace', 'companies'];

type NewVisitNavbarProps = {
  user: CurrentUserEntityResponse | null;
};

export const NewVisitNavbar: FC<NewVisitNavbarProps> = ({ user }) => {
  const match = useMatch('/redesigned/new-ordinary-visit/:id/:step');
  const visitDate = useSelector(visitDateSelector);
  const location = useLocation();
  const activeCompanyVisit = useSelector(activeCompanyVisitSelector);
  const { t } = useTranslation();
  const { md } = useScreenSizeContext();
  const industryId = useSelector(newVisitIndustryIdSelector);
  const creatorId = useSelector(newVisitCreatorIdSelector);
  const companyVisits = useSelector(newVisitCompanyVisitsSelector);
  const { setModalComponent, showModal, setCustomStyles, setShowHeader } = useModal();

  const userCanEditDate = React.useMemo(
    () => (user?.admin && industryId === user?.industryId) || creatorId === user?.id,
    [companyVisits, user],
  );

  const editNewDate = React.useCallback(() => {
    setCustomStyles({
      maxWidth: 'fit-content',
      minWidth: '330px',
      minHeight: '450px',
      height: 'fit-content',
    });
    setModalComponent(
      <EditDateModal companyVisits={companyVisits} initialDate={new Date(visitDate)} />,
    );
    setShowHeader(false);
    showModal();
  }, [setCustomStyles, showModal, setModalComponent, md]);

  if (!match) return <NotFound />;

  const isPreviewDisabled = !activeCompanyVisit || match.step !== 'companies';
  const previewButton = (
    <StyledNewVisitNavigationButton
      icon={<EyeIcon />}
      isDisabled={isPreviewDisabled}
      testid='preview'
      variant='tertiary'
    />
  );

  const returnUrl =
    location.state?.previousPathname && location.state?.previousPathname !== location.pathname
      ? location.state?.previousPathname
      : `/redesigned/search/company_visits`;

  return (
    <StyledNewVisitNavbar>
      <StyledNewVisitNavbarLayout>
        <StyledTextAndIcon>
          <Tooltip label={t('common.back')}>
            <StyledNewVisitNavigationButton
              icon={<ArrowLeftBigIcon />}
              onClick={() =>
                navigate(returnUrl, {
                  state: {
                    ...location.state,
                    districtNames: returnUrl.includes('new-ordinary-visit')
                      ? window.history.state.DistrictNames
                      : window.history.state.previousDistrictNames,
                  },
                })
              }
              testid='back'
              type='button'
              variant='tertiary'
            />
          </Tooltip>
          {visitDate && formatDate(visitDate)}
          <ButtonWrapper>
            <Tooltip label={t('new_visits.edit_date')}>
              <DefaultButton
                icon={<EditIcon />}
                isDisabled={!userCanEditDate}
                onClick={editNewDate}
                variant='secondary'
              />
            </Tooltip>
          </ButtonWrapper>
        </StyledTextAndIcon>
        <Breadcrumb
          activeStep={match!.step}
          handleClick={(step: string) =>
            navigate(`./${step}`, { state: { ...location.state }, replace: true })
          }
          steps={steps}
          translate
        />
        {isPreviewDisabled ? (
          previewButton
        ) : (
          <Tooltip label={t('new_visits.preview_visit_report')}>
            <div
              onClick={() =>
                setTimeout(function () {
                  window.open(
                    `${API_BASE_URL}/new_visits/visit_reports/${activeCompanyVisit}`,
                    '_blank',
                  );
                }, 100)
              }
              style={{ cursor: 'pointer' }}
            >
              <div style={{ pointerEvents: 'none' }}>{previewButton}</div>
            </div>
          </Tooltip>
        )}
      </StyledNewVisitNavbarLayout>
    </StyledNewVisitNavbar>
  );
};
