import React, { useCallback, useMemo, useState } from 'react';
import { SerializedError, unwrapResult } from '@reduxjs/toolkit';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { navigate } from '@reach/router';

import { amendAddresses } from 'ducks/powerSearch/company/reducer';
import {
  StyledAddressContainer,
  StyledAddressTag,
  StyledAddressWrapper,
  StyledButtonsWrapper,
  StyledLink,
} from 'containers/companyDetails/BasicData/styles';
import { ValueWithLabel } from 'components/ValueWithLabel/ValueWithLabel';
import { StaticMapImage } from 'components/StaticMapImage/StaticMapImage';
import { ContentContainer, Margin } from 'styles/utils';
import {
  StyledDetails,
  StyledSecondaryText,
} from 'containers/companyDetails/CompanyNavigationPanel/styles';
import { StatusBadge } from 'components/StatusBadge/StatusBadge';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { StyledDivider } from 'components/Modal/Modal';
import { StyledVerticalFieldWrapper } from 'components/Forms/styles';
import { TextInput } from 'components/TextInput/TextInput';
import { API } from 'api';
import { ButtonWrapper } from 'components/ButtonWrapper/ButtonWrapper';
import {
  createSameAsCompanyVisit,
  createVisitWithOneTimeAddress,
  newVisitAddressSelector,
  updateSameAsCompanyVisit,
  updateVisitWithOneTimeAddress,
} from 'ducks/newVisit';
import { Address, CompanyEntity, OneTimeAddress } from 'ducks/companies/types';
import { useAppDispatch } from 'store';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { ReactComponent as EditIcon } from 'images/editIcon.svg';
import { PlusIcon } from 'utils/iconsMap';
import { StyledButtonWrapper } from 'components/ButtonWrapper/styles';
import {
  newVisitFullAddressSelector,
  visitDateSelector,
  visitIdSelector,
  visitParamsSelector,
} from '../../../../ducks/newVisit/selectors';
import { allFilteredDistrictsSelector } from 'ducks/powerSearch/selectors';
import { generateVisit } from './generateVisit';

type ResponseType = {
  data: {
    name: string;
    id: number;
  };
  status: number;
};

interface WorkplacePickCompanyAddressPropTypes {
  isOneTimeAddress?: boolean;
  selectedCompany: CompanyEntity;
  hasAddress: boolean;
  setSelectedCompany: React.Dispatch<React.SetStateAction<CompanyEntity | null>>;
}

export const WorkplacePickCompanyAddress = ({
  isOneTimeAddress = false,
  selectedCompany,
  hasAddress,
}: WorkplacePickCompanyAddressPropTypes) => {
  const { t } = useTranslation();
  const existingAddress = useSelector(newVisitAddressSelector);
  const existingAddressObj = useSelector(newVisitFullAddressSelector);
  const amendedAddresses = useMemo(
    () => amendAddresses(selectedCompany?.addresses || []),
    [selectedCompany],
  );

  const [addAddress, setAddAddress] = useState(false);

  const [newStreet, setNewStreet] = useState('');
  const [newStreetNumber, setNewStreetNumber] = useState('');
  const [newPostalCode, setNewPostalCode] = useState('');
  const [newCity, setNewCity] = useState('');
  const [newPostalCodeId, setNewPostalCodeId] = useState<number | undefined>();
  const [newPostCodeError, setNewPostCodeError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { light, type } = useSelector(visitParamsSelector);
  const visitDate = useSelector(visitDateSelector);
  const allDistricts = useSelector(allFilteredDistrictsSelector).map((district) => district.value);
  const visitId = useSelector(visitIdSelector);

  const dispatch = useAppDispatch();
  const oneTimeAddress = {
    postalAreaId: newPostalCodeId,
    street: newStreet,
    streetNumber: newStreetNumber,
  };

  const selectAddress = useCallback(
    async (address: Address) => {
      let generatedVisitId: string | null = null;
      visitId === 0 &&
        (generatedVisitId = await generateVisit(light, type, visitDate, allDistricts));

      dispatch(
        hasAddress
          ? updateSameAsCompanyVisit({
              address,
              visit_id: String(generatedVisitId ?? visitId),
            })
          : createSameAsCompanyVisit({
              address,
              company_id: selectedCompany?.id,
              visit_id: String(generatedVisitId ?? visitId),
            }),
      )
        .then(unwrapResult)
        .then(() => navigate('./companies', { state: { ...window.history.state } }))
        .catch((err: SerializedError) => console.log(err));
    },
    [dispatch],
  );

  const selectOneTimeAddress = useCallback(
    async (address: OneTimeAddress) => {
      let generatedVisitId: string | null = null;
      visitId === 0 &&
        (generatedVisitId = await generateVisit(light, type, visitDate, allDistricts));

      dispatch(
        hasAddress
          ? updateVisitWithOneTimeAddress({
              address: address,
              visit_id: String(generatedVisitId ?? visitId),
            })
          : createVisitWithOneTimeAddress({
              address: address,
              visit_id: String(generatedVisitId ?? visitId),
              company_id: selectedCompany?.id,
            }),
      )
        .then(unwrapResult)
        .then(() => navigate('./companies', { state: { ...window.history.state } }))
        .catch((err: SerializedError) => console.log(err));
    },
    [dispatch],
  );

  const newPostCodeCallback = async (event: React.FormEvent<HTMLInputElement>) => {
    const { value }: { value: string } = event.currentTarget;
    setNewPostalCode(value);
    setNewCity('');
    setNewPostCodeError(false);
    if (value.length === 4) {
      try {
        const response: ResponseType = await API.get(`/post_codes/${value}`);
        setNewCity(response.data.name);
        setNewPostalCodeId(response.data.id);
      } catch (error) {
        setNewPostCodeError(true);
      }
    }
  };

  const getTranslation = (type: string) => {
    switch (type) {
      case 'select_address':
        return t('new_visits.select_address');
      case 'org_number':
        return t('company_details.organisational_number');
      case 'one_time_address':
        return t('new_visits.one_time_address');
      case 'active-status':
        return t(
          `company_details.company_is_${selectedCompany?.info.active ? 'active' : 'inactive'}`,
        );
      default:
        console.log(`Missing translation: ${type}`);
        return '';
    }
  };

  return (
    <Margin margin='0.8rem 0'>
      <StyledDetails>
        <Margin left='1.6rem'>
          <h1>{selectedCompany?.info.name}</h1>
        </Margin>
        <StatusBadge variant={selectedCompany?.info.active ? 'success' : 'inactive'}>
          {capitalize(getTranslation('active-status'))}
        </StatusBadge>
        <StyledSecondaryText>
          {getTranslation('org_number')}: {selectedCompany?.info.number}
        </StyledSecondaryText>
      </StyledDetails>
      <StyledDivider customProps={{ margin: '0' }} />
      {amendedAddresses.length > 0 && (
        <>
          <h3>{getTranslation('select_address')}</h3>
          {amendedAddresses.map((address, i) => (
            <StyledAddressContainer justify key={i}>
              {address.typeSymbol === 'visit' ? (
                <>
                  <StyledAddressWrapper>
                    <StyledAddressTag>{address.tagLetter}</StyledAddressTag>
                    <ValueWithLabel label={address.label}>
                      <Tooltip label={address.address}>
                        <StyledLink isDisabled>{address.address}</StyledLink>
                      </Tooltip>
                    </ValueWithLabel>
                  </StyledAddressWrapper>
                  <DefaultButton
                    label={t('common.select')}
                    onClick={() => selectAddress(address)}
                    size='small'
                    variant='success'
                  />
                </>
              ) : (
                <>
                  <StyledAddressWrapper>
                    <StyledAddressTag>{address.tagLetter}</StyledAddressTag>
                    <ValueWithLabel label={address.label}>
                      <Tooltip label={address.address}>
                        <StyledLink isDisabled>{address.address}</StyledLink>
                      </Tooltip>
                    </ValueWithLabel>
                  </StyledAddressWrapper>
                  <DefaultButton
                    label={t('common.select')}
                    onClick={() => selectAddress(address)}
                    size='small'
                    variant='success'
                  />
                </>
              )}
            </StyledAddressContainer>
          ))}
          {hasAddress && isOneTimeAddress && (
            <>
              {isEditing ? (
                <>
                  <StyledAddressContainer>
                    <StyledAddressWrapper>
                      <StyledAddressTag>E</StyledAddressTag>
                      <ValueWithLabel label={t('new_visits.one_time_address')} />
                    </StyledAddressWrapper>
                  </StyledAddressContainer>
                  <Margin margin='2rem 0'>
                    <ContentContainer display='flex'>
                      <StyledVerticalFieldWrapper>
                        <TextInput
                          inputProps={{
                            value: newStreet,
                            onChange: (val) => setNewStreet(val.currentTarget.value),
                          }}
                          label={t('forms.street')}
                          size='fullWidth'
                        />
                      </StyledVerticalFieldWrapper>
                      <StyledVerticalFieldWrapper>
                        <TextInput
                          inputProps={{
                            value: newStreetNumber,
                            onChange: (val) => setNewStreetNumber(val.currentTarget.value),
                          }}
                          label={t('forms.street_number')}
                        />
                      </StyledVerticalFieldWrapper>
                      <StyledVerticalFieldWrapper>
                        <TextInput
                          inputProps={{
                            value: newPostalCode,
                            onChange: newPostCodeCallback,
                            maxLength: 4,
                          }}
                          isError={newPostCodeError}
                          label={t('forms.post_code')}
                        />
                      </StyledVerticalFieldWrapper>
                      <StyledVerticalFieldWrapper>
                        <TextInput
                          inputProps={{ value: newCity || '' }}
                          isDisabled
                          label={t('forms.post_area')}
                          size='big'
                        />
                      </StyledVerticalFieldWrapper>
                      <ButtonWrapper>
                        <DefaultButton
                          capitalize
                          label={t('common.cancel')}
                          onClick={() => {
                            setAddAddress(false);
                            setIsEditing(false);
                          }}
                          size='small'
                          type='button'
                          variant='secondary'
                        />
                        <DefaultButton
                          capitalize
                          label={t('common.select')}
                          onClick={() => selectOneTimeAddress(oneTimeAddress)}
                          size='small'
                          type='button'
                          variant='success'
                        />
                      </ButtonWrapper>
                    </ContentContainer>
                  </Margin>
                </>
              ) : (
                <StyledAddressContainer justify>
                  <StyledAddressWrapper>
                    <StyledAddressTag>E</StyledAddressTag>
                    <ValueWithLabel label={getTranslation('one_time_address')}>
                      <Tooltip label={existingAddress}>
                        <StyledLink isDisabled>{existingAddress}</StyledLink>
                      </Tooltip>
                    </ValueWithLabel>
                  </StyledAddressWrapper>
                  <StyledButtonsWrapper>
                    <DefaultButton
                      capitalize
                      icon={<EditIcon />}
                      label={t('common.edit')}
                      onClick={() => setIsEditing(true)}
                      size='small'
                      type='button'
                      variant='tertiary'
                    />
                    <DefaultButton
                      label={t('common.select')}
                      onClick={() => selectAddress(existingAddressObj)}
                      size='small'
                      variant='success'
                    />
                  </StyledButtonsWrapper>
                </StyledAddressContainer>
              )}
            </>
          )}
          {!isOneTimeAddress && (
            <>
              {!addAddress ? (
                <StyledButtonWrapper align='right' isWrap={false}>
                  <Margin margin='1rem 0'>
                    <DefaultButton
                      icon={<PlusIcon />}
                      label={t('new_visits.use_another_address')}
                      onClick={() => setAddAddress(true)}
                      size='small'
                    />
                  </Margin>
                </StyledButtonWrapper>
              ) : (
                <>
                  <StyledAddressContainer>
                    <StyledAddressWrapper>
                      <StyledAddressTag>E</StyledAddressTag>
                      <ValueWithLabel label={t('new_visits.one_time_address')} />
                    </StyledAddressWrapper>
                  </StyledAddressContainer>
                  <Margin margin='2rem 0'>
                    <ContentContainer display='flex'>
                      <StyledVerticalFieldWrapper>
                        <TextInput
                          inputProps={{
                            value: newStreet,
                            onChange: (val) => setNewStreet(val.currentTarget.value),
                          }}
                          label={t('forms.street')}
                          size='fullWidth'
                        />
                      </StyledVerticalFieldWrapper>
                      <StyledVerticalFieldWrapper>
                        <TextInput
                          inputProps={{
                            value: newStreetNumber,
                            onChange: (val) => setNewStreetNumber(val.currentTarget.value),
                          }}
                          label={t('forms.street_number')}
                        />
                      </StyledVerticalFieldWrapper>
                      <StyledVerticalFieldWrapper>
                        <TextInput
                          inputProps={{
                            value: newPostalCode,
                            onChange: newPostCodeCallback,
                            maxLength: 4,
                          }}
                          isError={newPostCodeError}
                          label={t('forms.post_code')}
                        />
                      </StyledVerticalFieldWrapper>
                      <StyledVerticalFieldWrapper>
                        <TextInput
                          inputProps={{ value: newCity || '' }}
                          isDisabled
                          label={t('forms.post_area')}
                          size='big'
                        />
                      </StyledVerticalFieldWrapper>
                      <ButtonWrapper>
                        <DefaultButton
                          capitalize
                          label={t('common.cancel')}
                          onClick={() => {
                            setAddAddress(false);
                            setIsEditing(false);
                          }}
                          size='small'
                          type='button'
                          variant='secondary'
                        />
                        <DefaultButton
                          capitalize
                          label={t('common.select')}
                          onClick={() => selectOneTimeAddress(oneTimeAddress)}
                          size='small'
                          type='button'
                          variant='success'
                        />
                      </ButtonWrapper>
                    </ContentContainer>
                  </Margin>
                </>
              )}
            </>
          )}
          <Margin margin='4rem 0'>
            <StaticMapImage addresses={amendedAddresses} />
          </Margin>
        </>
      )}
    </Margin>
  );
};
