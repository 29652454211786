import * as CSS from 'csstype';
import styled, { css, keyframes } from 'styled-components/macro';

export const SpanCenter = styled.span<{ withFlex?: boolean }>`
  text-align: center;
  width: 100%;

  ${({ withFlex }) =>
    withFlex &&
    css`
      display: flex;
    `}
`;

export const DivCenter = styled.div`
  width: 100%;
  text-align: center;
`;

export const KeyframesPopup = keyframes`
  0% {
      transform: scale(0.9);
      opacity: 0;
      }

  50% {
     opacity: 1;
      }

  100% {
      transform: scale(1);
      opacity: 1;
  }
`;

export const Margin = styled.div<{
  bottom?: string;
  display?: string;
  left?: string;
  margin?: string;
  right?: string;
  top?: string;
}>`
  display: ${({ display }) => display || 'block'};

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `};
  ${({ bottom }) =>
    bottom &&
    css`
      margin-bottom: ${bottom};
    `};
  ${({ top }) =>
    top &&
    css`
      margin-top: ${top};
    `};
  ${({ left }) =>
    left &&
    css`
      margin-left: ${left};
    `};
  ${({ right }) =>
    right &&
    css`
      margin-right: ${right};
    `};
`;

export const ContentContainer = styled.div<{
  display?: CSS.DisplayProperty;
  margin?: CSS.MarginProperty<1>;
  maxWidth?: CSS.MaxWidthProperty<1>;
  flex?: CSS.FlexProperty<1>;
}>`
  display: ${({ display }) => display || 'block'};
  margin: ${({ margin }) => margin || '0 auto'};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}
`;

export const FlexContainer = styled.div<{
  alignItems?: CSS.AlignItemsProperty;
  flexWrap?: CSS.FlexWrapProperty;
  flexDirection?: CSS.FlexDirectionProperty;
  justifyContent?: CSS.JustifyContentProperty;
  margin?: CSS.MarginProperty<1>;
}>`
  display: flex;
  margin: ${({ margin }) => margin || '0 auto'};
  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}
  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `}
  ${({ flexDirection }) =>
    flexDirection &&
    css`
      flex-direction: ${flexDirection};
    `}
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}
`;

export const ClearStyles = styled.div<{
  margin?: boolean;
  padding?: boolean;
}>`
  > div,
  button,
  p,
  span {
    ${({ margin }) =>
      margin &&
      css`
        margin: 0;
      `}
    ${({ padding }) =>
      padding &&
      css`
        padding: 0;
      `}
  }
  max-width: 50rem;
  @media screen and (max-width: 1220px) {
    max-width: 30rem;
  }
  @media screen and (max-width: 1020px) {
    max-width: 20rem;
  }
  button,
  span {
    overflow: hidden;
  }
`;
