import * as React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import axios, { CancelTokenSource } from 'axios';

import { MultiSelectField } from 'components/Forms/MultiSelectField/MultiSelectField';
import { Option } from 'components/MultiSelectList/MultiSelectList';
import { fetchCompanyVisits } from 'dialogs/RevisedActivities/helpers';
import { SpinnerIcon } from 'utils/iconsMap';
import { StyledFieldWrapper, StyledLabel } from 'components/Forms/styles';
import { uniqBy } from 'lodash';

interface CompanyVisitsFieldProps {
  selectedCompanies: Option[];
  preselectedVisitOptions?: Option[];
}

export const CompanyVisitsField = (props: CompanyVisitsFieldProps): JSX.Element => {
  const { selectedCompanies, preselectedVisitOptions = [] } = props;
  const [isFetchingOptions, setIsFetchingOptions] = React.useState(false);
  const [options, setOptions] = React.useState<Option[]>([]);
  const cancelToken = React.useRef<CancelTokenSource | null>(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    const fetchOptions = async () => {
      cancelToken.current?.cancel();
      const CancelToken = axios.CancelToken;
      cancelToken.current = CancelToken.source();

      setIsFetchingOptions(true);
      if (selectedCompanies.length) {
        try {
          const response = await fetchCompanyVisits(
            selectedCompanies.map(({ label }) => label),
            cancelToken.current,
          );

          const visitsArray = [...preselectedVisitOptions, ...response];
          const dedup = uniqBy(visitsArray, 'value');
          setOptions(dedup);
        } catch (e) {
          console.error(e);
        }
      } else setOptions(preselectedVisitOptions);
      setIsFetchingOptions(false);
    };

    try {
      fetchOptions();
    } catch (e) {
      console.error(e);
    }
  }, [selectedCompanies]);

  return isFetchingOptions ? (
    <StyledFieldWrapper>
      <StyledLabel>{t('table.columns.visits')}</StyledLabel>
      <SpinnerIcon />
    </StyledFieldWrapper>
  ) : (
    <Field
      component={MultiSelectField}
      label={t('table.columns.visits')}
      name='visits'
      options={options}
      placeholder={`${t('forms.select_a')} ${t('company_details.visits.result')}`}
    />
  );
};
