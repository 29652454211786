import styled from 'styled-components/macro';

export const StyledContainer = styled.div`
  margin: 10rem auto;
  text-align: center;
  width: fit-content;

  & > span {
    ${({ theme }) => theme.fontSizes.xxl};
    font-weight: 500;
  }
`;
