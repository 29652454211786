import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { TableGrid } from 'components/Table/TableGrid/TableGrid';
import { PaginationComposed } from 'components/Pagination/Pagination';
import { TableFilters } from 'components/TableFilters/TableFilters';
import { useLoadingContext } from 'contexts/LoadingContext';
import { StyledColumn, StyledTableBottomPanel } from 'containers/companyDetails/Visits/styles';
import { StoreType } from 'store';
import { rvoEventsComponentsMap } from './tableComponentsMap';
import { RvoEventsEntity, RvoEventsEntityResponse } from 'ducks/events/types';
import { getAllRvoEvents, getEventsCSV, getRvoEvents } from 'ducks/events/actions';
import { clearEventsError, resetEvents } from 'ducks/events/reducer';
import { ButtonWrapper } from 'components/ButtonWrapper/ButtonWrapper';
import { CommonActionsPanel } from 'components/CommonActionsPanel/CommonActionsPanel';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { StyledEmptyEvents } from './styles';
import { useModal } from 'components/Modal/Modal';
import {
  TableProvidersProps,
  useTableContexts,
  withTableProviders,
} from 'hocs/composedTableProviders/composedTableProviders';
import {
  AddOrEditRevisedActivity,
  DeleteRevisedActivity,
  ShowRevisedActivity,
} from 'dialogs/RevisedActivities';

import { ReactComponent as PlusIcon } from 'images/plusIcon.svg';
import { ReactComponent as DeleteIcon } from 'images/deleteIcon.svg';
import { companySelector } from 'ducks/companies/selectors';
import { toast } from 'react-hot-toast';

interface RvoEventsProps extends TableProvidersProps {
  companyId?: string;
}

const RvoEvents: FC<RvoEventsProps> = (props) => {
  const { fetchParams } = props;
  const events = useSelector((state: StoreType) => state.events.rvo);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setLoaded, setLoading } = useLoadingContext();
  const { setModalComponent, showModal, setShowHeader } = useModal();
  const {
    addMultipleSelection,
    commonAction,
    deleteSelection,
    filterParams,
    itemsSelected,
    setContextName,
  } = useTableContexts();
  const company = useSelector(companySelector);

  useEffect(() => {
    setContextName('rvo');
    return () => {
      setContextName(null);
      dispatch(resetEvents());
    };
  }, []);

  // ----- action handler

  const createEvent = () => {
    setModalComponent(
      <AddOrEditRevisedActivity
        defaultCompany={[{ label: company!.info.name, value: company!.id }]}
        isCompaniesFieldVisible={false}
      />,
    );
    setShowHeader(false);
    showModal();
  };

  const editHandler = (entity: RvoEventsEntity) => {
    setModalComponent(<AddOrEditRevisedActivity event={entity} />);
    setShowHeader(false);
    showModal();
  };

  const deleteHandler = (entity: RvoEventsEntity) => {
    setModalComponent(
      <DeleteRevisedActivity
        actionCallback={() => deleteSelection(entity.id)}
        itemsSelected={[entity]}
      />,
    );
    setShowHeader(false);
    showModal();
  };

  const showHandler = (entity: RvoEventsEntity) => {
    setModalComponent(<ShowRevisedActivity event={entity} />);
    setShowHeader(true);
    showModal();
  };

  const selectAllHandler = async () => {
    setLoading('rvo');
    const response = await getAllRvoEvents({
      ...fetchParams,
      ...filterParams,
    });

    addMultipleSelection(
      response.data.tableBody.map((item: RvoEventsEntityResponse) => ({
        entity: { ...item, id: item.revisedActivityId },
        id: item.revisedActivityId,
      })),
    );
    setLoaded('rvo');
  };

  const exportCsvHandler = async () => {
    setLoading('rvo');
    await getEventsCSV(itemsSelected.map((item: RvoEventsEntity) => item.id));
    setLoaded('rvo');
  };

  const actionHandlers = {
    exportCSV: exportCsvHandler,
    show: showHandler,
    delete: deleteHandler,
    edit: editHandler,
    selectAll: selectAllHandler,
  };

  if (events.error) {
    toast.error(events.error);
    dispatch(clearEventsError());
  }

  if (isEmpty(events.meta.columnsOrder)) return null;

  if (isEmpty(events.data.tableBody) && isEmpty(filterParams))
    return (
      <StyledEmptyEvents>
        <span>{t('company_details.events.no_events')}</span>
        <DefaultButton
          icon={<PlusIcon />}
          label={t('company_details.events.add_event')}
          onClick={createEvent}
        />
      </StyledEmptyEvents>
    );

  return (
    <StyledColumn>
      <TableFilters />
      <TableGrid
        actionHandlers={actionHandlers}
        addButton={
          <DefaultButton
            icon={<PlusIcon />}
            label={t('company_details.events.add_event')}
            onClick={createEvent}
          />
        }
        allFilteredOutMessage={t('company_details.events.all_filtered_out')}
        bodyData={events.data.tableBody}
        columnsOrder={events.meta.columnsOrder}
        componentsMap={rvoEventsComponentsMap}
        loadResource='rvo'
        resultsSelector='company_details.events'
        totalCount={events.meta.totalCount}
        withCheckbox
      />
      <StyledTableBottomPanel>
        <PaginationComposed loadResource='rvo' />
        <ButtonWrapper align='right'>
          <CommonActionsPanel actionHandlers={actionHandlers}>
            <Tooltip label={t('common.delete_selected')}>
              <DefaultButton
                icon={<DeleteIcon />}
                isDisabled={!itemsSelected.length || itemsSelected?.length > 1}
                onClick={commonAction ? commonAction.action : () => {}}
                testid='rvo-events-delete-btn'
                variant='secondary'
              />
            </Tooltip>
          </CommonActionsPanel>
        </ButtonWrapper>
      </StyledTableBottomPanel>
    </StyledColumn>
  );
};

const RvoEventsComposed = withTableProviders(RvoEvents, {
  fetchFnExtraParams: (props) => ({ company_id: props.companyId }),
  fetchFunction: getRvoEvents,
  loadResource: 'rvo',
  metaUrl: 'revised_activities',
});

export { RvoEventsComposed as RvoEvents };
