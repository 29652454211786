import { createAsyncThunk } from '@reduxjs/toolkit';
import FileSaver from 'file-saver';

import {
  CrmEventsResponse,
  GetEventsParams,
  RvoEventsEntityResponse,
  RvoEventsResponse,
  RvoEventsTipsATPropsResponse,
} from 'ducks/events/types';
import { API } from 'api';
import { StoreType } from 'store';
import { AxiosError } from 'axios';

export const getCrmEvents = createAsyncThunk(
  'events/crmEvents',
  async (params: GetEventsParams, { rejectWithValue }) => {
    try {
      const response = await API.get(`crm_events`, { params });
      return response.data as CrmEventsResponse;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const getAllCrmEvents = async (params: GetEventsParams) => {
  const response = await API.get(`crm_events`, {
    params: {
      ...params,
      page: 1,
      page_size: 1000,
    },
  });
  return response.data as CrmEventsResponse;
};

export const getRvoEvents = createAsyncThunk(
  'events/rvoEvents',
  async (params: GetEventsParams, { rejectWithValue }) => {
    try {
      const response = await API.get(`revised_activities`, { params });
      return response.data as RvoEventsResponse;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const getAllRvoEvents = async (params: GetEventsParams) => {
  const response = await API.get(`revised_activities`, {
    params: {
      ...params,
      page: 1,
      page_size: 1000,
    },
  });
  return response.data as RvoEventsResponse;
};

export const deleteRvoEvent = createAsyncThunk(
  'events/deleteRvoEvents',
  async (id: string, { rejectWithValue }) => {
    try {
      await API.delete(`revised_activities/${id}`);
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const createRvoEvent = createAsyncThunk(
  'events/createRvoEvent',
  async (values: FormData, { getState, rejectWithValue }) => {
    try {
      const store = getState() as StoreType;
      const companyId = store?.companies?.id;
      const response = await API.post(`revised_activities`, values, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      // add event to store only if event contains company that is currently browsed, see reducer
      if (companyId && values.getAll('company_ids[]').includes(companyId)) {
        response.data.browsedCompany = true;
        return response.data;
      }
      return response.data as RvoEventsEntityResponse;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const updateRvoEvent = createAsyncThunk(
  'events/updateRvoEvent',
  async (values: FormData, { rejectWithValue }) => {
    try {
      const response = await API.put(`revised_activities/${values.get('id')}`, values, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data as RvoEventsEntityResponse;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const updateRvoEventTips = createAsyncThunk(
  'events/updateRvoEventTips',
  async (attributes: RvoEventsTipsATPropsResponse, { rejectWithValue }) => {
    try {
      const response = await API.post(`revised_activities/tips`, { data: { attributes } });
      return response.data;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const getEventsCSV = async (ids: string[]) => {
  const response = await API.post(
    `revised_activities`,
    {
      id: ids.join(','),
    },
    {
      params: { page: 1, page_size: 10000 },
      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
      },
    },
  );

  const blob = new Blob([response.request.response], {
    type: 'application/vnd.ms-excel',
  });

  FileSaver.saveAs(blob, 'rvo-events-export.xls');
  return response.data as RvoEventsEntityResponse;
};
