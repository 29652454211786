import React, { FC } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { StyledLayout, StyledTabContainer } from './styles';
import { TableGrid } from 'components/Table/TableGrid/TableGrid';
import { Card, StyledCardDivider, StyledCardHeader } from 'components/Card/Card';
import { StyledCellContent } from 'components/Table/TableRow/styles';
import { currentUserSelector } from 'ducks/users/selectors';
import { setQuery } from 'ducks/powerSearch/actions';
import { SavedSearchesType } from 'ducks/users/types';
import { StyledLink } from 'containers/companyDetails/BasicData/styles';
import { useModal } from 'components/Modal/Modal';
import { DeleteSavedSearch, SaveSearch } from '../Results/resultsModals';

const componentsMap = {
  name: {
    component: ({ name, state, tag, query }: SavedSearchesType) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const dispatch = useDispatch();
      return (
        <StyledLink
          as='button'
          onClick={() => {
            dispatch(setQuery(query));
            navigate(`/redesigned/search/${tag}?q=${query}`, { state });
          }}
        >
          {name}
        </StyledLink>
      );
    },
    size: 'min-content',
  },
  description: {
    component: ({ description }: { description: string }) => (
      <StyledCellContent>{description || '-'}</StyledCellContent>
    ),
    size: 'auto',
  },
};

const columnsOrder: ('name' | 'description')[] = ['name', 'description'];

export const Bookmarks: FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const currentUser = useSelector(currentUserSelector);
  const tableBody = currentUser?.savedSearches || [];
  const { setModalComponent, showModal, setShowHeader } = useModal();

  const actions = {
    edit: (savedSearch: SavedSearchesType) => {
      setModalComponent(<SaveSearch params={savedSearch} />);
      setShowHeader(false);
      showModal();
    },
    delete: (savedSearch: SavedSearchesType) => {
      setModalComponent(<DeleteSavedSearch itemsSelected={[savedSearch]} />);
      setShowHeader(false);
      showModal();
    },
  };

  return (
    <StyledTabContainer>
      <StyledLayout>
        <Card>
          <StyledCardHeader>{t('top_bar.menu_drawer.bookmarks')}</StyledCardHeader>
          <StyledCardDivider fullWidth />
          <TableGrid
            actionHandlers={actions}
            bodyData={tableBody}
            columnsOrder={columnsOrder}
            componentsMap={componentsMap}
            noResultsMessage={t('power_search.bookmarks.no_bookmarks')}
            withCheckbox={false}
          />
        </Card>
      </StyledLayout>
    </StyledTabContainer>
  );
};
