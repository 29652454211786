import { previousDistrictNames } from 'containers/newVisit/ChooseVisitModal';
import { NewVisitType, setNewVisitDateAndId } from 'ducks/newVisit';
import store from 'store';

export const generateVisit = async (
  light: boolean | undefined,
  type: NewVisitType | undefined,
  visitDate: string,
  allDistricts: string[],
) => {
  const callback = async (id: string | number) => {
    const path = `/redesigned/new-ordinary-visit/${id}/workplace`;

    window.history.replaceState(
      {
        ...window.history.state,
        previousPathname: path,
        districtNames: {
          type: 'string_array',
          values: allDistricts,
        },
        previousDistrictNames: previousDistrictNames(),
      },
      '',
      path,
    );
  };

  await store.dispatch(
    setNewVisitDateAndId({
      visitDate,
      type,
      light,
      subTypeId: light ? window.history.state.subType.value : undefined,
      callback,
    }),
  );

  return store.getState().newVisit.id;
};
