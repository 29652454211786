import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CertificateIcon } from 'images/certificate.svg';
import { ContactEntity } from 'ducks/contacts/types';
import { StyledLink } from '../BasicData/styles';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { StyledEmail, StyledIconWrap, StyledName, StyledRole, StyledWrapper } from './styles';

export const PreviewContactRow = ({ contact }: { contact: ContactEntity }) => {
  const { t } = useTranslation();

  const description = `${contact.role} ${
    contact.companyName && `${t('company_details.contacts.role_at')} ${contact.companyName}`
  }`;
  return (
    <div style={{ minHeight: '60px', height: 'fit-content' }}>
      <StyledWrapper>
        <StyledName>{contact.name}</StyledName>
        {contact.obligatoryTraining && (
          <StyledIconWrap>
            <CertificateIcon />
          </StyledIconWrap>
        )}
        <Tooltip label={description}>
          <StyledRole>{description}</StyledRole>
        </Tooltip>
      </StyledWrapper>
      <div>
        {contact.email && <StyledEmail>{contact.email}</StyledEmail>}
        {contact.phone && <StyledLink href={`tel:${contact.phone}`}>{contact.phone}</StyledLink>}
      </div>
      {contact.comment && (
        <div>
          <h4>{t('common.comment')}</h4>
          {contact.comment}
        </div>
      )}
    </div>
  );
};
