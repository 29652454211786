import React, { ComponentType, useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useCombobox } from 'downshift';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';

import { StyledError, StyledFieldWrapper, StyledLabel } from 'components/Forms/styles';
import { StyledMenu, StyledSelectItem } from 'components/FieldSingleSelect/styles';
import { FieldTextPropTypes, TextInput } from 'components/TextInput/TextInput';
import { StyledClearIcon } from 'components/SelectButton/SelectButton';
import { promiseDebounce } from 'utils';
import { StyledSearchIcon } from 'components/Forms/AsyncSelectField/styles';

type Option = { value: string; label: string };

interface SingleSelectFieldPropTypes extends FieldRenderProps<Option | null> {
  additionalInputProps?: FieldTextPropTypes;
  fetchFunction: (query: string) => Promise<Option[]>;
  id?: string;
  isDisabled?: boolean;
  label?: string;
  onSelect?: (selectedItem?: Option | null) => void;
  placeholder?: string;
  required?: boolean;
  size?: 'small' | 'medium' | 'big' | 'fullWidth';
  withClearButton?: boolean;
}

const TOOLTIP_ROOT_ELEMENT = document.querySelector('body');

const AsyncSelectField: ComponentType<SingleSelectFieldPropTypes> = ({
  additionalInputProps,
  input,
  placeholder,
  id,
  label,
  size = 'fullWidth',
  required,
  fetchFunction,
  withClearButton,
  meta,
  isDisabled,
  onSelect,
}: SingleSelectFieldPropTypes) => {
  const [inputItems, setInputItems] = useState<Option[]>([]);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLUListElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        options: { fallbackPlacements: ['top'] },
      },
    ],
  });

  const debouncedFetch = promiseDebounce(async (query: string) => fetchFunction(query), 300);

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    setInputValue,
    reset,
  } = useCombobox({
    items: inputItems,
    onInputValueChange: async ({ inputValue, selectedItem }) => {
      if (selectedItem?.label !== inputValue) {
        try {
          const options = await debouncedFetch(inputValue);
          setInputItems(options);
        } catch (err) {}
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      input.onChange(selectedItem);
      onSelect && onSelect(selectedItem);
    },
    itemToString: (item) => item?.label || '',
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.FunctionReset:
          input.onChange(selectedItem);
          break;
        default:
          break;
      }
    },
  });

  useEffect(() => {
    setInputValue(input?.value?.label || '');
  }, [input.value]);

  return (
    <StyledFieldWrapper id={id}>
      {label && (
        <StyledLabel {...getLabelProps()}>
          {label}
          {required ? '*' : ''}
        </StyledLabel>
      )}

      <div ref={setReferenceElement}>
        <div {...getComboboxProps()}>
          <TextInput
            inputProps={{
              ...getInputProps(),
              onBlur: (e) => {
                if (setInputValue && e.currentTarget.value !== selectedItem?.label) {
                  input.onChange(selectedItem);
                  setInputValue(selectedItem?.label || '');
                }
              },
              name: input.name,
            }}
            isDisabled={isDisabled || false}
            isError={meta.touched && meta.error}
            placeholder={placeholder || ''}
            postfixElement={withClearButton && !isDisabled && <StyledClearIcon onClick={reset} />}
            prefixElement={<StyledSearchIcon />}
            size={size}
            {...additionalInputProps}
          />
          {meta.touched && meta.error && <StyledError>{meta.error}</StyledError>}
        </div>
      </div>

      {createPortal(
        <div {...getMenuProps()}>
          {isOpen && (
            <StyledMenu
              isOpen={isOpen}
              ref={setPopperElement}
              style={{ ...styles.popper, maxHeight: '40vh' }}
              {...attributes.popper}
            >
              {inputItems.map((item, index) => (
                <StyledSelectItem
                  isHighlighted={highlightedIndex === index}
                  isSelected={selectedItem?.value === item.value}
                  key={index}
                  {...getItemProps({ item, index })}
                >
                  {item.label}
                </StyledSelectItem>
              ))}
            </StyledMenu>
          )}
        </div>,
        TOOLTIP_ROOT_ELEMENT!,
      )}
    </StyledFieldWrapper>
  );
};

AsyncSelectField.defaultProps = {
  options: [],
  required: false,
};

export { AsyncSelectField };
