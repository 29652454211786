import React, { FC } from 'react';

import { StyledLabel, StyledPrimaryLabel, StyledThinLabel } from 'components/StandardLabel/styles';

const labelVariants = {
  default: StyledLabel,
  thin: StyledThinLabel,
  primary: StyledPrimaryLabel,
};

interface StandardLabelPropTypes {
  className?: string;
  isDisabled?: boolean;
  testid?: string;
  variant?: keyof typeof labelVariants;
}

const StandardLabel: FC<StandardLabelPropTypes> = ({
  children,
  className = undefined,
  isDisabled = false,
  testid = undefined,
  variant = 'default',
}) => {
  const UsedLabel = labelVariants[variant];

  return (
    <UsedLabel className={className} data-testid={testid} isDisabled={isDisabled}>
      {children}
    </UsedLabel>
  );
};

export { StandardLabel };
