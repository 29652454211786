import React, { useMemo } from 'react';

import { Card, StyledCardDivider, StyledCardHeader } from 'components/Card/Card';
import { TableGrid } from 'components/Table/TableGrid/TableGrid';
import { CompanyEntity } from 'ducks/companies/types';
import { useTranslation } from 'react-i18next';

interface BasicDataNacePropTypes {
  company: CompanyEntity;
}

const componentsMap = {
  code: {
    component: ({ code }: { code: string }) => <>{code || '-'}</>,
    size: 'min-content',
  },
  name: {
    component: ({ name }: { name: string }) => <>{name || '-'}</>,
    size: 'auto',
  },
};

export const BasicDataNace = ({ company }: BasicDataNacePropTypes) => {
  const { classificationCodes } = company;
  const { t } = useTranslation();
  const tableBody = useMemo(
    () =>
      classificationCodes.map(({ code, title }, id) => ({
        code,
        name: title,
        id: String(id),
      })),
    [classificationCodes],
  );
  const columnsOrder: ('code' | 'name')[] = ['code', 'name'];

  return (
    <Card>
      <StyledCardHeader>{t('company_details.basic-data.nace')}</StyledCardHeader>
      <StyledCardDivider fullWidth />
      <TableGrid
        bodyData={tableBody}
        columnsOrder={columnsOrder}
        componentsMap={componentsMap}
        loadResource='NACE'
        noResultsMessage={t('company_details.basic-data.no_nace')}
        withCheckbox={false}
      />
    </Card>
  );
};
