import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { PopupMenu } from 'components/PopupMenu/PopupMenu';
import { StyledMenuItem } from 'components/PopupMenu/styles';
import { SearchBar } from 'components/SearchBar/SearchBar';
import { MenuDrawer } from 'components/MenuDrawer/MenuDrawer';
import { useScreenSizeContext } from 'contexts/ScreenSizeContext';
import { Margin } from 'styles/utils';
import { AvatarMenu } from './AvatarMenu/AvatarMenu';
import { BASE_URL } from 'api';
import { StyledSearchWrap, StyledSymbol, StyledTopBar } from './styles';
import { StyledSearchBarMobile } from 'components/SearchBar/styles';
import { currentUserSelector } from 'ducks/users/selectors';
import { useModal } from 'components/Modal/Modal';
import { ChooseVisitModal } from 'containers/newVisit/ChooseVisitModal';
import { ProfileModal } from 'components/ProfileModal/ProfileModal';
import { ReactComponent as PlusIcon } from 'images/plusIcon.svg';
import { ReactComponent as MenuLeftIcon } from 'images/menuLeftIcon.svg';
import { ReactComponent as PeopleIcon } from 'images/peopleIcon.svg';
import { ReactComponent as LogoutIcon } from 'images/logoutIcon.svg';
import { ReactComponent as SearchIcon } from 'images/searchIcon.svg';
import { resetReport } from 'ducks/reports/reducers';
import { fetchEmbededUrl } from 'ducks/reports/actions';
import { StoreType } from 'store';
import { signOut } from 'containers/Auth/helpers';
import toast from 'react-hot-toast';
import { resetCurrentUser } from 'ducks/users/reducer';

export const TopBar: FC = () => {
  const { t } = useTranslation();
  const { md, lg } = useScreenSizeContext();
  const [isMenuOpen, toggleMenu] = useState(false);
  const [isSearchBarOpened, openSearchbar] = useState(false);
  const currentUser = useSelector(currentUserSelector);
  const { setModalComponent, setShowHeader, showModal, setCustomStyles } = useModal();
  const dispatch = useDispatch();
  const reports = useSelector((state: StoreType) => state.reports);

  React.useEffect(() => {
    dispatch(resetReport());
  }, []);

  React.useEffect(() => {
    dispatch(fetchEmbededUrl());
  }, [dispatch]);
  if (!currentUser) return null;

  const signOutUser = async () => {
    try {
      await signOut();
      toast.success(t('auth.success.logout_confirmation'));
      await dispatch(resetCurrentUser());
    } catch (e) {
      console.log(e);
      toast.error(t('auth.error.generic_error'));
    }
  };

  return (
    <StyledTopBar>
      <a href={BASE_URL}>
        <StyledSymbol />
      </a>
      {md ? (
        <StyledSearchWrap>
          <SearchBar />
        </StyledSearchWrap>
      ) : (
        <Margin left='auto'>
          <DefaultButton
            icon={<SearchIcon />}
            isRound
            onClick={() => openSearchbar(!isSearchBarOpened)}
            variant='tertiary'
          />
        </Margin>
      )}

      <DefaultButton
        icon={<PlusIcon title={!lg ? t('common.new_visit') : undefined} />}
        isRound={!lg}
        label={lg ? t('common.new_visit') : undefined}
        onClick={() => {
          md ? setCustomStyles({ maxWidth: '75rem' }) : setCustomStyles({ maxWidth: '42rem' });
          setModalComponent(<ChooseVisitModal />);
          setShowHeader(false);
          showModal();
        }}
        variant='primary'
      />

      <PopupMenu ToggleComponent={AvatarMenu}>
        <StyledMenuItem
          onClick={() => {
            setModalComponent(<ProfileModal />);
            setCustomStyles({ maxWidth: '78rem' });
            setShowHeader(false);
            showModal();
          }}
        >
          <PeopleIcon />
          {t('common.profile')}
        </StyledMenuItem>
        <StyledMenuItem onClick={signOutUser}>
          <LogoutIcon />
          {t('common.logout')}
        </StyledMenuItem>
      </PopupMenu>

      <DefaultButton
        icon={<MenuLeftIcon />}
        label={md ? t('common.menu') : undefined}
        onClick={() => toggleMenu(true)}
        variant='flat'
      />
      <MenuDrawer
        handleClose={() => toggleMenu(false)}
        isAdmin={currentUser.admin}
        isMenuOpen={isMenuOpen}
        reports={reports && !(reports.error || reports instanceof Error) ? reports : []}
      />
      {!md && isSearchBarOpened && (
        <StyledSearchBarMobile noPadding>
          <SearchBar />
        </StyledSearchBarMobile>
      )}
    </StyledTopBar>
  );
};
