import React from 'react';

import { StyleSheetManager, ThemeProvider } from 'styled-components/macro';
import { Provider } from 'react-redux';
import { Normalize } from 'styled-normalize';
import { GlobalStyle } from 'styles/globalStyle';
import { theme } from 'consts/theme';

import { ModalProvider } from 'components/Modal/Modal';
import { LoadingContextProvider } from 'contexts/LoadingContext';
import { ScreenSizeContextProvider } from 'contexts/ScreenSizeContext';

import store from '../../store';
import 'i18n';

export const AppProvider = ({ children }) => (
  <Provider store={store}>
    <StyleSheetManager disableVendorPrefixes={window.REACT_APP_ENV.NODE_ENV === 'development'}>
      <ThemeProvider theme={theme}>
        <ScreenSizeContextProvider>
          <LoadingContextProvider>
            <Normalize />
            <GlobalStyle />
            <ModalProvider>{children}</ModalProvider>
          </LoadingContextProvider>
        </ScreenSizeContextProvider>
      </ThemeProvider>
    </StyleSheetManager>
  </Provider>
);
