import styled from 'styled-components/macro';

import { CapsLabel } from 'components/CapsLabel/CapsLabel';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { MultiSelectList } from 'components/MultiSelectList/MultiSelectList';
import { PopperDropdown } from 'components/PopperDropdown/PopperDropdown';
import { StyledDropdownContainer } from 'components/PopperDropdown/styles';

export const StyledInputWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.sizes.sm};

  & > div,
  & input {
    cursor: pointer;
  }
`;

export const StyledCapsLabel = styled(CapsLabel)`
  margin-bottom: 3.2rem;
  display: block;
`;

export const CentredDefaultButton = styled(DefaultButton)`
  margin: auto;
`;

export const SingleListContainer = styled.div`
  padding: 0.4rem 0;
`;

export const FilterMultiSelectList = styled(MultiSelectList)`
  width: 26rem;
`;

export const FilterDropdown = styled(PopperDropdown)`
  & ${StyledDropdownContainer} {
    width: 26rem;
  }
`;
