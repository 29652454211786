import React, { ComponentType, SVGProps, useMemo, useState } from 'react';

import { Checkbox } from 'components/Checkbox/Checkbox';
import { ActionDrawer } from 'components/ActionDrawer/ActionDrawer';

import {
  ActionDrawerWrap,
  CellWrap,
  ErrorCell,
  StyledCell,
  StyledChevron,
  StyledDropdownRow,
} from './styles';
import { useBatchActionsContext } from 'contexts/BatchActionsContext/BatchActionsContext';
import { ActionsHandlers, TableComponentsMap, TableEntity } from 'components/Table/types';
import { StyledDrawerButton } from 'components/ActionDrawer/styles';
import { Margin } from 'styles/utils';
import { Tooltip } from 'components/Tooltip/Tooltip';

interface TableRowPropTypes<T, K> {
  actionHandlers?: ActionsHandlers;
  columnsOrder: (keyof T)[];
  componentsMap: T;
  CustomToggleDropdownIcon?: ComponentType<SVGProps<SVGSVGElement> & { isOpen?: boolean }>;
  disabled?: boolean;
  DropdownRowComponent?: ComponentType<K>;
  entity?: K;
  expandableButtonTooltips?: { opened?: string; closed?: string };
  isExpandable?: boolean;
  withActions?: boolean;
  withCheckbox?: boolean;
}

export const TableRow = <T extends TableComponentsMap, K extends TableEntity>({
  actionHandlers,
  columnsOrder,
  componentsMap,
  CustomToggleDropdownIcon,
  disabled,
  DropdownRowComponent,
  entity,
  expandableButtonTooltips,
  isExpandable = false,
  withActions,
  withCheckbox,
}: TableRowPropTypes<T, K>) => {
  const { addSelection, deleteSelection, isSelected } = useBatchActionsContext();
  const [isExpanded, setIsExpanded] = useState(false);

  const actionsWithHandlers: { handler: Function; type: string }[] = [];
  actionHandlers &&
    entity?.actions?.forEach((action) => {
      // @ts-ignore
      const handler = actionHandlers[action.type] as Function;
      handler && actionsWithHandlers.push({ ...action, handler: () => handler(entity) });
    });

  const gridColumnEnd: number = useMemo(() => {
    return (
      columnsOrder.length + Number(!!withActions || !!isExpandable) * 2 + Number(!!withCheckbox) + 1
    );
  }, [columnsOrder, withActions, withCheckbox, isExpandable]);

  if (!entity) return null;

  const fieldType = entity.id ? 'id' : !!entity.companyVisitId ? 'companyVisitId' : 'visitId';
  const entityFieldType = entity[fieldType];
  const isActive = isSelected(entityFieldType, fieldType);

  const expendedTooltip = (): string => {
    if (expandableButtonTooltips) {
      if (isExpanded) {
        return expandableButtonTooltips.opened || '';
      } else {
        return expandableButtonTooltips.closed || '';
      }
    }
    return '';
  };

  const expandableButton = (
    <StyledDrawerButton
      icon={
        !!CustomToggleDropdownIcon ? (
          <CustomToggleDropdownIcon />
        ) : (
          <StyledChevron isFlipped={isExpanded} />
        )
      }
      onClick={() => setIsExpanded((x) => !x)}
      size='small'
      testid='table-row-expand-btn'
      variant='tertiary'
    />
  );

  return (
    <>
      {withCheckbox && (
        // @ts-ignore
        <CellWrap data-testid={`tr-checkbox-${entityFieldType}`} isActive={isActive}>
          <Checkbox
            isChecked={isActive}
            isDisabled={disabled}
            onClick={() =>
              isActive
                ? entityFieldType && deleteSelection(entityFieldType, fieldType)
                : entityFieldType && addSelection(entityFieldType, entity, fieldType)
            }
          />
        </CellWrap>
      )}
      {columnsOrder.map((columnName) => {
        const Component = componentsMap[columnName]?.component || ErrorCell;
        return (
          <StyledCell
            data-testid={`tr-${columnName}-${entityFieldType}`}
            isActive={isActive}
            isExpanded={isExpanded}
            key={columnName as string}
          >
            <Component {...entity} disabled={disabled} />
          </StyledCell>
        );
      })}
      {(withActions || isExpandable) && (
        <>
          <ActionDrawerWrap
            data-testid={`tr-actions-${entityFieldType}`}
            isActive={isActive}
            isExpanded={isExpanded}
          >
            {withActions && <ActionDrawer actions={actionsWithHandlers} disabled={disabled} />}
            {isExpandable && (
              <Margin left='0.8rem'>
                {expandableButtonTooltips &&
                (expandableButtonTooltips.opened || expandableButtonTooltips.closed) ? (
                  <Tooltip label={expendedTooltip()}>{expandableButton}</Tooltip>
                ) : (
                  expandableButton
                )}
              </Margin>
            )}
          </ActionDrawerWrap>
          <div /> {/* this div is a hack that fixes problem with position: sticky on firefox*/}
        </>
      )}

      {isExpandable && DropdownRowComponent && (
        <StyledDropdownRow
          className={isExpanded ? 'is-expanded' : 'is-collapsed'}
          data-testid='table-dropdown-row'
          gridColumnEnd={gridColumnEnd}
          isOpen={isExpanded}
        >
          <DropdownRowComponent {...entity} />
        </StyledDropdownRow>
      )}
    </>
  );
};
