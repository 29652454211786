import styled from 'styled-components/macro';

export const StyledDropdownWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.sizes.xs};
  margin-right: ${({ theme }) => theme.sizes.xs};
  position: relative;
`;

export const StyledDropdownContainer = styled.div<{ noPadding: boolean }>`
  background-color: white;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  box-shadow: ${({ theme }) => theme.boxShadows.coolGrey400};
  min-width: 20rem;
  padding: ${({ noPadding }) => (noPadding ? '0' : '1.6rem')};
  position: absolute;
  z-index: 10000;
`;
