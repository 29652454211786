import styled, { css } from 'styled-components/macro';

export type StickyOptionsype = 'horizontal' | 'vertical' | 'both';

interface StyledHeaderColumnPropTypes {
  withHover?: boolean;
  sticky?: StickyOptionsype;
}

const stickyModes = (type: StickyOptionsype) => {
  switch (type) {
    case 'horizontal':
      return css`
        position: sticky;
        right: 0;
      `;
    case 'vertical':
      return css`
        position: sticky;
        top: 0;
      `;
    case 'both':
      return css`
        position: sticky;
        right: 0;
        top: 0;
      `;
    default:
      return '';
  }
};

export const StyledHeaderColumn = styled.div<StyledHeaderColumnPropTypes>`
  ${({ theme }) => theme.fontSizes.sm};
  align-items: center;
  background-color: white;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black100};
  color: ${({ theme }) => theme.colors.black400};
  display: flex;
  justify-content: flex-start;
  padding: ${({ theme }) => `0 ${theme.tableVerticalPadding} ${theme.sizes.hmd}`};

  & > * {
    transition: color 0.3s;
  }

  ${({ withHover, theme }) =>
    withHover &&
    css`
      cursor: pointer;
      user-select: none;

      &:hover > * {
        color: ${theme.colors.black900};
      }
    `}

  ${({ sticky }) => sticky && stickyModes(sticky)};
`;

export const StyledSortIcon = styled.div`
  ${({ theme }) => theme.icon.sm};
  color: ${({ theme }) => theme.colors.black100};
  transform: translateX(0.3rem);
`;
