import { getWorkplaceContactRoles } from 'ducks/contacts/actions';
import { createSlice } from '@reduxjs/toolkit';

import { ContactEntity, ContactEntityResponse, ContactsState } from 'ducks/contacts/types';
import {
  addCompanyContact,
  deleteCompanyContact,
  editCompanyContact,
  getCompanyContactRoles,
  getCompanyContacts,
} from './actions';

const initialState: ContactsState = {
  data: {
    tableBody: [],
  },
  error: undefined,
  meta: {
    columnsOrder: [],
    filters: {},
    page: 1,
    pageSize: 10,
    totalCount: 1,
    totalPages: 1,
  },
  roles: [],
  workplaceRoles: [],
};

export const amendContactEntity = (entity: ContactEntityResponse): ContactEntity => ({
  ...entity,
});

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    resetContactsReducer: () => initialState,
    clearContactsError: (state) => ({ ...state, error: undefined }),
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyContacts.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(getCompanyContacts.fulfilled, (state, { payload }) => {
      state.data.tableBody = payload.data.tableBody.map(amendContactEntity);
      state.meta = { ...state.meta, ...payload.meta };
    });
    builder.addCase(getCompanyContactRoles.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(getCompanyContactRoles.fulfilled, (state, { payload }) => {
      state.roles = payload;
    });
    builder.addCase(getWorkplaceContactRoles.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(getWorkplaceContactRoles.fulfilled, (state, { payload }) => {
      state.workplaceRoles = payload;
    });
    builder.addCase(addCompanyContact.fulfilled, (state, { payload }) => {
      state.data.tableBody = [...state.data.tableBody, amendContactEntity(payload)];
      if (typeof state.meta.totalCount === 'number') ++state.meta.totalCount;
    });
    builder.addCase(addCompanyContact.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(editCompanyContact.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(editCompanyContact.fulfilled, (state, { payload }) => {
      state.data.tableBody = state.data.tableBody.reduce(
        (acc: ContactEntity[], cur) =>
          cur.id === payload.id ? [...acc, amendContactEntity(payload)] : [...acc, cur],
        [],
      );
    });
    builder.addCase(deleteCompanyContact.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(deleteCompanyContact.fulfilled, (state, { meta }) => {
      state.data.tableBody = state.data.tableBody.filter((contact) => contact.id !== meta.arg);
      if (typeof state.meta.totalCount === 'number') --state.meta.totalCount;
    });
  },
});

const {
  reducer: contactsReducer,
  actions: { resetContactsReducer, clearContactsError },
} = contactsSlice;

export { contactsReducer, resetContactsReducer, clearContactsError };
