import React, { FC } from 'react';
import { StyledFallbackLink } from './styles';
import { useTranslation } from 'react-i18next';

export const FallbackLink: FC = () => {
  const { t } = useTranslation();
  return (
    <StyledFallbackLink>
      <h1>{t('common.fallback_error')}</h1>
      <a href='/redesigned/dashboard'>{t('common.back_to_dashboard')}</a>
    </StyledFallbackLink>
  );
};
