import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Tab, TabContainer, TabSubtext } from './styles';

type TabNameType = string | { main: string; sub: string };

const Tabs: FC<{
  activeTab: string;
  handleClick: Function;
  translate?: boolean;
  tabNames: TabNameType[];
  withScroll?: boolean;
}> = ({ activeTab, handleClick, withScroll = false, tabNames, translate = false }) => {
  const { t } = useTranslation();

  return (
    <TabContainer withScroll={withScroll}>
      {tabNames.map((tabName: TabNameType) =>
        typeof tabName === 'string' ? (
          <Tab
            active={activeTab === tabName}
            key={tabName}
            length={(translate ? t(`company_details.tabs.${tabName}`) : tabName).length}
            onClick={() => handleClick(tabName)}
          >
            {translate ? t(`company_details.tabs.${tabName}`) : tabName}
          </Tab>
        ) : (
          <Tab
            active={activeTab === tabName.main}
            key={tabName.main}
            length={(translate ? t(`company_details.tabs.${tabName.main}`) : tabName.main).length}
            onClick={() => handleClick(tabName)}
            withSubtext
          >
            {translate ? t(`company_details.tabs.${tabName.main}`) : tabName.main}
            {tabName.sub && (
              <TabSubtext>
                {translate ? t(`company_details.tabs.${tabName.sub}`) : tabName.sub}
              </TabSubtext>
            )}
          </Tab>
        ),
      )}
    </TabContainer>
  );
};

export { Tabs };
