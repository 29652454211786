import { createAsyncThunk } from '@reduxjs/toolkit';
import FileSaver from 'file-saver';

import { API } from 'api';
import {
  SearchRevisedActivitiesParams,
  SearchRevisedActivitiesResponse,
  SubmitRevisedActivityDialogParams,
} from './types';
import { AxiosError } from 'axios';

export const searchRevisedActivities = createAsyncThunk(
  'search/revised_activities',
  async (params: SearchRevisedActivitiesParams, { rejectWithValue, signal }) => {
    try {
      const response = await API.get(`power/revised_activities`, { params, signal });
      return response.data as SearchRevisedActivitiesResponse;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const searchAllRevisedActivities = async (params: SearchRevisedActivitiesParams) => {
  const response = await API.get(`power/revised_activities`, {
    params: { ...params, page_size: 10000 },
  });
  return response.data as SearchRevisedActivitiesResponse;
};

export const createRevisedActivityDialogPath = createAsyncThunk(
  'revisedActivity/createDialogPath',
  async (attributes: SubmitRevisedActivityDialogParams, { rejectWithValue }) => {
    try {
      const response = await API.post(`revised_activities/dialog`, {
        data: { attributes },
      });
      return response.data;
    } catch (e) {
      return rejectWithValue((e as AxiosError).response?.data);
    }
  },
);

export const getRevisedActivitiesCSV = async (ids: string[]) => {
  const response = await API.post(
    `power/revised_activities`,
    {
      id: ids.join(','),
    },
    {
      params: { page: 1, page_size: 10000 },
      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
      },
    },
  );

  const blob = new Blob([response.request.response], {
    type: 'application/vnd.ms-excel',
  });

  FileSaver.saveAs(blob, 'contacts-export.xls');
  return response.data as SearchRevisedActivitiesResponse;
};
