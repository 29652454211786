/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback } from 'react';
import { useAppDispatch } from 'store';

import { StyledNoWrapCellContent } from 'components/Table/TableRow/styles';
import i18n from 'i18n';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { StyledLink } from 'containers/companyDetails/BasicData/styles';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { NewVisitContact, setStep2Contact, setStep3Contact } from 'ducks/newVisit';

import { ReactComponent as CertificateIcon } from 'images/certificate.svg';
import { ReactComponent as PdfIcon } from 'images/pdfIcon.svg';
import { ReactComponent as EnvelopeIcon } from 'images/envelopeIcon.svg';

const t = (x: string) => i18n.t(x);

const receiveVisitReportHeader = () => (
  <Tooltip centerContent label={t(`table.columns.receive_visit_report`)}>
    <EnvelopeIcon data-testid='th-receive_visit_report' height={20} width={20} />
  </Tooltip>
);

const showInReportHeader = () => (
  <Tooltip centerContent label={t(`table.columns.show_in_report`)}>
    <PdfIcon data-testid='th-show_in_report' height={20} width={20} />
  </Tooltip>
);

const obligatoryTrainingHeader = () => (
  <Tooltip centerContent label={t(`table.columns.obligatory_training`)}>
    <CertificateIcon data-testid='th-obligatory_training' height={20} width={20} />
  </Tooltip>
);

const contactsComponentsMapBase = {
  name: {
    component: ({ name }: NewVisitContact) => (
      <StyledNoWrapCellContent>{name || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  role: {
    component: ({ role }: NewVisitContact) => (
      <StyledNoWrapCellContent>{role || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  email: {
    component: ({ email }: NewVisitContact) => (
      <StyledNoWrapCellContent>{email || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  phone: {
    component: ({ phone }: NewVisitContact) =>
      phone ? (
        <StyledLink href={`tel:${phone}`}>{phone}</StyledLink>
      ) : (
        <StyledNoWrapCellContent>-</StyledNoWrapCellContent>
      ),
    size: 'min-content',
  },
  comment: {
    component: ({ comment }: NewVisitContact) => (
      <StyledNoWrapCellContent>{comment || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
};

export const contactsComponentsMapStep2 = {
  ...contactsComponentsMapBase,
  obligatory_training: {
    component: (contact: NewVisitContact & { workplace_id: string }) => {
      const dispatch = useAppDispatch();
      const onClick = useCallback(() => {
        dispatch(
          setStep2Contact({
            id: contact.id,
            include_in_report: contact.includeInReport,
            obligatory_training: !contact.obligatoryTraining,
            origin: contact.origin,
            receive_visit_report: contact.receiveVisitReport,
            visit_id: contact.visitId,
            workplace_id: contact.workplaceId!,
          }),
        );
      }, [contact]);

      return (
        <Checkbox
          isChecked={contact.obligatoryTraining}
          isDisabled={contact.loading}
          key={`${contact.id}-ot`}
          onClick={onClick}
        />
      );
    },
    headerComponent: obligatoryTrainingHeader,
    size: 'min-content',
  },

  show_in_report: {
    component: (contact: NewVisitContact & { workplace_id: string }) => {
      const dispatch = useAppDispatch();
      const onClick = useCallback(() => {
        dispatch(
          setStep2Contact({
            id: contact.id,
            include_in_report: !contact.includeInReport,
            obligatory_training: contact.obligatoryTraining,
            origin: contact.origin,
            receive_visit_report: contact.receiveVisitReport,
            visit_id: contact.visitId,
            workplace_id: contact.workplaceId!,
          }),
        );
      }, [contact]);

      return (
        <Checkbox
          isChecked={contact.includeInReport}
          isDisabled={contact.loading}
          key={`${contact.id}-ot`}
          onClick={onClick}
        />
      );
    },
    headerComponent: showInReportHeader,
    size: 'min-content',
  },
  receive_visit_report: {
    component: (contact: NewVisitContact & { workplace_id: string }) => {
      const dispatch = useAppDispatch();
      const onClick = useCallback(() => {
        dispatch(
          setStep2Contact({
            id: contact.id,
            include_in_report: contact.includeInReport,
            obligatory_training: contact.obligatoryTraining,
            origin: contact.origin,
            receive_visit_report: !contact.receiveVisitReport,
            visit_id: contact.visitId,
            workplace_id: contact.workplaceId!,
          }),
        );
      }, [contact]);

      return (
        <Checkbox
          isChecked={contact.receiveVisitReport}
          isDisabled={contact.loading}
          key={`${contact.id}-ot`}
          onClick={onClick}
        />
      );
    },
    headerComponent: receiveVisitReportHeader,
    size: 'min-content',
  },
};

// ---------    step 3 ------------

export const contactsComponentsMapStep3 = {
  ...contactsComponentsMapBase,
  obligatory_training: {
    component: (contact: NewVisitContact) => {
      const dispatch = useAppDispatch();
      const onClick = useCallback(() => {
        dispatch(
          setStep3Contact({
            company_visit_id: contact.companyVisitId!,
            id: contact.id,
            include_in_report: contact.includeInReport,
            obligatory_training: !contact.obligatoryTraining,
            origin: contact.origin,
            receive_visit_report: contact.receiveVisitReport,
            visit_id: contact.visitId,
          }),
        );
      }, [contact]);

      return (
        <Checkbox
          isChecked={contact.obligatoryTraining}
          isDisabled={contact.loading || contact.disabled}
          key={`${contact.id}-ot`}
          onClick={onClick}
        />
      );
    },
    headerComponent: obligatoryTrainingHeader,
    size: 'min-content',
  },

  show_in_report: {
    component: (contact: NewVisitContact) => {
      const dispatch = useAppDispatch();
      const onClick = useCallback(() => {
        dispatch(
          setStep3Contact({
            company_visit_id: contact.companyVisitId!,
            id: contact.id,
            include_in_report: !contact.includeInReport,
            obligatory_training: contact.obligatoryTraining,
            origin: contact.origin,
            receive_visit_report: contact.receiveVisitReport,
            visit_id: contact.visitId,
          }),
        );
      }, [contact]);

      return (
        <Checkbox
          isChecked={contact.includeInReport}
          isDisabled={contact.loading || contact.disabled}
          key={`${contact.id}-ot`}
          onClick={onClick}
        />
      );
    },
    headerComponent: showInReportHeader,
    size: 'min-content',
  },
  receive_visit_report: {
    component: (contact: NewVisitContact) => {
      const dispatch = useAppDispatch();
      const onClick = useCallback(() => {
        dispatch(
          setStep3Contact({
            company_visit_id: contact.companyVisitId!,
            id: contact.id,
            include_in_report: contact.includeInReport,
            obligatory_training: contact.obligatoryTraining,
            origin: contact.origin,
            receive_visit_report: !contact.receiveVisitReport,
            visit_id: contact.visitId,
          }),
        );
      }, [contact]);

      return (
        <Checkbox
          isChecked={!!contact.email && contact.receiveVisitReport}
          isDisabled={!contact.email || contact.loading || contact.disabled}
          key={`${contact.id}-ot`}
          onClick={onClick}
        />
      );
    },
    headerComponent: receiveVisitReportHeader,
    size: 'min-content',
  },
};
