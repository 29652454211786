import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { DeleteIcon } from 'utils/iconsMap';
import { StyledObservationActions } from 'containers/newVisit/shared/Observation/style';
import { Tooltip } from 'components/Tooltip/Tooltip';

interface Props {
  isDisabled: boolean;
  isLoading: boolean;
  onRemove: () => void;
}

export const ObservationButtons = ({ isLoading, onRemove, isDisabled }: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <StyledObservationActions>
      <Tooltip label={t('common.delete')}>
        <DefaultButton
          capitalize
          icon={<DeleteIcon />}
          isDisabled={isLoading || isDisabled}
          onClick={onRemove}
          size='small'
          testid='observation-button-delete'
          variant='danger'
        />
      </Tooltip>
    </StyledObservationActions>
  );
};
