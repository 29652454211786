import React, { FC, ReactElement, useState } from 'react';
import {
  StyledCard,
  StyledCardDivider,
  StyledCardMax,
  StyledCardSmall,
  StyledCardWithTabsContent,
} from './styles';
import { Tabs } from 'components/Tabs/Tabs';
import { Margin } from 'styles/utils';

export interface CardProps {
  noPadding?: boolean;
  flexDirection?: string;
}

export const Card: FC<CardProps> = ({ children, noPadding }) => (
  <StyledCard noPadding={noPadding}>{children}</StyledCard>
);

Card.defaultProps = {
  noPadding: false,
};

export const CardSmall: FC<CardProps> = ({ children, noPadding }) => (
  <StyledCardSmall noPadding={noPadding}>{children}</StyledCardSmall>
);

export const CardMax: FC<CardProps> = ({ children, noPadding }) => (
  <StyledCardMax noPadding={noPadding}>{children}</StyledCardMax>
);

interface Tab {
  name: string;
  content: ReactElement;
}

export const CardWithTabs: FC<{ tabs: Tab[] }> = ({ tabs }) => {
  const [activeTab, setTab] = useState(tabs[0].name);

  return (
    <Card noPadding>
      <StyledCardWithTabsContent>
        <Tabs activeTab={activeTab} handleClick={setTab} tabNames={tabs.map((x) => x.name)} />
        <StyledCardDivider />
        <Margin margin='1.2rem 2.4rem'>{tabs.find((x) => x.name === activeTab)?.content}</Margin>
      </StyledCardWithTabsContent>
    </Card>
  );
};

export * from './styles';
