import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { unwrapResult } from '@reduxjs/toolkit';

import { WorkplaceForm, WorkplaceFormSubmitValues } from 'containers/newVisit/shared/WorkplaceForm';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import {
  createNewVisitCompanyVisit,
  createNewVisitWorkplace,
  newVisitAddressSelector,
  newVisitWorkplaceSelector,
  setWorkplaceIdToVisit,
  visitDateSelector,
  visitIdSelector,
  visitParamsSelector,
} from 'ducks/newVisit';
import { useAppDispatch } from 'store';
import { ContentContainer, Margin } from 'styles/utils';
import { ButtonsPanel } from 'containers/newVisit/shared/ButtonsPanel/ButtonsPanel';
import { StyledHorizontalRule } from 'containers/CompanyCreate/styles';
import { Contacts } from 'containers/newVisit/OrdinaryVisit/Contacts';
import { WorkplaceChosen } from 'containers/newVisit/OrdinaryVisit/Step1/WorkplaceChosen';
import { StyledWrappingContainer } from '../styles';
import { generateVisit } from './generateVisit';
import { allFilteredDistrictsSelector } from 'ducks/powerSearch/selectors';

export const NewWorkplace = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const chosenAddress = useSelector(newVisitAddressSelector);
  const chosenWorkplace = useSelector(newVisitWorkplaceSelector);
  const { light, type } = useSelector(visitParamsSelector);
  const visitDate = useSelector(visitDateSelector);
  const visitId = useSelector(visitIdSelector);
  const allDistricts = useSelector(allFilteredDistrictsSelector).map((district) => district.value);

  const workplaceOnSubmit = useCallback(
    async (values: WorkplaceFormSubmitValues) => {
      // returnedVisitId is set by generateVisit function and scoped to this function.
      const returnedVisitId = await generateVisit(light, type, visitDate, allDistricts);
      return dispatch(createNewVisitWorkplace(values))
        .then(unwrapResult)
        .then(async ({ workplaceId }) => {
          await dispatch(setWorkplaceIdToVisit({ visitId: returnedVisitId, workplaceId }));
          const company_id = sessionStorage.getItem('automaticallyAddCompanyToVisit');
          company_id &&
            dispatch(createNewVisitCompanyVisit({ company_id, visit_id: returnedVisitId }));
        });
    },
    [dispatch],
  );

  return (
    <StyledWrappingContainer limitHeight={!!chosenWorkplace}>
      {chosenWorkplace ? (
        <WorkplaceChosen workplace={chosenWorkplace} />
      ) : (
        <ContentContainer margin='1.2rem auto' maxWidth='72rem'>
          <WorkplaceForm
            Buttons={({ onClick, isPrimaryDisabled }) => (
              <Margin left='auto'>
                <DefaultButton
                  capitalize
                  isDisabled={isPrimaryDisabled}
                  label={t('common.save')}
                  onClick={onClick}
                  size='small'
                  variant='success'
                />
              </Margin>
            )}
            isCommentEditable
            onSubmit={workplaceOnSubmit}
          />
        </ContentContainer>
      )}

      <StyledHorizontalRule />
      <Contacts isDisabled={!chosenAddress} />
      {chosenAddress && (
        <ButtonsPanel
          isButtonsActive
          mainButtonHandler={() => navigate('./companies', { state: { ...window.history.state } })}
          mainButtonLabel={t('common.next_step')}
          visitId={String(visitId)}
        />
      )}
    </StyledWrappingContainer>
  );
};
