import React from 'react';

import styled from 'styled-components/macro';

const StyledTextLink = styled.a`
  color: ${({ isDanger, theme }) => (isDanger ? theme.colors.red500 : theme.colors.blue500)};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  & > svg {
    color: ${({ theme }) => theme.colors.black900};
  }
`;

export const TextLink = ({ children, ...rest }) => (
  <StyledTextLink {...rest}>{children}</StyledTextLink>
);
