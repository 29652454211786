import React, { ComponentType, useCallback, useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import Compressor from 'compressorjs';
import { useTranslation } from 'react-i18next';

import { StyledFieldWrapper, StyledLabel } from 'components/Forms/styles';
import { StyledBrowseFiles, StyledDropzoneWrapper } from 'components/Forms/Attachments/styles';
import { Thumbs } from 'components/Forms/Attachments/Thumbs';
import { Documents } from 'components/Forms/Attachments/Documents';
import { RejectedFiles } from 'components/Forms/Attachments/RejectedFiles';
import { FolderIcon, SpinnerIcon } from 'utils/iconsMap';
import toast from 'react-hot-toast';

export interface ExtendFile extends FileWithPath {
  preview: string;
}

export interface AttachmentsProps {
  label?: string;
  value: ExtendFile[];
  onChange: (file: ExtendFile[]) => void;
}

const FILE_SIZE_LIMIT = 20_000_000;

export const Attachments: ComponentType<AttachmentsProps> = ({ value = [], label, onChange }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { t } = useTranslation();

  const onDrop = useCallback(
    async (acceptedFiles: FileWithPath[]) => {
      setIsProcessing(true);
      const dataPromise = acceptedFiles
        .filter((file) => !value.some(({ path }) => path === file.path))
        .map(async (file) => {
          if (file.type.includes('image')) {
            return new Promise<ExtendFile>(
              (resolve, reject) =>
                new Compressor(file, {
                  success: (compressedFile) => {
                    const normalizedFile = new File([compressedFile], file.name, {
                      type: file.type,
                    });
                    resolve(Object.assign(normalizedFile, { preview: URL.createObjectURL(file) }));
                  },
                  quality: 0.7,
                  maxHeight: 3840,
                  maxWidth: 3840,
                  error: reject,
                }),
            );
          } else {
            return Object.assign(file, { preview: URL.createObjectURL(file) });
          }
        });

      try {
        const data = await Promise.all(dataPromise);
        setIsProcessing(false);

        const filteredData = data.filter((item): item is ExtendFile => item !== undefined);
        onChange([...value, ...filteredData]);
      } catch (err) {
        setIsProcessing(false);
        toast.error(t('common.error_message'));
      }
    },

    [value, onChange],
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    accept: [
      'application/pdf',
      'image/png',
      'image/jpeg',
      'video/mp4',
      'image/gif',
      'image/jpg',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'application/msword',
    ],
    maxSize: FILE_SIZE_LIMIT,
    disabled: isProcessing,
  });

  const deleteFile = useCallback(
    ({ name }: { name?: string }) => onChange(value.filter((el) => el.name !== name)),
    [value, onChange],
  );

  return (
    <StyledFieldWrapper>
      {label && <StyledLabel>{label}</StyledLabel>}

      <StyledDropzoneWrapper {...getRootProps()} isActive={isDragActive}>
        <input {...getInputProps()} />
        {isProcessing && <SpinnerIcon />}
        {!isProcessing && isDragActive && t('forms.drop_here')}
        {!isProcessing && !isDragActive && (
          <>
            {t('forms.drag_and_drop')}
            <StyledBrowseFiles>
              <FolderIcon />
              {t('forms.browse_files')}
            </StyledBrowseFiles>
          </>
        )}
      </StyledDropzoneWrapper>

      <Thumbs deleteFile={deleteFile} files={value} />
      <Documents deleteFile={deleteFile} files={value} />
      <RejectedFiles fileRejections={fileRejections} />
    </StyledFieldWrapper>
  );
};
