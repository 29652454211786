import { StyledCheckbox } from 'components/Checkbox/styles';
import styled from 'styled-components/macro';

export const StyledForm = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledContactsRow = styled('div')`
  margin-left: 3.6rem;
`;

export const StyledDateAndTypeContainer = styled('div')`
  display: flex;
  justify-content: center;
`;

export const StyledCheckboxRow = styled('div')`
  display: flex;
  flex-direction: row;

  ${StyledCheckbox} {
  }

  & > * {
    margin-bottom: 2.4rem;
  }

  @media (min-width: 768px) {
    align-items: flex-end;
    flex-direction: row;

    & > div {
      margin-right: 1.2rem;
    }
  }
`;
