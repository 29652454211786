import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { decamelize } from 'humps';
import { isEmpty, omit } from 'lodash';
import { globalHistory } from '@reach/router';

import { useFiltersContext } from 'contexts/FilterContext';
import {
  StyledFiltersContainer,
  StyledFiltersWrapper,
  StyledFilterToggleButton,
  StyledSection,
  StyledTag,
} from 'components/TableFilters/styles';
import { navigateWithState } from 'utils/navigateWithState';

const hiddenFilters = ['districtNames'];

export const TableFilters = ({ isSearchPagination = false, isDashboard = false }) => {
  const { filtersOrder, componentsMap, filtersState, reset } = useFiltersContext();
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();
  const { location } = globalHistory;
  let buttonsCount = 0;

  const filters = useMemo(
    () => filtersOrder.filter((x) => !hiddenFilters.includes(x)),
    [filtersOrder, filtersOrder.length],
  );

  if (isEmpty(filters)) return null;

  // Because both workplace and company have visits that can be sent to tips, two 'Sendt til TIPS' filters are created
  // This removes the duplicate.
  const visibleOrderedFilters = filters.filter((el, i) => {
    return filters.indexOf(el) === i;
  });

  const onReset = () => {
    reset();

    if (isSearchPagination) {
      navigateWithState('search', {
        search: {
          ...omit(location.state?.search, 'filters'),
        },
      });
    } else if (isDashboard) {
      navigateWithState('dashboard', {
        dashboard: {
          ...omit(location.state?.dashboard, 'filters'),
        },
      });
    }
  };

  return (
    <StyledFiltersWrapper data-testid='table-filters'>
      <StyledFiltersContainer isOpen={isOpen}>
        <StyledSection>
          {visibleOrderedFilters.map((filterName) => {
            const { Component, onChange, max, min, options, translate, removeFn } =
              componentsMap[filterName];
            const { isActive, value } = filtersState[filterName] || {};
            const label = t(`table.columns.${decamelize(filterName)}`);

            return (
              <Component
                isActive={isActive}
                key={filterName + Math.random()}
                label={label}
                maxValue={max}
                minValue={min}
                onChange={onChange}
                options={options}
                removeFn={removeFn}
                translate={translate}
                value={value}
              />
            );
          })}
        </StyledSection>

        <StyledSection>
          {visibleOrderedFilters.map((filterName) => {
            const { isActive, displayValue } = filtersState[filterName] || {};
            const { translate, removeFn } = componentsMap[filterName];
            const label = t(`table.columns.${decamelize(filterName)}`);

            if (!isActive) return null;

            buttonsCount += displayValue.length;

            return displayValue
              .sort()
              .map((filterValue) => (
                <StyledTag
                  active
                  handleClick={() => removeFn(filterValue, filtersState[filterName])}
                  key={`${filterName}-${filterValue}`}
                  label={label}
                  value={translate ? t(`filters.${decamelize(filterValue)}`) : filterValue}
                />
              ));
          })}
          {buttonsCount >= 2 && (
            <StyledTag handleClick={onReset} value={t('filters.clear_filters')} />
          )}
        </StyledSection>
      </StyledFiltersContainer>

      <StyledFilterToggleButton onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? t('filters.hide') : t('filters.show')}
      </StyledFilterToggleButton>
    </StyledFiltersWrapper>
  );
};
