import { StyledFieldWrapper } from 'components/Forms/styles';
import styled from 'styled-components/macro';

export const StyledOrgNumberWrapper = styled.form`
  align-items: flex-start;
  display: flex;

  ${StyledFieldWrapper} {
    margin: 0;
  }

  @media (max-width: 576px) {
    align-items: flex-start;
    flex-direction: column;

    & > * {
      margin: ${({ theme }) => theme.sizes.xs} 0;
    }
  }
`;

export const StyledCompanyInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  & > div * {
    margin-right: 1rem;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
    & > :first-child {
      margin-bottom: 1rem;
    }
  }
`;

export const StyledAddressLabelingWrapper = styled.div`
  display: flex;
  align-items: center;
  & > :first-child {
    width: 120px;
    margin-right: ${({ theme }) => theme.sizes.lg};
  }
  & > :second-child {
  }
  label {
    margin-left: ${({ theme }) => theme.sizes.xs};
  }
  @media (max-width: 576px) {
    align-items: flex-start;
    flex-direction: column;
    & > * {
      margin: ${({ theme }) => theme.sizes.xs} 0;
    }
  }
`;

export const StyledFormWrapper = styled.div`
  padding: 0;
  ${({ theme }) => theme.sizes.sm};
`;

export const StyledHorizontalRule = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.black100};
  display: flex;
`;

export const StyledAddressWrapper = styled.div`
  display: flex;

  @media (min-width: 577px) {
    & > div:not(:first-child) {
      margin-left: ${({ theme }) => theme.sizes.sm};
    }

    & > div:first-child {
      flex: 0 1 60%;
    }

    & > div:nth-child(2) {
      flex: 0 1 14rem;
    }

    & > div:nth-child(3) {
      flex: 0 1 8rem;
    }

    & > div:nth-child(4) {
      flex: 0 1 22rem;
    }
    & > div:nth-child(5) {
      flex: 0 1 22rem;
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;

    & > div {
      flex: 0 1;
    }
  }
`;

export const StyledEmailPhoneWrapper = styled.div`
  display: flex;

  @media (min-width: 577px) {
    & > div:first-child {
      flex: 0 1 100%;
    }

    & > div:nth-child(2) {
      flex: 0 1 20rem;
      margin-left: ${({ theme }) => theme.sizes.sm};
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;

    & > div {
      flex: 0 1;
    }
  }
`;
export const StyledBasicDataWrapper = styled.div`
  display: flex;

  @media (min-width: 577px) {
    & > div {
      margin-left: ${({ theme }) => theme.sizes.sm};
    }

    & > div:first-child {
      margin-left: 0px;
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;

    & > div {
      flex: 0 1;
    }
  }
`;
