import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useModal } from 'components/Modal/Modal';
import {
  StyledModalButtonsWrapper,
  StyledModalHeader,
  StyledModalMessage,
} from 'components/Modal/styles';
import { useAppDispatch } from 'store';
import { deleteCompanyVisit, deleteVisit } from 'ducks/newVisit';

interface DeleteCompanyVisitProps {
  companyVisitId: string;
  name: string;
  isALastVisit?: boolean;
  visitId: string;
}

export const DeleteCompanyVisit = (props: DeleteCompanyVisitProps): React.ReactElement => {
  const { companyVisitId, name, visitId, isALastVisit } = props;
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const { hideModal } = useModal();
  const dispatch = useAppDispatch();

  const onSubmit = React.useCallback(async () => {
    setIsProcessing(true);
    await dispatch(deleteCompanyVisit(companyVisitId));
    isALastVisit && (await dispatch(deleteVisit(visitId)));
    hideModal();
  }, [companyVisitId, isALastVisit, visitId]);

  return (
    <>
      <StyledModalHeader>{t('new_visits.remove_the_company_visit')}</StyledModalHeader>

      <StyledModalMessage>{t('new_visits.remove_company_visit_desc', { name })}</StyledModalMessage>
      <StyledModalButtonsWrapper>
        <DefaultButton
          capitalize
          isDisabled={isProcessing}
          label={t('common.cancel')}
          onClick={hideModal}
          type='button'
          variant='tertiary'
        />
        <DefaultButton
          isDisabled={isProcessing}
          label={t('new_visits.remove_company_visit')}
          onClick={onSubmit}
          type='button'
          variant='danger'
        />
      </StyledModalButtonsWrapper>
    </>
  );
};
