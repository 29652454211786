import styled from 'styled-components/macro';

export const StyledRichTextReset = styled.div`
  li,
  ul {
    all: revert;
  }
  img {
    max-width: 100%;
  }
  p,
  h1,
  h2,
  ul {
    margin: 0.4rem 0;
  }
`;
