import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';

import { StyledContainer } from './styles';
import { useTranslation } from 'react-i18next';

export const NotFound: FC<RouteComponentProps> = () => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <span>{t('not_found.not_exist')}</span>
      <p>{t('not_found.description')}</p>
    </StyledContainer>
  );
};
