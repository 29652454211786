import styled, { css } from 'styled-components';

import { TableGridShading } from 'components/Table/TableGrid/styles';

export const StyledContainer = styled.main`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
  border-radius: ${({ theme }) => theme.borderRadius.md};

  ${({ isIpad }) =>
    isIpad &&
    css`
      max-height: -webkit-fill-available;
    `}
`;

export const StyledEmptyVisits = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.black400};

  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.sizes.hmd};
  }
`;

export const StyledEmptyVisitsWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: ${({ theme }) => theme.sizes.sm} 0;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: visible;
  position: relative;
  width: 100%;

  & > * {
    flex: 1 0 auto;
  }

  & > ${TableGridShading} {
    flex: 0 1 auto;
  }

  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.sizes.xs};
  }
`;

export const StyledTableBottomPanel = styled.div`
  display: flex;
  justify-content: space-between;
`;
