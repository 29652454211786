/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BatchActionsContextType, CommonActionType } from './types';
import { useModal } from 'components/Modal/Modal';
import { DeleteContactModal } from 'containers/companyDetails/Contacts/ContactsModals';
import { DeleteMultipleVisitsModalContent } from './ModalComponents';
import { VisitEntity } from 'ducks/visits/types';
import { ContactEntity } from 'ducks/contacts/types';
import { TableEntity } from 'components/Table/types';
import { DeleteRevisedActivity } from 'dialogs/RevisedActivities';
import { RvoEventsEntity } from 'ducks/events/types';

interface ContextType {
  addMultipleSelection: (items: Array<{ id: string; entity: TableEntity }>) => void;
  addSelection: (id: string | number, entity: TableEntity, field?: string) => void;
  clearSelection: () => void;
  commonAction: CommonActionType | null;
  contextName: BatchActionsContextType;
  deleteSelection: (id: string | number, field?: string) => void;
  isSelected: (id?: string | number, field?: string) => boolean;
  itemsSelected: TableEntity[];
  setContextName: (name: BatchActionsContextType) => void;
}

export const BatchActionsContext = React.createContext<ContextType>({
  addMultipleSelection: (items) => items,
  addSelection: () => {},
  clearSelection: () => {},
  commonAction: null,
  contextName: null,
  deleteSelection: () => {},
  isSelected: () => false,
  itemsSelected: [],
  setContextName: () => {},
});

export const BatchActionsProvider: FC = ({ children }) => {
  const [itemsSelected, setItemsSelected] = useState<TableEntity[]>([]);
  const [commonAction, setCommonAction] = useState<CommonActionType | null>(null);
  const [contextName, setContextName] = useState<BatchActionsContextType>(null);
  const { hideModal, setModalComponent, showModal, setShowHeader } = useModal();

  const { t } = useTranslation();

  const isSelected = (id?: string | number, field = 'id'): boolean => {
    // @ts-ignore
    return !!id && Boolean(itemsSelected.find((item) => item[field] === id));
  };

  const addSelection = (id: string | number, entity: TableEntity, field = 'id') => {
    if (!isSelected(id, field)) {
      setItemsSelected([...itemsSelected, entity]);
    }
  };

  const addMultipleSelection = (items: Array<{ id: string; entity: TableEntity }>) => {
    setItemsSelected([
      ...itemsSelected,
      ...items.filter((item) => !isSelected(item.id)).map((x) => x.entity),
    ]);
  };

  const deleteSelection = (id: string | number, field = 'id') => {
    if (isSelected(id, field)) {
      // @ts-ignore
      setItemsSelected(itemsSelected.filter((item) => item[field] !== id));
    }
  };

  const clearSelection = (): void => setItemsSelected([]);

  useEffect(() => {
    setCommonAction(null);

    if (contextName === 'visits') {
      const allHaveDeleteAction = itemsSelected.every((visit) => {
        return visit.actions?.find((action) => action.type === 'delete');
      });

      if (allHaveDeleteAction) {
        setCommonAction({
          label: t('common.delete_all'),
          action: () => {
            setModalComponent(
              <DeleteMultipleVisitsModalContent
                actionCallback={clearSelection}
                hideModal={hideModal}
                itemsSelected={itemsSelected as VisitEntity[]}
              />,
            );
            setShowHeader(false);
            showModal();
          },
        });
      }
    } else if (contextName === 'contacts') {
      const allHaveDeleteAction = itemsSelected.every((contact) => {
        return contact.actions?.find((action) => action.type === 'delete');
      });

      if (allHaveDeleteAction) {
        setCommonAction({
          label: t('common.delete_all'),
          action: () => {
            setModalComponent(
              <DeleteContactModal
                actionCallback={clearSelection}
                itemsSelected={itemsSelected as ContactEntity[]}
              />,
            );
            setShowHeader(false);
            showModal();
          },
        });
      }
    } else if (contextName === 'rvo' || contextName === 'powerRevisedActivities') {
      const allHaveDeleteAction = itemsSelected.every((event) => {
        return event.actions?.find((action) => action.type === 'delete');
      });

      if (allHaveDeleteAction) {
        setCommonAction({
          label: t('common.delete_selected'),
          action: () => {
            setModalComponent(
              <DeleteRevisedActivity
                actionCallback={clearSelection}
                itemsSelected={itemsSelected as RvoEventsEntity[]}
              />,
            );
            setShowHeader(false);
            showModal();
          },
        });
      }
    }
  }, [contextName, itemsSelected, t]);

  return (
    <BatchActionsContext.Provider
      value={{
        addMultipleSelection,
        addSelection,
        clearSelection,
        commonAction,
        contextName,
        deleteSelection,
        isSelected,
        itemsSelected,
        setContextName,
      }}
    >
      {children}
    </BatchActionsContext.Provider>
  );
};

export const useBatchActionsContext = () => React.useContext(BatchActionsContext);
