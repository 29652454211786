import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';

import { WorkplaceChosen } from 'containers/newVisit/OrdinaryVisit/Step1/WorkplaceChosen';
import { WorkplaceSearch } from 'containers/newVisit/OrdinaryVisit/Step1/WorkplaceSearch';
import {
  newVisitAddressSelector,
  newVisitWorkplaceSelector,
  visitIdSelector,
} from 'ducks/newVisit';
import { Contacts } from 'containers/newVisit/OrdinaryVisit/Contacts';
import { ButtonsPanel } from 'containers/newVisit/shared/ButtonsPanel/ButtonsPanel';
import { StyledHorizontalRule } from 'containers/CompanyCreate/styles';
import { StyledWrappingContainer } from '../styles';
import { getWorkplace } from 'ducks/workplaces/actions';
import { workplaceSelector } from 'ducks/workplaces/selectors';
import { SpinnerWrapper } from 'hocs/composedTableProviders/styles';
import { SpinnerIcon } from 'utils/iconsMap';

export const FindWorkplace = () => {
  const chosenAddress = useSelector(newVisitAddressSelector);
  const chosenWorkplace = useSelector(newVisitWorkplaceSelector);
  const workplace = useSelector(workplaceSelector);
  const visitId = useSelector(visitIdSelector);
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fetchAndSetWorkplace = async (workplaceId: string) => {
    setIsFetching(true);
    dispatch(getWorkplace(workplaceId));
    setIsFetching(false);
  };

  useEffect(() => {
    const workplaceId = window.history.state.workplaceId;
    workplaceId && fetchAndSetWorkplace(workplaceId);
  }, []);

  return (
    <StyledWrappingContainer limitHeight={!!chosenAddress}>
      {!chosenAddress && <WorkplaceSearch />}
      {isFetching ? (
        <div style={{ marginTop: 50 }}>
          <SpinnerWrapper>
            <SpinnerIcon />
          </SpinnerWrapper>
        </div>
      ) : (
        (chosenWorkplace || workplace) && (
          <WorkplaceChosen workplace={chosenWorkplace ?? workplace!} />
        )
      )}

      <StyledHorizontalRule />
      <Contacts isDisabled={!chosenAddress} />
      {chosenAddress && !isFetching && (
        <ButtonsPanel
          isButtonsActive
          mainButtonHandler={() => navigate('./companies', { state: { ...window.history.state } })}
          mainButtonLabel={t('common.next_step')}
          visitId={String(visitId)}
        />
      )}
    </StyledWrappingContainer>
  );
};
