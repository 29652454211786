import React, { useEffect, useMemo, useState } from 'react';
import { snakeCase } from 'lodash';

import { Checkbox } from 'components/Checkbox/Checkbox';
import { TextInput } from 'components/TextInput/TextInput';
import { useDropdownContext } from 'components/PopperDropdown/PopperDropdown';

import { ReactComponent as SearchIcon } from 'images/searchIcon.svg';

import {
  StyledCheckboxList,
  StyledCheckboxWrapper,
  StyledClearButton,
  StyledHorizontalRule,
  StyledMultiSelectList,
  StyledSearchWrapper,
  StyledSelectedCounter,
} from './styles';
import { useTranslation } from 'react-i18next';

export interface Option {
  label: string;
  value: string;
  additionalLabel?: string;
}

interface MultiSelectListPropTypes {
  options: Array<Option>;
  onChange: (param: Array<string>) => void;
  value: Array<string>;
  translate?: boolean;
  className?: string;
}

const MultiSelectList = ({
  onChange,
  options,
  value: receivedValue,
  translate,
  className,
}: MultiSelectListPropTypes) => {
  const [searchValue, setSearchValue] = useState('');
  const [selected, setSelected] = useState<Array<string>>([]);
  const [optionsOrder, setOptionsOrder] = useState<Array<Option>>([]);
  const { isOpen } = useDropdownContext();
  const { t } = useTranslation();

  const handleChange = (newValue: Array<string>) => {
    if (onChange) onChange(newValue);
    if (!receivedValue) setSelected(newValue);
  };

  const selectedValues: Array<string> = receivedValue || selected;
  const values = useMemo(() => options.map((item) => item.value), [options.length]);

  useEffect(() => {
    const reorderedOptions = [...options].sort((a) => (selectedValues.includes(a.value) ? -1 : 0));
    setOptionsOrder(selectedValues.length > 0 ? reorderedOptions : options);
  }, [isOpen, options.length]);

  return (
    <StyledMultiSelectList className={className}>
      {options.length > 11 && (
        <StyledSearchWrapper>
          <TextInput
            inputProps={{
              onChange: ({ target }) => setSearchValue((target as HTMLInputElement)?.value || ''),
              onBlur: () => {},
              value: searchValue,
            }}
            placeholder={`${t('filters.search')}...`}
            prefixElement={<SearchIcon />}
            size='fullWidth'
            withClearButton
          />
        </StyledSearchWrapper>
      )}
      {options.length > 4 && (
        <>
          <StyledSelectedCounter>
            {selectedValues.length}/{options.length}
          </StyledSelectedCounter>
          <StyledCheckboxWrapper bold capitalize>
            <Checkbox
              isChecked={selectedValues.length === options.length}
              label={`${t('filters.all')}`}
              onClick={() =>
                selectedValues.length === options.length
                  ? handleChange([])
                  : handleChange([...values])
              }
              size='small'
            />
          </StyledCheckboxWrapper>
          <StyledHorizontalRule />
        </>
      )}
      <StyledCheckboxList>
        {optionsOrder.map(
          (option) =>
            (!searchValue || option.label.toLowerCase().includes(searchValue.toLowerCase())) && (
              <StyledCheckboxWrapper
                capitalize
                isChecked={selectedValues.includes(option.value)}
                key={option.value}
                showShading
              >
                <Checkbox
                  additionalLabel={option.additionalLabel}
                  isChecked={selectedValues.includes(option.value)}
                  label={translate ? t(`filters.${snakeCase(option.label)}`) : option.label}
                  onClick={() => {
                    if (selectedValues.includes(option.value)) {
                      handleChange(selectedValues.filter((item) => item !== option.value));
                    } else {
                      handleChange([...selectedValues, option.value]);
                    }
                  }}
                  size='small'
                />
              </StyledCheckboxWrapper>
            ),
        )}
      </StyledCheckboxList>
      <StyledClearButton
        isActive={selectedValues.length > 0}
        label={t('filters.clear_selected')}
        onClick={() => handleChange([])}
        type='button'
        variant='contrast-flat'
      />
    </StyledMultiSelectList>
  );
};

MultiSelectList.defaultProps = {
  translate: false,
};

export { MultiSelectList };
