import React, { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { StyledLevelFieldButton, StyledLevelFieldButtonsWrapper } from './styles';
import { StyledLabel } from 'components/Forms/styles';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';

interface Option {
  label: string;
  value: string | number;
}

interface LevelFieldPropTypes extends FieldRenderProps<string> {
  clearButton?: boolean;
  isDisabled?: boolean;
  label?: string;
  stateChangeFunction?: React.Dispatch<React.SetStateAction<Option['value']>>;
  options: Option[];
  size?: 'small' | 'medium' | 'large';
}

const LevelField: FC<LevelFieldPropTypes> = ({
  clearButton = false,
  input: { onChange, value },
  isDisabled,
  label,
  stateChangeFunction,
  options,
  size = 'medium',
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledLevelFieldButtonsWrapper>
        {options.map((option) => (
          <StyledLevelFieldButton
            active={value === option.value}
            clearButton={clearButton}
            disabled={isDisabled}
            key={option.value}
            onClick={(e) => {
              e.stopPropagation();
              onChange(option.value);
              if (stateChangeFunction) {
                stateChangeFunction(option.value);
              }
            }}
            size={size}
            type='button'
          >
            {option.label}
          </StyledLevelFieldButton>
        ))}
        {clearButton && (
          <DefaultButton
            label={t('common.clear')}
            onClick={(e) => {
              e.stopPropagation();
              onChange(null);
            }}
            size={size}
            type='button'
            variant='tertiary'
          />
        )}
      </StyledLevelFieldButtonsWrapper>
    </div>
  );
};

export { LevelField };
