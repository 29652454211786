import styled from 'styled-components/macro';
import { Tag } from 'components/Tag/Tag';
import { theme } from 'consts/theme';

export const StyledSection = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledTag = styled(Tag)`
  margin-bottom: ${({ theme }) => theme.sizes.xs};
  margin-right: ${({ theme }) => theme.sizes.xs};

  & div:first-letter {
    text-transform: capitalize;
  }
`;

export const StyledFiltersContainer = styled.div<{ isOpen?: boolean }>`
  border-bottom: 3px solid ${theme.colors.blue100};
  height: ${({ isOpen }) => (isOpen ? 'auto' : 0)};
  overflow: auto;
  width: 100%;
`;

export const StyledFiltersWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 40rem;
`;

export const StyledFilterToggleButton = styled.span`
  border-radius: 0 0 0.8rem 0.8rem;
  box-shadow: ${theme.boxShadows.blue300};
  cursor: pointer;
  line-height: ${({ theme }) => theme.lineHeight.lg};
  padding: ${({ theme }) => `${theme.sizes.xs} ${theme.sizes.sm}`};

  @media (min-width: 768px) {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
  }
`;
