import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { navigate, useLocation } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

import {
  StyledContextBar,
  StyledDetails,
  StyledLayout,
  StyledSecondaryText,
  StyledTabsFieldWrapper,
} from 'containers/companyDetails/CompanyNavigationPanel/styles';
import { ButtonWrapper } from 'components/ButtonWrapper/ButtonWrapper';
import { CompanyEntity } from 'ducks/companies/types';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { EditCompanyModal } from './CompanyNavigationPanelModals';
import { getRvoEvents } from 'ducks/events/actions';
import { ReactComponent as EditIcon } from 'images/editIcon.svg';
import { ReactComponent as EnvelopeIcon } from 'images/envelopeIcon.svg';
import { ReactComponent as BrregIcon } from 'images/brregIcon.svg';
import { ReactComponent as ProffIcon } from 'images/proffIcon.svg';
import { rvoEventsMetaSelector } from 'ducks/events/selectors';
import { SendEmailsModal } from '../Contacts/ContactsModals';
import { StatusBadge } from 'components/StatusBadge/StatusBadge';
import { Tabs } from 'components/Tabs/Tabs';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { useModal } from 'components/Modal/Modal';
import { StyledNewVisitNavigationButton } from 'containers/newVisit/styles';
import { ArrowLeftBigIcon } from 'utils/iconsMap';

export type TabsName = 'basic-data' | 'contacts' | 'visits' | 'event-logs';

interface CompanyNavigationPanelPropTypes {
  company: CompanyEntity;
  activeTab: TabsName;
}

const tabNames: TabsName[] = ['basic-data', 'contacts', 'visits', 'event-logs'];

const setTab = (id: string, tab: string) =>
  navigate(`/redesigned/companies/${id}/${tab}`, {
    state: { ...window.history.state },
    replace: true,
  });

export const CompanyNavigationPanel: FC<CompanyNavigationPanelPropTypes> = ({
  company,
  activeTab,
}) => {
  const { t } = useTranslation();
  const { setModalComponent, showModal, setShowHeader } = useModal();
  const dispatch = useDispatch();
  const { pageSize, page } = useSelector(rvoEventsMetaSelector);
  const location = useLocation();
  const { name, number, active, orgTypeCode, vatDuty, aka, commentOnCompanyStatus } = company.info;

  const companyName = [company.info.name];
  aka && companyName.push(`(${aka.trim()})`);
  const validNumber = /^\d{9}$/.test(number.trim());

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onEmailSubmit = async () => {
    if (activeTab === 'event-logs') {
      setTimeout(
        () => dispatch(getRvoEvents({ company_id: company.id, page_size: pageSize, page })),
        300,
      );
    }
  };
  const onSetTab = (tab: string) => setTab(company.id, tab);
  const returnUrl =
    location.state?.previousPathname && location.state?.previousPathname !== location.pathname
      ? location.state?.previousPathname
      : `/redesigned/search/company`;
  return (
    <StyledContextBar>
      <StyledLayout>
        <StyledDetails>
          <Tooltip label={t('common.back')}>
            <StyledNewVisitNavigationButton
              icon={<ArrowLeftBigIcon />}
              onClick={() => navigate(returnUrl, { state: { ...location.state } })}
              testid='back'
              type='button'
              variant='tertiary'
            />
          </Tooltip>
          <h1 style={{ marginRight: '1.5rem' }}>{companyName.join(' ')}</h1>
          {orgTypeCode && <StatusBadge variant='active'>{orgTypeCode}</StatusBadge>}
          <StatusBadge variant={vatDuty ? 'active' : 'inactive'}>
            {t('company_details.vat')}
          </StatusBadge>
          <StatusBadge variant={active ? 'success' : 'inactive'}>
            {capitalize(t(`company_details.company_is_${active ? 'active' : 'inactive'}`))}
          </StatusBadge>
          <StyledSecondaryText>{company.info.number}</StyledSecondaryText>
          <Tooltip label={t('new_visits.search_brreg')}>
            <DefaultButton
              icon={<BrregIcon />}
              onClick={() => {
                window.open(
                  validNumber
                    ? `https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${number}`
                    : `https://w2.brreg.no/enhet/sok/treffliste.jsp?navn=${name}AS&orgform=0&fylke=0&kommune=0`,
                  '_blank',
                );
              }}
              variant='default'
            />
          </Tooltip>
          <Tooltip label={t('new_visits.search_proff')}>
            <DefaultButton
              icon={<ProffIcon />}
              onClick={() => {
                window.open(
                  `https://proff.no/bransjesøk?q=${validNumber ? number : name}`,
                  '_blank',
                );
              }}
              variant='default'
            />
          </Tooltip>
          <ButtonWrapper align='right'>
            <Tooltip label={t('company_details.contacts.send_email')}>
              <DefaultButton
                icon={<EnvelopeIcon />}
                isDisabled={!company.contact.email}
                onClick={() => {
                  setModalComponent(
                    <SendEmailsModal
                      companyId={company.id}
                      itemsSelected={[company.contact.email as string]}
                      onSubmit={onEmailSubmit}
                    />,
                  );
                  setShowHeader(false);
                  showModal();
                }}
                variant='secondary'
              />
            </Tooltip>
            <Tooltip label={t('company_details.edit_company')}>
              <DefaultButton
                icon={<EditIcon />}
                onClick={() => {
                  setModalComponent(
                    <EditCompanyModal
                      aka={aka ?? ''}
                      companyName={name ?? ''}
                      id={company.id}
                      taxable={active}
                      orgNumber={number ?? ''}
                      commentOnCompanyStatus={commentOnCompanyStatus ?? ''}
                    />,
                  );
                  setShowHeader(false);
                  showModal();
                }}
                variant='secondary'
              />
            </Tooltip>
          </ButtonWrapper>
        </StyledDetails>

        <StyledTabsFieldWrapper>
          <Tabs activeTab={activeTab} handleClick={onSetTab} tabNames={tabNames} translate />
        </StyledTabsFieldWrapper>
      </StyledLayout>
    </StyledContextBar>
  );
};
