import FileSaver from 'file-saver';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { GetCompanyVisitsParams, VisitsResponse } from 'ducks/visits/types';
import { API } from 'api';
import { AxiosError } from 'axios';

export const getCompanyVisits = createAsyncThunk(
  'visits/getCompanyVisits',
  async (params: GetCompanyVisitsParams, { rejectWithValue }) => {
    try {
      const response = await API.get(`company_visits`, { params });
      return response.data as VisitsResponse;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const getWorkplaceVisits = createAsyncThunk(
  'visits/getWokplaceVisits',
  async (params: GetCompanyVisitsParams, { rejectWithValue }) => {
    try {
      const response = await API.get(`workplace_visits`, { params });
      return response.data as VisitsResponse;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const getAllWorkplaceVisits = async (params: GetCompanyVisitsParams) => {
  const response = await API.get(`workplace_visits`, {
    params: {
      ...params,
      page: 1,
      page_size: 1000,
    },
  });
  return response.data as VisitsResponse;
};
export const getAllCompanyVisits = async (params: GetCompanyVisitsParams) => {
  const response = await API.get(`company_visits`, {
    params: {
      ...params,
      page: 1,
      page_size: 1000,
    },
  });
  return response.data as VisitsResponse;
};

export const deleteCompanyVisit = createAsyncThunk(
  'visits/deleteCompanyVisits',
  async (companyVisitId: string, { rejectWithValue }) => {
    try {
      await API.delete(`company_visits/${companyVisitId}`);
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const setCompanyVisitColumnsConfig = createAsyncThunk(
  'contact/setCompanyVisitColumnsConfig',
  async ({ order, visibility }: { order?: string[]; visibility?: string[] }) => {
    return { order, visibility };
  },
);
export const getVisitsCSV = async (company_id: string, ids: string[]) => {
  const response = await API.post(
    `company_visits`,
    {
      id: ids.join(','),
    },
    {
      params: {
        page: 1,
        company_id: company_id,
        page_size: 10000,
      },

      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
      },
    },
  );

  const blob = new Blob([response.request.response], {
    type: 'application/vnd.ms-excel',
  });

  FileSaver.saveAs(blob, 'visits-export.xls');
  return response.data as VisitsResponse;
};
