/* eslint-disable @typescript-eslint/no-explicit-any */

export const getInitials = (fullname?: string) => {
  if (!fullname) return '';
  return fullname.split(' ').reduce((prev, curr) => prev.charAt(0) + curr.charAt(0), '');
};

export const getActiveFiltersState = (state: { [key: string]: { isActive: boolean } }) => {
  return Object.keys(state).reduce((acc, cur) => {
    if (state[cur].isActive) return { ...acc, [cur]: state[cur] };
    else return acc;
  }, {});
};

export const popperSameMinWidthModifier = {
  name: 'sameMinWidth',
  enabled: true,
  phase: 'beforeWrite' as const,
  requires: ['computeStyles'],
  fn: ({ state }: any) => {
    state.styles.popper.minWidth = `${state.rects.reference.width}px`;
  },
  effect: ({ state }: any) => {
    state.elements.popper.style.minWidth = `${state.elements.reference.offsetWidth}px`;
  },
};

export const popperOffsetModifier = {
  name: 'offset',
  phase: 'beforeMain' as const,
  options: {
    offset: ({ placement, reference }: any) => {
      if (placement.includes('bottom')) return [0, -reference.height];
      else if (placement.includes('top')) return [0, -reference.height];
      else return [];
    },
  },
};

// https://stackoverflow.com/questions/35228052/debounce-function-implemented-with-promises
export function promiseDebounce(
  exec: (...args: any[]) => Promise<any>,
  interval: number,
): (...args: any[]) => ReturnType<typeof exec> {
  let handle: number | undefined;
  let resolves: Array<(value?: unknown) => void> = [];

  return async (...args: unknown[]) => {
    clearTimeout(handle);
    handle = setTimeout(() => {
      const result = exec(...args);
      resolves.forEach((resolve) => resolve(result));
      resolves = [];
    }, interval);

    return new Promise((resolve) => resolves.push(resolve));
  };
}

export const stripHtmlTags = (text?: string) => {
  if (typeof String.prototype.replaceAll === 'undefined') {
    return text?.replace(new RegExp(/<.*?>/, 'gim'), () => '') || '';
  }
  return text?.replaceAll(/<.*?>/gim, '') || '';
};
