import styled from 'styled-components/macro';

export const ColumnOrderContainer = styled.div`
  padding: ${({ theme }) => theme.sizes.xs};

  & button {
    width: 100%;
  }
`;

export const ColumOrderItem = styled.div<{ isDragging: boolean }>`
  background-color: ${({ isDragging, theme }) => (isDragging ? theme.colors.blue50 : 'white')};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black100};
  padding: ${({ theme }) => `${theme.sizes.xxs} ${theme.sizes.xs}`};
`;
