import { createSlice } from '@reduxjs/toolkit';

import {
  VisitColumnsName,
  VisitEntity,
  VisitEntityResponse,
  VisitsState,
} from 'ducks/visits/types';
import {
  deleteCompanyVisit,
  getCompanyVisits,
  getWorkplaceVisits,
  setCompanyVisitColumnsConfig,
} from './actions';

const initialState: VisitsState = {
  data: {
    tableBody: [],
  },
  error: undefined,
  meta: {
    columnsOrder: [],
    filters: {},
    page: 1,
    pageSize: 10,
    totalCount: 1,
    totalPages: 1,
  },
};

const amendEntity = (entity: VisitEntityResponse): VisitEntity => ({
  ...entity,
  id: entity.visitId,
});

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    resetCompanyVisitReducer: () => initialState,
    clearCompanyVisitError: (state) => ({ ...state, error: undefined }),
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyVisits.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(getWorkplaceVisits.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(getWorkplaceVisits.fulfilled, (state, { payload }) => {
      state.data.tableBody = payload.data.tableBody.map(amendEntity);
      const newMeta = { ...state.meta, ...payload.meta };
      newMeta.columnsOrder = newMeta.columnsOrder.filter((column) => column !== 'tips_id_present');
      state.meta = newMeta;
    });
    builder.addCase(getCompanyVisits.fulfilled, (state, { payload }) => {
      state.data.tableBody = payload.data.tableBody.map(amendEntity);
      const newMeta = { ...state.meta, ...payload.meta };
      newMeta.columnsOrder = newMeta.columnsOrder.filter((column) => column !== 'tips_id_present');
      state.meta = newMeta;
    });
    builder.addCase(deleteCompanyVisit.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(deleteCompanyVisit.fulfilled, (state, { meta }) => {
      state.data.tableBody = state.data.tableBody.filter(
        (visit) => visit.companyVisitId !== meta.arg,
      );
      if (typeof state.meta.totalCount === 'number') --state.meta.totalCount;
    });
    builder.addCase(
      setCompanyVisitColumnsConfig.fulfilled,
      (state, { payload: { order, visibility } }) => {
        if (order) state.meta.columnsOrder = order as VisitColumnsName[];
      },
    );
  },
});

const {
  reducer: visitsReducer,
  actions: { resetCompanyVisitReducer, clearCompanyVisitError },
} = contactsSlice;

export { visitsReducer, resetCompanyVisitReducer, clearCompanyVisitError };
