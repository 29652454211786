import { createSlice } from '@reduxjs/toolkit';
import {
  deleteDashboardVisit,
  getDashboardVisits,
  setDashboardVisitColumnsConfig,
} from 'ducks/dashboard/actions';
import { DashboardVisitColumnsName, DashboardVisitsState } from 'ducks/dashboard/types';

const initialState: DashboardVisitsState = {
  data: {
    tableBody: [],
  },
  error: undefined,
  meta: {
    columnsOrder: [],
    filters: {},
    page: 1,
    pageSize: 10,
    totalCount: 1,
    totalPages: 1,
  },
};

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    resetCompanyVisitReducer: () => initialState,
    clearCompanyVisitError: (state) => ({ ...state, error: undefined }),
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboardVisits.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(getDashboardVisits.fulfilled, (state, { payload }) => {
      state.data.tableBody = payload.data.tableBody;
      const newMeta = { ...state.meta, ...payload.meta };
      newMeta.columnsOrder = newMeta.columnsOrder.filter((column) => column !== 'tips_id_present');
      state.meta = newMeta;
    });
    builder.addCase(
      setDashboardVisitColumnsConfig.fulfilled,
      (state, { payload: { order, visibility } }) => {
        if (order) state.meta.columnsOrder = order as DashboardVisitColumnsName[];
      },
    );
    builder.addCase(deleteDashboardVisit.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(deleteDashboardVisit.fulfilled, (state, { meta }) => {
      state.data.tableBody = state.data.tableBody.filter(
        ({ companyVisitId }) => companyVisitId !== meta.arg,
      );
    });
  },
});

const {
  reducer: dashboardVisitsReducer,
  actions: { resetCompanyVisitReducer, clearCompanyVisitError },
} = contactsSlice;

export { dashboardVisitsReducer, resetCompanyVisitReducer, clearCompanyVisitError };
