import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { capitalize, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, FormSpy } from 'react-final-form';
import * as yup from 'yup';

import {
  StyledDivider,
  StyledModalButtonsWrapper,
  StyledModalHeader,
  StyledModalItemToRemove,
  StyledModalMessage,
  useModal,
} from 'components/Modal/Modal';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import {
  addCompanyContact,
  deleteCompanyContact,
  editCompanyContact,
  sendEmail,
} from 'ducks/contacts/actions';
import { StoreType, useAppDispatch } from 'store';
import { useLoadingContext } from 'contexts/LoadingContext';
import { StyledForm } from 'components/Forms/styles';
import { TextField } from 'components/Forms/TextField/TextField';
import { SingleSelectField } from 'components/Forms/SingleSelectField/SingleSelectField';
import { CheckboxField } from 'components/Forms/CheckboxField/CheckboxField';
import { MultiSelectField } from 'components/Forms/MultiSelectField/MultiSelectField';
import { AttachmentField } from 'components/Forms/AttachmentField/AttachmentField';
import { TextareaField } from 'components/Forms/TextareaField/TextareaField';
import { ContactEntity } from 'ducks/contacts/types';
import { yupValidate } from 'utils/yupValidate';
import { rvoUsersSelector } from 'ducks/users/selectors';
import { PreviewContactRow } from './PreviewContactRow';
import { getSendEmailSubtypes } from 'ducks/constants/actions';
import { constantsSelector } from 'ducks/constants/selectors';

interface DeleteContactModalPropsType {
  itemsSelected: Array<ContactEntity>;
  actionCallback?: () => void;
}

const DeleteContactModal: FC<DeleteContactModalPropsType> = ({ itemsSelected, actionCallback }) => {
  const { t } = useTranslation();
  const { setLoading, isLoading, setLoaded } = useLoadingContext();
  const dispatch = useDispatch();
  const { hideModal } = useModal();

  return (
    <>
      <StyledModalHeader>{`${t('common.delete_all')}?`}</StyledModalHeader>
      {itemsSelected.map((contact) => (
        <StyledModalItemToRemove key={contact.id}>
          {contact.name} - {contact.role}
        </StyledModalItemToRemove>
      ))}
      <StyledModalMessage>{t('company_details.visits.data_lose')}</StyledModalMessage>
      <StyledModalButtonsWrapper>
        <DefaultButton
          isDisabled={isLoading('delete-multiple-contacts')}
          label={capitalize(t('common.cancel'))}
          onClick={hideModal}
          variant='secondary'
        />

        <DefaultButton
          isDisabled={isLoading('delete-multiple-contacts')}
          label={capitalize(t('common.delete'))}
          onClick={async () => {
            setLoading('delete-multiple-contacts');
            await Promise.all(itemsSelected.map((item) => dispatch(deleteCompanyContact(item.id))));
            actionCallback && actionCallback();
            setLoaded('delete-multiple-contacts');
            hideModal();
          }}
          variant='danger'
        />
      </StyledModalButtonsWrapper>
    </>
  );
};

interface AddEditContactModalPropsType {
  contact?: ContactEntity;
}

type Option = { label: string; value: number };

interface AddEditFormValues {
  companyName?: string;
  name: string;
  role?: Option;
  email?: string;
  phone?: string;
  obligatory_training: boolean;
  comment?: string;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().trim().email(),
  role: yup.object().shape({
    value: yup.number().min(1).required(),
  }),
  comment: yup.string().max(500),
});

const AddEditContactModal: FC<AddEditContactModalPropsType> = ({ contact }) => {
  const roles = useSelector((state: StoreType) =>
    state.contacts.roles.map((role) => ({ label: role.name, value: role.id })),
  );
  const companyId = useSelector((state: StoreType) => state.companies?.id) as string;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hideModal } = useModal();

  const onSubmit = async (values: AddEditFormValues) => {
    const valuesToPost = {
      name: values.name || '',
      email: values.email?.trim() || '',
      phone: values.phone || '',
      obligatory_training: values.obligatory_training,
      company_contact_role_id: (values.role as Option).value,
      company_id: companyId,
      comment: values.comment || '',
    };
    contact?.id
      ? await dispatch(editCompanyContact({ ...valuesToPost, contactId: contact?.id }))
      : await dispatch(addCompanyContact(valuesToPost));
    hideModal();
  };

  const initialValues = {
    name: contact?.name || '',
    email: contact?.email?.trim() || '',
    phone: contact?.phone || '',
    obligatory_training: contact?.obligatoryTraining || false,
    role: roles.find(({ value }) => value === Number(contact?.roleId)),
    comment: contact?.comment || '',
  };

  const isEdit = !!contact;

  const getHeader = useCallback(() => {
    if (isEdit) {
      if (contact?.companyName) {
        return `${t('company_details.contacts.edit_a_contact')} ${t('common.at')} ${
          contact?.companyName
        }`;
      }
      return t('company_details.contacts.edit_a_contact');
    }
    return t('company_details.contacts.new_contact');
  }, [contact?.companyName, isEdit]);

  return (
    <>
      <StyledModalHeader>{getHeader()}</StyledModalHeader>
      <Form
        keepDirtyOnReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => (
          <StyledForm onSubmit={handleSubmit}>
            <Field
              component={TextField}
              label={t('table.columns.name')}
              name='name'
              placeholder={t('forms.name_and_surname')}
              required
              subscription={{ value: true, touched: true, error: true }}
            />
            <Field
              component={SingleSelectField}
              label={t('table.columns.role')}
              name='role'
              options={roles}
              placeholder={`${t('forms.select_a')} ${t('forms.business_role')}`}
              required
              subscription={{ value: true }}
            />
            <Field
              component={TextField}
              label={t('table.columns.email')}
              name='email'
              placeholder={t('forms.email_address')}
              subscription={{ value: true, touched: true, error: true }}
            />
            <Field
              additionalInputProps={{ size: 'big' }}
              component={TextField}
              label={t('table.columns.phone')}
              name='phone'
              subscription={{ value: true, touched: true, error: true }}
            />
            <Field
              component={CheckboxField}
              label={t('forms.obligatory_training')}
              name='obligatory_training'
              subscription={{ value: true }}
            />
            <Field
              component={(props) => (
                <TextareaField
                  characterLimit={500}
                  onChange={props.input.onChange}
                  value={props.input.value}
                  input={props.input}
                  meta={props.meta}
                  label={t('common.comment')}
                />
              )}
              name='comment'
              subscription={{ value: true }}
            />
            <StyledModalButtonsWrapper>
              <DefaultButton
                capitalize
                label={t('common.cancel')}
                onClick={hideModal}
                type='button'
                variant='tertiary'
              />
              <FormSpy subscription={{ errors: true }}>
                {({ errors }) => (
                  <DefaultButton
                    capitalize
                    isDisabled={!isEmpty(errors)}
                    label={t(
                      isEdit ? 'forms.save_changes' : 'company_details.contacts.save_contact',
                    )}
                    testid='submit-add-edit-contact-form'
                    type='submit'
                    loading={submitting}
                  />
                )}
              </FormSpy>
            </StyledModalButtonsWrapper>
          </StyledForm>
        )}
        subscription={{}}
        validate={yupValidate(schema)}
      />
    </>
  );
};

interface SendEmailsModalPropsType {
  itemsSelected?: Array<string>;
  companyId?: string;
  onSubmit?: () => void;
}

interface SendEmailsFormValues {
  subtype: Option | null;
  send_to: string;
  title: string;
  message: string;
  attachments: Array<Blob>;
  own_copy: boolean;
  send_to_other_rvo: boolean;
  other_rvo: Array<string>;
}

const SendEmailsModal: FC<SendEmailsModalPropsType> = ({
  itemsSelected,
  companyId,
  onSubmit: onSubmitCallback,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setLoaded, isLoading, setLoading } = useLoadingContext();
  const { hideModal } = useModal();
  const loadingContextActionName = 'email-sent-form';
  const rvos = useSelector(rvoUsersSelector);
  const { sendEmailSubtypes, sendEmailSubtypesStatus } = useSelector(constantsSelector);

  useEffect(() => {
    if (sendEmailSubtypesStatus === 'idle') dispatch(getSendEmailSubtypes());
  }, [dispatch, sendEmailSubtypesStatus]);

  const onSubmit = async (values: SendEmailsFormValues) => {
    setLoading(loadingContextActionName);
    const formData = new FormData();

    formData.append('data[attributes][send_to]', values.send_to);
    formData.append('data[attributes][title]', values.title);
    formData.append('data[attributes][message]', values.message);
    formData.append('data[attributes][own_copy]', values.own_copy.toString());
    formData.append(
      'data[attributes][send_to_other_rvo]',
      values.send_to_other_rvo ? values.other_rvo.join(',') : '',
    );
    values.attachments?.forEach((attachment) => formData.append('attachments[]', attachment));
    companyId && formData.append('current_company_id', companyId);
    values.subtype && formData.append('subtype', values.subtype.label.toString());

    await dispatch(sendEmail(formData));

    setLoaded(loadingContextActionName);
    hideModal();

    onSubmitCallback && onSubmitCallback();
  };

  const subtypeOptions = useMemo(() => {
    return sendEmailSubtypes.map((subtype) => ({
      value: subtype.id,
      label: subtype.name,
    }));
  }, [sendEmailSubtypes]);

  const initialValues = {
    send_to: itemsSelected ? itemsSelected.join(', ') : '',
    title: '',
    message: '',
    own_copy: false,
    attachments: [],
    other_rvo: [],
    send_to_other_rvo: false,
    subtype: null,
  };

  return (
    <>
      <StyledModalHeader>{t('company_details.contacts.send_email')}</StyledModalHeader>
      <Form
        initialValues={initialValues}
        keepDirtyOnReinitialize
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <StyledForm onSubmit={handleSubmit}>
            <Field
              component={TextField}
              description={`${t('forms.type_emails_seperated_by_comma')} ","`}
              label={t('forms.send_to')}
              name='send_to'
              required
              subscription={{ value: true, touched: true, error: true }}
            />
            <Field
              component={TextField}
              label={t('common.title')}
              name='title'
              placeholder={t('forms.message_title')}
              required
              subscription={{ value: true, touched: true, error: true }}
            />
            <Field
              component={TextareaField}
              label={t('common.message')}
              maxRows={16}
              name='message'
              placeholder={t('forms.write_a_message')}
              required
              subscription={{ value: true }}
            />
            {subtypeOptions.length > 0 && (
              <Field
                component={SingleSelectField}
                label={t('table.columns.subtype')}
                name='subtype'
                options={subtypeOptions}
                placeholder={`${t('forms.select_a')} ${t('table.columns.subtype')}`}
                subscription={{ value: true }}
                withClearButton
              />
            )}
            <Field
              component={AttachmentField}
              label={t('forms.attachments')}
              name='attachments'
              subscription={{ value: true }}
            />
            <Field
              component={CheckboxField}
              label={t('forms.send_a_copy_to_my_email')}
              name='own_copy'
              subscription={{ value: true }}
            />
            <Field
              component={CheckboxField}
              label={t('forms.send_a_copy_to_other_rvo')}
              name='send_to_other_rvo'
              subscription={{ value: true }}
            />
            <FormSpy subscription={{ values: true }}>
              {({ values }) =>
                values.send_to_other_rvo && (
                  <Field
                    component={MultiSelectField}
                    name='other_rvo'
                    openUpwards
                    options={rvos}
                    placeholder={t('forms.select_rvo')}
                    subscription={{ value: true }}
                  />
                )
              }
            </FormSpy>
            <StyledModalButtonsWrapper>
              <DefaultButton
                capitalize
                isDisabled={isLoading(loadingContextActionName)}
                label={t('common.cancel')}
                onClick={hideModal}
                type='button'
                variant='tertiary'
              />
              <FormSpy subscription={{ errors: true }}>
                {({ errors }) => (
                  <DefaultButton
                    capitalize
                    isDisabled={errors.hasError || isLoading(loadingContextActionName)}
                    label={t('company_details.contacts.send_an_email')}
                  />
                )}
              </FormSpy>
            </StyledModalButtonsWrapper>
          </StyledForm>
        )}
        subscription={{}}
        validate={(values) => {
          if (!values.send_to || !values.title || !values.message) return { hasError: true };
          return {};
        }}
      />
    </>
  );
};

export { AddEditContactModal, DeleteContactModal, SendEmailsModal };

interface PreviewContactModalPropTypes {
  itemsSelected: ContactEntity[];
}

export const PreviewContactModal = ({ itemsSelected }: PreviewContactModalPropTypes) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledModalHeader>{t('common.contact')}</StyledModalHeader>
      <div style={{ maxHeight: '70vh', overflowY: 'auto', overflowX: 'hidden' }}>
        {itemsSelected.map((contact, i) => (
          <Fragment key={contact.id}>
            <PreviewContactRow contact={contact} />
            {i < itemsSelected.length - 1 && <StyledDivider />}
          </Fragment>
        ))}
      </div>
    </>
  );
};
