import React, { useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field, FieldInputProps, FieldMetaState, Form, FormSpy } from 'react-final-form';
import * as yup from 'yup';

import { StyledModalButtonsWrapper, StyledModalHeader, useModal } from 'components/Modal/Modal';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { StoreType } from 'store';
import { StyledCheckboxWrapper, StyledFieldWrapper, StyledForm } from 'components/Forms/styles';
import { TextField } from 'components/Forms/TextField/TextField';
import { SingleSelectField } from 'components/Forms/SingleSelectField/SingleSelectField';
import { CheckboxField } from 'components/Forms/CheckboxField/CheckboxField';
import { yupValidate } from 'utils/yupValidate';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { NewVisitContact } from 'ducks/newVisit';
import { ReceiveVisitReportField } from 'dialogs/newVisits/Contacts/ReceiveVisitReportField';
import { TextareaField } from 'components/Forms/TextareaField/TextareaField';

export type ContactTypes = 'workplace' | 'visit' | 'company';

export type CanConnectToType = [ContactTypes, ContactTypes?];

interface NewVisitContactModalPropsType {
  autosetReciveVisitButtonToTrue?: boolean;
  canConnectTo: CanConnectToType;
  contact?: NewVisitContact;
  defaultConectTo: ContactTypes;
  showInReportDefaultTrue?: boolean;
  submitFn: (values: AddEditFormValues, connectTo: ContactTypes) => Promise<unknown> | void;
}

type Option = { label: string; value: number };

interface AddEditFormValues {
  name: string;
  role: Option | undefined;
  email?: string;
  phone?: string;
  obligatory_training: boolean;
  include_in_report: boolean;
  receive_visit_report: boolean;
  comment: string;
}

interface CustomTextareaFieldProps {
  input: FieldInputProps<string, HTMLElement>;
  meta: FieldMetaState<string>;
  label: string;
  characterLimit?: number;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  role: yup.object().required(),
  email: yup.string().trim().email(),
  phone: yup.string().matches(/^(\+?[0-9]*)$/),
  comment: yup.string().max(500),
});

const validateForm = yupValidate(schema);

// This component was causing re-renders within the form, so I had to refactor it to a functional component
const CustomTextareaField = ({ input, meta, label, ...rest }: CustomTextareaFieldProps) => (
  <TextareaField
    characterLimit={500}
    onChange={input.onChange}
    value={input.value}
    input={input}
    meta={meta}
    label={label}
    {...rest}
  />
);

export const NewVisitContactModal = ({
  canConnectTo,
  contact,
  defaultConectTo,
  autosetReciveVisitButtonToTrue = false,
  showInReportDefaultTrue = false,
  submitFn,
}: NewVisitContactModalPropsType) => {
  const isEdit = !!contact;
  const [connectTo, setConnectTo] = useState<ContactTypes>(defaultConectTo);
  const { t } = useTranslation();
  const { hideModal } = useModal();

  const roles = {
    company: useSelector((state: StoreType) =>
      state.contacts.roles.map((role) => ({ label: role.name, value: role.id })),
    ),
    // visit contact has the same roles as the company contact
    visit: useSelector((state: StoreType) =>
      state.contacts.roles.map((role) => ({ label: role.name, value: role.id })),
    ),
    workplace: useSelector((state: StoreType) =>
      state.contacts.workplaceRoles.map((role) => ({ label: role.name, value: role.id })),
    ),
  } as const;

  const toggleCheckbox = () => {
    const [newValue] = canConnectTo.filter((x) => x !== connectTo);
    setConnectTo(newValue!);
  };

  const onSubmit = async (values: AddEditFormValues) => {
    const { email, ...rest } = values;

    await submitFn({ ...rest, email: email?.trim() }, connectTo);
    hideModal();
  };

  const initialValues = {
    name: contact?.name || '',
    email: contact?.email || '',
    phone: contact?.phone || '',
    obligatory_training: contact?.obligatoryTraining || false,
    role: roles[connectTo].find(({ value }) => value === Number(contact?.roleId)),
    include_in_report: contact?.includeInReport ?? showInReportDefaultTrue,
    receive_visit_report: !!contact?.receiveVisitReport,
    comment: contact?.comment || '',
  };

  const getHeader = useCallback(() => {
    if (isEdit)
      if (contact?.company)
        return `${t('company_details.contacts.edit_a_contact')} ${t('common.at')} ${
          contact?.company
        }`;
      else return t('company_details.contacts.edit_a_contact');
    else return t('company_details.contacts.new_contact');
  }, [contact?.company, isEdit]);

  return (
    <>
      <StyledModalHeader>{getHeader()}</StyledModalHeader>
      <Form
        keepDirtyOnReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting }) => (
          <StyledForm onSubmit={handleSubmit}>
            {!isEdit && canConnectTo.length === 2 && (
              <StyledFieldWrapper>
                <Checkbox
                  isChecked={connectTo === 'company'}
                  label={t('new_visits.connect_to_company')}
                  onClick={toggleCheckbox}
                />
              </StyledFieldWrapper>
            )}
            <Field
              component={TextField}
              label={t('table.columns.name')}
              name='name'
              placeholder={t('forms.name_and_surname')}
              required
              subscription={{ value: true, touched: true, error: true }}
            />
            <Field
              component={SingleSelectField}
              label={t('table.columns.role')}
              name='role'
              options={roles[connectTo]}
              placeholder={`${t('forms.select_a')} ${t('forms.business_role')}`}
              required
              subscription={{ value: true, error: true }}
            />

            <Field
              component={TextField}
              label={t('table.columns.email')}
              name='email'
              placeholder={t('forms.email_address')}
              subscription={{ value: true, touched: true, error: true }}
            />
            <Field
              additionalInputProps={{ size: 'big' }}
              component={TextField}
              label={t('table.columns.phone')}
              name='phone'
              subscription={{ value: true, touched: true, error: true }}
            />
            <StyledCheckboxWrapper>
              <Field
                component={CheckboxField}
                label={t('forms.obligatory_training')}
                name='obligatory_training'
                subscription={{ value: true }}
              />
              <Field
                component={CheckboxField}
                label={t('table.columns.show_in_report')}
                name='include_in_report'
                subscription={{ value: true }}
              />
              <FormSpy subscription={{ errors: true, values: true }}>
                {({ errors, values }) => (
                  <ReceiveVisitReportField
                    autosetReciveVisitButtonToTrue={autosetReciveVisitButtonToTrue}
                    formFieldChange={form.change}
                    hasErrors={errors?.email}
                    value={values.email}
                  />
                )}
              </FormSpy>
            </StyledCheckboxWrapper>
            <Field
              component={CustomTextareaField}
              name='comment'
              subscription={{ value: true }}
              label={t('table.columns.comment')}
            />
            <StyledModalButtonsWrapper>
              <DefaultButton
                capitalize
                label={t('common.cancel')}
                onClick={hideModal}
                type='button'
                variant='tertiary'
              />
              <FormSpy subscription={{ errors: true }}>
                {({ errors }) => (
                  <DefaultButton
                    capitalize
                    isDisabled={!isEmpty(errors)}
                    label={t(
                      isEdit ? 'forms.save_changes' : 'company_details.contacts.save_contact',
                    )}
                    testid='submit-add-edit-contact-form'
                    type='submit'
                    loading={submitting}
                  />
                )}
              </FormSpy>
            </StyledModalButtonsWrapper>
          </StyledForm>
        )}
        validate={validateForm}
      />
    </>
  );
};
