import styled from 'styled-components/macro';

export const StyledAuthFormWrapper = styled.div<{ marginTop?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: ${({ marginTop }) => marginTop || '15em'};
  width: 50%;
  min-width: 20em;
  max-width: 30em;
`;

export const StyledLogoWrapper = styled.div`
  width: 100%;
`;

export const StyledLogo = styled.img`
  ${({ theme }) => theme.logo.default};
`;

export const StyledAuthHeader = styled.header`
  font-size: 20px;
  text-align: center;
  font-weight: bold;
`;

export const StyledPasswordPolicyList = styled.ul`
  list-style: inside;
  padding: 0.5em;
`;

export const StyledPasswordPolicyListItem = styled.li`
  margin: 0.5em 0 0.5em 0;
  list-style-type: disc;
`;

export const StyledAuthForm = styled.form`
  background-color: white;
  border-radius: 0.5em;
  box-shadow: 0.5em 0.5em 2em 0.5em ${({ theme }) => theme.colors.black200};
  display: flex;
  flex-direction: column;

  padding: 1em;
`;

export const StyledAuthInput = styled.input`
  border-radius: 0.2em;
  border: 1px solid ${({ theme }) => theme.colors.black100};
  margin: 0.5em 0 0.5em 0;
  height: 2.5em;
  padding: 1em;
  font-size: 15px;
  width: 100%;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0.3em ${({ theme }) => theme.colors.blue200};
  }
`;

export const StyledSubmitButton = styled.button`
  background-color: ${({ theme }) => theme.colors.blue400};
  margin: 1em 0 1em 0;
  padding: 0.5em;
  border-radius: 0.5em;
  border: 1px solid ${({ theme }) => theme.colors.blue300};
  color: white;
  font-size: 15px;
  height: 2.5em;
  &:hover {
    outline: none;
    background-color: ${({ theme }) => theme.colors.blue500};
  }
`;

export const StyledInstructions = styled.p`
  font-size: 15px;
  text-align: center;
`;

export const QRCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1em 0 1em 0;
`;
