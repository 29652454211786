import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { RevisedActivitiesFields } from './RevisedActivitiesFields';
import { RvoEventsEntity } from 'ducks/events/types';
import { StyledModalHeader } from 'components/Modal/styles';

interface ShowRevisedActivityProps {
  event: RvoEventsEntity;
}

export const ShowRevisedActivity = ({ event }: ShowRevisedActivityProps) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledModalHeader>{t('company_details.events.result')}</StyledModalHeader>

      {<RevisedActivitiesFields item={event} />}
    </>
  );
};
