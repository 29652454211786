/* eslint-disable import/no-default-export */
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { companiesReducer } from 'ducks/companies/reducer';
import { constantsReducer } from 'ducks/constants/reducer';
import { contactsReducer } from 'ducks/contacts/reducer';
import { eventsReducer } from 'ducks/events/reducer';
import { newVisitReducer } from 'ducks/newVisit/reducer';
import { powerSearchReducer } from 'ducks/powerSearch/reducer';
import { usersReducer } from 'ducks/users/reducer';
import { reportsReducer } from 'ducks/reports/reducers';
import { visitsReducer } from 'ducks/visits/reducer';
import { dashboardVisitsReducer } from 'ducks/dashboard/reducer';
import { workplacesReducer } from 'ducks/workplaces/reducer';
import { useDispatch } from 'react-redux';

export const rootReducer = combineReducers({
  companies: companiesReducer,
  constants: constantsReducer,
  contacts: contactsReducer,
  events: eventsReducer,
  newVisit: newVisitReducer,
  powerSearch: powerSearchReducer,
  dashboardVisits: dashboardVisitsReducer,
  reports: reportsReducer,
  users: usersReducer,
  visits: visitsReducer,
  workplaces: workplacesReducer,
});

const store = configureStore({ reducer: rootReducer });

export default store;

export type StoreType = ReturnType<typeof rootReducer>;

export type DispatchType = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<DispatchType>();

export interface ThunkApiInterface {
  dispatch: DispatchType;
  state: StoreType;
}
