import styled from 'styled-components/macro';

export const SearchSelectList = styled.div`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.black100};
  box-shadow: ${({ theme }) => theme.boxShadows.grey500};
  max-height: 50vh;
  overflow: auto;
  z-index: 1000;

  & > div {
    border-bottom: 1px solid ${({ theme }) => theme.colors.black100};

    &:last-child {
      border-bottom: none;
    }
  }
`;

export const SearchSelectListItemStandard = styled.div<{ selected?: boolean }>`
  ${({ theme }) => theme.fontSizes.base};
  background-color: ${({ theme, selected }) => (selected ? theme.colors.blue50 : 'white')};
  cursor: pointer;
  padding: ${({ theme }) => `${theme.sizes.xs} ${theme.sizes.hmd}`};
  word-break: break-word;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue50};
  }
`;

export const SearchSelectListItemSmall = styled.div`
  padding: ${({ theme }) => `${theme.sizes.xxs} ${theme.sizes.sm}`};
`;
