import styled, { keyframes } from 'styled-components/macro';

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

export const StyledSpinner = styled.svg<{
  small?: boolean;
  fast?: boolean;
}>`
  animation: ${rotate} 2s linear infinite;
  height: ${({ small }) => (small ? '2rem' : '4.8rem')};
  width: ${({ small }) => (small ? '2rem' : '4.8rem')};

  & circle {
    animation: ${dash} 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke: ${({ theme }) => theme.colors.blue300};
  }
`;
