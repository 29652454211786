import { createSelector } from '@reduxjs/toolkit';

import { StoreType } from 'store/index';

export const visitSelector = (state: StoreType) => state.newVisit;

export const activeCompanyVisitSelector = createSelector(
  visitSelector,
  (newVisit) => newVisit?.activeCompanyVisit,
);

export const visitDateSelector = createSelector(visitSelector, (newVisit) => newVisit?.visitDate);

export const visitIdSelector = createSelector(visitSelector, (newVisit): number =>
  Number(newVisit?.id),
);

export const visitParamsSelector = createSelector(visitSelector, (newVisit) => ({
  type: newVisit.type,
  light: newVisit.light,
}));

export const newVisitContactsSelector = createSelector(
  visitSelector,
  (newVisit) => newVisit?.contacts,
);

export const newVisitWorkplaceSelector = createSelector(
  visitSelector,
  (newVisit) => newVisit?.workplace,
);

export const newVisitWorkplaceOptionSelector = createSelector(
  visitSelector,
  (newVisit) => newVisit?.workplaceOption,
);

export const newVisitAddressSelector = createSelector(
  visitSelector,
  (newVisit) => newVisit?.visitationAddress,
);

export const newVisitFullAddressSelector = createSelector(
  visitSelector,
  (newVisit) => newVisit?.addressForVisiting,
);

export const newVisitSameAsCompanySelector = createSelector(
  visitSelector,
  (newVisit) => newVisit?.companyVisits[0]?.companyId,
);

export const newVisitCompanyVisitsSelector = createSelector(
  visitSelector,
  (newVisit) => newVisit?.companyVisits,
);
export const newVisitCreatorIdSelector = createSelector(
  visitSelector,
  (newVisit) => newVisit?.userId,
);
export const newVisitIndustryIdSelector = createSelector(
  visitSelector,
  (newVisit) => newVisit?.companyVisits[0]?.industryId,
);

export const newVisitCompanyVisitSelector = (companyVisitId?: string) =>
  createSelector(newVisitCompanyVisitsSelector, (companyVisits) =>
    companyVisits?.find(({ id }) => id === companyVisitId),
  );

export const isNewVisitFinished = (companyVisitId?: string) =>
  createSelector(
    [(state: StoreType) => newVisitCompanyVisitSelector(companyVisitId)(state)],
    (companyVisit) => companyVisit?.status === 'completed',
  );

export const isNewVisitFollowUp = (companyVisitId?: string) =>
  createSelector(
    [(state: StoreType) => newVisitCompanyVisitSelector(companyVisitId)(state)],
    (companyVisit) => companyVisit?.status === 'follow_up',
  );

export const isNewVisitHasAccessToTips = (companyVisitId?: string) =>
  createSelector(
    [(state: StoreType) => newVisitCompanyVisitSelector(companyVisitId)(state)],
    (companyVisit) => companyVisit?.actions.some((action) => action.type === 'tips_eligible'),
  );

export const isNewVisitWithEditingAccess = (companyVisitId?: string) =>
  createSelector(
    [
      (state: StoreType) =>
        (companyVisitId && newVisitCompanyVisitSelector(companyVisitId)(state)) || { actions: [] },
    ],
    (companyVisit) => companyVisit?.actions?.some((action) => action.type === 'edit'),
  );

export const isNewVisitWithDeletingAccess = (companyVisitId?: string) =>
  createSelector(
    [
      (state: StoreType) =>
        (companyVisitId && newVisitCompanyVisitSelector(companyVisitId)(state)) || { actions: [] },
    ],
    (companyVisit) => companyVisit?.actions?.some((action) => action.type === 'delete'),
  );
export const newVisitCompanyNameSelector = (companyVisitId: string) =>
  createSelector(
    [(state: StoreType) => newVisitCompanyVisitSelector(companyVisitId)(state)],
    (companyVisit) => companyVisit?.companyName,
  );

export const newVisitCompanyContactsSelector = (companyVisitId: string) =>
  createSelector(
    [(state: StoreType) => newVisitCompanyVisitSelector(companyVisitId)(state)],
    (companyVisit) => companyVisit?.contacts || [],
  );

export const newVisitCompanyEmailSelector = (companyVisitId: string) =>
  createSelector(
    [(state: StoreType) => newVisitCompanyVisitSelector(companyVisitId)(state)],
    (companyVisit) => companyVisit?.companyEmail,
  );

export const newVisitCompanyRoleSelectors = (companyId: string) =>
  createSelector(
    newVisitCompanyVisitsSelector,
    (companyVisits) => companyVisits?.find((cv) => companyId === cv.companyId)!.companyRoleIds,
  );

export const newVisitPersonalNotesSelector = (companyVisitId: string) =>
  createSelector(
    [(state: StoreType) => newVisitCompanyVisitSelector(companyVisitId)(state)],
    (companyVisit) =>
      ({
        notes: companyVisit?.personalNotes,
        attachments: companyVisit?.personalNotesAttachments,
        attachmentsToRemove: companyVisit?.personalNotesAttachmentsToRemove,
      } as const),
  );

export const newVisitSummaryNotesSelector = (companyVisitId: string) =>
  createSelector(
    [(state: StoreType) => newVisitCompanyVisitSelector(companyVisitId)(state)],
    (companyVisit) =>
      ({
        notes: companyVisit?.comment,
        attachments: companyVisit?.commentAttachments,
        attachmentsToRemove: companyVisit?.commentAttachmentsToRemove,
      } as const),
  );

export const newVisitObservationSelector = (companyVisitId?: string) =>
  createSelector(
    [(state: StoreType) => newVisitCompanyVisitSelector(companyVisitId)(state)],
    (companyVisit) => companyVisit?.observations,
  );
