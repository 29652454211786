import React from 'react';
import { navigate } from '@reach/router';

import i18n from 'i18n';
import { SearchCompanyEntity } from 'ducks/powerSearch/company/types';
import { SpanCenter } from 'styles/utils';
import { visitsComponentsMap } from 'containers/companyDetails/Visits/tableComponentsMap';
import { StatusBadge } from 'components/StatusBadge/StatusBadge';
import {
  StyledCellContent,
  StyledMultiLineTruncateWrapper,
  StyledNoWrapCellContent,
  StyledTruncatedContent,
  TrueFalse,
} from 'components/Table/TableRow/styles';
import { formatDate } from 'utils/date';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { StyledLink } from 'containers/companyDetails/BasicData/styles';

import { ReactComponent as PeopleIcon } from 'images/peopleIcon.svg';
import { ReactComponent as SpoonKnifeIcon } from 'images/spoonKnife.svg';

const t = (x: string) => i18n.t(x);

export const companyTableMapper = {
  ...visitsComponentsMap,
  name: {
    component: ({ name, companyId }: SearchCompanyEntity) => (
      <Tooltip label={name?.length > 24 ? name : ''}>
        <StyledTruncatedContent>
          <StyledLink
            as='button'
            onClick={() =>
              navigate(`/redesigned/companies/${companyId}/basic-data`, {
                state: { ...window.history.state, previousPathname: window.location.pathname },
              })
            }
          >
            {name}
          </StyledLink>
        </StyledTruncatedContent>
      </Tooltip>
    ),
    size: 'minmax(24rem, max-content)',
  },
  org_no: {
    component: ({ orgNo }: SearchCompanyEntity) => <StyledCellContent>{orgNo}</StyledCellContent>,
    size: 'min-content',
  },
  email: {
    component: ({ email }: SearchCompanyEntity) => (
      <Tooltip label={email && email.length > 24 ? email : ''}>
        <StyledTruncatedContent>{email || '-'}</StyledTruncatedContent>
      </Tooltip>
    ),
    size: 'minmax(24rem, max-content)',
  },
  phone: {
    component: ({ phone }: SearchCompanyEntity) => (
      <StyledNoWrapCellContent>{phone || '-'}</StyledNoWrapCellContent>
    ),
    size: 'minmax(24rem, max-content)',
  },
  last_visited_date: {
    component: ({ companyId, lastVisitedDate }: SearchCompanyEntity) => {
      return (
        <StyledLink
          as='button'
          onClick={() => navigate(`/redesigned/companies/${companyId}/visits`)}
        >
          {lastVisitedDate?.value ? formatDate(lastVisitedDate.value) : '-'}
        </StyledLink>
      );
    },
    size: 'min-content',
  },
  joined_date: {
    component: ({ joinedDate }: SearchCompanyEntity) => (
      <StyledCellContent>{joinedDate ? formatDate(joinedDate) : '-'}</StyledCellContent>
    ),
    size: 'min-content',
  },
  municipality_name: {
    component: ({ municipalityName }: SearchCompanyEntity) => (
      <StyledNoWrapCellContent>{municipalityName || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  postal_area_name: {
    component: ({ postalAreaName }: SearchCompanyEntity) => (
      <StyledCellContent>{postalAreaName || '-'}</StyledCellContent>
    ),
    size: 'min-content',
  },
  postal_code: {
    component: ({ postalCode }: SearchCompanyEntity) => (
      <StyledCellContent>{postalCode || '-'}</StyledCellContent>
    ),
    size: 'min-content',
  },
  nkodes: {
    component: ({
      nkode1,
      nkode2,
      nkode3,
      nkode1Name,
      nkode2Name,
      nkode3Name,
    }: SearchCompanyEntity) => {
      const codes = [
        nkode1 && `${nkode1}${nkode1Name ? ` - ${nkode1Name}` : ''}`,
        nkode2 && `${nkode2}${nkode2Name ? ` - ${nkode2Name}` : ''}`,
        nkode3 && `${nkode3}${nkode3Name ? ` - ${nkode3Name}` : ''}`,
      ].filter(Boolean);

      return codes.length > 0 ? (
        <Tooltip label={codes.join(', ')}>
          <StyledNoWrapCellContent>
            {nkode1}
            {codes.length - 1 ? ` + ${codes.length - 1}` : ''}
          </StyledNoWrapCellContent>
        </Tooltip>
      ) : (
        <StyledNoWrapCellContent>{'-'}</StyledNoWrapCellContent>
      );
    },
    size: 'min-content',
  },
  branch: {
    component: ({ branch }: SearchCompanyEntity) => {
      if (!branch) return <StyledCellContent>-</StyledCellContent>;
      const content: string[] = branch
        .filter((x) => x !== 'no_branch')
        .map((x: string) => t(`company_details.basic-data.${x}`));
      return content.length ? (
        <Tooltip label={content.join(' / ')}>
          <StatusBadge variant='active'>{content.map((x) => x[0]).join(' / ')}</StatusBadge>
        </Tooltip>
      ) : (
        <StyledCellContent>-</StyledCellContent>
      );
    },
    size: 'min-content',
  },
  cleaning_license_text: {
    component: ({ cleaningLicenseText }: SearchCompanyEntity) =>
      cleaningLicenseText ? (
        <StyledMultiLineTruncateWrapper>
          <Tooltip
            label={
              cleaningLicenseText && cleaningLicenseText?.length > 24 ? cleaningLicenseText : ''
            }
          >
            <StyledTruncatedContent>{cleaningLicenseText}</StyledTruncatedContent>
          </Tooltip>
        </StyledMultiLineTruncateWrapper>
      ) : (
        <StyledCellContent>-</StyledCellContent>
      ),
    size: 'minmax(24rem, max-content)',
  },
  risk_level: {
    component: ({ riskLevel }: SearchCompanyEntity) => <SpanCenter>{riskLevel}</SpanCenter>,
    size: 'min-content',
  },
  amu: {
    component: ({ amu }: SearchCompanyEntity) => <TrueFalse val={!!amu} />,
    size: 'min-content',
  },
  bht: {
    component: ({ bht }: SearchCompanyEntity) => <TrueFalse val={!!bht} />,
    size: 'min-content',
  },
  ik: {
    component: ({ ik }: SearchCompanyEntity) => <TrueFalse val={!!ik} />,
    size: 'min-content',
  },
  safety_representative: {
    component: ({ safetyRepresentative }: SearchCompanyEntity) => (
      <TrueFalse val={safetyRepresentative} />
    ),
    size: 'min-content',
  },
  number_of_employees: {
    component: ({ numberOfEmployees }: SearchCompanyEntity) => (
      <SpanCenter>{numberOfEmployees || '-'}</SpanCenter>
    ),
    size: 'min-content',
    headerComponent: () => (
      <Tooltip label={t(`table.columns.number_of_employees`)}>
        <SpanCenter withFlex>
          <PeopleIcon data-testid='th-number_of_employees' />
        </SpanCenter>
      </Tooltip>
    ),
  },
  has_alcohol_license: {
    component: ({ providesAlcoholService }: SearchCompanyEntity) => (
      <TrueFalse val={providesAlcoholService} />
    ),
    size: 'min-content',
    headerComponent: () => (
      <Tooltip label={t(`table.columns.has_alcohol_license`)}>
        <SpanCenter withFlex>
          <SpoonKnifeIcon data-testid='th-has_alcohol_license' />
        </SpanCenter>
      </Tooltip>
    ),
  },
  district_names: {
    component: ({ districtNames }: SearchCompanyEntity) =>
      districtNames?.length > 1 ? (
        <Tooltip label={districtNames.join(', ')}>
          <StyledNoWrapCellContent>
            {districtNames[0]} + {districtNames.length - 1}
          </StyledNoWrapCellContent>
        </Tooltip>
      ) : (
        <StyledNoWrapCellContent>
          {!districtNames || districtNames.length === 0 ? '-' : districtNames[0]}
        </StyledNoWrapCellContent>
      ),
    size: 'max-content',
  },
  aka: {
    component: ({ aka }: SearchCompanyEntity) => (
      <StyledCellContent>{aka || '-'}</StyledCellContent>
    ),
    size: 'min-content',
  },
};

export type CompanyTableMapper = typeof companyTableMapper;
