import React, { FC } from 'react';

import { CapsLabel } from 'components/CapsLabel/CapsLabel';
import styled from 'styled-components/macro';

interface ValueWithLabelPropTypes {
  label: string;
  flexDirection?: 'horizontal' | 'vertical';
  className?: string;
}

export const StyledContainer = styled.div<{ flexDirection?: 'horizontal' | 'vertical' }>`
  display: flex;
  flex-direction: ${({ flexDirection }) => (flexDirection === 'horizontal' ? 'row' : 'column')};
  align-items: ${({ flexDirection }) => (flexDirection === 'horizontal' ? 'center' : 'flex-start')};
  margin-bottom: ${({ theme }) => theme.sizes.hmd};
  margin-right: ${({ flexDirection, theme }) => theme.sizes.lg};

  & span:first-child {
    margin-right: ${({ flexDirection, theme }) =>
      flexDirection === 'horizontal' ? theme.sizes.sm : 0};
    margin-bottom: ${({ flexDirection, theme }) =>
      flexDirection === 'horizontal' ? 0 : theme.sizes.xs};
  }
`;

export const ValueWithLabel: FC<ValueWithLabelPropTypes> = ({
  label,
  children,
  flexDirection,
  className,
}) => (
  <StyledContainer className={className} flexDirection={flexDirection}>
    <CapsLabel>{`${label}${flexDirection === 'horizontal' ? ':' : ''}`}</CapsLabel>
    {children}
  </StyledContainer>
);

ValueWithLabel.defaultProps = {
  flexDirection: 'horizontal',
};
