import { omit } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createNewVisitCompanyVisit,
  createNewVisitWorkplace,
  createObservation,
  createSameAsCompanyVisit,
  createStep2Contact,
  createStep3Contact,
  createVisitWithOneTimeAddress,
  deleteCompanyVisit,
  deleteObservation,
  deleteVisit,
  editNewVisitWorkplace,
  getVisitById,
  reduxDeleteCompanyVisitAttachment,
  reduxDeleteObservationAttachment,
  removeStep2Contact,
  removeStep3Contact,
  setNewVisitDate,
  setNewVisitDateAndId,
  setStep2Contact,
  setStep3Contact,
  setWorkplaceIdToVisit,
  updateCompanyVisit,
  updateCompanyVisitStatus,
  updateCompanyVisitTips,
  updateObservation,
  updateSameAsCompanyVisit,
  updateVisitWithOneTimeAddress,
} from 'ducks/newVisit/actions';
import { amendCompanyVisitResponse, amendVisitResponse } from 'ducks/newVisit/helpers';
import { NewVisitState, NewVisitType, NewVisitWorkplace, NewVisitWorkplaceStepType } from './types';

const locationId = window.location.pathname.match(/new-ordinary-visit\/(\d*)\//);

export const initialState: NewVisitState = {
  activeCompanyVisit: null,
  companyVisits: [],
  contacts: [],
  id: locationId ? locationId[1] : '',
  loading: false,
  type: 'Ordinary',
  userId: null,
  visitationAddress: '',
  visitDate: '',
  workplaceOption: 'New',
};

const newVisitSlice = createSlice({
  name: 'newVisit',
  initialState,
  reducers: {
    // step 1
    setNewVisitWorkplace: (state, { payload }: PayloadAction<NewVisitWorkplace | undefined>) => {
      state.workplace = payload;
    },

    setNewVisitWorkplaceOption: (state, { payload }: PayloadAction<NewVisitWorkplaceStepType>) => {
      state.workplaceOption = payload;
    },

    setActiveCompanyId: (state, { payload }: PayloadAction<string | null>) => {
      return { ...state, activeCompanyVisit: payload };
    },

    setVisitWithoutRequest: (
      state,
      {
        payload,
      }: PayloadAction<{
        visitDate: string;
        userId: number | null;
        type: NewVisitType;
        light: boolean;
      }>,
    ) => {
      state.visitDate = payload.visitDate;
      state.userId = payload.userId;
      state.type = payload.type;
      state.light = payload.light;
    },

    updateVisitDateWithoutRequest: (state, { payload }: PayloadAction<string>) => {
      state.visitDate = payload;
    },

    resetNewVisit: (_, params: PayloadAction<{ id: string } | undefined>) => {
      if (params) {
        // Force id to reset
        return { ...initialState, ...params.payload };
      }
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setNewVisitDateAndId.fulfilled, (state, { payload }) => {
      const newState = amendVisitResponse({ ...initialState, ...payload });
      return { ...newState, workplaceOption: state.workplaceOption };
    });

    builder.addCase(getVisitById.fulfilled, (state, { payload, meta }) => {
      return amendVisitResponse(
        { ...state, ...payload },
        meta?.arg?.withoutResetActiveCompanyVisit || false,
      );
    });

    builder.addCase(setNewVisitDate.fulfilled, (state, { payload }) => {
      const newState = amendVisitResponse({ ...state, ...payload });
      return { ...newState, workplaceOption: state.workplaceOption };
    });

    builder.addCase(setWorkplaceIdToVisit.fulfilled, (state, { payload }) => {
      const newState = amendVisitResponse({ ...state, ...payload });
      return { ...newState, workplaceOption: state.workplaceOption };
    });

    builder.addCase(createNewVisitWorkplace.fulfilled, (state, { payload }) => {
      state.workplace = payload;
    });

    builder.addCase(editNewVisitWorkplace.fulfilled, (state, { payload }) => {
      if (state.workplace) {
        Object.assign(state.workplace, payload);
      }
    });

    // ---------- workplace contacts ----------

    builder.addCase(createStep2Contact.fulfilled, (state, { payload }) => {
      state.contacts.unshift(payload);
    });

    builder.addCase(setStep2Contact.pending, (state, { meta }) => {
      if (state.contacts.some(({ id, origin }) => id === meta.arg.id && origin === meta.arg.origin))
        state.contacts = state.contacts.map((contact) =>
          contact.id === meta.arg.id && contact.origin === meta.arg.origin
            ? { ...contact, loading: true }
            : contact,
        );
    });

    builder.addCase(setStep2Contact.fulfilled, (state, { payload }) => {
      if (state.contacts.some(({ id, origin }) => id === payload.id && origin === payload.origin))
        state.contacts = state.contacts.map((contact) =>
          contact.id === payload.id && contact.origin === payload.origin ? payload : contact,
        );
      else state.contacts.unshift(payload);
    });

    builder.addCase(removeStep2Contact.pending, (state, { meta }) => {
      state.contacts = state.contacts.map((contact) =>
        contact.id === meta.arg.id && contact.origin === meta.arg.origin
          ? { ...contact, loading: true }
          : contact,
      );
    });

    builder.addCase(removeStep2Contact.fulfilled, (state, { meta }) => {
      state.contacts = state.contacts.filter(
        ({ id, origin }) => !(id === meta.arg.id && origin === meta.arg.origin),
      );
    });

    // ---------- company visit ----------

    builder.addCase(createSameAsCompanyVisit.fulfilled, (_, { payload }) =>
      amendVisitResponse(payload),
    );

    builder.addCase(updateSameAsCompanyVisit.fulfilled, (_, { payload }) =>
      amendVisitResponse(payload),
    );

    builder.addCase(createVisitWithOneTimeAddress.fulfilled, (_, { payload }) =>
      amendVisitResponse(payload),
    );

    builder.addCase(updateVisitWithOneTimeAddress.fulfilled, (_, { payload }) =>
      amendVisitResponse(payload),
    );

    builder.addCase(updateCompanyVisit.fulfilled, (state, { payload, meta }) => {
      const companyVisit = state.companyVisits.find(({ id }) => id === meta.arg.companyVisitId);

      if (companyVisit) {
        Object.assign(
          companyVisit,
          meta?.arg?.without?.length ? omit(payload, meta.arg.without) : payload,
        );
      }
    });

    builder.addCase(updateCompanyVisitStatus.fulfilled, (state, { payload, meta }) => {
      const companyVisit = state.companyVisits.find(({ id }) => id === meta.arg.id);
      Object.assign(companyVisit, payload);
    });

    builder.addCase(createNewVisitCompanyVisit.fulfilled, (state, { payload }) => {
      state.companyVisits.push(amendCompanyVisitResponse(payload));
    });

    builder.addCase(reduxDeleteCompanyVisitAttachment.fulfilled, (state, { meta }) => {
      const { companyVisitId, id } = meta.arg;

      const companyVisit = state.companyVisits.find(({ id }) => id === companyVisitId)!;
      companyVisit.personalNotesAttachments = companyVisit.personalNotesAttachments.filter(
        (file) => file.id !== id,
      );
      companyVisit.commentAttachments = companyVisit.commentAttachments.filter(
        (file) => file.id !== id,
      );
    });

    builder.addCase(deleteCompanyVisit.fulfilled, (state, { meta }) => {
      state.companyVisits = state.companyVisits.filter(({ id }) => meta.arg !== id);
    });

    // ---------- company visit contacts ----------

    builder.addCase(createStep3Contact.fulfilled, (state, { payload }) => {
      const companyVisit = state.companyVisits.find(({ id }) => id === payload.companyVisitId)!;

      companyVisit.contacts.unshift(payload);
    });

    builder.addCase(setStep3Contact.pending, (state, { meta }) => {
      const companyVisit = state.companyVisits.find(({ id }) => id === meta.arg.company_visit_id);

      if (
        companyVisit?.contacts.some(
          ({ id, origin }) => id === meta.arg.id && origin === meta.arg.origin,
        )
      )
        companyVisit.contacts = companyVisit.contacts.map((contact) =>
          contact.id === meta.arg.id && contact.origin === meta.arg.origin
            ? { ...contact, loading: true }
            : contact,
        );
    });

    builder.addCase(setStep3Contact.fulfilled, (state, { payload }) => {
      const companyVisit = state.companyVisits.find(({ id }) => id === payload.companyVisitId)!;

      if (
        companyVisit.contacts.some(
          ({ id, origin }) => id === payload.id && origin === payload.origin,
        )
      )
        companyVisit.contacts = companyVisit.contacts.map((contact) =>
          contact.id === payload.id && contact.origin === payload.origin ? payload : contact,
        );
      else companyVisit.contacts.unshift(payload);
    });

    builder.addCase(removeStep3Contact.pending, (state, { meta }) => {
      const companyVisit = state.companyVisits.find(({ id }) => id === meta.arg.company_visit_id)!;

      companyVisit.contacts = companyVisit.contacts.map((contact) =>
        contact.id === meta.arg.id && contact.origin === meta.arg.origin
          ? { ...contact, loading: true }
          : contact,
      );
    });

    builder.addCase(removeStep3Contact.fulfilled, (state, { meta }) => {
      const companyVisit = state.companyVisits.find(({ id }) => id === meta.arg.company_visit_id)!;

      companyVisit.contacts = companyVisit.contacts.filter(
        ({ id, origin }) => !(id === meta.arg.id && origin === meta.arg.origin),
      );
    });

    // ---------- observations ----------

    builder.addCase(createObservation.fulfilled, (state, { payload }) => {
      const companyVisit = state.companyVisits.find(({ id }) => id === payload.companyVisitId)!;
      companyVisit.observations.push(payload);
    });

    builder.addCase(updateObservation.fulfilled, (state, { payload }) => {
      const companyVisit = state.companyVisits.find(({ id }) => id === payload.companyVisitId)!;
      companyVisit.observations = companyVisit.observations.map((obs) =>
        obs.id === payload.id ? payload : obs,
      );
    });

    builder.addCase(deleteObservation.fulfilled, (state, { meta }) => {
      const companyVisit = state.companyVisits.find(({ id }) => id === meta.arg.companyVisitId)!;
      companyVisit.observations = companyVisit.observations.filter(({ id }) => id !== meta.arg.id);
    });

    builder.addCase(reduxDeleteObservationAttachment.fulfilled, (state, { meta }) => {
      const companyVisit = state.companyVisits.find(({ id }) => id === meta.arg.companyVisitId)!;
      const observation = companyVisit.observations.find(
        ({ id }) => id === meta.arg.observationId,
      )!;
      observation.attachments = observation.attachments.filter(({ id }) => id !== meta.arg.id);
    });

    builder.addCase(deleteVisit.fulfilled, () => initialState);
    builder.addCase(updateCompanyVisitTips.fulfilled, (state, { meta, payload }) => {
      const companyVisit = state.companyVisits.find(({ id }) => id === meta.arg.companyVisitId);

      if (companyVisit) {
        companyVisit.actions = companyVisit.actions.filter(
          (action) => action.type !== 'tips_eligible',
        );
        companyVisit.tipsExternalId = payload.id;
      }
    });
  },
});

const { reducer: newVisitReducer, actions } = newVisitSlice;

export const {
  setNewVisitWorkplace,
  setNewVisitWorkplaceOption,
  setActiveCompanyId,
  setVisitWithoutRequest,
  updateVisitDateWithoutRequest,
  resetNewVisit,
} = actions;
export { newVisitReducer };
