import React, { forwardRef, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize, isEqual } from 'lodash';

import { DatePicker } from 'components/DatePicker/DatePicker';
import { PopperDropdown } from 'components/PopperDropdown/PopperDropdown';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { formatDate } from 'utils/date';
import {
  CentredDefaultButton,
  StyledCapsLabel,
  StyledInputWrapper,
} from 'contexts/FilterContext/styles';
import { TextInput } from 'components/TextInput/TextInput';

import { ReactComponent as CalendarIcon } from 'images/calendarIcon.svg';
import { ReactComponent as ArrowDownIcon } from 'images/arrowDownIcon.svg';

const DatePickerFilter = (props) => {
  const { t } = useTranslation();
  const { isActive, label, maxValue, minValue, onChange, value } = props;
  const [minOrMax, setMinOrMax] = useState();

  const ToggleComponent = forwardRef((compProps, ref) => (
    <DefaultButton
      icon={<ArrowDownIcon />}
      iconPosition='right'
      isActive={isActive}
      isDisabled={maxValue.value === minValue.value}
      label={label}
      ref={ref}
      variant='filter'
      {...compProps}
    />
  ));

  const changeHandler = (value) => {
    if (!value) onChange({ displayValue: [], isActive: false, value: null });
    else {
      const displayValue = [
        value.max ? `${capitalize(t('filters.to'))}: ${formatDate(value.max)}` : null,
        value.min ? `${capitalize(t('filters.from'))}: ${formatDate(value.min)}` : null,
      ].filter(Boolean);

      onChange({ displayValue, isActive: true, value });
    }
  };

  return (
    <PopperDropdown ToggleComponent={ToggleComponent}>
      {minOrMax ? (
        <>
          <StyledCapsLabel>
            {t(minOrMax === 'min' ? 'filters.date_from' : 'filters.date_to')}
          </StyledCapsLabel>
          <DatePicker
            cancelHandler={() => setMinOrMax()}
            onChange={(val) => {
              changeHandler({ ...value, [minOrMax]: val });
              setMinOrMax();
            }}
            value={value ? value[minOrMax] : null}
          />
        </>
      ) : (
        <>
          <StyledInputWrapper>
            <TextInput
              inputProps={{
                onClick: () => setMinOrMax('min'),
                readOnly: true,
                value: value?.min ? formatDate(value.min) : '',
              }}
              label={t('filters.date_from')}
              placeholder='DD.MM.YYYY'
              postfixElement={<CalendarIcon />}
              size='fullWidth'
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <TextInput
              inputProps={{
                onClick: () => setMinOrMax('max'),
                readOnly: true,
                value: value?.max ? formatDate(value.max) : '',
              }}
              label={t('filters.date_to')}
              placeholder='DD.MM.YYYY'
              postfixElement={<CalendarIcon />}
              size='fullWidth'
            />
          </StyledInputWrapper>
          <CentredDefaultButton
            isActive={isActive}
            label={t('filters.clear_selected')}
            onClick={() => isActive && changeHandler(null)}
            variant='contrast-flat'
          />
        </>
      )}
    </PopperDropdown>
  );
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.value, nextProps.value) &&
  isEqual(prevProps.maxValue, nextProps.maxValue) &&
  isEqual(prevProps.minValue, nextProps.minValue);

const PureDatePickerFilter = memo(DatePickerFilter, areEqual);

export { PureDatePickerFilter as DatePickerFilter };
