import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate, useLocation } from '@reach/router';

import {
  StyledContextBar,
  StyledDetails,
  StyledLayout,
  StyledTabsFieldWrapper,
} from 'containers/companyDetails/CompanyNavigationPanel/styles';

import { Tabs } from 'components/Tabs/Tabs';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { StyledNewVisitNavigationButton } from 'containers/newVisit/styles';
import { ArrowLeftBigIcon } from 'utils/iconsMap';

export type TabsName = 'basic-data' | 'contacts' | 'visits';

interface WorkplaceNavigationPanelPropTypes {
  workplaceName: string;
  workplaceId: string;
  activeTab: TabsName;
}

const tabNames: TabsName[] = ['basic-data', 'visits'];

const setTab = (id: string, tab: string) =>
  navigate(`/redesigned/workplaces/${id}/${tab}`, {
    state: { ...window.history.state },
    replace: true,
  });

export const WorkplaceNavigationPanel: FC<WorkplaceNavigationPanelPropTypes> = ({
  workplaceName = '',
  workplaceId = '',
  activeTab,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const onSetTab = (tab: string) => setTab(workplaceId, tab);

  const returnUrl =
    location.state?.previousPathname && location.state?.previousPathname !== location.pathname
      ? location.state?.previousPathname
      : `/redesigned/search/workplace`;

  return (
    <StyledContextBar>
      <StyledLayout>
        <StyledDetails>
          <Tooltip label={t('common.back')}>
            <StyledNewVisitNavigationButton
              icon={<ArrowLeftBigIcon />}
              onClick={() => navigate(returnUrl, { state: { ...location.state } })}
              testid='back'
              type='button'
              variant='tertiary'
            />
          </Tooltip>
          <h1 style={{ marginRight: '1.5rem' }}>{workplaceName}</h1>
        </StyledDetails>

        <StyledTabsFieldWrapper>
          <Tabs activeTab={activeTab} handleClick={onSetTab} tabNames={tabNames} translate />
        </StyledTabsFieldWrapper>
      </StyledLayout>
    </StyledContextBar>
  );
};
