import React, { FC } from 'react';
import DOMPurify from 'dompurify';

import { StyledRichTextReset } from './styles';

export const RichTextRenderer: FC<{ children: string }> = ({ children }) => {
  return (
    <StyledRichTextReset>
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(children) }}></div>
    </StyledRichTextReset>
  );
};
