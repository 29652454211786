import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledModalButtonsWrapper, StyledModalHeader, useModal } from 'components/Modal/Modal';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { GroupListPicker } from 'components/GroupListPicker/GroupListPicker';
import { useSelector } from 'react-redux';
import {
  standardCommentCategoriesSelector,
  standardCommentsSelector,
} from 'ducks/constants/selectors';
import { StandardComment } from 'ducks/constants/types';
import { CapsLabel } from 'components/CapsLabel/styles';
import { Margin } from 'styles/utils';
import { StyledGroupListItem, StyledGroupListName } from 'components/GroupListPicker/styles';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { GroupListItem } from 'components/GroupListPicker/GroupListPicker';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { stripHtmlTags } from 'utils';

interface NewVisitCommentModalPropTypes {
  linkedStandardComments?: GroupListItem[];
  handleSelect: React.Dispatch<React.SetStateAction<string>>;
}

const amendComment = (comment: StandardComment): GroupListItem => ({
  ...comment,
  description: stripHtmlTags(comment.content),
});

export const NewVisitCommentModal = (props: NewVisitCommentModalPropTypes) => {
  const { handleSelect, linkedStandardComments = [] } = props;
  const allStandardComments = useSelector(standardCommentsSelector);
  const { t } = useTranslation();
  const { hideModal } = useModal();
  const [selectedComments, setSelectedComments] = useState<string[]>([]);
  const standardCommentCategories = useSelector(standardCommentCategoriesSelector);

  const onSave = () => {
    const message = selectedComments
      .map((commentId) => allStandardComments[commentId].content)
      .join('<br />');
    handleSelect(message);
    hideModal();
  };

  const amendedOptions = standardCommentCategories.map(({ name, id, standardComments }) => ({
    id,
    name,
    elements: standardComments.map((commentId) => amendComment(allStandardComments[commentId])),
  }));
  return (
    <>
      <StyledModalHeader>{t('new_visits.choose_standard_comment')}</StyledModalHeader>

      {linkedStandardComments.length > 0 && (
        <Margin bottom='2.4rem'>
          <CapsLabel>Linked</CapsLabel>
          {linkedStandardComments.map((standardComment) => {
            return (
              <Tooltip
                key={standardComment.id}
                label={stripHtmlTags(standardComment.description)}
                placement='bottom-end'
              >
                <StyledGroupListItem
                  onClick={() =>
                    selectedComments.includes(standardComment.id)
                      ? setSelectedComments(
                          selectedComments.filter((x) => x !== standardComment.id),
                        )
                      : setSelectedComments([...selectedComments, standardComment.id])
                  }
                >
                  <Margin right='1.6rem'>
                    <Checkbox
                      isChecked={selectedComments.includes(standardComment.id)}
                      onClick={() => {}}
                    />
                  </Margin>
                  <StyledGroupListName>{standardComment.name}</StyledGroupListName>
                </StyledGroupListItem>
              </Tooltip>
            );
          })}
        </Margin>
      )}

      {linkedStandardComments.length > 0 && <CapsLabel>All</CapsLabel>}
      <GroupListPicker
        items={amendedOptions}
        onChange={setSelectedComments}
        values={selectedComments}
        withSearch
      />

      {amendedOptions.length === 0 && <InfoBox big message={t('new_visits.no_results_message')} />}
      <StyledModalButtonsWrapper>
        <DefaultButton
          capitalize
          label={t('common.cancel')}
          onClick={hideModal}
          type='button'
          variant='tertiary'
        />
        <DefaultButton
          capitalize
          label={t('forms.save_changes')}
          onClick={onSave}
          testid='submit-save'
          type='button'
        />
      </StyledModalButtonsWrapper>
    </>
  );
};
