import React, { FC } from 'react';

import { StyledSpinner } from './styles';

interface LoadingSpinnerPropTypes {
  small?: boolean;
}

export const LoadingSpinner: FC<LoadingSpinnerPropTypes> = (props) => (
  <StyledSpinner {...props} viewBox='0 0 50 50'>
    <circle cx='25' cy='25' fill='none' r='20' strokeWidth='5'></circle>
  </StyledSpinner>
);
