import React from 'react';

import {
  CustomTag,
  DefaultTag,
  TagCloseButton,
  TagContentWrapper,
  TagLabel,
  TagValue,
} from './styles';
import { colorVariants, TagColorVariant } from './colorVariants';

import { ReactComponent as CloseIcon } from 'images/closeBigIcon.svg';

interface TagProps {
  active?: boolean;
  className?: string;
  handleClick?: () => void;
  label?: string;
  variant?: TagColorVariant;
  value: string;
}

const Tag = ({ active, className, handleClick, label, value, variant }: TagProps) => {
  const Component = variant ? CustomTag : DefaultTag;
  const colorPallete = variant && colorVariants[variant];

  return (
    <Component
      active={active}
      className={className}
      colorPallete={colorPallete}
      data-testid='tag-container'
    >
      <TagContentWrapper>
        {label && <TagLabel>{label}</TagLabel>}
        <TagValue>{value}</TagValue>
      </TagContentWrapper>
      {handleClick && (
        <TagCloseButton active={active} data-testid='tag-button' onClick={handleClick}>
          <CloseIcon />
        </TagCloseButton>
      )}
    </Component>
  );
};

export { Tag };
