import { createAsyncThunk } from '@reduxjs/toolkit';
import FileSaver from 'file-saver';

import { API } from 'api';
import { GetWorkplaceResponse, SearchWorkplacesParams, SearchWorkplacesResponse } from './types';
import { WorkplaceFormSubmitValues } from 'containers/newVisit/shared/WorkplaceForm';
import { AxiosError } from 'axios';

export const searchWorkplaces = createAsyncThunk(
  'search/Workplaces',
  async (params: SearchWorkplacesParams, { rejectWithValue, signal }) => {
    try {
      const response = await API.get(`power/workplaces`, { params, signal });
      return response.data as SearchWorkplacesResponse;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const getWorkplaces = async (params: SearchWorkplacesParams) => {
  const response = await API.get(`power/workplaces`, { params });
  return response.data as SearchWorkplacesResponse;
};

export const getWorkplace = async (id: string) => {
  const response = await API.get(`workplaces/${id}`);
  return response.data as GetWorkplaceResponse;
};

export const getWorkplaceCSV = async (ids: string[]) => {
  const response = await API.post(
    `power/workplaces`,
    {
      id: ids.join(','),
    },
    {
      params: { page: 1, page_size: 10000 },
      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
      },
    },
  );

  const blob = new Blob([response.request.response], {
    type: 'application/vnd.ms-excel',
  });

  FileSaver.saveAs(blob, 'workplace-export.xls');
  return response.data as SearchWorkplacesResponse;
};

export const createWorkplace = createAsyncThunk(
  'power/newWorkplace',
  async (attributes: WorkplaceFormSubmitValues, { rejectWithValue }) => {
    try {
      const response = await API.post<GetWorkplaceResponse>(`workplaces`, { data: { attributes } });
      return response.data;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const editWorkplace = createAsyncThunk(
  'power/editWorkplace',
  async (
    { id, ...attributes }: WorkplaceFormSubmitValues & { id: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await API.put<GetWorkplaceResponse>(`workplaces/${id}`, {
        data: { attributes },
      });
      return response.data;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const removeWorkplace = createAsyncThunk(
  'power/removeWorkplace',
  async (id: string, { rejectWithValue }) => {
    try {
      return await API.delete<void>(`workplaces/${id}`);
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);
