import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { useBatchActionsContext } from 'contexts/BatchActionsContext/BatchActionsContext';
import { ActionsHandlers } from 'components/Table/types';
import { ExportIcon } from 'utils/iconsMap';

export const CommonActionsPanel: FC<{
  actionHandlers?: ActionsHandlers;
}> = ({ children, actionHandlers }) => {
  const { t } = useTranslation();
  const { itemsSelected } = useBatchActionsContext();

  return (
    <>
      <Tooltip label={t('common.export')}>
        <DefaultButton
          icon={<ExportIcon />}
          isDisabled={!actionHandlers?.exportCSV || itemsSelected.length === 0}
          onClick={() => actionHandlers?.exportCSV && actionHandlers?.exportCSV(itemsSelected)}
          variant='secondary'
        />
      </Tooltip>
      {children}
    </>
  );
};
