import { createAsyncThunk } from '@reduxjs/toolkit';
import FileSaver from 'file-saver';
import { AxiosError, CancelTokenSource } from 'axios';

import { API } from 'api';
import { SearchContactsParams, SearchContactsResponse } from './types';

export const searchContacts = createAsyncThunk(
  'search/contacts',
  async (params: SearchContactsParams, { rejectWithValue, signal }) => {
    try {
      const response = await API.get(`power/contacts`, { params, signal });
      return response.data as SearchContactsResponse;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const getContacts = async (params: SearchContactsParams, token: CancelTokenSource) => {
  const response = await API.get(`new_visits/contacts`, { params, cancelToken: token.token });
  return response.data as SearchContactsResponse;
};

export const searchAllContacts = async (params: SearchContactsParams) => {
  const response = await API.get(`power/contacts`, {
    params: { ...params, page_size: 10000 },
  });
  return response.data as SearchContactsResponse;
};

export const getContactsCSV = async (ids: string[]) => {
  const response = await API.post(
    `power/contacts`,
    {
      id: ids.join(','),
    },
    {
      params: { page: 1, page_size: 10000 },
      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
      },
    },
  );

  const blob = new Blob([response.request.response], {
    type: 'application/vnd.ms-excel',
  });

  FileSaver.saveAs(blob, 'contacts-export.xls');
  return response.data as SearchContactsResponse;
};
