import React, { FC, ReactElement, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ArrowRightBigIcon, EyeIcon } from 'utils/iconsMap';
import { chosenDistrictSelector } from 'ducks/powerSearch/selectors';
import { ContentContainer } from 'styles/utils';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { getWorkplaces } from 'ducks/powerSearch/workplace/actions';
import { NewVisitWorkplace, setNewVisitWorkplace } from 'ducks/newVisit';
import { SearchSelect } from 'components/Forms/SearchSelect/SearchSelect';
import { SearchSelectListItemStandard } from 'components/Forms/SearchSelect/styles';
import { Text } from 'components/Text/Text';
import { useAppDispatch } from 'store';
import { useModal } from 'components/Modal/Modal';
import { userIndustryNameSelector } from 'ducks/users/selectors';
import { WorkplaceListItem } from 'containers/newVisit/OrdinaryVisit/styles';
import { WorkplaceModal } from 'components/WorkplaceModal/WorkplaceModal';

interface WorkplaceSearchProps {
  isDisabled?: boolean;
}

export const WorkplaceSearch: FC<WorkplaceSearchProps> = ({ isDisabled }) => {
  const [listItems, setListItems] = useState<ReactElement[]>([]);
  const { t } = useTranslation();
  const { showModal, setModalComponent, setCustomStyles, setShowHeader } = useModal();
  const dispatch = useAppDispatch();

  const chosenDistrict = useSelector(chosenDistrictSelector);
  const currentUserIndustryName = useSelector(userIndustryNameSelector);

  const onSearchHandle = async (text: string) => {
    if (text.length === 0) setListItems([]);
    else
      debouncedSearchWorkplace.current({
        'filter[industry][values]': currentUserIndustryName,
        'filter[has_expired]': 'no',
        'filter[municipality_active]': 'yes',
        query: text,
        page_size: 500,
      });
  };

  const debouncedSearchWorkplace = useRef(
    debounce(
      async (params: {
        'filter[has_expired]': string;
        'filter[industry][values]'?: string;
        'filter[municipality_active]': string;
        page_size: number;
        query: string;
      }) => {
        const { data } = await getWorkplaces(params);
        if (data?.tableBody?.length > 0) {
          setListItems([
            ...data.tableBody.map((item: NewVisitWorkplace) => (
              <SearchSelectListItemStandard key={item.workplaceId}>
                <WorkplaceListItem onClick={() => dispatch(setNewVisitWorkplace(item))}>
                  <div>
                    <div>
                      {item.name} {item.kind && `- ${item.kind}`}
                    </div>
                    <Text color='black500' size='xs'>
                      {item.municipalityName}, {item.address}
                    </Text>
                  </div>
                  <ContentContainer display='flex' margin='auto 1.2rem auto auto'>
                    <DefaultButton
                      icon={<EyeIcon />}
                      onClick={(e) => {
                        e.stopPropagation();
                        setCustomStyles({ maxWidth: '78rem' });
                        setModalComponent(<WorkplaceModal isDisabled workplace={item} />);
                        setShowHeader(false);
                        showModal();
                      }}
                      size='small'
                      variant='tertiary'
                    />
                  </ContentContainer>
                  <ArrowRightBigIcon />
                </WorkplaceListItem>
              </SearchSelectListItemStandard>
            )),
          ]);
        } else {
          setListItems([
            <SearchSelectListItemStandard key='no-results'>
              {t('new_visits.no_results_message')}
            </SearchSelectListItemStandard>,
          ]);
        }
      },
      350,
    ),
  );

  return (
    <ContentContainer margin='1.2rem auto' maxWidth='72rem'>
      <SearchSelect
        autoFocus
        closeOnSelect
        disabled={isDisabled}
        listItems={listItems}
        onSearch={onSearchHandle}
        placeholder={t('new_visits.find_workplace')}
        vsize='small'
      />
    </ContentContainer>
  );
};
