import React, { FC, useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import { device } from 'consts/device';

interface ContextValue {
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
}

const getScreenSizeValues = (): ContextValue => ({
  sm: window.matchMedia(device.sm).matches,
  md: window.matchMedia(device.md).matches,
  lg: window.matchMedia(device.lg).matches,
  xl: window.matchMedia(device.xl).matches,
});

export const ScreenSizeContext = React.createContext<ContextValue>(getScreenSizeValues());

export const ScreenSizeContextProvider: FC = ({ children }) => {
  const [screenSizes, setScreenSizes] = useState<ContextValue>(getScreenSizeValues());

  useEffect(() => {
    const handler = () => {
      const cur = getScreenSizeValues();
      setScreenSizes((prev) => (isEqual(prev, cur) ? prev : cur));
    };
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, [setScreenSizes]);

  return <ScreenSizeContext.Provider value={screenSizes}>{children}</ScreenSizeContext.Provider>;
};

export const useScreenSizeContext = () => React.useContext(ScreenSizeContext);
