import React, { ComponentType, useEffect } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { FieldSingleSelect } from 'components/FieldSingleSelect/FieldSingleSelect';
import { StyledError, StyledFieldWrapper, StyledLabel } from 'components/Forms/styles';

type Option = { value: string | number; label: string };

interface SingleSelectFieldPropTypes extends FieldRenderProps<Option> {
  isDisabled?: boolean;
  label?: string;
  options: Option[];
  required?: boolean;
  placeholder?: string;
  withClearButton?: boolean;
  absolute?: boolean;
  size?: 'small' | 'medium' | 'large';
}

const SingleSelectField: ComponentType<SingleSelectFieldPropTypes> = ({
  input: { onChange, onBlur, value, name },
  isDisabled,
  options,
  placeholder,
  label,
  required,
  meta,
  withClearButton,
  absolute = false,
  size,
}) => {
  useEffect(() => {
    if (value && !options.some((option) => option.value === value.value)) onChange(null);
  }, [value, options]);

  return (
    <StyledFieldWrapper>
      {label && (
        <StyledLabel>
          {label}
          {required ? '*' : ''}
        </StyledLabel>
      )}

      <FieldSingleSelect
        absolute={absolute}
        handleSelectedItemChange={onChange}
        isDisabled={isDisabled}
        isError={meta.touched && meta.error}
        name={name}
        onBlur={onBlur}
        options={options}
        placeholder={placeholder}
        selectedItem={value}
        size={size}
        withClearButton={withClearButton}
      />
      {meta.touched && meta.error && <StyledError>{meta.error}</StyledError>}
    </StyledFieldWrapper>
  );
};

SingleSelectField.defaultProps = {
  options: [],
  required: false,
};

export { SingleSelectField };
