import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import axios, { CancelTokenSource } from 'axios';

import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { SearchSelect } from 'components/Forms/SearchSelect/SearchSelect';
import { SearchSelectListItemStandard } from 'components/Forms/SearchSelect/styles';
import { ContactListItem, StyledCompanySearchWrapper } from './../OrdinaryVisit/styles';
import { getContacts } from 'ducks/powerSearch/contacts/actions';
import { Margin } from 'styles/utils';
import { useScreenSizeContext } from 'contexts/ScreenSizeContext';
import { SearchContactsEntity } from 'ducks/powerSearch/contacts/types';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { ArrowRightBigIcon, PlusIcon } from 'utils/iconsMap';

type Origin = 'company' | 'workplace' | 'visit';

interface ContactsSearchSelectPropTypes {
  companyId?: string | null;
  isDisabled?: boolean;
  newContactHandler?: () => void;
  onSelect: (contact: SearchContactsEntity) => void;
  searchWithin?: Origin[];
  vsize?: 'small' | 'regular';
  workplaceId?: string;
}

export const ContactsSearchSelect = (props: ContactsSearchSelectPropTypes) => {
  const { companyId, isDisabled, newContactHandler, onSelect, searchWithin, vsize, workplaceId } =
    props;
  const [listItems, setListItems] = useState<ReactElement[]>([]);
  const [searchWithinCompany, setSearchWithinCompany] = useState(!!companyId);
  const cancelToken = useRef<CancelTokenSource | null>(null);
  const { sm } = useScreenSizeContext();
  const { t } = useTranslation();

  useEffect(() => {
    setListItems([]);
  }, [companyId, workplaceId, searchWithin]);

  useEffect(() => {
    setSearchWithinCompany(!!companyId);
  }, [companyId]);

  const onSearchHandle = async (text: string) => {
    if (text.length === 0) setListItems([]);
    else debouncedSearchContacts({ name: text, page_size: 500 });
  };

  const debouncedSearchContacts = useCallback(
    debounce(async (params: { name: string; page_size: number }) => {
      cancelToken.current?.cancel();
      const CancelToken = axios.CancelToken;
      cancelToken.current = CancelToken.source();

      try {
        const response = await getContacts(
          {
            ...params,
            company_id: searchWithinCompany ? companyId! : undefined,
            origin: searchWithin,
          },
          cancelToken.current,
        );
        if (response.data?.tableBody?.length > 0) {
          setListItems(
            response.data.tableBody.map((item) => (
              <SearchSelectListItemStandard key={item.id} onClick={() => onSelect(item)}>
                <ContactListItem>
                  <div>{item.name}</div>
                  <div className='extra-info'> {item.role || '-'}</div>
                  <div className='extra-info'> {item.email || '-'}</div>
                  <div className='extra-info'> {item.phone || '-'}</div>
                  <div className='extra-info'> {item.companyName || '-'}</div>
                  <ArrowRightBigIcon />
                </ContactListItem>
              </SearchSelectListItemStandard>
            )),
          );
        } else {
          setListItems([
            <SearchSelectListItemStandard key='no-results'>
              {t('new_visits.no_results_message')}
            </SearchSelectListItemStandard>,
          ]);
        }
      } catch (err) {}
    }, 350),
    [companyId, searchWithin, workplaceId, onSelect, searchWithinCompany],
  );

  return (
    <Margin data-testid='contacts-search-select' top='1.2rem'>
      <StyledCompanySearchWrapper>
        <SearchSelect
          clearOnSelect
          disabled={isDisabled}
          listItems={listItems}
          onSearch={onSearchHandle}
          placeholder={t('new_visits.find_contacts')}
          size='small'
          testid='contacts-search-input'
          vsize={vsize}
        />
        <Checkbox
          isChecked={searchWithinCompany}
          isDisabled={!companyId || isDisabled}
          label={t('new_visits.search_within_owning_company')}
          onClick={() => setSearchWithinCompany(!searchWithinCompany)}
          testid='contacts-search-select-checkbox'
        />
        <DefaultButton
          icon={<PlusIcon />}
          isDisabled={!newContactHandler || isDisabled}
          label={sm ? t('new_visits.add_new_contact') : undefined}
          onClick={newContactHandler}
          size='small'
          testid='contacts-search-select-button'
        />
      </StyledCompanySearchWrapper>
    </Margin>
  );
};
