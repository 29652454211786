import { API } from 'api';
import { AxiosError } from 'axios';
import { ChallengeResponse } from './types';

export async function initiateAuth(loginParams: { username: string; password: string }) {
  try {
    const response = await API.post('auth/sign_in', loginParams);
    return response.data;
  } catch (e) {
    throw (e as AxiosError).message;
  }
}

export async function signOut() {
  try {
    const response = await API.post('auth/sign_out');
    return response.data;
  } catch (e) {
    console.log(e);
    throw (e as AxiosError).message;
  }
}

export async function setPassword(
  challengeName: string,
  newPassword: string,
  session: string,
  cognitoUserId: string,
) {
  try {
    const params = {
      challenge_name: challengeName,
      new_password: newPassword,
      session: session,
      cognito_user_id: cognitoUserId,
    };
    const response = await API.post('auth/new_password', params);
    return response.data;
  } catch (e) {
    console.log(e);
    throw (e as AxiosError).message;
  }
}

export async function finishMfaSetup(code: string, session: string) {
  const params = {
    otp_code: code,
    session: session,
  };
  try {
    const response = await API.post('auth/verify_token', params);
    return response.data;
  } catch (e) {
    console.log(e);
    throw (e as AxiosError).message;
  }
}

export async function verifyMfaCode(code: string, challengeResponse: ChallengeResponse) {
  const { cognitoUserId, session, challengeName } = challengeResponse;
  const params = {
    otp_code: code,
    challenge_name: challengeName,
    session_from_cognito: session,
    cognito_user_id: cognitoUserId,
  };
  try {
    const response = await API.post('auth/verify_mfa_code', params);
    return response.data;
  } catch (e) {
    console.log(e);
    throw (e as AxiosError).message;
  }
}

export async function forgotPassword(username: string) {
  const params = {
    username,
  };
  try {
    const response = await API.post('auth/forgot_password', params);
    return response.data;
  } catch (e) {
    console.log(e);
    throw (e as AxiosError).message;
  }
}

export async function resetPassword(
  email: string,
  new_password: string,
  confirmation_code: string,
) {
  const params = {
    email,
    new_password,
    confirmation_code,
  };
  try {
    const response = await API.post('auth/reset_password', params);
    return response.data;
  } catch (e) {
    console.log(e);
    throw (e as AxiosError).message;
  }
}

export async function resetUser(email: string) {
  const params = { email };
  try {
    const response = await API.post('auth/reset_user', params);
    return response.data;
  } catch (e) {
    console.log(e);
    throw (e as AxiosError).message;
  }
}

export const validatePassword = (password: string) => {
  const uppercase = /[A-Z]/;
  const symbol = /[!~@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  if (password.length < 12) {
    return { error: 'password_length' };
  } else if (!uppercase.test(password)) {
    return { error: 'password_uppercase' };
  } else if (!symbol.test(password)) {
    return { error: 'password_symbol' };
  }

  return { error: null };
};
