import React, { useEffect } from 'react';
import { startOfTomorrow } from 'date-fns';

import 'utils/yupErrorsMessages';

import { Routes } from 'pages/routes';
import { AppProvider } from 'components/AppProvider/AppProvider';
import { Bugsnag } from 'components/Bugsnag/Bugsnag';
import { toast, ToastBar, Toaster } from 'react-hot-toast';

function App({ data = {} }) {
  useEffect(() => {
    const scheduleClear = localStorage.getItem('schedule_clear');
    const tomorrow = startOfTomorrow().toString();
    if (scheduleClear !== tomorrow) {
      const filterKeys = Object.keys(localStorage).filter((key) =>
        /\/redesigned\/companies/.test(key),
      );
      filterKeys.forEach((key) => localStorage.removeItem(key));
    }
    localStorage.setItem('schedule_clear', tomorrow);
  }, []);

  return (
    <AppProvider>
      <Bugsnag>
        <Toaster
          position='top-center'
          reverseOrder={false}
          toastOptions={{
            duration: 5000,
            success: {
              iconTheme: {
                primary: '#fff',
                secondary: '#61d345',
              },
              style: {
                background: '#61d345',
                color: 'white',
              },
            },
            error: {
              duration: 9999999,
              iconTheme: {
                primary: '#fff',
                secondary: '#ff4b4b',
              },
              style: {
                background: '#ff4b4b',
                color: 'white',
              },
            },
          }}
        >
          {(t) => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <button
                  onClick={() => toast.dismiss(t.id)}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {icon}
                  {message}
                </button>
              )}
            </ToastBar>
          )}
        </Toaster>
        <Routes {...data} />
      </Bugsnag>
    </AppProvider>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
