import styled, { css } from 'styled-components/macro';
import { ReactComponent as CloseIcon } from 'images/closeIcon.svg';
import { StandardLabel } from 'components/StandardLabel/StandardLabel';
import { Tooltip } from 'components/Tooltip/Tooltip';

export const StyledDropzoneWrapper = styled.div<{ isActive?: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black50};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: 1px dashed ${({ theme }) => theme.colors.black100};
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.sizes.sm};
  text-align: center;
  transition: background-color 0.3s, border 0.3s;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue50};
    border: 0.1rem dashed ${({ theme }) => theme.colors.blue100};
  }
`;

export const StyledBrowseFiles = styled.span`
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: 1px solid ${({ theme }) => theme.colors.black100};
  display: inline-flex;
  font-weight: 600;
  margin-left: ${({ theme }) => theme.sizes.sm};
  padding: ${({ theme }) => theme.sizes.hmd};

  & svg {
    margin-right: ${({ theme }) => theme.sizes.hmd};
  }
`;

export const StyledFilesListItem = styled.li`
  color: ${({ theme }) => theme.colors.black500};
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => `0 0 ${theme.sizes.hmd} ${theme.sizes.md}`};
`;

export const StyledFileName = styled.span<{ isRejected?: boolean }>`
  color: ${({ theme }) => theme.colors.black500};
  display: inline-block;
  overflow: hidden;
  padding-right: ${({ theme }) => theme.sizes.hmd};
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 30rem;

  ${({ isRejected }) =>
    isRejected &&
    css`
      text-decoration: line-through;
    `}
`;

export const StyledFileSize = styled.span`
  color: ${({ theme }) => theme.colors.black200};
`;

export const StyledDeleteIcon = styled(CloseIcon)`
  ${({ theme }) => theme.icon.sm};
  color: ${({ theme }) => theme.colors.red100};
  cursor: pointer;
`;

export const StyledFilesHeader = styled(StandardLabel)`
  display: block;
  margin: ${({ theme }) => theme.sizes.hmd};
`;

export const StyledThumbnailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledThumbnail = styled.div`
  background-position: center;
  background-size: cover;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  height: calc(${({ theme }) => theme.sizes.md} * 5);
  margin: ${({ theme }) => `${theme.sizes.xs} 0 ${theme.sizes.xs} ${theme.sizes.sm}`};
  position: relative;
  width: calc(${({ theme }) => theme.sizes.md} * 5);
`;

const thumbnailCrossPosition = css`
  ${({ theme }) => theme.icon.lg};
  background-color: ${({ theme }) => theme.colors.black50};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  border: ${({ theme }) => theme.sizes.xxs} solid white;
  color: ${({ theme }) => theme.colors.black300};
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(${({ theme }) => `${theme.sizes.hmd}, -${theme.sizes.xs}`});
`;

export const StyledThumbnailCross = styled(CloseIcon)`
  ${thumbnailCrossPosition}
`;

export const StyledThumbnailTooltipWithCross = styled(Tooltip)`
  ${thumbnailCrossPosition}
`;

export const StyledVideoContainer = styled.div`
  height: calc(${({ theme }) => theme.sizes.md} * 5);
  margin: ${({ theme }) => `${theme.sizes.xs} ${theme.sizes.sm} ${theme.sizes.xs} 0`};
  position: relative;
  width: calc(${({ theme }) => theme.sizes.md} * 5);
`;

export const StyledVideoPlayer = styled.video`
  border-radius: ${({ theme }) => theme.borderRadius.md};
  height: calc(${({ theme }) => theme.sizes.md} * 5);
  object-fit: cover;
  width: calc(${({ theme }) => theme.sizes.md} * 5);
`;

export const StyledPlayButtonContainer = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
