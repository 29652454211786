/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
// @ts-nocheck

import React, {
  ComponentType,
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { debounce } from 'lodash';
import { usePopper } from 'react-popper';
import { StyledCompanySearchWrapper } from 'containers/newVisit/OrdinaryVisit/styles';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { TextInput } from 'components/TextInput/TextInput';
import { useOnClickOutside } from 'utils/hooks';
import { SearchSelectList } from './styles';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { popperSameMinWidthModifier } from 'utils';

import { ReactComponent as SearchIcon } from 'images/searchIcon.svg';

interface SearchSelectWithCheckboxPropTypes {
  autoFocus?: boolean;
  clearOnSelect?: boolean;
  closeOnSelect?: boolean;
  disabled?: boolean;
  isChecked: boolean;
  label: string;
  listItems: ReactElement<{ selected?: boolean }>[];
  onChange?: (val: string) => void;
  onSearch: (text: string) => void;
  placeholder?: string;
  setSearchInactive: (val: boolean) => void;
  size?: 'small' | 'regular';
  testid?: string;
  value?: string;
  vsize?: 'small' | 'regular';
}

const ROOT_ELEMENT = document.querySelector('body');

const SearchSelectWithCheckbox: ComponentType<SearchSelectWithCheckboxPropTypes> = ({
  autoFocus = false,
  clearOnSelect = false,
  closeOnSelect = false,
  disabled,
  isChecked = false,
  label,
  setSearchInactive,
  listItems,
  onChange: changeHandle,
  onSearch,
  placeholder,
  size = 'regular',
  testid = 'search-select-component',
  value: controlledValue,
  vsize = 'regular',
}: SearchSelectWithCheckboxPropTypes) => {
  const iconSize = size === 'small' ? 20 : 24;
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [textSearch, setTextSearch] = useState(controlledValue || '');
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'flip',
        options: { allowedAutoPlacements: ['top-start', 'bottom-start'] },
      },
      popperSameMinWidthModifier,
    ],
  });
  const [listVisible, setVisibility] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const listItemsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useOnClickOutside(listItemsRef, (event) => {
    if (!inputRef.current.contains(event.target)) setVisibility(false);
  });

  const onListClick = React.useCallback(() => {
    closeOnSelect && setVisibility(false);
    if (clearOnSelect) {
      setVisibility(false);
      inputRef!.current!.value = '';
      changeHandle && changeHandle('');
    }
  }, [clearOnSelect, closeOnSelect, changeHandle, setVisibility]);

  const debouncedSearch = useCallback(
    debounce(async (text: string) => {
      setLoading(true);
      onSearch(text);
    }, 300),
    [onSearch, isChecked],
  );

  useEffect(() => {
    setLoading(false);
  }, [listItems]);

  useEffect(() => {
    debouncedSearch(textSearch, isChecked);
  }, [isChecked, textSearch]);

  return (
    <StyledCompanySearchWrapper data-testid={testid} ref={inputRef}>
      <div ref={setReferenceElement}>
        <TextInput
          autoFocus={autoFocus}
          inputProps={{
            'data-testid': 'search-select-input',
            onFocus: () => {
              setVisibility(true);
            },
            onChange: ({ currentTarget: { value } }) => {
              setTextSearch(value);
              changeHandle && changeHandle(value);
              debouncedSearch(value);
            },
            value: controlledValue || controlledValue === '' ? controlledValue : undefined,
          }}
          isDisabled={disabled}
          placeholder={placeholder}
          postfixElement={isLoading ? <LoadingSpinner small /> : undefined}
          prefixElement={<SearchIcon height={iconSize} width={iconSize} />}
          size='fullWidth'
          vsize={vsize}
        />

        {listVisible &&
          listItems?.length > 0 &&
          createPortal(
            <div ref={listItemsRef}>
              <SearchSelectList
                onClick={onListClick}
                ref={setPopperElement}
                style={{ ...styles.popper }}
                {...attributes.popper}
              >
                {listItems.map((listItem, index) => (
                  <Fragment key={index}>{listItem}</Fragment>
                ))}
              </SearchSelectList>
            </div>,
            ROOT_ELEMENT,
          )}
      </div>
      <Checkbox
        isChecked={isChecked}
        label={label}
        onClick={() => {
          setSearchInactive((x) => !x);
        }}
      />
    </StyledCompanySearchWrapper>
  );
};

export { SearchSelectWithCheckbox };
