import styled from 'styled-components/macro';

import { StyledContainer } from 'containers/companyDetails/Visits/styles';
import { StyledColumn } from 'containers/companyDetails/BasicData/styles';

export const HeaderText = styled.h1`
  font-weight: 100;
`;

export const StyledNewVisitCompanyPreview = styled.div`
  ${StyledContainer} {
    flex-direction: column;
  }

  ${StyledColumn} {
    padding: 0;
  }

  button {
    display: none;
  }
`;

export const WorkplaceListItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;

  & > svg {
    flex: 0 0 auto;
  }
`;
export const ContactListItem = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 20% 1fr 1fr 1fr 1fr 24px;
  column-gap: 10px;
  text-align: left;
  font-weight: 600;
  .extra-info {
    font-weight: 400;
    font-size: 12px;
    min-width: fit-content;
  }
  & > svg {
    flex: 0 0 auto;
  }
  @media (max-width: 550px) {
    font-size: 10px;
    grid-template-columns: 20% 1fr 1fr 1fr 1fr 16px;
    .extra-info {
      font-size: 8px;
    }
    & > svg {
      width: 16px;
      align-self: right;
    }
  }
`;

export const InfoboxContent = styled.div`
  ${({ theme }) => theme.fontSizes.xl};
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const StyledWorkplaceOptions = styled.div`
  margin: 0 auto;
  max-width: 72rem;

  & * {
    flex: 1 1 auto;
    justify-content: center;
  }
`;

export const StyledCompanySearchWrapper = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  width: 100%;

  & > * {
    flex: 1 0 100%;
  }

  & > :nth-child(1) {
    flex: 1 1 auto;
  }

  & > :nth-child(2) {
    flex: 0 0 auto;
    margin-right: ${({ theme }) => theme.sizes.sm};
    margin-left: ${({ theme }) => theme.sizes.sm};
  }

  & > button {
    flex: 0 1 auto;
  }
`;
export const StyledOwnerSearchWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;

  & > * {
    flex: 1 0;
    margin-bottom: auto;
  }

  & > #searchOwner {
    flex: 1 1 auto;
    margin-right: 1.2rem;
    margin-bottom: 0px;
  }
`;

export const StyledError = styled.span`
  color: ${({ theme }) => theme.colors.red500};
  margin: 0 ${({ theme }) => theme.sizes.xs};
  font-weight: 600;
`;

export const NewVisitCard = styled.div`
  background-color: white;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: ${({ theme }) => theme.boxShadows.blue500};
  margin: ${({ theme }) => theme.sizes.xs} auto;
  max-width: 144rem;
  overflow-y: auto;
  padding: ${({ theme }) => `${theme.sizes.hmd} ${theme.sizes.md}`};
`;

export const StyledWorkplaceSaveCancelButtons = styled.div`
  display: flex;

  & > *:not(:first-child) {
    margin-left: ${({ theme }) => theme.sizes.hmd};
  }
`;

export const StyledWrappingContainer = styled.div<{ limitHeight: boolean }>`
  padding-bottom: ${(props) => (props.limitHeight ? '58px' : '0px')};
`;

export const LoadingWrapper = styled.div`
  margin-top: 100px;
  display: flex;
  justify-content: center;
`;
