import styled from 'styled-components/macro';

import { ReactComponent as Symbol } from 'images/symbol.svg';

export const StyledSearchWrap = styled.div`
  margin: 0 ${({ theme }) => theme.sizes.lg};
  width: 100%;

  @media (max-width: 768px) {
    margin: 0 ${({ theme }) => theme.sizes.sm};
  }
`;

export const StyledTopBar = styled.nav`
  align-items: center;
  background-color: ${window.REACT_APP_ENV.BUGSNAG_RELEASE_STAGE === 'staging'
    ? '#64B6AC'
    : 'white'};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.sizes.md};
  width: 100%;

  & > *:not(:last-child):not(${StyledSearchWrap}) {
    margin-right: ${({ theme }) => theme.sizes.sm};
  }

  @media (max-width: 768px) {
    padding: 0 ${({ theme }) => theme.sizes.xs};

    & > *:not(:last-child):not(${StyledSearchWrap}) {
      margin-right: ${({ theme }) => theme.sizes.xs};
    }
  }
`;

export const StyledAnchor = styled.a`
  color: ${({ theme }) => theme.colors.black700};
  text-decoration: none;
`;

export const StyledSymbol = styled(Symbol)`
  ${({ theme }) => theme.icon.xl};
`;
