import React, { FC, InputHTMLAttributes } from 'react';

import {
  StyledBox,
  StyledCheckbox,
  StyledCheckboxAdditionalLabel,
  StyledCheckboxLabel,
  StyledCheckboxMainLabel,
} from './styles';
import { CheckIcon, MinusIcon } from 'utils/iconsMap';

export type CheckboxSizes = 'small' | 'medium' | 'large';

interface CheckboxPropsType {
  additionalLabel?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  isChecked?: boolean;
  isDisabled?: boolean;
  isIndeterminate?: boolean;
  label?: string;
  labelColor?: string;
  onClick: (e?: React.MouseEvent | React.ChangeEvent) => void;
  size?: CheckboxSizes;
  testid?: string;
}

export const Checkbox: FC<CheckboxPropsType> = ({
  additionalLabel,
  inputProps,
  isChecked,
  isDisabled,
  isIndeterminate,
  label,
  labelColor,
  onClick,
  size = 'medium',
  testid = 'checkbox-component',
}) => {
  const RenderedIcon = () => {
    if (isChecked) {
      return <CheckIcon />;
    }
    if (isIndeterminate) {
      return <MinusIcon />;
    }
    return null;
  };

  return (
    <StyledCheckbox data-testid={testid}>
      <input
        checked={isChecked}
        data-testid='Checkbox-input'
        disabled={isDisabled}
        name='checkbox'
        onChange={onClick}
        type='checkbox'
        {...inputProps}
      />

      <StyledBox
        data-testid='Checkbox'
        isIndeterminate={isIndeterminate}
        onClick={onClick}
        size={size}
      >
        <RenderedIcon />
      </StyledBox>
      {label && (
        <StyledCheckboxLabel color={labelColor} htmlFor='checkbox' onClick={onClick}>
          <StyledCheckboxMainLabel>{label}</StyledCheckboxMainLabel>
          {additionalLabel && (
            <StyledCheckboxAdditionalLabel>{additionalLabel}</StyledCheckboxAdditionalLabel>
          )}
        </StyledCheckboxLabel>
      )}
    </StyledCheckbox>
  );
};

Checkbox.defaultProps = {
  isChecked: false,
  isDisabled: false,
  isIndeterminate: false,
  label: '',
};
