import { createSelector } from '@reduxjs/toolkit';

import { currentUserSelector } from '../users/selectors';
import { District } from './types';
import { StoreType } from 'store/index';

export const chosenDistrictSelector = (state: StoreType) => state.powerSearch.district;
export const allDistrictsSelector = (state: StoreType) => state.powerSearch.allDistricts.data;
export const allDistrictsStatusSelector = (state: StoreType) =>
  state.powerSearch.allDistricts.status;
export const allFilteredDistrictsSelector = createSelector(
  [allDistrictsSelector, currentUserSelector],
  (allDistricts, currentUser) =>
    allDistricts
      .filter((x) => x.industryId === currentUser?.industryId)
      .map((x: District) => ({ label: x.name, value: x.name })),
);
