import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useScreenSizeContext } from 'contexts/ScreenSizeContext';
import { BreadcrumbContainer, BreadcrumbNumber, BreadcrumbStep, BreadcrumbText } from './styles';
import { newVisitCompanyVisitsSelector } from 'ducks/newVisit';
import { currentUserSelector } from 'ducks/users/selectors';

export const Breadcrumb: React.FC<{
  activeStep: string;
  handleClick: Function;
  translate?: boolean;
  additionalDisabled?: string[];
  steps: string[];
}> = ({ activeStep, handleClick, steps, translate = false, additionalDisabled = [] }) => {
  const { t } = useTranslation();
  const { md } = useScreenSizeContext();
  const companyVisits = useSelector(newVisitCompanyVisitsSelector);
  const user = useSelector(currentUserSelector);
  const userCanGoBackToWorkplace = React.useMemo(
    () => activeStep === 'companies' && companyVisits[0]?.industryId === user?.industryId,
    [activeStep, companyVisits, user],
  );

  return (
    <BreadcrumbContainer>
      {steps.map((step, index) => (
        <BreadcrumbStep
          active={
            steps.indexOf(activeStep) >= index &&
            !additionalDisabled.includes(step) &&
            !(step === 'workplace' && !userCanGoBackToWorkplace)
          }
          disabled={
            steps.indexOf(activeStep) < index || (step === 'workplace' && !userCanGoBackToWorkplace)
          }
          key={step}
          onClick={() => activeStep !== step && handleClick(step)}
        >
          <BreadcrumbNumber>{index + 1}</BreadcrumbNumber>
          {md && <BreadcrumbText>{translate ? t(`breadcrumb.${step}`) : step}</BreadcrumbText>}
        </BreadcrumbStep>
      ))}
    </BreadcrumbContainer>
  );
};
