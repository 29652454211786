import { cells } from 'utils/cells';
import { ComponentsMapType } from 'types';
import { DashboardVisitColumnsName, DashboardVisitEntity } from 'ducks/dashboard/types';

export const visitsComponentsMap: ComponentsMapType<
  DashboardVisitColumnsName,
  DashboardVisitEntity
> = {
  address: cells.address,
  comment: cells.comment,
  company_name: cells.company_name,
  company_aka: cells.company_aka,
  date: cells.date,
  deadline: cells.deadline,
  deadlines_fulfilled: cells.deadlines_fulfilled,
  district_names: cells.district_names,
  industry: cells.industry,
  issues: cells.issues,
  municipality: cells.municipality,
  number_of_employees: cells.number_of_employees,
  rvo: cells.rvo,
  status: cells.status,
  stops: cells.stops,
  guidances: cells.guidances,
  oks: cells.oks,
  tips_id_present: cells.tips_external_id,
  tips_external_id: cells.tips_external_id,
  type: cells.type,
  type_of_visit: cells.type_of_visit,
  visit_report: cells.visit_report,
  visited_as: cells.visited_as,
  workplace: cells.workplace,
  postal_code: cells.postal_code,
};

export type VisitsComponentsMap = typeof visitsComponentsMap;
