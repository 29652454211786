import React from 'react';
import { FieldRenderProps } from 'react-final-form';

import {
  DatepickerInput,
  DatepickerInputPropTypes,
} from 'components/Forms/DatepickerInput/DatepickerInput';

type DatepickerFieldPropTypes = Omit<
  DatepickerInputPropTypes,
  keyof FieldRenderProps<string>['input']
> &
  FieldRenderProps<string>;

export const DatepickerField = (props: DatepickerFieldPropTypes) => {
  const { input } = props;
  return <DatepickerInput {...props} {...input} />;
};
