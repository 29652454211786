import React from 'react';
import { navigate } from '@reach/router';
import { SearchWorkplacesEntity } from 'ducks/powerSearch/workplace/types';
import { StyledNoWrapCellContent, StyledTruncatedContent } from 'components/Table/TableRow/styles';
import { StyledLink } from 'containers/companyDetails/BasicData/styles';
import { formatDate } from 'utils/date';
import { Tooltip } from 'components/Tooltip/Tooltip';

export const workplaceTableMapper = {
  name: {
    component: ({ name, workplaceId }: SearchWorkplacesEntity) => (
      <Tooltip label={name && name.length > 24 ? name : ''}>
        <StyledTruncatedContent>
          <StyledLink
            as='button'
            onClick={() =>
              navigate(`/redesigned/workplaces/${workplaceId}/basic-data`, {
                state: { ...window.history.state, previousPathname: window.location.pathname },
              })
            }
          >
            {name}
          </StyledLink>
        </StyledTruncatedContent>
      </Tooltip>
    ),
    size: 'minmax(24rem, max-content)',
  },
  created_by: {
    component: ({ createdBy }: SearchWorkplacesEntity) => (
      <StyledNoWrapCellContent>{createdBy || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  created_at: {
    component: ({ createdAt }: SearchWorkplacesEntity) => (
      <StyledNoWrapCellContent>{formatDate(createdAt)}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  municipality_name: {
    component: ({ municipalityName }: SearchWorkplacesEntity) => (
      <StyledNoWrapCellContent>{municipalityName || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  address: {
    component: ({ address }: SearchWorkplacesEntity) => (
      <StyledNoWrapCellContent>{address || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  kind: {
    component: ({ kind }: SearchWorkplacesEntity) => (
      <StyledNoWrapCellContent>{kind || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  postal_area_code: {
    component: ({ postalAreaCode }: SearchWorkplacesEntity) => (
      <StyledNoWrapCellContent>{postalAreaCode || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  postal_area_name: {
    component: ({ postalAreaName }: SearchWorkplacesEntity) => (
      <StyledNoWrapCellContent>{postalAreaName || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  comment: {
    component: ({ comment }: SearchWorkplacesEntity) => (
      <StyledNoWrapCellContent>{comment || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  proprietorship_name: {
    component: ({ proprietorshipName }: SearchWorkplacesEntity) => (
      <StyledNoWrapCellContent>{proprietorshipName || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  last_visited_date: {
    component: ({ lastVisitedDate }: SearchWorkplacesEntity) => {
      return (
        <StyledNoWrapCellContent>
          {lastVisitedDate ? formatDate(lastVisitedDate) : '-'}
        </StyledNoWrapCellContent>
      );
    },
    size: 'min-content',
  },
  termination: {
    component: ({ termination }: SearchWorkplacesEntity) => {
      if (!termination) return <StyledNoWrapCellContent>-</StyledNoWrapCellContent>;

      const [day, month, year] = termination.split('.');
      const standarizedDate = `${year}-${month}-${day}`;
      return <StyledNoWrapCellContent>{formatDate(standarizedDate)}</StyledNoWrapCellContent>;
    },
    size: 'min-content',
  },
  district_names: {
    component: ({ districtNames }: SearchWorkplacesEntity) =>
      districtNames?.length > 1 ? (
        <Tooltip label={districtNames.join(', ')}>
          <StyledNoWrapCellContent>
            {districtNames[0]} + {districtNames.length - 1}
          </StyledNoWrapCellContent>
        </Tooltip>
      ) : (
        <StyledNoWrapCellContent>
          {!districtNames || districtNames.length === 0 ? '-' : districtNames[0]}
        </StyledNoWrapCellContent>
      ),
    size: 'max-content',
  },
};

export type WorkplaceTableMapper = typeof workplaceTableMapper;
