import React from 'react';
import {
  CompanyVisitResponse,
  getVisitById,
  NewVisitContact,
  NewVisitResponse,
  resetNewVisit,
} from 'ducks/newVisit';
import { CopyVisitsModal } from 'dialogs/Dashboard/CopyVisitsModal';
import { useAppDispatch } from 'store';
import { unwrapResult } from '@reduxjs/toolkit';

import { fetchCompany } from 'ducks/companies/actions';
import { CompanyEntity } from 'ducks/companies/types';

interface ChooseCompaniesVisitProps {
  callback?: (id: string | number) => void | Promise<unknown>;
  visitId: string | number;
  originalVisitId: string;
}

export interface fetchedCompanyVisit extends CompanyVisitResponse {
  checked: boolean;
  contacts: fetchedContacts[];
}

interface fetchedContacts extends NewVisitContact {
  checked?: boolean;
}

export const ChooseCompaniesVisit = (props: ChooseCompaniesVisitProps): JSX.Element => {
  const { originalVisitId, visitId } = props;
  const [initialValues, setInitialValues] = React.useState<fetchedCompanyVisit[]>([]);
  const [selectedCompany, setSelectedCompany] = React.useState<CompanyEntity | null>(null);
  const [visit, setVisit] = React.useState<NewVisitResponse>();
  const [loading, setLoading] = React.useState(false);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await dispatch(getVisitById({ id: originalVisitId! }))
        .then(unwrapResult)
        .then(async (fetchedVisit: NewVisitResponse) => {
          setVisit(fetchedVisit);
          const fetchedInitialValues: fetchedCompanyVisit[] = fetchedVisit.companyVisits.map(
            (companyVisit: CompanyVisitResponse) => {
              const companyVisitUpdated: fetchedCompanyVisit = {
                ...companyVisit,
                checked: true,
              };
              companyVisitUpdated.contacts = companyVisitUpdated.contacts.map(
                (contact: fetchedContacts) => {
                  const contactUpdated = { ...contact };
                  contactUpdated.checked = true;
                  return contactUpdated;
                },
              );
              return companyVisitUpdated;
            },
          );
          setInitialValues(fetchedInitialValues);
          const fetchedCompany: CompanyEntity = await fetchCompany(
            fetchedVisit.companyVisits[0]?.companyId,
          );
          setSelectedCompany(fetchedCompany);
          return fetchedCompany;
        });
    };
    fetch();
    return () => {
      dispatch(resetNewVisit());
    };
  }, [visitId]);

  return (
    <CopyVisitsModal
      initialValues={initialValues}
      loading={loading}
      originalVisitId={originalVisitId}
      selectedCompany={selectedCompany}
      setLoading={setLoading}
      visit={visit}
      visitId={visitId}
    />
  );
};
