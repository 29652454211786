import React, { ReactElement, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useTranslation } from 'react-i18next';

import { SimpleTableGrid } from 'components/Table/TableGrid/SimpleTableGrid';
import { columns, componentsMap, fetchMailLogs } from 'containers/MailLog/utils';
import { StyledLayout } from 'containers/styles';
import { StyledTabContainer } from 'containers/powersearch/Page/styles';
import { IMailLog } from 'containers/MailLog/types';
import { SpinnerIcon } from 'utils/iconsMap';
import { StyledContainer } from 'containers/companyDetails/Visits/styles';
import { Card } from 'components/Card/Card';
import { Margin } from 'styles/utils';

export const MailLog = (props: RouteComponentProps): ReactElement => {
  const { t } = useTranslation();
  const [logs, setLogs] = useState<IMailLog[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      const response = await fetchMailLogs();
      setLogs(response);
      setLoading(false);
    };
    fetch();
  }, []);

  return (
    <StyledTabContainer>
      <StyledLayout>
        <StyledContainer>
          <Card>
            <h1>{t('top_bar.menu_drawer.mail_log')}</h1>

            {loading ? (
              <Margin margin='0 auto'>
                <SpinnerIcon />
              </Margin>
            ) : (
              <SimpleTableGrid
                bodyData={logs}
                columnsOrder={columns}
                componentsMap={componentsMap}
                noResultsMessage={t('mailgun.no_results')}
              />
            )}
          </Card>
        </StyledContainer>
      </StyledLayout>
    </StyledTabContainer>
  );
};
