import styled from 'styled-components/macro';

export const StyledCheckboxLabel = styled.label`
  color: ${({ theme }) => theme.colors.black700};
  display: inline-block;
  margin-left: 1.2rem;
`;

export const StyledCheckboxWrapper = styled.div`
  align-items: center;
  display: flex;
`;
