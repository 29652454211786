import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatMonth, formatMonthYear, formatQueryDate, formatWeekdayShort } from 'utils/date';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import {
  StyledButtonContainer,
  StyledCalendar,
  StyledCalendarWrapper,
} from 'components/DatePicker/styles';
import { ArrowLeftBigIcon, ArrowRightBigIcon } from 'utils/iconsMap';

export const DatePicker = (props) => {
  const { t } = useTranslation();
  const { cancelHandler, onChange, value } = props;
  const [date, setDate] = useState(value ? new Date(value) : undefined);

  return (
    <StyledCalendarWrapper>
      <StyledCalendar
        formatMonth={(locale, day) => formatMonth(day)}
        formatMonthYear={(locale, day) => formatMonthYear(day)}
        formatShortWeekday={(locale, day) => formatWeekdayShort(day)}
        minDetail='decade'
        next2Label={null}
        nextLabel={<ArrowRightBigIcon />}
        onChange={setDate}
        prev2Label={null}
        prevLabel={<ArrowLeftBigIcon />}
        showNeighboringMonth={false}
        value={date}
      />

      <StyledButtonContainer>
        <DefaultButton
          capitalize
          label={t('common.cancel')}
          onClick={cancelHandler}
          variant='flat'
        />
        <DefaultButton
          capitalize
          label={t('common.today')}
          onClick={() => onChange(formatQueryDate(new Date()))}
          variant='secondary'
        />
        <DefaultButton
          capitalize
          label={t('common.save')}
          onClick={() => onChange(date ? formatQueryDate(date) : date)}
        />
      </StyledButtonContainer>
    </StyledCalendarWrapper>
  );
};

DatePicker.defaultProps = {
  activeStartDate: undefined,
  value: undefined,
};
