import React, { FC } from 'react';
import { RadioButtonContainer, RadioButtonProps, StyledRadioButtonCircle } from './styles';

export interface RadioButtonWithLabelProps extends RadioButtonProps {
  onClick?: () => void;
}

export const RadioButtonWithLabel: FC<RadioButtonWithLabelProps> = ({
  children,
  isDisabled,
  isSelected,
  onClick,
}) => (
  <RadioButtonContainer
    className={isSelected ? 'radio-button-checked' : 'radio-button-not-checked'}
    isDisabled={isDisabled}
    isSelected={isSelected}
    onClick={isDisabled ? undefined : onClick}
  >
    <input type='radio' />
    <StyledRadioButtonCircle />
    <p>{children}</p>
  </RadioButtonContainer>
);
