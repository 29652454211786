import { StyledCheckbox } from 'components/Checkbox/styles';
import { StyledDropdownWrapper } from 'components/PopperDropdown/styles';
import styled from 'styled-components/macro';

export const StyledWorkplaceFormRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;

    & > :not(:first-child) {
      margin-left: 1.6rem;
    }
  }
`;

export const StyledWorkplaceFormButtonsRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;

    & > :not(:first-child) {
      margin-left: 1.6rem;
    }
  }
`;

export const StyledWorkplaceKindRow = styled(StyledWorkplaceFormRow)`
  @media (min-width: 768px) {
    & > * {
      flex: 1 1 30rem;
    }
  }
`;

export const StyledWorkplaceStreetRow = styled(StyledWorkplaceFormRow)`
  @media (min-width: 768px) {
    & > :first-child {
      flex: 5 1 auto;
    }

    & > :nth-child(2) {
      flex: 0 1 6rem;
    }

    & > :nth-child(3) {
      flex: 0 0 7rem;
    }

    & > :nth-child(4) {
      flex: 1 1 auto;
    }
  }
`;

export const StyledWorkplaceOwnerRow = styled(StyledWorkplaceFormRow)`
  display: flex;
  flex-direction: column;

  ${StyledCheckbox} {
    height: 3rem;
  }

  & > * {
    margin-bottom: 2.4rem;
  }

  @media (min-width: 768px) {
    align-items: flex-start;
    justify-content: start;
    flex-direction: row;

    & > :first-child {
      margin-right: 1.2rem;
    }

    & > #searchOwner {
      flex: 1 1 auto;
      margin-right: 1.2rem;
    }

    & > :last-child {
      margin-left: auto;
    }
  }
`;

export const StyledWorkplaceTerminationRow = styled(StyledWorkplaceFormRow)`
  ${StyledDropdownWrapper} {
    margin-right: 0;
  }

  & > * {
    flex: 1 1 50%;
  }

  button {
    width: 100%;
  }

  @media (min-width: 768px) {
    & > :first-child {
      flex: 1 1 auto;
    }
  }
`;
