import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

import { allDistrictsStatusSelector } from 'ducks/powerSearch/selectors';
import { checkIsIpad } from 'utils/deviceChecker';
import { currentUserSelector } from 'ducks/users/selectors';
import { getCurrentUser } from 'ducks/users/actions';
import { getDistricts } from 'ducks/powerSearch/actions';
import { Navbar } from 'components/Navbar/Navbar';
import { StyledPage } from './styles';

export const PageWithNavbar: FC<RouteComponentProps> = ({ children }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);
  const districtsStatus = useSelector(allDistrictsStatusSelector);

  const fetchAll = async () => {
    await Promise.all([dispatch(getDistricts()), dispatch(getCurrentUser())]);
  };

  useEffect(() => {
    fetchAll();
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (!currentUser || ['pending', 'idle'].includes(districtsStatus)) return null;

  const isIpad = checkIsIpad();

  return (
    <StyledPage isIpad={isIpad}>
      <Navbar />
      {children}
    </StyledPage>
  );
};
