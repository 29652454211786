import { createGlobalStyle } from 'styled-components/macro';

export const GlobalStyle = createGlobalStyle`

/* Do not set @font-face rules here. See: https://github.com/styled-components/styled-components/issues/1593 for details */

 html {
  /* 1rem = 10px */
  font-size: 62.5%;
}
body {
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.black900};
}

* {
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
    outline: 0;
  }

}

[role="button"],
button,
label {
    cursor: pointer;
}

button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	outline: inherit;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

.ReactModal__Body--open {
  overflow: hidden;
}
.ReactModalPortal {
  left: 0;
  max-height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  z-index: 1000;
}
.ql-toolbar.ql-snow { // richText
  background-color: #eee;
}
.ql-editor {
  background-color: #fff;
}
`;
