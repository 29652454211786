import styled from 'styled-components/macro';

import { TabContainer } from 'components/Tabs/styles';
import { StyledWrapper } from 'components/TextInput/styles';

import { ReactComponent as SearchIcon } from 'images/searchIcon.svg';

export const StyledContextBar = styled.div`
  background-color: white;
  box-shadow: ${({ theme }) => theme.boxShadows.coolGrey200};
  margin-top: calc(${({ theme }) => theme.sizes.md} * 2.5);
  width: 100%;
  z-index: 50;
`;

export const StyledLayout = styled.div`
  margin: 0 auto;
  max-width: 100%;
  padding: ${({ theme }) => `${theme.sizes.xs} ${theme.sizes.md} 0`};
`;

export const StyledDetails = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.sizes.xs};

  & > span {
    margin-right: ${({ theme }) => theme.sizes.sm};
  }

  h1 {
    ${({ theme }) => theme.fontSizes.xxl};
    margin: ${({ theme }) => theme.sizes.xs};
    margin-left: 0;
  }
`;

export const StyledSecondaryText = styled.span`
  color: ${({ theme }) => theme.colors.black500};
`;

export const StyledTabsFieldWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;

  ${TabContainer} {
    flex: 0 1 70rem;
    width: 100%;
  }

  ${StyledWrapper} {
    flex: 0 1 70rem;
    width: auto;
  }

  @media (min-width: 1366px) {
    flex-wrap: nowrap;

    ${TabContainer} {
      flex: 1 1 65rem;
    }

    ${StyledWrapper} {
      flex: 1 1 65rem;
      margin-left: ${({ theme }) => theme.sizes.sm};
    }
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  ${({ theme }) => theme.icon.md};
`;
