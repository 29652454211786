import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldSingleSelect } from 'components/FieldSingleSelect/FieldSingleSelect';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { TextInput } from 'components/TextInput/TextInput';
import { usePaginationContext } from 'contexts/PaginationContext';
import { useLoadingContext } from 'contexts/LoadingContext';
import { PAGE_SIZES } from 'consts/components/Pagination';

import { ReactComponent as ArrowLeftBigIcon } from 'images/arrowLeftBigIcon.svg';
import { ReactComponent as ArrowRightBigIcon } from 'images/arrowRightBigIcon.svg';

import { StyledNav, StyledSpan, StyledText, StyledWrap } from './styles';

const Pagination = ({ currentPage, disabled, pages, pageSizes, rowsPerPage, setParams }) => {
  const [pageInputValue, setPageInputValue] = useState(String(currentPage));
  const inputSize = String(pages).length || 1;

  const { t } = useTranslation();

  const options = useMemo(
    () => pageSizes.map((page) => ({ label: page, value: page })),
    [pageSizes],
  );

  useEffect(() => {
    setPageInputValue(String(currentPage));
  }, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const inputOnBlur = useCallback(() => {
    const numberValue = Math.floor(Number(pageInputValue));
    if (numberValue && numberValue <= pages && numberValue > 0) {
      setParams({ currentPage: Number(pageInputValue) });
    } else {
      setPageInputValue(currentPage);
    }
  }, [currentPage, pageInputValue, pages, setParams]);

  const inputOnChange = useCallback(({ target: { value } }) => {
    if (!/\D/g.test(value)) {
      setPageInputValue(String(value));
    }
  }, []);

  if ((!pages || pages === 1) && rowsPerPage === pageSizes[0]) return null;

  if (Number(pages) <= 5) {
    const buttonLabels = [];
    for (let i = 1; i <= Number(pages); i += 1) {
      buttonLabels.push(String(i));
    }

    return (
      <StyledNav>
        {buttonLabels.map((label) => (
          <DefaultButton
            isActive={label === String(currentPage)}
            isDisabled={disabled}
            key={label}
            label={label}
            onClick={() => setParams({ currentPage: Number(label) })}
            testid={`pagination-${label}`}
            variant='tertiary'
          />
        ))}
        <StyledWrap>
          <StyledText>{`${t('common.results_on_page')}:`}</StyledText>
        </StyledWrap>
        <FieldSingleSelect
          handleSelectedItemChange={({ value }) =>
            setParams({ rowsPerPage: value, currentPage: 1 })
          }
          isDisabled={disabled}
          name='pagination'
          options={options}
          popperPlacement='top-start'
          selectedItem={{ label: rowsPerPage, value: rowsPerPage }}
        />
      </StyledNav>
    );
  }

  return (
    <StyledNav data-testid='pagination'>
      <DefaultButton
        icon={<ArrowLeftBigIcon />}
        isDisabled={Number(currentPage) === 1 || disabled}
        onClick={() => setParams({ currentPage: Number(currentPage) - 1 })}
        testid='pagination-previous-page'
        variant='tertiary'
      />
      <TextInput
        inputProps={{
          value: pageInputValue,
          'data-testid': 'pagination-page-input',
          onChange: inputOnChange,
          onBlur: inputOnBlur,
          onKeyPress: (event) => {
            if (event.key === 'Enter') {
              event.target.blur();
            }
          },
          size: inputSize,
          maxLength: inputSize,
        }}
        isDisabled={disabled}
        postfixElement={<StyledSpan>{`/${pages}`}</StyledSpan>}
        size='auto'
        textAlign='center'
      />
      <DefaultButton
        icon={<ArrowRightBigIcon />}
        isDisabled={pages === Number(currentPage) || disabled}
        onClick={() => setParams({ currentPage: Number(currentPage) + 1 })}
        testid='pagination-next-page'
        variant='tertiary'
      />
      <StyledWrap>
        <StyledText>{`${t('common.results_on_page')}:`}</StyledText>
      </StyledWrap>
      <FieldSingleSelect
        handleSelectedItemChange={({ value }) => setParams({ rowsPerPage: value, currentPage: 1 })}
        isDisabled={disabled}
        name='pagination-rows-per-page'
        options={options}
        popperPlacement='top-start'
        selectedItem={{ label: rowsPerPage, value: rowsPerPage }}
      />
    </StyledNav>
  );
};

Pagination.defaultProps = {
  disabled: false,
};

const PaginationComposed = ({ loadResource }) => {
  const { currentPage, pages, rowsPerPage, setParams } = usePaginationContext();
  const { isLoading } = useLoadingContext();

  return (
    <Pagination
      currentPage={String(currentPage)}
      disabled={Boolean(loadResource && isLoading(loadResource))}
      pages={Number(pages)}
      pageSizes={PAGE_SIZES}
      rowsPerPage={rowsPerPage ? String(rowsPerPage) : PAGE_SIZES[0]}
      setParams={setParams}
    />
  );
};

export { Pagination, PaginationComposed };
