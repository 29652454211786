import React, { useCallback, useEffect, useState } from 'react';
import * as PopperJS from '@popperjs/core';
import { createPortal } from 'react-dom';
import { isBoolean } from 'lodash';
import { usePopper } from 'react-popper';
import { useSelect, UseSelectStateChange } from 'downshift';

import { popperOffsetModifier } from 'utils';
import { SelectButton } from 'components/SelectButton/SelectButton';
import { StyledMenu, StyledSelectItem, StyledWrapper } from 'components/FieldSingleSelect/styles';

type Option = { label: string; value: string | number; description?: string };

interface FieldSingleSelectPropTypes<O> {
  absolute?: boolean;
  className?: string;
  customEventName?: string;
  handleSelectedItemChange: (item: O | null) => void;
  isDisabled?: boolean;
  isError?: boolean;
  isOpenControlled?: boolean;
  name: string;
  onBlur: React.FocusEvent | ((event: undefined) => unknown);
  options: O[];
  placeholder?: string;
  popperPlacement?: PopperJS.Placement;
  selectedItem: O | null;
  size?: 'small' | 'medium' | 'large';
  withClearButton?: boolean;
}

const POPPER_ROOT_ELEMENT = document.querySelector('body');

export const FieldSingleSelect = <O extends Option>({
  absolute = false,
  className,
  customEventName,
  handleSelectedItemChange,
  isDisabled,
  isError,
  isOpenControlled,
  name,
  onBlur,
  options: items,
  placeholder,
  popperPlacement = 'bottom-start',
  selectedItem,
  size = 'medium',
  withClearButton,
}: FieldSingleSelectPropTypes<O>) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLUListElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: popperPlacement,
    modifiers: [
      popperOffsetModifier,
      {
        name: 'flip',
        options: { allowedAutoPlacements: ['top'], padding: { top: 60, bottom: 60 } },
      },
    ],
  });

  const onSelectedItemChange = (changes: UseSelectStateChange<O>) => {
    const { selectedItem } = changes;
    handleSelectedItemChange(selectedItem || null);
  };
  const itemToString = useCallback((option: O | null) => option?.label || '', []);

  const {
    reset,
    isOpen: isOpenSelect,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    openMenu,
  } = useSelect({
    items,
    selectedItem,
    onSelectedItemChange,
    itemToString,
  });
  const isOpen = isBoolean(isOpenControlled) ? isOpenControlled : isOpenSelect;

  useEffect(() => {
    if (customEventName) {
      document.addEventListener(customEventName, openMenu);
      return () => document.removeEventListener(customEventName, openMenu);
    }
  }, [getToggleButtonProps]);

  return (
    <StyledWrapper className={className} onClick={(e) => e.stopPropagation()}>
      <div ref={setReferenceElement}>
        <SelectButton
          {...getToggleButtonProps()}
          clearSelection={reset}
          isDisabled={isDisabled}
          isError={isError}
          label={selectedItem?.label || placeholder}
          onBlur={onBlur}
          selected={items.find(({ value }) => value === selectedItem?.value)}
          size={size}
          testid={`downshift-${name}`}
          withClearButton={selectedItem && withClearButton}
        />
      </div>
      {absolute ? (
        createPortal(
          <div {...getMenuProps()}>
            <StyledMenu
              className={isOpen ? 'field-single-select--is-open' : 'field-single-select--is-closed'}
              isOpen={isOpen}
              ref={setPopperElement}
              style={{ ...styles.popper }}
              {...attributes.popper}
            >
              {items.map((item, index) => (
                <StyledSelectItem
                  data-testid='field-single-select-option'
                  isHighlighted={highlightedIndex === index}
                  isSelected={selectedItem?.value === item.value}
                  key={index}
                  {...getItemProps({ item, index })}
                >
                  {item.label}
                  {item.description && <span>{item.description}</span>}
                </StyledSelectItem>
              ))}
            </StyledMenu>
          </div>,
          POPPER_ROOT_ELEMENT!,
        )
      ) : (
        <div {...getMenuProps()}>
          <StyledMenu
            className={isOpen ? 'field-single-select--is-open' : 'field-single-select--is-closed'}
            isOpen={isOpen}
            ref={setPopperElement}
            style={{ ...styles.popper, transform: undefined }}
            {...attributes.popper}
          >
            {items.map((item, index) => (
              <StyledSelectItem
                data-testid='field-single-select-option'
                isHighlighted={highlightedIndex === index}
                isSelected={selectedItem?.value === item.value}
                key={index}
                {...getItemProps({ item, index })}
              >
                {item.label}
                {item.description && <span>{item.description}</span>}
              </StyledSelectItem>
            ))}
          </StyledMenu>
        </div>
      )}
    </StyledWrapper>
  );
};

// TODO: refactor this to useImperativeHandler hook.
export const triggerCustomEvent = (eventName: string) => {
  const event = new CustomEvent(eventName);
  document.dispatchEvent(event);
};
