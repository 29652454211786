import React, { FC, useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { navigate, RouteComponentProps } from '@reach/router';
import { useDispatch } from 'react-redux';
import { StyledContent } from 'containers/styles';
import { StandardLabel } from 'components/StandardLabel/StandardLabel';
import { Margin } from 'styles/utils';
import { StyledCheckboxWrapper, StyledError } from 'components/Forms/styles';
import { CheckboxField } from 'components/Forms/CheckboxField/CheckboxField';
import { LevelField } from 'components/Forms/LevelField/LevelField';
import { TextField } from 'components/Forms/TextField/TextField';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import {
  StyledAddressLabelingWrapper,
  StyledAddressWrapper,
  StyledBasicDataWrapper,
  StyledCompanyInfoWrapper,
  StyledFormWrapper,
} from './styles';
import { TextareaField } from 'components/Forms/TextareaField/TextareaField';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { ButtonWrapper } from 'components/ButtonWrapper/ButtonWrapper';
import { StyledHorizontalRule } from 'components/MultiSelectList/styles';
import { NumberField } from 'components/Forms/NumberField/NumberField';
import { API } from 'api';
import { yupValidate } from 'utils/yupValidate';
import { useModal } from 'components/Modal/Modal';
import { createCompany } from 'ducks/companies/actions';
import { Address, CompanyEntity, CompanyStatusResponse } from 'ducks/companies/types';
import { CapsLabel } from 'components/CapsLabel/styles';
import { SwitchField } from 'components/Forms/SwitchField/SwitchField';
import { StatusBadge } from 'components/StatusBadge/StatusBadge';
import { StyledWarningMessage } from 'containers/companyDetails/BasicData/styles';
import { WarningIcon } from 'utils/iconsMap';
import { StyledCardDivider } from 'components/Card/styles';
import { getCompanyContactRoles } from 'ducks/contacts/actions';
import { resetSearchResults } from 'ducks/powerSearch/actions';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { format } from 'date-fns';

type PostalCodeResponseType = Readonly<{
  data: {
    id: number;
    name: string;
    code: string;
    municipality: string;
  };
}>;

type ErrorValidation = {
  comment?: string;
  post_code?: string;
  mPost_code?: string;
  orgNumber?: string;
};

const schema = yup.object().shape({
  name: yup.string().when('orgNumber', {
    is: '',
    then: yup.string().required(),
  }),
  email: yup.string().trim().nullable().email(),
  ceoEmail: yup.string().trim().nullable().email(),
  website: yup
    .string()
    .nullable()
    .trim()
    .matches(
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w?[a-zA-Z-_%/@?]+)*([^/\w?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      { excludeEmptyString: true, message: 'Ugyldig format' },
    ),
});

const yupValidationBasicData = yupValidate(schema);

const riskOptions = ['1', '2', '3', '4'].map((x) => ({ label: x, value: x }));

interface CompanyDetailsPropTypes extends RouteComponentProps {
  addNext?: boolean;
  callback?: (company: CompanyEntity) => void;
  numberKnown?: boolean;
  status: CompanyStatusResponse | null;
  orgNumberValue: string;
}
type classificationCodesType = {
  code: string;
  title: string;
};

export const CompanyDetails: FC<CompanyDetailsPropTypes> = ({
  addNext = false,
  numberKnown = true,
  status,
  callback,
}) => {
  const { t } = useTranslation();
  const [postalAreaId, setPostalAreaId] = useState<number | null>(null);
  const [mPostalAreaId, setMPostalAreaId] = useState<number | null>(null);
  const [copyAddress, setCopyAddress] = useState<boolean>(true);
  const [copyAddressM, setCopyAddressM] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { hideModal } = useModal();
  const isHrrBranch = window.REACT_APP_ENV?.BRAND_ID === 'hrr';
  const dispatch = useDispatch();

  const [businessAddress, setBusinessAddress] = useState<Address | undefined>();
  const [mailingAddress, setMailingAddress] = useState<Address | undefined>();

  const isEnk = status?.company.info?.orgTypeCode === 'ENK';

  React.useEffect(() => {
    dispatch(getCompanyContactRoles());
    if (numberKnown && status?.company?.addresses?.filter((address) => address.type === 20)[0]) {
      setBusinessAddress(status?.company?.addresses?.filter((address) => address.type === 20)[0]);
      setPostalAreaId(
        status?.company?.addresses?.filter((address) => address.type === 20)[0].postalAreaId,
      );
    } else {
      setBusinessAddress(undefined);
      setPostalAreaId(null);
    }
    if (numberKnown && status?.company?.addresses?.filter((address) => address.type === 50)[0]) {
      setMailingAddress(status?.company?.addresses?.filter((address) => address.type === 50)[0]);
      setMPostalAreaId(
        status?.company?.addresses?.filter((address) => address.type === 50)[0].postalAreaId,
      );
    } else {
      setMailingAddress(undefined);
      setMPostalAreaId(null);
    }
    setCopyAddressM(false);
    setCopyAddress(true);

    return () => {
      dispatch(resetSearchResults());
    };
  }, [dispatch, resetSearchResults, status, numberKnown]);

  const postCodeChange = async (value: string, formOnChange: Function, mailing = false) => {
    if (value.length === 4) {
      try {
        const response: PostalCodeResponseType = await API.get(`/post_codes/${value}`);
        mailing ? setMPostalAreaId(response.data.id) : setPostalAreaId(response.data.id);
        formOnChange(response.data);
      } catch (error) {
        mailing ? setMPostalAreaId(null) : setPostalAreaId(null);
        formOnChange({
          name: '',
          municipality: '',
        });
      }
    } else {
      mailing ? setMPostalAreaId(null) : setPostalAreaId(null);
      formOnChange({
        name: '',
        municipality: '',
      });
    }
  };

  const onSubmit = useCallback(
    async (values) => {
      try {
        setLoading(true);
        if (numberKnown) {
          const classificationCodesArray: classificationCodesType[] = [];
          if (status && status?.company?.classificationCodes.length > 0) {
            status.company.classificationCodes.forEach((code) => {
              classificationCodesArray.push(code);
            });
          }
          const paramsToPost = {
            add_company: true,
            name: status?.company?.info?.name,
            comment: values.comment,
            org_no: status?.company?.info?.number,
            visit_street: values.street,
            visit_street_number: values.street_number,
            visit_postal_area_id: postalAreaId || null,
            mailing_street: values.mStreet,
            mailing_street_number: values.mStreetNumber,
            mailing_postal_area_id: mPostalAreaId || null,
            taxable: values.taxable,
            phone: values.phone || '',
            email: values?.email?.trim() || '',
            website: values.website || '',
            aka: values.aka,
            [isEnk ? 'proprietorName' : 'ceoName']:
              values[isEnk ? 'proprietorName' : 'ceoName'] || '',
            [isEnk ? 'proprietorEmail' : 'ceoEmail']:
              values[isEnk ? 'proprietorEmail' : 'ceoEmail']?.trim() || '',
            [isEnk ? 'proprietorPhone' : 'ceoPhone']:
              values[isEnk ? 'proprietorPhone' : 'ceoPhone'] || '',
            establishedAtDate: values.founded,
            registeredAtDate: values.registered,
            lastRegisteredAnnualAccountsYear: values.lastAccountYear,
            classificationCodes: classificationCodesArray,
          };
          const response = await createCompany(paramsToPost);
          if (callback) callback(response);
          else navigate(`/redesigned/companies/${response.id}/basic-data`);
          hideModal && hideModal();
          setLoading(false);
        } else {
          const paramsToPost = {
            postal_area_id: postalAreaId || null,
            name: values.name,
            street: values.street,
            street_number: values.street_number,
            phone: values.phone,
            amu: values.amu,
            aka: values.aka,
            ik: values.ik,
            bht: values.bht,
            comment: values.comment,
            number_of_employees: values.employees,
            email: values?.email?.trim(),
            taxable: true,
            safety_representative: values.representative,
            accommodation: values.accomodation,
            cleaning: values.cleaning,
            has_alcohol_license: values.food_service,
            food_service: values.food_service,
            risk_level: values.risk_level,
          };
          const response = await createCompany(paramsToPost);
          if (callback) {
            callback(response);
          } else {
            navigate(`/redesigned/companies/${response.id}/basic-data`);
          }
          if (values.submit_type === 'next') return;
          hideModal && hideModal();
          setLoading(false);
        }
      } catch {
        setLoading(false);
      }
    },
    [status, postalAreaId, mPostalAreaId, numberKnown],
  );

  return (
    <Form
      initialValues={{
        street: numberKnown ? businessAddress?.street : '' || '',
        street_number: numberKnown ? businessAddress?.streetNumber : '' || '',
        post_code: numberKnown ? businessAddress?.postalCode : '' || '',
        post_area: numberKnown ? businessAddress?.city : '' || '',
        municipality: numberKnown ? businessAddress?.municipality : '' || '',
        bStreet: numberKnown ? businessAddress?.street : '' || '',
        bStreet_number: numberKnown ? businessAddress?.streetNumber : '' || '',
        bPost_code: numberKnown ? businessAddress?.postalCode : '' || '',
        bPost_area: numberKnown ? businessAddress?.city : '' || '',
        bMunicipality: numberKnown ? businessAddress?.municipality : '' || '',
        mStreet: numberKnown ? mailingAddress?.street : '' || '',
        mStreetNumber: numberKnown ? mailingAddress?.streetNumber : '' || '',
        mPost_code: numberKnown ? mailingAddress?.postalCode : '' || '',
        mPost_area: numberKnown ? mailingAddress?.city : '' || '',
        mMunicipality: numberKnown ? mailingAddress?.municipality : '' || '',
        taxable: numberKnown ? status?.company?.info?.active : false,
        orgNumber: numberKnown ? status?.company?.info?.number : '' || '',
        website: numberKnown ? status?.company?.contact?.website : '' || '',
        email: numberKnown ? status?.company?.contact?.email?.trim() : '' || '',
        phone: numberKnown ? status?.company?.contact?.phone : '' || '',
        [isEnk ? 'proprietorName' : 'ceoName']: numberKnown
          ? status?.company?.people[0][isEnk ? 'owner' : 'ceo'].name
          : '' || '',
        founded:
          numberKnown && status?.company?.status?.establishedAtDate
            ? format(new Date(status.company.status.establishedAtDate), 'dd.MM.yyyy')
            : '',
        registered:
          numberKnown && status?.company?.status?.registeredAtDate
            ? format(new Date(status.company.status.registeredAtDate), 'dd.MM.yyyy')
            : '',
        recorded: numberKnown ? format(new Date(), 'dd.MM.yyyy') : '' || '',
        lastAccountYear: numberKnown
          ? status?.company?.status?.lastRegisteredAnnualAccountsYear
          : '' || '',
        comment: '',
      }}
      onSubmit={onSubmit}
      render={({ values, handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          {(!numberKnown || (numberKnown && status?.status === 3)) && (
            <StyledFormWrapper>
              <Margin margin='3.2rem -1.6rem'>
                <StyledHorizontalRule />
              </Margin>
              <StyledContent>
                {numberKnown && (
                  <Margin bottom='2.4rem'>
                    <CapsLabel>{t('forms.company_found')}:</CapsLabel>

                    <h2>{status?.company?.info?.name}</h2>
                    {(status?.company.status.bankrupt ||
                      status?.company.status.ongoingBankruptcyProceedings ||
                      status?.company.status.ongoingForcedLiquidation) && (
                      <>
                        {status?.company.status.bankrupt && (
                          <StyledWarningMessage variant='danger'>
                            <WarningIcon />
                            <span>{t('company_details.basic-data.bankruptcy')}</span>
                          </StyledWarningMessage>
                        )}
                        {status?.company.status.ongoingBankruptcyProceedings && (
                          <StyledWarningMessage variant='warning'>
                            <WarningIcon />
                            <span>{t('company_details.basic-data.bankruptcy_proceeding')}</span>
                          </StyledWarningMessage>
                        )}
                        {status?.company.status.ongoingForcedLiquidation && (
                          <StyledWarningMessage variant='warning'>
                            <WarningIcon />
                            <span>{t('company_details.basic-data.forced_liquidation')}</span>
                          </StyledWarningMessage>
                        )}
                        <StyledCardDivider fullWidth />
                      </>
                    )}
                    <StyledCompanyInfoWrapper>
                      <div>
                        <StandardLabel variant='thin'>
                          {t('company_details.organisational_number')}:{' '}
                          {status?.company?.info?.number}
                        </StandardLabel>
                        {status?.company?.info?.orgTypeCode && (
                          <StatusBadge variant='active'>
                            {status?.company.info.orgTypeCode}
                          </StatusBadge>
                        )}
                        <StatusBadge
                          variant={status?.company?.info?.vatDuty ? 'active' : 'inactive'}
                        >
                          {t('company_details.vat')}
                        </StatusBadge>
                      </div>
                      <Field
                        capitalize
                        component={SwitchField}
                        label={
                          values.taxable
                            ? `${t('company_details.company_is_active')} (${t(
                                'company_details.taxable',
                              )})`
                            : `${t('company_details.company_is_inactive')} (${t(
                                'company_details.not_taxable',
                              )})`
                        }
                        name='taxable'
                      />
                    </StyledCompanyInfoWrapper>
                  </Margin>
                )}
                {!numberKnown && (
                  <>
                    <Margin bottom='1.6rem'>
                      <InfoBox message={t('forms.check_BRREG_message')} />
                    </Margin>

                    <Field
                      additionalInputProps={{ vsize: 'small' }}
                      component={TextField}
                      label={t('forms.company_name')}
                      name='name'
                      required
                    />
                  </>
                )}
                <Field
                  additionalInputProps={{ vsize: 'small' }}
                  component={TextField}
                  label={t('company_details.basic-data.aka')}
                  name='aka'
                />
                {numberKnown &&
                  (values.bStreet ||
                    values.bStreet_number ||
                    values.bPost_code ||
                    values.bPost_area) && (
                    <>
                      <StandardLabel variant='primary'>
                        {t('company_details.basic-data.address_types.business')}
                      </StandardLabel>
                      <StyledAddressWrapper>
                        <Field
                          additionalInputProps={{ vsize: 'small' }}
                          component={TextField}
                          isDisabled
                          label={t('forms.street')}
                          name='bStreet'
                        />
                        <Field
                          additionalInputProps={{ vsize: 'small' }}
                          component={TextField}
                          isDisabled
                          label={t('forms.street_number')}
                          name='bStreet_number'
                        />

                        <Field
                          additionalInputProps={{ vsize: 'small' }}
                          component={TextField}
                          isDisabled
                          label={t('forms.post_code')}
                          name='bPost_code'
                        />

                        <Field
                          additionalInputProps={{ vsize: 'small' }}
                          component={TextField}
                          isDisabled
                          label={t('forms.post_area')}
                          name='bPost_area'
                        />
                        <Field
                          additionalInputProps={{ vsize: 'small' }}
                          component={TextField}
                          isDisabled
                          label={t('forms.municipality')}
                          name='bMunicipality'
                        />
                      </StyledAddressWrapper>
                    </>
                  )}
                {!numberKnown && (
                  <StandardLabel variant='primary'>
                    {t('company_details.basic-data.address_types.visit')}
                  </StandardLabel>
                )}
                {numberKnown && (
                  <StyledAddressLabelingWrapper>
                    <StandardLabel variant='primary'>
                      {t('company_details.basic-data.address_types.visit')}
                    </StandardLabel>
                    <Checkbox
                      isChecked={copyAddress}
                      label={t('forms.same_address')}
                      onClick={() => {
                        if (!copyAddress) {
                          form.change('street', businessAddress?.street || '');
                          form.change('street_number', businessAddress?.streetNumber || '');
                          if (businessAddress?.postalCode) {
                            form.change('post_code', businessAddress?.postalCode || '');
                            postCodeChange(
                              businessAddress?.postalCode,
                              (val: PostalCodeResponseType['data']) => {
                                form.change('post_area', val.name);
                                form.change('municipality', val.municipality);
                              },
                            );
                          }
                        } else {
                          form.change('street', '');
                          form.change('streetNumber', '');
                          form.change('post_code', '');
                          postCodeChange(
                            '',
                            (val: PostalCodeResponseType['data']) => {
                              form.change('post_area', val.name);
                              form.change('municipality', val.municipality);
                            },
                            true,
                          );
                        }
                        setCopyAddress(!copyAddress);
                      }}
                      size='small'
                    />
                  </StyledAddressLabelingWrapper>
                )}
                <StyledAddressWrapper>
                  <Field
                    additionalInputProps={{ vsize: 'small' }}
                    component={TextField}
                    input={{
                      name: 'street',
                      id: 'street',
                      value: values.street || '',
                      onChange: ({
                        currentTarget: { value },
                      }: React.FormEvent<HTMLInputElement>) => {
                        form.change('street', value);
                        if (value !== businessAddress?.street && copyAddress === true) {
                          setCopyAddress(false);
                        }
                      },
                    }}
                    label={t('forms.street')}
                    name='street'
                  />

                  <Field
                    additionalInputProps={{ vsize: 'small' }}
                    component={TextField}
                    input={{
                      name: 'street_number',
                      id: 'street_number',
                      value: values.street_number || '',
                      onChange: ({
                        currentTarget: { value },
                      }: React.FormEvent<HTMLInputElement>) => {
                        form.change('street_number', value);
                        if (value !== businessAddress?.streetNumber && copyAddress === true) {
                          setCopyAddress(false);
                        }
                      },
                    }}
                    label={t('forms.street_number')}
                    name='street_number'
                  />

                  <Field
                    additionalInputProps={{ vsize: 'small' }}
                    component={TextField}
                    input={{
                      name: 'post_code',
                      id: 'post_code',
                      value: values.post_code || '',
                      maxLength: 4,
                      onChange: ({
                        currentTarget: { value },
                      }: React.FormEvent<HTMLInputElement>) => {
                        form.change('post_code', value);
                        if (value !== businessAddress?.postalCode && copyAddress === true) {
                          setCopyAddress(false);
                        }
                        postCodeChange(value, (val: PostalCodeResponseType['data']) => {
                          form.change('post_area', val.name);
                          form.change('municipality', val.municipality);
                        });
                      },
                    }}
                    label={t('forms.post_code')}
                    name='post_code'
                  />

                  <Field
                    additionalInputProps={{ vsize: 'small' }}
                    component={TextField}
                    isDisabled
                    label={t('forms.post_area')}
                    name='post_area'
                  />
                  <Field
                    additionalInputProps={{ vsize: 'small' }}
                    component={TextField}
                    isDisabled
                    label={t('forms.municipality')}
                    name='municipality'
                  />
                </StyledAddressWrapper>
                {numberKnown && (
                  <>
                    <StyledAddressLabelingWrapper>
                      <StandardLabel variant='primary'>
                        {t('company_details.basic-data.address_types.mailing')}
                      </StandardLabel>
                      <Checkbox
                        isChecked={copyAddressM}
                        label={t('forms.same_address')}
                        onClick={() => {
                          if (!copyAddressM) {
                            form.change('mStreet', businessAddress?.street || '');
                            form.change('mStreetNumber', businessAddress?.streetNumber || '');
                            if (businessAddress?.postalCode) {
                              form.change('mPost_code', businessAddress?.postalCode || '');
                              postCodeChange(
                                businessAddress?.postalCode,
                                (val: PostalCodeResponseType['data']) => {
                                  form.change('mPost_area', val.name);
                                  form.change('mMunicipality', val.municipality);
                                },
                                true,
                              );
                            }
                          } else {
                            form.change('mStreet', '');
                            form.change('mStreetNumber', '');
                            form.change('mPost_code', '');
                            postCodeChange(
                              '',
                              (val: PostalCodeResponseType['data']) => {
                                form.change('mPost_area', val.name);
                                form.change('mMunicipality', val.municipality);
                              },
                              true,
                            );
                          }
                          setCopyAddressM(!copyAddressM);
                        }}
                        size='small'
                      />
                    </StyledAddressLabelingWrapper>
                    <StyledAddressWrapper>
                      <Field
                        additionalInputProps={{ vsize: 'small' }}
                        component={TextField}
                        input={{
                          name: 'mStreet',
                          id: 'mStreet',
                          value: values.mStreet || '',
                          onChange: ({
                            currentTarget: { value },
                          }: React.FormEvent<HTMLInputElement>) => {
                            form.change('mStreet', value);
                            if (value !== businessAddress?.street && copyAddressM === true) {
                              setCopyAddressM(false);
                            }
                          },
                        }}
                        label={t('forms.street')}
                        name='mStreet'
                      />

                      <Field
                        additionalInputProps={{ vsize: 'small' }}
                        component={TextField}
                        input={{
                          name: 'mStreetNumber',
                          id: 'mStreetNumber',
                          value: values.mStreetNumber || '',
                          onChange: ({
                            currentTarget: { value },
                          }: React.FormEvent<HTMLInputElement>) => {
                            form.change('mStreetNumber', value);
                            if (value !== businessAddress?.streetNumber && copyAddressM === true) {
                              setCopyAddressM(false);
                            }
                          },
                        }}
                        label={t('forms.street_number')}
                        name='mStreetNumber'
                      />

                      <Field
                        additionalInputProps={{ vsize: 'small' }}
                        component={TextField}
                        input={{
                          name: 'mPost_code',
                          id: 'mPost_code',
                          value: values.mPost_code || '',
                          maxLength: 4,
                          onChange: ({
                            currentTarget: { value },
                          }: React.FormEvent<HTMLInputElement>) => {
                            form.change('mPost_code', value);
                            if (value !== businessAddress?.postalCode && copyAddressM === true) {
                              setCopyAddressM(false);
                            }
                            postCodeChange(
                              value,
                              (val: PostalCodeResponseType['data']) => {
                                form.change('mPost_area', val.name);
                                form.change('mMunicipality', val.municipality);
                              },
                              true,
                            );
                          },
                        }}
                        label={t('forms.post_code')}
                        name='mPost_code'
                      />

                      <Field
                        additionalInputProps={{ vsize: 'small' }}
                        component={TextField}
                        isDisabled
                        label={t('forms.post_area')}
                        name='mPost_area'
                      />
                      <Field
                        additionalInputProps={{ vsize: 'small' }}
                        component={TextField}
                        isDisabled
                        label={t('forms.municipality')}
                        name='mMunicipality'
                      />
                    </StyledAddressWrapper>
                  </>
                )}
                <StyledBasicDataWrapper>
                  {numberKnown && (
                    <Field
                      additionalInputProps={{ vsize: 'small' }}
                      component={TextField}
                      label={t('table.columns.website')}
                      name='website'
                    />
                  )}
                  <Field
                    additionalInputProps={{ vsize: 'small' }}
                    component={TextField}
                    label={t('table.columns.email')}
                    name='email'
                  />
                  <Field
                    additionalInputProps={{ vsize: 'small' }}
                    component={TextField}
                    label={t('table.columns.phone')}
                    name='phone'
                  />
                </StyledBasicDataWrapper>
                {numberKnown && (
                  <>
                    <StandardLabel variant='primary'>
                      {t(`forms.${isEnk ? 'proprietor' : 'ceo'}`)}
                    </StandardLabel>
                    <StyledBasicDataWrapper>
                      <Field
                        additionalInputProps={{ vsize: 'small' }}
                        component={TextField}
                        label={t('table.columns.name')}
                        name={isEnk ? 'proprietorName' : 'ceoName'}
                      />
                      <Field
                        additionalInputProps={{ vsize: 'small' }}
                        component={TextField}
                        label={t('table.columns.email')}
                        name={isEnk ? 'proprietorEmail' : 'ceoEmail'}
                      />
                      <Field
                        additionalInputProps={{ vsize: 'small' }}
                        component={TextField}
                        label={t('table.columns.phone')}
                        name={isEnk ? 'proprietorPhone' : 'ceoPhone'}
                      />
                    </StyledBasicDataWrapper>
                  </>
                )}
                {!numberKnown && (
                  <>
                    <Field
                      component={NumberField}
                      label={t('company_details.basic-data.number_of_employees')}
                      name='employees'
                    />

                    <Margin bottom='2.4rem' top='1.6rem'>
                      <StyledCheckboxWrapper>
                        <Field
                          component={CheckboxField}
                          label={t('company_details.basic-data.amu')}
                          name='amu'
                        />
                        <Field
                          component={CheckboxField}
                          label={t('company_details.basic-data.bht')}
                          name='bht'
                        />
                        <Field
                          component={CheckboxField}
                          label={t('company_details.basic-data.ik_system')}
                          name='ik'
                        />
                        <Field
                          component={CheckboxField}
                          label={t('company_details.basic-data.safety_representative')}
                          name='representative'
                        />
                      </StyledCheckboxWrapper>
                    </Margin>
                    {isHrrBranch && (
                      <>
                        <StandardLabel>{t('company_details.basic-data.branch')}</StandardLabel>
                        <Margin bottom='2.4rem' top='1.6rem'>
                          <StyledCheckboxWrapper>
                            <Field
                              component={CheckboxField}
                              label={t('company_details.basic-data.accommodation')}
                              name='accomodation'
                            />

                            <Field
                              component={CheckboxField}
                              label={t('company_details.basic-data.food_service')}
                              name='food_service'
                            />

                            <Field
                              component={CheckboxField}
                              label={t('company_details.basic-data.cleaning')}
                              name='cleaning'
                            />
                          </StyledCheckboxWrapper>
                        </Margin>

                        <Margin bottom='3.2rem'>
                          <Field
                            clearButton
                            component={LevelField}
                            label={t('company_details.basic-data.risk')}
                            name='risk_level'
                            options={riskOptions}
                          />
                        </Margin>
                      </>
                    )}
                  </>
                )}
                {numberKnown && (
                  <>
                    <StandardLabel variant='primary'>{t(`forms.history`)}</StandardLabel>
                    <StyledBasicDataWrapper>
                      <Field
                        additionalInputProps={{ vsize: 'small' }}
                        component={TextField}
                        isDisabled={true}
                        label={t('forms.founded')}
                        name='founded'
                        size='big'
                      />
                      <Field
                        additionalInputProps={{ vsize: 'small' }}
                        component={TextField}
                        isDisabled={true}
                        label={t('forms.registered')}
                        name='registered'
                        size='big'
                      />
                      <Field
                        additionalInputProps={{ vsize: 'small' }}
                        component={TextField}
                        isDisabled={true}
                        label={t('forms.recorded')}
                        name='recorded'
                        size='big'
                      />
                      <Field
                        additionalInputProps={{ vsize: 'small' }}
                        component={TextField}
                        isDisabled={true}
                        label={t('forms.last_account_year')}
                        name='lastAccountYear'
                        size='big'
                      />
                    </StyledBasicDataWrapper>
                  </>
                )}

                {numberKnown && status && status.company.classificationCodes?.length > 0 && (
                  <>
                    <StandardLabel variant='primary'>NACE</StandardLabel>
                    <Margin margin='.5rem'>
                      <StyledHorizontalRule />
                    </Margin>
                    {status.company.classificationCodes.map((code, i) => (
                      <div key={i}>
                        {code.code} {code.title}
                      </div>
                    ))}
                    <Margin bottom='1.5rem' margin='.5rem'>
                      <StyledHorizontalRule />
                    </Margin>
                  </>
                )}

                <Field name='comment'>
                  {({ input, meta }) => (
                    <>
                      <TextareaField
                        characterLimit={1000}
                        input={input}
                        label={t('company_details.basic-data.comment')}
                        meta={meta}
                        {...input}
                        placeholder={t('forms.description_or_comment_about_the_company')}
                        required
                      />
                      {(!numberKnown || (numberKnown && status?.status === 3)) &&
                        meta.error &&
                        meta.touched && <StyledError>{meta.error}</StyledError>}
                    </>
                  )}
                </Field>
                {addNext ? (
                  <ButtonWrapper align='right'>
                    <DefaultButton
                      capitalize
                      label={t('common.cancel')}
                      onClick={hideModal}
                      type='button'
                      variant='flat'
                    />
                    <DefaultButton
                      capitalize
                      label={t('common.add')}
                      onClick={(event) => {
                        form.change('submit_type', 'add');
                        handleSubmit(event);
                      }}
                      variant='primary'
                    />
                    <DefaultButton
                      capitalize
                      label={t('common.add_next_one')}
                      onClick={(event) => {
                        form.change('submit_type', 'next');
                        handleSubmit(event)?.then(() => {
                          form.reset();
                        });
                      }}
                      variant='secondary'
                    />
                  </ButtonWrapper>
                ) : (
                  <ButtonWrapper align='right'>
                    <DefaultButton
                      capitalize
                      label={t('common.cancel')}
                      onClick={hideModal}
                      type='button'
                      variant='flat'
                    />
                    <DefaultButton
                      capitalize
                      isDisabled={loading || !values.comment}
                      label={t('common.save')}
                      onClick={handleSubmit}
                      variant='primary'
                    />
                  </ButtonWrapper>
                )}
              </StyledContent>
            </StyledFormWrapper>
          )}
        </form>
      )}
      validate={async (values) => {
        let errors: ErrorValidation = {};
        errors = (await yupValidationBasicData(values)) || {};
        if ((!numberKnown || (numberKnown && status?.status === 3)) && !values.comment) {
          errors.comment = t('forms.comment_required');
        }
        if (!numberKnown) {
          if (values.post_code && !values.post_area) {
            errors.post_code = t('forms.incorrect_code');
          }
          if (values.mPost_code && !values.mPost_area) {
            errors.mPost_code = t('forms.incorrect_code');
          }
        } else {
          if (values.post_code && !values.post_area) {
            errors.post_code = t('forms.incorrect_code');
          }
          if (values.mPost_code && !values.mPost_area) {
            errors.mPost_code = t('forms.incorrect_code');
          }
        }
        return errors ? errors : null;
      }}
    />
  );
};
