import { useState } from 'react';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, FormSpy } from 'react-final-form';

import styled from 'styled-components/macro';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { RadioButtonWithLabel } from 'components/RadioButtonWithLabel/RadioButtonWithLabel';
import { StyledForm, StyledLabel, StyledVerticalFieldWrapper } from 'components/Forms/styles';
import { TextareaField } from 'components/Forms/TextareaField/TextareaField';
import { updateRvoEventTips } from 'ducks/events/actions';
import { useAppDispatch } from 'store';
import { useModal } from 'components/Modal/Modal';
import { StyledModalButtonsWrapper, StyledModalHeader } from 'components/Modal/styles';
import { useSelector } from 'react-redux';
import { TextField } from 'components/Forms/TextField/TextField';
import { yupValidate } from 'utils/yupValidate';
import { tipsAtForRevisedActivities } from './helpers';
import { API } from 'api';
import { ContentContainer, Margin } from 'styles/utils';
import { tipsThemesSelector } from 'ducks/constants/selectors';
import { CheckboxField } from 'components/Forms/CheckboxField/CheckboxField';
import { Address, CompanyEntity } from 'ducks/companies/types';
import { StyledAddressWrapper } from 'containers/CompanyCreate/styles';
import { StyledLink } from 'containers/companyDetails/BasicData/styles';
import { ValueWithLabel } from 'components/ValueWithLabel/ValueWithLabel';
import { AttachmentsToRemoveField } from 'components/Forms/AttachmentsToRemoveField/AttachmentsToRemoveField';
import { FormApi } from 'final-form';
import { Attachment } from 'ducks/events/types';

type ResponseType = {
  data: {
    name: string;
    id: number;
  };
  status: number;
};

interface TipsATProps {
  revisedActivityId: string;
  currentTipValue?: string | number;
  activityDescription?: string;
  selectedCompany: CompanyEntity;
  attachments: Attachment[];
}

interface TipsFormValues {
  comment: string;
  streetNumber: string;
  street: string;
  postCode: string;
  postArea: string | null;
  tipsThemeId: string | number;
  includeAttachments: boolean;
  useVisitAddress: boolean;
  attachmentsToRemove: number[]; // A list of ids of attachments on the revised activity that are not to be included for TIPS
}

const StyledErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.red500};
  font-size: 1.2rem;
`;

export const TipsATForRevisedActivities = ({
  revisedActivityId,
  currentTipValue = '',
  activityDescription,
  selectedCompany,
  attachments = [],
}: TipsATProps): React.ReactElement => {
  const address = selectedCompany.addresses.find(
    (address: Address) => address.typeSymbol === 'visit',
  );
  const validateAddress = () => {
    if (address && address.postalCode && address.city) {
      return true;
    }
    return false;
  };
  const formattedAddress = validateAddress()
    ? `${address?.address}, ${address?.postalCode} ${address?.city}`
    : '';

  const { t } = useTranslation();
  const tipsThemes = useSelector(tipsThemesSelector);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { hideModal } = useModal();
  const dispatch = useAppDispatch();

  const fetchCity = async (form: FormApi<TipsFormValues>, postCodeInput: string) => {
    if (postCodeInput && postCodeInput.length === 4) {
      try {
        const response: ResponseType = await API.get(`/post_codes/${postCodeInput}`);
        form.change('postArea', response.data.name);
      } catch (error) {
        console.log(error);
      }
    } else {
      form.change('postArea', '');
    }
  };

  const onSubmit = async (values: TipsFormValues) => {
    setIsProcessing(true);
    const location = {
      streetWithNumber: values.street + ' ' + values.streetNumber,
      postalCode: values.postCode,
      postalAreaName: values.postArea,
    };

    if (
      !location ||
      !location.streetWithNumber ||
      !location.postalCode ||
      !location.postalAreaName
    ) {
      setError(`${t('new_visits.something_went_wrong')}: Insufficient address information`);
      setIsProcessing(false);
      return;
    }

    const includedAttachments = attachments.filter((file: Attachment) =>
      !values.attachmentsToRemove.includes(file.id) ? file : null,
    );

    const attributes = {
      comment: values.comment,
      revisedActivityId: revisedActivityId,
      tipsThemeId: values.tipsThemeId,
      includedAttachmentIds: includedAttachments.map((file) => file.id),
      location: location,
    };

    // @ts-ignore
    dispatch(updateRvoEventTips(attributes))
      .then((response) => {
        setIsProcessing(false);
        // @ts-ignore
        if (response?.error?.message) {
          // @ts-ignore
          setError(`${t('new_visits.something_went_wrong')}: ${response?.error?.message}`);
          console.log(response);
        } else {
          hideModal();
        }
        return response;
      })
      .catch(() => setError(t('new_visits.something_went_wrong')));
  };

  const initialValues = {
    comment: activityDescription || '',
    includeAttachments: true,
    tipsThemeId: currentTipValue || tipsThemes[0]?.id,
    street: address ? address.street : '',
    streetNumber: address ? address.streetNumber : '',
    useVisitAddress: true,
    postCode: address ? address.postalCode : '',
    postArea: address ? address.city : '',
    attachmentsToRemove: [],
  };

  const resetAddress = (form: FormApi<TipsFormValues>, values: TipsFormValues) => {
    if (values.useVisitAddress) {
      form.change('street', initialValues.street);
      form.change('streetNumber', initialValues.streetNumber);
      form.change('postCode', initialValues.postCode);
      form.change('postArea', initialValues.postArea);
    }
  };

  const getTranslation = (type: string) => {
    if (type === 'header') {
      return t('new_visits.tips_at_title');
    } else if (type === 'theme_selector') {
      return t('company_details.events.select_theme');
    }
    return '';
  };

  return (
    <>
      <StyledModalHeader>{getTranslation('header')}</StyledModalHeader>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, values, form }) => (
          <StyledForm onSubmit={handleSubmit}>
            <StyledLabel>{getTranslation('theme_selector')}*</StyledLabel>
            {tipsThemes.map((item) => (
              <Field
                component={RadioButtonWithLabel}
                isSelected={values.tipsThemeId === item.id}
                key={item.id}
                name={`tipsTheme${item.id}`}
                onClick={() => form.change('tipsThemeId', item.id)}
                required
                testId='tips-at-modal-theme'
              >
                {item.name}
              </Field>
            ))}
            {formattedAddress && (
              <Margin top='2rem'>
                <Field
                  component={CheckboxField}
                  label={t('company_details.events.use_visit_address')}
                  name='useVisitAddress'
                  onChange={resetAddress(form, values)}
                  testid='tips-at-modal-use-visit-address-checkbox'
                />
              </Margin>
            )}
            {values.useVisitAddress && formattedAddress ? (
              <StyledAddressWrapper>
                <ValueWithLabel label={t('company_details.basic-data.address_types.visit')}>
                  <StyledLink isDisabled>{formattedAddress}</StyledLink>
                </ValueWithLabel>
              </StyledAddressWrapper>
            ) : (
              <>
                <ContentContainer display='flex' margin='0 0 0 0'>
                  <StyledVerticalFieldWrapper>
                    <Field
                      component={TextField}
                      label={t('forms.street')}
                      name='street'
                      required
                      testId='tips-at-modal-street-input'
                    />
                  </StyledVerticalFieldWrapper>
                  <StyledVerticalFieldWrapper>
                    <Field
                      component={TextField}
                      label={t('forms.street_number')}
                      name='streetNumber'
                      required
                      size='medium'
                      testId='tips-at-modal-street-number-input'
                    />
                  </StyledVerticalFieldWrapper>
                </ContentContainer>
                <ContentContainer display='flex' margin='0 0 0 0'>
                  <StyledVerticalFieldWrapper>
                    <Field
                      component={TextField}
                      label={t('forms.post_code')}
                      name='postCode'
                      onChange={fetchCity(form, values.postCode)}
                      required
                      size='medium'
                      testId='tips-at-modal-post-code-input'
                    />
                  </StyledVerticalFieldWrapper>
                  <StyledVerticalFieldWrapper>
                    <Field
                      component={TextField}
                      isDisabled
                      label={t('forms.post_area')}
                      name='postArea'
                      required
                      size='big'
                      testId='tips-at-modal-post-area-input'
                    />
                  </StyledVerticalFieldWrapper>
                </ContentContainer>
              </>
            )}
            {attachments.length > 0 && (
              <Field
                component={CheckboxField}
                label={t('company_details.events.include_attachments')}
                name='includeAttachments'
                testid='tips-at-modal-include-attachments-checkbox'
              />
            )}
            {values.includeAttachments && (
              <Field
                attachmentList={attachments}
                component={AttachmentsToRemoveField}
                name='attachmentsToRemove'
              />
            )}
            <Margin top='1.2rem'>
              <Field
                characterLimit={250}
                component={TextareaField}
                label={t('common.comment')}
                name='comment'
                placeholder={t('forms.add_a_description')}
                required
                testId='tips-at-modal-comment-field'
              />
            </Margin>
            {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
            <StyledModalButtonsWrapper>
              <DefaultButton
                capitalize
                isDisabled={isProcessing}
                label={t('common.cancel')}
                onClick={hideModal}
                testid='tips-at-modal-cancel-btn'
                type='button'
                variant='tertiary'
              />
              <FormSpy subscription={{ errors: true }}>
                {({ errors }) => (
                  <DefaultButton
                    isDisabled={isProcessing || !isEmpty(errors) || !values.comment}
                    label={t('new_visits.send_tips')}
                    testid='tips-at-modal-submit-btn'
                    type='submit'
                    variant='primary'
                  />
                )}
              </FormSpy>
            </StyledModalButtonsWrapper>
          </StyledForm>
        )}
        subscription={{ values: true }}
        validate={yupValidate(tipsAtForRevisedActivities)}
      ></Form>
    </>
  );
};
