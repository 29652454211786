import React, { ComponentType } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { StyledFieldWrapper, StyledLabel } from 'components/Forms/styles';
import {
  StyledDeleteIcon,
  StyledFileName,
  StyledFilesListItem,
  StyledUndoIcon,
} from 'components/Forms/AttachmentsToRemove/styles';
import { Attachment } from 'ducks/events/types';
import { FileIcon, LandscapeIcon, PlayIcon, SpeakerIcon } from 'utils/iconsMap';

const iconVariants = Object.freeze({
  application: FileIcon,
  audio: SpeakerIcon,
  image: LandscapeIcon,
  text: FileIcon,
  video: PlayIcon,
});

export interface AttachmentsToRemovePropTypes {
  attachmentList: Attachment[];
  onChange: (ids: number[]) => void;
  value: number[];
}

export const AttachmentsToRemove: ComponentType<AttachmentsToRemovePropTypes> = ({
  attachmentList,
  onChange,
  value = [],
}) => {
  const { t } = useTranslation();

  const handleChange = (id: number) => {
    const newValue = value.includes(id) ? value.filter((x) => x !== id) : [...value, id];
    onChange(newValue);
  };

  if (isEmpty(attachmentList)) return null;

  return (
    <StyledFieldWrapper>
      <StyledLabel>{t('forms.manage_uploaded')}</StyledLabel>
      <ul>
        {attachmentList.map(({ fileName, id, contentType }: Attachment) => {
          const toBeRemoved = value.includes(id);
          const [attachmentType] = contentType.split('/') as (keyof typeof iconVariants)[];
          const Icon = iconVariants[attachmentType] || FileIcon;

          return (
            <StyledFilesListItem key={id}>
              <StyledFileName isRejected={toBeRemoved}>
                <Icon />
                {fileName}
              </StyledFileName>
              {toBeRemoved ? (
                <StyledUndoIcon onClick={() => handleChange(id)} />
              ) : (
                <StyledDeleteIcon onClick={() => handleChange(id)} />
              )}
            </StyledFilesListItem>
          );
        })}
      </ul>
    </StyledFieldWrapper>
  );
};
