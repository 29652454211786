import styled, { css, keyframes } from 'styled-components/macro';

interface StyledWrapperPropTypes {
  isDisabled?: boolean;
  isError: boolean;
  size: 'small' | 'medium' | 'big' | 'fullWidth' | 'auto';
  vsize: 'small' | 'regular';
}

export const StyledWrapper = styled.div<StyledWrapperPropTypes>`
  background-color: white;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: 1px solid ${({ theme }) => theme.colors.black300};
  display: inline-flex;
  height: ${({ vsize }) => {
    switch (vsize) {
      case 'small':
        return '3rem';
      default:
        return 'auto';
    }
  }};
  justify-content: center;
  overflow: hidden;
  padding: ${({ vsize, theme }) => {
    switch (vsize) {
      case 'small':
        return `0 ${theme.sizes.xs}`;
      default:
        return `${theme.sizes.xs} ${theme.sizes.sm}`;
    }
  }};
  position: relative;
  width: ${({ size }) => {
    switch (size) {
      case 'small':
        return '4.4rem';
      case 'medium':
        return '8rem';
      case 'big':
        return '16rem';
      case 'fullWidth':
        return '100%';
      case 'auto':
        return 'auto';
      default:
        return '4.4rem';
    }
  }};

  ${({ vsize }) =>
    vsize === 'small' &&
    css`
      svg {
        height: ${({ theme }) => theme.sizes.sm};
      }
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: ${({ theme }) => theme.colors.black50};
      border: 1px solid ${({ theme }) => theme.colors.black100};

      & input {
        background-color: ${({ theme }) => theme.colors.black50};
      }
    `}

  ${({ isError }) =>
    isError &&
    css`
      border-color: ${({ theme }) => theme.colors.red500};
    `}
`;

export const FixElementBox = styled.div`
  color: ${({ theme }) => theme.colors.black500};
  position: relative;
  right: 0;
  padding: 0 ${({ theme }) => theme.sizes.xxs};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ClearButton = styled.button`
  ${({ theme }) => theme.icon.md};
  align-items: center;
  animation: ${fadeInAnimation} 0.3s forwards;
  background-color: ${({ theme }) => theme.colors.black500};
  border-radius: 50%;
  color: white;
  display: inline-flex;
  justify-content: center;
  transition: 0.3s background-color;

  &:hover {
    background-color: ${({ theme }) => theme.colors.black400};
  }
`;

export const PrefixElementBox = styled(FixElementBox)`
  padding-right: ${({ theme }) => theme.sizes.xs};
`;

export const StyledInputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

interface StyledInputPropTypes {
  textAlign?: string;
}

export const StyledInput = styled.input<StyledInputPropTypes>`
  ${({ theme }) => theme.fontSizes.base};
  line-height: ${({ theme }) => theme.lineHeight.lg};
  border: none;
  color: inherit;
  padding: 0;
  position: relative;
  width: 100%;

  &::placeholder,
  &:disabled {
    color: ${({ theme }) => theme.colors.black200};
    font-weight: 100;
  }

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `};
`;
