import React, { FC, useState } from 'react';

import {
  StyledExpandIcon,
  StyledGroupListCounter,
  StyledGroupListItem,
  StyledGroupListItemWrapper,
  StyledGroupListName,
  StyledGroupListNameWrapper,
} from './styles';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { GroupListItem } from './GroupListPicker';
import { Margin } from 'styles/utils';
import { useTranslation } from 'react-i18next';

interface GroupListPickerItemPropTypes {
  items: GroupListItem[];
  groupName: string;
  setSelection: (values: string[]) => void;
  selected: string[];
}

export const GroupListPickerItem: FC<GroupListPickerItemPropTypes> = ({
  items,
  groupName,
  setSelection,
  selected,
}) => {
  const [isOpened, setOpened] = useState(false);
  const { t } = useTranslation();
  const selectionsCount = selected.reduce((acc, cur) => {
    return items.some(({ id }) => id === cur) ? acc + 1 : acc;
  }, 0);

  return (
    <StyledGroupListItemWrapper>
      <StyledGroupListItem
        data-testid='group-list-picker-item-parent'
        key={groupName}
        onClick={() => setOpened(!isOpened)}
      >
        <StyledGroupListNameWrapper>
          <StyledGroupListName hasSelections={selectionsCount > 0}>{groupName}</StyledGroupListName>
          {selectionsCount > 0 && (
            <StyledGroupListCounter>{`${selectionsCount} ${t(
              'common.selected',
            )}`}</StyledGroupListCounter>
          )}
        </StyledGroupListNameWrapper>
        <StyledExpandIcon flip={isOpened} />
      </StyledGroupListItem>
      {isOpened && (
        <Margin left='2.4rem'>
          {items.map((item) => (
            <Tooltip
              key={item.id}
              label={item.description || t('common.no_description')}
              placement='bottom'
            >
              <StyledGroupListItem
                data-testid='group-list-picker-item'
                key={item.id}
                onClick={() =>
                  selected.includes(item.id)
                    ? setSelection(selected.filter((x) => x !== item.id))
                    : setSelection([...selected, item.id])
                }
              >
                <Margin right='1.6rem'>
                  <Checkbox isChecked={selected.includes(item.id)} onClick={() => {}} />
                </Margin>
                <StyledGroupListName>{item.name}</StyledGroupListName>
              </StyledGroupListItem>
            </Tooltip>
          ))}
        </Margin>
      )}
    </StyledGroupListItemWrapper>
  );
};
