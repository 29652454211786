import { CancelTokenSource } from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { API } from 'api';
import { Category, District, SearchMatchesParams } from './types';
import { CategoryMatches } from 'components/SearchBar/SearchBar';
import { resetCompanySearchResults } from './company/reducer';
import { DispatchType } from 'store';
import { resetCompanyVisitsSearchResults } from './companyVisits/reducer';
import { resetWorkplacesSearchResults } from './workplace/reducer';
import { resetPriorNotificationsSearchResults } from 'ducks/powerSearch/priorNotifications/reducer';
import { resetRevisedActivitiesSearchResults } from './revisedActivities/reducer';

export const setQuery = createAction<string>('search/query');
export const setCategory = createAction<Category>('search/category');

export const resetSearchResults = () => (dispatch: DispatchType) => {
  dispatch(resetCompanySearchResults());
  dispatch(resetCompanyVisitsSearchResults());
  dispatch(resetWorkplacesSearchResults());
  dispatch(resetPriorNotificationsSearchResults());
  dispatch(resetRevisedActivitiesSearchResults());
};

export const searchMatches = async (params: SearchMatchesParams, token: CancelTokenSource) => {
  const data = await Promise.all([
    API.get(`power/companies`, {
      params: { query: params.query, ...params.company },
      cancelToken: token.token,
    }).catch(() => {}),
    await API.get(`power/company_visits`, {
      params: { query: params.query, ...params.companyVisit },
      cancelToken: token.token,
    }).catch(() => {}),
    await API.get(`power/workplaces`, {
      params: { query: params.query, ...params.workplace },
      cancelToken: token.token,
    }).catch(() => {}),
    await API.get(`power/contacts`, {
      params: { query: params.query, ...params.contacts },
      cancelToken: token.token,
    }).catch(() => {}),
    await API.get(`power/prior_notifications`, {
      params: { query: params.query, ...params.priorNotifications },
      cancelToken: token.token,
    }).catch(() => {}),
    await API.get(`power/revised_activities`, {
      params: { query: params.query, ...params.revisedActivities },
      cancelToken: token.token,
    }).catch(() => {}),
  ]);

  const categoryOrder = [
    'company',
    'company_visits',
    'workplace',
    'contact',
    'prior_notifications',
    'revised_activities',
  ];
  const res = categoryOrder.map((category, i) => {
    const categoryResponse = data[i];
    const matches = categoryResponse ? (categoryResponse.data.meta.totalCount as number) : 0;
    return {
      category,
      matches,
    };
  });

  return res as CategoryMatches[];
};

export const getDistricts = createAsyncThunk('search/getDistricts', async () => {
  const response = await API.get(`districts`);
  return response.data.data.tableBody as District[];
});
