import styled from 'styled-components/macro';

export const StyledTabContainer = styled.div`
  margin: 8.7rem 2.4rem 0;
`;

export const StyledLayout = styled.div`
  max-width: 144rem;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;
