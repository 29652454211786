import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { contactsTableMapper } from './contactsTableMapper';
import {
  TableProvidersProps,
  useTableContexts,
  withTableProviders,
} from 'hocs/composedTableProviders/composedTableProviders';
import { StoreType } from 'store';
import { TableGrid } from 'components/Table/TableGrid/TableGrid';
import {
  getContactsCSV,
  searchAllContacts,
  searchContacts,
} from 'ducks/powerSearch/contacts/actions';
import {
  StyledColumn,
  StyledContainer,
  StyledTableBottomPanel,
} from 'containers/companyDetails/Visits/styles';
import { TableFilters } from 'components/TableFilters/TableFilters';
import { PaginationComposed } from 'components/Pagination/Pagination';
import { CommonActionsPanel } from 'components/CommonActionsPanel/CommonActionsPanel';
import { ButtonWrapper } from 'components/ButtonWrapper/ButtonWrapper';
import { useLoadingContext } from 'contexts/LoadingContext';
import { StyledCardMax } from 'components/Card/styles';
import { SearchContactsEntity, SearchContactsParams } from 'ducks/powerSearch/contacts/types';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useModal } from 'components/Modal/Modal';
import { SaveSearch } from './resultsModals';
import { getActiveFiltersState } from 'utils';
import { resetSearchResults } from 'ducks/powerSearch/actions';
import {
  AddEditContactModal,
  DeleteContactModal,
  PreviewContactModal,
  SendEmailsModal,
} from 'containers/companyDetails/Contacts/ContactsModals';
import { getCompanyContactRoles } from 'ducks/contacts/actions';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { checkIsIpad } from 'utils/deviceChecker';

import { ReactComponent as BookmarkIcon } from 'images/bookmarkIcon.svg';
import { ReactComponent as EnvelopeIcon } from 'images/envelopeIcon.svg';
import { toast } from 'react-hot-toast';
import { clearContactsSearchError } from 'ducks/powerSearch/contacts/reducer';

const filtersNamesMapper = (filter: string) => {
  if (filter === 'email') return 'email_present';
  if (filter === 'phone') return 'phone_present';
  return filter;
};

const ContactsResults: FC<TableProvidersProps> = (props) => {
  const results = useSelector((state: StoreType) => state.powerSearch.contacts);
  const { query } = useSelector((state: StoreType) => state.powerSearch);
  const {
    deleteSelection,
    filtersState,
    itemsSelected,
    setContextName,
    addMultipleSelection,
    filterParams,
  } = useTableContexts();
  const { setLoaded, setLoading } = useLoadingContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showModal, setModalComponent, setShowHeader } = useModal();

  useEffect(() => {
    setContextName('powerContacts');
  }, []);

  useEffect(() => {
    dispatch(getCompanyContactRoles());
    return () => {
      dispatch(resetSearchResults());
    };
  }, [dispatch, resetSearchResults]);

  const selectAllHandler = async () => {
    setLoading('powersearch');
    const params = { ...filterParams, ...props.fetchParams } as unknown as SearchContactsParams;
    const response = await searchAllContacts(params);
    addMultipleSelection(
      response.data.tableBody.map((item) => ({
        entity: item,
        id: item.id,
      })),
    );
    setLoaded('powersearch');
  };

  const editHandler = (entity: SearchContactsEntity) => {
    setModalComponent(<AddEditContactModal contact={entity} />);
    setShowHeader(false);
    showModal();
  };

  const showHandler = (entity: SearchContactsEntity) => {
    setModalComponent(<PreviewContactModal itemsSelected={[entity]} />);
    setShowHeader(true);
    showModal();
  };

  const deleteHandler = (entity: SearchContactsEntity) => {
    setModalComponent(
      <DeleteContactModal
        actionCallback={() => deleteSelection(entity.id)}
        itemsSelected={[entity]}
      />,
    );
    setShowHeader(false);
    showModal();
  };

  const exportCsvHandler = async () => {
    setLoading('powersearch');
    await getContactsCSV(itemsSelected.map((item: SearchContactsEntity) => item.id));
    setLoaded('powersearch');
  };

  const actionHandlers = {
    exportCSV: exportCsvHandler,
    delete: deleteHandler,
    edit: editHandler,
    selectAll: selectAllHandler,
    show: showHandler,
  };

  if (results.error) {
    toast.error(results.error);
    dispatch(clearContactsSearchError());
  }

  const isIpad = checkIsIpad();

  return (
    // @ts-ignore
    <StyledContainer isIpad={isIpad}>
      <StyledCardMax>
        <StyledColumn>
          <TableFilters isSearchPagination />
          <TableGrid
            actionHandlers={actionHandlers}
            addButton={
              <DefaultButton
                icon={<BookmarkIcon />}
                label={t(`power_search.save_search`)}
                onClick={() => {
                  setModalComponent(
                    <SaveSearch
                      params={{ state: getActiveFiltersState(filtersState), query, tag: 'contact' }}
                    />,
                  );
                  setShowHeader(false);
                  showModal();
                }}
              />
            }
            allFilteredOutMessage={t(`company_details.contacts.all_filtered_out`)}
            bodyData={results.data.tableBody}
            columnsOrder={results.meta.columnsOrder}
            componentsMap={contactsTableMapper}
            isSearchPagination
            loadResource='powersearch'
            noResultsMessage={t(`company_details.contacts.no_results`)}
            totalCount={results.meta.totalCount}
            withCheckbox
            withFullWidth
          />
          <StyledTableBottomPanel>
            <PaginationComposed loadResource='powersearch' />
            <ButtonWrapper align='right'>
              <CommonActionsPanel actionHandlers={actionHandlers} />
              <Tooltip label={t('company_details.contacts.send_selected')}>
                <DefaultButton
                  icon={<EnvelopeIcon />}
                  isDisabled={itemsSelected.length === 0}
                  onClick={() => {
                    setModalComponent(
                      <SendEmailsModal
                        itemsSelected={itemsSelected
                          .map((item: SearchContactsEntity) => item.email)
                          .filter(Boolean)}
                      />,
                    );
                    setShowHeader(false);
                    showModal();
                  }}
                  variant='secondary'
                />
              </Tooltip>
            </ButtonWrapper>
          </StyledTableBottomPanel>
        </StyledColumn>
      </StyledCardMax>
    </StyledContainer>
  );
};

const ResultsComposed = withTableProviders(ContactsResults, {
  fetchFunction: searchContacts,
  filtersNamesMapper,
  loadResource: 'powersearch',
  metaUrl: 'power/contacts',
  spyDistrict: true,
  spyQuery: true,
});

export { ResultsComposed as ContactsResults };
