import { StyledWrapper } from 'components/TextInput/styles';
import styled from 'styled-components/macro';

export const StyledNumberFieldWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  width: min-content;
  position: relative;
`;

export const StyledTextInputWrapper = styled.div<{ digitsLimit?: number; isMobile: boolean }>`
  ${StyledWrapper} {
    padding: ${({ theme }) => `${theme.sizes.xxs} ${theme.sizes.xs}`};
    width: ${({ digitsLimit, isMobile }) =>
      digitsLimit ? `calc(${isMobile ? '1.8rem' : '3rem'} + ${digitsLimit}ch)` : '6rem'};
    & input {
      text-align: ${({ isMobile }) => (isMobile ? 'center' : 'left')};
    }
  }
`;

export const StyledMobileArrow = styled.div<{ hidden: boolean }>`
  color: ${({ theme }) => theme.colors.black700};
  display: flex;
  left: 0;
  position: absolute;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  width: 100%;

  & button {
    width: 100%;
  }
`;

export const StyledMobileUp = styled(StyledMobileArrow)`
  top: 0;
  transform: translate(0, -100%);
  z-index: 1;
`;

export const StyledMobileDown = styled(StyledMobileArrow)`
  bottom: 0;
  transform: translate(0, 100%);
  z-index: 1;
`;

export const StyledSmallNumberFieldArrows = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);

  & button {
    height: 12px;
    width: 12px;

    &:hover svg {
      color: ${({ theme }) => theme.colors.blue200};
    }
  }
  & svg {
    color: ${({ theme }) => theme.colors.black100};
    height: 12px;
    width: 12px;
  }
`;
