import { createSlice } from '@reduxjs/toolkit';

import {
  CrmEventsEntity,
  CrmEventsEntityResponse,
  EventsState,
  RvoEventsEntity,
  RvoEventsEntityResponse,
} from 'ducks/events/types';
import {
  createRvoEvent,
  deleteRvoEvent,
  getCrmEvents,
  getRvoEvents,
  updateRvoEvent,
  updateRvoEventTips,
} from './actions';

const initialState: EventsState = {
  crm: {
    data: {
      tableBody: [],
    },
    error: undefined,
    meta: {
      columnsOrder: [],
      page: 1,
      filters: {},
      pageSize: 10,
      totalCount: 1,
      totalPages: 1,
    },
  },
  rvo: {
    data: {
      tableBody: [],
    },
    error: undefined,
    meta: {
      columnsOrder: [],
      page: 1,
      filters: {},
      pageSize: 10,
      totalCount: 1,
      totalPages: 1,
    },
  },
};

const amendCrmEntity = (entity: CrmEventsEntityResponse): CrmEventsEntity => {
  delete entity.actions;
  return {
    ...entity,
    id: entity.crmEventId,
  };
};

const amendRvoEntity = (entity: RvoEventsEntityResponse): RvoEventsEntity => ({
  ...entity,
  id: entity.revisedActivityId,
});

const contactsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    resetEvents: () => initialState,
    clearEventsError: (state) => ({ ...state, error: undefined }),
  },
  extraReducers: (builder) => {
    builder.addCase(getCrmEvents.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(getCrmEvents.fulfilled, (state, { payload }) => {
      state.crm.data.tableBody = payload.data.tableBody.map(amendCrmEntity);
      state.crm.meta = payload.meta;
    });
    builder.addCase(getRvoEvents.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(getRvoEvents.fulfilled, (state, { payload }) => {
      state.rvo.data.tableBody = payload.data.tableBody.map(amendRvoEntity);
      state.rvo.meta = payload.meta;
    });
    builder.addCase(deleteRvoEvent.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(deleteRvoEvent.fulfilled, (state, { meta }) => {
      state.rvo.data.tableBody = state.rvo.data.tableBody.filter((event) => event.id !== meta.arg);
      if (typeof state.rvo.meta.totalCount === 'number') --state.rvo.meta.totalCount;
    });
    builder.addCase(createRvoEvent.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(createRvoEvent.fulfilled, (state, { payload }) => {
      if (!payload || !payload.browsedCompany) return state;
      state.rvo.data.tableBody = [amendRvoEntity(payload), ...state.rvo.data.tableBody];
      if (typeof state.rvo.meta.totalCount === 'number') ++state.rvo.meta.totalCount;
    });
    builder.addCase(updateRvoEvent.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(updateRvoEvent.fulfilled, (state, { payload }) => {
      state.rvo.data.tableBody = state.rvo.data.tableBody.reduce(
        (acc: RvoEventsEntity[], cur) =>
          cur.revisedActivityId === payload.revisedActivityId
            ? [...acc, amendRvoEntity(payload)]
            : [...acc, cur],
        [],
      );
    });
    builder.addCase(updateRvoEventTips.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(updateRvoEventTips.fulfilled, (state, { meta, payload }) => {
      const revisedActivity = state.rvo.data.tableBody.find(
        (event) => event.id === meta.arg.revisedActivityId,
      );
      if (revisedActivity) {
        revisedActivity.tipsExternalId = payload.id;
      }
    });
  },
});

const {
  reducer: eventsReducer,
  actions: { resetEvents, clearEventsError },
} = contactsSlice;

export { eventsReducer, resetEvents, clearEventsError };
