import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import {
  StyledDescription,
  StyledError,
  StyledFieldWrapper,
  StyledLabel,
} from 'components/Forms/styles';
import { StyledCalendar, StyledCalendarWrapper } from 'components/DatePicker/styles';
import { formatMonth, formatMonthYear, formatWeekdayShort } from 'utils/date';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';

import { ReactComponent as ArrowLeftBigIcon } from 'images/arrowLeftBigIcon.svg';
import { ReactComponent as ArrowRightBigIcon } from 'images/arrowRightBigIcon.svg';

interface EmbededDatepickerFieldPropTypes extends FieldRenderProps<string> {
  label?: string;
  required?: boolean;
  extraOptions?: object;
}

export const EmbededDatepickerField = ({
  description,
  extraOptions,
  input,
  label,
  meta,
  required,
}: EmbededDatepickerFieldPropTypes) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(input.value ? new Date(input.value) : undefined);

  const onChange = (value: Date | Date[]) => {
    input?.onChange(value);
    setDate(value as Date);
  };

  return (
    <StyledFieldWrapper>
      {label && (
        <StyledLabel>
          {label}
          {required ? '*' : ''}
        </StyledLabel>
      )}

      <StyledCalendarWrapper>
        <StyledCalendar
          formatMonth={(locale: string, day: Date) => formatMonth(day)}
          formatMonthYear={(locale: string, day: Date) => formatMonthYear(day)}
          formatShortWeekday={(locale: string, day: Date) => formatWeekdayShort(day)}
          minDetail='decade'
          next2Label={null}
          nextLabel={<ArrowRightBigIcon />}
          onChange={onChange}
          prev2Label={null}
          prevLabel={<ArrowLeftBigIcon />}
          value={date}
          {...extraOptions}
        />
        <DefaultButton
          capitalize
          label={t('common.today')}
          onClick={() => onChange(new Date())}
          type='button'
          variant='tertiary'
          width='fullWidth'
        />
      </StyledCalendarWrapper>
      {description && <StyledDescription>{description}</StyledDescription>}
      {meta.touched && meta.error && <StyledError>{meta.error}</StyledError>}
      <input name={input.name} onChange={input.onChange} type='hidden' value={input.value} />
    </StyledFieldWrapper>
  );
};
