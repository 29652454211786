import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useSelector } from 'react-redux';

import { NewVisitNavbar } from '../NewVisitNavbar';
import { ContentContainer } from 'styles/utils';
import { getCompanyContactRoles, getWorkplaceContactRoles } from 'ducks/contacts/actions';
import {
  getChecklists,
  getCompanyRoles,
  getIndustryBranches,
  getMeasures,
  getMeasureTypes,
  getStandardCommentCategories,
  getTipsThemes,
} from 'ducks/constants/actions';
import { currentUserSelector } from 'ducks/users/selectors';
import { LoadingWrapper, NewVisitCard } from 'containers/newVisit/OrdinaryVisit/styles';
import { getUsers } from 'ducks/users/actions';
import { getVisitById } from 'ducks/newVisit';
import { useAppDispatch } from 'store';
import { constantsSelector } from 'ducks/constants/selectors';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';

export const OrdinaryVisit: FC<RouteComponentProps<{ id: string }>> = (props) => {
  const { children, id } = props;
  const dispatch = useAppDispatch();
  const user = useSelector(currentUserSelector);
  const isHrrBranch = window.REACT_APP_ENV?.BRAND_ID === 'hrr';
  const [loading, setLoading] = useState(true);
  const {
    checklistsStatus,
    measuresStatus,
    measureTypesStatus,
    standardCommentsStatus,
    industryBranchesStatus,
  } = useSelector(constantsSelector);

  useEffect(() => {
    const fetch = async () => {
      id && (await dispatch(getVisitById({ id })));
      dispatch(getWorkplaceContactRoles()); // TODO add conditionally fetch
      dispatch(getCompanyContactRoles()); // TODO add conditionally fetch
      dispatch(getCompanyRoles()); // TODO add conditionally fetch
      checklistsStatus !== 'resolved' &&
        user?.industryId &&
        dispatch(getChecklists(user?.industryId));
      dispatch(getUsers()); // TODO add conditionally fetch
      measuresStatus !== 'resolved' && user?.industryId && dispatch(getMeasures(user?.industryId));
      measureTypesStatus !== 'resolved' && dispatch(getMeasureTypes());
      standardCommentsStatus !== 'resolved' &&
        user?.industryId &&
        dispatch(getStandardCommentCategories(user?.industryId));
      isHrrBranch &&
        industryBranchesStatus !== 'resolved' &&
        user?.industryId &&
        dispatch(getIndustryBranches(user?.industryId));
      await dispatch(getTipsThemes());
      setLoading(false);
    };

    if (id !== '0') {
      fetch();
    } else {
      setLoading(false);
    }
  }, [id]);

  if (loading)
    return (
      <LoadingWrapper>
        <LoadingSpinner />
      </LoadingWrapper>
    );

  return (
    <>
      <NewVisitNavbar user={user} />
      <NewVisitCard id='new-visit-card'>
        <ContentContainer maxWidth='110rem'>{children}</ContentContainer>
      </NewVisitCard>
    </>
  );
};
