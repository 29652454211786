import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { RouteComponentProps } from '@reach/router';

import { CardSmall } from 'components/Card/Card';
import { TableGrid } from 'components/Table/TableGrid/TableGrid';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { PaginationComposed } from 'components/Pagination/Pagination';
import { TableFilters } from 'components/TableFilters/TableFilters';
import { CommonActionsPanel } from 'components/CommonActionsPanel/CommonActionsPanel';
import {
  getAllCompanyContacts,
  getCompanyContactRoles,
  getCompanyContacts,
  getContactsCSV,
} from 'ducks/contacts/actions';
import {
  ContactEntity,
  ContactEntityResponse,
  GetCompanyContactsParams,
} from 'ducks/contacts/types';
import { useLoadingContext } from 'contexts/LoadingContext';
import { useModal } from 'components/Modal/Modal';
import {
  StyledColumn,
  StyledContainer,
  StyledEmptyVisits,
  StyledEmptyVisitsWrap,
  StyledTableBottomPanel,
} from 'containers/companyDetails/Visits/styles';
import { StoreType } from 'store';
import {
  AddEditContactModal,
  DeleteContactModal,
  PreviewContactModal,
  SendEmailsModal,
} from './ContactsModals';
import { ButtonWrapper } from 'components/ButtonWrapper/ButtonWrapper';
import { clearContactsError, resetContactsReducer } from 'ducks/contacts/reducer';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { contactsComponentsMap } from './tableComponentsMap';
import {
  TableProvidersProps,
  useTableContexts,
  withTableProviders,
} from 'hocs/composedTableProviders/composedTableProviders';

import { ReactComponent as PlusIcon } from 'images/plusIcon.svg';
import { ReactComponent as EnvelopeIcon } from 'images/envelopeIcon.svg';
import { ReactComponent as DeleteIcon } from 'images/deleteIcon.svg';
import { toast } from 'react-hot-toast';

interface ContactsProps extends RouteComponentProps, TableProvidersProps {
  companyId?: string;
}

const filtersNamesMapper = (filter: string) => {
  if (filter === 'email') return 'email_present';
  if (filter === 'phone') return 'phone_present';
  return filter;
};

const Contacts: FC<ContactsProps> = (props) => {
  const contacts = useSelector((state: StoreType) => {
    return state.contacts;
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setLoaded, setLoading } = useLoadingContext();
  const { setModalComponent, showModal, setShowHeader } = useModal();
  const {
    setContextName,
    itemsSelected,
    addMultipleSelection,
    commonAction,
    deleteSelection,
    filterParams,
  } = useTableContexts();

  useEffect(() => {
    setContextName('contacts');
    dispatch(getCompanyContactRoles());
    return () => {
      setContextName(null);
      dispatch(resetContactsReducer());
    };
  }, []);

  // --- table row actions handlers ---

  const selectAllHandler = async () => {
    setLoading('company-contact');
    const response = await getAllCompanyContacts({
      ...(props.fetchParams as unknown as GetCompanyContactsParams),
      ...filterParams,
    });
    addMultipleSelection(
      response.data.tableBody.map((item: ContactEntityResponse) => ({
        entity: item,
        id: item.id,
      })),
    );
    setLoaded('company-contact');
  };

  const editHandler = (entity: ContactEntity) => {
    setModalComponent(<AddEditContactModal contact={entity} />);
    setShowHeader(false);
    showModal();
  };

  const showHandler = (entity: ContactEntity) => {
    setModalComponent(<PreviewContactModal itemsSelected={[entity]} />);
    setShowHeader(true);
    showModal();
  };

  const modalHandler = (entity: ContactEntity) => {
    setModalComponent(
      <DeleteContactModal
        actionCallback={() => deleteSelection(entity.id)}
        itemsSelected={[entity]}
      />,
    );
    setShowHeader(false);
    showModal();
  };

  const exportCsvHandler = async () => {
    setLoading('company-contact');
    await getContactsCSV(itemsSelected.map((item: ContactEntity) => item.id));
    setLoaded('company-contact');
  };

  const actionHandlers = {
    exportCSV: exportCsvHandler,
    delete: modalHandler,
    edit: editHandler,
    show: showHandler,
    selectAll: selectAllHandler,
  };

  if (contacts.error) {
    toast.error(contacts.error);
    dispatch(clearContactsError());
  }

  if (isEmpty(contacts.meta.columnsOrder)) return null;

  if (isEmpty(contacts.data.tableBody) && isEmpty(filterParams)) {
    return (
      <CardSmall>
        <StyledEmptyVisitsWrap>
          <StyledEmptyVisits>
            <div>{t('company_details.contacts.no_visits')}</div>
            <div>
              <DefaultButton
                icon={<PlusIcon />}
                label={t('company_details.contacts.new_contact')}
                onClick={() => {
                  setModalComponent(<AddEditContactModal />);
                  setShowHeader(false);
                  showModal();
                }}
              />
            </div>
          </StyledEmptyVisits>
        </StyledEmptyVisitsWrap>
      </CardSmall>
    );
  }

  return (
    <StyledContainer>
      <CardSmall>
        <StyledColumn>
          <TableFilters />
          <TableGrid
            actionHandlers={actionHandlers}
            addButton={
              <DefaultButton
                icon={<PlusIcon />}
                label={t('company_details.contacts.new_contact')}
                onClick={() => {
                  setModalComponent(<AddEditContactModal />);
                  setShowHeader(false);
                  showModal();
                }}
              />
            }
            allFilteredOutMessage={t('company_details.contacts.all_filtered_out')}
            bodyData={contacts.data.tableBody}
            columnsOrder={contacts.meta.columnsOrder}
            componentsMap={contactsComponentsMap}
            loadResource='company-contact'
            resultsSelector='company_details.contacts'
            totalCount={contacts.meta.totalCount}
            withCheckbox
          />
          <StyledTableBottomPanel>
            <PaginationComposed loadResource='company-contact' />
            <ButtonWrapper align='right'>
              <CommonActionsPanel actionHandlers={actionHandlers}>
                <>
                  <Tooltip label={t('company_details.contacts.send_selected')}>
                    <DefaultButton
                      icon={<EnvelopeIcon />}
                      isDisabled={itemsSelected.length === 0}
                      onClick={() => {
                        setModalComponent(
                          <SendEmailsModal
                            companyId={props.companyId!}
                            itemsSelected={itemsSelected
                              .map((item: ContactEntity) => item.email)
                              .filter(Boolean)}
                          />,
                        );
                        setShowHeader(false);
                        showModal();
                      }}
                      variant='secondary'
                    />
                  </Tooltip>
                  <Tooltip label={t('common.delete_selected')}>
                    <DefaultButton
                      icon={<DeleteIcon />}
                      isDisabled={!itemsSelected.length}
                      onClick={commonAction ? commonAction.action : () => {}}
                      variant='secondary'
                    />
                  </Tooltip>
                </>
              </CommonActionsPanel>
            </ButtonWrapper>
          </StyledTableBottomPanel>
        </StyledColumn>
      </CardSmall>
    </StyledContainer>
  );
};

const ContactsComposed = withTableProviders(Contacts, {
  fetchFnExtraParams: (props) => ({ company_id: props.companyId }),
  fetchFunction: getCompanyContacts,
  filtersNamesMapper,
  loadResource: 'companies',
  metaUrl: 'company_contacts',
});

export { ContactsComposed as Contacts };
