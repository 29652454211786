import * as React from 'react';
import { capitalize, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios, { CancelTokenSource } from 'axios';
import { Field, Form, FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { unwrapResult } from '@reduxjs/toolkit';

import { StyledModalButtonsWrapper, StyledModalHeader, useModal } from 'components/Modal/Modal';
import { TipsATForRevisedActivities } from 'dialogs/RevisedActivities/TipsATForRevisedActivities';
import { getTipsThemes } from 'ducks/constants/actions';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useLoadingContext } from 'contexts/LoadingContext';
import { Attachment, RvoEventsEntity, RvoEventsEntityResponse } from 'ducks/events/types';
import { formatDate } from 'utils/date';
import { createRvoEvent, updateRvoEvent } from 'ducks/events/actions';
import { StyledForm } from 'components/Forms/styles';
import { SingleSelectField } from 'components/Forms/SingleSelectField/SingleSelectField';
import { yupValidate } from 'utils/yupValidate';
import { TextareaField } from 'components/Forms/TextareaField/TextareaField';
import { DatepickerField } from 'components/Forms/DatepickerField/DatepickerField';
import { AttachmentField } from 'components/Forms/AttachmentField/AttachmentField';
import { AttachmentsToRemoveField } from 'components/Forms/AttachmentsToRemoveField/AttachmentsToRemoveField';
import { DivCenter } from 'styles/utils';
import {
  addOrEditRevisedActivitySchema,
  fetchCompanies,
  fetchContacts,
} from 'dialogs/RevisedActivities/helpers';
import { SpinnerIcon } from 'utils/iconsMap';
import { useAppDispatch } from 'store';
import { getEventTypes } from 'ducks/constants/actions';
import { constantsSelector, revisedActivityTypesOptionsSelector } from 'ducks/constants/selectors';
import { AsyncMultiSelectField } from 'components/Forms/AsyncMultiSelectField/AsyncMultiSelectField';
import { CheckboxField } from 'components/Forms/CheckboxField/CheckboxField';
import { CompanyVisitsField } from 'dialogs/RevisedActivities/CompanyVisitsField';
import { fetchCompany } from 'ducks/companies/actions';
import { CompanyEntity } from 'ducks/companies/types';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { InfoBox } from 'components/InfoBox/InfoBox';
import styled from 'styled-components/macro';
import { SubmitRevisedActivityDialogResponse } from 'ducks/powerSearch/revisedActivities/types';
import { createRevisedActivityDialogPath } from 'ducks/powerSearch/revisedActivities/actions';
import { TimePickerField } from 'components/Forms/TimePickerField/TimePickerField';
import { add30MinutesToTime, getHoursAndMinutes, mergeTimeAndDate } from 'utils/helpers';
import toast from 'react-hot-toast';

const HorizontalWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.25fr auto 1.25fr auto;
  align-items: center;

  & > div:nth-child(2) {
    margin-right: 20px;
  }
`;

const StyledInfoBox = styled(InfoBox)`
  align-items: center;
  & > div {
    align-items: center;
    display: flex;
  }
`;

const StyledInfoBoxButton = styled(DefaultButton)`
  margin-left: ${({ theme }) => theme.sizes.xs};
`;

interface Option {
  label: string;
  value: string;
}

interface AddOrEditRevisedActivityProps {
  event?: RvoEventsEntity;
  defaultCompany?: Option[];
  isCompaniesFieldVisible?: boolean;
}

interface Type extends Option {
  subTypes: Option[];
}

interface AddEditFormValues {
  attachments_to_remove?: null | number[];
  attachments?: Blob[];
  companies: Option[];
  contacts: Option[];
  date?: string;
  time?: string;
  to_date?: string;
  to_time?: string;
  description?: string;
  search_within_companies: boolean;
  subtype?: Option;
  type?: Type;
  visits: string[];
  tips: boolean;
}

export const AddOrEditRevisedActivity = (props: AddOrEditRevisedActivityProps): JSX.Element => {
  const { defaultCompany, event, isCompaniesFieldVisible = true } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [tipsLoading, setTipsLoading] = React.useState(false);
  const { setLoaded, setLoading, isLoading } = useLoadingContext();
  const { hideModal, setModalComponent, showModal, setShowHeader } = useModal();
  const companiesCancelToken = React.useRef<CancelTokenSource | null>(null);
  const contactsCancelToken = React.useRef<CancelTokenSource | null>(null);

  const isEdit = !!event;

  const typeOptions = useSelector(revisedActivityTypesOptionsSelector);
  const { revisedActivityTypesStatus } = useSelector(constantsSelector);

  React.useEffect(() => {
    if (revisedActivityTypesStatus !== 'resolved') dispatch(getEventTypes());
    const fetch = async () => await dispatch(getTipsThemes());
    fetch();
  }, [typeOptions]);

  const companiesFetchFunction = async (query: string) => {
    companiesCancelToken.current?.cancel();
    const CancelToken = axios.CancelToken;
    companiesCancelToken.current = CancelToken.source();
    try {
      const response = await fetchCompanies(query, companiesCancelToken.current);
      return response;
    } catch (e) {
      toast.error((e as Error)?.message ?? t('common.error_message'));
      return [];
    }
  };

  const contactsFetchFunction = React.useCallback((query: string, companyNames?: string[]) => {
    contactsCancelToken.current?.cancel();
    const CancelToken = axios.CancelToken;
    contactsCancelToken.current = CancelToken.source();

    return fetchContacts(query, companyNames, contactsCancelToken.current);
  }, []);

  const preselectedVisitOptions = React.useMemo(
    () =>
      event?.visits.map(({ id, date, workplace, status, rvo }) => ({
        additionalLabel: [capitalize(t(`filters.${status}`)), rvo].filter(Boolean).join(', '),
        label: `${formatDate(date)} - ${workplace ? workplace : t('workplace.no_workplace')}`,
        value: String(id),
      })),
    [],
  );

  const tipsATButton = React.useCallback(
    (id: string, comment: string, company: CompanyEntity, attachments: Attachment[]) => {
      setModalComponent(
        <TipsATForRevisedActivities
          activityDescription={comment}
          attachments={attachments}
          revisedActivityId={id!}
          selectedCompany={company}
        />,
      );
      setShowHeader(false);
      showModal();
    },
    [],
  );

  const saveAndTips = async (formData: FormData) => {
    try {
      const response = await dispatch(isEdit ? updateRvoEvent(formData) : createRvoEvent(formData));

      if (response.meta.requestStatus === 'rejected') {
        throw new Error(response.payload);
      }

      const data: RvoEventsEntityResponse | RvoEventsEntity = unwrapResult(response);
      const selectedCompany = await fetchCompany(data.companies[0].companyId);
      tipsATButton(data.revisedActivityId, data.description, selectedCompany, data.attachments);
    } catch (error) {
      toast.error((error as Error)?.message ?? t('common.error_message'));
    } finally {
      setLoaded('rvo-event-submit');
    }
  };

  const saveAndClose = async (formData: FormData) => {
    try {
      const response = await dispatch(isEdit ? updateRvoEvent(formData) : createRvoEvent(formData));

      if (response.meta.requestStatus === 'rejected') {
        throw new Error(response.payload);
      }

      hideModal();
    } catch (error) {
      toast.error((error as Error)?.message ?? t('common.error_message'));
    } finally {
      setLoaded('rvo-event-submit');
    }
  };

  const onSubmit = React.useCallback(
    async (values: AddEditFormValues) => {
      setLoading('rvo-event-submit');
      const formData = new FormData();

      formData.append(
        'date',
        values.time
          ? new Date(mergeTimeAndDate(values.date!, values.time)).toUTCString()
          : new Date(values.date!).toUTCString(),
      );
      formData.append(
        'to_date',
        values.to_time
          ? new Date(mergeTimeAndDate(values.to_date!, values.to_time!)).toUTCString()
          : new Date(values.to_date!).toUTCString(),
      );
      formData.append('description', values.description as string);
      formData.append('type', (values.type as Option).value);
      formData.append('subtype', (values.subtype as Option)?.value || '');
      event?.id && formData.append('id', event.id);
      values.attachments?.forEach((attachment) => formData.append('attachments[]', attachment));
      values.companies.forEach((company) => formData.append('company_ids[]', company.value));
      values.contacts.forEach((contact) => formData.append('contact_ids[]', contact.value));
      values.visits.forEach((visit) => formData.append('visit_ids[]', visit));
      values.attachments_to_remove?.forEach((attachment) =>
        formData.append('attachment_ids_to_remove[]', String(attachment)),
      );

      if (values.tips) {
        saveAndTips(formData);
      } else {
        saveAndClose(formData);
      }
    },
    [dispatch, event],
  );

  const initialDateSetter = (date: string | undefined | null) => {
    switch (date) {
      case undefined:
        return new Date().toISOString().split('T')[0];
      case null:
        return '';
      default:
        return date;
    }
  };

  const initialTimeSetter = (
    targetDate: string | undefined | null,
    dateToCompare: string | undefined | null,
  ) => {
    const targetTime = targetDate ? new Date(targetDate).toLocaleTimeString() : '';
    const timeToCompare = dateToCompare ? new Date(dateToCompare).toLocaleTimeString() : '';

    if (targetTime === timeToCompare || targetTime === '' || timeToCompare === '') return '';

    return getHoursAndMinutes(targetDate!);
  };

  const initialValues = {
    type: event?.type ? typeOptions.find(({ value }) => value === event.type) : undefined,
    subtype: event?.subtype ? { label: event.subtype, value: event.subtype } : undefined,
    description: event?.description || '',
    date: initialDateSetter(event?.date),
    to_date: initialDateSetter(event?.toDate),
    time: initialTimeSetter(event?.date, event?.toDate),
    to_time: initialTimeSetter(event?.toDate, event?.date),
    attachments: [],
    attachments_to_remove: null,
    search_within_companies: true,
    companies:
      event?.companies.map(({ companyId, companyName }) => ({
        label: companyName,
        value: String(companyId),
      })) ||
      defaultCompany ||
      [],
    contacts: event?.contacts.map(({ id, name }) => ({ label: name, value: id })) || [],
    visits: event?.visits.map(({ id }) => String(id)) || [],
    tips: false,
  };

  const tipsDialog = async () => {
    setTipsLoading(true);

    if (event?.revisedActivityId) {
      const dialogPath = (await dispatch(
        createRevisedActivityDialogPath({ revisedActivityId: event.revisedActivityId }),
      )) as { payload: SubmitRevisedActivityDialogResponse };

      if (dialogPath?.payload?.url) {
        window.open(dialogPath.payload.url, '_blank');
      }
    }

    setTipsLoading(false);
  };

  if (revisedActivityTypesStatus !== 'resolved')
    return (
      <DivCenter>
        <SpinnerIcon />
      </DivCenter>
    );

  return (
    <>
      <StyledModalHeader>
        {t(isEdit ? 'company_details.events.edit_a_event' : 'company_details.events.add_event')}
      </StyledModalHeader>
      {event && event.tipsIdPresent && (
        <StyledInfoBox
          message={
            <>
              {t('new_visits.sent_to_at', { tipsExternalId: event.tipsExternalId })}
              <StyledInfoBoxButton
                capitalize
                icon={tipsLoading ? <LoadingSpinner small /> : ''}
                isDisabled={tipsLoading}
                label={t('new_visits.open_dialog')}
                onClick={tipsDialog}
                size='small'
                testid='status-section-dialog-btn'
                type='button'
                variant='tertiary'
              />
            </>
          }
        />
      )}
      <Form
        keepDirtyOnReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, values, form }) => (
          <StyledForm onSubmit={handleSubmit}>
            <HorizontalWrapper>
              <Field
                component={DatepickerField}
                label={t('table.columns.from_date')}
                name='date'
                required
                subscription={{ value: true, touched: true, error: true }}
                width='fullWidth'
              />
              <OnChange name='date'>
                {(value: string) => {
                  form.change('to_date', value);
                }}
              </OnChange>
              <Field
                component={TimePickerField}
                label={t('table.columns.time')}
                name='time'
                subscription={{ value: true, touched: true, error: true }}
                width='fullWidth'
              />
              <OnChange name='time'>
                {(value: string) => {
                  form.change('to_time', add30MinutesToTime(value));
                }}
              </OnChange>
              <Field
                component={(props) => (
                  <DatepickerField {...props} minDate={new Date(values.date)} />
                )}
                label={t('table.columns.to_date')}
                name='to_date'
                required
                subscription={{ value: true, touched: true, error: true }}
                width='fullWidth'
              />
              <Field
                component={TimePickerField}
                disabled={!values.time}
                label={t('table.columns.time')}
                name='to_time'
                subscription={{ value: true, touched: true, error: true }}
                width='fullWidth'
              />
            </HorizontalWrapper>
            <Field
              component={SingleSelectField}
              label={t('table.columns.type')}
              name='type'
              options={typeOptions}
              placeholder={`${t('forms.select_a')} ${t('forms.type')}`}
              required
            />
            <Field
              component={SingleSelectField}
              isDisabled={!values.type || isEmpty(values.type?.subTypes)}
              label={t('table.columns.subtype')}
              name='subtype'
              options={values.type?.subTypes || []}
              placeholder={`${t('forms.select_a')} ${t('table.columns.subtype')}`}
              withClearButton
            />
            {isCompaniesFieldVisible && (
              <Field
                component={AsyncMultiSelectField}
                fetchFunction={companiesFetchFunction}
                label={t('table.columns.company_names')}
                name='companies'
              />
            )}

            <div>
              <Field
                component={CheckboxField}
                label={t('company_details.events.search_within_companies')}
                name='search_within_companies'
              />

              <Field
                component={AsyncMultiSelectField}
                fetchFunction={(query: string) => {
                  return contactsFetchFunction(
                    query,
                    values.search_within_companies
                      ? values.companies.map(({ label }) => label)
                      : undefined,
                  );
                }}
                label={t('table.columns.contacts')}
                name='contacts'
              />
            </div>
            <CompanyVisitsField
              preselectedVisitOptions={preselectedVisitOptions}
              selectedCompanies={values.companies}
            />
            <Field
              characterLimit={1000}
              component={TextareaField}
              label={t('table.columns.description')}
              maxRows={16}
              name='description'
              placeholder={t('forms.write_a_message')}
              required
            />
            <Field component={AttachmentField} label={t('forms.attachments')} name='attachments' />
            <Field
              attachmentList={event?.attachments || []}
              component={AttachmentsToRemoveField}
              label={t('forms.uploaded_attachments')}
              name='attachments_to_remove'
            />
            <StyledModalButtonsWrapper>
              <DefaultButton
                capitalize
                isDisabled={isLoading('rvo-event-submit')}
                label={t('common.cancel')}
                onClick={hideModal}
                type='button'
                variant='tertiary'
              />
              <FormSpy subscription={{ errors: true }}>
                {({ errors }) => (
                  <>
                    <DefaultButton
                      capitalize
                      icon={isLoading('rvo-event-submit') ? <SpinnerIcon /> : null}
                      isDisabled={isLoading('rvo-event-submit')}
                      label={t(isEdit ? 'forms.save_changes' : 'company_details.events.save_event')}
                      onClick={() => form.change('tips', false)}
                      testid='submit-add-edit-rvo-form'
                      type='submit'
                    />
                    <DefaultButton
                      icon={isLoading('rvo-event-submit') ? <SpinnerIcon /> : null}
                      isDisabled={
                        isLoading('rvo-event-submit') ||
                        values.companies.length !== 1 ||
                        (event && event.tipsIdPresent)
                      }
                      label={t('company_details.events.save_event_and_tips')}
                      onClick={() => form.change('tips', true)}
                      size='small'
                      testid='tips-at-button'
                      type='submit'
                    />
                  </>
                )}
              </FormSpy>
            </StyledModalButtonsWrapper>
          </StyledForm>
        )}
        subscription={{ values: true }}
        validate={yupValidate(addOrEditRevisedActivitySchema)}
      />
    </>
  );
};
