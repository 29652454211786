import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, isEmpty, isNumber } from 'lodash';
import { globalHistory } from '@reach/router';
import { Loader } from '@googlemaps/js-api-loader';

import { TableRow } from 'components/Table/TableRow/TableRow';
import { TableHeader } from 'components/Table/TableHeader/TableHeader';
import { useLoadingContext } from 'contexts/LoadingContext';
import { StandardLabel } from 'components/StandardLabel/StandardLabel';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useBatchActionsContext } from 'contexts/BatchActionsContext/BatchActionsContext';
import {
  Dialog,
  DialogCloseButton,
  Map,
  StyledGrid,
  StyledGridScrollContainer,
  StyledTopPanel,
  TableGridAddButton,
  TableGridLoading,
  TableGridShading,
} from './styles';
import { ActionsHandlers, TableComponentsMap, TableEntity } from 'components/Table/types';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { Margin } from 'styles/utils';
import { navigateWithState } from 'utils/navigateWithState';
import { prepareColumnsOrderProps } from 'utils/helpers';
import { Shading } from 'components/Shading/Shading';
import { SpinnerIcon } from 'utils/iconsMap';
import { updateGridSettings } from 'ducks/users/actions';
import { useDidUpdate } from 'hooks/useDidUpdate';
import { useFiltersContext } from 'contexts/FilterContext';
import { userGridPrefferencesSelector } from 'ducks/users/selectors';
import toast from 'react-hot-toast';

const CHECKBOX_WIDTH = `5rem`;
const ACTIONS_WIDTH = `auto`;
const FIREFOX_HACK = '0.3px';

interface TableGridProps<T, K> {
  actionHandlers?: ActionsHandlers;
  addButton?: ReactElement;
  allFilteredOutMessage?: string;
  bodyData: Array<K>;
  columnsOrder: (keyof T)[];
  componentsMap: T;
  isDashboard?: boolean;
  isDisabled?: boolean;
  isSearchPagination?: boolean;
  loadResource?: string;
  noConfig?: boolean;
  noResultsContent?: ReactElement;
  noResultsMessage?: string;
  resultsSelector?: string;
  totalCount?: number | null;
  withCheckbox?: boolean;
  withFullWidth?: boolean;
}

export const TableGrid = <T extends TableComponentsMap, K extends TableEntity>({
  actionHandlers: receivedActionHandlers,
  addButton,
  allFilteredOutMessage,
  bodyData = [],
  columnsOrder: defaultColumnsOrder,
  componentsMap,
  isDashboard = false,
  isDisabled = false,
  isSearchPagination = false,
  loadResource,
  noConfig = false,
  noResultsContent,
  noResultsMessage,
  resultsSelector,
  totalCount,
  withCheckbox,
  withFullWidth = false,
}: TableGridProps<T, K>) => {
  const [shading, setShading] = useState({ showLeft: false, showRight: false });
  const gridRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const filterCtx = useFiltersContext();
  const { isLoading } = useLoadingContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { itemsSelected, clearSelection, contextName } = useBatchActionsContext();
  const withActions = bodyData.some((el) => !!el.actions);
  const loading = !!loadResource && isLoading(loadResource);

  const userColumnsSettings = useSelector(userGridPrefferencesSelector);
  const { columnsOrder, columnsVisibility } = prepareColumnsOrderProps<keyof T>(
    defaultColumnsOrder || [],
    (contextName && userColumnsSettings
      ? userColumnsSettings![contextName]
      : defaultColumnsOrder) as (keyof T)[],
  );

  const showOnMap = async () => {
    if (itemsSelected.length) {
      const addresses: string[] = [];

      itemsSelected.forEach(
        (
          item: TableEntity & {
            address?: string;
            postalCode?: string;
            postalAreaName?: string;
            postalArea?: string;
            workplaceAddress?: string;
          },
        ) => {
          // Visit entity
          if ('companyVisitId' in item && 'visitId' in item) {
            item.address && addresses.push(item.address);
          }
          // Company or Workplace entity
          else if (('companyId' in item && 'orgNo' in item) || 'workplaceId' in item) {
            item?.address &&
              addresses.push(
                `${item.address}, ${item.postalCode ?? ''} ${item.postalAreaName ?? ''}`,
              );
          }
          // Prior notification entity
          else if ('id' in item && 'projectType' in item) {
            item?.workplaceAddress &&
              addresses.push(
                `${item.workplaceAddress}, ${item.postalCode ?? ''} ${item.postalArea ?? ''}`,
              );
          }
        },
      );

      if (addresses.length) {
        const loader = new Loader({
          apiKey: window.REACT_APP_ENV.GOOGLE_PLACES_API_KEY!,
          version: 'weekly',
          libraries: ['places'],
        });

        const { Map, InfoWindow } = (await loader.importLibrary('maps')) as google.maps.MapsLibrary;
        const { AdvancedMarkerElement } = (await loader.importLibrary(
          'marker',
        )) as google.maps.MarkerLibrary;

        const map = new Map(document.getElementsByClassName('markers-map')[0] as HTMLElement, {
          zoom: 1,
          center: { lat: 0, lng: 0 },
          mapId: 'markers-map',
        });

        const bounds = new google.maps.LatLngBounds();
        const infoWindow = new InfoWindow();

        for (const address of addresses) {
          const { Geocoder } = (await loader.importLibrary(
            'geocoding',
          )) as google.maps.GeocodingLibrary;

          const geocoder = new Geocoder();

          try {
            const geocodedAddress = await geocoder.geocode({ address });
            const { lat, lng } = geocodedAddress.results[0].geometry.location;

            const position = { lat: lat(), lng: lng() };
            const title = geocodedAddress.results[0].formatted_address;

            const marker = new AdvancedMarkerElement({
              map,
              position,
              title,
            });

            bounds.extend(position);

            marker.addListener('click', () => {
              const googleMapsUrl = `https://www.google.com/maps/?q=${position.lat},${position.lng}`;
              const contentString = `
                <div>
                  <strong>${title}</strong>
                  <br><br>
                  <a href="${googleMapsUrl}" target="_blank">Open in Google Maps</a>
                </div>
              `;

              infoWindow.setContent(contentString);
              infoWindow.open(map, marker);
            });
          } catch (err) {
            console.error(err);
          }
        }

        map.fitBounds(bounds);

        const dialog = document.querySelector('dialog');

        // Handle click outside of dialog
        dialog?.addEventListener('click', (e) => {
          if (e.target === dialog) {
            dialog.close();
          }
        });

        dialog?.showModal();
      } else {
        toast.error(t('common.no_address'));
      }
    }
  };

  const closeMapDialog = () => {
    const dialog = document.querySelector('dialog');
    dialog?.close();
  };

  const setColumnsConfig = useCallback(
    ({ order }: { order?: (keyof T)[] }) => {
      contextName && dispatch(updateGridSettings({ [contextName]: order || [] }));
    },
    [contextName, dispatch],
  );

  const actionHandlers = { ...receivedActionHandlers, setColumnsConfig };

  const displayedColumns = columnsVisibility
    ? columnsOrder.filter((col) => columnsVisibility.includes(col)).map(String)
    : columnsOrder;

  const changeHandlerSearch = (scrollTop: number) =>
    navigateWithState('search', {
      search: {
        ...(globalHistory.location?.state?.search || {}),
        scrollTop,
      },
    });

  const changeHandlerDashboard = (scrollTop: number) =>
    navigateWithState('dashboard', {
      dashboard: {
        ...(globalHistory.location?.state?.dashboard || {}),
        scrollTop,
      },
    });

  const debouncedChangeHandlerSearch = useMemo(() => debounce(changeHandlerSearch, 300), []);
  const debouncedChangeHandlerDashboard = useMemo(() => debounce(changeHandlerDashboard, 300), []);

  const calculateShading = () => {
    if (!gridRef.current) return;
    const {
      current: { scrollLeft, scrollWidth, offsetWidth },
    } = gridRef;
    const showLeft = scrollLeft > 0;
    const showRight = scrollLeft + offsetWidth < scrollWidth;
    if (showLeft !== shading.showLeft || showRight !== shading.showRight) {
      setShading({ showLeft, showRight });
    }

    if (isSearchPagination) {
      debouncedChangeHandlerSearch(gridRef.current?.scrollTop || 0);
    } else if (isDashboard) {
      debouncedChangeHandlerDashboard(gridRef.current?.scrollTop || 0);
    }
  };

  useDidUpdate(() => {
    calculateShading();
  }, [totalCount]);

  const templateColumns = useMemo(() => {
    let cols = displayedColumns.reduce<string>((acc, currentValue) => {
      try {
        return `${acc} ${componentsMap[currentValue].size}`;
      } catch (err) {
        console.error(`component for field ${currentValue} doesn't exist`);
        return `${acc} auto`;
      }
    }, '');

    if (withCheckbox) cols = `${CHECKBOX_WIDTH} ${cols}`;
    if (withActions) cols += ` ${ACTIONS_WIDTH} ${FIREFOX_HACK}`;

    return cols;
  }, [componentsMap, withCheckbox, withActions, displayedColumns]);

  if (!columnsOrder.length || totalCount === null)
    return (
      <Margin margin='0 auto'>
        <SpinnerIcon data-testid='table-grid-spinner-columns-empty' />
      </Margin>
    );
  if (isEmpty(filterCtx?.filterParams) && !bodyData.length && !loading)
    return (
      <Margin margin='0.8rem 0' right='auto'>
        <InfoBox content={noResultsContent} message={noResultsMessage} />
      </Margin>
    );
  if (allFilteredOutMessage && !bodyData.length)
    return (
      <Margin margin='0.8rem 0' right='auto'>
        <InfoBox message={allFilteredOutMessage} />
      </Margin>
    );

  return (
    <>
      <Dialog>
        <DialogCloseButton onClick={closeMapDialog}>X</DialogCloseButton>
        <Map className='markers-map' />
      </Dialog>
      {loading && (
        <TableGridLoading>
          <SpinnerIcon data-testid='table-grid-spinner-loading' />
        </TableGridLoading>
      )}
      <StyledTopPanel>
        {isNumber(totalCount) && (
          <StandardLabel variant='thin'>
            {`${totalCount} ${t(`${resultsSelector}.${totalCount === 1 ? 'result' : 'results'}`)}`}
          </StandardLabel>
        )}
        {withCheckbox && !!itemsSelected.length && (
          <>
            <DefaultButton
              label={`${t('filters.clear_selected')} (${itemsSelected.length})`}
              onClick={clearSelection}
              variant='secondary'
            />
            <DefaultButton
              label={`${t('filters.show_on_map')} (${itemsSelected.length})`}
              onClick={showOnMap}
              variant='secondary'
            />
          </>
        )}

        {addButton ? <TableGridAddButton>{addButton}</TableGridAddButton> : null}
      </StyledTopPanel>
      <TableGridShading>
        <Shading rightLarge {...shading}>
          <StyledGridScrollContainer
            data-testid='table-grid-scroll-container'
            id='table-grid-scroll-container'
            onScroll={calculateShading}
            ref={gridRef}
          >
            <StyledGrid
              disabled={loading}
              templateColumns={templateColumns}
              withFullWidth={withFullWidth}
            >
              <TableHeader
                actionHandlers={actionHandlers}
                allColumns={columnsOrder}
                columnsOrder={displayedColumns}
                columnsVisibility={columnsVisibility}
                componentsMap={componentsMap}
                disabled={loading}
                isDashboard={isDashboard}
                isSearchPagination={isSearchPagination}
                noConfig={noConfig}
                totalCount={totalCount}
                withActions={withActions}
                withCheckbox={withCheckbox}
              />
              {bodyData.map((entity, i) => (
                <TableRow<T, K>
                  actionHandlers={actionHandlers}
                  columnsOrder={displayedColumns}
                  componentsMap={componentsMap}
                  disabled={loading || isDisabled}
                  entity={entity}
                  key={i}
                  withActions={withActions}
                  withCheckbox={withCheckbox}
                />
              ))}
            </StyledGrid>
          </StyledGridScrollContainer>
        </Shading>
      </TableGridShading>
    </>
  );
};

TableGrid.defaultProps = {
  resultsSelector: 'common',
};
