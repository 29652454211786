import styled, { css } from 'styled-components/macro';

export interface RadioButtonProps {
  isSelected?: boolean;
  isDisabled?: boolean;
}

export const StyledRadioButtonCircle = styled.span`
  ${({ theme }) => theme.icon.lg};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.black100};
  margin-right: ${({ theme }) => theme.sizes.hmd};
  position: relative;

  &::after {
    background-color: ${({ theme }) => theme.colors.black200};
    border-radius: 50%;
    content: '';
    height: ${({ theme }) => theme.sizes.xs};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: ${({ theme }) => theme.sizes.xs};
  }
`;

export const RadioButtonContainer = styled.button.attrs(() => ({ type: 'button' }))<
  RadioButtonProps
>`
  display: flex;
  padding: ${({ theme }) => `${theme.sizes.xs} ${theme.sizes.sm}`};
  cursor: pointer;
  user-select: none;
  align-items: center;

  & p::first-letter{
    text-transform: capitalize;
  }

  & p {
    margin: 0;
  }

  & > input {
    position: absolute;
    visibility: hidden;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      & > span {
        background-color: ${({ theme }) => theme.colors.black50};
      }
    `}

  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      &:hover ${StyledRadioButtonCircle} {
        background-color: ${({ theme }) => theme.colors.blue300};
        border-color: ${({ theme }) => theme.colors.blue300};

        &::after {
          background-color: white;
        }
      }
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      & ${StyledRadioButtonCircle} {
        background-color: ${({ theme }) => theme.colors.blue500};
        border-color: ${({ theme }) => theme.colors.blue500};

        &::after {
          background-color: white;
        }
      }
    `}
`;
