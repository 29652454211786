import React from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { Column } from 'components/Table/TableHeader/Column/Column';
import { ActionsHandlers, TableComponentsMap } from 'components/Table/types';
import { useTableContexts } from 'hocs/composedTableProviders/composedTableProviders';
import { useModal } from 'components/Modal/Modal';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { ColumnOrderModal } from 'components/ColumnOrder/ColumnOrder';
import { CenterCenter, CenterEnd, StyledConfigWrap } from './styles';
import { ConfigIcon } from 'utils/iconsMap';

interface TableHeaderPropTypes<T> {
  actionHandlers?: ActionsHandlers;
  allColumns: (keyof T)[];
  columnsOrder: (keyof T)[];
  columnsVisibility?: (keyof T)[];
  componentsMap: T;
  disabled?: boolean;
  isDashboard?: boolean;
  isExpandable?: boolean;
  isSearchPagination?: boolean;
  noConfig: boolean;
  totalCount?: number | null;
  withActions?: boolean;
  withCheckbox?: boolean;
}

const TableHeader = <T extends TableComponentsMap>({
  actionHandlers,
  allColumns,
  columnsOrder,
  columnsVisibility,
  componentsMap,
  disabled,
  isDashboard,
  isExpandable,
  isSearchPagination,
  noConfig,
  totalCount,
  withActions,
  withCheckbox,
}: TableHeaderPropTypes<T>) => {
  const { t } = useTranslation();
  const { itemsSelected, clearSelection, contextName, allParams, addMultipleSelection } =
    useTableContexts();
  const { setModalComponent, showModal, setShowHeader } = useModal();

  const allSelected = itemsSelected && itemsSelected.length >= Number(totalCount);

  const handleClick = () => {
    setModalComponent(
      <ColumnOrderModal
        activeColumns={columnsVisibility?.map(String) || []}
        onSave={actionHandlers?.setColumnsConfig}
        order={allColumns.map(String)}
      />,
    );
    setShowHeader(false);
    showModal();
  };

  return (
    <>
      {withCheckbox && (
        <CenterCenter sticky='vertical'>
          <Tooltip label={allSelected ? t('filters.clear_selected') : t('common.select_all')}>
            <Checkbox
              isChecked={allSelected}
              isDisabled={disabled}
              onClick={() =>
                allSelected
                  ? clearSelection()
                  : actionHandlers?.selectAll &&
                    actionHandlers?.selectAll(allParams, addMultipleSelection)
              }
            />
          </Tooltip>
        </CenterCenter>
      )}
      {columnsOrder.map((columnName) => {
        return (
          <Column
            columnName={columnName as string}
            CustomComponent={componentsMap[columnName]?.headerComponent}
            disabled={disabled}
            isDashboard={isDashboard}
            isSearchPagination={isSearchPagination}
            key={columnName as string}
            label={t(`table.columns.${columnName}`)}
            sticky='vertical'
          />
        );
      })}
      {(withActions || isExpandable) && (
        <>
          <CenterEnd sticky='both'>
            {!noConfig && (
              <StyledConfigWrap>
                <DefaultButton
                  icon={<ConfigIcon />}
                  isDisabled={disabled || !contextName}
                  onClick={handleClick}
                  size='small'
                  variant='tertiary'
                />
              </StyledConfigWrap>
            )}
          </CenterEnd>
          <div /> {/* this div is a hack that fixes problem with position: sticky on firefox*/}
        </>
      )}
    </>
  );
};

TableHeader.defaultProps = {
  disabled: false,
};

export { TableHeader };
