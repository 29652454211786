import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateWorkplaceParams, WorkplaceKindResponse } from './types';
import { API } from 'api';
import { GetWorkplaceResponse, WorkplaceEntity } from 'ducks/powerSearch/workplace/types';
import { StoreType } from 'store';

export const getWorkplace = createAsyncThunk(
  'workplace/getWorkplace',
  async (workplaceId: string) => {
    const response = await API.get(`workplaces/${workplaceId}`);
    return response.data as WorkplaceEntity;
  },
);

export const getWorkplaceKinds = createAsyncThunk(
  'workplace/workplace_kinds',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as StoreType;
    const params = { industry_id: state.users.current?.industryId };
    const response = await API.get('workplace_types', { params });
    return response.data as WorkplaceKindResponse;
  },
);

export const createWorkplace = async (attributes: CreateWorkplaceParams) => {
  const response = await API.post('workplaces', { data: { attributes } });
  return response.data as GetWorkplaceResponse;
};
