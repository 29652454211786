import React, { useCallback, useState } from 'react';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  StyledModalButtonsWrapper,
  StyledModalHeader,
  StyledModalItemToRemove,
  StyledModalMessage,
  useModal,
} from 'components/Modal/Modal';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { formatDate } from 'utils/date';
import { DashboardVisitEntity } from 'ducks/dashboard/types';
import { useAppDispatch } from 'store';
import { deleteDashboardVisit } from 'ducks/dashboard/actions';

interface Props {
  entity: DashboardVisitEntity;
}

export const DeleteDashboardVisit = ({ entity }: Props) => {
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState(false);
  const { typeOfVisit, date, companyName, companyVisitId, address, workplace, siblingsNames } =
    entity;
  const { hideModal } = useModal();
  const dispatch = useAppDispatch();

  const header = t(
    `company_details.visits.${
      typeOfVisit === 'light' || !workplace ? 'delete_visit_report' : 'remove_company_from_visit'
    }`,
  );
  const message = t(
    `company_details.visits.${
      typeOfVisit === 'light' || !workplace
        ? 'data_lose'
        : siblingsNames && siblingsNames.length > 1
        ? 'data_company_lose'
        : 'delete_whole_visit'
    }`,
  );

  const deleteVisit = useCallback(async () => {
    setIsProcessing(true);
    await dispatch(deleteDashboardVisit(companyVisitId));
    setIsProcessing(false);
    hideModal();
  }, [dispatch, hideModal, deleteDashboardVisit, companyVisitId]);

  return (
    <>
      <StyledModalHeader>{header}</StyledModalHeader>
      {date && (
        <>
          <StyledModalItemToRemove>
            {`${formatDate(date.value)} - ${companyName ? companyName : ''}`}
          </StyledModalItemToRemove>
          <StyledModalItemToRemove>
            {workplace ? `${workplace.value} - ${address}` : address}
          </StyledModalItemToRemove>
        </>
      )}

      <StyledModalMessage>{message}</StyledModalMessage>
      <StyledModalButtonsWrapper>
        <DefaultButton
          isDisabled={isProcessing}
          label={capitalize(t('common.cancel'))}
          onClick={hideModal}
          variant='secondary'
        />
        <DefaultButton
          isDisabled={isProcessing}
          label={
            siblingsNames && siblingsNames.length > 1
              ? capitalize(t('company_details.visits.delete_company'))
              : capitalize(t('company_details.visits.delete'))
          }
          onClick={() => {
            deleteVisit();
          }}
          variant='danger'
        />
      </StyledModalButtonsWrapper>
    </>
  );
};
