import React, { FC, useCallback, useState } from 'react';

interface PageObject {
  currentPage: number | null;
  pages: number | null;
  rowsPerPage: number | null;
}

interface ContextValue extends PageObject {
  setParams: (newParams: Partial<PageObject>) => void;
}

interface ContextProviderProps {
  isDashboard: boolean;
  isPowerSearch: boolean;
  metaObject: { page: number; pageSize: number; totalPages: number };
  setPaginationInLocation: Function;
}
export const PaginationContext = React.createContext<ContextValue>({
  currentPage: null,
  pages: null,
  rowsPerPage: null,
  setParams: () => {},
});

const PaginationContextProvider: FC<ContextProviderProps> = ({
  isDashboard = false,
  children,
  isPowerSearch = false,
  metaObject,
  setPaginationInLocation,
}) => {
  const { page, pageSize, totalPages } = metaObject;
  const [paginationContext, setPaginationContext] = useState<PageObject>({
    currentPage: page,
    pages: totalPages,
    rowsPerPage: pageSize,
  });

  const setParams = useCallback(
    (newParams: Partial<PageObject>) => {
      setPaginationContext((value) => ({ ...value, ...newParams }));

      if (isPowerSearch || isDashboard) {
        const newParamsForLocation = {};
        if (newParams.currentPage) {
          // @ts-ignore
          newParamsForLocation.currentPage = newParams.currentPage;
        }
        if (newParams.rowsPerPage) {
          // @ts-ignore
          newParamsForLocation.rowsPerPage = newParams.rowsPerPage;
        }
        setPaginationInLocation(newParamsForLocation);
      }
    },
    [setPaginationContext],
  );

  return (
    <PaginationContext.Provider
      value={{
        ...paginationContext,
        setParams,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

const usePaginationContext = () => React.useContext(PaginationContext);

export { usePaginationContext, PaginationContextProvider };
