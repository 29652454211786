import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { priorNotificationsTableMapper } from './priorNotificationsTableMapper';
import {
  TableProvidersProps,
  useTableContexts,
  withTableProviders,
} from 'hocs/composedTableProviders/composedTableProviders';
import { StoreType } from 'store';
import { TableGrid } from 'components/Table/TableGrid/TableGrid';
import {
  getPriorNotificationsCSV,
  searchPriorNotifications,
} from 'ducks/powerSearch/priorNotifications/actions';
import {
  StyledColumn,
  StyledContainer,
  StyledTableBottomPanel,
} from 'containers/companyDetails/Visits/styles';
import { TableFilters } from 'components/TableFilters/TableFilters';
import { PaginationComposed } from 'components/Pagination/Pagination';
import { CommonActionsPanel } from 'components/CommonActionsPanel/CommonActionsPanel';
import { ButtonWrapper } from 'components/ButtonWrapper/ButtonWrapper';
import { useLoadingContext } from 'contexts/LoadingContext';
import { StyledCardMax } from 'components/Card/styles';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { useModal } from 'components/Modal/Modal';
import { SaveSearch, ShowPriorNotification } from './resultsModals';
import { getActiveFiltersState } from 'utils';
import { resetSearchResults } from 'ducks/powerSearch/actions';
import { SearchPriorNotificationsEntity } from 'ducks/powerSearch/priorNotifications/types';
import { API } from 'api';
import { checkIsIpad } from 'utils/deviceChecker';

import { ReactComponent as BookmarkIcon } from 'images/bookmarkIcon.svg';
import { PlanAVisit } from 'dialogs/PriorNotices/PlanAVisit';

const PriorNotificationsResults: FC<TableProvidersProps> = (props) => {
  const results = useSelector((state: StoreType) => state.powerSearch.priorNotifications);
  const { query } = useSelector((state: StoreType) => state.powerSearch);
  const { filtersState, setContextName, itemsSelected, filterParams, addMultipleSelection } =
    useTableContexts();
  const { setLoaded, setLoading } = useLoadingContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showModal, setModalComponent, setShowHeader } = useModal();

  useEffect(() => {
    return () => {
      dispatch(resetSearchResults());
    };
  }, [dispatch, resetSearchResults]);

  useEffect(() => {
    setContextName('powerPriorNotifications');
  }, []);

  const showHandler = (entity: SearchPriorNotificationsEntity) => {
    setModalComponent(<ShowPriorNotification notification={entity} />);
    setShowHeader(false);
    showModal();
  };

  const planAVisitHandler = (entity: SearchPriorNotificationsEntity) => {
    setModalComponent(
      <PlanAVisit companyName={entity.reportingCompanyName} priorNotificationId={entity.id} />,
    );
    setShowHeader(false);
    showModal();
  };

  const selectAllHandler = async () => {
    setLoading('powersearch');
    const params = { ...filterParams, ...props.fetchParams, page_size: 10000, page: 1 };
    const response = await API.get(`power/prior_notifications`, { params });

    addMultipleSelection(
      response.data.data.tableBody.map((x: SearchPriorNotificationsEntity) => ({
        entity: { ...x },
        id: x.id,
      })),
    );
    setLoaded('powersearch');
  };

  const exportCsvHandler = async () => {
    setLoading('powersearch');
    await getPriorNotificationsCSV(
      itemsSelected.map((item: SearchPriorNotificationsEntity) => item.id),
    );
    setLoaded('powersearch');
  };

  const actionHandlers = {
    exportCSV: exportCsvHandler,
    plan_a_visit: planAVisitHandler,
    selectAll: selectAllHandler,
    show: showHandler,
  };

  const isIpad = checkIsIpad();

  return (
    // @ts-ignore
    <StyledContainer isIpad={isIpad}>
      <StyledCardMax>
        <StyledColumn>
          <TableFilters isSearchPagination />
          <TableGrid
            actionHandlers={actionHandlers}
            addButton={
              <DefaultButton
                icon={<BookmarkIcon />}
                label={t(`power_search.save_search`)}
                onClick={() => {
                  setModalComponent(
                    <SaveSearch
                      params={{
                        state: getActiveFiltersState(filtersState),
                        query,
                        tag: 'prior_notifications',
                      }}
                    />,
                  );
                  setShowHeader(false);
                  showModal();
                }}
              />
            }
            allFilteredOutMessage={t(`power_search.prior_notifications.all_filtered_out`)}
            bodyData={results.data.tableBody}
            columnsOrder={results.meta.columnsOrder}
            componentsMap={priorNotificationsTableMapper}
            isSearchPagination
            loadResource='powersearch'
            noResultsMessage={t(`power_search.prior_notifications.no_results`)}
            totalCount={results.meta.totalCount}
            withCheckbox
            withFullWidth
          />
          <StyledTableBottomPanel>
            <PaginationComposed loadResource='powersearch' />
            <ButtonWrapper align='right'>
              <CommonActionsPanel actionHandlers={actionHandlers} />
            </ButtonWrapper>
          </StyledTableBottomPanel>
        </StyledColumn>
      </StyledCardMax>
    </StyledContainer>
  );
};

const ResultsComposed = withTableProviders(PriorNotificationsResults, {
  fetchFunction: searchPriorNotifications,
  loadResource: 'powersearch',
  metaUrl: 'power/prior_notifications',
  spyDistrict: true,
  spyQuery: true,
});

export { ResultsComposed as PriorNotificationsResults };
