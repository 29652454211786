import React, { useCallback, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMatch } from '@reach/router';
import { useSelector } from 'react-redux';

import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { ButtonsPanelWrapper } from 'containers/newVisit/styles';
import {
  isNewVisitHasAccessToTips,
  newVisitCompanyVisitSelector,
  newVisitCompanyVisitsSelector,
  newVisitCreatorIdSelector,
  newVisitIndustryIdSelector,
  newVisitObservationSelector,
  newVisitWorkplaceOptionSelector,
} from 'ducks/newVisit';
import { useModal } from 'components/Modal/Modal';
import { DeleteVisit } from 'dialogs/newVisits/ButtonsPanel/DeleteVisit';
import { DeleteCompanyVisit } from 'dialogs/newVisits/ButtonsPanel/DeleteCompanyVisit';
import { FinishCompanyVisit } from 'dialogs/newVisits/ButtonsPanel/FinishCompanyVisit';
import { FollowUpCompanyVisit } from 'dialogs/newVisits/ButtonsPanel/FollowUpCompanyVisit';
import { SetEditCompanyVisit } from 'dialogs/newVisits/ButtonsPanel/SetEditCompanyVisit';
import { companyVisitDetailsSchema } from 'containers/newVisit/OrdinaryVisit/Step3/validation';
import { validateSchema } from 'utils/yupValidate';
import { TipsATModal } from 'dialogs/newVisits/ButtonsPanel/TipsATModal';
import { tipsThemesSelector } from 'ducks/constants/selectors';
import { currentUserSelector } from 'ducks/users/selectors';

const ROOT_ELEMENT = document.querySelector('body');
interface ButtonsPanelProps {
  isButtonsActive: boolean;
  companyVisitId?: string;
  isMainButtonDisabled?: boolean;
  mainButtonLabel: string;
  mainButtonHandler: () => unknown;
  visitId: string;
}

const isHrrBranch = window.REACT_APP_ENV?.BRAND_ID === 'hrr';

export const ButtonsPanel = ({
  companyVisitId,
  isButtonsActive,
  isMainButtonDisabled,
  mainButtonHandler,
  mainButtonLabel,
  visitId,
}: ButtonsPanelProps) => {
  const { t } = useTranslation();
  const { setModalComponent, showModal, setShowHeader } = useModal();

  const match = useMatch('/redesigned/new-ordinary-visit/:id/:step');

  const [isValid, setIsValid] = React.useState(false);

  const observations = useSelector(newVisitObservationSelector(companyVisitId));
  const companyVisit = useSelector(newVisitCompanyVisitSelector(companyVisitId));
  const companyVisitHasAccessToTip = useSelector(isNewVisitHasAccessToTips(companyVisitId));
  const companyVisits = useSelector(newVisitCompanyVisitsSelector);
  const industryId = useSelector(newVisitIndustryIdSelector);
  const workplaceOption = useSelector(newVisitWorkplaceOptionSelector);
  const creatorId = useSelector(newVisitCreatorIdSelector);
  const tipsThemes = useSelector(tipsThemesSelector);
  const user = useSelector(currentUserSelector);
  const hasNotMetObservations = useMemo(
    () => observations?.some(({ deadline, fulfilled }) => deadline && !fulfilled),
    [observations],
  );

  const hasNotMetObservationsInEditDraftState = useMemo(
    () => observations?.some(({ deadline }) => deadline),
    [observations],
  );

  const companyVisitIds = useMemo(() => companyVisits.map((visit) => visit.id), [companyVisits]);

  const hasReportSent = useMemo(
    () =>
      companyVisits
        .map((companyVisit) => companyVisit?.companyVisitReports.length > 0)
        .includes(true),
    [companyVisitIds],
  );

  const userHasDeletingRights = useMemo(
    () => user?.admin && (industryId === user?.industryId || creatorId === user?.id),
    [companyVisits, user],
  );

  const isEditBtnDisabled =
    !isButtonsActive ||
    match?.step !== 'companies' ||
    companyVisit?.status === 'draft' ||
    companyVisit?.status === 'edit';

  const isFollowUpBtnDisabled =
    !isButtonsActive ||
    match?.step !== 'companies' ||
    !isValid || // A required attribute is missing
    !observations?.some(({ deadline }) => deadline) || // No deadline set
    companyVisit?.status === 'follow_up' || // In Follow up state
    companyVisit?.status === 'completed'; // In Finished state

  const isFinishBtnDisabled =
    !isButtonsActive ||
    match?.step !== 'companies' ||
    (hasNotMetObservationsInEditDraftState &&
      companyVisit?.status &&
      ['edit', 'draft'].includes(companyVisit?.status)) ||
    !isValid || // A required attribute is missing
    companyVisit?.status === 'completed'; // In Finished state

  const isSendBtnDisabled = useMemo(() => {
    if (match?.step !== 'companies') return false;
    if (companyVisit?.status === 'edit' || companyVisit?.status === 'draft') return true; // In Draft state, In Edit state
    return false;
  }, [match?.step, companyVisit?.status]);

  const isDeleteButtonDisabled =
    !isButtonsActive ||
    (match?.step === 'companies' && companyVisits.length > 0 && !companyVisitId) ||
    (match?.step === 'workplace' && (!userHasDeletingRights || hasReportSent));

  const isTipsATButtonDisabled =
    !isButtonsActive ||
    match?.step !== 'companies' ||
    companyVisit?.status === 'edit' ||
    companyVisit?.status === 'draft' ||
    tipsThemes.length === 0 ||
    !companyVisitHasAccessToTip;

  const editHandler = useCallback(() => {
    setModalComponent(<SetEditCompanyVisit companyVisitId={companyVisitId!} />);
    setShowHeader(false);
    showModal();
  }, [companyVisitId]);

  const tipsATButton = useCallback(() => {
    setModalComponent(
      <TipsATModal
        companyVisitId={companyVisitId!}
        currentTipValue={tipsThemes?.find((tip) => tip.default)?.id || ''}
        tipsOptions={tipsThemes}
      />,
    );
    setShowHeader(false);
    showModal();
  }, [companyVisitId, companyVisit?.tipsExternalId, tipsThemes]);

  const followUpHandler = useCallback(() => {
    setModalComponent(<FollowUpCompanyVisit companyVisitId={companyVisitId!} />);
    setShowHeader(false);
    showModal();
  }, [companyVisitId]);

  const finishHandler = useCallback(() => {
    setModalComponent(
      <FinishCompanyVisit
        companyVisitId={companyVisitId!}
        hasNotMetObservations={hasNotMetObservations || false}
      />,
    );
    setShowHeader(false);
    showModal();
  }, [companyVisitId, observations, hasNotMetObservations]);

  const deleteHandler = useCallback(() => {
    if (workplaceOption !== 'SameAsCompany' && companyVisitId && companyVisits.length > 1)
      setModalComponent(
        <DeleteCompanyVisit
          companyVisitId={companyVisitId}
          isALastVisit={companyVisits.length === 1}
          name={companyVisit!.companyName}
          visitId={visitId}
        />,
      );
    else setModalComponent(<DeleteVisit visitId={visitId} />);
    setShowHeader(false);
    showModal();
  }, [companyVisitId, visitId, workplaceOption, companyVisits]);

  useEffect(() => {
    const validate = async () => {
      const result =
        !!companyVisit &&
        (await validateSchema(companyVisitDetailsSchema, companyVisit, {
          context: { isHrrBranch },
        }));
      setIsValid(result);
    };
    validate();
  }, [companyVisit]);

  return createPortal(
    <ButtonsPanelWrapper>
      <DefaultButton
        isDisabled={isEditBtnDisabled}
        label={capitalize(t('common.edit'))}
        onClick={editHandler}
        size='large'
        testid='edit-button'
        type='button'
        variant='tertiary'
      />
      <DefaultButton
        isDisabled={isFollowUpBtnDisabled}
        label={capitalize(t('common.follow_up'))}
        onClick={followUpHandler}
        size='large'
        testid='follow-up-button'
        type='button'
        variant='tertiary'
      />
      <DefaultButton
        isDisabled={isFinishBtnDisabled}
        label={t('common.finish')}
        onClick={finishHandler}
        size='large'
        testid='finish-button'
        type='button'
        variant='tertiary'
      />
      <DefaultButton
        isDisabled={!isButtonsActive || isSendBtnDisabled || isMainButtonDisabled}
        label={mainButtonLabel}
        onClick={mainButtonHandler}
        size='large'
        testid='success-button'
        type='button'
        variant='success'
      />
      <DefaultButton
        isDisabled={isDeleteButtonDisabled}
        label={t('common.remove')}
        onClick={deleteHandler}
        size='large'
        testid='remove-button'
        type='button'
        variant='danger'
      />
      <DefaultButton
        isDisabled={isTipsATButtonDisabled}
        label={t('new_visits.tips_at')}
        onClick={tipsATButton}
        size='large'
        testid='tips-at-button'
        type='button'
        variant='tertiary'
      />
    </ButtonsPanelWrapper>,
    ROOT_ELEMENT!,
  );
};
