import React, { ComponentType, FC, useCallback, useMemo, useRef, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { FieldTextPropTypes, TextInput } from 'components/TextInput/TextInput';
import {
  StyledDescription,
  StyledError,
  StyledFieldWrapper,
  StyledLabel,
} from 'components/Forms/styles';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { Margin } from 'styles/utils';

import { ReactComponent as ArrowUp } from 'images/arrowUpBigIcon.svg';
import { ReactComponent as ArrowDown } from 'images/arrowDownBigIcon.svg';
import { ReactComponent as PlusIcon } from 'images/plusIcon.svg';
import { ReactComponent as MinusIcon } from 'images/minusIcon.svg';
import {
  StyledMobileDown,
  StyledMobileUp,
  StyledNumberFieldWrapper,
  StyledSmallNumberFieldArrows,
  StyledTextInputWrapper,
} from './styles';
import { useOnClickOutside } from 'utils/hooks';
import { checkIsTablet } from '../../../utils/deviceChecker';

interface NumberFieldPropTypes extends FieldRenderProps<string> {
  additionalInputProps?: FieldTextPropTypes;
  description?: string;
  isDisabled?: boolean;
  label?: string;
  placeholder?: string;
  required?: boolean;
  digitsLimit?: number;
  size?: 'regular' | 'small';
}

const NumberField: ComponentType<NumberFieldPropTypes> = ({
  digitsLimit,
  input,
  size = 'regular',
  ...props
}) => {
  const onChange = useCallback((event) => {
    const { value } = event.target;
    if (digitsLimit && value.length > digitsLimit) return;
    if (!Number.isNaN(+value) && !value.includes('-')) return input.onChange(event);
  }, []);

  const componentProps: NumberFieldPropTypes = { ...props, input, onChange, digitsLimit };

  if (size === 'small') return <SmallNumberComponent {...componentProps} />;
  else {
    return <RegularNumberComponent {...componentProps} />;
  }
};

const SmallNumberComponent: FC<NumberFieldPropTypes> = ({
  input,
  onChange,
  label,
  required,
  placeholder,
  additionalInputProps,
  description,
  digitsLimit,
  meta,
  isDisabled,
}) => {
  const isMobile = useMemo(
    () => window.matchMedia('(max-width: 700px)').matches,
    [window.innerWidth],
  );
  const isTablet = checkIsTablet();

  const [arrowsVisibility, setArrowsVisibility] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, () => setArrowsVisibility(false));

  const focusHandle = (event: React.FocusEvent<HTMLInputElement>) => {
    input.onFocus && input.onFocus(event);
    setArrowsVisibility(true);
  };

  return (
    <StyledFieldWrapper data-testid='number-field' onClick={(e) => e.stopPropagation()}>
      {label && (
        <StyledLabel as='label' htmlFor={input.name}>
          {label && `${label}${required ? '*' : ''}`}
        </StyledLabel>
      )}

      <StyledNumberFieldWrapper ref={containerRef}>
        <StyledTextInputWrapper digitsLimit={digitsLimit} isMobile={isMobile || isTablet}>
          <TextInput
            inputProps={{
              ...input,
              onChange,
              onFocus: focusHandle,
              type: 'number',
              pattern: '[0-9]*',
              inputMode: 'numeric',
            }}
            isDisabled={isDisabled || false}
            isError={meta.touched && meta.error}
            placeholder={placeholder || ''}
            {...additionalInputProps}
          />
        </StyledTextInputWrapper>

        {isMobile || isTablet ? (
          <>
            <StyledMobileDown hidden={!arrowsVisibility}>
              <DefaultButton
                icon={<ArrowDown />}
                onClick={() => {
                  const value = Number(input.value);
                  input.onChange({
                    target: { value: (value > 0 ? value - 1 : 0).toString() },
                  } as React.ChangeEvent<HTMLInputElement>);
                }}
                size='small'
                testid='number-filed-button-down'
                type='button'
                variant='tertiary'
              />
            </StyledMobileDown>
            <StyledMobileUp hidden={!arrowsVisibility}>
              <DefaultButton
                icon={<ArrowUp />}
                onClick={() => {
                  input.onChange({
                    target: { value: (Number(input.value) + 1).toString() },
                  } as React.ChangeEvent<HTMLInputElement>);
                }}
                size='small'
                testid='number-filed-button-up'
                type='button'
                variant='tertiary'
              />
            </StyledMobileUp>
          </>
        ) : (
          <StyledSmallNumberFieldArrows>
            <button
              data-testid='number-filed-button-up'
              disabled={isDisabled}
              onClick={() => {
                const newValue = (Number(input.value) + 1).toString();
                if (!digitsLimit || newValue.length <= digitsLimit)
                  input.onChange({
                    target: { value: newValue },
                  } as React.ChangeEvent<HTMLInputElement>);
              }}
              type='button'
            >
              <ArrowUp />
            </button>
            <button
              data-testid='number-filed-button-down'
              disabled={isDisabled}
              onClick={() => {
                const value = Number(input.value);
                input.onChange({
                  target: { value: (value > 0 ? value - 1 : 0).toString() },
                } as React.ChangeEvent<HTMLInputElement>);
              }}
              type='button'
            >
              <ArrowDown />
            </button>
          </StyledSmallNumberFieldArrows>
        )}
      </StyledNumberFieldWrapper>

      {description && <StyledDescription>{description}</StyledDescription>}
      {meta.touched && meta.error && <StyledError>{meta.error}</StyledError>}
    </StyledFieldWrapper>
  );
};

const RegularNumberComponent: FC<NumberFieldPropTypes> = ({
  input,
  onChange,
  label,
  required,
  placeholder,
  additionalInputProps,
  description,
  meta,
  isDisabled,
}) => (
  <StyledFieldWrapper>
    {label && (
      <StyledLabel as='label' htmlFor={input.name}>
        {label && `${label}${required ? '*' : ''}`}
      </StyledLabel>
    )}

    <StyledNumberFieldWrapper>
      <DefaultButton
        icon={<MinusIcon />}
        onClick={() => {
          const value = Number(input.value);
          input.onChange({
            target: { value: (value > 0 ? value - 1 : 0).toString() },
          } as React.ChangeEvent<HTMLInputElement>);
        }}
        testid='number-filed-button-down'
        type='button'
        variant='tertiary'
      />

      <Margin margin='0 0.8rem'>
        <TextInput
          inputProps={{ ...input, onChange }}
          isDisabled={isDisabled || false}
          isError={meta.touched && meta.error}
          placeholder={placeholder || ''}
          size='medium'
          {...additionalInputProps}
        />
      </Margin>

      <DefaultButton
        icon={<PlusIcon />}
        onClick={() => {
          input.onChange({
            target: { value: (Number(input.value) + 1).toString() },
          } as React.ChangeEvent<HTMLInputElement>);
        }}
        testid='number-filed-button-up'
        type='button'
        variant='tertiary'
      />
    </StyledNumberFieldWrapper>
    {description && <StyledDescription>{description}</StyledDescription>}
    {meta.touched && meta.error && <StyledError>{meta.error}</StyledError>}
  </StyledFieldWrapper>
);

NumberField.defaultProps = {
  required: false,
};

export { NumberField };
