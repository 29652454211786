import styled, { css } from 'styled-components/macro';

export const TabContainer = styled.div<{
  withScroll?: boolean;
}>`
  display: flex;
  flex-wrap: nowrap;

  ${({ withScroll }) =>
    withScroll &&
    css`
      overflow: auto;

      ${Tab} {
        flex: 0 0 auto;
        max-width: unset;
      }
    `}
  @media (max-width: 700px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const TabSubtext = styled.div`
  ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.black300};
  font-weight: 500;
  margin-top: ${({ theme }) => theme.sizes.xxs};
`;

const notBigCardStyles = css`
  &:after {
    background-color: ${({ theme }) => theme.colors.blue500};
    border-radius: ${({ theme }) => `${theme.borderRadius.sm} ${theme.borderRadius.sm} 0 0`};
    bottom: 0;
    content: '';
    height: 0.3rem;
    left: 0;
    position: absolute;
    right: 0;
    transform: translateY(100%);
    transition: transform 0.3s;
  }
`;

const bigCardActiveStyles = css`
  &:after {
    background-color: white;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
  }

  border: ${({ theme }) => `1px solid ${theme.colors.black300}`};
  border-bottom: none;
`;

const nonActiveBigCarStyles = css`
  background-color: #f7f7f7;

  &.company-visit-tab-inactive + .company-visit-tab-inactive {
    border-left: ${({ theme }) => `1px solid ${theme.colors.black100}`};
  }
`;

const nonBigCardNonActiveStyles = css`
  color: ${({ theme }) => theme.colors.black900};

  ${TabSubtext} {
    color: ${({ theme }) => theme.colors.black400};
    font-weight: 600;
  }

  &:after {
    transform: translateY(0);
  }
`;

export const Tab = styled.button<{
  active: boolean;
  bigCard?: boolean;
  borderLeft?: boolean;
  borderRight?: boolean;
  length: number;
  withSubtext?: boolean;
}>`
  ${({ theme }) => theme.fontSizes.base};
  align-items: center;
  color: ${({ theme }) => theme.colors.black500};
  display: flex;
  flex: 1 1 auto;
  font-weight: bold;
  justify-content: flex-start;
  max-width: ${({ length }) => `${length * 0.7 + 4.8}rem`};
  overflow: hidden;
  padding: ${({ theme, bigCard }) =>
    bigCard ? ` ${theme.sizes.hmd} 1rem .7rem` : `${theme.sizes.xs} ${theme.sizes.hmd}`};
  position: relative;
  transition: color 0.3s;
  white-space: nowrap;

  ${({ bigCard, borderLeft, borderRight }) =>
    bigCard
      ? css`
          border-top-left-radius: ${borderLeft ? '3px' : '0'};
          border-top-right-radius: ${borderRight ? '3px' : '0'};
        `
      : notBigCardStyles}
    
  ${({ withSubtext }) =>
    withSubtext &&
    css`
      align-items: flex-start;
      flex-direction: column;
      flex: 0 1 auto;
      max-width: none;
      padding: ${({ theme }) => `${theme.sizes.sm} ${theme.sizes.hmd}`};
    `}

  ${({ active, bigCard }) => {
    if (bigCard) {
      return active ? bigCardActiveStyles : nonActiveBigCarStyles;
    }
    if (active) {
      return nonBigCardNonActiveStyles;
    }
  }}}

  @media (min-width: 700px) {
    justify-content: center;
  }
  @media (max-width: 700px) {
    justify-content: center;
    }
`;
