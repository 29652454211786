import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { navigate, RouteComponentProps } from '@reach/router';
import { isEmpty } from 'lodash';

import { TableGrid } from 'components/Table/TableGrid/TableGrid';
import { CardSmall } from 'components/Card/Card';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { PaginationComposed } from 'components/Pagination/Pagination';
import { TableFilters } from 'components/TableFilters/TableFilters';
import { CommonActionsPanel } from 'components/CommonActionsPanel/CommonActionsPanel';
import {
  StyledColumn,
  StyledEmptyVisits,
  StyledEmptyVisitsWrap,
  StyledTableBottomPanel,
} from './styles';
import { StoreType } from 'store';
import { ButtonWrapper } from 'components/ButtonWrapper/ButtonWrapper';
import { getAllWorkplaceVisits, getVisitsCSV, getWorkplaceVisits } from 'ducks/visits/actions';
import { useLoadingContext } from 'contexts/LoadingContext';
import { useModal } from 'components/Modal/Modal';
import { VisitEntity, VisitEntityResponse } from 'ducks/visits/types';
import { clearCompanyVisitError, resetCompanyVisitReducer } from 'ducks/visits/reducer';
import { visitsComponentsMap } from './tableComponentsMap';
import {
  TableProvidersProps,
  useTableContexts,
  withTableProviders,
} from 'hocs/composedTableProviders/composedTableProviders';
import { DeleteVisitsModal } from './VisitsModals';
import { ChooseVisitModal } from 'containers/newVisit/ChooseVisitModal';
import { useScreenSizeContext } from 'contexts/ScreenSizeContext';
import { PlusIcon } from 'utils/iconsMap';
import { ChooseDateDashboardVisit } from 'dialogs/Dashboard/ChooseDateDashboardVisit';
import { WorkplaceEntity } from 'ducks/powerSearch/workplace/types';
import { toast } from 'react-hot-toast';

interface VisitsProps extends RouteComponentProps, TableProvidersProps {
  workplaceId?: string;
  workplace: WorkplaceEntity;
}

const Visits: FC<VisitsProps> = (props) => {
  const { fetchParams, workplaceId } = props;
  const visits = useSelector((state: StoreType) => state.visits);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setLoaded, setLoading } = useLoadingContext();
  const { setModalComponent, showModal, setCustomStyles, setShowHeader } = useModal();
  const { md } = useScreenSizeContext();
  const { setContextName, itemsSelected, deleteSelection, filterParams, addMultipleSelection } =
    useTableContexts();

  useEffect(() => {
    setContextName('workplaceVisits');
    return () => {
      setContextName(null);
      dispatch(resetCompanyVisitReducer());
    };
  }, []);

  // --- table row actions handlers ---

  const addNewVisit = useCallback(() => {
    md
      ? setCustomStyles({ maxWidth: '64rem', height: '64rem' })
      : setCustomStyles({ maxWidth: '42rem', height: '78rem' });
    setModalComponent(<ChooseVisitModal noType={true} workplaceId={workplaceId} />);
    setShowHeader(false);
    showModal();
  }, [setCustomStyles, showModal, setModalComponent, md]);

  const duplicateHandler = (entity: VisitEntity) => {
    if (entity.visitId) {
      setModalComponent(<ChooseDateDashboardVisit visitId={entity.visitId} />);
      setShowHeader(false);
      showModal();
    }
  };

  const showAndEditHandler = ({ date }: VisitEntity) => navigate(date.url);

  const selectAllHandler = async () => {
    setLoading('workplace-visits');
    const response = await getAllWorkplaceVisits({
      ...fetchParams,
      ...filterParams,
    });

    addMultipleSelection(
      response.data.tableBody.map((x: VisitEntityResponse) => ({
        entity: { ...x, id: x.visitId },
        id: x.visitId,
      })),
    );
    setLoaded('workplace-visits');
  };

  const deleteHandler = (entity: VisitEntity) => {
    setModalComponent(
      <DeleteVisitsModal actionCallback={() => deleteSelection(entity.id)} entity={entity} />,
    );
    setShowHeader(false);
    showModal();
  };

  const exportCsvHandler = async () => {
    setLoading('workplace-visits');
    await getVisitsCSV(
      '',
      itemsSelected.map((item: VisitEntity) => item.id),
    );
    setLoaded('workplace-visits');
  };

  const actionHandlers = {
    exportCSV: exportCsvHandler,
    selectAll: selectAllHandler,
    delete: deleteHandler,
    duplicate: duplicateHandler,
    edit: showAndEditHandler,
    show: showAndEditHandler,
  };

  if (visits.error) {
    toast.error(visits.error);
    dispatch(clearCompanyVisitError());
  }

  if (isEmpty(visits.meta.columnsOrder)) return null;

  if (isEmpty(visits.data.tableBody) && isEmpty(filterParams)) {
    return (
      <CardSmall>
        <StyledEmptyVisitsWrap>
          <StyledEmptyVisits>
            <div>{t('company_details.visits.no_visits')}</div>
            <div>
              <DefaultButton
                icon={<PlusIcon />}
                label={t('common.add_visit')}
                onClick={addNewVisit}
              />
            </div>
          </StyledEmptyVisits>
        </StyledEmptyVisitsWrap>
      </CardSmall>
    );
  }

  return (
    <CardSmall>
      <StyledColumn>
        <TableFilters />
        <TableGrid
          actionHandlers={actionHandlers}
          addButton={
            <DefaultButton
              icon={<PlusIcon />}
              label={t('common.add_visit')}
              onClick={addNewVisit}
            />
          }
          allFilteredOutMessage={t('company_details.visits.all_filtered_out')}
          bodyData={visits.data.tableBody}
          columnsOrder={visits.meta.columnsOrder}
          componentsMap={visitsComponentsMap}
          loadResource='workplace-visits'
          resultsSelector='company_details.visits'
          totalCount={visits.meta.totalCount}
          withCheckbox
        />
        <StyledTableBottomPanel>
          <PaginationComposed loadResource='workplace-visits' />
          <ButtonWrapper align='right'>
            <CommonActionsPanel actionHandlers={actionHandlers}></CommonActionsPanel>
          </ButtonWrapper>
        </StyledTableBottomPanel>
      </StyledColumn>
    </CardSmall>
  );
};

const VisitsComposed = withTableProviders(Visits, {
  fetchFnExtraParams: (props) => ({ workplace_id: props.workplaceId }),
  fetchFunction: getWorkplaceVisits,
  loadResource: 'workplace-visits',
  metaUrl: 'workplace_visits',
});

export { VisitsComposed as VisitsWorkplace };
