import React, { FC, useCallback, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { capitalize } from 'lodash';
import { StandardLabel } from 'components/StandardLabel/StandardLabel';
import { Margin } from 'styles/utils';
import { LevelField } from 'components/Forms/LevelField/LevelField';
import { TextField } from 'components/Forms/TextField/TextField';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { StyledOrgNumberWrapper } from './styles';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { StyledHorizontalRule } from 'components/MultiSelectList/styles';
import { useModal } from 'components/Modal/Modal';
import { getCompanyStatus } from 'ducks/companies/actions';
import { CompanyEntity, CompanyStatusResponse } from 'ducks/companies/types';
import { CompanyDetails } from './CompanyDetails';
import { toast } from 'react-hot-toast';

interface CreateCompanyFormPropTypes extends RouteComponentProps {
  addNext?: boolean;
  callback?: (company: CompanyEntity) => void;
  numberKnown?: boolean;
}

type ErrorValidation = {
  orgNumber?: string;
};

const identity = (value: string) => value; // https://github.com/final-form/react-final-form/issues/130

export const CreateCompanyForm: FC<CreateCompanyFormPropTypes> = ({
  addNext = false,
  numberKnown = true,
  callback,
}) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<CompanyStatusResponse | null>(null);
  const [numberKnownStatus, setNumberKnownStatus] = useState<boolean>(numberKnown);
  const [orgNumber, setOrgNumber] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const { setShowHeader } = useModal();

  const onSubmit = useCallback(
    async (values, e) => {
      e.preventDefault();
      try {
        setLoading(true);
        if (values.number_known) {
          const orgNumberValue = values?.orgNumber?.replace(/\D/g, '');
          if (orgNumberValue.length && status?.company?.info?.number !== orgNumberValue) {
            const status = await getCompanyStatus(orgNumberValue);
            setStatus(status);
            setOrgNumber(orgNumberValue);
          }
        }
      } catch (err) {
        toast.error(err as string);
      } finally {
        setLoading(false);
      }
    },
    [status],
  );

  useEffect(() => {
    !numberKnownStatus || (numberKnownStatus && status?.status === 3)
      ? setShowHeader(false)
      : setShowHeader(true);
  }, [numberKnownStatus]);

  return (
    <>
      <Form
        initialValues={{
          number_known: numberKnownStatus || true,
          orgNumber: status?.company?.info?.number || '',
        }}
        onSubmit={onSubmit}
        render={({ values }) => (
          <>
            <Margin margin='3.2rem 0'>
              <Margin margin='0 4.8rem'>
                <Margin bottom='0.8rem'>
                  <StandardLabel>{t('forms.do_you_know_orginizational_number')}</StandardLabel>
                </Margin>
                <StyledOrgNumberWrapper>
                  <Margin right='1.6rem'>
                    <Field
                      component={LevelField}
                      name='number_known'
                      options={[
                        { label: capitalize(t('common.yes')), value: true },
                        { label: capitalize(t('common.no')), value: false },
                      ]}
                      stateChangeFunction={setNumberKnownStatus}
                    />
                  </Margin>
                  {values.number_known && (
                    <>
                      <Field
                        component={TextField}
                        name='orgNumber'
                        parse={identity}
                        placeholder={t('forms.org_number')}
                      />
                      <Margin left='1.6rem'>
                        <DefaultButton
                          isDisabled={loading}
                          loading={loading}
                          label={t('forms.find_company')}
                          onClick={(e) => onSubmit(values, e)}
                          type='button'
                        />
                      </Margin>
                    </>
                  )}
                </StyledOrgNumberWrapper>

                {values.number_known && (
                  <>
                    {status !== null && status?.status !== 3 && (
                      <Margin margin='3.2rem -1.6rem'>
                        <StyledHorizontalRule />
                      </Margin>
                    )}
                    {status?.status === -1 && <InfoBox message={'Error'} variant='error' />}
                    {status?.status === 0 && (
                      <InfoBox message={t('forms.company_not_found')} variant='error' />
                    )}
                    {(status?.status === 1 || status?.status === 2) && (
                      <InfoBox
                        content={
                          <>
                            <Margin top='0.4rem'>
                              <b>{status.company?.info?.name}</b>{' '}
                              {t('company_details.create_company.found_in_crm_message')}
                            </Margin>
                            <Margin top='1.2rem'>
                              <a href={`/redesigned/companies/${status.company?.id}/basic-data`}>
                                {t('common.click_here')}
                              </a>{' '}
                              {t('company_details.create_company.to_get_to_the_company')}
                            </Margin>
                          </>
                        }
                      />
                    )}
                  </>
                )}
              </Margin>
            </Margin>
            {(!values.number_known || (values.number_known && status?.status === 3)) && (
              <CompanyDetails
                addNext={addNext}
                callback={callback}
                numberKnown={numberKnownStatus}
                orgNumberValue={orgNumber}
                status={status}
              />
            )}
          </>
        )}
        validate={async (values) => {
          const errors: ErrorValidation = {};
          if (status?.company?.info?.number !== values.orgNumber) {
            if (values?.orgNumber.length > 0 && values?.orgNumber?.replace(/\D/g, '').length < 9) {
              errors.orgNumber = t('forms.incorrect_number');
            }
          }
          return errors ? errors : null;
        }}
      />
    </>
  );
};
