import styled, { css } from 'styled-components/macro';

import { StatusBadgeSize } from './StatusBadge';

export const Badge = styled.span<{ fillIn?: boolean; size: StatusBadgeSize }>`
  ${({ theme }) => theme.fontSizes.xs};
  align-items: center;
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.sizes.md};
  display: inline-flex;
  font-weight: 600;
  justify-content: center;
  padding: ${({ theme, size }) =>
    size === 'small' || size === 'verySmall'
      ? `0 ${theme.sizes.xs} `
      : `${theme.sizes.xxs} ${theme.sizes.hmd} `};
  text-transform: capitalize;
  white-space: nowrap;

  &::first-letter {
    text-transform: capitalize;
  }

  ${({ fillIn }) =>
    fillIn &&
    css`
      border: none;
    `};
  ${({ size }) =>
    size === 'verySmall' &&
    css`
      font-size: 1.1rem;
      height: 18px;
    `};
`;

export const SuccessBadge = styled(Badge)`
  background-color: ${({ theme, fillIn }) => (fillIn ? theme.colors.green500 : 'transparent')};
  border-color: ${({ theme }) => theme.colors.green100};
  color: ${({ theme, fillIn }) => (fillIn ? 'white' : theme.colors.green500)};
`;

export const LowAlertBadge = styled(Badge)`
  background-color: ${({ theme, fillIn }) => (fillIn ? theme.colors.yellow500 : 'transparent')};
  border-color: ${({ theme }) => theme.colors.yellow100};
  color: ${({ theme, fillIn }) => (fillIn ? 'white' : theme.colors.yellow500)};
`;

export const HighAlertBadge = styled(Badge)`
  background-color: ${({ theme, fillIn }) => (fillIn ? theme.colors.orange500 : 'transparent')};
  border-color: ${({ theme }) => theme.colors.orange100};
  color: ${({ theme, fillIn }) => (fillIn ? 'white' : theme.colors.orange500)};
`;

export const PrimaryBadge = styled(Badge)`
  background-color: ${({ theme, fillIn }) => (fillIn ? theme.colors.blue500 : 'transparent')};
  border-color: ${({ theme }) => theme.colors.blue100};
  color: ${({ theme, fillIn }) => (fillIn ? 'white' : theme.colors.blue500)};
`;

export const ActivatedBadge = styled(Badge)`
  border-color: ${({ theme }) => theme.colors.black300};
  color: ${({ theme }) => theme.colors.black900};
`;

export const NotActiveBadge = styled(Badge)`
  background-color: ${({ theme }) => theme.colors.black50};
  border: 1px solid ${({ theme }) => theme.colors.black100};
  color: ${({ theme }) => theme.colors.black300};
`;

export const NeutralBadge = styled(Badge)`
  border-color: ${({ theme }) => theme.colors.black100};
  color: ${({ theme }) => theme.colors.black500};
`;

export const FailBadge = styled(Badge)`
  background-color: ${({ theme, fillIn }) => (fillIn ? theme.colors.red500 : 'transparent')};
  border-color: ${({ theme }) => theme.colors.red100};
  color: ${({ theme, fillIn }) => (fillIn ? 'white' : theme.colors.red500)};
`;
