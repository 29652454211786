import React from 'react';
import i18n from 'i18n';

import { StyledNoWrapCellContent } from 'components/Table/TableRow/styles';
import { NewVisitContact } from 'ducks/newVisit';
import { StatusBadge } from 'components/StatusBadge/StatusBadge';
import { SpanCenter } from 'styles/utils';
import { Tooltip } from 'components/Tooltip/Tooltip';

import { ReactComponent as CertificateIcon } from 'images/certificate.svg';
import { ReactComponent as PdfIcon } from 'images/pdfIcon.svg';

const t = (x: string) => i18n.t(x);

export const contactsTableMapper = Object.freeze({
  name: {
    component: ({ name }: NewVisitContact) => (
      <StyledNoWrapCellContent>{name || '-'}</StyledNoWrapCellContent>
    ),
    size: 'auto',
  },
  role: {
    component: ({ role }: NewVisitContact) => (
      <StyledNoWrapCellContent>{role || '-'}</StyledNoWrapCellContent>
    ),
    size: 'auto',
  },
  phone: {
    component: ({ phone }: NewVisitContact) => (
      <StyledNoWrapCellContent>{phone || '-'}</StyledNoWrapCellContent>
    ),
    size: 'auto',
  },
  email: {
    component: ({ email }: NewVisitContact) => (
      <StyledNoWrapCellContent>{email || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  obligatory_training: {
    component: ({ obligatoryTraining }: NewVisitContact) => (
      <SpanCenter>
        <StatusBadge variant={obligatoryTraining ? 'success' : 'fail'}>
          {t(`common.${obligatoryTraining}`)}
        </StatusBadge>
      </SpanCenter>
    ),
    headerComponent: () => (
      <Tooltip centerContent label={t(`table.columns.obligatory_training`)}>
        <CertificateIcon data-testid='th-obligatory_training' />
      </Tooltip>
    ),
    size: 'min-content',
  },
  show_in_report: {
    component: ({ includeInReport }: NewVisitContact) => {
      return (
        <SpanCenter>
          <StatusBadge variant={includeInReport ? 'success' : 'fail'}>
            {t(`common.${includeInReport ? 'yes' : 'no'}`)}
          </StatusBadge>
        </SpanCenter>
      );
    },
    headerComponent: () => (
      <Tooltip centerContent label={t(`table.columns.show_in_report`)}>
        <PdfIcon data-testid='th-report' height='24' width='24' />
      </Tooltip>
    ),
    size: 'min-content',
  },
});

export const contactsColumns = [
  'name',
  'role',
  'phone',
  'email',
  'obligatory_training',
  'show_in_report',
] as const;

export type ContactsColumns = typeof contactsColumns[number];
