import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { Card, StyledCardDivider, StyledCardHeader } from 'components/Card/Card';
import { ButtonWrapper } from 'components/ButtonWrapper/ButtonWrapper';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { ValueWithLabel } from 'components/ValueWithLabel/ValueWithLabel';
import { TextField } from 'components/Forms/TextField/TextField';
import { CompanyEntity } from 'ducks/companies/types';
import { StyledForm, StyledLink } from './styles';
import { yupValidate } from 'utils/yupValidate';
import { useModal } from 'components/Modal/Modal';
import { SendEmailsModal } from '../Contacts/ContactsModals';
import { updateCompany } from 'ducks/companies/actions';
import { ToggleBar } from 'components/ToggleBar/ToggleBar';

import { ReactComponent as EditIcon } from 'images/editIcon.svg';

export const BasicDataContactInfo: FC<{ company: CompanyEntity; clickableEmail?: boolean }> = ({
  company,
  clickableEmail = true,
}) => {
  const { phone, email, phones, emails, website } = company.contact;
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();
  const { showModal, setModalComponent, setShowHeader } = useModal();
  const dispatch = useDispatch();

  const additionalPhones = useMemo(
    () => phones.filter(({ typeSymbol }) => typeSymbol !== 'internal'),
    [company.id],
  );
  const additionalEmails = useMemo(
    () => emails.filter(({ typeSymbol }) => typeSymbol !== 'internal'),
    [company.id],
  );

  const onSubmit = useCallback(
    async (values) => {
      await dispatch(
        updateCompany({
          companyId: company.id,
          phone: values.phone || '',
          email: values?.email?.trim() || '',
          website: values.website || '',
        }),
      );
      setIsEdit(false);
    },
    [company.id, dispatch],
  );
  const validWebsite =
    website && !website.match(/^(https{0,1}:){0,1}\/\//) ? '//' + website : website;
  return (
    <Card>
      <Form
        initialValues={{ phone: phone || '', email: email || '', website: website || '' }}
        keepDirtyOnReinitialize
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => (
          <>
            <StyledCardHeader>
              {t('company_details.basic-data.contact_information')}
              {isEdit ? (
                <ButtonWrapper>
                  <DefaultButton
                    capitalize
                    isDisabled={submitting}
                    label={t('common.cancel')}
                    onClick={() => setIsEdit(false)}
                    size='small'
                    type='button'
                    variant='secondary'
                  />
                  <DefaultButton
                    capitalize
                    form='contact-information-form'
                    isDisabled={submitting}
                    label={t('common.save')}
                    size='small'
                    type='submit'
                    variant='primary'
                  />
                </ButtonWrapper>
              ) : (
                <DefaultButton
                  capitalize
                  icon={<EditIcon />}
                  label={t('common.edit')}
                  onClick={() => setIsEdit(true)}
                  size='small'
                  type='button'
                  variant='tertiary'
                />
              )}
            </StyledCardHeader>
            <StyledCardDivider fullWidth />

            {isEdit ? (
              <StyledForm id='contact-information-form' onSubmit={handleSubmit}>
                <Field
                  component={TextField}
                  label={t('table.columns.website')}
                  name='website'
                  placeholder={t('table.columns.website')}
                />
                <Field
                  component={TextField}
                  label={t('table.columns.email')}
                  name='email'
                  placeholder={t('forms.email_address')}
                />
                <Field
                  component={TextField}
                  label={t('table.columns.phone')}
                  name='phone'
                  placeholder={t('table.columns.phone')}
                />
              </StyledForm>
            ) : (
              <>
                <ValueWithLabel label={t('table.columns.website')}>
                  {website ? (
                    <StyledLink href={validWebsite || ''} rel='noopener noreferrer' target='_blank'>
                      {website}
                    </StyledLink>
                  ) : (
                    '-'
                  )}
                </ValueWithLabel>
                <ValueWithLabel label={t('table.columns.email')}>
                  {!email && '-'}
                  {!clickableEmail && email}
                  {clickableEmail && email && (
                    <StyledLink
                      as='button'
                      onClick={() => {
                        setModalComponent(
                          <SendEmailsModal companyId={company.id} itemsSelected={[email]} />,
                        );
                        setShowHeader(false);
                        showModal();
                      }}
                    >
                      {email}
                    </StyledLink>
                  )}
                </ValueWithLabel>
                <ValueWithLabel label={t('table.columns.phone')}>
                  {phone ? <StyledLink href={`tel:${phone}`}>{phone}</StyledLink> : '-'}
                </ValueWithLabel>
                {(!isEmpty(additionalEmails) || !isEmpty(additionalPhones)) && (
                  <ToggleBar>
                    {additionalEmails.map(({ email, typeSymbol }) => (
                      <ValueWithLabel
                        key={typeSymbol}
                        label={t(`company_details.basic-data.contacts.${typeSymbol}`)}
                      >
                        <StyledLink
                          as='button'
                          onClick={() => {
                            setModalComponent(
                              <SendEmailsModal companyId={company.id} itemsSelected={[email]} />,
                            );
                            setShowHeader(false);
                            showModal();
                          }}
                        >
                          {email}
                        </StyledLink>
                      </ValueWithLabel>
                    ))}
                    {additionalPhones.map(({ number, typeSymbol }) => (
                      <ValueWithLabel
                        key={typeSymbol}
                        label={t(`company_details.basic-data.contacts.${typeSymbol}`)}
                      >
                        <StyledLink href={`tel:${number}`}>{number}</StyledLink>
                      </ValueWithLabel>
                    ))}
                  </ToggleBar>
                )}
              </>
            )}
          </>
        )}
        validate={yupValidate(schema)}
      />
    </Card>
  );
};

const schema = yup.object().shape({
  email: yup.string().trim().email(),
  phone: yup.string(),
  website: yup
    .string()
    .trim()
    .matches(
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w?[a-zA-Z-_%/@?]+)*([^/\w?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      { excludeEmptyString: true, message: 'Ugyldig format' },
    ),
});
