import React from 'react';
import { useSelector } from 'react-redux';
import { allFilteredDistrictsSelector } from 'ducks/powerSearch/selectors';
import { DashboardVisitEntity } from 'ducks/dashboard/types';
import { VisitEntity } from 'ducks/visits/types';
import { TextOnclickLink } from 'components/TextOnclickLink/TextOnclickLink';
import { navigate } from '@reach/router';
import { StyledNoWrapCellContent } from 'components/Table/TableRow/styles';
import { formatDate } from './date';

export const DateComponent: React.FC<DashboardVisitEntity | VisitEntity> = (props) => {
  const { date, tipsExternalId } = props;

  const allDistricts = useSelector(allFilteredDistrictsSelector).map(
    (district: { value: string }) => district.value,
  );

  return (
    <TextOnclickLink
      data-testid={
        date.inViolation && !tipsExternalId ? 'date-cell-component-exceed' : 'date-cell-component'
      }
      isDanger={tipsExternalId ? false : date.inViolation}
      onClick={() => {
        if (date.url) {
          return date.url.includes('redesigned')
            ? navigate(date.url, {
                state: {
                  ...window.history.state,
                  previousPathname: window.location.pathname,
                  districtNames: {
                    type: 'string_array',
                    values: allDistricts,
                  },
                  previousDistrictNames: window.history?.state?.districtNames ?? [],
                },
              })
            : window.open(date.url, '_blank');
        }
      }}
    >
      <StyledNoWrapCellContent>{date.value ? formatDate(date.value) : '-'}</StyledNoWrapCellContent>
    </TextOnclickLink>
  );
};
