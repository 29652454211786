import styled, { css } from 'styled-components/macro';
import { StyledTertiaryButton } from 'components/DefaultButton/styles';

interface StyledLevelFieldButtonProps {
  active: boolean;
  clearButton: boolean;
  size: 'small' | 'medium' | 'large';
}

export const StyledLevelFieldButtonsWrapper = styled.div`
  display: flex;

  & ${StyledTertiaryButton} {
    margin-left: ${({ theme }) => theme.sizes.md};
  }
`;

export const StyledLevelFieldButton = styled.button<StyledLevelFieldButtonProps>`
  ${({ size, theme }) => {
    const sizes = {
      small: {
        fontSize: theme.fontSizes.base,
        lineHeight: theme.lineHeight.lg,
        padding: `${theme.sizes.xxs} ${theme.sizes.xs}`,
      },
      medium: {
        fontSize: theme.fontSizes.base,
        lineHeight: theme.lineHeight.lg,
        padding: `${theme.sizes.xs} ${theme.sizes.sm}`,
      },
      large: {
        fontSize: theme.fontSizes.lg,
        lineHeight: theme.lineHeight.xl,
        padding: `${theme.sizes.hmd} ${theme.sizes.md}`,
      },
    };

    return css`
      ${sizes[size].fontSize};
      line-height: ${sizes[size].lineHeight};
      padding: ${sizes[size].padding};
    `;
  }}

  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.black100};
  color: ${({ theme }) => theme.colors.black500};
  display: inline-block;
  min-width: 4.4rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue50};
  }

  &:first-child {
    border-radius: ${({ theme }) => `${theme.borderRadius.sm} 0 0 ${theme.borderRadius.sm}`};
  }

  &:nth-last-child(${({ clearButton }) => (clearButton ? '2' : '1')}) {
    border-radius: ${({ theme }) => `0 ${theme.borderRadius.sm} ${theme.borderRadius.sm} 0`};
  }

  &:not(:nth-last-child(${({ clearButton }) => (clearButton ? '2' : '1')})) {
    border-right: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.black50};
    cursor: not-allowed;
  }

  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.colors.blue500} !important;
      border-color: ${theme.colors.blue500};
      box-shadow: ${theme.boxShadows.blue300};
      color: white;
      position: relative;

      &:hover {
        background-color: ${theme.colors.blue500};
      }
    `};
`;
