import React, { useCallback } from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { capitalize, isEmpty } from 'lodash';

import { StyledForm } from 'components/Forms/styles';
import {
  StyledDivider,
  StyledModalButtonsWrapper,
  StyledModalHeader,
  StyledModalItemToRemove,
  StyledModalMessage,
  useModal,
} from 'components/Modal/Modal';
import { TextField } from 'components/Forms/TextField/TextField';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { yupValidate } from 'utils/yupValidate';
import { TextareaField } from 'components/Forms/TextareaField/TextareaField';
import { addSavedSearches, removeSavedSearches, updateSavedSearch } from 'ducks/users/actions';
import { currentUserSelector } from 'ducks/users/selectors';
import { SavedSearchesType } from 'ducks/users/types';
import { SearchPriorNotificationsEntity } from 'ducks/powerSearch/priorNotifications/types';
import { ValueWithLabel } from 'components/ValueWithLabel/ValueWithLabel';
import { formatDate } from 'utils/date';

const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
});

interface SaveSearchPropTypes {
  params: {
    description?: string;
    id?: string;
    name?: string;
    query: string;
    state: object;
    tag: string;
  };
}

interface SaveSearchFormValues {
  name: string;
  description: string;
}

export const SaveSearch = ({ params }: SaveSearchPropTypes) => {
  const { t } = useTranslation();
  const { hideModal } = useModal();
  const dispatch = useDispatch();
  const userId = useSelector(currentUserSelector)!.id;
  const isEdit = !!params?.id;

  const onSubmit = useCallback(
    async (values: SaveSearchFormValues) => {
      const valuesToPost = {
        userId,
        attributes: {
          id: 'new',
          ...params,
          name: values.name,
          description: values.description,
        },
      };
      isEdit ? dispatch(updateSavedSearch(valuesToPost)) : dispatch(addSavedSearches(valuesToPost));
      hideModal();
    },
    [userId, dispatch],
  );

  const initialValues = {
    name: params?.name || '',
    description: params?.description || '',
  };

  return (
    <>
      <StyledModalHeader>{t(`power_search.save_search`)}</StyledModalHeader>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <StyledForm onSubmit={handleSubmit}>
            <Field
              component={TextField}
              label={t('table.columns.name')}
              name='name'
              placeholder={t('table.columns.name')}
              required
              subscription={{ value: true, touched: true, error: true }}
            />
            <Field
              component={TextareaField}
              label={t('table.columns.description')}
              name='description'
              placeholder={t('table.columns.description')}
              subscription={{ value: true }}
            />
            <StyledModalButtonsWrapper>
              <DefaultButton
                capitalize
                label={t('common.cancel')}
                onClick={hideModal}
                type='button'
                variant='tertiary'
              />
              <FormSpy subscription={{ errors: true }}>
                {({ errors }) => (
                  <DefaultButton
                    capitalize
                    isDisabled={!isEmpty(errors)}
                    label={t('common.save')}
                    testid='submit-save-search'
                    type='submit'
                  />
                )}
              </FormSpy>
            </StyledModalButtonsWrapper>
          </StyledForm>
        )}
        subscription={{}}
        validate={yupValidate(schema)}
      />
    </>
  );
};

interface DeleteSavedSearchPropTypes {
  itemsSelected: SavedSearchesType[];
}

export const DeleteSavedSearch = ({ itemsSelected }: DeleteSavedSearchPropTypes) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector(currentUserSelector)!.id;
  const { hideModal } = useModal();

  return (
    <>
      <StyledModalHeader>{`${t('common.delete_all')}?`}</StyledModalHeader>
      {itemsSelected.map((search) => (
        <StyledModalItemToRemove key={search.id}>
          {search.name}
          {search.description && ` - ${search.description}`}
        </StyledModalItemToRemove>
      ))}
      <StyledModalMessage>{t('power_search.bookmarks.data_lose')}</StyledModalMessage>
      <StyledModalButtonsWrapper>
        <DefaultButton
          label={capitalize(t('common.cancel'))}
          onClick={hideModal}
          variant='secondary'
        />

        <DefaultButton
          label={capitalize(t('common.delete'))}
          onClick={async () => {
            const searchIds = itemsSelected.map(({ id }) => id);

            await dispatch(removeSavedSearches({ userId, searchIds }));
            hideModal();
          }}
          variant='danger'
        />
      </StyledModalButtonsWrapper>
    </>
  );
};

interface ShowPriorNotificationPropTypes {
  notification: SearchPriorNotificationsEntity;
}

export const ShowPriorNotification = ({ notification }: ShowPriorNotificationPropTypes) => {
  const { t } = useTranslation();
  const { hideModal } = useModal();

  return (
    <>
      <StyledModalHeader>{`${t('common.prior_notifications')}`}</StyledModalHeader>
      <StyledDivider />

      <ValueWithLabel label={t('table.columns.start_date')}>
        {notification.startDate ? formatDate(notification.startDate) : '-'}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.reporting_company_name')}>
        {notification.reportingCompanyName || '-'}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.project_type')}>
        {notification.projectType || '-'}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.workplace_address')}>
        {notification.workplaceAddress || '-'}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.postal_code')}>
        {notification.postalCode || '-'}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.postal_area')}>
        {notification.postalArea || '-'}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.municipality_name')}>
        {notification.municipalityName || '-'}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.constructor_name')}>
        {notification.constructorName || '-'}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.constructor_org_no')}>
        {notification.constructorOrgNo || '-'}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.organisation_contact')}>
        {notification.organisationContact || '-'}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.organisation_contact_phone')}>
        {notification.organisationContactPhone || '-'}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.organisation_representative')}>
        {notification.organisationRepresentative || '-'}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.constructor_representative_org_no')}>
        {notification.constructorRepresentativeOrgNo || '-'}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.representative_contact_person')}>
        {notification.representativeContactPerson || '-'}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.representative_contact_phone')}>
        {notification.representativeContactPhone || '-'}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.hms_plan')}>
        {t(`common.${notification.hmsPlan ? 'yes' : 'no'}`)}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.end_date')}>
        {notification.endDate ? formatDate(notification.endDate) : '-'}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.expected_number_of_companies')}>
        {notification.expectedNumberOfCompanies || '-'}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.expected_number_of_employees')}>
        {notification.expectedNumberOfEmployees || '-'}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.project_manager_name')}>
        {notification.projectManagerName || '-'}
      </ValueWithLabel>
      <ValueWithLabel label={t('table.columns.comment')}>
        {notification.comment || '-'}
      </ValueWithLabel>

      <StyledModalButtonsWrapper>
        <DefaultButton
          label={capitalize(t('common.cancel'))}
          onClick={hideModal}
          variant='secondary'
        />
      </StyledModalButtonsWrapper>
    </>
  );
};
