import React from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import { StyledModalHeader, useModal } from 'components/Modal/Modal';
import { StyledAuthInput, StyledSubmitButton } from './styles';
import { resetUser } from './helpers';

export const ResetUserModal = () => {
  const [email, setEmail] = React.useState('');
  const { t } = useTranslation();
  const { hideModal } = useModal();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await resetUser(email);
      if (response.type === 'error') {
        toast.error(t('auth.reset_user_error'));
        console.log(response.message);
      } else {
        toast.success(t('auth.reset_user_success'));
      }
      hideModal();
    } catch (e) {
      console.log(e);
      toast.error(t('auth.reset_user_error'));
    }
  };

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <form onSubmit={onSubmit}>
      <StyledModalHeader>{t('auth.reset_user_submit')}</StyledModalHeader>
      <p>{t('auth.instructions.reset_user_mfa')}</p>
      <StyledAuthInput
        autoFocus
        aria-label='reset user email'
        placeholder={t('auth.email')}
        type='email'
        onChange={handleEmail}
      />
      <StyledSubmitButton type='submit'>{t('auth.reset_user_submit')}</StyledSubmitButton>
    </form>
  );
};
