import styled, { css } from 'styled-components/macro';

import { ReactComponent as CalendarIcon } from 'images/calendarIcon.svg';
import { ReactComponent as ClearIcon } from 'images/closeBigIcon.svg';

export const StyledButton = styled.button<{
  isDisabled?: boolean;
  isExceed?: boolean;
  size: 'small' | 'medium';
  width?: 'fullWidth';
}>`
  ${({ size, theme }) => {
    const sizes = {
      small: {
        fontSize: theme.fontSizes.base,
        icon: theme.icon.sm,
        lineHeight: theme.lineHeight.lg,
        margin: theme.sizes.xs,
        padding: theme.sizes.xxs,
      },
      medium: {
        fontSize: theme.fontSizes.base,
        icon: theme.icon.md,
        lineHeight: theme.lineHeight.lg,
        margin: theme.sizes.xs,
        padding: theme.sizes.xs,
      },
    };

    return css`
      padding: ${sizes[size].padding};

      & svg {
        color: ${theme.colors.black300};
        ${sizes[size].icon};
      }

      & > span {
        ${sizes[size].fontSize};
        line-height: ${sizes[size].lineHeight};
        margin: 0 ${sizes[size].margin};
      }
    `;
  }}
  align-items: center;
  background-color: white;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: 1px solid ${({ theme }) => theme.colors.black300};
  display: inline-flex;
  justify-content: space-between;

  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      background-color: ${theme.colors.black50};
      border-color: ${theme.colors.black100};
      color: ${theme.colors.black200};
      cursor: default;
    `}

  ${({ width }) =>
    width === 'fullWidth' &&
    css`
      width: 100%;
    `}
    
  ${({ isExceed, theme }) =>
    isExceed &&
    css`
      color: ${theme.colors.red500};
    `}
`;

export const StyledPlaceholder = styled.span`
  color: ${({ theme }) => theme.colors.black300};
`;

export const StyledCalendarIcon = styled(CalendarIcon)`
  ${({ theme }) => theme.icon.md};
  margin-left: ${({ theme }) => theme.sizes.xs};
`;

export const StyledClearIcon = styled(ClearIcon)`
  ${({ theme }) => theme.icon.md};
  margin-left: ${({ theme }) => theme.sizes.xs};
`;
