import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { get } from 'lodash';

import { API_BASE_URL } from './apiUrl.ts';
import { toast } from 'react-hot-toast';

const API = axios.create({
  baseURL: API_BASE_URL,
  headers: { Accept: 'application/json' },
  withCredentials: true,
  transformRequest: [
    (req) => (req?.constructor?.name === 'FormData' ? req : decamelizeKeys(req)),
    ...axios.defaults.transformRequest,
  ],
  transformResponse: [...axios.defaults.transformResponse, (res) => camelizeKeys(res)],
});

API.interceptors.request.use(
  // it is super weird, but this is what client expect.
  // see more details https://skalar.atlassian.net/browse/RVO-828
  // related file: packages/client/src/components/SearchBar/SearchBar.tsx:104
  (config) => {
    const areAllDistrictsSelected = sessionStorage.getItem('areAllDistrictsSelected') === 'true';
    if (areAllDistrictsSelected)
      return {
        ...config,
        params: { ...config.params, 'filter[district_names][values]': undefined },
      };
    else return config;
  },
  null,
  { synchronous: true },
);

API.interceptors.response.use(
  (response) => response, // Any status code that lie within the range of 2xx cause this function to trigger
  (error) => {
    const customError = {
      ...error,
      message: get(error, 'response.data.error', error.message),
      status: get(error, 'response.data.status'),
    };

    if (customError.message && customError.status) toast.error(customError.message);
    return Promise.reject(customError);
  },
);

export { API };
export { BASE_URL, API_BASE_URL } from './apiUrl.ts';
