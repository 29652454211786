import styled, { keyframes } from 'styled-components/macro';

export const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const StyledShading = styled.div`
  position: relative;
`;

interface ShadingProps {
  isCompanyVisit?: boolean;
  large?: boolean;
}

export const StyledLeftShading = styled.div<ShadingProps>`
  animation: ${fadeInAnimation} 0.15s forwards;
  background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, white 100%);
  bottom: 7px;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: ${({ large }) => (large ? '20rem' : '10rem')};
  z-index: 1;

  @-moz-document url-prefix() {
    bottom: 14px;
  }
`;

export const StyledRightShading = styled.div<ShadingProps>`
  animation: ${fadeInAnimation} 0.15s forwards;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 100%);
  bottom: 7px;
  height: 100%;
  pointer-events: none;
  position: absolute;
  right: ${({ isCompanyVisit }) => (isCompanyVisit ? '0px' : '7px')};
  width: ${({ large }) => (large ? '20rem' : '10rem')};
  z-index: 1;

  @-moz-document url-prefix() {
    bottom: 14px;
    right: 14px;
  }
`;
