import { FormEvent, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components/macro';
import { CheckboxField } from 'components/Forms/CheckboxField/CheckboxField';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { RadioButtonWithLabel } from 'components/RadioButtonWithLabel/RadioButtonWithLabel';
import { StyledFieldWrapper, StyledLabel } from 'components/Forms/styles';
import { Textarea } from 'components/Forms/Textarea/Textarea';
import { TipsThemesResponse } from 'ducks/constants/types';
import { updateCompanyVisitTips } from 'ducks/newVisit';
import { useAppDispatch } from 'store';
import { useModal } from 'components/Modal/Modal';
import {
  StyledModalButtonsWrapper,
  StyledModalHeader,
  StyledModalMessage,
} from 'components/Modal/styles';

interface TipsATProps {
  companyVisitId: string;
  currentTipValue?: string | number;
  tipsOptions: TipsThemesResponse[];
}

const StyledErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.red500};
  font-size: 1.2rem;
`;

export const TipsATModal = ({
  companyVisitId,
  currentTipValue = '',
  tipsOptions = [],
}: TipsATProps): React.ReactElement => {
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState(false);
  const [tipsThemeId, setTipThemeThemeNumber] = useState(currentTipValue || tipsOptions[0]?.id);
  const [error, setError] = useState(null);
  const [comment, setComment] = useState('');
  const [sendReport, setSendReport] = useState(false);
  const { hideModal } = useModal();
  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    setIsProcessing(true);
    setError(null);
    dispatch(
      updateCompanyVisitTips({
        comment,
        companyVisitId,
        tipsThemeId,
        sendReport,
      }),
    )
      .then((response) => {
        setIsProcessing(false);
        // @ts-ignore
        if (response?.error?.message) {
          // @ts-ignore
          setError(`${t('new_visits.something_went_wrong')}: ${response?.error?.message}`);
        } else {
          hideModal();
        }
        return response;
      })
      .catch(() => setError(t('new_visits.something_went_wrong')));
  };

  return (
    <>
      <StyledModalHeader>{t('new_visits.tips_at_title')}</StyledModalHeader>
      <StyledModalMessage>
        <StyledFieldWrapper data-testid='tips-at-modal-themes'>
          <StyledLabel>Velg tema*</StyledLabel>
          {tipsOptions.map((item) => (
            <RadioButtonWithLabel
              isSelected={tipsThemeId === item.id}
              key={item.id}
              onClick={() => setTipThemeThemeNumber(item.id)}
            >
              {item.name}
            </RadioButtonWithLabel>
          ))}
        </StyledFieldWrapper>

        <Textarea
          characterLimit={250}
          label={t('common.comment')}
          onChange={(event: FormEvent<HTMLTextAreaElement>) =>
            setComment((event?.target as HTMLTextAreaElement)?.value || '')
          }
          placeholder={t('forms.add_a_description')}
          required
          testId='tips-at-modal-comment-field'
          value={comment}
        />

        <StyledLabel>{t('common.visit_report')}</StyledLabel>
        <CheckboxField
          input={{ onChange: setSendReport, value: sendReport }}
          label={t('common.send_report')}
          testid='tips-at-modal-send-report-checkbox'
        />

        {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
      </StyledModalMessage>
      <StyledModalButtonsWrapper>
        <DefaultButton
          capitalize
          isDisabled={isProcessing}
          label={t('common.cancel')}
          onClick={hideModal}
          testid='tips-at-modal-cancel-btn'
          type='button'
          variant='tertiary'
        />
        <DefaultButton
          isDisabled={isProcessing || comment?.length === 0 || !tipsThemeId}
          label={t('new_visits.send_tips')}
          onClick={onSubmit}
          testid='tips-at-modal-submit-btn'
          type='submit'
          variant='primary'
        />
      </StyledModalButtonsWrapper>
    </>
  );
};
