import React from 'react';
import { CapsContent, StyledNoWrapCellContent } from 'components/Table/TableRow/styles';
import { createRevisedActivityDialogPath } from 'ducks/powerSearch/revisedActivities/actions';
import { createVisitDialogPath, SubmitVisitDialogResponse } from 'ducks/newVisit';
import { FC, useState } from 'react';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { TextOnclickLink } from 'components/TextOnclickLink/TextOnclickLink';
import { useAppDispatch } from 'store';
import { SubmitRevisedActivityDialogResponse } from 'ducks/powerSearch/revisedActivities/types';
import toast from 'react-hot-toast';

interface TipsIdLinkProps {
  tipsExternalId: string | null | undefined;
  entityId: string;
  entityType: 'companyVisit' | 'revisedActivity';
}

interface TipsDialogPath {
  payload: SubmitVisitDialogResponse | SubmitRevisedActivityDialogResponse;
}

export const TipsIdLink: FC<TipsIdLinkProps> = ({
  tipsExternalId,
  entityId,
  entityType,
}: TipsIdLinkProps) => {
  const [tipsLoading, setTipsLoading] = useState(false);
  const dispatch = useAppDispatch();

  if (!tipsExternalId) return <StyledNoWrapCellContent>{'-'}</StyledNoWrapCellContent>;

  const tipsDialog = async () => {
    setTipsLoading(true);

    let dialogPath: TipsDialogPath | undefined;

    switch (entityType) {
      case 'companyVisit':
        dialogPath = (await dispatch(createVisitDialogPath({ companyVisitId: entityId }))) as {
          payload: SubmitVisitDialogResponse;
        };
        break;
      case 'revisedActivity':
        dialogPath = (await dispatch(
          createRevisedActivityDialogPath({ revisedActivityId: entityId }),
        )) as {
          payload: SubmitRevisedActivityDialogResponse;
        };
        break;
      default:
        dialogPath = undefined;
    }

    if (dialogPath?.payload?.url) {
      window.open(dialogPath.payload.url, '_blank');
    } else if (dialogPath?.payload?.errorMessage) {
      toast.error(dialogPath.payload.errorMessage);
    }

    setTipsLoading(false);
  };

  return (
    <TextOnclickLink onClick={tipsDialog}>
      <CapsContent>{tipsLoading ? <LoadingSpinner small /> : tipsExternalId}</CapsContent>
    </TextOnclickLink>
  );
};
