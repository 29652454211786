import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { fetchEmbededUrl } from 'ducks/reports/actions';
import { useDispatch, useSelector } from 'react-redux';
import { StoreType } from 'store';
import { resetReport } from 'ducks/reports/reducers';
import { ReportsTabs } from './ReportsTabs';
import {
  StyledContextBar,
  StyledLayout,
} from 'containers/companyDetails/CompanyNavigationPanel/styles';
import { SpinnerWrapper } from 'hocs/composedTableProviders/styles';
import { SpinnerIcon } from 'utils/iconsMap';
import { Margin } from 'styles/utils';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { useTranslation } from 'react-i18next';

export const Reports: FC<RouteComponentProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reports = useSelector((state: StoreType) => state.reports);
  //@ts-ignore
  const reportTitle = props.location?.state?.reportTitle;
  React.useEffect(() => {
    dispatch(resetReport());
  }, [reportTitle]);

  React.useEffect(() => {
    dispatch(fetchEmbededUrl());
  }, [dispatch, reportTitle]);

  if (!reports) {
    return (
      <SpinnerWrapper style={{ paddingTop: '8rem' }}>
        <SpinnerIcon data-testid='composed-table-providers-loading-icon' />
      </SpinnerWrapper>
    );
  }
  if ('error' in reports || reports instanceof Error) {
    return (
      <StyledContextBar>
        <StyledLayout style={{ height: '90vh' }}>
          <Margin margin='5rem 0' right='auto'>
            <InfoBox
              message={
                //@ts-ignore
                reports.error || `${reports.message}. ${t('common.error_message')}`
              }
              variant='error'
            />
          </Margin>
        </StyledLayout>
      </StyledContextBar>
    );
  }

  return (
    <StyledContextBar>
      <StyledLayout>
        <ReportsTabs fetchedReports={reports} title={reportTitle} />
      </StyledLayout>
    </StyledContextBar>
  );
};
