import React, { FC } from 'react';

import { StyledHeadPanel } from './styles';
import { StyledLayout } from 'containers/styles';
import { Margin } from 'styles/utils';

export const HeadPanel: FC = ({ children }) => {
  return (
    <StyledHeadPanel>
      <StyledLayout>
        <Margin margin='0 2.4rem'>{children}</Margin>
      </StyledLayout>
    </StyledHeadPanel>
  );
};
