import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

import { InfoBox, variants } from 'components/InfoBox/InfoBox';
import { StyledCardHeader } from 'components/Card/Card';
import {
  AttachmentsToInstantRemove,
  AttachmentsToInstantRemoveProps,
} from 'components/Forms/AttachmentsToRemove/AttachmentsToInstantRemove';
import { Margin } from 'styles/utils';
import { useUnmount } from 'utils/hooks';
import { useModal } from 'components/Modal/Modal';
import { NewVisitCommentModal } from 'containers/newVisit/OrdinaryVisit/NewVisitCommentModal';
import {
  AttachmentsWithRichText,
  AttachmentWithPreview,
} from 'components/Forms/Attachments/AttachmentsWithRichText';
import { UPDATE_COMMENT_TIME } from '../constants';
import { Attachment } from 'ducks/events/types';
import { FileWithPath } from 'react-dropzone';

interface EditorPropTypes {
  defaultValue?: string;
  onBlur?: (text: string) => void;
  onChange?: (value: string) => void;
  placeholder?: string;
  value?: string;
}

interface NotesPropTypes {
  deleteFile: (file: AttachmentWithPreview) => Promise<void>;
  uploadAttachment: (file: FileWithPath) => Promise<Attachment>;
  additionalInfo?: {
    message: string;
    variant?: variants;
  };
  attachmentsToRemoveProps: AttachmentsToInstantRemoveProps;
  editorProps: EditorPropTypes;
  isDisabled?: boolean;
  label: string;
  testid?: string;
  withStandardComments?: boolean;
}

export const Notes = (props: NotesPropTypes) => {
  const {
    additionalInfo,
    attachmentsToRemoveProps,
    deleteFile,
    editorProps,
    isDisabled,
    label,
    testid,
    uploadAttachment,
    withStandardComments = false,
  } = props;

  // to prevent store update with each key stroke it is done onBlur
  const [textareaValue, setTextareaValue] = useState(editorProps.defaultValue || '');

  const onBlur = () => {
    if (editorProps.onBlur && textareaValue !== editorProps.defaultValue) {
      editorProps.onBlur(textareaValue);
    }
  };

  const { setModalComponent, showModal, setCustomStyles, setShowHeader } = useModal();

  const openStandardCommentsModal = React.useCallback(() => {
    setCustomStyles({ maxWidth: '60rem' });
    setModalComponent(
      <NewVisitCommentModal
        handleSelect={(message) => {
          setTextareaValue((prev) => {
            const newComment = `${prev}${message}`.trim();
            if (editorProps.onBlur) {
              editorProps.onBlur(newComment);
            }
            return newComment;
          });
        }}
      />,
    );
    setShowHeader(false);
    showModal();
  }, [setTextareaValue]);

  useEffect(() => {
    setTextareaValue(editorProps.defaultValue!);
  }, [editorProps.defaultValue]);

  const debouncedUpdateComment = useCallback(
    debounce(
      (comment: string) => {
        if (editorProps.onBlur && comment !== editorProps.defaultValue) {
          editorProps.onBlur(comment);
        }
      },
      UPDATE_COMMENT_TIME,
      { maxWait: UPDATE_COMMENT_TIME },
    ),
    [],
  );

  useUnmount(
    ([lastValue, prevValue, onBlur]) => {
      lastValue !== prevValue && onBlur && onBlur(lastValue);
    },
    [textareaValue, editorProps.defaultValue, editorProps.onBlur],
  );

  return (
    <Margin bottom='2.4rem' data-testid={testid}>
      <StyledCardHeader>{label}</StyledCardHeader>
      {additionalInfo && <InfoBox {...additionalInfo} />}
      <AttachmentsWithRichText
        deleteFile={deleteFile}
        isDisabled={isDisabled}
        openStandardCommentsModal={openStandardCommentsModal}
        richTextProps={{
          onBlur,
          onChange: async (value = '') => {
            setTextareaValue(value);
            if (value !== editorProps.defaultValue) {
              await debouncedUpdateComment(value);
            }
          },
          value: textareaValue,
        }}
        uploadAttachment={uploadAttachment}
        withStandardComments={withStandardComments}
      />
      <AttachmentsToInstantRemove {...attachmentsToRemoveProps} isDisabled={isDisabled} />
    </Margin>
  );
};
