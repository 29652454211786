/* eslint-disable react/button-has-type */
// Rules are disabled because Downshift spreads all the needed props. ESLint can't parse that, so it throws on missing button type and label control.
/* eslint-disable jsx-a11y/label-has-associated-control */

import ReactDOM from 'react-dom';
import React, { useEffect, useRef, useState } from 'react';

import { Manager, Popper, Reference } from 'react-popper';

import { SelectButton } from 'components/SelectButton/SelectButton';
import { useOnClickOutside } from 'utils/hooks';

import {
  StyledInlineBlock,
  StyledMenu,
  StyledPopup,
  StyledPopupSurface,
} from 'components/PopupMenu/styles';

const POPPER_ROOT_ELEMENT = document.querySelector('body');

export const PopupMenu = ({ children, ToggleComponent }) => {
  const refButtonWrap = useRef(null);
  const menuRef = useRef();
  const [isOpen, toggleOpen] = useState(false);
  const [selectButtonHeight, setSelectButtonHeight] = useState(0);
  useOnClickOutside(menuRef, () => toggleOpen(false));
  useEffect(() => {
    if (!refButtonWrap.current) return;
    const { height } = refButtonWrap.current.getBoundingClientRect();
    setSelectButtonHeight(height);
  }, [selectButtonHeight]);
  return (
    <Manager>
      <Reference>
        {({ ref }) => {
          return (
            <StyledInlineBlock ref={refButtonWrap}>
              <button onClick={() => toggleOpen(!isOpen)} ref={ref}>
                {ToggleComponent && <ToggleComponent />}
              </button>
            </StyledInlineBlock>
          );
        }}
      </Reference>
      {POPPER_ROOT_ELEMENT &&
        isOpen &&
        ReactDOM.createPortal(
          <Popper>
            {({ placement, ref, style }) => {
              return (
                <StyledPopup
                  data-placement={placement}
                  isOpen={isOpen}
                  ref={ref}
                  // top value needs to be overwritten after passed 'style' is applied
                  selectButtonHeight={selectButtonHeight}
                  style={style}
                >
                  <StyledPopupSurface>
                    <StyledMenu onClick={() => toggleOpen(false)} ref={menuRef}>
                      {children}
                    </StyledMenu>
                  </StyledPopupSurface>
                </StyledPopup>
              );
            }}
          </Popper>,
          POPPER_ROOT_ELEMENT,
        )}
    </Manager>
  );
};

PopupMenu.defaultProps = {
  ToggleComponent: SelectButton,
};
