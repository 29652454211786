import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { TableGrid } from 'components/Table/TableGrid/TableGrid';
import { PaginationComposed } from 'components/Pagination/Pagination';
import { TableFilters } from 'components/TableFilters/TableFilters';
import { StyledColumn, StyledTableBottomPanel } from 'containers/companyDetails/Visits/styles';
import { StoreType } from 'store';
import { crmComponentsMap } from './tableComponentsMap';
import { CrmEventsEntityResponse } from 'ducks/events/types';
import { getAllCrmEvents, getCrmEvents } from 'ducks/events/actions';
import { clearEventsError, resetEvents } from 'ducks/events/reducer';
import {
  TableProvidersProps,
  useTableContexts,
  withTableProviders,
} from 'hocs/composedTableProviders/composedTableProviders';
import { toast } from 'react-hot-toast';

interface CrmEventsProps extends TableProvidersProps {
  companyId?: string;
}

const CrmEvents: FC<CrmEventsProps> = (props) => {
  const events = useSelector((state: StoreType) => state.events.crm);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setContextName, addMultipleSelection, filterParams } = useTableContexts();

  useEffect(() => {
    setContextName('crm');
    return () => {
      setContextName(null);
      dispatch(resetEvents());
    };
  }, []);

  const selectAllHandler = async () => {
    const response = await getAllCrmEvents({
      ...props.fetchParams,
      ...filterParams,
    });

    addMultipleSelection(
      response.data.tableBody.map((item: CrmEventsEntityResponse) => ({
        entity: { ...item, id: item.crmEventId },
        id: item.crmEventId,
      })),
    );
  };

  const actionHandlers = {
    selectAll: selectAllHandler,
  };

  if (events.error) {
    toast.error(events.error);
    dispatch(clearEventsError());
  }

  if (isEmpty(events.meta.columnsOrder)) return null;

  if (isEmpty(events.data.tableBody) && isEmpty(filterParams))
    return <div>{t('company_details.events.no_events')}</div>;

  return (
    <StyledColumn>
      <TableFilters />
      <TableGrid
        actionHandlers={actionHandlers}
        allFilteredOutMessage={t('company_details.events.all_filtered_out')}
        bodyData={events.data.tableBody}
        columnsOrder={events.meta.columnsOrder}
        componentsMap={crmComponentsMap}
        loadResource='company-contact'
        resultsSelector='company_details.events'
        totalCount={events.meta.totalCount}
      />
      <StyledTableBottomPanel>
        <PaginationComposed loadResource='company-contact' />
      </StyledTableBottomPanel>
    </StyledColumn>
  );
};

const CrmEventsComposed = withTableProviders(CrmEvents, {
  fetchFnExtraParams: (props) => ({ company_id: props.companyId }),
  fetchFunction: getCrmEvents,
  loadResource: 'crm',
  metaUrl: 'crm_events',
});

export { CrmEventsComposed as CrmEvents };
