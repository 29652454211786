import * as React from 'react';

import {
  StyledAccentButton,
  StyledDangerButton,
  StyledDefaultButton,
  StyledFilterButton,
  StyledFlatButton,
  StyledFlatContrastButton,
  StyledGrayButton,
  StyledPlaceholderButton,
  StyledPrimaryButton,
  StyledSecondaryButton,
  StyledSuccessButton,
  StyledTertiaryButton,
} from './styles';

import { capitalize as lodashCapitalize } from 'lodash';
import { SpinnerIcon } from 'utils/iconsMap';

const buttonVariants = Object.freeze({
  'contrast-flat': StyledFlatContrastButton,
  accent: StyledAccentButton,
  danger: StyledDangerButton,
  filter: StyledFilterButton,
  flat: StyledFlatButton,
  gray: StyledGrayButton,
  placeholder: StyledPlaceholderButton,
  primary: StyledPrimaryButton,
  secondary: StyledSecondaryButton,
  success: StyledSuccessButton,
  tertiary: StyledTertiaryButton,
  default: StyledDefaultButton,
});

export interface DefaultButtonProps {
  capitalize?: boolean;
  form?: string;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  isActive?: boolean;
  isDisabled?: boolean;
  isRound?: boolean;
  label?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  size?: 'small' | 'medium' | 'large';
  testid?: string;
  type?: 'button' | 'submit';
  variant?: keyof typeof buttonVariants;
  width?: 'auto' | 'fullWidth';
  loading?: boolean;
}

export const DefaultButton = React.forwardRef<HTMLButtonElement, DefaultButtonProps>(
  (
    {
      capitalize,
      icon,
      iconPosition,
      isActive,
      isDisabled,
      isRound,
      label,
      onClick,
      testid,
      loading,
      variant = 'primary',
      width = 'auto',
      size = 'medium',
      ...rest
    },
    ref,
  ) => {
    const UsedButton = buttonVariants[variant];

    return (
      <UsedButton
        data-testid={testid}
        disabled={isDisabled}
        iconPosition={iconPosition}
        isActive={isActive}
        isRound={isRound}
        onClick={onClick}
        ref={ref}
        size={size}
        width={width}
        {...rest}
      >
        {icon && icon}
        {label && !isRound && <span>{capitalize ? lodashCapitalize(label) : label}</span>}
        {loading && <SpinnerIcon />}
      </UsedButton>
    );
  },
);

DefaultButton.defaultProps = {
  capitalize: false,
  icon: null,
  iconPosition: 'left',
  isActive: false,
  isDisabled: false,
  isRound: false,
  label: '',
  testid: 'DefaultButton',
  variant: 'primary',
};
