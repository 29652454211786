import styled, { css } from 'styled-components/macro';

export interface ButtonBaseProps {
  iconPosition?: 'left' | 'right';
  isActive?: boolean;
  isRound?: boolean;
  size: 'small' | 'medium' | 'large';
  width: 'auto' | 'fullWidth';
}

const ButtonBase = styled.button<ButtonBaseProps>`
  ${({ size, theme, iconPosition }) => {
    const sizes = {
      small: {
        fontSize: theme.fontSizes.base,
        icon: theme.icon.sm,
        lineHeight: theme.lineHeight.lg,
        margin: theme.sizes.xs,
        padding: theme.sizes.xxs,
      },
      medium: {
        fontSize: theme.fontSizes.base,
        icon: theme.icon.md,
        lineHeight: theme.lineHeight.lg,
        margin: theme.sizes.xs,
        padding: theme.sizes.xs,
      },
      large: {
        fontSize: theme.fontSizes.lg,
        icon: theme.icon.lg,
        lineHeight: theme.lineHeight.xl,
        margin: theme.sizes.hmd,
        padding: theme.sizes.hmd,
      },
    };

    return css`
      padding: ${sizes[size].padding};

      & > svg {
        ${sizes[size].icon};
      }

      & > svg:not(:last-child) {
        margin-${iconPosition}: ${sizes[size].margin};
      }

      & > span {
        ${sizes[size].fontSize};
        line-height: ${sizes[size].lineHeight};
        margin: 0 ${sizes[size].margin};
      }
    `;
  }}

  align-items: center;
  border-radius: ${({ isRound, theme }) => (isRound ? '100%' : theme.sizes.xxs)};
  border: 1px solid transparent;
  color: white;
  display: flex;
  font-weight: 500;
  justify-content: center;
  margin: 0;
  position: relative;
  transition: 0.15s all;
  white-space: nowrap;
  flex-direction: ${({ iconPosition }) => (iconPosition === 'right' ? 'row-reverse' : 'row')};
  width: ${({ width }) => (width === 'fullWidth' ? '100%' : 'auto')};

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.85;
    box-shadow: none;
  }

  &:disabled {
    box-shadow: none;
    pointer-events: none;
    user-select: none;
    ${({ theme }) => css`
      background-color: ${theme.colors.black50};
      border: 1px solid ${theme.colors.black100};
      color: ${theme.colors.black300};

      & svg path {
        fill: ${theme.colors.black300};
      }
    `};
  }
`;

export const StyledPrimaryButton = styled(ButtonBase)`
  ${({ theme }) =>
    css`
      background: ${theme.colors.blue500};
      box-shadow: ${theme.boxShadows.blue300};

      &:hover {
        box-shadow: ${theme.boxShadows.blue200};
      }
    `}
`;

export const StyledAccentButton = styled(ButtonBase)`
  ${({ theme }) =>
    css`
      background: ${theme.colors.orange500};
      box-shadow: ${theme.boxShadows.orange300};

      &:hover {
        box-shadow: ${theme.boxShadows.orange200};
      }
    `}
`;

export const StyledSecondaryButton = styled(ButtonBase)`
  ${({ theme }) =>
    css`
      color: ${theme.colors.blue500};
      box-shadow: ${theme.boxShadows.lightBlue300};

      &:hover {
        background: ${theme.colors.blue50};
        box-shadow: ${theme.boxShadows.lightBlue200};
      }

      &:active {
        background: ${theme.colors.blue100};
      }

      & svg path {
        fill: ${theme.colors.blue500};
      }
    `}
`;
export const StyledDefaultButton = styled(ButtonBase)`
  ${({ theme }) =>
    css`
      &:hover {
        background: ${theme.colors.blue50};
        box-shadow: ${theme.boxShadows.lightBlue200};
      }

      &:active {
        background: ${theme.colors.blue100};
      }
    `}
`;

export const StyledTertiaryButton = styled(ButtonBase)`
  ${({ isActive, theme }) =>
    isActive
      ? css`
          color: ${theme.colors.blue300};
          border: 1px solid ${theme.colors.blue300};
          &:hover {
            background: ${theme.colors.blue50};
          }

          &:active {
            background: ${theme.colors.blue100};
          }

          & svg path {
            fill: ${theme.colors.blue300};
          }
        `
      : css`
          background: white;
          color: ${theme.colors.black500};
          border: 1px solid ${theme.colors.black100};

          &:hover,
          &:active {
            color: ${theme.colors.black900};
            border: 1px solid ${theme.colors.black300};

            & svg path {
              fill: ${theme.colors.black900};
            }
          }

          &:active {
            background: ${theme.colors.black50};
          }

          & svg path {
            fill: ${theme.colors.black500};
          }
        `}
`;

export const StyledGrayButton = styled(ButtonBase)`
  ${({ theme }) => css`
    background: #9fa1a7;
    color: #fff;

    &:hover,
    &:active {
      color: ${theme.colors.black50};

      & svg path {
        fill: ${theme.colors.black50};
      }
    }

    svg path {
      fill: #fff;
    }
  `}
`;

export const StyledFlatButton = styled(ButtonBase)`
  ${({ isActive, theme }) =>
    isActive
      ? css`
          color: ${theme.colors.blue300};
          &:hover {
            background: ${theme.colors.blue50};
          }

          &:active {
            background: ${theme.colors.blue100};
          }

          & svg path {
            fill: ${theme.colors.blue300};
          }
        `
      : css`
          background: unset;
          color: ${theme.colors.black500};

          &:hover,
          &:active {
            color: ${theme.colors.black900};

            & svg path {
              fill: ${theme.colors.black900};
            }
          }

          & svg path {
            fill: ${theme.colors.black500};
          }
        `}
`;

export const StyledDangerButton = styled(ButtonBase)`
  ${({ theme }) =>
    css`
      background-color: ${theme.colors.red500};
      box-shadow: ${theme.boxShadows.red300};

      &:hover {
        box-shadow: ${theme.boxShadows.red200};
      }
      svg {
        color: white;

        path {
          fill: white;
        }
      }
    `}
`;

export const StyledFlatContrastButton = styled(ButtonBase)`
  &:hover {
    opacity: 1;
  }

  ${({ isActive, theme }) =>
    isActive
      ? css`
          color: ${theme.colors.blue500};
          &:hover {
            background: ${theme.colors.blue50};
          }

          &:active {
            background: ${theme.colors.blue100};
          }

          & svg path {
            fill: ${theme.colors.blue500};
          }
        `
      : css`
          background: unset;
          color: ${theme.colors.black300};

          & svg path {
            fill: ${theme.colors.black300};
          }
        `}
`;

export const StyledFilterButton = styled(ButtonBase)`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.black100};
  color: ${({ isActive, theme }) => (isActive ? theme.colors.blue500 : theme.colors.black900)};
  font-weight: 600;

  &:hover,
  &:focus,
  &:active {
    border-color: ${({ theme }) => theme.colors.black300};
  }
`;

export const StyledPlaceholderButton = styled(ButtonBase)`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.black300};
  color: ${({ theme }) => theme.colors.black200};
`;

export const StyledSuccessButton = styled(ButtonBase)`
  ${({ theme }) =>
    css`
      background: ${theme.colors.green500};
      box-shadow: ${theme.boxShadows.green300};

      &:hover {
        box-shadow: ${theme.boxShadows.blue200};
      }
    `}
`;
