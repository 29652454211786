import * as React from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import Compressor from 'compressorjs';

import { Thumbs } from 'components/Forms/Attachments/Thumbs';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { Documents } from 'components/Forms/Attachments/Documents';
import { RejectedFiles } from 'components/Forms/Attachments/RejectedFiles';
import { AddFile, PlusIcon, SpinnerIcon } from 'utils/iconsMap';
import { Attachment } from 'ducks/events/types';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { RichText, RichTextPropTypes } from 'components/RichText/RichText';
import {
  StyledAttachmentAndCommentButtonsWrapper,
  StyledTextAndButtonWrapper,
} from 'containers/newVisit/shared/Observation/style';
import { DeleteAttachmentModal } from 'containers/newVisit/shared/Observation/DeleteAttachmentModal';
import { useModal } from 'components/Modal/Modal';
import toast from 'react-hot-toast';

export interface AttachmentWithPreview extends Attachment {
  preview: string;
  size: number;
  type: string;
  name: string;
}

export interface AttachmentsWithRichTextProps {
  className?: string;
  deleteFile: (file: AttachmentWithPreview) => Promise<unknown>;
  deleteTooltip?: string;
  isDisabled?: boolean;
  isDisabledAddingComment?: boolean;
  openStandardCommentsModal: () => void;
  richTextProps: RichTextPropTypes;
  uploadAttachment: (file: FileWithPath) => Promise<Attachment>;
  withStandardComments?: boolean;
}

const FILE_SIZE_LIMIT = 20_000_000;

export const AttachmentsWithRichText = ({
  className,
  deleteFile,
  deleteTooltip,
  isDisabled,
  isDisabledAddingComment,
  openStandardCommentsModal,
  richTextProps,
  uploadAttachment,
  withStandardComments,
}: AttachmentsWithRichTextProps): React.ReactElement => {
  const [noOfProcessingFiles, setNoOfProcessingFiles] = React.useState(0);
  const [attachments, setAttachments] = React.useState<AttachmentWithPreview[]>([]);
  const { setModalComponent, showModal, setShowHeader } = useModal();
  const { t } = useTranslation();

  const deleteAttachment = React.useCallback(
    async (file) => {
      if (!isDisabled) {
        setModalComponent(
          <DeleteAttachmentModal
            deleteFile={async () => {
              await deleteFile(file);
              setAttachments((prev) => prev.filter((attachment) => attachment.id !== file.id));
            }}
          />,
        );
        setShowHeader(false);
        showModal();
      }
    },
    [deleteFile, isDisabled, setAttachments],
  );

  const onDrop = React.useCallback(
    async (acceptedFiles: FileWithPath[]) => {
      setNoOfProcessingFiles(acceptedFiles.length);

      acceptedFiles
        .filter((file) => !attachments.some(({ fileName }) => fileName === file.name))
        .forEach(async (file) => {
          try {
            if (file.type.includes('image')) {
              const compressedFile = await new Promise<FileWithPath>(
                (resolve, reject) =>
                  new Compressor(file, {
                    success: (compressedFile) => {
                      const normalizedFile = new File([compressedFile], file.name, {
                        type: file.type,
                      });
                      resolve(
                        Object.assign(normalizedFile, { preview: URL.createObjectURL(file) }),
                      );
                    },
                    quality: 0.7,
                    maxHeight: 3840,
                    maxWidth: 3840,
                    error: reject,
                  }),
              );
              const response = await uploadAttachment(compressedFile);
              response &&
                setAttachments((prev) => [
                  ...prev,
                  Object.assign(response, {
                    preview: URL.createObjectURL(file),
                    size: response.fileSize,
                    type: response.contentType,
                    name: response.fileName,
                  }),
                ]);
              setNoOfProcessingFiles((prev) => (prev -= 1));
            } else {
              const response = await uploadAttachment(file);
              response &&
                setAttachments((prev) => [
                  ...prev,
                  Object.assign(response, {
                    preview: URL.createObjectURL(file),
                    size: response.fileSize,
                    type: response.contentType,
                    name: response.fileName,
                  }),
                ]);
              setNoOfProcessingFiles((prev) => (prev -= 1));
            }
          } catch (error) {
            setNoOfProcessingFiles((prev) => (prev -= 1));
            toast.error((error as Error)?.message ?? t('common.error_message'));
          }
        });
    },
    [setNoOfProcessingFiles, setAttachments, uploadAttachment],
  );

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept: [
      'application/pdf',
      'image/png',
      'image/jpeg',
      'video/mp4',
      'image/gif',
      'image/jpg',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'application/msword',
    ],
    maxSize: FILE_SIZE_LIMIT,
    disabled: !!noOfProcessingFiles,
  });

  return (
    <>
      <StyledTextAndButtonWrapper className={className} data-testid='attachments-with-rich-text'>
        <RichText placeholder={t('common.comment')} {...richTextProps} isDisabled={isDisabled} />
        <StyledAttachmentAndCommentButtonsWrapper>
          {withStandardComments && (
            <Tooltip label={t('new_visits.add_standard_comment')}>
              <DefaultButton
                icon={<PlusIcon />}
                isDisabled={isDisabled || isDisabledAddingComment}
                onClick={openStandardCommentsModal}
                size='small'
                testid='new-visit-add-standard-comment-button'
                variant='primary'
              />
            </Tooltip>
          )}
          <Tooltip label={t('common.add_file')}>
            <DefaultButton
              icon={!!noOfProcessingFiles ? <SpinnerIcon /> : <AddFile />}
              isDisabled={!!noOfProcessingFiles || isDisabled}
              onClick={getRootProps().onClick}
              size='small'
              testid='new-visit-add-standard-add-file-button'
            />
          </Tooltip>
        </StyledAttachmentAndCommentButtonsWrapper>
      </StyledTextAndButtonWrapper>
      <input {...getInputProps()} disabled={isDisabled} />

      <Thumbs
        deleteFile={deleteAttachment}
        deleteTooltip={deleteTooltip}
        files={attachments}
        isDisabled={isDisabled}
      />

      <Documents deleteFile={deleteAttachment} files={attachments} isDisabled={isDisabled} />
      <RejectedFiles fileRejections={fileRejections} />
    </>
  );
};
