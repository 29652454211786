import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { forgotPassword } from './helpers';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import {
  StyledAuthForm,
  StyledAuthFormWrapper,
  StyledAuthInput,
  StyledSubmitButton,
} from './styles';

export const ForgotPassword: FC<RouteComponentProps> = (props) => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState('');
  const [showCode, setShowCode] = React.useState(false);
  const [code, setCode] = React.useState('');

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const sendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email) {
      toast.error(t('auth.error.valid_email'));
      return;
    }

    try {
      const response = await forgotPassword(email);
      if (response.error) {
        toast.error(response.error);
        return;
      } else {
        setShowCode(true);
        toast.success(t('auth.instructions.email_sent'));
      }
    } catch (err) {
      console.log(err);
      toast.error(t('auth.error.generic_error'));
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!code) {
      toast.error('auth.error.missing_code');
      return;
    }
    props.navigate && props.navigate('../reset_password', { state: { email: email, code: code } });
  };

  return (
    <StyledAuthFormWrapper>
      {!showCode && (
        <StyledAuthForm onSubmit={sendEmail}>
          <StyledAuthInput
            autoFocus
            placeholder={t('auth.email')}
            type='text'
            onChange={handleEmail}
          />
          <StyledSubmitButton type='submit'>{t('auth.send_verification_code')}</StyledSubmitButton>
        </StyledAuthForm>
      )}

      {showCode && (
        <StyledAuthForm onSubmit={onSubmit}>
          <StyledAuthInput
            autoFocus
            placeholder={t('auth.enter_code')}
            type='text'
            onChange={handleCode}
          />
          <StyledSubmitButton type='submit'>{t('auth.reset_password')}</StyledSubmitButton>
        </StyledAuthForm>
      )}
    </StyledAuthFormWrapper>
  );
};
