import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isEqual } from 'lodash';

import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { FilterDropdown, FilterMultiSelectList } from './styles';

import { ReactComponent as ArrowDownIcon } from 'images/arrowDownIcon.svg';

interface FilterState {
  isActive: boolean;
  displayValue: string[];
  value: string[] | null;
  removeFn?: (value: string, state: FilterState) => void;
}

interface MultiSelectFilterPropTypes {
  isActive?: boolean;
  label: string;
  onChange: (e: FilterState | null) => void;
  options: string[];
  removeFn: (val: string, state: { displayValue: string[] }) => void;
  translate?: boolean;
  value: string[];
}

const MultiSelectFilter = (props: MultiSelectFilterPropTypes) => {
  const { isActive, label, onChange, options, value, translate, removeFn } = props;
  const { t } = useTranslation();

  const optionsWithLabels = useMemo(
    () => options.filter((o) => o).map((o) => ({ label: o, value: o })),
    [options],
  );

  const onFilterChange = (value: string[]) => {
    if (isEmpty(value)) onChange({ isActive: false, displayValue: [], value });
    else {
      const displayValue = value.length > 5 ? [`${value.length} ${t('common.selected')}`] : value;
      onChange({
        isActive: true,
        displayValue,
        removeFn,
        value,
      });
    }
  };

  const ToggleComponent = (compProps: object) => (
    <DefaultButton
      icon={<ArrowDownIcon />}
      iconPosition='right'
      isActive={isActive}
      isDisabled={optionsWithLabels.length < 2}
      label={label}
      variant='filter'
      {...compProps}
    />
  );

  return (
    <FilterDropdown noPadding ToggleComponent={ToggleComponent}>
      <FilterMultiSelectList
        onChange={onFilterChange}
        options={optionsWithLabels}
        translate={translate}
        value={value || []}
      />
    </FilterDropdown>
  );
};

const areEqual = (prevProps: MultiSelectFilterPropTypes, nextProps: MultiSelectFilterPropTypes) =>
  isEqual(prevProps.value, nextProps.value) && isEqual(prevProps.options, nextProps.options);

const PureMultiSelectFilter = memo(MultiSelectFilter, areEqual);

export { PureMultiSelectFilter as MultiSelectFilter };
