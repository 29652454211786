import styled from 'styled-components';

import { StyledContent } from 'components/Tooltip/styles';

export const StyledWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${({ theme }) => theme.sizes.xs};

  & ${StyledContent} {
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const StyledName = styled.span`
  ${({ theme }) => theme.fontSizes.lg};
  margin-right: ${({ theme }) => theme.sizes.hmd};
  white-space: nowrap;
`;

export const StyledRole = styled.span`
  ${({ theme }) => theme.fontSizes.base};
  color: ${({ theme }) => theme.colors.black500};
`;

export const StyledIconWrap = styled.div`
  margin-right: ${({ theme }) => theme.sizes.hmd};

  & > svg {
    ${({ theme }) => theme.icon.md};
  }
`;

export const StyledEmail = styled.span`
  color: ${({ theme }) => theme.colors.blue500};
  cursor: default;
  margin-right: ${({ theme }) => theme.sizes.hmd};
`;
