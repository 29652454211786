import React from 'react';
import styled, { css } from 'styled-components/macro';

import i18n from 'i18n';
import { ArrowDownIcon } from 'utils/iconsMap';
import { SpanCenter } from 'styles/utils';
import { StatusBadge } from 'components/StatusBadge/StatusBadge';

const t = (x: string) => i18n.t(x);

export const CellWrap = styled.div<{ isActive?: boolean; isExpanded?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid
    ${({ theme, isExpanded }) => (isExpanded ? 'transparent' : theme.colors.black100)};
  ${({ isActive, theme }) =>
    isActive &&
    css`
      background-color: ${theme.colors.blue50};
    `}
`;

export const ActionDrawerWrap = styled(CellWrap)`
  background-color: white;
  justify-content: flex-end;
  padding-left: ${({ theme }) => theme.tableVerticalPadding};
  padding-right: ${({ theme }) => theme.sizes.xs};
  position: sticky;
  right: 0;
  z-index: 10;

  ${({ isActive, theme }) =>
    isActive &&
    css`
      background-color: ${theme.colors.blue50};
    `}
`;

export const CapsContent = styled.span`
  text-transform: uppercase;
`;

export const StyledCellLink = styled.a`
  &,
  &:hover,
  &:visited {
    color: ${({ theme }) => theme.colors.blue500};
    text-decoration: none;
    white-space: nowrap;
  }
`;

export const StyledTruncateWrapper = styled.div`
  max-width: 100%;

  & > * {
    max-width: 100%;
  }
`;

export const StyledMultiLineTruncateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

export const StyledTruncatedContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledCellContent = styled.div`
  display: inline-flex;
  justify-content: flex-start;
`;

export const StyledNoWrapCellContent = styled.div`
  white-space: nowrap;
`;

export const StyledDeadline = styled.div<{ inViolation: boolean }>`
  white-space: nowrap;
  color: ${({ theme, inViolation }) => (inViolation ? theme.colors.red500 : theme.colors.black900)};
`;

export const StyledCell = styled.div<{ isActive?: boolean; isExpanded?: boolean }>`
  border-bottom: 1px solid
    ${({ theme, isExpanded }) => (isExpanded ? 'transparent' : theme.colors.black100)};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ theme }) => `${theme.sizes.xxs} ${theme.tableVerticalPadding}`};

  ${({ isActive, theme }) =>
    isActive &&
    css`
      background-color: ${theme.colors.blue50};
    `}
`;

export const StyledMultiLineCell = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin: 0.4rem 0;
  }
`;

export const TrueFalse = ({ val }: { val: boolean }) => (
  <SpanCenter>
    <StatusBadge variant={val ? 'success' : 'fail'}>
      {t(`common.${val ? 'yes' : 'no'}`)}
    </StatusBadge>
  </SpanCenter>
);

export const StyledChevron = styled(ArrowDownIcon)<{ isFlipped?: boolean }>`
  ${({ isFlipped }) =>
    isFlipped &&
    css`
      transform: rotate(180deg);
    `}

  path {
    fill: ${({ theme }) => theme.colors.black100};
    transition: fill 0.3s, transform 0.3s;
  }
`;

export const StyledDropdownRow = styled.div<{ isOpen?: boolean; gridColumnEnd: number }>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.black100};
  grid-column-end: ${({ gridColumnEnd }) => gridColumnEnd};
  grid-column-start: 1;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  padding: ${({ theme }) => `${theme.sizes.sm} ${theme.sizes.lg}`};
`;

export const ErrorCell = styled.div.attrs(() => ({ children: 'error' }))`
  color: ${({ theme }) => theme.colors.red500};
  font-size: 3rem;
`;
