import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { initiateAuth } from './helpers';
import toast from 'react-hot-toast';

import baLogo from 'images/RVO_BA_rgb.png';
import hrrLogo from 'images/RVO_HRR_rgb.png';

import {
  StyledAuthForm,
  StyledAuthFormWrapper,
  StyledAuthInput,
  StyledLogo,
  StyledLogoWrapper,
  StyledSubmitButton,
} from './styles';
import { StyledPage } from 'containers/styles';
import { useTranslation } from 'react-i18next';
import { checkIsIpad } from 'utils/deviceChecker';
import { ChallengeNames } from './types';

export const Login: FC<RouteComponentProps> = ({ navigate }) => {
  const { BRAND_ID } = window.REACT_APP_ENV;
  const { t } = useTranslation();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email || !password) {
      toast.error(t('auth.error.invalid_credentials'));
      return;
    }

    const user = {
      username: email,
      password,
    };

    try {
      const response = await initiateAuth(user);
      if (response.challengeName === ChallengeNames.NEW_PASSWORD_REQUIRED) {
        navigate && navigate('../new_password', { state: { challengeResponse: response } });
        return;
      } else if (response.challengeName === ChallengeNames.MFA_SETUP) {
        navigate && navigate('../setup_mfa', { state: { mfaDetails: response } });
        return;
      } else if (response.challengeName === ChallengeNames.SOFTWARE_TOKEN_MFA) {
        navigate && navigate('../otp', { state: { challengeResponse: response } });
        return;
      }
    } catch (err) {
      toast.error(t('auth.error.invalid_user'));
    }
  };

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const isIpad = checkIsIpad();

  return (
    <StyledPage isIpad={isIpad}>
      <StyledAuthFormWrapper>
        <StyledAuthForm onSubmit={onSubmit}>
          <StyledLogoWrapper>
            <StyledLogo src={BRAND_ID === 'hrr' ? hrrLogo : baLogo} />
          </StyledLogoWrapper>
          <StyledAuthInput
            autoFocus
            aria-label='email'
            placeholder={t('auth.email')}
            name='email'
            type='email'
            onChange={handleEmail}
          />
          <StyledAuthInput
            aria-label='password'
            placeholder={t('auth.password')}
            type='password'
            onChange={handlePassword}
          />
          <StyledSubmitButton type='submit'>{t('auth.login')}</StyledSubmitButton>
          <a href='../users/forgot_password'>{t('auth.forgot_password')}</a>
        </StyledAuthForm>
      </StyledAuthFormWrapper>
    </StyledPage>
  );
};
