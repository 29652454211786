import { createSlice } from '@reduxjs/toolkit';

import { searchCompanyVisits } from './actions';
import {
  SearchCompanyVisitsEntity,
  SearchCompanyVisitsEntityResponse,
  SearchCompanyVisitsState,
} from './types';
import { deleteCompanyVisit } from 'ducks/visits/actions';

const initialState: SearchCompanyVisitsState = {
  data: {
    tableBody: [],
  },
  error: undefined,
  meta: {
    columnsOrder: [],
    filters: {},
    page: 1,
    pageSize: 10,
    totalCount: null,
    totalPages: 1,
  },
};

const amendEntity = (entity: SearchCompanyVisitsEntityResponse): SearchCompanyVisitsEntity => ({
  ...entity,
  id: entity.companyVisitId,
});

const companySearchSlice = createSlice({
  name: 'search/companyVisits',
  initialState,
  reducers: {
    resetCompanyVisitsSearchResults: () => initialState,
    clearCompanyVisitsSearchError: (state) => ({ ...state, error: undefined }),
  },
  extraReducers: (builder) => {
    builder.addCase(searchCompanyVisits.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(searchCompanyVisits.fulfilled, (state, { payload }) => {
      state.data.tableBody = payload.data.tableBody.map(amendEntity);
      const newMeta = { ...state.meta, ...payload.meta };
      newMeta.columnsOrder = newMeta.columnsOrder.filter((column) => column !== 'tips_id_present');
      state.meta = newMeta;
    });
    builder.addCase(deleteCompanyVisit.rejected, (state, { payload }) => ({
      ...state,
      error: payload as string,
    }));
    builder.addCase(deleteCompanyVisit.fulfilled, (state, { meta }) => {
      state.data.tableBody = state.data.tableBody.filter(
        (visit) => visit.companyVisitId !== meta.arg,
      );
      if (typeof state.meta.totalCount === 'number') --state.meta.totalCount;
    });
  },
});

const {
  reducer: companyVisitsSearchReducer,
  actions: { resetCompanyVisitsSearchResults, clearCompanyVisitsSearchError },
} = companySearchSlice;

export {
  companyVisitsSearchReducer,
  resetCompanyVisitsSearchResults,
  clearCompanyVisitsSearchError,
};
