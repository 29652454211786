export const checkIsIpad = (): boolean => {
  if (navigator?.platform && /iPad|iPod/.test(navigator.platform)) {
    return true;
  }
  return (
    (navigator?.maxTouchPoints &&
      navigator?.platform &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)) ||
    false
  );
};

export const checkIsTablet = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();
  return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    userAgent,
  );
};
