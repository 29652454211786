import React from 'react';

import {
  StyledCellContent,
  StyledMultiLineCell,
  StyledNoWrapCellContent,
  StyledTruncatedContent,
} from 'components/Table/TableRow/styles';
import { cells } from 'utils/cells';
import { formatDate } from 'utils/date';
import { CrmEventsEntity, RvoEventsEntity } from 'ducks/events/types';
import { AttachmentBadge } from 'components/AttachmentBadge/AttachmentBadge';
import { StyledLink } from '../BasicData/styles';
import { useModal } from 'components/Modal/Modal';
import { PreviewContactModal } from '../Contacts/ContactsModals';
import { amendContactEntity } from 'ducks/contacts/reducer';
import { StyledMultiLineContactsCell } from './styles';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { TextOnclickLink } from 'components/TextOnclickLink/TextOnclickLink';
import { navigate } from '@reach/router';
import { TipsIdLink } from 'components/TipsIdLink/TipsIdLink';

export const crmComponentsMap = Object.freeze({
  source: {
    component: ({ source }: CrmEventsEntity) => (
      <StyledNoWrapCellContent>{source || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  change_type: {
    component: ({ changeType }: CrmEventsEntity) => (
      <StyledNoWrapCellContent>{changeType || '-'}</StyledNoWrapCellContent>
    ),
    size: 'auto',
  },
  description: {
    component: ({ description }: CrmEventsEntity) => (
      <StyledCellContent>{description || '-'}</StyledCellContent>
    ),
    size: 'auto',
  },
  user: {
    component: ({ user }: CrmEventsEntity) => (
      <StyledNoWrapCellContent>{user || '-'}</StyledNoWrapCellContent>
    ),
    size: 'auto',
  },
  date: {
    component: ({ date }: CrmEventsEntity) => (
      <StyledNoWrapCellContent>{date ? formatDate(date) : '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
});

export type CrmEventsComponentsMap = typeof crmComponentsMap;

export const rvoEventsComponentsMap = Object.freeze({
  rvo: {
    component: ({ rvo }: RvoEventsEntity) => (
      <StyledNoWrapCellContent>{rvo || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  type: {
    component: ({ type }: RvoEventsEntity) => (
      <StyledNoWrapCellContent>{type || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  subtype: {
    component: ({ subtype }: RvoEventsEntity) => (
      <StyledNoWrapCellContent>{subtype || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  description: {
    component: ({ description }: RvoEventsEntity) => (
      <Tooltip label={description?.length > 24 ? description : ''}>
        <StyledTruncatedContent>{description}</StyledTruncatedContent>
      </Tooltip>
    ),
    size: 'minmax(24rem, max-content)',
  },
  date: {
    component: ({ date }: RvoEventsEntity) => (
      <StyledNoWrapCellContent>{date ? formatDate(date) : '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  contacts: {
    component: ({ contacts }: RvoEventsEntity) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { showModal, setModalComponent, setShowHeader } = useModal();
      const amendContacts = contacts.map(amendContactEntity);

      return (
        <StyledMultiLineContactsCell
          onClick={() => {
            setModalComponent(<PreviewContactModal itemsSelected={amendContacts} />);
            setShowHeader(true);
            showModal();
          }}
        >
          {amendContacts.map((contact) => (
            <StyledLink key={contact.id}>{contact.name}</StyledLink>
          ))}
        </StyledMultiLineContactsCell>
      );
    },
    size: 'min-content',
  },
  visits: {
    component: ({ visits }: RvoEventsEntity) => (
      <StyledMultiLineCell>
        {visits.map((visit) => (
          <TextOnclickLink
            key={visit.id}
            onClick={() =>
              navigate(
                `/redesigned/new-ordinary-visit/${visit.visitId}/companies?company_visit_id=${visit.id}`,
                {
                  state: { ...window.history.state, previousPathname: window.location.pathname },
                  replace: false,
                },
              )
            }
          >
            <StyledNoWrapCellContent>{formatDate(visit.date)}</StyledNoWrapCellContent>
          </TextOnclickLink>
        ))}
      </StyledMultiLineCell>
    ),
    size: 'min-content',
  },
  attachments: {
    component: ({ attachments }: RvoEventsEntity) => (
      <StyledMultiLineCell>
        {attachments.map((attachment, i) => (
          <AttachmentBadge key={i} {...attachment} />
        ))}
      </StyledMultiLineCell>
    ),
    size: 'min-content',
  },
  district_names: cells.district_names,
  tips_external_id: {
    component: ({ tipsExternalId, revisedActivityId }: RvoEventsEntity) => {
      return (
        <TipsIdLink
          entityId={revisedActivityId}
          entityType='revisedActivity'
          tipsExternalId={tipsExternalId}
        />
      );
    },
    size: 'min-content',
  },
});

export type RvoEventsComponentsMap = typeof rvoEventsComponentsMap;
