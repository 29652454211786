import React, { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { StyledSwitch, StyledSwitchBox, StyledSwitchLabel, StyledSwitchWrapper } from './styles';

interface SwitchFieldPropTypes extends FieldRenderProps<boolean> {
  capitalize?: boolean;
  label?: string;
}

const SwitchField: FC<SwitchFieldPropTypes> = ({
  capitalize,
  input: { onChange, value },
  label,
}) => {
  return (
    <StyledSwitchWrapper>
      <StyledSwitch
        checked={value}
        onChange={() => {
          onChange(!value);
        }}
        type='checkbox'
      />
      <StyledSwitchBox active={value} />
      {label && <StyledSwitchLabel capitalize={capitalize}>{label}</StyledSwitchLabel>}
    </StyledSwitchWrapper>
  );
};

export { SwitchField };
