import React from 'react';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  StyledModalButtonsWrapper,
  StyledModalHeader,
  StyledModalItemToRemove,
  StyledModalMessage,
} from 'components/Modal/Modal';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { formatDate } from 'utils/date';
import { deleteCompanyVisit } from 'ducks/visits/actions';
import { useLoadingContext } from 'contexts/LoadingContext';
import { VisitEntity } from 'ducks/visits/types';

interface DeleteMultipleVisitsModalContentPropsType {
  hideModal: () => void;
  itemsSelected: Array<VisitEntity>;
  actionCallback: () => void;
}

const DeleteMultipleVisitsModalContent = ({
  hideModal,
  itemsSelected,
  actionCallback,
}: DeleteMultipleVisitsModalContentPropsType) => {
  const { t } = useTranslation();
  const { setLoading, isLoading, setLoaded } = useLoadingContext();
  const dispatch = useDispatch();

  return (
    <>
      <StyledModalHeader>{`${t('common.delete_all')}?`}</StyledModalHeader>
      {itemsSelected.map((visit) => (
        <StyledModalItemToRemove key={visit.companyVisitId}>
          {`${formatDate(visit.date.value)} - ${visit?.workplace?.value}`}
        </StyledModalItemToRemove>
      ))}
      <StyledModalMessage>{t('company_details.visits.data_lose')}</StyledModalMessage>
      <StyledModalButtonsWrapper>
        <DefaultButton
          isDisabled={isLoading('delete-multiple-visits')}
          label={capitalize(t('common.cancel'))}
          onClick={hideModal}
          variant='secondary'
        />

        <DefaultButton
          isDisabled={isLoading('delete-multiple-visits')}
          label={capitalize(t('common.delete'))}
          onClick={async () => {
            setLoading('delete-multiple-visits');
            await Promise.all(itemsSelected.map((item) => dispatch(deleteCompanyVisit(item.id))));
            actionCallback();
            setLoaded('delete-multiple-visits');
            hideModal();
          }}
          variant='danger'
        />
      </StyledModalButtonsWrapper>
    </>
  );
};

export { DeleteMultipleVisitsModalContent };
