import React, { FC } from 'react';

import { StyledFieldWrapper } from 'components/Forms/styles';
import { StyledInputlikeContent, StyledInputlikeLabel } from 'components/InputlikeValue/styles';

interface InputlikeValueProps {
  label: string;
  value: string;
}

export const InputlikeValue: FC<InputlikeValueProps> = (props) => {
  const { label, value } = props;
  return (
    <StyledFieldWrapper>
      <StyledInputlikeLabel>{label}</StyledInputlikeLabel>
      <StyledInputlikeContent>{value}</StyledInputlikeContent>
    </StyledFieldWrapper>
  );
};
