import React, { useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';

import { GetWorkplaceResponse } from 'ducks/powerSearch/workplace/types';
import { WorkplaceForm } from 'containers/newVisit/shared/WorkplaceForm';
import { ContentContainer } from 'styles/utils';
import { useAppDispatch } from 'store';
import { editNewVisitWorkplace } from 'ducks/newVisit';
import { WorkplaceChosenButtons } from 'containers/newVisit/OrdinaryVisit/Step1/WorkplaceChosenButtons';

interface WorkplaceChosenPropTypes {
  workplace: GetWorkplaceResponse;
  setEdited?: Function;
}

export const WorkplaceChosen = ({ workplace, setEdited }: WorkplaceChosenPropTypes) => {
  const dispatch = useAppDispatch();
  const [isEdit, setIsEdit] = useState(false);

  return (
    <ContentContainer margin='1.2rem auto' maxWidth='72rem'>
      <WorkplaceForm
        Buttons={({ setHaveOwner, isPrimaryDisabled }) => (
          <WorkplaceChosenButtons
            isEdit={isEdit}
            isPrimaryDisabled={isPrimaryDisabled}
            noChoosing={setEdited ? true : false}
            setHaveOwner={setHaveOwner}
            setIsEdit={setIsEdit}
            workplace={workplace}
          />
        )}
        formId='workplace-form'
        init={{
          kind: workplace.kind ? { label: workplace.kind, value: workplace.kind } : undefined,
          name: workplace.name || '',
          postal_area_name: workplace.postalAreaName || undefined,
          postal_area_id: workplace.postalAreaId || undefined,
          post_code: workplace.postalAreaCode || '',
          municipality: {
            label: workplace.municipalityName,
            value: Number(workplace.municipalityId),
          },
          street: workplace.street || workplace.address || '',
          street_number: workplace.streetNumber || '',
          proprietorship: workplace.proprietorshipId
            ? { label: workplace.proprietorshipName!, value: workplace.proprietorshipId }
            : undefined,
          termination: workplace.termination?.split('.').reverse().join('.'),
          comment: workplace.comment || '',
        }}
        isCommentEditable={workplace.isCommentEditable}
        isDisabled={!isEdit}
        onSubmit={(values) =>
          dispatch(editNewVisitWorkplace({ ...values, id: workplace.workplaceId }))
            .then(unwrapResult)
            .then(() => {
              setIsEdit(false);
              setEdited && setEdited(true);
            })
        }
      />
    </ContentContainer>
  );
};
