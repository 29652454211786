import React from 'react';
import i18n from 'i18n';
import { StyledNewVisitModalHeader } from 'containers/newVisit/styles';
import { CompanyVisitResponse, NewVisitContact, NewVisitResponse } from 'ducks/newVisit';

import { CopyVisitsForm } from './CopyVisitsForm';
import { CompanyEntity } from 'ducks/companies/types';

const t = (x: string) => i18n.t(x);

interface CopyVisitsModalProps {
  callback?: (id: string | number) => void | Promise<unknown>;
  visitId: string | number;
  originalVisitId: string;
  initialValues: fetchedCompanyVisit[];
  selectedCompany?: CompanyEntity | null;
  visit?: NewVisitResponse;
  loading: boolean;
  setLoading: Function;
}

interface fetchedCompanyVisit extends CompanyVisitResponse {
  checked: boolean;
  contacts: fetchedContacts[];
}

interface fetchedContacts extends NewVisitContact {
  checked?: boolean;
}

export const CopyVisitsModal = (props: CopyVisitsModalProps): JSX.Element => {
  const { originalVisitId, visitId, initialValues, loading, setLoading } = props;

  return (
    <>
      <StyledNewVisitModalHeader>{t('new_visits.choose_what_to_copy')}</StyledNewVisitModalHeader>
      <CopyVisitsForm
        initialValues={initialValues}
        loading={loading}
        originalVisitId={originalVisitId}
        setLoading={setLoading}
        visitId={visitId}
      />
    </>
  );
};
