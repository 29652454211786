import React from 'react';
import { useTranslation } from 'react-i18next';

import { amendContactEntity } from 'ducks/contacts/reducer';
import { CapsLabel } from 'components/CapsLabel/styles';
import { ContactEntityResponse } from 'ducks/contacts/types';
import { FileThumbnail } from 'components/FileThumbnail/FileThumbnail';
import { formatDate } from 'utils/date';
import { hasPreview } from 'utils/helpers';
import { Margin } from 'styles/utils';
import { PreviewContactRow } from 'containers/companyDetails/Contacts/PreviewContactRow';
import { RvoEventsEntity } from 'ducks/events/types';
import { StyledAttachments } from 'containers/companyDetails/EventsLog/styles';
import { StyledCardDivider } from 'components/Card/styles';
import { ValueWithLabel } from 'components/ValueWithLabel/ValueWithLabel';

export const RevisedActivitiesFields = ({ item }: { item: RvoEventsEntity }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <ValueWithLabel flexDirection='vertical' label={t('table.columns.from_date')}>
        {item?.toDate
          ? new Date(item.date).toLocaleTimeString() === new Date(item.toDate).toLocaleTimeString()
            ? new Date(item.date).toLocaleDateString()
            : new Date(item.date).toLocaleString()
          : new Date(item.date).toLocaleDateString()}
      </ValueWithLabel>
      <ValueWithLabel flexDirection='vertical' label={t('table.columns.to_date')}>
        {item?.toDate
          ? new Date(item.date).toLocaleTimeString() === new Date(item.toDate).toLocaleTimeString()
            ? new Date(item.date).toLocaleDateString()
            : new Date(item.toDate).toLocaleString()
          : '-'}
      </ValueWithLabel>
      <ValueWithLabel flexDirection='vertical' label={t('table.columns.description')}>
        {item.description || '-'}
      </ValueWithLabel>
      <ValueWithLabel flexDirection='vertical' label={t('table.columns.type')}>
        {item?.type || '-'}
      </ValueWithLabel>
      <ValueWithLabel flexDirection='vertical' label={t('table.columns.subtype')}>
        {item?.subtype || '-'}
      </ValueWithLabel>
      {item.rvo && (
        <ValueWithLabel flexDirection='vertical' label='RVO'>
          {item.rvo}
        </ValueWithLabel>
      )}
      {item.companies.length > 0 && <CapsLabel>{t('table.columns.company_names')}</CapsLabel>}
      {item.companies.map((item) => (
        <React.Fragment key={item.companyId}>
          <Margin margin='0.8rem 0'>{item.companyName}</Margin>
        </React.Fragment>
      ))}
      {item.contacts.length > 0 && <CapsLabel>{t('table.columns.contacts')}</CapsLabel>}
      {item.contacts.map((item, i) => (
        <React.Fragment key={i}>
          <Margin margin='1.6rem 0'>
            <PreviewContactRow contact={amendContactEntity(item as ContactEntityResponse)} />
          </Margin>
          <StyledCardDivider />
        </React.Fragment>
      ))}
      {item.visits.length > 0 && <CapsLabel>{t('table.columns.visits')}</CapsLabel>}
      {item.visits.map((item) => (
        <React.Fragment key={item.id}>
          <Margin margin='0.8rem 0'>{formatDate(item.date) || '-'}</Margin>
        </React.Fragment>
      ))}
      {item.attachments.length > 0 && <CapsLabel>{t('forms.attachments')}</CapsLabel>}
      <StyledAttachments>
        {item.attachments
          .filter(({ contentType }) => hasPreview(contentType))
          .map((attachment, i) => (
            <FileThumbnail file={attachment} key={i} />
          ))}
      </StyledAttachments>
      <div>
        {item.attachments
          .filter(({ contentType }) => !hasPreview(contentType))
          .map((attachment, i) => (
            <FileThumbnail file={attachment} key={i} />
          ))}
      </div>
    </>
  );
};
