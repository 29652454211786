import React, { ComponentType, FocusEvent, FormEvent } from 'react';

import { StyledFieldWrapper, StyledLabel } from 'components/Forms/styles';
import {
  StyledTextarea,
  StyledTextareaCounter,
  StyledTextareaLabelWrapper,
} from 'components/Forms/Textarea/styles';

export type FieldSizeType = 'small' | 'medium' | 'big' | 'fullWidth';

export interface TextareaPropTypes {
  characterLimit?: number;
  fieldSize?: FieldSizeType;
  isDisabled?: boolean;
  label?: string;
  maxRows?: number;
  minRows?: number;
  onBlur?: (e: FocusEvent<HTMLElement>) => void;
  onChange: (e: FormEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  required?: boolean;
  testId?: string;
  value: string;
  vsize?: 'small' | 'medium';
}

export const Textarea: ComponentType<TextareaPropTypes> = ({
  characterLimit,
  fieldSize = 'fullWidth',
  isDisabled,
  label,
  maxRows,
  minRows,
  onBlur,
  onChange,
  placeholder,
  required,
  testId = 'textarea',
  value,
  vsize = 'medium',
}: TextareaPropTypes) => {
  return (
    <StyledFieldWrapper>
      <StyledTextareaLabelWrapper>
        {label && <StyledLabel>{`${label}${required ? '*' : ''}`}</StyledLabel>}
        {characterLimit && (
          <StyledTextareaCounter
            hasError={value.length > characterLimit}
          >{`${value.length}/${characterLimit}`}</StyledTextareaCounter>
        )}
      </StyledTextareaLabelWrapper>
      <StyledTextarea
        disabled={isDisabled}
        fieldSize={fieldSize}
        maxLength={characterLimit}
        maxRows={maxRows}
        minRows={minRows}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        testId={testId}
        value={value}
        vsize={vsize}
      />
    </StyledFieldWrapper>
  );
};

Textarea.defaultProps = {
  fieldSize: 'fullWidth',
  maxRows: 8,
  minRows: 3,
  required: false,
};
