import * as React from 'react';
import * as yup from 'yup';
import { CheckboxField } from 'components/Forms/CheckboxField/CheckboxField';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

interface ReceiveVisitReportFieldProps {
  receiveVisitReportAutoTrue?: boolean;
  autosetReciveVisitButtonToTrue?: boolean;
  formFieldChange: (fieldName: string, value: boolean) => void;
  hasErrors?: boolean;
  value: string;
}

const FIELD_NAME = 'receive_visit_report';

export const ReceiveVisitReportField = (props: ReceiveVisitReportFieldProps) => {
  const { value, autosetReciveVisitButtonToTrue, formFieldChange, hasErrors } = props;
  const { t } = useTranslation();

  const prevValue = React.useRef(false);

  React.useEffect(() => {
    if (!autosetReciveVisitButtonToTrue) return;
    const isValid = yup.string().email().required().isValidSync(value);

    if (prevValue.current === isValid) return;

    prevValue.current = isValid;
    formFieldChange(FIELD_NAME, isValid);
  }, [value]);

  return (
    <Field
      component={CheckboxField}
      isDisabled={!value || hasErrors}
      label={t('table.columns.receive_visit_report')}
      name={FIELD_NAME}
      subscription={{ value: true }}
    />
  );
};
