import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FileWithPath } from 'react-dropzone';

import {
  StyledFileName,
  StyledFilesHeader,
  StyledFileSize,
  StyledFilesListItem,
} from 'components/Forms/Attachments/styles';

interface RejectedFilesProps {
  fileRejections: Record<'file', FileWithPath>[];
}

export const RejectedFiles = (props: RejectedFilesProps): JSX.Element | null => {
  const { fileRejections } = props;
  const { t } = useTranslation();

  const rejectedFiles = fileRejections.map(({ file }) => (
    <StyledFilesListItem key={file.path}>
      <StyledFileName>{file.path}</StyledFileName>
      <StyledFileSize>{t('forms.too_big')}</StyledFileSize>
    </StyledFilesListItem>
  ));

  if (fileRejections.length === 0) return null;
  return (
    <>
      <StyledFilesHeader>{t('forms.rejected_files')}</StyledFilesHeader>
      <ul>{rejectedFiles}</ul>
    </>
  );
};
