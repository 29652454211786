import React from 'react';
import styled, { css } from 'styled-components/macro';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';

import { FieldSizeType } from './Textarea';

interface TextareaProps {
  fieldSize: FieldSizeType;
  isError?: boolean;
  testId?: string;
  vsize?: 'small' | 'medium';
}

interface TextareaExtendedProps extends TextareaProps, Omit<TextareaAutosizeProps, 'ref'> {}

export const StyledTextarea = styled(
  ({ fieldSize, isError, vsize, testId, ...rest }: TextareaExtendedProps) => (
    <TextareaAutosize {...rest} data-testid={testId} />
  ),
)`
  ${({ theme }) => theme.fontSizes.base};
  background-color: white;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: 1px solid ${({ theme }) => theme.colors.black300};
  padding: ${({ vsize, theme }) => {
    switch (vsize) {
      case 'small':
        return `${theme.sizes.xxs} ${theme.sizes.xs}`;
      default:
        return `${theme.sizes.xs} ${theme.sizes.sm}`;
    }
  }};
  line-height: ${({ theme }) => theme.lineHeight.lg};
  min-width: 4.4rem;
  resize: none;
  transition: border-color 0.3s;
  width: ${({ fieldSize }) => {
    switch (fieldSize) {
      case 'small':
        return '4.4rem';
      case 'medium':
        return '8rem';
      case 'big':
        return '16rem';
      case 'fullWidth':
        return '100%';
      default:
        return '4.4rem';
    }
  }};

  &:placeholder {
    color: ${({ theme }) => theme.colors.black200};
  }
  &:disabled {
    color: ${({ theme }) => theme.colors.black200};
    background-color: ${({ theme }) => theme.colors.black50};
    border: 0.1rem solid ${({ theme }) => theme.colors.black100};
  }
  &:hover:not(:disabled) {
    border: 1px solid ${({ theme }) => theme.colors.black600};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.black900};
  }

  ${({ isError }) =>
    isError &&
    css`
      border-color: ${({ theme }) => theme.colors.red500};
    `}
`;

export const StyledTextareaLabelWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const StyledTextareaCounter = styled.div<{ hasError: boolean }>`
  color: ${({ theme, hasError }) => (hasError ? theme.colors.red500 : theme.colors.black300)};
  font-size: 1.2rem;
`;
