import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CollapsibleSection } from 'components/CollapsibleSection/CollapsibleSection';
import {
  createVisitDialogPath,
  newVisitCompanyVisitSelector,
  SubmitVisitDialogResponse,
} from 'ducks/newVisit';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { FlexContainer } from 'styles/utils';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { useAppDispatch } from 'store';
import { ValueWithLabel } from 'components/ValueWithLabel/ValueWithLabel';
import toast from 'react-hot-toast';

interface StatusSectionProps {
  companyVisitId: string;
}

const StyledInfoBox = styled(InfoBox)`
  align-items: center;
  & > div {
    align-items: center;
    display: flex;
  }
`;

const StyledInfoBoxButton = styled(DefaultButton)`
  margin-left: ${({ theme }) => theme.sizes.xs};
`;

export const StatusSection = ({ companyVisitId }: StatusSectionProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const companyVisit = useSelector(newVisitCompanyVisitSelector(companyVisitId))!;
  const { status, finalizationDate, updatedAt, followUpDate } = companyVisit;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onClick = async () => {
    setIsLoading(true);

    try {
      const dialogPath = (await dispatch(
        createVisitDialogPath({ companyVisitId: companyVisit.id }),
      )) as { payload: SubmitVisitDialogResponse };

      if (dialogPath?.payload?.url) {
        window.open(dialogPath.payload.url, '_blank');
      }

      if (dialogPath?.payload?.errorMessage) {
        throw new Error(dialogPath.payload.errorMessage);
      }
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      } else {
        throw err;
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {companyVisit.tipsExternalId && (
        <StyledInfoBox
          message={
            <>
              {t('new_visits.sent_to_at', { tipsExternalId: companyVisit.tipsExternalId })}
              <StyledInfoBoxButton
                capitalize
                icon={isLoading ? <LoadingSpinner small /> : ''}
                isDisabled={isLoading}
                label={t('new_visits.open_dialog')}
                onClick={onClick}
                size='small'
                testid='status-section-dialog-btn'
                type='button'
                variant='tertiary'
              />
            </>
          }
        />
      )}

      {status !== 'draft' && (
        <CollapsibleSection
          header={t('table.columns.status')}
          marginBottom='1.2rem'
          name={`isStatusSectionOpen-${companyVisitId}`}
        >
          <FlexContainer data-testid='status-section' flexWrap='wrap'>
            <ValueWithLabel
              data-testid='status-section-follow-up-date'
              flexDirection='vertical'
              label={t('new_visits.follow_up_date')}
            >
              {followUpDate || '-'}
            </ValueWithLabel>
            <ValueWithLabel
              data-testid='status-section-finished-date'
              flexDirection='vertical'
              label={t('new_visits.finished_date')}
            >
              {finalizationDate || '-'}
            </ValueWithLabel>
            <ValueWithLabel
              data-testid='status-section-last-updated'
              flexDirection='vertical'
              label={t('new_visits.last_updated')}
            >
              {updatedAt || '-'}
            </ValueWithLabel>
          </FlexContainer>
        </CollapsibleSection>
      )}
    </>
  );
};
