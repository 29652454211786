import styled, { css } from 'styled-components/macro';

export const StyledPage = styled.div<{ isIpad: boolean }>`
  background-color: ${({ theme }) => theme.colors.blue100};
  display: flow-root;

  ${({ isIpad }) =>
    isIpad
      ? css`
          height: -webkit-fill-available;
        `
      : css`
          min-height: 100vh;
        `}
`;

export const StyledTabContainer = styled.div`
  margin: ${({ theme }) => `${theme.sizes.xs} ${theme.sizes.xxs} 0`};
`;

export const StyledLayout = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 144rem;
  width: 100%;
`;

export const StyledLayoutWithFullWidth = styled(StyledLayout)`
  max-width: 100%;
  padding: 0 0.8rem;
`;

export const StyledContent = styled.div`
  margin: 0 auto;
  width: 100%;
`;

export const StyledTopSpace = styled.div`
  padding-top: 7.2rem;
`;
