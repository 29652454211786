import React, { useRef, useState } from 'react';

import PlayButton from 'images/play.png';
import {
  StyledPlayButtonContainer,
  StyledThumbnailCross,
  StyledVideoContainer,
  StyledVideoPlayer,
} from './styles';
import { ThumbFile } from 'components/Forms/Attachments/Thumbs';

interface VideoThumbnailProps {
  file: ThumbFile;
  deleteHandler: (file: ThumbFile) => void;
}

export const VideoThumbnail = (props: VideoThumbnailProps) => {
  const { deleteHandler, file } = props;
  const [isPaused, setIsPaused] = useState(true);
  const myRef = useRef<HTMLVideoElement>(null);
  const clickHandler = () => {
    if (!myRef.current) return null;
    myRef.current.paused ? myRef.current.play() : myRef.current.pause();
    myRef.current.muted = true;
    setIsPaused((x) => !x);
  };

  return (
    <StyledVideoContainer as='button' key={file.name} onClick={clickHandler}>
      <StyledVideoPlayer ref={myRef} src={file.preview} />
      {isPaused && <StyledPlayButtonContainer src={PlayButton} />}
      <StyledThumbnailCross onClick={() => deleteHandler(file)} />
    </StyledVideoContainer>
  );
};
