import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { isEmpty } from 'lodash';

import { StyledFieldWrapper } from 'components/Forms/styles';
import {
  StyledDeleteIcon,
  StyledFileName,
  StyledFilesListItem,
} from 'components/Forms/AttachmentsToRemove/styles';
import { Attachment } from 'ducks/events/types';
import { FileIcon, LandscapeIcon, PlayIcon, SpeakerIcon, SpinnerIcon } from 'utils/iconsMap';
import { BASE_URL } from 'api';
import { StyledFilesHeader } from 'components/Forms/Attachments/styles';
import { Tooltip } from 'components/Tooltip/Tooltip';

const iconVariants = Object.freeze({
  application: FileIcon,
  audio: SpeakerIcon,
  image: LandscapeIcon,
  text: FileIcon,
  video: PlayIcon,
});

export interface AttachmentsToInstantRemoveProps {
  attachmentList: Attachment[];
  deleteFile: (id: number) => void;
  isDisabled?: boolean;
}

export const AttachmentsToInstantRemove: ComponentType<AttachmentsToInstantRemoveProps> = ({
  attachmentList,
  deleteFile,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const [idToRemove, setIdToRemove] = React.useState<number[]>([]);

  const deleteAttachment = React.useCallback(
    async (id: number) => {
      setIdToRemove((prev) => [...prev, id]);
      await deleteFile(id);
      setIdToRemove((prev) => prev.filter((prevId) => prevId !== id));
    },
    [deleteFile, setIdToRemove],
  );

  if (isEmpty(attachmentList)) return null;

  return (
    <StyledFieldWrapper>
      <StyledFilesHeader>{t('forms.manage_uploaded')}</StyledFilesHeader>
      <ul>
        {attachmentList.map(({ fileName, id, contentType, className }: Attachment) => {
          const [attachmentType] = contentType.split('/') as (keyof typeof iconVariants)[];
          const Icon = iconVariants[attachmentType] || FileIcon;
          const isProcessing = idToRemove.includes(id);
          const urlParams = queryString.stringify({
            id,
            class_name: className,
            content_disposition: 'attachment',
          });
          const href = `${BASE_URL}/api/serve_attachments?${urlParams}`;

          return (
            <StyledFilesListItem key={id}>
              <StyledFileName as='a' href={href} isRejected={isProcessing} target='_blank'>
                <Icon />
                {fileName}
              </StyledFileName>
              {isProcessing ? (
                <SpinnerIcon height={16} width={16} />
              ) : (
                !isDisabled && (
                  <Tooltip label={t('common.delete_attachment')}>
                    <StyledDeleteIcon onClick={() => deleteAttachment(id)} />
                  </Tooltip>
                )
              )}
            </StyledFilesListItem>
          );
        })}
      </ul>
    </StyledFieldWrapper>
  );
};
