import React, { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { camelize, decamelize } from 'humps';
import { capitalize, omit } from 'lodash';
import { globalHistory } from '@reach/router';

import i18n from 'i18n';
import { useBatchActionsContext } from 'contexts/BatchActionsContext/BatchActionsContext';
import { DatePickerFilter } from 'contexts/FilterContext/DatePickerFilter';
import { MultiSelectFilter } from 'contexts/FilterContext/MultiSelectFilter';
import { RangeFilter } from 'contexts/FilterContext/RangeFilter';
import { SingleSelectFilter } from 'contexts/FilterContext/SingleSelectFilter';
import { MetaFilterObject } from 'ducks/types';
import { userGridPrefferencesSelector } from 'ducks/users/selectors';
import { formatQueryDate } from 'utils/date';
import { usePaginationContext } from '../PaginationContext';

export type FiltersInitialState = { [key: string]: FilterState };

interface FiltersContextProviderProps {
  metaObject: {
    columnsOrder: string[];
    filters: {
      [key: string]: MetaFilterObject;
    };
  };
  filtersNamesMapper?: (x: string) => string;
  initialState?: FiltersInitialState;
  isDashboard: boolean;
  isPowerSearch: boolean;
  setFiltersInLocation: Function;
}

type PresetFilter = {
  filterName: string;
  displayValue: string;
  value: FilterState;
};

interface ContextValue {
  componentsMap: object;
  filterParams: object;
  filtersOrder: string[];
  filtersState: { [key: string]: FilterState };
  reset: () => void;
  setParams: (filters: object) => void;
  preloadFilters: (filters: PresetFilter[]) => void;
}

interface FilterStateBase {
  displayValue: string[];
  isActive: boolean;
}

interface FilterStateDatepicker extends FilterStateBase {
  value: { min?: null | string; max?: null | string };
}

interface FilterStateRange extends FilterStateBase {
  value: null | { min: null | string; max: null | string };
}

interface FilterStateSingleSelect extends FilterStateBase {
  value: string | null;
}

interface FilterStateMultiSelect extends FilterStateBase {
  value: string[] | null;
}

type FilterState =
  | FilterStateDatepicker
  | FilterStateRange
  | FilterStateSingleSelect
  | FilterStateMultiSelect;

export const FilterContext = React.createContext<ContextValue>({
  componentsMap: {},
  filterParams: {},
  filtersOrder: [],
  filtersState: {},
  setParams: () => {},
  reset: () => {},
  preloadFilters: () => {},
});

const t = (x: string) => i18n.t(x);

const componentsTypeMapper = {
  singleselect: SingleSelectFilter,
  multiselect: MultiSelectFilter,
  string_array: MultiSelectFilter,
  datepicker: DatePickerFilter,
  range: RangeFilter,
};

const removeFnMapper = (type: string, onChange: (filterValue: FilterState) => void) => {
  if (type === 'singleselect' || type === 'range')
    return () => onChange({ isActive: false, displayValue: [], value: null });

  if (type === 'datepicker')
    return (filter: string, state: FilterStateDatepicker) => {
      const displayValue = state.displayValue.filter((x: string) => x !== filter);
      const isActive = !!displayValue.length;
      const minOrMax = filter.includes(capitalize(t('filters.from'))) ? 'min' : 'max';
      onChange({ isActive, displayValue, value: { ...state.value, [minOrMax]: null } });
    };

  if (type === 'multiselect' || type === 'string_array')
    return (val: string, state: FilterStateMultiSelect) => {
      const newValue = state.displayValue.filter((x) => x !== val);
      onChange({
        displayValue: newValue,
        value: newValue,
        isActive: !!newValue.length,
      });
    };
};

const createFiltersState = (filtersObject: object, initialState: FiltersInitialState) => {
  const state: { [key: string]: FilterState } = {};
  Object.keys(filtersObject).forEach((filterName) => {
    const initState = initialState[filterName];
    state[filterName] = initState || {
      displayValue: [],
      isActive: false,
      value: null,
    };
  });
  return state;
};

const FiltersContext: FC<FiltersContextProviderProps> = ({
  children,
  filtersNamesMapper = (x: string) => x,
  initialState = {},
  isDashboard = false,
  isPowerSearch = false,
  metaObject,
  setFiltersInLocation,
}) => {
  const userColumnsSettings = useSelector(userGridPrefferencesSelector);
  const { contextName } = useBatchActionsContext();
  const { setParams: setParamsPagination } = usePaginationContext();
  const [filtersState, setFiltersState] = useState(
    createFiltersState(metaObject.filters, initialState),
  );

  const setParams = useCallback(
    (newParams: object) => {
      setFiltersState((value) => ({ ...value, ...newParams }));
    },
    [setFiltersState, metaObject.filters],
  );

  const filtersKeys = Object.keys(metaObject.filters);

  const filtersOrder = (
    userColumnsSettings && contextName && userColumnsSettings[contextName]
      ? [...(userColumnsSettings[contextName] as string[])] // TODO: fix types
      : [...metaObject.columnsOrder]
  )
    .map(filtersNamesMapper)
    .map((column) => camelize(column))
    .map((column) => {
      if (column === 'tipsExternalId' && filtersKeys.includes('tipsIdPresent')) {
        return 'tipsIdPresent';
      } else {
        return column;
      }
    })
    .filter((column) => filtersKeys.includes(column));
  const componentsMap = useMemo(() => {
    return Object.keys(metaObject.filters).reduce((prev, filter) => {
      const { type, ...rest } = metaObject.filters[filter];

      const onChange = (filterValue: FilterState) => {
        setFiltersState((prev) => ({ ...prev, [filter]: filterValue }));

        if (isPowerSearch || isDashboard) {
          setFiltersInLocation(filter, omit(filterValue, 'removeFn'));
          setParamsPagination({ currentPage: 1 });
        }
      };

      return {
        ...prev,
        [filter]: {
          Component: componentsTypeMapper[type],
          onChange,
          removeFn: removeFnMapper(type, onChange),
          ...rest,
          options: 'values' in rest ? rest.values : undefined,
          values: undefined,
        },
      };
    }, {});
  }, [metaObject, globalHistory.location]);

  const filterParams = useMemo(() => {
    const params: { [key: string]: string | undefined } = {};
    Object.keys(filtersState).forEach((filterName) => {
      const filter = filtersState[filterName];
      if (filterName === 'districtNames') {
        filter.isActive = true;
        filter.value = filtersState[filterName].values;
      }
      if ((isPowerSearch || isDashboard) && !metaObject.filters[filterName]) return undefined;
      const { type } = metaObject.filters[filterName];
      if (!filter.isActive) return undefined;

      // small mess from TS, not sure how to write it cleaner
      if (type === 'range') {
        params[`filter[${decamelize(filterName)}][max]`] = (filter as FilterStateRange).value!.max!;
        params[`filter[${decamelize(filterName)}][min]`] = (filter as FilterStateRange).value!.min!;
      }
      if (type === 'datepicker') {
        params[`filter[${decamelize(filterName)}][max]`] =
          formatQueryDate((filter as FilterStateDatepicker).value.max) || undefined;
        params[`filter[${decamelize(filterName)}][min]`] =
          formatQueryDate((filter as FilterStateDatepicker).value.min) || undefined;
      }
      if (type === 'multiselect') {
        params[`filter[${decamelize(filterName)}][values]`] = (
          filter as FilterStateMultiSelect
        ).value!.join(',');
      }
      if (type === 'singleselect') {
        params[`filter[${decamelize(filterName)}]`] = (filter as FilterStateSingleSelect).value!;
      }
    });
    return params;
  }, [JSON.stringify(filtersState)]);

  const reset = useCallback(() => {
    setFiltersState(createFiltersState(metaObject.filters, initialState));
  }, [setFiltersState, metaObject.filters]);

  const preloadFilters = (filters: PresetFilter[]) => {
    let presetFilterState = {};
    filters.forEach((filter) => {
      presetFilterState = {
        ...initialState,
        [filter.filterName]: {
          isActive: true,
          displayValue: filter.displayValue,
          value: filter.value,
        },
      };
    });
    setFiltersState(presetFilterState);
  };

  return (
    <FilterContext.Provider
      value={{
        componentsMap,
        filterParams,
        filtersOrder,
        filtersState,
        setParams,
        reset,
        preloadFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

const useFiltersContext = () => React.useContext(FilterContext);

export { useFiltersContext, FiltersContext };
