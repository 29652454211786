import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledModalButtonsWrapper, StyledModalHeader, useModal } from 'components/Modal/Modal';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { GroupListPicker } from 'components/GroupListPicker/GroupListPicker';
import { useSelector } from 'react-redux';
import { additionalChecklistSelector } from 'ducks/constants/selectors';
import { createObservation } from 'ducks/newVisit';
import { useAppDispatch } from 'store';

interface NewVisitCommentModalPropTypes {
  companyVisitId: string;
}

export const NewVisitCheckListModal = ({ companyVisitId }: NewVisitCommentModalPropTypes) => {
  const { t } = useTranslation();
  const { hideModal } = useModal();
  const [selectedChecklist, setSelectedChecklist] = useState<string[]>([]);
  const additionalChecklists = useSelector(additionalChecklistSelector);
  const dispatch = useAppDispatch();

  const onSave = () => {
    selectedChecklist.forEach(async (id) => {
      await dispatch(createObservation({ companyVisitId, checkpointId: id.toString() }));
    });
    hideModal();
  };

  const amendedOptions = additionalChecklists.map(({ name, sortValue, checkpoints }) => ({
    id: sortValue.toString(),
    name,
    elements: checkpoints
      ?.filter(({ active }) => active)
      .map(({ name, id, description }) => ({
        id: id?.toString(),
        name,
        description,
      })),
  }));

  return (
    <>
      <StyledModalHeader>{t('new_visits.choose_checkpoints')}</StyledModalHeader>
      <GroupListPicker
        items={amendedOptions}
        onChange={setSelectedChecklist}
        values={selectedChecklist}
      />
      {additionalChecklists.length === 0 && (
        <InfoBox big message={t('new_visits.no_results_message')} />
      )}
      <StyledModalButtonsWrapper>
        <DefaultButton
          capitalize
          label={t('common.cancel')}
          onClick={hideModal}
          type='button'
          variant='tertiary'
        />
        <DefaultButton
          capitalize
          label={t('forms.save_changes')}
          onClick={onSave}
          testid='submit-save'
          type='button'
        />
      </StyledModalButtonsWrapper>
    </>
  );
};
