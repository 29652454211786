import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DragDrop } from 'components/DragDrop/DragDrop';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { ColumnOrderContainer, ColumOrderItem } from './styles';
import { StandardLabel } from 'components/StandardLabel/StandardLabel';
import { Margin } from 'styles/utils';
import { StyledModalButtonsWrapper, useModal } from 'components/Modal/Modal';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';

interface ColumnOrderPropTypes {
  order: string[];
  activeColumns: string[];
  onChange?: ({ order, visibility }: { order?: string[]; visibility?: string[] }) => void;
}

const ColumnOrder: FC<ColumnOrderPropTypes> = ({ order, activeColumns, onChange }) => {
  const [active, setActive] = useState(activeColumns);
  const { t } = useTranslation();

  const setVisibility = (items: string[]) => {
    setActive(items);
    onChange && onChange({ visibility: items });
  };

  const columnOrderItems = order.map((item) => ({
    id: item,
    content: ({ isDragging }: { isDragging: boolean }) => (
      <ColumOrderItem isDragging={isDragging}>
        <Checkbox
          isChecked={active.includes(item)}
          label={t(`table.columns.${item}`)}
          onClick={() => {
            setVisibility(
              active.includes(item) ? active.filter((x) => x !== item) : [...active, item],
            );
          }}
          size='small'
        />
      </ColumOrderItem>
    ),
  }));

  return (
    <ColumnOrderContainer>
      <Margin margin='0.4rem 0.8rem'>
        <StandardLabel variant='primary'>{t('forms.column_settings')}</StandardLabel>
      </Margin>
      <DragDrop
        items={columnOrderItems}
        onChange={(items) => onChange && onChange({ order: items })}
      />
    </ColumnOrderContainer>
  );
};

interface ColumnOrderModalPropTypes {
  order: string[];
  activeColumns: string[];
  onSave?: Function;
}

const ColumnOrderModal: FC<ColumnOrderModalPropTypes> = ({ order, activeColumns, onSave }) => {
  const { t } = useTranslation();
  const { hideModal } = useModal();
  const [currentOrder, setCurrentOrder] = useState<string[]>(order);
  const [currentActiveColumns, setCurrentActiveColumns] = useState<string[]>(activeColumns);

  return (
    <div>
      <ColumnOrder
        activeColumns={currentActiveColumns}
        onChange={({ order, visibility }) => {
          order && setCurrentOrder(order);
          visibility && setCurrentActiveColumns(visibility);
        }}
        order={currentOrder}
      />
      <StyledModalButtonsWrapper>
        <DefaultButton
          capitalize
          label={t('common.cancel')}
          onClick={hideModal}
          type='button'
          variant='tertiary'
        />
        <DefaultButton
          capitalize
          label={t('common.save')}
          onClick={() => {
            onSave &&
              onSave({
                order: currentOrder.filter((item) => currentActiveColumns.includes(item)),
              });
            hideModal();
          }}
          testid='submit-add-edit-contact-form'
          type='submit'
        />
      </StyledModalButtonsWrapper>
    </div>
  );
};

export { ColumnOrder, ColumnOrderModal };
