import styled from 'styled-components/macro';

export const StyledAttachmentBadge = styled.a`
  ${({ theme }) => theme.fontSizes.base};
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: 1px solid ${({ theme }) => theme.colors.black300};
  color: ${({ theme }) => theme.colors.black900};
  cursor: pointer;
  display: inline-flex;
  padding: ${({ theme }) => `${theme.sizes.xs} ${theme.sizes.sm}`};
  transition: 0.15s background-color;
  text-decoration: none;

  & > svg {
    ${({ theme }) => theme.icon.sm};

    & > path {
      color: ${({ theme }) => theme.colors.blue300};
    }
  }

  & > span {
    margin-left: ${({ theme }) => theme.sizes.hmd};
    max-width: calc(${({ theme }) => theme.sizes.md} * 6);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue50};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.blue100};
    color: ${({ theme }) => theme.colors.black900};
  }

  &:visited {
    color: ${({ theme }) => theme.colors.black900};
  }
`;
