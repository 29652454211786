import React, { FC } from 'react';
import { Redirect, RouteComponentProps, WindowLocation } from '@reach/router';
import { verifyMfaCode } from './helpers';
import toast from 'react-hot-toast';

import {
  StyledAuthForm,
  StyledAuthFormWrapper,
  StyledAuthInput,
  StyledSubmitButton,
} from './styles';
import { useTranslation } from 'react-i18next';
import { ChallengeResponse } from './types';
import { useDispatch } from 'react-redux';
import { getCurrentUser } from 'ducks/users/actions';

interface OTPProps
  extends RouteComponentProps<{ location: { state: { challengeResponse: ChallengeResponse } } }> {
  location: WindowLocation<{ challengeResponse: ChallengeResponse }>;
}

export const OTP: FC<OTPProps> = (props) => {
  const { t } = useTranslation();
  const [code, setCode] = React.useState('');
  const dispatch = useDispatch();

  if (props.location.state === null) {
    return <Redirect from='' to='../sign_in' noThrow />;
  }

  const { challengeResponse } = props.location.state;

  const { navigate } = props;
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await verifyMfaCode(code, challengeResponse);
      await dispatch(getCurrentUser());
    } catch (e) {
      toast.error(t('auth.error.invalid_code'));
      navigate && navigate('../sign_in');
    }
  };
  const handleCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  return (
    <StyledAuthFormWrapper>
      <StyledAuthForm onSubmit={onSubmit}>
        <StyledAuthInput
          autoFocus
          placeholder={t('auth.otp')}
          aria-label='one time code'
          type='text'
          onChange={handleCode}
        />
        <StyledSubmitButton type='submit'>{t('auth.login')}</StyledSubmitButton>
      </StyledAuthForm>
    </StyledAuthFormWrapper>
  );
};
