import React from 'react';
import { navigate } from '@reach/router';

import i18n from 'i18n';
import { StyledNoWrapCellContent } from 'components/Table/TableRow/styles';
import { CapsLabel } from 'components/CapsLabel/styles';
import { IMailLog } from 'containers/MailLog/types';
import { API } from 'api';
import { StatusBadge } from 'components/StatusBadge/StatusBadge';
import { StyledLink } from 'containers/companyDetails/BasicData/styles';

const t = (x: string) => i18n.t(x);

export const columns: ('date' | 'company' | 'email' | 'status')[] = [
  'date',
  'company',
  'email',
  'status',
];

const statusesMap = {
  failed: { variant: 'fail', content: 'common.failed' },
  delivered: { variant: 'success', content: 'common.delivered' },
  accepted: { variant: 'low-alert', content: 'common.accepted' },
} as const;

export const componentsMap = {
  date: {
    component: (x: IMailLog) => <StyledNoWrapCellContent>{x.date}</StyledNoWrapCellContent>,
    size: 'auto',
    headerComponent: () => <CapsLabel>{t('table.columns.mail_log_date')}</CapsLabel>,
  },
  company: {
    component: (x: IMailLog) => (
      <StyledLink onClick={() => navigate(`/redesigned/companies/${x.companyId}`)}>
        <StyledNoWrapCellContent>{x.company}</StyledNoWrapCellContent>
      </StyledLink>
    ),
    size: 'auto',
    headerComponent: () => <CapsLabel>{t('table.columns.mail_log_company')}</CapsLabel>,
  },
  email: {
    component: (x: IMailLog) => <StyledNoWrapCellContent>{x.email}</StyledNoWrapCellContent>,
    size: 'auto',
    headerComponent: () => <CapsLabel>{t('table.columns.recipient')}</CapsLabel>,
  },
  status: {
    component: (x: IMailLog) => (
      <StatusBadge variant={statusesMap[x.status].variant}>
        {t(statusesMap[x.status].content)}
      </StatusBadge>
    ),
    size: 'min-content',
  },
} as const;

export const fetchMailLogs = async () => {
  const response = await API.get<IMailLog[]>(`dashboard/mail_logs`);

  return response.data;
};
