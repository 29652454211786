import { StyledCardHeader } from 'components/Card/Card';
import * as React from 'react';
import { Margin } from 'styles/utils';
import { ArrowDownIcon, ArrowUpIcon } from 'utils/iconsMap';

interface CollapsibleSectionProps {
  children: React.ReactNode;
  defaultOpen?: boolean;
  header: string;
  marginBottom?: string;
  name?: string; // use to persist state in sessionStorage
  testid?: string;
}

export interface SectionHandlers {
  close: () => void;
  open: () => void;
  set: (isOpen: boolean) => void;
  toggle: () => void;
}

export const CollapsibleSection = (props: CollapsibleSectionProps) => {
  const { header, children, defaultOpen = true, name, marginBottom = '2.4rem', testid } = props;
  const prevName = React.useRef<string | undefined>();

  const [isOpen, setIsOpen] = React.useState(defaultOpen);

  React.useEffect(() => {
    if (!name) return;

    if (prevName.current !== name) {
      prevName.current = name;
      const storageValue = sessionStorage.getItem(name);
      setIsOpen(storageValue ? JSON.parse(storageValue) : true);
    } else {
      sessionStorage.setItem(name, JSON.stringify(isOpen));
    }
  }, [name, isOpen]);

  return (
    <Margin bottom={marginBottom} data-testid={testid}>
      <StyledCardHeader as='button' onClick={() => setIsOpen((x) => !x)}>
        {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
        {header}
      </StyledCardHeader>
      <div>{isOpen ? children : null}</div>
    </Margin>
  );
};
