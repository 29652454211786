import React, { FormEvent, useCallback, useState } from 'react';
import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';
import { isEmpty } from 'lodash';
import { SerializedError } from '@reduxjs/toolkit';
import * as yup from 'yup';
import { CheckboxField } from 'components/Forms/CheckboxField/CheckboxField';
import { StyledChooseVisitForm } from 'containers/newVisit/styles';
import { yupValidate } from 'utils/yupValidate';
import { TextField } from 'components/Forms/TextField/TextField';
import { API } from 'api';
import {
  StyledProfileFormRow,
  StyledProfileFormSingleRow,
  StyledProfileKindRow,
  StyledProfileStreetRow,
} from './ProfileFormStyles';
import { StyledError } from 'containers/newVisit/OrdinaryVisit/styles';
import { Margin } from 'styles/utils';
import i18n from 'i18n';
import { ValuesPostType } from 'ducks/users/types';

const t = (text: string) => i18n.t(text);

interface ValuesType {
  name?: string;
  email?: string;
  phone?: string;
  postal_area?: string;
  postal_area_name?: string;
  postal_area_id?: number;
  address?: string;
  industryName?: string;
  districtName?: string;
  receiveCalendarEvents?: boolean;
}

export interface ProfileFormSubmitValues {
  id?: string;
  name?: string;
  email?: string;
  phone?: string;
  postal_area_id?: number;
  address?: string;
  industryName?: string;
  districtName?: string;
  receiveCalendarEvents?: boolean;
}

type PostalCodeResponseType = Readonly<{
  data: {
    code: string;
    id: number;
    municipality: string;
    municipalityId: number;
    name: string;
  };
}>;

interface ProfileFormPropTypes {
  Buttons?: (props: { onClick: () => void; isPrimaryDisabled: boolean }) => JSX.Element | null;
  buttonsPosition?: 'inside' | 'outside';
  formId?: string;
  init?: Partial<ValuesPostType & { id: string }>;
  isCommentEditable?: boolean;
  onSubmit: (values: ProfileFormSubmitValues) => Promise<unknown>;
}

export const ProfileForm = (props: ProfileFormPropTypes) => {
  const { Buttons, buttonsPosition = 'inside', formId, init } = props;
  const [disabled, setDisabled] = useState(false);
  const onSubmit = useCallback(
    async (values: ValuesType) => {
      setDisabled(true);
      const { name, phone, email, address, postal_area_id, receiveCalendarEvents } = values;

      const valuesToPost = {
        name: name || '',
        email: email || '',
        phone: phone || '',
        address: address || '',
        postal_area_id: postal_area_id ? Number(postal_area_id) : undefined,
        receiveCalendarEvents: receiveCalendarEvents || false,
      };
      return props
        .onSubmit(valuesToPost)
        .then(() => window.location.reload())
        .catch((err: SerializedError) => ({ [FORM_ERROR]: err.message }));
    },
    [props.onSubmit],
  );

  const postCodeChange = useCallback(async (value: string, formOnChange: Function) => {
    formOnChange('postal_area', value);
    if (value.length === 4) {
      try {
        const response: PostalCodeResponseType = await API.get(`/post_codes/${value}`);
        const { id, name } = response.data;
        formOnChange('postal_area_name', name);
        formOnChange('postal_area_id', id);
      } catch (error) {
        formOnChange('postal_area_name', '');
        formOnChange('postal_area_id', undefined);
      }
    } else {
      formOnChange('postal_area_name', '');
      formOnChange('postal_area_id', undefined);
    }
  }, []);

  return (
    <>
      <Form
        initialValues={(init || {}) as unknown as ValuesType}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, values, submitError, errors }) => (
          <StyledChooseVisitForm id={formId} onSubmit={handleSubmit}>
            <StyledProfileFormSingleRow>
              <Field
                additionalInputProps={{ vsize: 'small' }}
                component={TextField}
                label={t('forms.name')}
                name='name'
                required
                subscription={{ value: true, touched: true, error: true }}
              />
            </StyledProfileFormSingleRow>
            <StyledProfileStreetRow>
              <Field
                additionalInputProps={{ vsize: 'small' }}
                component={TextField}
                label={t('forms.street_name_and_number')}
                name='address'
                required
              />
              <Field
                additionalInputProps={{ vsize: 'small' }}
                component={TextField}
                inputExtra={{
                  maxLength: 4,
                  inputType: 'numeric',
                  onChange: ({ currentTarget: { value } }: FormEvent<HTMLInputElement>) => {
                    postCodeChange(value, form.change);
                  },
                  value: values.postal_area,
                }}
                label={t('forms.post_code')}
                name='postal_area'
                required
                subscription={{ value: true, touched: true, error: true }}
              />
              <Field
                additionalInputProps={{ vsize: 'small' }}
                component={TextField}
                isDisabled
                label={t('forms.post_area')}
                name='postal_area_name'
              />
            </StyledProfileStreetRow>
            <StyledProfileKindRow>
              <Field
                additionalInputProps={{ vsize: 'small' }}
                component={TextField}
                isDisabled
                label={t('forms.email_address')}
                name='email'
                subscription={{ value: true, touched: true, error: true }}
              />
              <Field
                additionalInputProps={{ vsize: 'small' }}
                component={TextField}
                label={t('table.columns.phone')}
                name='phone'
                required
                subscription={{ value: true, touched: true, error: true }}
              />
            </StyledProfileKindRow>
            <StyledProfileFormRow>
              <Field
                component={CheckboxField}
                label={t('forms.receiveCalendarEvents')}
                name='receiveCalendarEvents'
                subscription={{ value: true }}
              />
            </StyledProfileFormRow>
            <StyledProfileKindRow>
              <Field
                additionalInputProps={{ vsize: 'small' }}
                component={TextField}
                isDisabled
                label={t('table.columns.industry')}
                name='industryName'
              />
              <Field
                additionalInputProps={{ vsize: 'small' }}
                component={TextField}
                isDisabled
                label={t('table.columns.district_names')}
                name='districtName'
              />
            </StyledProfileKindRow>
            {submitError && (
              <Margin margin='1.2rem auto'>
                <StyledError>{submitError}</StyledError>
              </Margin>
            )}
            {Buttons && buttonsPosition === 'outside' && (
              <Buttons isPrimaryDisabled={!isEmpty(errors) || disabled} onClick={form.submit} />
            )}
          </StyledChooseVisitForm>
        )}
        validate={yupValidate(schema)}
      />
    </>
  );
};

const schema = yup.object().shape({
  name: yup.string().required(),
  phone: yup.number().required(),
  address: yup.string().required().nullable(),
  postal_area_name: yup.string().required(),
  postal_area: yup
    .string()
    .nullable()
    // @ts-ignore
    .test('hasPostnummerOrMunicipality', t('forms.required_postal_code'), function (value: string) {
      return value?.length !== 4 || (value?.length === 4 && !this.parent.postal_area_name)
        ? false
        : true;
    }),
});
