import React, { FC, InputHTMLAttributes } from 'react';

import { StyledBox, StyledCheckbox, StyledCheckboxLabel, StyledCheckboxMainLabel } from './styles';
import { CheckIcon } from 'utils/iconsMap';

export type CheckboxSizes = 'small' | 'medium' | 'large';

interface NestedCheckboxPropsType {
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  isChecked?: boolean;
  isDisabled?: boolean;
  isIndeterminate?: boolean;
  label?: string;
  labelColor?: string;
  onClick: (e?: React.MouseEvent | React.ChangeEvent) => void;
  size?: CheckboxSizes;
  testid?: string;
}

export const NestedCheckbox: FC<NestedCheckboxPropsType> = ({
  inputProps,
  isDisabled,
  isIndeterminate,
  label,
  labelColor,
  onClick,
  size = 'medium',
  testid = 'checkbox-component',
}) => {
  const [checked, setChecked] = React.useState(true);
  const RenderedIcon = () => {
    if (checked) {
      return <CheckIcon />;
    }
    return null;
  };

  return (
    <StyledCheckbox
      data-testid={testid}
      onClick={(e) => {
        e.stopPropagation();
        if (!isDisabled) {
          setChecked(!checked);
          onClick();
        }
      }}
    >
      <input
        checked={checked}
        disabled={isDisabled}
        name='checkbox'
        onChange={onClick}
        type='checkbox'
        {...inputProps}
      />
      <StyledBox data-testid='Checkbox' isIndeterminate={isIndeterminate} size={size}>
        <RenderedIcon />
      </StyledBox>
      {label && (
        <StyledCheckboxLabel color={labelColor} htmlFor='checkbox'>
          <StyledCheckboxMainLabel>{label}</StyledCheckboxMainLabel>
        </StyledCheckboxLabel>
      )}
    </StyledCheckbox>
  );
};

NestedCheckbox.defaultProps = {
  isChecked: false,
  isDisabled: false,
  isIndeterminate: false,
  label: '',
};
