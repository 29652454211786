import { visitsComponentsMap } from 'containers/companyDetails/Visits/tableComponentsMap';
import { cells } from 'utils/cells';

export const companyVisitsTableMapper = {
  ...visitsComponentsMap,

  company_name: {
    component: cells.company_name.component,
    size: 'min-content',
  },
  company_aka: {
    component: cells.company_aka.component,
    size: 'min-content',
  },
};

export type companyVisitsTableMapperType = typeof companyVisitsTableMapper;
