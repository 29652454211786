import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useLocation, useMatch, useNavigate } from '@reach/router';
import { getUsers } from 'ducks/users/actions';
import { StyledLayoutWithFullWidth, StyledTabContainer } from 'containers/styles';
import {
  TabsName,
  WorkplaceNavigationPanel,
} from 'containers/workplaceDetails/WorkplaceNavigationPanel/WorkplaceNavigationPanel';
import { getWorkplace } from 'ducks/workplaces/actions';
import { StoreType } from 'store';

interface PageProps extends RouteComponentProps {
  workplaceId?: string;
}

export const Page: FC<PageProps> = (props) => {
  const { children, workplaceId, uri } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const match = useMatch('/redesigned/workplaces/:workplaceId/:tab');
  const navigate = useNavigate();
  const workplaceDetails = useSelector((state: StoreType) => state.workplaces.workplace);

  useEffect(() => {
    const fetchWorkplace = async (id: string) => {
      await dispatch(getWorkplace(id));
    };
    workplaceId && fetchWorkplace(workplaceId);
    dispatch(getUsers());
  }, [workplaceId, dispatch, match?.workplaceId !== workplaceDetails?.workplaceId]);

  if (!workplaceDetails) return null;
  if (!match) navigate(`${uri}/basic-data`, { state: { ...location.state } });

  return (
    <>
      <WorkplaceNavigationPanel
        activeTab={match?.tab as TabsName}
        workplaceId={workplaceId || ''}
        workplaceName={workplaceDetails.name || ''}
      />
      <StyledTabContainer>
        <StyledLayoutWithFullWidth>{children}</StyledLayoutWithFullWidth>
      </StyledTabContainer>
    </>
  );
};
