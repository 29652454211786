import { Placement } from '@popperjs/core';
import styled, { css } from 'styled-components/macro';

interface StyledSurfacePropTypes {
  'data-popper-placement'?: Placement;
}

export const StyledSurface = styled.div<StyledSurfacePropTypes>`
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  pointer-events: none;
  z-index: 1001;
  ${(props) =>
    props['data-popper-placement']?.includes('bottom') &&
    css`
      flex-direction: column-reverse;
    `}
`;

export const StyledTooltip = styled.div`
  background-color: ${({ theme }) => theme.colors.black700};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  color: white;
  padding: ${({ theme }) => theme.sizes.hmd};

  &:first-letter {
    text-transform: capitalize;
  }
`;

interface StyledArrowPropTypes {
  'data-popper-placement'?: Placement;
}

export const StyledArrow = styled.div<StyledArrowPropTypes>`
  position: relative;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 7px solid ${({ theme }) => theme.colors.black700};
  top: -1px;

  ${(props) =>
    props['data-popper-placement']?.includes('bottom') &&
    css`
      border-top: 0;
      border-bottom: 7px solid ${({ theme }) => theme.colors.black700};
      bottom: 0;
      top: 1px;
    `}
`;

interface StyledContentPropTypes {
  isCentred?: boolean;
}

export const StyledContent = styled.span<StyledContentPropTypes>`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ isCentred }) =>
    isCentred &&
    css`
      margin: 0 auto;
    `}
`;
