import styled, { css } from 'styled-components/macro';

export const StyledSwitch = styled.input`
  position: absolute;
  visibility: hidden;
  z-index: -1;
`;

export const StyledSwitchWrapper = styled.label`
  margin-bottom: ${({ theme }) => theme.sizes.sm};
  align-items: center;
  display: flex;
`;

export const StyledSwitchBox = styled.div<{ active: boolean }>`
  background-color: ${({ theme }) => theme.colors.black100};
  border-radius: 2rem;
  height: 2.4rem;
  transition: background-color 0.3s;
  width: 4.8rem;

  &:after {
    background-color: white;
    border-radius: 50%;
    box-shadow: ${({ theme }) => theme.boxShadows.lightBlue300};
    content: '';
    display: block;
    height: 2.4rem;
    transition: transform 0.3s, background-color 0.3s, box-shadow 0.3s;
    width: 2.4rem;
  }

  ${({ theme, active }) =>
    active &&
    css`
      background-color: ${theme.colors.blue100};

      &:after {
        box-shadow: ${theme.boxShadows.blue300};
        background-color: ${theme.colors.blue500};
        transform: translateX(100%);
      }
    `}
`;

export const StyledSwitchLabel = styled.div<{ capitalize?: boolean }>`
  color: ${({ theme }) => theme.colors.black700};
  line-height: 1.4rem;
  margin-left: 1.2rem;
  user-select: none;

  ${({ capitalize }) =>
    capitalize &&
    css`
      &::first-letter {
        text-transform: capitalize;
      }
    `}
`;
