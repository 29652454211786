import React from 'react';
import { isPast, isToday } from 'date-fns';
import { capitalize } from 'lodash';
import { navigate } from '@reach/router';
import i18n from 'i18n';
import { BASE_URL } from 'api/apiUrl';
import {
  CapsContent,
  StyledCellContent,
  StyledDeadline,
  StyledNoWrapCellContent,
  StyledTruncatedContent,
} from 'components/Table/TableRow/styles';
import { companyVisitStatusesMap } from 'consts/companyVisitStatusesMap';
import { DashboardVisitEntity } from 'ducks/dashboard/types';
import { formatDate } from 'utils/date';
import { InfoIcon, PdfIcon, PeopleIcon, StopIcon, ThumbUp, WarningIcon } from 'utils/iconsMap';
import { SpanCenter } from 'styles/utils';
import { StatusBadge, StatusBadgeVariants } from 'components/StatusBadge/StatusBadge';
import { TextLink } from 'components/TextLink/TextLink';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { VisitEntity } from 'ducks/visits/types';
import { TextOnclickLink } from 'components/TextOnclickLink/TextOnclickLink';
import { DateComponent } from './DateComponent';
import { StyledLink } from 'containers/companyDetails/BasicData/styles';
import { TipsIdLink } from 'components/TipsIdLink/TipsIdLink';
import { RvoEventsEntity } from 'ducks/events/types';

const t = (x: string) => i18n.t(x);
export const dateExceedDeadline = (deadline: DashboardVisitEntity['deadline']) =>
  (deadline &&
    deadline?.length === 10 &&
    isPast(new Date(deadline)) &&
    !isToday(new Date(deadline))) ||
  false;

export const cells = {
  comment: {
    component: ({ comment, status }: DashboardVisitEntity) => {
      if (status === 'scheduled') {
        return (
          <Tooltip label={comment && comment?.length > 24 ? comment : ''}>
            <StyledTruncatedContent>{comment || '-'}</StyledTruncatedContent>
          </Tooltip>
        );
      }
      return <StyledNoWrapCellContent>-</StyledNoWrapCellContent>;
    },
    size: 'minmax(24rem, max-content)',
  },
  company_aka: {
    component: ({ companyAka }: VisitEntity | DashboardVisitEntity) => (
      <StyledNoWrapCellContent>{companyAka || '-'}</StyledNoWrapCellContent>
    ),
    size: 'max-content',
  },
  company_name: {
    component: ({ companyName, companyId }: VisitEntity | DashboardVisitEntity) => {
      if (companyId) {
        return (
          <TextOnclickLink
            onClick={() =>
              navigate(`/redesigned/companies/${companyId}/basic-data`, {
                state: { ...window.history.state, previousPathname: window.location.pathname },
              })
            }
          >
            <StyledNoWrapCellContent>{companyName}</StyledNoWrapCellContent>
          </TextOnclickLink>
        );
      }
      return <StyledNoWrapCellContent>{companyName || '-'}</StyledNoWrapCellContent>;
    },
    size: 'max-content',
  },
  date: {
    component: DateComponent,
    size: 'min-content',
  },
  deadline: {
    component: ({ deadline, date, tipsExternalId }: VisitEntity | DashboardVisitEntity) => {
      return (
        <StyledDeadline inViolation={tipsExternalId ? false : date.inViolation}>
          {deadline ? formatDate(deadline) : '-'}
        </StyledDeadline>
      );
    },
    size: 'min-content',
  },
  district_names: {
    component: ({ districtNames }: VisitEntity | DashboardVisitEntity | RvoEventsEntity) =>
      districtNames?.length > 1 ? (
        <Tooltip label={districtNames.join(', ')}>
          <StyledNoWrapCellContent>
            {districtNames[0]} + {districtNames.length - 1}
          </StyledNoWrapCellContent>
        </Tooltip>
      ) : (
        <StyledNoWrapCellContent>
          {!districtNames || districtNames.length === 0 ? '-' : districtNames[0]}
        </StyledNoWrapCellContent>
      ),
    size: 'max-content',
  },
  issues: {
    component: ({ issues }: VisitEntity | DashboardVisitEntity) => (
      <SpanCenter>{issues || '-'}</SpanCenter>
    ),
    size: 'min-content',
    headerComponent: () => (
      <Tooltip label={t(`table.columns.issues`)}>
        <SpanCenter withFlex>
          <WarningIcon data-testid='th-issues' />
        </SpanCenter>
      </Tooltip>
    ),
  },
  municipality: {
    component: ({ municipality }: VisitEntity | DashboardVisitEntity) => (
      <CapsContent>{municipality || '-'}</CapsContent>
    ),
    size: 'min-content',
  },
  tips_external_id: {
    component: ({ tipsExternalId, companyVisitId }: VisitEntity | DashboardVisitEntity) => {
      return (
        <TipsIdLink
          entityId={companyVisitId}
          entityType='companyVisit'
          tipsExternalId={tipsExternalId}
        />
      );
    },
    size: 'min-content',
  },
  number_of_employees: {
    component: ({ numberOfEmployees }: VisitEntity | DashboardVisitEntity) => (
      <SpanCenter>{numberOfEmployees || '-'}</SpanCenter>
    ),
    size: 'min-content',
    headerComponent: () => (
      <Tooltip label={t(`table.columns.number_of_employees_workplace`)}>
        <SpanCenter withFlex>
          <PeopleIcon data-testid='th-number_of_employees' />
        </SpanCenter>
      </Tooltip>
    ),
  },
  deadlines_fulfilled: {
    component: ({ deadlinesOverdue, deadlinesFulfilled }: VisitEntity | DashboardVisitEntity) => {
      let variant: StatusBadgeVariants;

      if (deadlinesFulfilled) {
        variant = 'success';
      } else if (deadlinesOverdue) {
        variant = 'fail';
      } else {
        variant = 'low-alert';
      }

      return deadlinesFulfilled === null ? (
        <StyledNoWrapCellContent>-</StyledNoWrapCellContent>
      ) : (
        <SpanCenter>
          <StatusBadge variant={variant}>
            {t(`common.${deadlinesFulfilled ? 'yes' : 'no'}`)}
          </StatusBadge>
        </SpanCenter>
      );
    },
    size: 'min-content',
  },
  rvo: {
    component: ({ rvo }: VisitEntity | DashboardVisitEntity) => (
      <StyledNoWrapCellContent>{rvo || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  status: {
    component: ({ status }: VisitEntity | DashboardVisitEntity) =>
      status ? (
        <StatusBadge variant={companyVisitStatusesMap[status].variant}>
          {capitalize(companyVisitStatusesMap[status].content)}
        </StatusBadge>
      ) : (
        <div>-</div>
      ),
    size: 'min-content',
  },
  stops: {
    component: ({ stops }: VisitEntity | DashboardVisitEntity) => (
      <SpanCenter>{stops || '-'}</SpanCenter>
    ),
    size: 'min-content',
    headerComponent: () => (
      <Tooltip label={t(`table.columns.stops`)}>
        <SpanCenter withFlex>
          <StopIcon data-testid='th-stops' />
        </SpanCenter>
      </Tooltip>
    ),
  },
  guidances: {
    component: ({ guidances }: VisitEntity | DashboardVisitEntity) => (
      <SpanCenter>{guidances || '-'}</SpanCenter>
    ),
    size: 'min-content',
    headerComponent: () => (
      <Tooltip label={t(`table.columns.guidances`)}>
        <SpanCenter withFlex>
          <InfoIcon data-testid='th-guidances' />
        </SpanCenter>
      </Tooltip>
    ),
  },
  oks: {
    component: ({ oks }: VisitEntity | DashboardVisitEntity) => (
      <SpanCenter>{oks || '-'}</SpanCenter>
    ),
    size: 'min-content',
    headerComponent: () => (
      <Tooltip label={t(`table.columns.oks`)}>
        <SpanCenter withFlex>
          <ThumbUp data-testid='th-oks' />
        </SpanCenter>
      </Tooltip>
    ),
  },
  type: {
    component: ({ type }: VisitEntity | DashboardVisitEntity) => (
      <StyledNoWrapCellContent>{type || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  type_of_visit: {
    component: ({ typeOfVisit }: VisitEntity | DashboardVisitEntity) => (
      <StyledNoWrapCellContent>{typeOfVisit || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  industry: {
    component: ({ industry }: VisitEntity | DashboardVisitEntity) => (
      <StyledNoWrapCellContent>{industry || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  visited_as: {
    component: ({ visitedAs }: VisitEntity | DashboardVisitEntity) =>
      visitedAs?.length > 1 ? (
        <Tooltip label={visitedAs.join(', ')}>
          <StyledNoWrapCellContent>
            {visitedAs[0]} + {visitedAs.length - 1}
          </StyledNoWrapCellContent>
        </Tooltip>
      ) : (
        <StyledNoWrapCellContent>
          {!visitedAs || visitedAs.length === 0 ? '-' : visitedAs[0]}
        </StyledNoWrapCellContent>
      ),
    size: 'max-content',
  },
  address: {
    component: ({ address }: VisitEntity | DashboardVisitEntity) => (
      <StyledNoWrapCellContent>{address || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  postal_code: {
    component: ({ postalCode }: VisitEntity | DashboardVisitEntity) => (
      <StyledNoWrapCellContent>{postalCode || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  visit_report: {
    component: ({ visitReport }: VisitEntity | DashboardVisitEntity) => {
      if (visitReport) {
        return (
          <TextLink href={`${BASE_URL}${visitReport}`}>
            <PdfIcon height='20' width='20' />
          </TextLink>
        );
      }
      return <CapsContent>-</CapsContent>;
    },
    headerComponent: () => (
      <Tooltip label={t(`table.columns.visit_report`)}>
        <SpanCenter withFlex>
          <PdfIcon data-testid='th-report' height='20' width='20' />
        </SpanCenter>
      </Tooltip>
    ),
    size: 'min-content',
  },
  workplace: {
    component: ({ workplace }: VisitEntity | DashboardVisitEntity) => {
      if (!workplace) return <StyledCellContent>-</StyledCellContent>;
      return (
        <StyledTruncatedContent>
          <StyledLink
            as='button'
            onClick={() =>
              navigate(`/redesigned${workplace.url}/basic-data`, {
                state: { ...window.history.state, previousPathname: window.location.pathname },
              })
            }
          >
            {workplace.value}
          </StyledLink>
        </StyledTruncatedContent>
      );
    },
    size: 'minmax(24rem, max-content)',
  },
};
