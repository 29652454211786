import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoadingContext } from 'contexts/LoadingContext';
import { StyledModalButtonsWrapper, StyledModalHeader, useModal } from 'components/Modal/Modal';
import { StyledForm } from 'components/Forms/styles';
import { Field, Form, FormSpy } from 'react-final-form';
import { TextField } from 'components/Forms/TextField/TextField';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { SwitchField } from 'components/Forms/SwitchField/SwitchField';
import { updateCompany } from 'ducks/companies/actions';
import { useAppDispatch } from 'store';
import { FormApi } from 'final-form';

interface EditCompanyValues {
  aka: string;
  companyName: string;
  id: string;
  taxable: boolean;
  orgNumber: string;
  commentOnCompanyStatus: string;
}

type FormValues = Omit<EditCompanyValues, 'id'>;

export const EditCompanyModal: FC<EditCompanyValues> = ({
  aka,
  companyName,
  id,
  taxable,
  orgNumber,
  commentOnCompanyStatus,
}) => {
  const { t } = useTranslation();
  const { setLoaded, isLoading, setLoading } = useLoadingContext();
  const { hideModal } = useModal();
  const loadingContextActionName = 'email-sent-form';
  const dispatch = useAppDispatch();

  const initialValues = {
    aka: aka,
    companyName: companyName,
    orgNumber: orgNumber,
    taxable,
    commentOnCompanyStatus,
  };

  const onSubmit = async (values: {
    taxable: boolean;
    aka?: string;
    commentOnCompanyStatus: string;
  }) => {
    setLoading(loadingContextActionName);
    await dispatch(
      updateCompany({
        companyId: id,
        aka: values?.aka || '',
        taxable: values.taxable,
        commentOnCompanyStatus: values.commentOnCompanyStatus,
      }),
    );
    setLoaded(loadingContextActionName);
    hideModal();
  };

  const handleFormStateChange = (values: FormValues, form: FormApi<FormValues>) => {
    if (
      values.taxable !== initialValues.taxable &&
      !form.getFieldState('commentOnCompanyStatus')?.active
    ) {
      form.change('commentOnCompanyStatus', '');
    }

    if (
      values.taxable === initialValues.taxable &&
      form.getState().dirtyFieldsSinceLastSubmit.taxable
    ) {
      form.change('commentOnCompanyStatus', commentOnCompanyStatus);
    }
  };

  return (
    <>
      <StyledModalHeader>{t('company_details.edit_company')}</StyledModalHeader>
      <Form
        initialValues={initialValues}
        keepDirtyOnReinitialize
        onSubmit={onSubmit}
        render={({ handleSubmit, errors, values, form }) => (
          <StyledForm onSubmit={handleSubmit}>
            <FormSpy<FormValues>
              subscription={{ values: true }}
              onChange={(props) => handleFormStateChange(props.values, form)}
            />
            <Field
              component={TextField}
              isDisabled
              label={t('table.columns.name')}
              name='companyName'
              required
            />
            <Field
              component={TextField}
              isDisabled
              label={t('company_details.organisational_number')}
              name='orgNumber'
              required
            />
            <Field component={TextField} label={t('company_details.basic-data.aka')} name='aka' />
            <Field
              capitalize
              component={SwitchField}
              label={
                values.taxable
                  ? `${t('company_details.company_is_active')} (${t('company_details.taxable')})`
                  : `${t('company_details.company_is_inactive')} (${t(
                      'company_details.not_taxable',
                    )})`
              }
              name='taxable'
            />
            <Field
              component={TextField}
              isDisabled={!form.getState().dirtyFields.taxable}
              label={t('company_details.comment_on_company_status')}
              name='commentOnCompanyStatus'
              required
            />
            <StyledModalButtonsWrapper>
              <DefaultButton
                capitalize
                isDisabled={isLoading(loadingContextActionName)}
                label={t('common.cancel')}
                onClick={hideModal}
                type='button'
                variant='tertiary'
              />
              <DefaultButton
                capitalize
                isDisabled={
                  errors.hasError ||
                  isLoading(loadingContextActionName) ||
                  (form.getState().dirtyFields.taxable && !values.commentOnCompanyStatus)
                }
                label={t('common.save')}
              />
            </StyledModalButtonsWrapper>
          </StyledForm>
        )}
      />
    </>
  );
};
