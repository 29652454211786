import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';

import {
  AsyncMultiSelect,
  AsyncMultiSelectProps,
  Option,
} from 'components/AsyncMultiselect/AsyncMultiSelect';

export type AsyncMultiSelectFieldProps = Omit<
  AsyncMultiSelectProps,
  'onChange' | 'value' | 'hasError'
> &
  FieldRenderProps<Option[]>;

export const AsyncMultiSelectField = (props: AsyncMultiSelectFieldProps): React.ReactElement => {
  const { input, meta, ...rest } = props;
  const { value, onChange, ...inputRest } = input;
  const hasError = meta.touched && (meta.error as string | undefined);

  return (
    <AsyncMultiSelect
      {...rest}
      extraInputProps={inputRest}
      hasError={hasError}
      onChange={input.onChange}
      value={input.value}
    />
  );
};
