import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Checkbox } from 'components/Checkbox/Checkbox';
import { contactsTableMapper } from 'containers/newVisit/shared/tableMappers/summaryContacts';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { EmailSearchSelect } from './EmailSearchSelect';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { Margin } from 'styles/utils';
import { MultiSelectField } from 'components/Forms/MultiSelectField/MultiSelectField';
import { RichText } from 'components/RichText/RichText';
import { rvoUsersSelector } from 'ducks/users/selectors';
import { StandardLabel } from 'components/StandardLabel/StandardLabel';
import { StyledColumn } from 'containers/companyDetails/BasicData/styles';
import { StyledModalButtonsWrapper, StyledModalHeader, useModal } from 'components/Modal/Modal';
import { TableGrid } from 'components/Table/TableGrid/TableGrid';
import { useAppDispatch } from 'store';
import {
  getVisitById,
  newVisitCompanyContactsSelector,
  newVisitCompanyEmailSelector,
  newVisitCompanyNameSelector,
  NewVisitContact,
  submitVisitReport,
  SubmitVisitReportParams,
  visitIdSelector,
} from 'ducks/newVisit';
import toast from 'react-hot-toast';

interface RecipientsModalProps {
  companyVisitId: string;
  workplaceContacts?: NewVisitContact[];
}

interface OtherRecipient {
  actions: [{ type: 'delete' }];
  email: string;
  id: string;
  name: string;
}

const recipientToOtherRecipient = ({
  email,
  name,
}: {
  email: string;
  name: string;
}): OtherRecipient => ({
  actions: [{ type: 'delete' }],
  email,
  id: email,
  name,
});

export const RecipientsModal: FC<RecipientsModalProps> = ({
  companyVisitId,
  workplaceContacts = [],
}) => {
  const { t } = useTranslation();
  const { hideModal } = useModal();

  const [isProcessing, setIsProcessing] = useState(false);
  const [includeMyself, setIncludeMyself] = useState(false);
  const [includeCompanyMail, setIncludeCompanyMail] = useState(false);
  const [otherRecipients, setOtherRecipients] = useState<{ name: string; email: string }[]>([]);
  const [selectedRvos, setSelectedRvos] = useState<string[]>([]);
  const [body, setBody] = useState('');

  const companyVisitName = useSelector(newVisitCompanyNameSelector(companyVisitId));
  const companyVisitContacts = useSelector(newVisitCompanyContactsSelector(companyVisitId));
  const companyVisitEmail = useSelector(newVisitCompanyEmailSelector(companyVisitId)) || '';

  const visitId = useSelector(visitIdSelector);
  const rvos = useSelector(rvoUsersSelector);
  const dispatch = useAppDispatch();

  const contacts = useMemo(
    (): NewVisitContact[] =>
      [...companyVisitContacts, ...workplaceContacts].filter(
        ({ receiveVisitReport }) => receiveVisitReport,
      ),
    [companyVisitContacts, workplaceContacts],
  );

  const noRecipientsSelected =
    contacts.length === 0 &&
    otherRecipients.length === 0 &&
    selectedRvos.length === 0 &&
    !includeMyself &&
    (companyVisitEmail.length === 0 || (companyVisitEmail.length > 0 && !includeCompanyMail));

  const actionHandlers = {
    delete: (record: OtherRecipient) => {
      setOtherRecipients((oR) => oR.filter(({ email }) => email !== record.email));
    },
  };

  const onSubmit = async () => {
    setIsProcessing(true);
    const extraRecipients: SubmitVisitReportParams['extraRecipients'] = [...otherRecipients];

    selectedRvos.forEach((email) => {
      const rvo = rvos.find(({ value }) => value === email)!;
      extraRecipients.push({ name: rvo.label, email: rvo.value });
    });

    if (companyVisitEmail && includeCompanyMail) {
      extraRecipients.push({ name: companyVisitName || '', email: companyVisitEmail });
    }

    const data: SubmitVisitReportParams = {
      body,
      companyVisitId,
      includeMeInCopy: includeMyself,
      extraRecipients,
    };

    try {
      const response = await submitVisitReport(data);

      if (response.errorMessage) {
        throw new Error(response.errorMessage);
      }

      dispatch(getVisitById({ id: String(visitId) }));
      hideModal();
    } catch (error) {
      setIsProcessing(false);
      toast.error((error as Error).message);
    }
  };

  return (
    <>
      <StyledModalHeader>{t('new_visits.recipients')}</StyledModalHeader>

      {contacts.length > 0 && (
        <StyledColumn>
          <TableGrid
            bodyData={contacts}
            columnsOrder={['name', 'role', 'email']}
            componentsMap={contactsTableMapper}
            noConfig
          />
        </StyledColumn>
      )}

      {noRecipientsSelected && <InfoBox message={t('new_visits.no_recipients')} />}

      {!companyVisitEmail && (
        <Margin top='1rem'>
          <InfoBox message={t('forms.company_email_missing')} />
        </Margin>
      )}

      <Margin margin='2.4rem 0'>
        {companyVisitEmail && (
          <Margin bottom='1rem'>
            <Checkbox
              isChecked={includeCompanyMail}
              label={`${t('forms.send_to_company_email')} ${companyVisitEmail}`}
              onClick={() => setIncludeCompanyMail((x) => !x)}
            />
          </Margin>
        )}

        <Checkbox
          isChecked={includeMyself}
          label={t('forms.send_a_copy_to_my_email')}
          onClick={() => setIncludeMyself((x) => !x)}
        />
      </Margin>

      <MultiSelectField
        input={{
          value: selectedRvos,
          onChange: setSelectedRvos,
          name: '',
          onBlur: () => {},
          onFocus: () => {},
        }}
        label={t('forms.send_a_copy_to_other_rvo')}
        meta=''
        options={rvos}
        placeholder={t('forms.select_rvo')}
        size='full-width'
      />

      <StandardLabel>{t('new_visits.other_recipients')}</StandardLabel>
      <EmailSearchSelect
        onSelect={(recipient: { name: string; email: string }) =>
          setOtherRecipients((oR) => {
            oR.every(({ email }) => recipient.email !== email);
            return oR.every(({ email }) => recipient.email !== email) ? [...oR, recipient] : oR;
          })
        }
      />
      {otherRecipients.length > 0 && (
        <TableGrid
          actionHandlers={actionHandlers}
          bodyData={otherRecipients.map(recipientToOtherRecipient)}
          columnsOrder={['name', 'email']}
          componentsMap={contactsTableMapper}
          noConfig
          noResultsMessage=''
        />
      )}

      <Margin top='2.4rem'>
        <RichText
          label={t('new_visits.email_comment')}
          onChange={setBody}
          placeholder={t('forms.write_a_comment')}
          value={body}
        />
      </Margin>

      <StyledModalButtonsWrapper>
        <DefaultButton
          capitalize
          isDisabled={isProcessing}
          label={t('common.cancel')}
          onClick={hideModal}
          testid='recipients-modal-cancel-btn'
          type='button'
          variant='flat'
        />
        <DefaultButton
          capitalize
          isDisabled={isProcessing || noRecipientsSelected}
          label={t('common.send')}
          onClick={onSubmit}
          testid='recipients-modal-submit-btn'
          variant='primary'
        />
      </StyledModalButtonsWrapper>
    </>
  );
};
