import { createAsyncThunk } from '@reduxjs/toolkit';

import { API } from 'api';
import { AxiosError } from 'axios';
import {
  ChecklistType,
  CompanyRolesType,
  IndustryBranch,
  MeasureResponse,
  MeasureTypeResponse,
  SendEmailSubTypes,
  StandardcommentCategoryResponse,
  VisitSubType,
} from 'ducks/constants/types';
import { RevisedActivityTypes } from 'ducks/events/types';

export const getCompanyRoles = createAsyncThunk('constants/getCompanyRoles', async () => {
  const response = await API.get<CompanyRolesType[]>(`company_roles`);
  return response.data;
});

export const getChecklists = createAsyncThunk(
  'constants/getChecklists',
  async (industry_id?: number) => {
    const response = await API.get<ChecklistType[]>(`checklists`, { params: { industry_id } });
    return response.data;
  },
);

export const getMeasures = createAsyncThunk(
  'constants/getMeasures',
  async (industry_id?: number) => {
    const response = await API.get<MeasureResponse[]>(`measures`, { params: { industry_id } });
    return response.data;
  },
);

export const getIndustryBranches = createAsyncThunk(
  'constants/getIndustryBranches',
  async (industry_id?: number) => {
    const response = await API.get<IndustryBranch[]>(`industry_branches`, {
      params: { industry_id },
    });
    return response.data;
  },
);

export const getMeasureTypes = createAsyncThunk('constants/getMeasureTypes', async () => {
  const response = await API.get<MeasureTypeResponse[]>('measure_types');
  return response.data;
});

export const getStandardCommentCategories = createAsyncThunk(
  'constants/getStandardCommentCategories',
  async (industry_id?: number) => {
    const response = await API.get<StandardcommentCategoryResponse[]>(
      'standard_comment_categories',
      { params: { industry_id } },
    );
    return response.data;
  },
);

export const getEventTypes = createAsyncThunk('constants/getEventTypes', async () => {
  const response = await API.get<RevisedActivityTypes[]>(`revised_activity_types`);
  return response.data;
});

export const getSendEmailSubtypes = createAsyncThunk('constants/getSendEmailSubtypes', async () => {
  const response = await API.get<SendEmailSubTypes[]>(`revised_activity_types`);
  return response.data;
});

export const getTipsThemes = createAsyncThunk('constants/tipsThemes', async () => {
  const response = await API.get(`tips_themes`);
  return response.data;
});
export const getVisitSubTypes = createAsyncThunk(
  'constants/visitSubTypes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get<VisitSubType[]>(`sub_types`);
      return response.data;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);
