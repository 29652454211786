import { RouteComponentProps } from '@reach/router';
import React, { FC, useState } from 'react';
import ReactQuill from 'react-quill';

import { Margin } from 'styles/utils';
import { RichTextRenderer } from './RichTextRenderer';

import 'react-quill/dist/quill.snow.css';

/* Whole file is going to be removed */

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
  ],
};

export const RichTextPresentation: FC<RouteComponentProps> = () => {
  const [value, setValue] = useState('');

  return (
    <div style={{ backgroundColor: 'white' }}>
      <Margin margin='8rem'>
        <ReactQuill modules={modules} onChange={setValue} value={value} />
        <RichTextRenderer>{value}</RichTextRenderer>
        <Margin margin='4rem 0'>
          <h2>Raw:</h2>
          {value}
        </Margin>
      </Margin>
    </div>
  );
};
