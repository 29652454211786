import styled from 'styled-components/macro';

export const StyledSelectItem = styled.li<{
  isHighlighted: boolean;
  isSelected: boolean;
}>`
  background-color: ${({ isSelected, isHighlighted, theme }) => {
    if (isSelected) return theme.colors.blue100;
    if (isHighlighted) return theme.colors.blue50;
    return 'white';
  }};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  list-style-type: none;
  padding: 1rem 2rem;
  white-space: nowrap;
  max-width: 100%;
  & > span {
    color: ${({ theme }) => theme.colors.black200};
    font-size: 1.2rem;
    overflow-x: auto;
  }
`;

export const StyledWrapper = styled.div`
  position: relative;
  span {
    overflow-x: auto;
  }
`;

export const StyledMenu = styled.ul<{ isOpen?: boolean }>`
  background: white;
  border-radius: 0.4rem;
  box-shadow: ${({ theme }) => theme.boxShadows.coolGrey400};
  color: ${({ theme }) => theme.colors.black700};
  overflow-y: scroll;
  max-height: 25rem;
  padding: 0;
  width: ${({ isOpen }) => (isOpen ? 'fit-content' : '0')} !important;
  will-change: transform, opacity;
  z-index: 1000;
  overflow: -moz-scrollbars-vertical;
`;
