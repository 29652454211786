import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import FileSaver from 'file-saver';

import { API } from 'api';
import { SearchPriorNotificationsParams, SearchPriorNotificationsResponse } from './types';

export const searchPriorNotifications = createAsyncThunk(
  'search/PriorNotifications',
  async (params: SearchPriorNotificationsParams, { rejectWithValue, signal }) => {
    try {
      const response = await API.get(`power/prior_notifications`, { params, signal });
      return response.data as SearchPriorNotificationsResponse;
    } catch (e) {
      return rejectWithValue((e as AxiosError).message);
    }
  },
);

export const getPriorNotificationsCSV = async (ids: string[]) => {
  const response = await API.post(
    `power/prior_notifications`,
    {
      id: ids.join(','),
    },
    {
      params: { page: 1, page_size: 10000 },
      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
      },
    },
  );
  const blob = new Blob([response.request.response], {
    type: 'application/vnd.ms-excel',
  });

  FileSaver.saveAs(blob, 'prior-notifications-export.xls');
  return response.data as SearchPriorNotificationsResponse;
};
