// TODO import and reexport all icons here
import React from 'react';

import { ReactComponent as AddFile } from 'images/filePlus.svg';
import { ReactComponent as ArrowDownIcon } from 'images/arrowDownIcon.svg';
import { ReactComponent as ArrowLeftBigIcon } from 'images/arrowLeftBigIcon.svg';
import { ReactComponent as ArrowRightBigIcon } from 'images/arrowRightBigIcon.svg';
import { ReactComponent as ArrowUpIcon } from 'images/arrowUpIcon.svg';
import { ReactComponent as BookmarkIcon } from 'images/bookmarkIcon.svg';
import { ReactComponent as CalendarIcon } from 'images/calendarIcon.svg';
import { ReactComponent as CheckIcon } from 'images/checkIcon.svg';
import { ReactComponent as ConfigIcon } from 'images/configIcon.svg';
import { ReactComponent as CrossIcon } from 'images/closeBigIcon.svg';
import { ReactComponent as DeleteIcon } from 'images/deleteIcon.svg';
import { ReactComponent as DuplicateIcon } from 'images/duplicateIcon.svg';
import { ReactComponent as EditIcon } from 'images/editIcon.svg';
import { ReactComponent as EnvelopeIcon } from 'images/envelopeIcon.svg';
import { ReactComponent as ExportIcon } from 'images/exportIcon.svg';
import { ReactComponent as EyeIcon } from 'images/eyeIcon.svg';
import { ReactComponent as FileIcon } from 'images/fileIcon.svg';
import { ReactComponent as FolderIcon } from 'images/folderIcon.svg';
import { ReactComponent as InfoIcon } from 'images/infoIcon.svg';
import { ReactComponent as LandscapeIcon } from 'images/landscapeIcon.svg';
import { ReactComponent as MeatballIcon } from 'images/meatballIcon.svg';
import { ReactComponent as MinusIcon } from 'images/minusIcon.svg';
import { ReactComponent as PaperclipIcon } from 'images/paperclipIcon.svg';
import { ReactComponent as PdfIcon } from 'images/pdfIcon.svg';
import { ReactComponent as PeopleIcon } from 'images/peopleIcon.svg';
import { ReactComponent as PlayIcon } from 'images/playIcon.svg';
import { ReactComponent as PlusIcon } from 'images/plusIcon.svg';
import { ReactComponent as SpeakerIcon } from 'images/speakerIcon.svg';
import { ReactComponent as SpinnerIcon } from 'images/spinner.svg';
import { ReactComponent as SpoonKnifeIcon } from 'images/spoonKnife.svg';
import { ReactComponent as StopIcon } from 'images/stopIcon.svg';
import { ReactComponent as ThumbUp } from 'images/thumbUp.svg';
import { ReactComponent as UndoIcon } from 'images/undoIcon.svg';
import { ReactComponent as WarningIcon } from 'images/warningIcon.svg';

export const iconsMap = {
  add: <PlusIcon />,
  arrowDown: <ArrowDownIcon />,
  arrowLeftBig: <ArrowLeftBigIcon />,
  arrowRightBig: <ArrowRightBigIcon />,
  arrowUp: <ArrowUpIcon />,
  checkMark: <CheckIcon />,
  config: <ConfigIcon />,
  create_visit: <PlusIcon />,
  delete_from_visit: <DeleteIcon />,
  delete: <DeleteIcon />,
  duplicate: <DuplicateIcon />,
  edit: <EditIcon />,
  export: <ExportIcon />,
  exportCSV: <ExportIcon />,
  file: <FileIcon />,
  infoIcon: <InfoIcon />,
  landscape: <LandscapeIcon />,
  meatball: <MeatballIcon />,
  minus: <MinusIcon />,
  open_report: <PdfIcon />,
  paperclip: <PaperclipIcon />,
  people: <PeopleIcon />,
  plan_a_visit: <CalendarIcon />,
  play: <PlayIcon />,
  send: <EnvelopeIcon />,
  show: <EyeIcon />,
  speaker: <SpeakerIcon />,
  spinner: <SpinnerIcon />,
  spoonKnife: <SpoonKnifeIcon />,
  thumbUp: <ThumbUp />,
};

export type IconsMapType = typeof iconsMap;

export {
  AddFile,
  ArrowDownIcon,
  ArrowLeftBigIcon,
  ArrowRightBigIcon,
  ArrowUpIcon,
  BookmarkIcon,
  CalendarIcon,
  CheckIcon,
  ConfigIcon,
  CrossIcon,
  DeleteIcon,
  DuplicateIcon,
  EditIcon,
  EnvelopeIcon,
  ExportIcon,
  EyeIcon,
  FileIcon,
  FolderIcon,
  InfoIcon,
  LandscapeIcon,
  MeatballIcon,
  MinusIcon,
  PaperclipIcon,
  PdfIcon,
  PeopleIcon,
  PlayIcon,
  PlusIcon,
  SpeakerIcon,
  SpinnerIcon,
  SpoonKnifeIcon,
  StopIcon,
  ThumbUp,
  UndoIcon,
  WarningIcon,
};
