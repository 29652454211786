/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useRef, useState } from 'react';
import { AsyncThunkAction, PayloadAction } from '@reduxjs/toolkit';
import { globalHistory } from '@reach/router';
import { isEqual } from 'lodash';

import { FetchParams } from 'hocs/composedTableProviders/composedTableProviders';
import { navigateWithState } from 'utils/navigateWithState';
import { useAppDispatch } from 'store';
import { useFiltersContext } from 'contexts/FilterContext';
import { useLoadingContext } from 'contexts/LoadingContext';
import { usePaginationContext } from 'contexts/PaginationContext';
import { useSortContext } from 'contexts/SortContext';

type RefetcherProps = {
  fetchFunction: (arg?: any) => AsyncThunkAction<any, any, object>;
  loadResource: string;
  params: FetchParams;
  isLoading: boolean;
};

const getElementByIdAsync = (id: string) =>
  new Promise((resolve) => {
    const getElement = () => {
      const element = document.getElementById(id);
      if (element) {
        resolve(element);
      } else {
        requestAnimationFrame(getElement);
      }
    };
    getElement();
  });

const SEARCH_PAGE = 'powersearch';
const DASHBOARD_PAGE = 'dashboardVisits';

export const Refetcher: FC<RefetcherProps> = (props) => {
  const { children, fetchFunction, loadResource, isLoading } = props;
  const filterCtx = useFiltersContext();
  const paginationCtx = usePaginationContext();
  const { setLoading, setLoaded } = useLoadingContext();
  const { location } = globalHistory;
  const sortCtx = useSortContext();
  const dispatch = useAppDispatch();
  // Used 'any' as I couldn't figure out the type for the promise returned from the dispatch func.
  const promiseRef = useRef<any>(null);

  const params = {
    ...props.params,
    ...filterCtx.filterParams,
    ...sortCtx.sortParams,
    page_size: paginationCtx.rowsPerPage,
    page: paginationCtx.currentPage,
  };

  const [queryParams, setQueryParams] = useState(params);

  const setPaginationStart = (type: string) => {
    if (location?.state?.[type]?.filters) {
      filterCtx.setParams(location.state?.[type].filters);
    }
    if (location?.state?.[type]?.currentPage && location?.state?.[type]?.rowsPerPage) {
      paginationCtx.setParams({
        currentPage: location?.state?.[type]?.currentPage,
        rowsPerPage: location?.state?.[type]?.rowsPerPage,
      });
    } else if (location?.state?.[type]?.currentPage) {
      paginationCtx.setParams({ currentPage: location?.state?.[type].currentPage });
    } else if (location?.state?.[type]?.rowsPerPage) {
      paginationCtx.setParams({ rowsPerPage: location?.state?.[type].rowsPerPage });
    }
  };

  useEffect(() => {
    if (loadResource === SEARCH_PAGE) {
      setPaginationStart('search');
    } else if (loadResource === DASHBOARD_PAGE) {
      setPaginationStart('dashboard');
    }
  }, []);

  useEffect(() => {
    if (!isEqual(queryParams, params)) {
      if (loadResource === SEARCH_PAGE && location.state.search?.currentPage) {
        setQueryParams({ ...params, page: location.state.search.currentPage });
      } else if (loadResource === DASHBOARD_PAGE && location.state.dashboard?.currentPage) {
        setQueryParams({ ...params, page: location.state.dashboard.currentPage });
      } else {
        paginationCtx.setParams({ currentPage: 1 });
        setQueryParams({ ...params, page: 1 });
      }
    }
  }, [filterCtx.filterParams, sortCtx]);

  useEffect(() => {
    if (!isEqual(queryParams, params)) {
      setQueryParams(params);
    }
  }, [paginationCtx.currentPage, paginationCtx.rowsPerPage]);

  const setParamsFromState = async (response: PayloadAction<any>, type: string) => {
    const { page, pageSize, totalPages } = response.payload.meta;
    const currentPage =
      totalPages > 0 && totalPages < location?.state?.[type]?.currentPage
        ? totalPages
        : location?.state?.[type]?.currentPage || page;

    paginationCtx.setParams({
      currentPage: currentPage,
      pages: totalPages,
      rowsPerPage: location?.state?.[type]?.rowsPerPage || pageSize,
    });

    if (type === 'search') {
      navigateWithState('search', {
        search: {
          ...(globalHistory.location?.state?.search || {}),
          currentPage: currentPage,
        },
      });
    } else if (type === 'dashboard') {
      navigateWithState('dashboard', {
        dashboard: {
          ...(globalHistory.location?.state?.dashboard || {}),
          currentPage: currentPage,
        },
      });
    }

    const divElement = (await getElementByIdAsync('table-grid-scroll-container')) as Element;

    if (divElement) {
      if (loadResource === DASHBOARD_PAGE) {
        divElement.scrollTop = globalHistory.location.state?.dashboard?.scrollTop || 0;
      } else if (loadResource === SEARCH_PAGE) {
        divElement.scrollTop = globalHistory.location.state?.search?.scrollTop || 0;
      }
    }
  };

  const fetch = async () => {
    if (promiseRef.current) {
      promiseRef.current.abort();
    }

    promiseRef.current = dispatch(fetchFunction(params));
    const response = await promiseRef.current;

    setLoading(loadResource);
    setLoaded(loadResource);
    if (response.meta.requestStatus === 'fulfilled') {
      const { page, pageSize, totalPages } = response.payload.meta;

      if (loadResource === SEARCH_PAGE) {
        await setParamsFromState(response, 'search');
      } else if (loadResource === DASHBOARD_PAGE) {
        await setParamsFromState(response, 'dashboard');
      } else {
        paginationCtx.setParams({
          currentPage: page,
          pages: totalPages,
          rowsPerPage: pageSize,
        });
      }
    }
  };

  useEffect(() => {
    !isLoading && fetch();
  }, [queryParams, fetchFunction, isLoading]);

  return <>{children}</>;
};
