import styled, { css } from 'styled-components/macro';

interface TagBasePropTypes {
  active?: boolean;
  colorPallete?: {
    background: string;
    border: string;
    containerShadow: string;
    label: string;
    value: string;
  };
}

const TagBase = styled.div<TagBasePropTypes>`
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  display: inline-flex;
  overflow: hidden;
  padding: ${({ theme }) => `${theme.sizes.xxs} ${theme.sizes.xs}`};
`;

export const TagLabel = styled.label`
  ${({ theme }) => theme.fontSizes.xxs};
  display: block;
`;

export const TagValue = styled.div`
  ${({ theme }) => theme.fontSizes.base};
  font-weight: 600;
  max-width: 13.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TagCloseButton = styled.button<{ active?: boolean }>`
  display: inline-block;
  margin-left: ${({ theme }) => theme.sizes.xs};
  transition: 0.3s color;

  & svg {
    ${({ theme }) => theme.icon.sm};
  }
`;

export const TagContentWrapper = styled.div`
  min-height: ${({ theme }) => theme.lineHeight.lg};
  margin: 0 ${({ theme }) => theme.sizes.xs};
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const DefaultTag = styled(TagBase)`
  box-shadow: ${({ theme }) => theme.boxShadows.lightBlue300};

  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.colors.blue50};
      border: 1px solid ${theme.colors.blue200};
      box-shadow: none;
    `}

  ${TagLabel} {
    color: ${({ theme }) => theme.colors.black500};
  }

  ${TagValue} {
    color: ${({ theme }) => theme.colors.blue500};
  }

  ${TagCloseButton} {
    color: ${({ theme }) => theme.colors.blue500};

    &:hover {
      color: ${({ theme }) => theme.colors.black500};
    }

    ${({ active, theme }) =>
      active &&
      css`
        color: ${theme.colors.black500};

        &:hover {
          color: ${theme.colors.blue500};
        }
      `}
  }
`;

export const CustomTag = styled(TagBase)`
  ${({ colorPallete }) =>
    colorPallete &&
    css`
      box-shadow: ${colorPallete.containerShadow};
      background-color: ${colorPallete.background};
      border: 1px solid ${colorPallete.border};

      ${TagLabel} {
        color: ${colorPallete.label};
      }

      ${TagValue} {
        color: ${colorPallete.value};
      }

      ${TagCloseButton} {
        color: ${colorPallete.value};

        &:hover {
          color: ${colorPallete.label};
        }
      }
    `}
`;
