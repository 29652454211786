import styled, { css } from 'styled-components/macro';
import { DefaultButton } from '../DefaultButton/DefaultButton';
import { StyledCheckboxLabel, StyledCheckboxMainLabel } from 'components/Checkbox/styles';

export const StyledMultiSelectList = styled.div`
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  max-height: 42rem;
  overflow: hidden;
  padding: ${({ theme }) => theme.sizes.xxs} 0;
  width: 100%;
`;

export const StyledSearchWrapper = styled.div`
  padding: ${({ theme }) => `${theme.sizes.xxs} ${theme.sizes.sm}`};

  & input::placeholder {
    font-weight: 100;
    text-transform: capitalize;
  }
`;

export const StyledHorizontalRule = styled.hr`
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.black100};
  margin: 0;
`;

export const StyledCheckboxList = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;

export const StyledCheckboxWrapper = styled.div<{
  isChecked?: boolean;
  capitalize?: boolean;
  bold?: boolean;
  showShading?: boolean;
}>`
  align-items: center;
  display: flex;
  padding: ${({ theme }) => `${theme.sizes.xs} ${theme.sizes.sm}`};
  position: relative;
  white-space: nowrap;

  ${({ isChecked, theme }) =>
    isChecked &&
    css`
      background-color: ${theme.colors.blue50};
    `}

  & ${StyledCheckboxLabel} {
    overflow: hidden;

    ${({ capitalize }) =>
      capitalize &&
      css`
        & ${StyledCheckboxMainLabel} {
          text-transform: capitalize;
        }
      `}

    ${({ bold }) =>
      bold &&
      css`
        font-weight: bold;
      `}

      ${({ showShading }) =>
        showShading &&
        css`
      &::after {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        content: '';
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: ${({ theme }) => theme.sizes.lg};
        `}
  }
`;

export const StyledSelectedCounter = styled.div`
  color: ${({ theme }) => theme.colors.blue400};
  line-height: 0;
  margin-right: ${({ theme }) => theme.sizes.md};
  text-align: right;
  transform: translateY(${({ theme }) => theme.sizes.sm});
`;

export const StyledClearButton = styled(DefaultButton)`
  font-weight: 600;
  width: 100%;
`;
