import React from 'react';

import { StyledCellContent, StyledNoWrapCellContent } from 'components/Table/TableRow/styles';
import { StatusBadge } from 'components/StatusBadge/StatusBadge';
import { formatDate } from 'utils/date';
import { SpanCenter } from 'styles/utils';
import { ContactEntity } from 'ducks/contacts/types';
import i18n from 'i18n';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { useModal } from 'components/Modal/Modal';
import { SendEmailsModal } from '../Contacts/ContactsModals';
import { StyledLink } from '../BasicData/styles';

import { ReactComponent as CertificateIcon } from 'images/certificate.svg';

const t = (x: string) => i18n.t(x);

export const contactsComponentsMap = {
  name: {
    component: ({ name }: ContactEntity) => (
      <StyledNoWrapCellContent>{name || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  role: {
    component: ({ role }: ContactEntity) => (
      <StyledNoWrapCellContent>{role || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  email: {
    component: ({ email, companyId }: ContactEntity) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { showModal, setModalComponent, setShowHeader } = useModal();

      return email ? (
        <StyledLink
          as='button'
          onClick={() => {
            setModalComponent(<SendEmailsModal companyId={companyId} itemsSelected={[email]} />);
            setShowHeader(false);
            showModal();
          }}
        >
          {email}
        </StyledLink>
      ) : (
        <StyledCellContent>-</StyledCellContent>
      );
    },
    size: 'min-content',
  },
  phone: {
    component: ({ phone }: ContactEntity) =>
      phone ? (
        <StyledLink href={`tel:${phone}`}>{phone}</StyledLink>
      ) : (
        <StyledNoWrapCellContent>-</StyledNoWrapCellContent>
      ),
    size: 'min-content',
  },
  obligatory_training: {
    component: ({ obligatoryTraining }: ContactEntity) => (
      <SpanCenter>
        <StatusBadge variant={obligatoryTraining ? 'success' : 'fail'}>
          {t(`common.${obligatoryTraining ? 'yes' : 'no'}`)}
        </StatusBadge>
      </SpanCenter>
    ),
    size: 'min-content',
    headerComponent: () => (
      <Tooltip centerContent label={t(`table.columns.obligatory_training`)}>
        <CertificateIcon data-testid='th-obligatory_training' />
      </Tooltip>
    ),
  },
  created_by: {
    component: ({ createdBy }: ContactEntity) => (
      <StyledNoWrapCellContent>{createdBy || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  date: {
    component: ({ date }: ContactEntity) => (
      <StyledNoWrapCellContent>{date ? formatDate(date) : '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
  district_names: {
    component: ({ districtNames }: ContactEntity) =>
      districtNames?.length > 1 ? (
        <Tooltip label={districtNames.join(', ')}>
          <StyledNoWrapCellContent>
            {districtNames[0]} + {districtNames.length - 1}
          </StyledNoWrapCellContent>
        </Tooltip>
      ) : (
        <StyledNoWrapCellContent>
          {!districtNames || districtNames.length === 0 ? '-' : districtNames[0]}
        </StyledNoWrapCellContent>
      ),
    size: 'max-content',
  },
  comment: {
    component: ({ comment }: ContactEntity) => (
      <StyledNoWrapCellContent>{comment || '-'}</StyledNoWrapCellContent>
    ),
    size: 'min-content',
  },
};

export type ContactsComponentsMap = typeof contactsComponentsMap;
