import React from 'react';

import { StyledAvatar } from './styles';

export const Avatar = ({ initials }) => {
  return (
    <StyledAvatar>
      <span>{initials}</span>
    </StyledAvatar>
  );
};

Avatar.defaultProps = {};
